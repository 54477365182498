import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TextFieldWithMask } from '../../TextFieldWithMask'
import { FormControlHelperText } from './FormControlHelperText'
import { FormControlProps } from './types'

export interface MaskProps {
  format: string,
  mask?: string[]
  invalidValuesRegexp?: RegExp
  onChange?: (value: string) => void
}

type FormControlMaskTextFieldProps = Omit<FormControlProps, 'inputRegex'>

export const FormControlMaskTextField: React.FC<FormControlMaskTextFieldProps & MaskProps> = ({
  id,
  name,
  rules,
  label,
  placeholder,
  description,
  inputProps,
  customInputProps,
  disabled,
  format,
  mask = [],
  invalidValuesRegexp,
  onChange,
}) => {
  const { control } = useFormContext()
  const required = rules ? (rules['required']?.value === true || rules['required'] === true) : undefined

  const handleOnChange = (originalHandler: (e) => void, e) => {
    originalHandler(e)
    if (onChange) {
      onChange(e.target?.value)
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, ref, onChange, ...rest }, fieldState: { error } }) => {
        return (
        <>
          <TextFieldWithMask
            {...rest}
            id={id}
            inputRef={ref}
            format={format}
            mask={mask}
            invalidValuesRegexp={invalidValuesRegexp}
            value={value || ''}
            fullWidth
            label={`${label}${required ? ' *': ''}`}
            placeholder={placeholder}
            error={!!error}
            showvalidationstatus={true}
            isvalid={!error && !!value?.length}
            ispristine={!value?.length}
            inputProps={inputProps}
            customInputProps={customInputProps}
            disabled={disabled}
            onChange={(e) => handleOnChange(onChange, e)}
          />
          <FormControlHelperText description={description} error={error} />
        </>
        )
      }
      }
    />
  )
}
