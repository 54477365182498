import styled from '@mui/material/styles/styled'

export const ShippingAddressNotEligible = styled('div', {
    name: 'ShippingFooter',
    slot: 'AddressNotEligible',
  })(({ theme }) => ({
    background: theme.palette.custom.light.yellow,
    padding: theme.spacing(4),
  }))
  
  export const ShippingNotEligibleWarningHeader = styled('span', {
    name: 'ShippingFooter',
    slot: 'NotEligibleWarningHeader',
  })(({ theme }) => ({
    fontWeight: '700',
    fontSize: theme.typography.body1.fontSize,
    paddingLeft:theme.spacing(2),
  }))
  
  export const ShippingNotEligibleWarningBody = styled('div', {
    name: 'ShippingFooter',
    slot: 'NotEligibleWarningBody',
  })(({ theme }) => ({
    fontWeight: '400',
    fontSize: theme.typography.subtitle1.fontSize,
    paddingLeft:theme.spacing(6),
  }))