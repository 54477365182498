import React, { FC, useState } from 'react'
import { SearchClient } from 'algoliasearch'
import { useTheme } from '@mui/material'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
// SWIPER
import SwiperCore, { A11y, Pagination } from 'swiper'
import { SwiperProps, SwiperSlide } from 'swiper/react'
// COMPONENTS
import { SuggestedProductTileProps } from '@components/SuggestedProductTile/SuggestedProductTile'
import { CmsCarouselProductTile } from './CmsCarouselProductTile/CmsCarouselProductTile'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { SuggestedProductTileAlgolia } from '@components/SuggestedProductTileAlgolia'
// TYPES
import { IProduct } from '@typesApp/product'
import { ICMExternalProduct } from '@typesApp/teaser'
import { CSSModule } from '@styles/types'
// STYLES
import { StyledSwiper } from '@components/UI/Carousel/Carousel.style'
import styles from './styles/index.module.scss'

interface CmsCarouselProps {
  categoryFilter?: string
  centeredSlides?: boolean
  currentSlide?: number
  description?: string
  isAlgolia?: boolean
  mainCarouselWidth?: number
  onNextClick?: () => void
  onPrevClick?: () => void
  onSlideChange?: (slideNumber: number | undefined) => void
  pdpdataloading?: boolean
  products?: unknown[] | ICMExternalProduct[] | IProduct[]
  productTileProps?: Partial<SuggestedProductTileProps>
  searchClient?: SearchClient
  searchTerm?: string
  sliderProps?: SwiperProps
  slidesPerView?: number
  subtitle?: string
  title?: string
  /**
   * NOTE: default styling can be overridden by passing css modules into this component.
   * Class names in passed css modules must match those set up for this component.
   * Overrides must be passed as a tuple, structured as follows:
   * first: carousel overrides, second: product tile overrides
   */
  styleOverride?: [CSSModule | undefined, CSSModule | undefined]
}

export const DEFAULT_SLIDES_PER_VIEW = 4

export const CmsCarousel: FC<CmsCarouselProps> = (props: CmsCarouselProps) => {
  const {
    products,
    sliderProps,
    onSlideChange,
    productTileProps,
    title,
    subtitle,
    description,
    slidesPerView,
    styleOverride,
    isAlgolia,
    ...rest
  } = props
  const mobileView = window.innerWidth < 768
  const productsToDisplay = mobileView ? products?.slice(0, 7) : products
  const numberOfProducts = productsToDisplay?.length ?? 0
  const hasMoreThanOneSlide = numberOfProducts > (slidesPerView ?? DEFAULT_SLIDES_PER_VIEW)

  const theme = useTheme()

  const [swiper, setSwiper] = useState<SwiperCore>()
  const [carouselStyleOverride, tileStyleOverride] = styleOverride ?? []
  const [navEnabled, setNavEnabled] = useState<boolean>(mobileView && hasMoreThanOneSlide)

  return !numberOfProducts ? null : (
    <div className={clsx(styles.wrapper, carouselStyleOverride && carouselStyleOverride.wrapper)}>
      <div className={clsx(styles.textContainer, carouselStyleOverride && carouselStyleOverride.textContainer)}>
        {title && <h3 className={clsx(styles.title, carouselStyleOverride && carouselStyleOverride.title)}>{title}</h3>}
        {subtitle && (
          <h4
            className={clsx(carouselStyleOverride && carouselStyleOverride.subtitle, styles.subtitle)}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        {description && (
          <div
            className={clsx(carouselStyleOverride && carouselStyleOverride.description, styles.description)}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>

      <div
        className={clsx(carouselStyleOverride && carouselStyleOverride.sliderContainer, styles.sliderContainer, {
          [styles.sliderInactive]: !navEnabled,
        })}
        {...rest}
      >
        <>
          {navEnabled && (
            <div
              className={clsx(
                carouselStyleOverride && carouselStyleOverride.navigationContainer,
                styles.navigationContainer
              )}
            >
              <div
                className={clsx(
                  carouselStyleOverride && carouselStyleOverride.navigationButton,
                  styles.navigationButton
                )}
                onClick={() => {
                  swiper?.slidePrev()
                }}
              >
                <SVGIcon library="arrow" name="arrow-left" color={theme.palette.text.dark.primary} />
              </div>
            </div>
          )}

          <StyledSwiper
            modules={[Pagination, A11y]}
            onLock={() => {
              setNavEnabled(false)
            }}
            onUnlock={() => {
              setNavEnabled(true)
            }}
            onSlideChange={() => onSlideChange && onSlideChange(swiper?.activeIndex)}
            onSwiper={setSwiper}
            pagination={{
              clickable: true,
            }}
            simulateTouch
            slidesPerGroup={1}
            slidesPerView={1.5}
            touchStartPreventDefault={false}
            breakpoints={{
              768: {
                slidesPerGroup: 2,
                slidesPerView: 2,
              },
              1024: {
                slidesPerGroup: 3,
                slidesPerView: 3,
              },
              1280: {
                slidesPerGroup: slidesPerView ?? DEFAULT_SLIDES_PER_VIEW,
                slidesPerView: slidesPerView ?? DEFAULT_SLIDES_PER_VIEW,
              },
            }}
            onResize={() => {
              if (swiper) {
                const breakpointParams = swiper.params?.breakpoints?.[swiper.currentBreakpoint]
                const hasMoreThanOneSlide = numberOfProducts > Number(breakpointParams?.slidesPerGroup)
                const shouldCenterSlides = window.innerWidth < 768 || !hasMoreThanOneSlide
                const swiperEl = swiper.el
                if (swiper.params.centeredSlides !== shouldCenterSlides) {
                  swiper.params.centeredSlides = shouldCenterSlides
                  swiper.update()
                }

                /**
                 * NOTE: There is a bug with Swiper library when
                 * updating the `centeredSlides` param to true/false.
                 * It doesn't add/remove the `swiper-centered` class name.
                 * So adding the code below to manually remove/add the class.
                 */
                if (shouldCenterSlides) {
                  swiperEl?.classList.add('swiper-centered')
                  setNavEnabled(false)
                } else {
                  swiperEl?.classList.remove('swiper-centered')
                  setNavEnabled(true)
                }
              }
            }}
            centeredSlides={mobileView || !hasMoreThanOneSlide}
            cssMode={true}
            className={clsx(carouselStyleOverride && carouselStyleOverride.cmsSwiper, styles.cmsSwiper)}
            {...sliderProps}
          >
            {productsToDisplay?.map((product, index: number) => (
              <SwiperSlide key={`swiper-${index}-${uuid()}`}>
                {/* TODO: using SuggestedProductTileAlgolia here since the product structure from Algolia
                is a bit different. But ideally we should refactor this to use CmsCarouselProductTile  */}
                {isAlgolia ? (
                  <SuggestedProductTileAlgolia
                    isPDP
                    isClustered
                    product={product}
                    tileIndex={index}
                    {...productTileProps}
                  />
                ) : (
                  <CmsCarouselProductTile
                    tileIndex={index}
                    product={product}
                    styleOverride={tileStyleOverride}
                    isPDP
                    isClustered
                    {...productTileProps}
                  />
                )}
              </SwiperSlide>
            ))}
          </StyledSwiper>

          {navEnabled && (
            <div
              className={clsx(
                carouselStyleOverride && carouselStyleOverride.navigationContainer,
                styles.navigationContainer
              )}
            >
              <div
                className={clsx(
                  carouselStyleOverride && carouselStyleOverride.navigationButton,
                  styles.navigationButton
                )}
                onClick={() => {
                  swiper?.slideNext()
                }}
              >
                <SVGIcon library="arrow" name="arrow-right" color={theme.palette.text.dark.primary} />
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  )
}
