import styled from '@mui/material/styles/styled'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'

export const OrderDetailsFooterContainer = styled('div', {
  name: 'OrderDetailsFooter',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 4),
  [getDesktopThemeBreakpoint(theme)]: {
    flexDirection: 'row',
    padding: 0,
  },
}))

export const OrderSummaryContainer = styled('div', {
  name: 'OrderDetailsFooter',
  slot: 'OrderSummaryContainer',
})(({ theme }) => ({
  backgroundColor: theme.palette.custom.light.grey,
  color: theme.palette.text.dark.primary,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),

  [getDesktopThemeBreakpoint(theme)]: {
    width: '50%',
  },
}))

export const OrderSummaryTitle = styled('div', {
  name: 'OrderDetailsFooter',
  slot: 'OrderSummaryTitle',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 700,
  marginBottom: theme.spacing(2),
}))

export const StyledText = styled('span', {
  name: 'OrderDetailsFooter',
  slot: 'StyledText',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.dark.primary,
}))

export const OrderSummaryRow = styled('div', {
  name: 'OrderDetailsFooter',
  slot: 'OrderSummaryRow',
})(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  flexGrow: 0,
  justifyContent: 'flex-start',
  padding: 0,
}))

export const OrderSummaryLabel = styled(StyledText, {
  name: 'OrderDetailsFooter',
  slot: 'OrderSummaryLabel',
})(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontWeight: 700,
  lineHeight: 1.33,
  marginRight: theme.spacing(2),
  minWidth: 100,
  ':lang(fr-ca)': {
    minWidth: 140,
  },
}))

export const OrderSummaryValue = styled(StyledText, {
  name: 'OrderDetailsFooter',
  slot: 'OrderSummaryValue',
})(({}) => ({
  lineHeight: 1.33,
}))

export const OrderTotalsContainer = styled('div', {
  name: 'OrderDetailsFooter',
  slot: 'TotalsContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  rowGap: theme.spacing(2),
  padding: theme.spacing(4),

  [getDesktopThemeBreakpoint(theme)]: {
    width: '50%',
    padding: theme.spacing(4, 4, 4, 8),
  },
}))

export const OrderTotalsRow = styled('div', {
  name: 'OrderDetailsFooter',
  slot: 'TotalsRow',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  flexGrow: 0,
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: 0,
}))

export const OrderTotalsLabel = styled('span', {
  name: 'OrderDetailsFooter',
  slot: 'TotalsLabel',
})(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontWeight: 700,
  width: '50%',
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: 1.33,
  color: theme.palette.text.dark.primary,
  marginRight: theme.spacing(2),
}))

export const OrderTotalsValue = styled('span', {
  name: 'OrderDetailsFooter',
  slot: 'TotalsValue',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  lineHeight: 1.43,
  textAlign: 'right',
}))

export const OrderTotalsTotalValue = styled('span', {
  name: 'OrderDetailsFooter',
  slot: 'TotalPriceValue',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 700,
  lineHeight: 1.43,
  textAlign: 'right',
  color: theme.palette.text.dark.primary,
}))
