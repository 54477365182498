// Standard libraries
import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'

//UI
import {
  PrescriptionHeader,
  PrescriptionHeaderComponent,
  PrescriptionHeaderRow,
  PrescriptionViewContainer,
  RxCTA,
  RXItemSection,
  RXIViewtemsSection,
  RXViewSectionItem,
  RXViewSectionItemHeader,
} from './Prescriptions.style'
import useBreakpoints from '../../hooks/useBreakpoints'
import { setOpenModalAddPrescription, setOpenModalDeletePrescription } from '../../features/ui/action'
import { useDispatch } from 'react-redux'
import { IPrescription } from '../../features/prescription/query'
import { toNumber } from 'lodash-es'
import { isValidRxValue, validateRx } from '../../components/PrescriptionLenses/RxUtils'
import { StyledAlert } from '../../components/UI/Alert/Alert'
import { StyledAlertHeader, StyledAlertMessage } from '../../components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

type PrescriptionContainerProps = {
  prescription: IPrescription
  onEditAction?: {}
  onDeleteAction?: {}
}
type RXInfoProps = {
  prescription: IPrescription | null
  isMobile: boolean
  highLightHeaders: boolean
  leftAxisError?: boolean
  rightAxisError?: boolean
}
export const RenderPrescriptionContainer: FC<PrescriptionContainerProps> = (props: PrescriptionContainerProps) => {
  const { isMobile } = useBreakpoints()
  const { t: translate } = useTranslation('', { keyPrefix: 'PrescriptionsSection.ViewSection' })
  const dispatch = useDispatch()
  const { prescription, onEditAction, onDeleteAction } = props
  const nameComponent = (
    <PrescriptionHeaderComponent className="text">
      {prescription.nickName || prescription.idXprescription}
    </PrescriptionHeaderComponent>
  )

  const creationDateComponent = (
    <PrescriptionHeaderComponent className="text">
      {prescription.dateCreated
        ? translate('CreationDate', {
            creationDate: prescription.dateCreated,
          })
        : null}
    </PrescriptionHeaderComponent>
  )

  const editButton = (
    <PrescriptionHeaderComponent className="cta edit">
      <RxCTA onClick={() => dispatch(setOpenModalAddPrescription(true, prescription, onEditAction))}>
        <SVGIcon library="account" name="edit" size={24} /> {translate('Edit')}
      </RxCTA>
    </PrescriptionHeaderComponent>
  )

  const deleteButton = (
    <PrescriptionHeaderComponent className="cta delete">
      <RxCTA onClick={() => dispatch(setOpenModalDeletePrescription(true, prescription, onDeleteAction))}>
        <SVGIcon library="account" name="trash-bin" size={18} /> {translate('Delete')}
      </RxCTA>
    </PrescriptionHeaderComponent>
  )

  const { leftAxisError, rightAxisError, pdError } = validateRx(prescription)
  const axisError = leftAxisError || rightAxisError

  return (
    <>
      <PrescriptionViewContainer id={`rx-item-id-${prescription?.idXprescription}`}>
        <PrescriptionHeader>
          <PrescriptionHeaderRow className="first">
            {nameComponent}
            {isMobile ? creationDateComponent : editButton}
          </PrescriptionHeaderRow>
          <PrescriptionHeaderRow>
            {isMobile ? editButton : creationDateComponent}
            {deleteButton}
          </PrescriptionHeaderRow>
        </PrescriptionHeader>

        <RXIViewtemsSection>
          <RenderRXInfo
            prescription={prescription}
            isMobile={isMobile}
            highLightHeaders={false}
            leftAxisError={leftAxisError}
            rightAxisError={rightAxisError}
          />

          {
            //We will hold this feature for the release date
            /* {prescription.prescriptionUrl === ''
            ?
            <UploadRXSection>
              <UploadPrescriptionCTA
                labeltext={translate('UploadPrescription')}
                size={'medium'}
                iconposition={'left'}
                renderIcon={() => <UploadIcon></UploadIcon>}
                fullwidth={isMobile}
                data-element-id={'X_X_Prescriptions_UploadPrescriptionButton'}
                variant="secondary"
                onClick={() => dispatch(setOpenModalViewRX(true, prescription))}
              >
              </UploadPrescriptionCTA>
              <FreeLabel
                onClick={() => dispatch(setOpenModalViewRX(true, prescription))}
              >
                {translate('AcceptedFiles')}
              </FreeLabel>
            </UploadRXSection>
            :
            <RxCTA
              onClick={() => dispatch(setOpenModalViewRX(true, prescription))}
            >
              <ViewPrescriptionIcon /> {translate('ViewThumbnail')}
            </RxCTA>
          } */
          }

          {axisError &&
            renderErrorContainer(translate('Error.AxisError.Title'), translate('Error.AxisError.Description'))}
          {pdError && renderErrorContainer(translate('Error.PDError.Title'), translate('Error.PDError.Description'))}
        </RXIViewtemsSection>
      </PrescriptionViewContainer>
    </>
  )
}

const renderErrorContainer = (header: string, message: string) => {
  return (
    <StyledAlert severity={'error'} sx={{ width: '100%' }}>
      <StyledAlertHeader>{header}</StyledAlertHeader>
      <StyledAlertMessage fullWidth>
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </StyledAlertMessage>
    </StyledAlert>
  )
}

export const RenderRXInfo = (props: RXInfoProps) => {
  const { prescription, isMobile, highLightHeaders, leftAxisError, rightAxisError } = props

  if (!prescription) {
    return null
  }
  const { t: translate } = useTranslation('', { keyPrefix: 'PrescriptionsSection.ViewSection' })

  const pupillaryDistance = toNumber(prescription?.pupillaryDistance)
  const rPupDistance = toNumber(prescription.rPupDistance).toFixed(1)
  const lPupDistance = toNumber(prescription.lPupDistance).toFixed(1)
  const singlePDValue = pupillaryDistance.toFixed(1)
  const isSinglePD = pupillaryDistance > 0
  const rightAxis = isValidRxValue(prescription.rightAxis) ? toNumber(prescription.rightAxis).toFixed(0) : '-'
  const leftAxis = isValidRxValue(prescription.leftAxis) ? toNumber(prescription.leftAxis).toFixed(0) : '-'
  const rightSphere = toNumber(prescription.rightSphere || '0').toFixed(2)
  const leftSphere = toNumber(prescription.leftSphere || '0').toFixed(2)
  const rightCyl = isValidRxValue(prescription.rightCylinder) ? toNumber(prescription.rightCylinder).toFixed(2) : '0.00'
  const leftCyl = isValidRxValue(prescription.leftCylinder) ? toNumber(prescription.leftCylinder).toFixed(2) : '0.00'
  const rightAdd = isValidRxValue(prescription.rightAdd) ? toNumber(prescription.rightAdd || '0').toFixed(2) : '-'
  const leftAdd = isValidRxValue(prescription.leftAdd) ? toNumber(prescription.leftAdd || '0').toFixed(2) : '-'

  return (
    <>
      {isMobile ? (
        <RXItemSection>
          <RXViewSectionItemHeader highLight={highLightHeaders} className="empty">
            &nbsp;
          </RXViewSectionItemHeader>
          <RXViewSectionItemHeader highLight={highLightHeaders}>{translate('OD')}</RXViewSectionItemHeader>
          <RXViewSectionItemHeader highLight={highLightHeaders}>{translate('OS')}</RXViewSectionItemHeader>

          <RXViewSectionItemHeader highLight={highLightHeaders} className="left">
            {translate('SPH')}
          </RXViewSectionItemHeader>
          <RXViewSectionItem>{rightSphere}</RXViewSectionItem>
          <RXViewSectionItem>{leftSphere}</RXViewSectionItem>

          <RXViewSectionItemHeader highLight={highLightHeaders} className="left">
            {translate('CYL')}
          </RXViewSectionItemHeader>
          <RXViewSectionItem>{rightCyl}</RXViewSectionItem>
          <RXViewSectionItem>{leftCyl}</RXViewSectionItem>

          <RXViewSectionItemHeader highLight={highLightHeaders} className="left">
            {translate('Axis')}
          </RXViewSectionItemHeader>
          <RXViewSectionItem hasError={rightAxisError}>{rightAxis}</RXViewSectionItem>
          <RXViewSectionItem hasError={leftAxisError}>{leftAxis}</RXViewSectionItem>

          <RXViewSectionItemHeader highLight={highLightHeaders} className="left">
            {translate('ADD')}
          </RXViewSectionItemHeader>
          <RXViewSectionItem>{rightAdd}</RXViewSectionItem>
          <RXViewSectionItem>{leftAdd}</RXViewSectionItem>

          <RXViewSectionItemHeader highLight={highLightHeaders} className={'left'}>
            {translate('PD')}
          </RXViewSectionItemHeader>
          <RXViewSectionItem className={isSinglePD ? 'singlePD' : ''}>
            {isSinglePD ? singlePDValue : rPupDistance}
          </RXViewSectionItem>
          <RXViewSectionItem>{isSinglePD ? '' : lPupDistance}</RXViewSectionItem>
        </RXItemSection>
      ) : (
        <RXItemSection>
          <RXViewSectionItemHeader highLight={highLightHeaders} className="empty">
            &nbsp;
          </RXViewSectionItemHeader>
          <RXViewSectionItemHeader highLight={highLightHeaders}>{translate('SPH')}</RXViewSectionItemHeader>
          <RXViewSectionItemHeader highLight={highLightHeaders}>{translate('CYL')}</RXViewSectionItemHeader>
          <RXViewSectionItemHeader highLight={highLightHeaders}>{translate('Axis')}</RXViewSectionItemHeader>
          <RXViewSectionItemHeader highLight={highLightHeaders}>{translate('ADD')}</RXViewSectionItemHeader>
          <RXViewSectionItemHeader highLight={highLightHeaders}>{translate('PD')}</RXViewSectionItemHeader>

          <RXViewSectionItemHeader highLight={highLightHeaders} className="left">
            {translate('OD')}
          </RXViewSectionItemHeader>
          <RXViewSectionItem>{rightSphere}</RXViewSectionItem>
          <RXViewSectionItem>{rightCyl}</RXViewSectionItem>
          <RXViewSectionItem hasError={rightAxisError}>{rightAxis}</RXViewSectionItem>
          <RXViewSectionItem>{rightAdd}</RXViewSectionItem>
          <RXViewSectionItem className={isSinglePD ? 'singlePD' : ''}>
            {isSinglePD ? singlePDValue : rPupDistance}
          </RXViewSectionItem>

          <RXViewSectionItemHeader highLight={highLightHeaders} className="left">
            {translate('OS')}
          </RXViewSectionItemHeader>
          <RXViewSectionItem>{leftSphere}</RXViewSectionItem>
          <RXViewSectionItem>{leftCyl}</RXViewSectionItem>
          <RXViewSectionItem hasError={leftAxisError}>{leftAxis}</RXViewSectionItem>
          <RXViewSectionItem>{leftAdd}</RXViewSectionItem>
          <RXViewSectionItem>{isSinglePD ? '' : lPupDistance}</RXViewSectionItem>
        </RXItemSection>
      )}
    </>
  )
}
