import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cartSelector,
  orderDetailsSelector,
  orderItemsSelector,
  reorderInfoSelector,
} from '../../../features/order/selector'
import { userDetailsSelector } from '@redux/selectors/user'
import {
  CheckoutSummaryContainer,
  CheckoutSummaryLabel,
  ContinueShoppingButton,
  StyledDiscountPriceValue,
  StyledFormattedPriceDisplay,
  Total,
  TotalSummaryContainer,
  TotalSummaryRow,
} from './CheckoutSummary.style'
import { StyledCheckoutButton } from '@views/Cart/components/CartCheckoutButtonsContainer/CartCheckoutButtonsContainer.style'
import {
  StyledReorderSummaryLabel,
  StyledEditLabel,
  StyledEditLink,
  StyledBillingAddressContainer,
  StyledStrikethroughPriceContainer,
} from './ReorderSummaryRightColumn.style'
import { PreLoader, StyledTypography } from '../../../components/UI'
import CheckoutPaymentMethod from './CheckoutPaymentMethod'
import { Contact } from '@typesApp/user'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import FormattedPriceDisplay from '../../../components/formatted-price-display'
import { useTranslation } from 'next-i18next'
import { APPLEPAY_ORDER_ID } from '../../../constants/checkout'
import { OrderTaxSummary } from '../../../components/OrderTaxSummary'
import OrderService from '../../../foundation/apis/transaction/order.service'
import { useFindOrderdByIdQuery } from '@features/order/query'
import { DiscountedHandlingFee } from '@components/OrderTotalSummary/HandlingFeeSummary'
import { useReorder } from '../hooks/useReorder'
import { useSite } from '@foundation/hooks/useSite'
import * as ROUTES from '../../../constants/routes'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useRouter } from 'next/navigation'
import { useTheme } from '@mui/material/styles'
import { DiscountedPrice } from '@components/order-recap/OrderRecapItemPrice.style'
import { ACCOUNT, ACCOUNT_CHILDREN } from '../../../constants/routes'
import { REORDER_BILLING_ADDRESS_ID, REORDER_OLD_ORDER_ID, REORDER_ORDER_ID } from '@foundation/constants/common'
import { DirectBillingCTA } from '@components/DirectBilling/components'
import { StyledRecapContainer } from '@components/order-recap/OrderRecap.style'
import CartPromoCodeSection from '@views/Cart/components/CartPromoCodeSection'
import { CommerceEnvironment, HANDLING_FEES_ADJUSTMENT, HANDLING_FEES_DISCOUNT } from '@constants/common'
import { clearReorderLocalStorage, isValidOrderItemsInReorder } from '@utils/order'
import { setCreditCardNumber } from '@features/order/slice'
import { getSubscribedItems } from '@views/Subscription/helpers/subscriptionHelpers'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const ReorderSummaryRightColumn: FC<{
  isReorderBusy: boolean
  setReorderBusy: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isReorderBusy, setReorderBusy }) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const { mySite } = useSite()
  const router = useRouter()
  const dispatch = useDispatch()
  const theme = useTheme()

  const userDetails = useSelector(userDetailsSelector)
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)
  const orderDetails = useSelector(orderDetailsSelector)
  const reorderInfo = useSelector(reorderInfoSelector)
  const hasSubscribedItemsInCart = !!orderItems && !!getSubscribedItems(orderItems).length
  const isDirectBillingEnabled =
    !!CommerceEnvironment.directInsuranceBilling[mySite.locale?.toLowerCase()]?.enabled ?? false

  const reorderOrderId = localStorageUtil.get(REORDER_ORDER_ID)
  const reorderOldOrderId = localStorageUtil.get(REORDER_OLD_ORDER_ID)
  const billingAddressId = localStorageUtil.get(REORDER_BILLING_ADDRESS_ID)

  const { availablePaymentMethod, cta, proceedCheckout } = useReorder(setReorderBusy)

  const getAddressLine1 = (contact?: Contact) => `${contact?.firstName} ${contact?.lastName}`
  const getAddressLine2 = (contact?: Contact) => `${contact?.addressLine?.[0]}, ${contact?.city} ${contact?.zipCode}`

  const shippingAddressData = userDetails?.contact?.find(a => a.addressId === orderItems?.[0]?.addressId)
  const billingAddressData = userDetails?.contact?.find(address => address.addressId === billingAddressId)
  const shippingAddressLine1 = getAddressLine1(shippingAddressData)
  const shippingAddressLine2 = getAddressLine2(shippingAddressData)
  const billingAddressLine1 = getAddressLine1(billingAddressData)
  const billingAddressLine2 = getAddressLine2(billingAddressData)

  const shippingName = orderItems?.length > 0 ? OrderService.getDisplayShippingName(orderItems) : ''
  const totals = useMemo(() => OrderService.getOrderTotals(cart), [cart])
  const isFreeHandling = totals.handlingFeeTotal <= 0
  const isDiscountedHandling = totals.handlingFeeAmount !== totals.handlingFeeTotal
  const creditCardBinPan = `${availablePaymentMethod?.creditCardBin}${availablePaymentMethod?.creditCardPan}`
  dispatch(setCreditCardNumber(creditCardBinPan))
  const totalFontSize = typeof theme.typography.htmlFontSize === 'number' ? theme.typography.htmlFontSize : 16
  const { completeCheckoutStep } = useCheckoutSteps()

  useFindOrderdByIdQuery(
    { storeId: mySite.storeID, orderId: cart?.orderId || localStorageUtil.get(APPLEPAY_ORDER_ID) },
    { skip: !cart && !localStorageUtil.get(APPLEPAY_ORDER_ID) }
  )

  if (!reorderOrderId && !reorderOldOrderId) {
    clearReorderLocalStorage()
    router.replace(`/${langCode}/${ACCOUNT}/${ACCOUNT_CHILDREN.DASHBOARD}`)
  }

  const EditLink = (props: { href: string }) => {
    const gotoHref = () => {
      if (props.href === `/${langCode}/${ROUTES.CHECKOUT_PAYMENT}`) {
        completeCheckoutStep('shipping')
      }

      if (props.href === `/${langCode}/${ROUTES.CHECKOUT_SHIPPING}`) {
        completeCheckoutStep('cart')
      }

      router.push(props.href)
    }
    return (
      <StyledEditLink href={props.href} onClick={gotoHref}>
        <SVGIcon library="account" name="edit" size={18} />
        <StyledEditLabel>{t('OrderRecap.Labels.Edit')}</StyledEditLabel>
      </StyledEditLink>
    )
  }

  const ShippingSummary = () => {
    return (
      <>
        <StyledReorderSummaryLabel>
          <span>{t('Shipping.Labels.ShippingMethod')}:</span>
          <span>
            <EditLink href={`/${langCode}/${ROUTES.CART}`} />
          </span>
        </StyledReorderSummaryLabel>
        <StyledReorderSummaryLabel>
          <CheckoutSummaryLabel>{shippingName}</CheckoutSummaryLabel>
          <CheckoutSummaryLabel>
            <StyledFormattedPriceDisplay min={totals.shipping} currency={totals.shippingCurrency} />
          </CheckoutSummaryLabel>
        </StyledReorderSummaryLabel>
      </>
    )
  }

  const HandlingFees = () => {
    return totals.handlingFeeAmount > 0 ? (
      <StyledReorderSummaryLabel>
        <CheckoutSummaryLabel>
          <span>{t('OrderTotalSummary.Labels.HandlingFee.Text')}</span>
        </CheckoutSummaryLabel>
        <CheckoutSummaryLabel>
          {isDiscountedHandling ? (
            <>
              <DiscountedHandlingFee>
                {isFreeHandling ? (
                  t('OrderTotalSummary.Labels.HandlingFee.Free')
                ) : (
                  <StyledFormattedPriceDisplay min={totals.handlingFeeTotal} currency={totals.grandTotalCurrency} />
                )}
              </DiscountedHandlingFee>
              <s>
                <StyledFormattedPriceDisplay min={totals.handlingFeeAmount} currency={totals.grandTotalCurrency} />
              </s>
            </>
          ) : (
            <StyledFormattedPriceDisplay min={totals.handlingFeeTotal} currency={totals.shippingCurrency} />
          )}
        </CheckoutSummaryLabel>
      </StyledReorderSummaryLabel>
    ) : (
      <></>
    )
  }

  const ShippingAndBillingAddress = () => {
    return (
      <>
        <StyledReorderSummaryLabel>
          <span>{t('ReorderSummary.DeliveryTo')}:</span>
          <span>
            <EditLink href={`/${langCode}/${ROUTES.CHECKOUT_SHIPPING}`} />
          </span>
        </StyledReorderSummaryLabel>
        <CheckoutSummaryLabel>{shippingAddressLine1}</CheckoutSummaryLabel>
        <CheckoutSummaryLabel>{shippingAddressLine2}</CheckoutSummaryLabel>
        <CheckoutSummaryLabel>{shippingAddressData?.email1}</CheckoutSummaryLabel>
        <CheckoutSummaryLabel>{shippingAddressData?.phone1}</CheckoutSummaryLabel>
        {billingAddressData && (
          <StyledBillingAddressContainer>
            <StyledReorderSummaryLabel>
              {t('ReorderSummary.BillingTo')}:
              <span>
                <EditLink href={`/${langCode}/${ROUTES.CHECKOUT_SHIPPING}`} />
              </span>
            </StyledReorderSummaryLabel>
            <CheckoutSummaryLabel>{billingAddressLine1}</CheckoutSummaryLabel>
            <CheckoutSummaryLabel>{billingAddressLine2}</CheckoutSummaryLabel>
          </StyledBillingAddressContainer>
        )}
      </>
    )
  }

  const PaymentSummary = () => {
    return (
      <>
        <StyledReorderSummaryLabel>
          <span>{t('Payment.Labels.PaymentMethod')}:</span>
          <span>
            <EditLink href={`/${langCode}/${ROUTES.CHECKOUT_PAYMENT}`} />
          </span>
        </StyledReorderSummaryLabel>
        <CheckoutPaymentMethod cardNumber={creditCardBinPan} />
      </>
    )
  }

  const TaxSummary = () => {
    return (
      <OrderTaxSummary
        orderId={orderDetails?.orderId}
        order={orderDetails}
        orderCurrency={orderDetails?.totalSalesTaxCurrency}
        orderItems={orderItems}
        showHeader={true}
        shippingTax={totals.shippingTax}
        salesTax={totals.tax}
      />
    )
  }

  const TotalSummary = () => {
    const EXCLUDED_DISCOUNTS = [HANDLING_FEES_ADJUSTMENT, HANDLING_FEES_DISCOUNT]
    return (
      <TotalSummaryContainer>
        {totals.hasInsurance && (
          <TotalSummaryRow>
            <StyledTypography>{t('OrderTotalSummary.Labels.InsuredCoverage')}</StyledTypography>
            <StyledDiscountPriceValue>
              -
              <FormattedPriceDisplay min={totals.insuranceAmount} currency={totals.insuranceCurrency} />
            </StyledDiscountPriceValue>
          </TotalSummaryRow>
        )}
        {totals.hasPromotion && (
          <>
            {cart?.adjustment
              .filter(adj => !EXCLUDED_DISCOUNTS.includes(adj.description))
              .map(adj => {
                return (
                  <TotalSummaryRow key={adj.code}>
                    <StyledTypography fontWeight="bold" fontColor={theme.palette.custom.light1.green}>
                      {adj.description || adj.code}
                    </StyledTypography>
                    <StyledDiscountPriceValue fontColor={theme.palette.custom.light1.green}>
                      -
                      <FormattedPriceDisplay min={Number(adj.amount) || 0} currency={totals.promotionCurrency} />
                    </StyledDiscountPriceValue>
                  </TotalSummaryRow>
                )
              })}
          </>
        )}
        <TotalSummaryRow>
          <StyledTypography fontWeight="bold" fontSize={20}>
            {t('ReorderSummary.Total')}
          </StyledTypography>
          <Total fontSize={totalFontSize}>
            {totals.hasPromotion && (
              <StyledStrikethroughPriceContainer>
                <FormattedPriceDisplay
                  min={totals.grandTotal + Math.abs(totals.promotionAmount)}
                  currency={totals.grandTotalCurrency}
                />
              </StyledStrikethroughPriceContainer>
            )}
            <DiscountedPrice>
              <FormattedPriceDisplay min={totals.grandTotal} currency={totals.grandTotalCurrency} />
            </DiscountedPrice>
          </Total>
        </TotalSummaryRow>
      </TotalSummaryContainer>
    )
  }

  return (
    <CheckoutSummaryContainer>
      {isValidOrderItemsInReorder(reorderInfo, orderItems) && (
        <>
          {!hasSubscribedItemsInCart && isDirectBillingEnabled && (
            <DirectBillingCTA
              translations={{
                unavailableTitle: t('DirectBilling.UnavailableTitle'),
                unavailableMessage: t('DirectBilling.UnavailableMessage'),
              }}
            />
          )}
          <StyledRecapContainer>
            <ShippingSummary />
            <HandlingFees />
          </StyledRecapContainer>
          <StyledRecapContainer>
            <ShippingAndBillingAddress />
          </StyledRecapContainer>
          {availablePaymentMethod?.creditCardPan && (
            <StyledRecapContainer>
              <PaymentSummary />
            </StyledRecapContainer>
          )}
          <StyledRecapContainer>
            <TaxSummary />
          </StyledRecapContainer>
          <CartPromoCodeSection />
          <TotalSummary />
        </>
      )}
      <ContinueShoppingButton>
        <StyledCheckoutButton
          fullwidth
          variant="secondary"
          onClick={() => proceedCheckout()}
          disabled={isReorderBusy}
          sx={{ marginTop: '4.3rem' }}
        >
          {isReorderBusy && <PreLoader withButton />}
          {cta.text}
        </StyledCheckoutButton>
      </ContinueShoppingButton>
    </CheckoutSummaryContainer>
  )
}

export default ReorderSummaryRightColumn
