import React, { useState, useEffect } from 'react'
import { compact, toNumber } from 'lodash-es'
import { useTranslation } from 'next-i18next'
import { Box } from '@mui/material'
import { getModelName } from '../../utils/productAttributes'
import { OrderItem } from '../../types/order'
import ProductImage from '../ProductImage/ProductImage'
import {
  OrderRecapImageWrapper,
  OrderRecapSectionLabel,
  OrderRecapSectionValue,
  ClOrderRecapSection,
  PriceWrapper,
  OrderRecapInfoSectionWrapper,
  OrderRecapPriceSection,
  OrderAdjustmentAmount,
  OrderRecapPriceLabel,
} from './OrderRecapItem.style'
import { useContactLensCartItemData } from '../../hooks/useContactLensCartItemData/useContactLensCartItemData'
import { OrderRecapClItemLensDetails } from './OrderRecapClItemLensDetails'
import { CHECKOUT_STEPS } from '../../constants/common'
import FormattedPriceDisplay from '../formatted-price-display'
import { InsuranceEligibleCallout } from '../../views/Cart/components/CartRecap/components/InsuranceEligibleCallout'
import { getFormattedDiscountedPrice } from '../../features/order/utils'
import OrderRecapItemPrice from './OrderRecapItemPrice'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import styles from './styles/OrderRecapItem.module.scss'
import { useSelector } from 'react-redux'
import { SubscriptionSummaryMessage } from '@views/Subscription/cart/components/SummaryMessage'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { renderOrderItemStatusColor } from '@constants/order'
import OrderService from '../../foundation/apis/transaction/order.service'
import { OrderRecapItemPropsBase } from './OrderRecapItem'
import { useOrderDetails } from '../../hooks/useOrderDetails'
import { mergeAdjustments } from '@views/Subscription/helpers/subscriptionHelpers'
import clsx from 'clsx'

export type OrderRecapClItemProps = OrderRecapItemPropsBase<OrderItem>

const OrderRecapClItem: React.FC<OrderRecapClItemProps> = ({
  orderItem: oItem,
  showPromoCodeAppliedDiscount,
  isMyAccount = false,
  orderId,
  isReorderEligibleOrder,
}) => {
  const { t: translate } = useTranslation()
  const [orderItem, setOrderItem] = useState(oItem)
  const names = compact([getModelName(orderItem)])
  const { orderItemClData, totalBoxes } = useContactLensCartItemData({ orderItem })
  const productName = names.join(' ')
  const formattedDiscountedPrice = getFormattedDiscountedPrice(orderItem)
  const isPromoCodeApplied = !!formattedDiscountedPrice
  const { activeStep } = useCheckoutSteps()
  const isConfirmationPage = CHECKOUT_STEPS.ORDER_CONFIRMATION === activeStep
  const isPostCheckout = CHECKOUT_STEPS.POST_CHECKOUT === activeStep
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const hookEstimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)
  const estimatedItemDeliveryDate =
    OrderService.getOrderItemEstimatedDeliveryDate(orderItem) || hookEstimatedDeliveryDate
  const orderDetails = useOrderDetails(orderId) || {}
  const isReorderEligibleItem = OrderService.isReorderEligible(orderDetails)

  useEffect(() => {
    if (orderItem?.sibilingOrderItem?.adjustment) {
      const mergedAdjustment = mergeAdjustments([orderItem.adjustment, orderItem.sibilingOrderItem.adjustment])
      setOrderItem(prevOrderItem => ({ ...prevOrderItem, adjustment: mergedAdjustment }))
    }
  }, [oItem])

  return (
    <Box style={{ width: '100%' }}>
      {!isPostCheckout && !isConfirmationPage && <InsuranceEligibleCallout orderItem={orderItem} />}
      {isConfirmationPage && estimatedItemDeliveryDate && (
        <div className={styles.containerDeliveryDateDecorated}>
          <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
          <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.containerWrapper}>
          {isMyAccount && (
            <h3
              className={clsx(
                styles.containerTitle,
                styles.containerTitleWithButton,
                !isReorderEligibleItem && isReorderEligibleOrder && styles.containerTitleNonReorderBackground
              )}
            >
              <div className={styles.containerTitleText}>
                <span>{translate('OrderRecap.Item.Status.Label')}</span>
                <span style={{ color: renderOrderItemStatusColor(orderItem.orderItemStatus?.toUpperCase()) }}>
                  {translate(`OrderRecap.Item.Status.${orderItem.orderItemStatus?.toUpperCase()}`)}
                </span>
              </div>
            </h3>
          )}
          {isMyAccount && estimatedItemDeliveryDate && (
            <div
              className={clsx(
                styles.containerDeliveryDateDecorated,
                styles.containerDeliveryDateStretched,
                !isReorderEligibleItem && styles.containerDeliveryDateNonReorder
              )}
            >
              <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
              <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
            </div>
          )}

          <div className={clsx(styles.content, !isMyAccount && !isConfirmationPage && styles.contentVertical)}>
            <OrderRecapImageWrapper>
              <ProductImage attachments={orderItem.attachments} usage="PLP" />
            </OrderRecapImageWrapper>

            <OrderRecapInfoSectionWrapper>
              <ClOrderRecapSection className={styles.dropdownContainer}>
                <div className={styles.contentSection}>
                  <h2 className={styles.contentTitle}>{productName}</h2>
                </div>

                {!!orderItemClData && (
                  <OrderRecapClItemLensDetails
                    orderItemClData={orderItemClData}
                    showQuantityPerEye={true}
                    alwaysShowPrescription={false}
                  />
                )}
              </ClOrderRecapSection>
              {showPromoCodeAppliedDiscount && isPromoCodeApplied && (
                <div className={styles.contentSection}>
                  <OrderRecapSectionLabel>{translate('OrderRecap.Labels.PromoCodeApplied')}:</OrderRecapSectionLabel>
                  <OrderRecapSectionValue>
                    <OrderAdjustmentAmount>
                      <FormattedPriceDisplay min={formattedDiscountedPrice} currency={orderItem.currency} />
                    </OrderAdjustmentAmount>
                  </OrderRecapSectionValue>
                </div>
              )}
              {isSubscriptionEnabled && <SubscriptionSummaryMessage orderItem={orderItem} orderId={orderId} />}
              <OrderRecapPriceSection>
                <OrderRecapPriceLabel>
                  {translate('OrderRecap.Labels.Boxes', { count: toNumber(totalBoxes) })}
                </OrderRecapPriceLabel>
                <PriceWrapper>
                  <OrderRecapItemPrice orderItem={orderItem} quantity={toNumber(totalBoxes)} showDiscountPrice />
                </PriceWrapper>
              </OrderRecapPriceSection>
              {!isMyAccount && !isConfirmationPage && estimatedItemDeliveryDate && (
                <div className={styles.containerDeliveryDate}>
                  <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
                  <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
                </div>
              )}
            </OrderRecapInfoSectionWrapper>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default OrderRecapClItem
