import React from 'react'
import { HandlingFeeDiv } from './OrderTotalSummary.style'
import { StyledTooltip } from '../UI'
import { useTranslation } from 'next-i18next'
import FormattedPriceDisplay from '../formatted-price-display'
import styled from '@mui/material/styles/styled'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface HandlingFeeSummaryProps {
  handlingFeeAmount: number
  handlingFeeTotal: number
  currency: string
}

export const DiscountedHandlingFee = styled('span', {
  name: 'HandlingFee',
  slot: 'Discount',
})(({ theme }) => ({
  color: theme.palette.custom.green,
  fontWeight: 700,
  marginRight: '0.5rem',
}))

const HandlingFeeLabel = styled(HandlingFeeDiv, {
  name: 'HandlingFee',
  slot: 'Label',
})(() => ({
  '& .Tooltip-root': {
    display: 'inline-block',
    marginLeft: '0.25rem',
    transform: 'translateY(3px)',
    width: '1rem',
  },
}))

export const HandlingFeeSummary: React.FC<HandlingFeeSummaryProps> = props => {
  const { handlingFeeAmount, handlingFeeTotal, currency } = props
  const { t } = useTranslation()

  const isFreeHandling = handlingFeeTotal <= 0
  const isDiscounted = handlingFeeAmount !== handlingFeeTotal
  return (
    <>
      <HandlingFeeLabel>
        <span>{t('OrderTotalSummary.Labels.HandlingFee.Text')}</span>
        <StyledTooltip
          contentVariant={{
            type: 'title+text',
            title: t('OrderTotalSummary.Labels.HandlingFee.Tooltip.Title'),
            body: t('OrderTotalSummary.Labels.HandlingFee.Tooltip.Body'),
          }}
          colorVariant={'white'}
          placement={'top'}
        >
          <span>
            <SVGIcon library="validation" name="info" color={'#000'} />
          </span>
        </StyledTooltip>
      </HandlingFeeLabel>
      <HandlingFeeDiv>
        {isDiscounted ? (
          <>
            <DiscountedHandlingFee>
              {isFreeHandling ? (
                t('OrderTotalSummary.Labels.HandlingFee.Free')
              ) : (
                <FormattedPriceDisplay min={handlingFeeTotal} currency={currency} />
              )}
            </DiscountedHandlingFee>
            <s>
              <FormattedPriceDisplay min={handlingFeeAmount} currency={currency} />
            </s>
          </>
        ) : (
          <FormattedPriceDisplay min={handlingFeeTotal} currency={currency} />
        )}
      </HandlingFeeDiv>
    </>
  )
}
