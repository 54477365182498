import { cmsApi } from './query'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import { RootReducerState } from '@redux/rootReducer'

export const getFooterData = createSelector(cmsApi.endpoints.getFooter.select({}), (result) => result.data)

export const getHclPageData = (externalId: string) =>
  createSelector(
    cmsApi.endpoints.getHclPage.select({ externalId }),
    (result) => result.data
  )

//Get Formatter Cms Footer
export const getFormatterFooterData = createSelector(getFooterData, (data) => {
  const footerPlacements = data?.footerPlacements

  const navigation = find(footerPlacements, { name: 'footer_navigation' })
  const newsLetter = find(footerPlacements, { name: 'footer_newsletter' })
  const newsLetterPopup = find(footerPlacements, {
    name: 'footer_newsletter_overlay',
  })
  const crossSiteLinks = find(footerPlacements, {
    name: 'footer_cross_site_links',
  })
  const copyRight = find(footerPlacements, { name: 'footer_copyright' })

  return {
    navigation,
    crossSiteLinks,
    copyRight,
    newsLetter,
    newsLetterPopup,
  }
})

//Get Formatter Cms HCL Cart Data
export const getFormatterHclPageCartData = (externalId: string) =>
  createSelector(getHclPageData(externalId), (data) => {
    const commerceCartPlacements = data?.commercePlacements
    const cartCheckoutDisclaimerTooltip = find(commerceCartPlacements, {
      name: 'Cart_Checkout_disclaimer_tooltip',
    })
    const cartCheckoutEmptyCartBanner = find(commerceCartPlacements, {
      name: 'Cart_Checkout_empty_cart_banner',
    })
    const cartCheckoutBenefitArea = find(commerceCartPlacements, {
      name: 'Cart_Checkout_benefit_area',
    })
    const cartCheckoutPromoBanner = find(commerceCartPlacements, {
      name: 'Cart_Checkout_promo_banner',
    })

    return {
      cartCheckoutDisclaimerTooltip,
      cartCheckoutEmptyCartBanner,
      cartCheckoutBenefitArea,
      cartCheckoutPromoBanner,
    }
  })

export const preFooterSelector = (state: RootReducerState) => state.cms.preFooter

export const menuLoadingSelector = (state: RootReducerState) => state.cms.menuLoading

export const menuLoadedSelector = (state: RootReducerState) => state.cms.menuLoaded

export const headerLoadingSelector = (state: RootReducerState) => state.cms.headerLoading

export const headerLoadedSelector = (state: RootReducerState) => state.cms.headerLoaded

export const footerLoadingSelector = (state: RootReducerState) => state.cms.footerLoading

export const footerLoadedSelector = (state: RootReducerState) => state.cms.footerLoaded

export const contentMegaMenuDataSelector = (state: RootReducerState) => state.cms.contentMegaMenu
