import * as React from 'react'
import { useTranslation } from 'next-i18next'
import styled from '@mui/material/styles/styled'
import { StyledInput } from '../../../../../components/FindStore/FindStore.style'

export interface ForterPaymentMethodProps {
  value?: string
  onInputChange?: any
}

const ForterPaymentMethodContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  border: `1px solid ${theme.palette.custom.black}`,
}))

const ForterPaymentMethodInputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(5),
  borderBottom: `1px solid ${theme.palette.custom.black}`,
}))

const ForterPaymentMethod: React.FC<ForterPaymentMethodProps> = ({
  value,
  onInputChange,
}: ForterPaymentMethodProps) => {
  const { t } = useTranslation()
  return (
    <ForterPaymentMethodContainer>
      <ForterPaymentMethodInputWrapper>
        <StyledInput
          error={false}
          value={value}
          onChange={(e) => onInputChange(e.target.value)}
          placeholder={t('PaymentMethodSelection.Labels.WebId')}
        />
      </ForterPaymentMethodInputWrapper>
    </ForterPaymentMethodContainer>
  )
}

export default ForterPaymentMethod
