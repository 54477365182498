import styled from '@mui/material/styles/styled'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Accordion from '@mui/material/Accordion'
import { GridItem, StyledButtonAsLink, StyledTypography, TextField } from '../../../components/UI'
import { Link } from '@mui/material'

export const PaymentMethodSelectionWrapper = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'Wrapper',
})(({}) => ({}))

export const PaymentMethodAccordion = styled(Accordion, {
  name: 'PaymentMethodSelection',
  slot: 'Accordion',
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  marginBottom: `${theme.spacing(6)}`,
  boxShadow: expanded
    ? `0px 0px 0px 1px ${theme.palette.custom.blue}`
    : `0px 0px 0px 1px ${theme.palette.custom.light2.grey}`,
  '&:first-of-type': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingTop: 0,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  '&.ApplePay': {
    '.payment-menthod-selection__value svg': {
      backgroundColor: theme.palette.custom.black,
      padding: `${theme.spacing(0.25)}px`,
      borderRadius: '5px',
    },
  },
}))

export const PaymentMethodDescription = styled(StyledTypography, {
  name: 'PaymentMethodSelection',
  slot: 'Description',
  shouldForwardProp: prop => prop !== 'isKlarna',
})<{ isKlarna: boolean }>(({ theme, isKlarna }) => ({
  flex: '1',
  color: theme.palette.text.dark.primary,
  alignSelf: 'flex-start',
  marginTop: isKlarna ? `${theme.spacing(4)}` : 0,

  [theme.breakpoints.up('sm')]: {
    // alignSelf: 'center',
    marginTop: 0,
  },
}))

export const PaymentMethodCardDetails = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'cardDetails',
})(({ theme }) => ({
  marginRight: theme.spacing(6),
  color: theme.palette.text.dark.primary,
  whiteSpace: 'nowrap',
}))

export const PaymentMethodCardExpired = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'cardExpired',
})(({ theme }) => ({
  color: theme.palette.color.error,

  [theme.breakpoints.up(745)]: {
    marginLeft: theme.spacing(6),
  },

  [theme.breakpoints.up('md')]: {
    marginLeft: 0,
  },

  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(6),
  },
}))

export const PaymentTitleHeaderWrapper = styled(Accordion, {
  name: 'TitleHeader',
  slot: 'Wrapper',
})(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  boxShadow: 'none',
  minWidth: '345px',
  [theme.breakpoints.up('md')]: {
    minWidth: '569px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  '&:first-of-type': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '&.Mui-expanded': {
    padding: '16px 0',
  },
}))

export const PaymentMethodSummaryWrapper = styled(AccordionSummary, {
  name: 'PaymentMethodSelection',
  slot: 'SummaryWrapper',
  shouldForwardProp: prop => prop !== 'isKlarna',
})<{ isKlarna: boolean }>(({ theme, isKlarna }) => ({
  minHeight: isKlarna ? '104px' : '56px',
  padding: 0,
  fontWeight: 'bold',

  [theme.breakpoints.up('sm')]: {
    minHeight: '56px',
  },

  '.MuiAccordionSummary-content': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  },

  '&.Mui-expanded': {
    boxShadow: `0px 1px 0px 0px ${theme.palette.custom.blue}`,
  },
}))

export const PaymentMethodDetailsContainer = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'detailsContainer',
  shouldForwardProp: prop => prop !== 'isStoredCard',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',

  [theme.breakpoints.up(745)]: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

export const PaymentMethodSelectionDetailsWrapper = styled(AccordionDetails, {
  name: 'PaymentMethodSelection',
  slot: 'DetailsWrapper',
})(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(8)} ${theme.spacing(4)}`,
}))

export const PaymentMethodCcardFormWrapper = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'CCardForm',
})(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1.5),
}))

export const PaymentMethodTotalPrice = styled(StyledTypography, {
  name: 'PaymentMethodSelection',
  slot: 'totalPrice',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 'bold',
  fontSize: 20,
  textTransform: 'uppercase',
}))

export const PaymentMethodSupportText = styled(StyledTypography, {
  name: 'PaymentMethodSelection',
  slot: 'supportText',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

export const PaymentIconWrapperRow = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'iconWrapper',
})(({ theme }) => ({
  display: 'none',
  gap: theme.spacing(2),

  [theme.breakpoints.up(745)]: {
    display: 'flex',
  },

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },

  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}))

export const PaymentIconWrapperColumn = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'iconWrapper',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignSelf: 'baseline',
  marginTop: `${theme.spacing(1)}`,

  [theme.breakpoints.up(745)]: {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },

  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

export const PaymentMethodSelectionSelectWrapper = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'SelectWrapper',
})(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  '& > div': {
    flex: 1,
  },
  marginBottom: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    width: 245,
  },
}))

export const PaymentMethodSelectionCVVInputWrapper = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'cvvInputWrapper',
})(({}) => ({}))

export const PaymentMethodSelectionFormInput = styled(TextField, {
  name: 'PaymentMethodSelection',
  slot: 'FormInput',
})(() => ({
  '&& .MuiFormHelperText-root': {
    position: 'static !important',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const PaymentMethodSelectionFormHelper = styled('span', {
  name: 'PaymentMethodSelection',
  slot: 'FormHelper',
})(({ theme }) => ({
  display: 'block',
  fontWeight: 'normal',
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.light.secondary,
}))

export const PaymentMethodSelectionCVVLink = styled(Link, {
  name: 'PaymentMethodSelection',
  slot: 'cvvLink',
})(({ theme }) => ({
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  color: theme.palette.text.dark.secondary,
}))

export const PaymentMethodSelectionCVVHint = styled(GridItem, {
  name: 'PaymentMethodSelection',
  slot: 'cvvHint',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(6),
  fontSize: '0.75rem',
}))

export const PaymentMethodSelectionCVVImage = styled('img', {
  name: 'PaymentMethodSelection',
  slot: 'cvvImage',
})(({ theme }) => ({
  width: 72,
  height: 52,
  marginLeft: theme.spacing(4),
}))

export const PaymentMethodSelectionCVVText = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'cvvText',
})(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(6),
  },
}))

export const PaymentMethodSelectionFormInputWrapper = styled(GridItem, {
  name: 'PaymentMethodSelection',
  slot: 'FormInputWrapper',
})(({}) => ({}))

export const PaymentMethodSelectionFormSelect = styled(TextField, {
  name: 'PaymentMethodSelection',
  slot: 'FormSelect',
})(() => ({}))

export const PaymentMethodSelectionFakeRadio = styled('span', {
  name: 'PaymentMethodSelection',
  slot: 'FakeRadio',
  shouldForwardProp: prop => prop !== 'isKlarna',
})<{ checked: boolean; isKlarna: boolean }>(({ checked, isKlarna, theme }) => ({
  borderRadius: 18,
  color: theme.palette.custom.greyLightmode,
  width: 16,
  height: 16,
  maxWidth: 16,
  minWidth: 16,
  display: 'inherit',
  borderWidth: 1,
  borderColor: theme.palette.custom.cyprus,
  borderStyle: 'solid',
  position: 'relative',
  marginRight: `${theme.spacing(5)}`,
  alignSelf: isKlarna ? 'flex-start' : 'center',
  marginTop: isKlarna ? `${theme.spacing(5)}` : 0,

  [theme.breakpoints.up('sm')]: {
    alignSelf: 'center',
    marginTop: 0,
  },

  '& > svg': {
    display: checked ? 'block' : 'none',
    position: 'absolute',
    right: '2px',
    top: '2px',
  },
}))

export const StyledPaypalDisclaimer = styled(StyledTypography, {
  name: 'Paypal',
  slot: 'Disclaimer',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
}))

export const StyledPaymentMethodAdornmentLink = styled(StyledButtonAsLink, {
  name: 'PaymentMethodSelection',
  slot: 'AdornmentLink',
})(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: 12,
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
}))

export const TotalZeroLabel = styled('div')(() => ({
  fontSize: '14px',
  lineHeight: '20px',
}))

export const TotalZeroButtonWrapper = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'TotalZeroButtonWrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
}))

export const TotalZeroContainer = styled('div', {
  name: 'PaymentMethodSelection',
  slot: 'TotalZeroContainer',
})(({ theme }) => ({
  width: '30vw',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const SelectPaymentContainer = styled(GridItem, {
  name: 'PaymentMethod',
  slot: 'SelectPaymentContainer',
  shouldForwardProp: prop => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible }) => ({
  display: isVisible ? 'block' : 'none',
}))
