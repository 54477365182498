import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

export const DashboardWishlistItems = styled(Typography, {
  name: 'DashboardWishlist',
  slot: 'Typography',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
}))

export const DashboardWishlistProductDetails = styled('div', {
  name: 'DashboardWishlist',
  slot: 'ProductDetails',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  flexGrow: 0,
  rowGap: theme.spacing(0.5),
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.light.primary,
}))

export const DashboardWishlistProductName = styled('span', {
  name: 'DashboardWishlist',
  slot: 'ProductName',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.body1.fontSize,
  color: theme.palette.text.dark.primary,
}))

export const DashboardWishlistBrandName = styled('span', {
  name: 'DashboardWishlist',
  slot: 'BrandName',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.h4.fontSize,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
}))

export const DashboardWishlistRowPrice = styled('span', {
  name: 'DashboardWishlist',
  slot: 'RowPrice',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  '.product-price': {
    flexDirection: 'row-reverse',
    margin: theme.spacing(2),
  }
}))