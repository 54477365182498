import styled from '@mui/material/styles/styled'

export const StyledFormContainer = styled('div', {
  name: 'ChangePassword',
  slot: 'Form'
})(({ theme }) => ({
  '& .NewPassword-Container': {
    marginBottom: theme.spacing(6),
  }
}))


