import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { getPopperSX, StyledCurrentDate, StyledDatePickerDays } from './StyledDatepickerComponents'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const StyledDatePicker = (props: DatePickerProps<Date, Date>) => {
  const { value, onChange, views = [] } = props
  const isValueExists = !!new Date(value || '').getTime()

  const [currentDate, setCurrentDate] = useState(isValueExists ? (value as Date) : new Date())
  const [view, setView] = useState<'day' | 'month' | 'year'>('day')
  const theme = useTheme()

  const controllersColor = theme.palette.custom.cyprus
  const showMonthController = !views.includes('month') || view === 'day'

  const getDatePickerDays = (_day, _selectedDays, pickersDayProps) => {
    const isDateManuallySelected =
      isValueExists &&
      value?.getMonth() === currentDate.getMonth() &&
      value?.getFullYear() === currentDate.getFullYear()

    return <StyledDatePickerDays isDateSelected={isDateManuallySelected} {...pickersDayProps} />
  }

  const getMonthController = props => (
    <>
      <SVGIcon library="arrow" name="arrow-left" color={controllersColor} {...props} style={{ cursor: 'pointer' }} />
      <StyledCurrentDate type="month">{currentDate.toLocaleString('default', { month: 'long' })}</StyledCurrentDate>
    </>
  )

  const getRightArrowButton = props => (
    <SVGIcon library="arrow" name="arrow-right" htmlColor={controllersColor} {...props} style={{ cursor: 'pointer' }} />
  )

  const getSwitchViewButton = () => {
    // FIXME: change to prop config
    const ArrowIcon =
      view === 'day'
        ? () => <SVGIcon library="arrow" name="arrow-down" color={controllersColor} />
        : () => <SVGIcon library="arrow" name="arrow-up" color={controllersColor} />
    const currentYear = currentDate.getFullYear()

    return (
      <>
        <StyledCurrentDate type="year">{currentYear}</StyledCurrentDate>
        <ArrowIcon />
      </>
    )
  }

  const onCurrentViewDateChange = (date, eventType: 'month' | 'year') => {
    const currentDate = new Date(date)

    setCurrentDate(prevValue => {
      const previousYear = prevValue.getFullYear()
      const isSameYear = previousYear === currentDate.getFullYear()
      const shouldKeepPreviousDateYear = !isSameYear && view === 'year' && eventType !== 'year'

      return shouldKeepPreviousDateYear ? new Date(currentDate.setFullYear(previousYear)) : currentDate
    })
  }

  const onChangeDate = (date, selectionState) => {
    if (view !== 'day') {
      return
    }

    onChange(date, selectionState)
  }

  const onOpenDatepicker = () => {
    setCurrentDate(isValueExists ? (value as Date) : new Date())
  }

  const datePickerProps = {
    ...props,
    onChange: onChangeDate,
    onOpen: onOpenDatepicker,
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        components={{
          OpenPickerIcon: () => <SVGIcon library="global" name="calendar" color={theme.palette.text.dark.primary} />,
          LeftArrowButton: getMonthController,
          RightArrowButton: getRightArrowButton,
          SwitchViewButton: getSwitchViewButton,
        }}
        renderDay={getDatePickerDays}
        PopperProps={{
          sx: getPopperSX({
            theme,
            showMonthController,
          }),
        }}
        onMonthChange={date => onCurrentViewDateChange(date, 'month')}
        onYearChange={date => {
          const mergedDate = new Date(date.setMonth(currentDate.getMonth()))

          onCurrentViewDateChange(mergedDate, 'year')
        }}
        onViewChange={setView}
        {...datePickerProps}
      />
    </LocalizationProvider>
  )
}
