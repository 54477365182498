import styled from '@mui/material/styles/styled'

export const PaymentMethodSection = styled('div', {
  name: 'PaymentMethod',
  slot: 'Section',
  shouldForwardProp: prop => prop !== 'isCheckoutPage',
})<{ isCheckoutPage?: boolean }>(({ theme, isCheckoutPage }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  minHeight: '52px',
  marginTop: `${!isCheckoutPage ? theme.spacing(4) : 0}`,

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    padding: `${theme.spacing(4)} 0`,
  },
}))

export const PaymentMethodItemContent = styled('div', {
  name: 'PaymentMethod',
  slot: 'ItemContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(6),
  },
}))

export const PaymentMethodContentCheckoutLinks = styled('div', {
  name: 'PaymentMethod',
  slot: 'ContentCheckoutLinks',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
  columnGap: theme.spacing(2),
  fontSize: 10,
  color: theme.palette.text.light.primary,
  [theme.breakpoints.up('sm')]: {
    columnGap: theme.spacing(4),
  },
}))

export const PaymentMethodRightItemContent = styled('div', {
  name: 'PaymentMethod',
  slot: 'RightItemContent',
})({
  display: 'flex',
  gridColumn: 'span 6',
  alignItems: 'center',
  justifyContent: 'end',
})

export const PaymentMethodSpanWrapper = styled('span')(({ theme }) => ({
  fontSize: '10px',
  fontWeight: '700',
  lineHeight: '2.1',
  color: theme.palette.text.light.primary,
  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

export const PaymentMethodImageWrapper = styled('div', {
  name: 'PaymentMethod',
  slot: 'ImageWrapper',
})(({ theme }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(4),
  columnGap: theme.spacing(2),

  '&.icon--payment-methods': {
    justifyContent: 'center',
    marginBottom: theme.spacing(5) 
  },

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    paddingBottom: 0,
    borderBottom: 'none',
  },
}))
