import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProductSoldOutStatus, IProduct } from '../../../types/product'
import { OrderItem } from '../../../types/order'
import { useAddOrderItem } from '../hooks/useAddOrderItem'
import CtaPdp from './cta'
import { currentProductBundleSelector } from '../../../features/product/selector'
import { roxApi } from '../../../features/rox/roxApi'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { preloadRxDataSelector } from '../../../redux/selectors/site'
import { PartNumberAlgoliaPrice } from '../../../foundation/algolia/algoliaPrice'
import { SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION } from '@redux/actions/subscription'
import { SubscriptionInfo } from '@typesApp/subscription'
import { toggleAddContactLensesToCartError, toggleAddSubscriptionToCartError } from '@features/product/slice'
import { subscriptionConfigSelector } from '@features/subscription/selector'

export interface ProductCtaContainerProps {
  error?: boolean
  isLoading?: boolean
  currentProduct: IProduct
  subscriptionInfo?: SubscriptionInfo
  roxable?: boolean
  soldOutStatus: ProductSoldOutStatus
  payload?: any
  partNumber?: string
  productQuantity?: string[]
  algoliaPrices?: PartNumberAlgoliaPrice
  productInCart?: OrderItem
  ctaRef: React.RefObject<HTMLDivElement>
  addToCartFillType?: 'outline' | 'fill'
  isStickyBar?: boolean
  hideNeedPrescriptionText?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
  isEditing?: boolean
  isSelectLensesBusy?: boolean
  setSelectLensesBusy?: React.Dispatch<boolean>
  onAddToCartClick?: (callback?: any) => void
}

const ProductCtaContainer: FC<ProductCtaContainerProps> = (props: ProductCtaContainerProps) => {
  const {
    error,
    isLoading = false,
    currentProduct,
    soldOutStatus,
    ctaRef,
    roxable,
    addToCartFillType,
    productQuantity,
    algoliaPrices,
    isStickyBar,
    hideNeedPrescriptionText,
    variant,
    isSelectLensesBusy,
    setSelectLensesBusy,
    isEditing,
    onAddToCartClick,
  } = props
  const dispatch = useDispatch()
  const { langId, isRXEnabled } = useStoreIdentity()

  const preloadRxData = useSelector(preloadRxDataSelector)
  const { orderItem } = useSelector(currentProductBundleSelector)
  const subscriptionConfig = useSelector(subscriptionConfigSelector)
  const subscriptionItemInfo = subscriptionConfig.subscribedItems?.find(
    item => item.identifier === currentProduct.partNumber && item.active
  )

  const { addToCart } = useAddOrderItem(
    orderItem,
    currentProduct,
    currentProduct?.partNumber,
    productQuantity,
    subscriptionItemInfo,
    algoliaPrices
  )

  useEffect(() => {
    dispatch(toggleAddSubscriptionToCartError(''))
    dispatch(toggleAddContactLensesToCartError(false))
  }, [])

  useEffect(() => {
    if (isRXEnabled && preloadRxData && currentProduct) {
      dispatch(
        roxApi.endpoints.roxLensData.initiate({
          productId: currentProduct.partNumber,
          langId,
        })
      )
    }
    dispatch(SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION(''))
  }, [currentProduct])

  return (
    <CtaPdp
      ref={ctaRef}
      isEditing={isEditing}
      isLoading={isLoading}
      soldOutStatus={soldOutStatus}
      product={currentProduct}
      error={error}
      addToCart={addToCart}
      onClickCta={onAddToCartClick}
      roxable={roxable}
      addToCartFillType={addToCartFillType}
      isStickyBar={isStickyBar}
      hideNeedPrescriptionText={hideNeedPrescriptionText}
      variant={variant}
      isSelectLensesBusy={isSelectLensesBusy}
      setSelectLensesBusy={setSelectLensesBusy}
    />
  )
}

export default ProductCtaContainer
