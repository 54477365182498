import { OrderItem, OrderItemsAttachments } from '../../types/order'
import React from 'react'
import OrderRecapRxItem, { OrderRecapRxItemProps } from './OrderRecapRxItem'
import OrderRecapItem, { OrderRecapItemPropsBase } from './OrderRecapItem'
import OrderRecapClItem from './OrderRecapClItem'
import OrderRecapAccessoryItem from './OrderRecapAccessoryItem'
import { isContactLensesProduct } from '../../utils/product'
import { isRxFrame } from '../../utils/isRxOrder'
import { isAccessoriesOrderItem, isClAccessoriesOrderItem } from '../../utils/order'
import OrderService from '../../foundation/apis/transaction/order.service'

export interface OrderRecapItemProps {
  orderItems: OrderItem[]
  orderItemsAttachments: OrderItemsAttachments
  orderId: string
  estimatedDeliveryDate?: string
  isMyAccount?: boolean
  isReorderEligibleOrder?: boolean
}

const OrderRecapItems: React.FC<OrderRecapItemProps> = ({
  orderItems,
  orderItemsAttachments,
  estimatedDeliveryDate,
  isMyAccount = false,
  orderId,
  isReorderEligibleOrder = false,
}) => {
  return (
    <>
      {orderItems?.map((orderItem, index) => {
        const key = `${orderItem.productId}-${index}`
        const props: OrderRecapItemPropsBase = {
          estimatedDeliveryDate,
          orderItem: {
            ...orderItem,
            attachments: orderItemsAttachments[orderItem.productId] || orderItem.attachments,
          },
          isMyAccount,
          orderId,
          orderContainsNonReorderableItem: OrderService.orderContainsNonReorderableItem(orderItems),
          isReorderEligibleOrder,
        }

        if (isContactLensesProduct(orderItem)) {
          return <OrderRecapClItem key={key} {...props} />
        }
        if (isRxFrame(orderItem['orderItemExtendAttribute'])) {
          return <OrderRecapRxItem key={key} {...(props as OrderRecapRxItemProps)} />
        }
        if (isAccessoriesOrderItem(orderItem.attributes) || isClAccessoriesOrderItem(orderItem.attributes)) {
          return <OrderRecapAccessoryItem key={key} {...props} />
        }
        return <OrderRecapItem key={key} {...props} />
      })}
    </>
  )
}
export default OrderRecapItems
