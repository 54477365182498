import React from 'react'
import { LoadingIndicator } from './LoadingIndicator'
import styles from '../styles/index.module.scss'

export interface StatusProps {
  isLoading: boolean
  isError: boolean
  unavailableTitle: string
  unavailableMessage: string
}

export const Status: React.FC<StatusProps> = props => {
  const { isLoading, isError, unavailableTitle, unavailableMessage } = props

  if (!isLoading && !isError) {
    return null
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && isError && (
        <div className={styles.status}>
          <p className={styles.title}>{unavailableTitle}</p>
          <p className={styles.message}>{unavailableMessage}</p>
        </div>
      )}
    </>
  )
}
