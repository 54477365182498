import React, { MouseEventHandler, ReactNode } from 'react'
import { Box, DialogContent } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { Button } from '../Button'
import { StyledDialog, StyledDialogActions, StyledDialogCloseButton, StyledDialogTitle } from './ModalDialog.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface ModalDialogProps {
  open: boolean
  onClose: MouseEventHandler
  title?: string
  hideFooter?: boolean
  footer?: ReactNode
  children?: ReactNode
  fullHeightOnSmallScreens?: boolean
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  open,
  onClose,
  title,
  hideFooter = false,
  footer,
  children,
  fullHeightOnSmallScreens = true,
}) => {
  const header = title ? <StyledDialogTitle variant="h4">{title}</StyledDialogTitle> : undefined

  return (
    <StyledDialog open={open} onClose={onClose} fullHeightOnSmallScreens={fullHeightOnSmallScreens}>
      <Box sx={{ paddingLeft: 6, marginBottom: 4 }}>
        {header}
        <StyledDialogCloseButton aria-label="close" onClick={onClose}>
          <SVGIcon library="close" name="close" />
        </StyledDialogCloseButton>
      </Box>

      <DialogContent>{children && children}</DialogContent>

      {!hideFooter && (
        <StyledDialogActions>
          {footer && footer}
          {!footer && <ModalFooter onClose={onClose} />}
        </StyledDialogActions>
      )}
    </StyledDialog>
  )
}

export interface ModalFooterProps {
  onClose: MouseEventHandler
}

const ModalFooter: React.FC<ModalFooterProps> = ({ onClose }) => {
  const { t } = useTranslation()

  return <Button onClick={onClose}>{t('ModalDialog.CTA-Close')}</Button>
}
