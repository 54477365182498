import React, { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { PreLoader } from './PreLoader'
import { LoaderProps } from './preloader.type'

interface PreLoaderWithButtonProps extends PropsWithChildren<unknown> {
  fill?: LoaderProps['fill']
  busy: boolean
}

export const PreLoaderWithButton: React.FC<PreLoaderWithButtonProps> = props => {
  const { busy, fill, children } = props
  const spacer = <Box sx={{ width: theme => theme.spacing(4) }} />

  return (
    <>
      {busy ? <PreLoader fill={fill} withButton /> : spacer}
      {children}
      {spacer}
    </>
  )
}
