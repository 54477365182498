/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import FormattedPriceDisplay from './FormattedPriceDisplay'

export default FormattedPriceDisplay
