import { useState, useEffect } from 'react'

import { getOrderItemContactLensesData } from '../../utils/order'
import { OrderItem } from '../../types/order'
import { OrderItemContactLensData } from '../../types/cart'
import sum from 'lodash/sum'
import { isContactLensesProduct } from '@utils/product'
/**
 * Contact lens product info data hook
 * displays contact lens order item info,quantity
 * @param { OrderItem } orderItem current cart order item
 */

export const getOrderItemClData = (orderItem?: OrderItem) => {
  const orderItemClData: OrderItemContactLensData | null = !!orderItem && isContactLensesProduct(orderItem)
    ? getOrderItemContactLensesData(orderItem, ['x_productId', 'x_eye', 'x_partNumber', 'x_dominance'])
    : null
  return orderItemClData
}

export const useContactLensCartItemData = ({ orderItem }: { orderItem?: OrderItem | OrderItem[] }) => {
  const [orderItemClData, setOrderItemClData] = useState<OrderItemContactLensData | null>({
    left: {
      data: null,
      quantity: null,
      orderItemId: null,
    },
    right: {
      data: null,
      quantity: null,
      orderItemId: null,
    },
  })

  const [totalBoxes, setTotalBoxes] = useState<number | null>(null)

  useEffect(() => {
    const hasMultipleOrderItems = Array.isArray(orderItem)
    let itemClData
    if (!hasMultipleOrderItems) {
      itemClData = getOrderItemClData(orderItem)
    } else {
      orderItem.forEach(item => {
        const clData = getOrderItemClData(item)
        const eye = clData?.left.quantity ? 'left' : 'right'
        itemClData = {
          ...itemClData,
          [eye]: clData?.[eye],
        }
      })
    }
    const rightEyeQuantity = itemClData?.right?.quantity
    const leftEyeQuantity = itemClData?.left?.quantity

    const totalBoxes = sum([parseInt(leftEyeQuantity || '0'), parseInt(rightEyeQuantity || '0')])

    setOrderItemClData(itemClData)
    setTotalBoxes(totalBoxes)
  }, [orderItem])

  return {
    orderItemClData,
    setOrderItemClData,
    totalBoxes,
  }
}
