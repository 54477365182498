import { Accordion, AccordionSummary, styled } from '@mui/material'
import { Button, TextField } from '../../../components/UI'

export const StyledPromoCodeAccordion = styled(Accordion, {
  name: 'PromoCode',
  slot: 'Accordion',
})(({ }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  padding: '0 0',

  '.MuiAccordionDetails-root': {
    padding: '0 0'
  },

  '&.Mui-expanded': {
    margin: 0,
  },
  
  '::before': {
    display: 'none'
  },
}))

export const StyledPromoCodeAccordionSummary = styled(AccordionSummary, {
  name: 'PromoCode',
  slot: 'AccordionSummary',
})(({ }) => ({
  justifyContent: 'space-between',
  padding: 0,

  '& > div': {
    '&:nth-of-type(1)': {
      margin: 0,
      flex: 'initial',
    },

    '&:nth-of-type(2)': {
      margin: 0,
      padding: 0,
      flex: 'initial',
    },
  }
}))

export const StyledPromoCodeTextField = styled(TextField, {
  name: 'PromoCode',
  slot: 'TextField',
})(({  }) => ({
}))

export const StyledApplyButton = styled(Button, {
  name: 'ApplyButton',
  slot: 'PromoCodeContainer'
})(({ theme }) => ({
  flex: 1,
  marginLeft: 0,
  marginTop: `${theme.spacing(1)}`,
  marginBottom: 0,
  width: 'auto'
}))

export const StyledRemoveLink = styled('div', {
  name: 'RemoveCode',
  slot: 'Link'
})(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  textDecoration: 'underline',
  fontWeight: 700,
  fontSize: theme.typography.subtitle1.fontSize,
}))
