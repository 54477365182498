import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Order } from '../../../types/order'
import OrderService from '../../../foundation/apis/transaction/order.service'
import {
  OrderStatusPrescriptionLabel,
  OrderStatusShippedLabel,
  OrderStatusContainer,
  StyledAnchorCTA,
  StyledText,
} from './OrderStatusInfo.style'
import { renderOrderStatusColor } from '@constants/order'
import { useSite } from '@foundation/hooks/useSite'
import { downloadInvoice, isOrderInvoiceAvailable } from '@features/orderhistory/utils'
import { Loader } from '@components/UI/Loader'
import { StyledAnchor } from '@components/UI/Anchor'

interface StatusLabelProps {
  order: Order
  trackingNumbers: string[]
  showTrackingNumber: boolean
  isColoredText?: boolean
}

const StatusLabel: React.FC<StatusLabelProps> = ({
  order,
  trackingNumbers,
  showTrackingNumber,
  isColoredText = false,
}) => {
  const { t: translate } = useTranslation()
  const { orderStatus } = order
  const isPendingPrescriptionStatus = OrderService.isPendingPrescription(order)
  const isShippedStatus = OrderService.isShipped(order)
  const isCompletedStatus = OrderService.isCompleted(order)

  if (isPendingPrescriptionStatus) {
    return <OrderStatusPrescriptionLabel>{translate(`Order.Status_.${orderStatus}`)}</OrderStatusPrescriptionLabel>
  }
  if (isShippedStatus) {
    return (
      <OrderStatusShippedLabel>
        <StyledText style={isColoredText ? { color: renderOrderStatusColor(orderStatus) } : undefined} isBold>
          {translate(`Order.Status_.${orderStatus}`)}
        </StyledText>
        {showTrackingNumber && (
          <StyledText>{translate('Order.TrackingNumber', { value: trackingNumbers.join(', ') })}</StyledText>
        )}
      </OrderStatusShippedLabel>
    )
  }
  if (isCompletedStatus) {
    return <OrderStatusShippedLabel>{translate('Order.StateCompleted')}</OrderStatusShippedLabel>
  }
  return (
    <StyledText style={isColoredText ? { color: renderOrderStatusColor(orderStatus) } : undefined} isBold>
      {translate(`Order.Status_.${orderStatus}`)}
    </StyledText>
  )
}

export interface OrderStatusInfoProps {
  order: Order
  isColoredText?: boolean
}

export const NewOrderStatusInfo: React.FC<OrderStatusInfoProps> = ({ order, isColoredText = false }) => {
  const { t: translate } = useTranslation()
  const { mySite } = useSite()
  const { orderExtendAttribute } = order
  const [isDownloading, setDownloading] = useState(false)
  const trackingNumbers = OrderService.getTrackingNumbers(orderExtendAttribute)
  const isInvoiceAvailable = isOrderInvoiceAvailable(order)

  const isPendingPrescriptionStatus = OrderService.isPendingPrescription(order)
  const showTrackingNumber = trackingNumbers?.length > 0 && !OrderService.isShippedSettled(order)

  if (isDownloading) {
    return <Loader />
  }

  return (
    <OrderStatusContainer>
      <StatusLabel
        order={order}
        isColoredText={isColoredText}
        trackingNumbers={trackingNumbers}
        showTrackingNumber={showTrackingNumber}
      />

      {isPendingPrescriptionStatus && (
        <StyledAnchorCTA href={`mailto:${translate('OrderDetails.Labels.CustomerServiceEmail')}`}>
          {translate('Order.PendingPrescriptionCta')}
        </StyledAnchorCTA>
      )}

      {isInvoiceAvailable && (
        <StyledAnchor
          variant="black"
          href={`#orderItem_${order.orderId}`}
          onClick={async () => {
            setDownloading(true)
            await downloadInvoice(mySite.storeId, order.orderId)
            setDownloading(false)
          }}
        >
          {translate('OrderDetails.Actions.DownloadInvoice')}
        </StyledAnchor>
      )}
    </OrderStatusContainer>
  )
}
