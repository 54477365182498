import { NewOrderDetailsWrapper } from './NewOrderDetails.style'
import React, { FC, useEffect } from 'react'
import { isRxCart, parseOrderItems } from '../../../utils/isRxOrder'
import { useOrderDetails, isOrderDetailsLoading } from '../../../hooks/useOrderDetails'

import { FETCH_ORDER_DETAILS_ACTION } from '../../../redux/actions/orderDetails'
import { useDispatch } from 'react-redux'
import { Loader } from '../../../components/UI'
import { NewOrderDetailsHeader } from './NewOrderDetailsHeader'
import { NewOrderDetailsItemList } from './NewOrderDetailsItemList'
import { Order } from '../../../types/order'
import { NewOrderDetailsFooter } from './NewOrderDetailsFooter'
import { useSite } from '@foundation/hooks/useSite'

export interface OrderDetailsProps {
  orderId: Order['orderId']
}

const NewOrderDetails: FC<OrderDetailsProps> = ({ orderId }) => {
  const dispatch = useDispatch()
  const orderDetails = useOrderDetails(orderId) || {}
  const isLoading = isOrderDetailsLoading(orderId)
  const { mySite } = useSite()

  useEffect(() => {
    if (orderId) {
      dispatch(FETCH_ORDER_DETAILS_ACTION({ orderId, storeId: mySite.storeID, skipErrorSnackbar: true }))
    }
  }, [orderId])

  if (isLoading) {
    return <Loader />
  }

  const orderItems = isRxCart(orderDetails?.orderExtendAttribute)
    ? parseOrderItems(orderDetails.orderItem)
    : orderDetails.orderItem

  return (
    <NewOrderDetailsWrapper>
      <NewOrderDetailsHeader orderDetails={orderDetails} />
      <NewOrderDetailsItemList orderDetails={orderDetails} orderItems={orderItems} />
      <NewOrderDetailsFooter orderDetails={orderDetails} orderItems={orderItems} />
    </NewOrderDetailsWrapper>
  )
}

export default NewOrderDetails
