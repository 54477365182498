import OrderReturnHeader from '@layouts/OrderReturnHeader'
import ReducedHeader from '@layouts/ReducedHeader'
import React from 'react'
import Header from './Header'

type HeaderSelectorProps = {
  pageType: string | undefined
  orderConfirmationPage: boolean
}

const HeaderSelector: React.FC<HeaderSelectorProps> = ({ pageType, orderConfirmationPage }) => {
  if (pageType === 'orderReturnSelect' || pageType === 'orderReturnConfirm' || pageType === 'orderReturnPrint')
    return <OrderReturnHeader />
  if (pageType === 'checkout' && !orderConfirmationPage) return <ReducedHeader />

  return <Header />
}

export default HeaderSelector
