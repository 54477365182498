import React from 'react'
//COMPONENTS
import { CheckoutProgress } from './components/CheckoutProgress'
import NavigationLogo from '../Header/components/NavigationBar/components/NavigationLogo'
import { CheckoutMobileBack } from './ReducedHeaderMobileBack'
//STYLES
import {
  ReducedHeaderWrapper,
  ReducedHeaderNavigationLogoWrapper,
  ReducedHeaderCheckoutDisclaimer,
  ReducedHeaderMobileWrapper,
  ReducedHeaderMobileBottom,
  ReducedHeaderMobileTop,
} from './reducedheader.style'

import { useTranslation } from 'next-i18next'

const ReducedHeader: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <ReducedHeaderMobileWrapper>
        <ReducedHeaderMobileTop>
          <CheckoutMobileBack />
          <NavigationLogo />
          <ReducedHeaderCheckoutDisclaimer>
            <span>{t('ReducedHeader.Labels.SecureCheckout')}</span>
          </ReducedHeaderCheckoutDisclaimer>
        </ReducedHeaderMobileTop>
        <ReducedHeaderMobileBottom>
          <CheckoutProgress />
        </ReducedHeaderMobileBottom>
      </ReducedHeaderMobileWrapper>
      <ReducedHeaderWrapper>
        <ReducedHeaderNavigationLogoWrapper>
          <NavigationLogo />
        </ReducedHeaderNavigationLogoWrapper>
        <CheckoutProgress />
        <ReducedHeaderCheckoutDisclaimer>
          <span>{t('ReducedHeader.Labels.SecureCheckout')}</span>
        </ReducedHeaderCheckoutDisclaimer>
      </ReducedHeaderWrapper>
    </>
  )
}

export default ReducedHeader
