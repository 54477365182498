import React, { useState } from 'react'
import styled from 'styled-components'
import { StyledTypography } from '../UI'
import ReassuranceItemModal from './ReassuranceItemModal'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface IProps {
  title: string
  subtitle: string
  leftComponent: React.ReactElement
  rightComponent?: React.ReactElement
  content: string
  modalIcon?: React.ReactElement
}

const StyledContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 20px 20px;
  cursor: pointer;
  min-height: 65px;
`

const StyledTitle = styled(StyledTypography)`
  text-align: left;
`

const StyledTitleDiv = styled('div')`
  align-self: left;
  width: 100%;
  padding-left: 40px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 12px;
  }
`
const StyledRightContent = styled('div')`
  align-self: center;

  svg {
    vertical-align: bottom;
  }
`

/**
 *
 * rightComponent defaults to right arrow.
 * */
const ReassuranceItem: React.FC<IProps> = ({
  content,
  title,
  subtitle,
  leftComponent,
  modalIcon,
  rightComponent = <SVGIcon library="arrow" name="unicode-arrow-right" size={24} />,
}) => {
  const [visible, setVisible] = useState(false)

  const onOpen = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <>
      <StyledContainer onClick={onOpen}>
        <div>{leftComponent}</div>
        <StyledTitleDiv>
          <StyledTitle>{title}</StyledTitle>
        </StyledTitleDiv>
        <StyledRightContent>{rightComponent}</StyledRightContent>
      </StyledContainer>

      <ReassuranceItemModal
        title={title}
        subtitle={subtitle}
        visible={visible}
        onClose={onClose}
        icon={modalIcon}
        content={content}
      />
    </>
  )
}

export default ReassuranceItem
