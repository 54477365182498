import React, { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import {
  AddToCartButton,
  AvailabilityTitle,
  AvailabilityWarning,
  BrowseSimilarItemsContainer,
  ComingSoonWarning,
  LineSeparator,
  NeedPrescriptionText,
  PdpCtasWrapper,
  SoldOutContainer,
  StyledButtonsContainer,
  StyledRelativeBtn,
} from './CtaPdp.style'
import { ProductSoldOutStatus, IProduct } from '@typesApp/product'
import { PRODUCT_SOLDOUT_STATUS } from '../../../../constants/product'
import { PreLoader } from '../../../../components/UI'
import { getModelName } from '../../../../utils/productAttributes'
import { getProductsPartNumbers, isOpticalProduct } from '../../../../utils/product'
import { openDrawerProductNotificationSelector } from '../../../../features/ui/selector'
import { setOpenDrawerProductNotificationAvailable } from '../../../../features/ui/action'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { LoaderProps } from '../../../../components/UI/Loader/preloader.type'
import { addToCartBusySelector } from '../../../../features/cartui/cartuiSlice'
import {
  prescriptionLensDataLoadingSelector,
  prescriptionLensFormOpenSelector,
  setPrescriptionLensFormOpen,
} from '../../../../features/rox/roxSlice'
import {
  addCLAccessoriesToCartErrorSelector,
  addContactLensesToCartErrorSelector,
  addSubscriptionToCartErrorSelector,
  productAddToCartEnabledSelector,
} from '../../../../features/product/selector'
import {
  setCurrentProductBundle,
  toggleAddContactLensesToCartError,
  toggleAddSubscriptionToCartError,
} from '../../../../features/product/slice'
import { APP_MAIN_CONTAINER_ID } from '../../../../constants/ui'
import { isSoldOutFeatureEnabled, uniqueId } from '../../../../utils/common'
import useScrollTo from '@hooks/useScrollTo'
import { initIndexName } from '@foundation/algolia/algoliaConfig'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { useGetRecommendedProducts } from '@components/AlgoliaRecommendations/AlgoliaRecommendations'
import {
  ALGOLIA_SUGGESTED_PRODUCTS_ID,
  ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_CLASS_NAME,
} from '../SuggestedProductsAlgolia/SuggestedProductsAlgolia'
import { Button } from '@components/UI-CSS/Button'

export interface CtaProps {
  'data-element-id'?: string
  error?: boolean
  isEditing?: boolean
  isLoading?: boolean
  isStickyBar?: boolean
  isMobileSticky?: true
  product: IProduct
  roxable?: boolean
  soldOutStatus: ProductSoldOutStatus
  variant?: 'primary' | 'secondary' | 'tertiary'
  addToCartFillType?: 'outline' | 'fill'
  hideNeedPrescriptionText?: boolean
  isSelectLensesBusy?: boolean
  setSelectLensesBusy?: React.Dispatch<boolean>
  addToCart?(): void
  onClickCta?: (callback?: () => void) => void
}

const CtaPdp = forwardRef<HTMLDivElement, CtaProps>((props, ref) => {
  const {
    'data-element-id': dataElementId = 'X_X_Prod_AddCart',
    error,
    isEditing = false,
    isLoading = false,
    isStickyBar,
    isMobileSticky,
    product,
    roxable,
    soldOutStatus,
    addToCart,
    onClickCta,
    addToCartFillType,
    hideNeedPrescriptionText,
    variant,
    isSelectLensesBusy,
    setSelectLensesBusy,
  } = props
  const { t } = useTranslation()
  const addContactLensesToCartError = useSelector(addContactLensesToCartErrorSelector)
  const addSubscriptionToCartError = useSelector(addSubscriptionToCartErrorSelector)
  const addCLAccessoriesToCartError = useSelector(addCLAccessoriesToCartErrorSelector)
  const hasAddToCartError =
    !!addContactLensesToCartError || !!addSubscriptionToCartError || !!addCLAccessoriesToCartError

  const { isRXEnabled } = useStoreIdentity()
  const dispatch = useDispatch()
  const isAddToCartBusy = useSelector(addToCartBusySelector)
  const isPrescriptionLensFormOpen = useSelector(prescriptionLensFormOpenSelector)
  const isLensDataLoading = useSelector(prescriptionLensDataLoadingSelector)
  const addToCartEnabled = useSelector(productAddToCartEnabledSelector)
  const productAvailableNotificationOpen = useSelector(openDrawerProductNotificationSelector)
  const modelName = getModelName(product)
  const spinnerFill: LoaderProps['fill'] = variant !== 'secondary' ? 'light' : 'dark'
  const toggleProductNotificationAvailableDrawer = () =>
    dispatch(setOpenDrawerProductNotificationAvailable(!productAvailableNotificationOpen))
  const scrollTo = useScrollTo()
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const productsPartNumber: string[] = getProductsPartNumbers([product])
  const indexName = initIndexName({ locale: langCountry, isRecommendations: true })
  const [recommendationCarousel, setRecommendationCarousel] = useState<Element | null>(null)
  const soldOutFeatureEnabled = isSoldOutFeatureEnabled()
  const recommendationProducts = useGetRecommendedProducts('', indexName, productsPartNumber, '')

  const openPrescriptionLenses = () => {
    if (!product) return
    setSelectLensesBusy && setSelectLensesBusy(true)
    dispatch(setCurrentProductBundle({ product }))
    dispatch(setPrescriptionLensFormOpen(true))
  }

  const onBrowseSimilarItemsClick = e => {
    const carouselHeight = recommendationCarousel?.clientHeight || 0
    const carouselPosition = recommendationCarousel?.getBoundingClientRect().top || 0
    const positionY = carouselPosition - carouselHeight || document.body.scrollHeight
    scrollTo(0, positionY)
  }

  const onAddToCartClick = () => {
    const callback = () => {
      dispatch(toggleAddSubscriptionToCartError(''))
      dispatch(toggleAddContactLensesToCartError(false))

      if (addToCartEnabled) {
        addToCart?.()
      } else if (isStickyBar) {
        document.getElementById(APP_MAIN_CONTAINER_ID)?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }

    if (onClickCta && typeof onClickCta === 'function') {
      onClickCta(callback)
    } else {
      callback()
    }
  }

  const hasRecommendedProducts = !!recommendationProducts?.length
  const showRecommendationsLink = soldOutFeatureEnabled && hasRecommendedProducts
  const isCLAccessoriesOutOfStock = soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK

  useEffect(() => {
    const carouselComponent =
      document.getElementById(ALGOLIA_SUGGESTED_PRODUCTS_ID) ||
      document.querySelectorAll(`[class^=${ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_CLASS_NAME}]`)?.[0]
    setRecommendationCarousel(carouselComponent)
  })

  const GetPdpCtas = (isDisabled?: boolean): React.ReactNode => {
    const busyAddToCart = (isLoading || isAddToCartBusy) && !hasAddToCartError
    const disabledAddToCart = isDisabled || error || isPrescriptionLensFormOpen || busyAddToCart

    const disabledSelectLenses =
      isDisabled || error || isAddToCartBusy || isPrescriptionLensFormOpen || isLensDataLoading

    const showBrowseSimilarCLAccessories = isDisabled && soldOutFeatureEnabled && !isStickyBar

    if (isCLAccessoriesOutOfStock) {
      if (hasRecommendedProducts) {
        return (
          <Button color="primary" size="big" onClick={onBrowseSimilarItemsClick}>
            {t('CtaPDP.Labels.BrowseSimilarProducts')}
          </Button>
        )
      }
      // hide add to cart button
      return
    }

    if (roxable && isRXEnabled && isOpticalProduct(product)) {
      return (
        <>
          {(showBrowseSimilarCLAccessories && (
            <>
              <AvailabilityWarning>
                <AvailabilityTitle>{t('Discontinued.Header')}</AvailabilityTitle>
                {t(hasRecommendedProducts ? 'Discontinued.RecommendationMsg' : 'Discontinued.NoRecommendationMsg')}
              </AvailabilityWarning>
              {hasRecommendedProducts && (
                <StyledRelativeBtn variant={variant || 'tertiary'} customBg onClick={e => onBrowseSimilarItemsClick(e)}>
                  {t('SoldOut.BrowseSimilarItems')}
                </StyledRelativeBtn>
              )}
            </>
          )) || (
            <StyledRelativeBtn
              id={`rxconfig-button-${uniqueId('')}`}
              disabled={disabledSelectLenses}
              data-id={isStickyBar ? 'Select_Lenses_CTA_Sticky' : 'Select_Lenses_CTA'}
              data-element-id="X_Pdp_Prod_AddLens"
              data-description={`${modelName}_${product?.partNumber}`}
              className="prescription"
              variant={variant || 'tertiary'}
              customBg
              customBorderColor
              onClick={() => openPrescriptionLenses()}
            >
              {isSelectLensesBusy && <PreLoader fill={spinnerFill} withButton />}
              {t('ProductDetails.Suggested.Actions.Prescription')}
            </StyledRelativeBtn>
          )}
        </>
      )
    } else if (roxable && !isStickyBar && isRXEnabled && !isOpticalProduct(product)) {
      return (
        <>
          {(isDisabled && soldOutFeatureEnabled && (
            <>
              <AvailabilityWarning>
                <AvailabilityTitle>{t('Discontinued.Header')}</AvailabilityTitle>
                {t(hasRecommendedProducts ? 'Discontinued.RecommendationMsg' : 'Discontinued.NoRecommendationMsg')}
              </AvailabilityWarning>
              {hasRecommendedProducts && (
                <StyledRelativeBtn variant={variant || 'tertiary'} customBg onClick={e => onBrowseSimilarItemsClick(e)}>
                  {t('SoldOut.BrowseSimilarItems')}
                </StyledRelativeBtn>
              )}
            </>
          )) || (
            <>
              <StyledRelativeBtn
                id={`rxconfig-button-${uniqueId('')}`}
                data-id="Select_Lenses_CTA"
                data-element-id="X_Pdp_Prod_AddLens"
                data-description={`${modelName}_${product?.partNumber}`}
                className="prescription"
                onClick={() => openPrescriptionLenses()}
                disabled={disabledSelectLenses}
                variant={variant || 'tertiary'}
                parentbg={'light'}
                customBg
                customBorderColor
              >
                {isSelectLensesBusy && <PreLoader fill={spinnerFill} withButton />}
                {t('ProductDetails.Suggested.Actions.Prescription')}
              </StyledRelativeBtn>
              {!hideNeedPrescriptionText && (
                <NeedPrescriptionText>{t('CtaPDP.Labels.NeedPrescription')}</NeedPrescriptionText>
              )}
              <AddToCartButton
                filltype={addToCartFillType}
                data-element-id={dataElementId}
                data-description={`${modelName}_${product?.partNumber}`}
                disabled={disabledAddToCart}
                onClick={onAddToCartClick}
              >
                {busyAddToCart && <PreLoader fill={spinnerFill} withButton />}
                {t([error ? 'ProductDetails.Errors.AddToCartError' : 'ProductDetails.CTA.AddToBag', 'Add to bag'])}
              </AddToCartButton>
            </>
          )}
        </>
      )
    } else if (isStickyBar && isRXEnabled && !isOpticalProduct(product)) {
      return (
        <>
          {roxable ? (
            <StyledRelativeBtn
              id="rxconfig-button-sticky"
              disabled={disabledSelectLenses}
              data-id="Select_Lenses_CTA_Sticky"
              data-element-id="X_X_Stickybar_AddLens"
              data-description={`${modelName}_${product?.partNumber}`}
              className="prescription"
              onClick={() => openPrescriptionLenses()}
              customBg
              customBorderColor
              variant={variant || 'tertiary'}
              fullwidth
            >
              {isSelectLensesBusy && <PreLoader fill={spinnerFill} withButton />}
              {t('ProductDetails.Suggested.Actions.Prescription')}
            </StyledRelativeBtn>
          ) : (
            <StyledRelativeBtn
              filltype={addToCartFillType}
              data-element-id="X_X_Stickybar_AddCart"
              data-description={`${modelName}_${product?.partNumber}`}
              disabled={disabledAddToCart}
              customBg
              customBorderColor
              onClick={onAddToCartClick}
              fullwidth
            >
              {busyAddToCart && <PreLoader fill={spinnerFill} withButton />}
              {t([error ? 'ProductDetails.Errors.AddToCartError' : 'ProductDetails.CTA.AddToBag', 'Add to bag'])}
            </StyledRelativeBtn>
          )}
        </>
      )
    } else {
      return (
        <StyledRelativeBtn
          filltype={'fill'}
          variant={variant}
          data-element-id={isStickyBar ? 'X_X_Stickybar_AddCart' : dataElementId}
          data-description={`${modelName}_${product?.partNumber}`}
          disabled={disabledAddToCart}
          customBg
          customBorderColor
          onClick={onAddToCartClick}
        >
          {busyAddToCart && <PreLoader fill={spinnerFill} withButton />}
          {t([error ? 'ProductDetails.Errors.AddToCartError' : 'ProductDetails.CTA.AddToBag', 'Add to bag'])}
        </StyledRelativeBtn>
      )
    }
  }

  if (
    isCLAccessoriesOutOfStock ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.NONE ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.INFINITE_INVENTORY
  )
    return (
      <PdpCtasWrapper isStickyBar={isStickyBar} className="pdp__cta-wrapper" ref={ref}>
        <StyledButtonsContainer>
          {GetPdpCtas(
            soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT || soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK
          )}
        </StyledButtonsContainer>
      </PdpCtasWrapper>
    )

  if (
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_SOON ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON
  ) {
    return isStickyBar ? (
      <SoldOutContainer ref={ref} isStickyBar={true}>
        <AddToCartButton onClick={toggleProductNotificationAvailableDrawer} isStickyBar={true} isOutOfStock={true}>
          {t('ProductDetails.CTA.EmailMe')}
        </AddToCartButton>
      </SoldOutContainer>
    ) : (
      <SoldOutContainer ref={ref} isStickyBar={false}>
        {!isMobileSticky &&
          (soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_SOON ? (
            <>
              <LineSeparator />
              <ComingSoonWarning isStickyBar={false}>{t('ComingSoon.ComingSoon')}</ComingSoonWarning>
            </>
          ) : (
            <>
              <LineSeparator />
              <AvailabilityWarning>
                <AvailabilityTitle>
                  {t(soldOutFeatureEnabled ? 'ComingBackSoon.Text1' : 'ComingBackSoon.Sorry')}
                </AvailabilityTitle>
                {t(soldOutFeatureEnabled ? 'ComingBackSoon.Text2' : 'ComingBackSoon.ComingBackSoonText')}
              </AvailabilityWarning>
            </>
          ))}
        <AddToCartButton onClick={toggleProductNotificationAvailableDrawer} isOutOfStock={true}>
          {t('ProductDetails.CTA.EmailMe')}
        </AddToCartButton>
        {showRecommendationsLink && (
          <BrowseSimilarItemsContainer>
            <p>{t('ProductDetails.Labels.Or')}</p>
            <a href="#" onClick={e => onBrowseSimilarItemsClick(e)}>
              {t('ComingBackSoon.BrowseSimilarItems')}
            </a>
          </BrowseSimilarItemsContainer>
        )}
      </SoldOutContainer>
    )
  }

  return null
})

export default CtaPdp
