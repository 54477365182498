interface ExpiryDate {
  value: string
  month: string
  year: string
}

export interface UseExpiryDateReturn {
  formatExpiryDate: (value: string | undefined) => string
  parseExpiryDate: (value: string | undefined) => ExpiryDate
}

export const useExpiryDate = (): UseExpiryDateReturn => {
  const formatExpiryDate = (value: string | undefined = ''): string => {
    return value?.replace(/[^0-9\/]/g, '')
  }

  const parseExpiryDate = (value: string | undefined = ''): ExpiryDate => {
    const date = formatExpiryDate(value)
    let [monthPart, yearPart] = date.split('/')
    if (yearPart?.length == 2) {
      yearPart = `20${yearPart}`
    }

    return {
      value: date,
      month: monthPart || '',
      year: yearPart || '',
    }
  }

  return {
    formatExpiryDate,
    parseExpiryDate,
  }
}
