import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { themeModeSelector } from '../../redux/selectors/theme'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'

interface LoadingSkeletonProps {
  fitContainer?: boolean
  width?: number
  height?: number
}

export const Wrapper = styled('div', {
  name: 'LoadingSkeleton',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'fitContainer',
})<{ fitContainer?: boolean }>(({ fitContainer }) => ({
  width: fitContainer ? '100%' : '100vw',
  height: fitContainer ? '100%' : '50vh',
}))

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = props => {
  const { fitContainer, width, height } = props
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Wrapper fitContainer={fitContainer}>
      {isMobile ? (
        <>
          <SkeletonTheme
            baseColor={themeMode === 'dark' ? 'black' : 'transparent'}
            highlightColor={themeMode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}
          >
            <Skeleton height={height ?? '50vh'} width={width} />
          </SkeletonTheme>
          <SkeletonTheme
            baseColor={themeMode === 'dark' ? 'black' : 'rgba(255, 255, 255, 0.5)'}
            highlightColor={themeMode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}
          >
            <Skeleton height={height ?? '50vh'} width={width} />
          </SkeletonTheme>
        </>
      ) : (
        <SkeletonTheme
          baseColor={themeMode === 'dark' ? 'black' : 'transparent'}
          highlightColor={themeMode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}
        >
          <Skeleton height={height ?? '50vh'} width={width} />
        </SkeletonTheme>
      )}
    </Wrapper>
  )
}

export default LoadingSkeleton
