import React, { useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { IOrderDetails, OrderItem, OrderTotals } from '../../../types/order'
import OrderService from '../../../foundation/apis/transaction/order.service'
import FormattedPriceDisplay from '../../../components/formatted-price-display'
import {
  OrderDetailsFooterContainer,
  OrderTotalsRow,
  OrderTotalsLabel,
  OrderTotalsTotalValue,
  OrderTotalsContainer,
  OrderSummaryContainer,
  OrderSummaryTitle,
  OrderSummaryLabel,
  OrderSummaryRow,
  OrderSummaryValue,
  OrderTotalsValue,
} from './OrderDetailsFooter.style'
import { OrderTaxSummary } from '../../../components/OrderTaxSummary'
import theme from '../../../themes'
import styles from '../styles/NewOrderDetailsFooter.module.scss'

export interface OrderPaymentInfo {
  billingAddress: string
  shippingAddress: string
  shippingName: string
  paymentMethod: string
}

const initPaymentInfo = (orderDetails: IOrderDetails): OrderPaymentInfo => {
  const billingAddress = [
    orderDetails.paymentInstruction?.[0]?.firstName,
    orderDetails.paymentInstruction?.[0]?.lastName,
    orderDetails.paymentInstruction?.[0]?.addressLine?.[0],
    orderDetails.paymentInstruction?.[0]?.city,
    orderDetails.paymentInstruction?.[0]?.zipCode,
    orderDetails.paymentInstruction?.[0]?.country,
  ].join(' ')

  const shippingAddress = [
    orderDetails.orderItem?.[0]?.firstName,
    orderDetails.orderItem?.[0]?.lastName,
    orderDetails.orderItem?.[0]?.addressLine?.[0],
    orderDetails.orderItem?.[0]?.city,
    orderDetails.orderItem?.[0]?.zipCode,
    orderDetails.orderItem?.[0]?.country,
  ].join(' ')

  return {
    billingAddress,
    shippingAddress,
    shippingName: orderDetails?.orderItem?.[0]?.shipModeDescription || '',
    paymentMethod: orderDetails?.paymentInstruction?.[0]?.piDescription?.toUpperCase() || '',
  }
}

export interface OrderDetailsFooterProps {
  orderDetails: IOrderDetails
  orderItems: OrderItem[]
}

export const NewOrderDetailsFooter: React.FC<OrderDetailsFooterProps> = ({ orderDetails, orderItems }) => {
  const paymentInfo = initPaymentInfo(orderDetails)
  const totals = useMemo(() => OrderService.getOrderTotals(orderDetails), [orderDetails])

  if (!paymentInfo) {
    return null
  }
  return (
    <OrderDetailsFooterContainer>
      <OrderSummary paymentInfo={paymentInfo} />
      <OrderTotalItems totals={totals} orderDetails={orderDetails} orderItems={orderItems} />
    </OrderDetailsFooterContainer>
  )
}

export interface OrderSummaryProps {
  paymentInfo: OrderPaymentInfo
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ paymentInfo }) => {
  const { t: translate } = useTranslation()

  return (
    <OrderSummaryContainer>
      <h3 className={styles.shippingSummaryTitle}>{translate('Order.NewShippingPaymentDetails')}</h3>
      <OrderSummaryRow>
        <Field label={translate('Order.NewShippingAddress')} value={paymentInfo.shippingAddress} />
      </OrderSummaryRow>
      <OrderSummaryRow>
        <Field label={translate('Order.ShippingMethod')} value={paymentInfo.shippingName} />
      </OrderSummaryRow>
      <OrderSummaryRow>
        <Field label={translate('Order.BillingAddress')} value={paymentInfo.billingAddress} />
      </OrderSummaryRow>
      <OrderSummaryRow>
        <Field label={translate('Order.PaymentMethod')} value={paymentInfo.paymentMethod} />
      </OrderSummaryRow>
    </OrderSummaryContainer>
  )
}

export interface FieldProps {
  label: string
  value: string
}

const Field: React.FC<FieldProps> = ({ label, value }) => {
  return (
    <>
      <OrderSummaryLabel>{label}</OrderSummaryLabel>
      <OrderSummaryValue>{value}</OrderSummaryValue>
    </>
  )
}

export interface OrderTotalsProps {
  totals: OrderTotals
  orderDetails: IOrderDetails
  orderItems: OrderItem[]
}

// TODO: common order totals component for cart and order history?
const OrderTotalItems: React.FC<OrderTotalsProps> = props => {
  const { t: translate } = useTranslation()
  const { totals, orderDetails, orderItems } = props

  return (
    <OrderTotalsContainer>
      <OrderTotalsRow>
        <PriceField label={translate('Order.Subtotal')} value={totals.subtotal} currency={totals.grandTotalCurrency} />
      </OrderTotalsRow>
      {totals.hasInsurance && (
        <OrderTotalsRow>
          <PriceField
            label={translate('Order.InsuredCoverage')}
            value={-totals.insuranceAmount}
            currency={totals.insuranceCurrency}
            showMinus={true}
          />
        </OrderTotalsRow>
      )}
      {totals.hasPromotion && (
        <OrderTotalsRow>
          <PriceField
            label={translate('Order.PromotionApplied')}
            value={-totals.promotionAmount}
            currency={totals.promotionCurrency}
            showMinus={true}
          />
        </OrderTotalsRow>
      )}
      <OrderTotalsRow>
        <PriceField
          label={translate('Order.NewShippingCost')}
          value={totals.shipping}
          currency={totals.shippingCurrency}
        />
      </OrderTotalsRow>
      <OrderTotalsRow>
        <PriceField
          label={translate('Order.HandlingCost')}
          value={totals.handlingFeeTotal}
          currency={totals.shippingCurrency}
        />
      </OrderTotalsRow>
      <OrderTaxSummary
        order={orderDetails}
        orderId={orderDetails.orderId}
        orderCurrency={orderDetails.totalSalesTaxCurrency}
        salesTax={orderDetails.totalSalesTax}
        shippingTax={orderDetails.totalShippingTax}
        orderItems={orderItems}
        labelFontSize={theme.typography.subtitle1.fontSize}
        boldLabel={true}
        showLabelSeparator={true}
      />
      <OrderTotalsRow>
        <PriceField
          label={translate('Order.OrderTotal')}
          value={totals.grandTotal}
          currency={totals.grandTotalCurrency}
          isGrandTotal
        />
      </OrderTotalsRow>
    </OrderTotalsContainer>
  )
}

export interface PriceFieldProps {
  label: string
  value: number
  currency: string
  showMinus?: boolean
  isGrandTotal?: boolean
}

const PriceField: React.FC<PriceFieldProps> = ({ label, value, currency, showMinus = false, isGrandTotal = false }) => {
  const ValueWrapper = isGrandTotal ? OrderTotalsTotalValue : OrderTotalsValue

  return (
    <>
      <OrderTotalsLabel>{label}</OrderTotalsLabel>
      <ValueWrapper>
        {showMinus && '-'}
        <FormattedPriceDisplay min={value} currency={currency} />
      </ValueWrapper>
    </>
  )
}
