import { toNumber } from 'lodash-es'
import { useEffect, useState } from 'react'
import Log from '../../services/Log'
import { executeOnce } from '../../utils/common'
import { useSite } from '../hooks/useSite'
import { findByCountryCode } from '../../components/UI/PhoneNumberField/CountryCodeMap'
import config from '@configs/index'

const GENESYS_LOADED_EVENT = 'genesysLoaded'

export const useGenesys = () => {
  const { mySite } = useSite()
  const [isLoading, setIsLoading] = useState(typeof window !== 'undefined' && !window?.Genesys)

  const toggleGenesys = () => {
    window.Genesys(
      'command',
      'Messenger.open',
      {},
      () => {}, // if resolved
      () => window.Genesys('command', 'Messenger.close') // if rejected
    )
  }

  const getConfig = (configName: string, defaultValue = '') => {
    return config.genesys[configName.toLowerCase()][mySite.locale.toLowerCase()] || defaultValue
  }

  const loadScript = () => {
    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const genesysScript = document.createElement('script')
      const widgetUrl = getConfig('URL', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js')
      const widgetKey = getConfig('KEY', '58525254-a5f2-4743-b279-1659c8c54ba7')
      const countryName = findByCountryCode(mySite.country)?.label || ''

      genesysScript.text = `(function (g, e, n, es, ys) {
          g['_genesysJs'] = e;
          g[e] = g[e] || function () {
            (g[e].q = g[e].q || []).push(arguments)
          };
          g[e].t = 1 * new Date();
          g[e].c = es;
          ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; 
          ys.onload = () => {
            try {
              if (Genesys != null) {
                Genesys('command', 'Database.set', {
                  messaging: {
                    customAttributes: {
                      CustomerCountry: \'${countryName}\'
                    }
                  }
                })
                window.dispatchEvent( new Event(\'${GENESYS_LOADED_EVENT}\') )
              }
              else{
                throw('Genesys object is null');
              }
            } catch(e) {
              console.error('Error Setting Genesys custom attributes: ' + e );
            }
          }
          document.head.appendChild(ys);
        })(window, 'Genesys', '${widgetUrl}', {
          environment: 'prod',
          deploymentId: '${widgetKey}'
        });`

      headFragment.appendChild(genesysScript)
      document.head.appendChild(headFragment)
      Log.info(`Loading Genesys widget ${widgetUrl} with key: ${widgetKey}`)
    }, 'cl-genesys')()
  }

  const hideDefaultToggle = () => {
    const chatWidget = document.getElementsByClassName('genesys-mxg-frame')?.[0] as HTMLElement
    if (chatWidget) {
      chatWidget.style.display = 'none'
    }
  }

  useEffect(() => {
    if (window?.Genesys) {
      setIsLoading(false)
      hideDefaultToggle()
    } else {
      try {
        window.setTimeout(() => loadScript(), toNumber(process?.env?.REACT_APP_GENESYS_LOAD_DELAY) || 1000)
        const callback = () => {
          window.Genesys('subscribe', 'Messenger.ready', () => {
            setIsLoading(false)
            hideDefaultToggle()
          })
          window.removeEventListener(GENESYS_LOADED_EVENT, callback)
        }
        window.addEventListener(GENESYS_LOADED_EVENT, callback)
      } catch (e: any) {
        Log.error('[GENESYS] LOAD SCRIPT ERROR: ' + e.message, window.location.href)
      }
    }
  }, [])

  return {
    isLoading,
    toggleGenesys,
  }
}
