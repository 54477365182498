import { StyledFormControlLabel, StyledSwitch } from '@components/UI'
import styled from '@mui/material/styles/styled'

export const SubscriptionSwitch = styled(StyledSwitch)(({ theme }) => ({
  margin: theme.spacing(3),
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.custom.green,
      },
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.custom.white,
  },
  '& .MuiSwitch-switchBase:hover + .MuiSwitch-track': {
    backgroundColor: theme.palette.custom.light1.grey,
  },
  '& .MuiSwitch-switchBase:hover.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.custom.green,
  },
  '& .MuiSwitch-switchBase:focus + .MuiSwitch-track': {
    backgroundColor: theme.palette.custom.green,
  },
}))

export const PricePerBoxWrapper = styled('div', {
  name: 'Subscription',
  slot: 'PricePerBoxWrapper',
  shouldForwardProp: prop => prop !== 'isPDP',
})<{ isPDP?: boolean }>(({ theme, isPDP }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.body2.fontSize,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(isPDP ? 3 : 0),
  display: 'flex',
  justifyContent: isPDP ? 'start' : 'end',
  '& > span': {
    marginRight: theme.spacing(1),
    ':last-of-type': {
      fontWeight: 700,
    },
  },
}))

export const ItemSeparator = styled('div', {
  name: 'Subscription',
  slot: 'Separator',
})(({ theme }) => ({
  gridColumn: 'span 3',
  height: '1px',
  backgroundColor: theme.palette.custom.alto,
}))

export const FormControlLabel = styled(StyledFormControlLabel, {
  name: 'Subscription',
  slot: 'FormControlLabel',
})(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    color: theme.palette.text.dark.primary,
  },
}))

export const QuantityWrapper = styled('div', {
  name: 'Subscription',
  slot: 'QuantityWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
  cursor: 'pointer',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  span: {
    marginRight: theme.spacing(2),
  },
  '>div': {
    width: theme.spacing(30),
    padding: theme.spacing(0.25),
    button: {
      backgroundColor: theme.palette.custom.white,
    },
  },
}))

export const CLQuantityWrapper = styled('div', {
  name: 'Subscription',
  slot: 'CLQuantityWrapper',
  shouldForwardProp: prop => prop !== 'disableAllFields',
})<{ disableAllFields: boolean }>(({ disableAllFields, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  cursor: 'pointer',

  marginBottom: theme.spacing(5),
  '>div': {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    pointerEvents: disableAllFields ? 'none' : 'inherit',
    span: {
      minWidth: theme.spacing(31),
      marginRight: theme.spacing(2),
    },
    '>div': {
      width: theme.spacing(27),

      padding: theme.spacing(0.25),
      button: {
        backgroundColor: theme.palette.custom.white,
      },
      li: {
        span: {
          minWidth: 'unset',
        },
      },
    },
  },
}))

export const SubscriptionQuantityLabel = styled('span', {
  name: 'Subscription',
  slot: 'QuantityLabel',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: '600',
  textTransform: 'uppercase',

  [theme.breakpoints.down('sm')]: {
    lineBreak: 'loose',
    hyphens: 'auto',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'elipsis',
    whiteSpace: 'break-spaces',
  },
}))

export const SubscriptionIntervalSection = styled('div', {
  name: 'Subscription',
  slot: 'IntervalSection',
  shouldForwardProp: props => props !== 'showStacked',
})<{ showStacked?: boolean }>(({ theme, showStacked }) => ({
  display: 'flex',
  flexDirection: showStacked ? 'column' : 'row',
  justifyContent: 'start',
  alignItems: showStacked ? 'start' : 'center',
  textAlign: 'left',
  fontWeight: showStacked ? 600 : 'normal',
  marginTop: showStacked ? theme.spacing(4) : 0,
  gap: showStacked ? 0 : theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 0,
    '>div:first-of-type': {
      marginTop: theme.spacing(6),
    },
  },

  '.recurrency-selector': {
    minWidth: theme.spacing(showStacked ? 15 : 50),
    maxWidth: showStacked ? 'unset' : theme.spacing(50),
    margin: theme.spacing(4, 0),
    height: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      maxWidth: 'unset',
      width: '100%',
      margin: 0,
    },

    '.MuiSelect-icon': {
      width: 'unset',
      height: 'unset',
    },
    svg: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 0,
      pointerEvents: 'none',
    },
  },

  '.textfield-wrapper': {
    width: showStacked ? '100%' : 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const HeaderSection = styled('div', {
  name: 'Subscription',
  slot: 'HeaderSection',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(3),
  '.MuiFormControlLabel-root': {
    marginBottom: 0,
  },
  span: {
    justifyContent: 'start',
  },
  label: {
    span: {
      fontWeight: 'bold',
    },
  },
}))

export const QuantityLabel = styled('span', {
  name: 'Subscription',
  slot: 'QuantityLabel',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  fontSize: theme.typography.body1.fontSize,
}))

export const SelectionTitle = styled('div', {
  name: 'Subscription',
  slot: 'SelectionTitle',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 700,
  marginBottom: theme.spacing(4),
}))

export const BenefitsSection = styled('div', {
  name: 'Subscription',
  slot: 'Benefits',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  '>div': {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    svg: {
      marginRight: theme.spacing(2),
    },
    span: {
      marginRight: theme.spacing(3),
    },
  },
}))

export const CartActiveSubscriptionSection = styled('div', {
  name: 'CartRecap',
  slot: 'SubscriptionSection',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.custom.wildSand,
  minHeight: theme.spacing(20),
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.body2.fontSize,
  label: {
    span: {
      fontWeight: 'bold',
    },
  },
  'span:first-of-type': {
    fontWeight: 'bold',
  },
  'div:nth-of-type(2)': {
    display: 'flex',
    alignItems: 'center',
  },
}))

export const SummarySection = styled(CartActiveSubscriptionSection, {
  name: 'Subscription',
  slot: 'SummarySection',
})(({ theme }) => ({
  minHeight: theme.spacing(16),
  margin: theme.spacing(5, 0),
}))

export const SwitchContainer = styled('div', {
  name: 'CartRecap',
  slot: 'SubscriptionSectionSwitchContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(5),
  marginTop: theme.spacing(2),
  '> label': {
    marginRight: 0,
  },
}))

export const RecurrencyContainer = styled('div', {
  name: 'CartRecap',
  slot: 'RecurrencyContainer',
})(({ theme }) => ({
  minHeight: theme.spacing(11),
  '>div': {
    marginTop: theme.spacing(-3),
  },
}))

export const PDPSubscriptionSection = styled('div', {
  name: 'PDP',
  slot: 'SubscriptionSection',
  shouldForwardProp: props => props !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: isSelected ? theme.palette.custom.wildSand : 'transparent',
  padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.body2.fontSize,
  border: `solid ${theme.spacing(isSelected ? 0.5 : 0.25)} ${
    isSelected ? theme.palette.custom.boulder : theme.palette.custom.light3.grey
  }`,
}))

export const SubscriptionErrorBox = styled('div', {
  name: 'CartRecap',
  slot: 'SubscriptionError',
})(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.color.error,
  backgroundColor: theme.palette.custom.light3.red,
  'div:nth-child(2)': {
    fontWeight: 600,
  },
}))
export const SubscriptionDiscountSection = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: theme.typography.body2.fontSize,
  margin: theme.spacing(2, 0),
  fontWeight: 400,
  'div:first-of-type': {
    fontWeight: 600,
  },
}))

export const ClSubscribedMsg = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.light.green,
  padding: theme.spacing(0.5, 4),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: theme.palette.custom.green,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 600,
}))

export const PriceLabelWrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}))
