import config from '@configs/index'
import { useDispatch } from 'react-redux'
import { LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION } from '@redux/actions/subscription'
export const useSubscription = () => {
  const { isSubscriptionEnabled, subscriptionAllowedProducts } = config
  const dispatch = useDispatch()

  dispatch(
    LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION({
      enabled: isSubscriptionEnabled,
      allowedProducts: subscriptionAllowedProducts,
    })
  )
}
