import { sendCouponSubmitEvent } from '@foundation/analytics/tealium/lib'
import Log from '@services/Log'
import { CommonData } from '@foundation/analytics/tealium/interfaces'

const ERR_PROMOTION_CODE_INVALID = 'ERR_PROMOTION_CODE_INVALID'
export const ERR_PROMOTION_CODE_KEYS = [
  'ERR_PROMOTION_CODE_INVALID',
  '_ERR_HANDLING_FEES_PROMO_CODE',
  'ERR_PROMOTION_CODE_DUPLICATED',
]

export interface Error {
  errorCode: string
  errorKey: string
  errorMessage: string
  errorParameters: string
}

export const retrieveApplyPromoCodeError = (
  error: Error,
  invalidPromoCode: string,
  promoErrorMessageTranslation: string
): { errorMessage: string; isWrongPromoCode: boolean } => {
  const isWrongPromoCode = error.errorKey === ERR_PROMOTION_CODE_INVALID
  const errorMessage = !!promoErrorMessageTranslation ? promoErrorMessageTranslation : invalidPromoCode
  return {
    errorMessage: errorMessage,
    isWrongPromoCode,
  }
}

export const registerApplyPromoCodeError = (
  analyticsDataForPromoCode: Partial<CommonData>,
  promoCodeToApply: string,
  errorMessage: string,
  isWrongPromoCode: boolean,
  errorCode: string
) => {
  sendCouponSubmitEvent({
    result: false,
    ...analyticsDataForPromoCode,
    Order_DiscountCode: promoCodeToApply,
    Error_Message: errorMessage,
    Error_Code: errorCode,
    Error_Source: isWrongPromoCode ? 'User' : 'Server',
  })

  Log.error('Could not add promo code')
}

export const registerRemovePromoCodeError = (promoCodeInputValue: string) => {
  sendCouponSubmitEvent({
    result: false,
    Order_DiscountCode: promoCodeInputValue,
    Error_Message: 'Could not remove promo code',
  })

  Log.error('Could not remove promo code')
}
