import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormLabel } from '@mui/material'
import { FormControlHelperText } from './FormControlHelperText'
import { FormControlProps, SelectOption } from './types'
import { StyledRadioDescription, StyledRadioGroup } from '../Checkbox/StyledRadioGroup'
import { StyledFormControlLabel } from '../Checkbox/StyledFormControlLabel'
import { StyledRadio } from '../Checkbox/StyledRadio'
import { FormControlRadioGroupContainer, FormControlRadioGroupContent } from './FormControlRadioGroup.style'

export interface FormControlRadioGroupProps extends FormControlProps {
  options: SelectOption[]
  row?: boolean
}

export const FormControlRadioGroup: React.FC<FormControlRadioGroupProps> = ({
  name,
  rules,
  label,
  description,
  options,
  row,
}) => {
  const { control } = useFormContext()
  const isRequired = rules ? (rules['required']?.value === true || rules['required'] === true) : false

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, ...rest }, fieldState: { error } }) => (
        <FormControlRadioGroupContainer>
          {label && <FormLabel id={`${name}-label`}>{`${label}${isRequired ? ' *' : ''}`}</FormLabel>}
          <StyledRadioGroup
            {...rest}
            row={row}
            value={value}
            aria-labelledby={`${name}-label`}
            name={name}
          >
            {options.map(({ value, label, description }: SelectOption) => (
              <FormControlRadioGroupContent key={value}>
                <StyledFormControlLabel
                  key={value}
                  value={value}
                  label={label}
                  control={<StyledRadio />}
                />
                <StyledRadioDescription>
                  {description}
                </StyledRadioDescription>
              </FormControlRadioGroupContent>
            ))}
          </StyledRadioGroup>
        
          <FormControlHelperText description={description} error={error} />
        </FormControlRadioGroupContainer>
      )}
    />
  )
}
