// Standard libraries
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'next-i18next'

//UI
import { FindStore } from '../../components/FindStore'
import {
  AccountStoresTitle,
  AccountStoresBodyWrapper,
  AccountStoresList,
  AccountStoresCard,
  AccountStoresCardName,
  AccounStoresCardContent,
  MapContainer,
  AccountStoreAddress,
  AccountStoreInfo,
  LinkStoreInfo,
  LinksContainer,
  StyledDivider,
  AccountStoresCardHeader,
  StoreHoursTitle,
  BookEyeTestButton,
  DefaultStoreText,
  ToggleDefaultStore,
  MapImage,
} from './AccountStores.style'
import { Button } from '../../components/UI/Button'
import { StoreOpeningHours } from './components/StoreOpeningHours'
import { useFavoriteStores } from '../../hooks/useFavoriteStores'
import { isEmpty } from 'lodash-es'
import { useFavoriteStoresMethods } from '../../hooks/useFavoriteStoresMethods'
import { StyledLoader } from '../../components/UI'
import { generateStaticMapUrl } from '../../utils/staticMapUrl'
import { useSelector } from 'react-redux'
import { googleApiKeySelector } from '../../redux/selectors/site'
import { Store } from '../../types/store'
import { sendMyAccountEvent } from '@foundation/analytics/tealium/lib'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import styles from './styles/AccountStores.module.scss'

const formatTelNumber = (number: string) => {
  const tel = number.replace(/\s/g, '')
  const code = tel.slice(0, 5)
  return '(' + code + ') ' + tel.slice(5, 8) + ' ' + tel.slice(8)
}

export const StoreInfo: React.FC<{
  store: Store
  hideBookEyeBtn?: boolean
  className?: string
  isBoldStoreHours?: boolean
}> = ({ store, className, hideBookEyeBtn = false, isBoldStoreHours }) => {
  const openInNewTab = (url?: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  const { t } = useTranslation()

  const { ...analyticsDataForMyAccount } = useAnalyticsData(ANALYTICS_PAGE_TYPE.PROFILE)

  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsDataForMyAccount,
      Page_Section2: 'Profile',
      Page_Section3: 'Stores',
    })
  }, [])

  return (
    <AccountStoreInfo className={className}>
      <AccountStoreAddress>{store.completeAddress}</AccountStoreAddress>
      <LinksContainer>
        <LinkStoreInfo external href={`tel:${store.telephone}`}>
          {formatTelNumber(store.telephone)}
        </LinkStoreInfo>
        <LinkStoreInfo
          target="_blank"
          rel="noopener noreferrer"
          external
          href={`https://www.google.com/maps?q=${store.completeAddress}`}
        >
          {t('AccountStores.GetDirection')}
        </LinkStoreInfo>
      </LinksContainer>
      <StyledDivider />
      <StoreHoursTitle className={'store-info__store-hours-title'}>
        {t('AccountStores.StoreHoursTitle')}
      </StoreHoursTitle>
      <StoreOpeningHours store={store} isBoldStoreHours={isBoldStoreHours} />
      {!hideBookEyeBtn && (
        <BookEyeTestButton onClick={() => openInNewTab(store.bookAnEyeTestUrl)}>
          {t('AccountStores.BookEyeTest')}
        </BookEyeTestButton>
      )}
    </AccountStoreInfo>
  )
}

const AccountStores: FC = () => {
  const { t } = useTranslation()
  const googleMapsApiKey = useSelector(googleApiKeySelector)

  const openInNewTab = (url?: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const { favoriteStores, defaultFavoriteStoreId } = useFavoriteStores()
  const { changeDefaultFavoriteStore, removeFavoriteStore, isLoading } = useFavoriteStoresMethods()

  return (
    <AccountStoresBodyWrapper>
      {isLoading ? (
        <StyledLoader size={50} />
      ) : (
        <>
          <AccountStoresTitle> {t('AccountStores.Title')}</AccountStoresTitle>
          {isEmpty(favoriteStores) ? (
            <FindStore />
          ) : (
            <AccountStoresList>
              {favoriteStores.map(store => {
                const staticMapUrl = generateStaticMapUrl(store, googleMapsApiKey)
                return (
                  <AccountStoresCard key={store.storeId}>
                    <ToggleDefaultStore>
                      {defaultFavoriteStoreId === `${store.storeId}` ? (
                        <>
                          <SVGIcon library="validation" name="checkmark-rounded" className={styles.checkmarkIcon} />
                          <DefaultStoreText>{t('AccountStores.DefaultStore')}</DefaultStoreText>
                        </>
                      ) : (
                        <>
                          <input type={'checkbox'} onClick={() => changeDefaultFavoriteStore(`${store.storeId}`)} />
                          <DefaultStoreText>{t('AccountStores.SetDefaultStore')}</DefaultStoreText>
                        </>
                      )}
                    </ToggleDefaultStore>
                    <AccountStoresCardHeader>
                      <AccountStoresCardName>{store.storeName}</AccountStoresCardName>
                      <Button filltype={'outline'} onClick={() => removeFavoriteStore(`${store.storeId}`)}>
                        {t('AccountStores.Remove')}
                      </Button>
                    </AccountStoresCardHeader>
                    <StyledDivider />
                    <AccounStoresCardContent>
                      <MapContainer>
                        <MapImage src={staticMapUrl} alt={store.storeName}></MapImage>
                      </MapContainer>
                      <AccountStoreInfo>
                        <AccountStoreAddress>{store.completeAddress}</AccountStoreAddress>
                        <LinksContainer>
                          <LinkStoreInfo external href={`tel:${store.telephone}`}>
                            {store.telephone}
                          </LinkStoreInfo>
                          <LinkStoreInfo
                            target="_blank"
                            rel="noopener noreferrer"
                            external
                            href={`https://www.google.com/maps?q=${store.completeAddress}`}
                          >
                            {t('AccountStores.GetDirection')}
                          </LinkStoreInfo>
                        </LinksContainer>
                        <StyledDivider />
                        <StoreHoursTitle>{t('AccountStores.StoreHoursTitle')}</StoreHoursTitle>
                        <StoreOpeningHours store={store} />
                        <BookEyeTestButton onClick={() => openInNewTab(store.bookAnEyeTestUrl)}>
                          {t('AccountStores.BookEyeTest')}
                        </BookEyeTestButton>
                      </AccountStoreInfo>
                    </AccounStoresCardContent>
                  </AccountStoresCard>
                )
              })}
            </AccountStoresList>
          )}
        </>
      )}
    </AccountStoresBodyWrapper>
  )
}

export default AccountStores
