import styled from '@mui/material/styles/styled'
import { IconButton } from '@components/UI/IconButton'
import { StyledTypography } from '@components/UI/Typography'
import { StyledSwiper } from '@components/UI/Carousel/Carousel.style'
import { Z_INDEX_LV2 } from '../../../../constants/ui'

export const StyledSuggestedProductsWrapper = styled('section', {
  name: 'SuggestedProductsAlgolia',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  alignItems: 'center',
  color: theme.palette.text.dark.primary,
  lineHeight: 1.4,
  margin: '0 auto',
  maxWidth: '1440px',
}))

export const StyledSuggestedProductsTextContainer = styled('div', {
  name: 'SuggestedProducts',
  slot: 'TextContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  textAlign: 'center',
}))

export const StyledSuggestedProductsTitle = styled(StyledTypography, {
  name: 'SuggestedProducts',
  slot: 'Title',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: theme.spacing(5),
  fontWeight: '600',
  lineHeight: 1.33,
}))

export const StyledSuggestedProductsDescription = styled(StyledTypography, {
  name: 'SuggestedProducts',
  slot: 'Description',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.body1.fontSize,
}))

export const StyledSuggestedProductsText = styled(StyledTypography, {
  name: 'SuggestedProductsAlgolia',
  slot: 'Text',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  textTransform: 'uppercase',
  fontWeight: '600',
  lineHeight: 1.33,
  color: theme.palette.text.dark.primary,
}))

export const SliderContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderContainer',
  shouldForwardProp: prop => prop !== 'singleItem',
})<{ singleItem?: boolean }>(() => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledSingleItem = styled('div', {
  name: 'PdpDetailsAlgolia',
  slot: 'SingleItemContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '304px',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 'unset',
    flexGrow: 0.3,
  },
}))

export const SliderNavigationContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderNavigationContainer',
})(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.custom.white,
  borderRadius: '100%',
  boxShadow: theme.shadows[6],
  display: 'none',
  height: '32px',
  justifyContent: 'center',
  marginBottom: theme.spacing(12.5),
  width: '32px',
  zIndex: Z_INDEX_LV2,

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}))

export const SliderNavigationButton = styled(IconButton, {
  name: 'PdpDetails',
  slot: 'SliderNavigationButton',
})(() => ({
  padding: '0',
}))

export const StyledSuggestedProductsCarousel = styled(StyledSwiper, {
  name: 'Styled',
  slot: 'Swiper',
})(({ theme }) => ({
  justifyContent: 'center',
  width: 'calc(100% - 64px)',

  '.swiper-slide': {
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },

  '.swiper-pagination': {
    margin: `${theme.spacing(10)} auto 0`,
  },

  '.swiper-pagination-bullet': {
    height: '4px',
    width: '30px',
  },

  '.swiper-pagination-bullet-active': {
    width: '60px',
  },
}))
