import React, { ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import { FormControlHelperTextContainer, FormControlHelperTextDescription, FormControlHelperTextError } from './FormControlHelperText.style'

export interface FormControlHelperTextProps {
  description?: string | ReactNode,
  error?: FieldError,
}

export const FormControlHelperText: React.FC<FormControlHelperTextProps> = ({ description, error }) => {
  const isError = error && error?.message
  return (
    <FormControlHelperTextContainer className='FormControlHelperText-root'>
      {description &&
        <FormControlHelperTextDescription>
          {typeof description === 'string' && <span dangerouslySetInnerHTML={{__html: description}}></span>}
          {typeof description !== 'string' && description}
        </FormControlHelperTextDescription>
      }
      {' '}
      {isError &&
        <FormControlHelperTextError>
          {error.message}
        </FormControlHelperTextError>
      }
    </FormControlHelperTextContainer>
  )
}
