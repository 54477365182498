import styled from '@mui/material/styles/styled'
import { Button, StyledAnchor, StyledFormControlLabel, StyledTypography } from '../../../components/UI'

export const RegistrationContainer = styled('div', {
  name: 'RegistrationLayout',
  slot: 'RegistrationContainer',
})(({ theme }) => ({
  maxWidth: 600,
  padding: '1rem',

  [theme.breakpoints.up('lg')]: {
    width: 569,
  },
}))

export const StyledRegistrationTitle = styled(StyledTypography, {
  name: 'RegistrationLayout',
  slot: 'RegistrationContainer',
})(({ theme }) => ({
  fontSize: 16,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.up('lg')]: {
    fontSize: 40,
  },
}))

export const StyledRegistrationSubtitle = styled('div', {
  name: 'RegistrationLayout',
  slot: 'StyledRegistrationSubtitle',
})(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.33,
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    fontSize: 16,
    lineHeight: 1.5,
    paddingBottom: 0,
  },
}))

export const StyledMobileSeparator = styled('div', {
  name: 'RegistrationLayout',
  slot: 'StyledMobileSeparator',
})(({ theme }) => ({
  height: 2,
  flexGrow: 1,
  backgroundColor: theme.palette.background.light.tertiary,
  margin: 16,
}))

export const HeaderPaper = styled('div', {
  name: 'RegistrationLayout',
  slot: 'HeaderPaper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(0.5)}px`,

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(2)}px`,
  },

  h1: {
    fontSize: '40px',
    color: theme.palette.custom.cyprus,
  },

  h2: {
    fontSize: '16px',
    color: theme.palette.custom.cyprus,
  },
}))

export const StyledPolicyLink = styled(StyledAnchor, {
  name: 'RegistrationLayout',
  slot: 'StyledPolicyLink',
})({
  fontWeight: 'bold',
  textDecoration: 'underline',
})

export const RegistrationFormControlLabel = styled(StyledFormControlLabel, {
  name: 'RegistrationLayout',
  slot: 'RegistrationFormControlLabel',
})({
  alignItems: 'flex-start',
  '.MuiFormControlLabel-label': {
    maxWidth: 569,
  },
})

export const RegistrationFormWrapper = styled('div', {
  name: 'RegistrationLayout',
  slot: 'RegistrationFormWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  paddingTop: theme.spacing(8),
}))

export const StyledPrivacyCheckboxes = styled(StyledTypography, {
  name: 'RegistrationLayout',
  slot: 'StyledPrivacyCheckboxes',
})({
  fontSize: 16,
  lineHeight: '1.5',
})

export const SubmitButton = styled(Button, {
  name: 'RegistrationLayout',
  slot: 'SubmitButton',
})(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  overflow: 'visible',
  [theme.breakpoints.up('sm')]: {
    width: '221px',
  },
}))

export const StyledPersonalInfo = styled('div', {
  name: 'RegistrationLayout',
  slot: 'StyledPersonalInfo',
})(({ theme }) => ({
  marginTop: 0,
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.custom.boulder,
}))
