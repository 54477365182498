import { StyledButtonAsLink, StyledLinkAsButton } from '../../../components/UI'
import { Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const WishlistSignInAlert = styled('div')(() => ({}))

export const WishlistCta = styled(StyledButtonAsLink, {
  name: 'Wishlist',
  slot: 'Cta ',
})(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.custom.black,
}))

export const WishlistContainer = styled('div', {
  name: 'Wishlist',
  slot: 'Container'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.custom.black,
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row'
  }
}))

export const WishlistMainSection = styled('section', {
  name: 'Wishlist',
  slot: 'MainSection',
})(({ theme }) => ({
  flex: '0 0 66.667%',
  padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(16)}`
  }
}))

export const WishlistAsideSection = styled('section', {
  name: 'Wishlist',
  slot: 'AsideSection'
})(({ theme }) => ({
  flex: '1 1 33.333%',
  padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
  backgroundColor: theme.palette.custom.light.grey,
  height: '100%',
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(16)}`
  }
}))

export const WishlistHeader = styled('header', {
  name: 'Wishlist',
  slot: 'Header'
})(({ theme }) => ({
  paddingBottom: theme.spacing(6),
  color: theme.palette.text.dark.primary
}))

export const WishlistTitle = styled(Typography, {
  name: 'Wishlist',
  slot: 'Title'
})(({ theme }) => ({
  lineHeight: 1.25,
  fontSize: theme.spacing(8)
}))

export const WishlistDescription = styled(Typography, {
  name: 'Wishlist',
  slot: 'Description'
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  marginTop: theme.spacing(4.5),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(3)
  }
}))

export const WishlistItemsContainer = styled('main', {
  name: 'Wishlist',
  slot: 'ItemsContainer'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(0.25)}`,
  gap: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(3.25)
  },
  '.pdp__cta-wrapper': {
    marginTop: theme.spacing(5),
    div: {
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'end'
      },
      button: {
        width: theme.spacing(50),
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        }
      }
    }
  }
}))

export const WishlistEmptyButtonsContainer = styled('div', {
  name: 'Wishlist',
  slot: 'EmptyButtonsContainer'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    '& > button': {
      flex: '0 0 250px'
    }
  }
}))

export const StyledWishlistLinkCta = styled(StyledLinkAsButton, {
  name: 'Wishlist',
  slot: 'StyledWishlistLinkCTA'
})(({ theme }) => ({
  width: 'auto',
  height: theme.spacing(12),
  color: theme.palette.text.light.primary,
  [theme.breakpoints.up('sm')]: {
    minWidth: theme.spacing(50)
  }
}))
