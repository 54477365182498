import * as React from 'react'
import { TextField } from '../TextField'
import { countryCodeMap } from './CountryCodeMap'
import { StyledCountryCodeInput, StyledCountryCodeLabel } from './PhoneNumberField.style'

const nonFixedCountryLabel = '(+ 00 )'

const getEndAdornment = (
  props: PhoneNumberFieldProps,
  countryCodeInput: React.RefObject<HTMLInputElement>,
  flagCdn: string
): React.ReactNode => {
  if (props.showFlag) {
    if (!countryCodeMap.get(countryCodeInput?.current?.value || '')) {
      return null
    }
    else {
      return <img
        loading="lazy"
        width="20"
        src={`${flagCdn}w20/${countryCodeMap
          .get(countryCodeInput?.current ? countryCodeInput?.current.value : props.fixedCountryCode)
          ?.toLowerCase()}.png`}
        srcSet={`${flagCdn}w40/${countryCodeMap
          .get(countryCodeInput?.current ? countryCodeInput?.current.value : props.fixedCountryCode)
          ?.toLowerCase()}.png 2x`}
        alt="Country flag"
      />
    }
  }
  return null
}

export interface PhoneNumberFieldProps {
  id: string,
  label: string,
  required: boolean,
  showFlag: boolean,
  fixedCountryCode: string,
  value: string,
  useUndecoratedValue: boolean,
  inputRef: React.Ref<HTMLInputElement>,
  error: boolean,
  onValueChange: (value: string) => void,
}

const defaultProps: PhoneNumberFieldProps = {
  id: 'phoneNumber',
  label: 'Phone number',
  required: false,
  showFlag: true,
  fixedCountryCode: '',
  value: '',
  useUndecoratedValue: false,
  inputRef: null,
  error: false,
  onValueChange: () => { },
}

/**
 * To use within a form, use FormControlPhoneNumberField instead.
 */
export const PhoneNumberField = (props: PhoneNumberFieldProps) => {
  const [isShowLabel, setShowLabel] = React.useState<boolean>(props.fixedCountryCode === '' ? false : true)
  const [label, setLabel] = React.useState<string>(props.fixedCountryCode ? '(+' : nonFixedCountryLabel)
  const [placeholder, setPlaceholder] = React.useState<string>('')
  const [phoneNumber, setPhoneNumber] = React.useState<string>(props.value)
  const [countryCode, setCountryCode] = React.useState<string>(props.fixedCountryCode)

  const countryCodeInput = React.useRef<HTMLInputElement>(null)
  const phoneInput = React.useRef<HTMLDivElement>(null)

  const FLAG_CDN = 'https://flagcdn.com/'
  const MAX_LENGTH = 13

  const error = props.error || false

  return (
    <TextField
      id={props.id}
      label={props.label}
      type="tel"
      name="phone1"
      value={phoneNumber}
      showvalidationstatus={true}
      error={error}
      isvalid={!error && !!phoneNumber?.length}
      ispristine={!props.value?.length}
      inputProps={{ maxLength: MAX_LENGTH, ref: { phoneInput } }}
      fullWidth
      onFocus={() => {
        const countryCodeElem = countryCodeInput.current
        if (countryCodeElem?.value === '' && phoneNumber === '') {
          countryCodeElem?.focus()
        }
      }}
      onChange={(e: any) => {
        let formattedVal = ''
        const input = e.target.value.replace(/\D/g, '').substring(0, 10)
        if (input) {
          const zip = input.substring(0, 3)
          const middle = input.substring(3, 6)
          const last = input.substring(6, 10)
          if (input.length > 6) {
            formattedVal = `(${zip})${middle}-${last}`
          } else if (input.length > 3) {
            formattedVal = `(${zip})${middle}`
          } else if (input.length > 0) {
            formattedVal = `(${zip}`
          }
        }
        setPhoneNumber(formattedVal)
        props.onValueChange(props.useUndecoratedValue ? countryCode + input : countryCode + formattedVal)
      }}
      customInputProps={{
        ref: phoneInput,
        startAdornment: (
          <>
            {<StyledCountryCodeLabel>{label}</StyledCountryCodeLabel>}
            <StyledCountryCodeInput
              ref={countryCodeInput}
              value={countryCode}
              type="tel"
              maxLength={3}
              readOnly={props.fixedCountryCode !== ''}
              placeholder={placeholder}
              onChange={(e: any) => {
                setCountryCode(e.target.value)
                props.onValueChange(e.target.value + phoneNumber)
                if (e.target.value.length > 1) {
                  const phoneNumberElem = phoneInput?.current?.children[1] as HTMLElement
                  phoneNumberElem?.focus()
                }
              }}
              onFocus={(e: any) => {
                if (e.target.value === '') {
                  e.target.value = ''
                  setPlaceholder('00')
                  setLabel('(+')
                  setShowLabel(true)
                }
              }}
              onBlur={(e: any) => {
                if (phoneNumber.length === 0 && e.target.value.length === 0) {
                  setPlaceholder('')
                  setLabel(nonFixedCountryLabel)
                  setShowLabel(false)
                }
              }}
            ></StyledCountryCodeInput>
            {isShowLabel && <StyledCountryCodeLabel>)</StyledCountryCodeLabel>}
          </>
        ),
        endAdornment: getEndAdornment(props, countryCodeInput, FLAG_CDN)
      }}
    />
  )
}

PhoneNumberField.defaultProps = defaultProps
