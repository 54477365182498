import Axios, { Canceler } from 'axios'
import { CheckoutPayload } from '../../../types/checkout'
import React, { useState } from 'react'
import { cartSelector, selectedPayMethodsSelector } from '../../../features/order/selector'
import { useSelector } from 'react-redux'

import { ACCOUNT } from '../../../foundation/constants/common'
import { BILLING_ADDRESS_ID } from '../../../constants/checkout'
import Log from '../../../services/Log'
import { PO_NUMBER } from '../../../constants/order'
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import { orderApi } from '../../../features/order/query'
import { useCheckoutPaths } from '../../../foundation/hooks/useCheckoutPaths'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useRouter } from 'next/navigation'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'

const poNumber = ''
const CancelToken = Axios.CancelToken
let cancels: Canceler[] = []

export const useZeroCheckout = webId => {
  const router = useRouter()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const checkoutPaths = useCheckoutPaths(langCode)

  const cart = useSelector(cartSelector)
  const contractId = useSelector(currentContractIdSelector)
  const selectedPaymentInfoList = useSelector(selectedPayMethodsSelector)
  const [zeroCheckoutPaymentLoading, setZeroCheckoutPaymentLoading] = useState<boolean>(false)
  const { completeCheckoutStep } = useCheckoutSteps()

  const [deleteAllPaymentInstructions] = orderApi.endpoints.deleteAllPaymentInstructions.useLazyQuery()
  const [addPaymentInstruction] = orderApi.endpoints.addPaymentInstruction.useLazyQuery()

  const isPONumberRequired = React.useMemo(() => cart?.x_isPurchaseOrderNumberRequired === 'true', [cart])
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const payloadBase: CheckoutPayload = {
    currency: defaultCurrencyID,
    contractId: contractId,
    storeId: mySite.storeID,
    responseFormat: 'application/json',
    widget: 'usePayment',
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
    webId: webId ? webId : undefined,
  }

  /**
   * Validate billing address id for a payment
   * @returns Whether or not the billing address is valid
   */
  const isValidBillingAddress = (paymentNumber: number): boolean => {
    if (paymentNumber >= selectedPaymentInfoList.length || selectedPaymentInfoList[paymentNumber].addressId === '') {
      return false
    }

    return true
  }

  /**
   * Validate billing address id list and payment options/credit card inputs for all payments
   * @returns Whether or not all payment selections is valid
   */
  const isValidPaymentList = () => {
    const isZero = parseInt(cart.grandTotal) === 0
    if (isZero) {
      return true
    }
    if (selectedPaymentInfoList.length < 1) {
      return false
    }

    for (let i = 0; i < selectedPaymentInfoList.length; i++) {
      if (!isValidBillingAddress(i)) {
        return false
      }
    }

    return true
  }

  /**
   * Validate whether po number is required and specified
   * @returns Whether po number is valid or needed
   */
  function isValidPO() {
    return !(isPONumberRequired && poNumber.trim() === '')
  }

  /**
   * Validate whether shopper can proceed to next step
   * @returns Whether next step is allowed
   */
  function canContinue() {
    return isValidPaymentList() && isValidPO()
  }

  /**
   * Submit the selected payment method and billing address information
   */
  const submitZeroCheckout = async () => {
    if (!canContinue()) {
      return
    }

    if (isPONumberRequired && cart) {
      localStorageUtil.set(ACCOUNT + '-' + PO_NUMBER + '-' + cart.orderId, poNumber)
    }

    await deleteAllPaymentInstructions(payloadBase)

    const payMethodInfo = {
      piAmount: '0',
      billing_address_id: localStorageUtil.get(BILLING_ADDRESS_ID),
      payMethodId: 'Zero',
      cc_brand: 'Zero',
    }

    const payload: CheckoutPayload = {
      ...payloadBase,
      body: payMethodInfo,
    }

    setZeroCheckoutPaymentLoading(true)

    try {
      await addPaymentInstruction(payload).catch(e => {
        throw e
      })
      completeCheckoutStep('payment')

      router.push(checkoutPaths['order-confirmation'])
    } catch (e: any) {
      setZeroCheckoutPaymentLoading(false)
      Log.error('payment add error: ' + e.message, window.location.href)
    }
  }

  return {
    zeroCheckoutPaymentLoading,
    submitZeroCheckout,
  }
}
