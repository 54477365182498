import DateService from '../../../services/DateService'

export enum FilterOption {
  LAST_6_MONTHS = 'Last6Months',
  LAST_YEAR = 'LastYear',
  ALL = 'All',
}

export const filterOptionToDate = (option: FilterOption, langCode: string): string => {
  const dateParam = new Date()
  switch (option) {
    case FilterOption.LAST_6_MONTHS:
      dateParam.setMonth(dateParam.getMonth() - 6)
      break
    case FilterOption.LAST_YEAR:
      dateParam.setMonth(dateParam.getMonth() - 12)
      break
    default:
      dateParam.setFullYear(dateParam.getFullYear() - 100)
      break
  }
  return DateService.format(dateParam, 'yyyy-MM-dd', langCode)
}
