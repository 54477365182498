import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'

import cartService from '../../foundation/apis/transaction/cart.service'

export const useAbandonedOrder = () => {
  const router = useRouter()
  const { abandonedOrderId: qsAbandonedOrderId } = router.query as {
    abandonedOrderId: string
  }

  const sendAbandonedOrderId = useCallback(
    async (qsAbandonedOrderId: string) => {
      await cartService
        .createCart({ abandonedOrderId: decodeURI(qsAbandonedOrderId) })
        .catch((ex) => {
          throw ex
        })
    },
    []
  )

  useEffect(() => {
    if (qsAbandonedOrderId) {
      sendAbandonedOrderId(qsAbandonedOrderId)
    }
  }, [qsAbandonedOrderId])
}
