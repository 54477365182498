import styled from '@mui/material/styles/styled'
import { StyledAnchor } from '../../components/UI'
import { StyledButton } from '@components/UI/Button/Button'

export const AccountStoresBodyWrapper = styled('div', {
  name: 'AccountStores',
  slot: 'BodyWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
  marginTop: theme.spacing(4),
  minHeight: '100%',

  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(4),
    paddingLeft: 67,
    paddingRight: theme.spacing(16),
    paddingBottom: 65,
  },
}))

export const AccountStoresTitle = styled('div', {
  name: 'AccountStores',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 600,
  lineHeight: 1.4,
  color: theme.palette.text.dark.primary,
}))

export const AccountStoresList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}))

export const AccountStoresCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(4),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
    alignItems: 'flex-start',
  },
}))

export const AccountStoresCardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const AccountStoresCardName = styled('div', {
  name: 'AccountStores',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    marginBottom: 'unset',
  },
}))

export const AccounStoresCardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

export const MapContainer = styled('div')(({ theme }) => ({
  height: '234px',
  position: 'relative',
  marginBottom: theme.spacing(4),
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    height: 'unset',
    marginRight: theme.spacing(4),
    marginBottom: 'unset',
    width: '40%',
  },

  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

export const MapImage = styled('img')(({}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

export const AccountStoreInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(4),
    width: '60%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

export const AccountStoreAddress = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  color: theme.palette.text.dark.primary,
}))

export const LinksContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: `${theme.spacing(3.5)} 0 0`,
}))

export const LinkStoreInfo = styled(StyledAnchor, {
  name: 'AccountStores',
  slot: 'Link',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontWeight: 600,
  '&::after': {
    background: theme.palette.text.dark.primary,
  },
  '&:hover': {
    color: theme.palette.text.dark.primary,
  },
}))

export const StyledDivider = styled('div', {
  name: 'AccountStores',
  slot: 'Divider',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  width: '100%',
  height: 1,
  backgroundColor: theme.palette.background.light.tertiary,
}))

export const StoreHoursTitle = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  lineHeight: 1.33,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  marginBottom: '6px',
}))

export const BookEyeTestButton = styled(StyledButton)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    maxWidth: 190,
  },
}))

export const DefaultStoreText = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  marginLeft: theme.spacing(4),
}))

export const ToggleDefaultStore = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    marginBottom: 'unset',
  },
}))
