import styled from '@mui/material/styles/styled'
import {
  AccordionSummary,
  Button,
  GridContainer,
  GridItem,
  TextField,
  StyledButtonAsLink,
} from '../../components/UI'
import { Typography } from '@mui/material'

const CartWrapper = styled(GridContainer, {
  name: 'Cart',
  slot: 'Wrapper'
})(() => ({}))

const EmptyCartContainer = styled(GridItem, {
  name: 'Cart',
  slot: 'EmptyCartContent'
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    placeItems: 'center',
    gridColumnStart: 1,
    gridColumnEnd: 13
  }
}))

const CartLeftColumn = styled(GridItem, {
  name: 'Cart',
  slot: 'LeftColumn'
})(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingBottom: theme.spacing(8),
  }
}))

const CartRightColumn = styled(GridItem, {
  name: 'Cart',
  slot: 'RightColumn'
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.quinary,
  color: theme.palette.text.dark.primary,
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(6)} ${theme.spacing(16)} 0`
  }
}))

const StyledDivider = styled('div', {
  name: 'Cart',
  slot: 'Divider'
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(6),
  width: '100%',
  height: 1,
  backgroundColor: theme.palette.text.light.tertiary
}))

const ShippingComponentContainer = styled('div', {
  name: 'Cart',
  slot: 'ShippingComponentContainer'
})(() => ({
  position: 'relative'
}))

const StyledLoadingContainer = styled('div', {
  name: 'Cart',
  slot: 'LoadingContainer'
})(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const CartPromoCodeAccordionSummary = styled(AccordionSummary, {
  name: 'CartPromoCode',
  slot: 'Summmary'
})(({ theme }) => ({
  border: 0,
  margin: 0,
  padding: 0,
  width: 'auto',
  [theme.breakpoints.up('sm')]: {
    width: '31%'
  }
}))

const CartPromoCodeField = styled(TextField, {
  name: 'CartPromoCode',
  slot: 'TextField'
})(({ theme }) => ({
  background: theme.palette.background.light.primary,
  maxWidth: 343,
  '& .MuiFormHelperText-root.Mui-error': {
    background: theme.palette.background.light.primary,
    margin: 0,
    paddingLeft: theme.spacing(2.5)
  }
}))

const CartPromoCodeApplyButton = styled(Button, {
  name: 'CartPromoCodeAccordion',
  slot: 'ApplyButton'
})(({ theme }) => ({
  color: theme.palette.text.dark.secondary,
  whiteSpace: 'nowrap',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 163
  },
}))

const CartPromoCodeAccordionContainer = styled('div', {
  name: 'CartPromoCodeAccordion',
  slot: 'Container'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(7)
}))

const CartPromoCodeAppliedContainer = styled('div', {
  name: 'CartPromoCodeAccordion',
  slot: 'AppliedContainer'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(8),
  padding: 0,
  flexGrow: 0,
  justifyContent: 'flex-start',
  alignItems: 'center'
}))

const CartPromoCodeAppliedMessage = styled('span', {
  name: 'CartPromoCodeAccordion',
  slot: 'AppliedMessage'
})(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.color.success
}))

const CartPromoCodeRemoveButton = styled(StyledButtonAsLink, {
  name: 'CartPromoCodeAccordion',
  slot: 'RemovePromoCode'
})(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
  lineHeight: 1.33,
  textAlign: 'left'
}))

const RemovedItemNotificationContainer = styled('div', {
  name: 'Cart',
  slot: 'RemovedItemContainer'
})(() => ({
  width: '100%'
}))

const RemovedLastItemContainer = styled('div', {
  name: 'Cart',
  slot: 'RemovedLastItemContainer'
})(({ theme }) => ({
  width: '100%',
  placeItems: 'center',
  gridColumnStart: 1,
  gridColumnEnd: 13,
  padding: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(16)
  },
}))

const RemovedLastItemTitle = styled(Typography, {
  name: 'Cart',
  slot: 'RemovedLastItemTitle'
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: 30,
  fontWeight: 'normal',
  lineHeight: 2,
  [theme.breakpoints.up('sm')]: {
    fontSize: 40
  },
}))

const RemovedLastItemDescription = styled(Typography, {
  name: 'Cart',
  slot: 'RemovedLastItemDescription'
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: 14,
  [theme.breakpoints.up('sm')]: {
    fontSize: 16
  }
}))

export {
  CartPromoCodeApplyButton,
  CartWrapper,
  CartLeftColumn,
  CartRightColumn,
  EmptyCartContainer,
  StyledDivider,
  ShippingComponentContainer,
  CartPromoCodeAccordionSummary,
  CartPromoCodeField,
  CartPromoCodeAccordionContainer,
  CartPromoCodeAppliedContainer,
  StyledLoadingContainer,
  CartPromoCodeAppliedMessage,
  CartPromoCodeRemoveButton,
  RemovedItemNotificationContainer,
  RemovedLastItemContainer,
  RemovedLastItemTitle,
  RemovedLastItemDescription,

}
