import { MenuItem, Select } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { Button, StyledButtonAsLink, TextField } from '../../components/UI'
import { ModalDialog } from '../../components/UI'

export const StyledEmptyMessage = styled('p', {
  name: 'Prescriptions',
  slot: 'StyledEmptyMessage',
})(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}))

export const InfoContainer = styled('div', {
  name: 'Prescriptions',
  slot: 'InfoContainer',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.custom.light.green,
  '&.error': {
    backgroundColor: theme.palette.custom.light.red,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const InfoContainerSection = styled('div', {
  name: 'Prescriptions',
  slot: 'InfoContainerSection',
})(({ theme }) => ({
  width: 'calc(100% - 80px)',
  marginTop: theme.spacing(2.5),
  marginLeft: theme.spacing(1.25),
}))

export const InfoContainerSectionSmall = styled(
  InfoContainerSection,
  {}
)(({theme}) => ({
  width: theme.spacing(7.5),
}))

export const InfoContainerSectionTitle = styled(
  'div',
  {}
)(({}) => ({
  fontSize: '1rem',
  lineHeight: '1.5',
  fontWeight: 700,
}))

export const InfoContainerSectionDescription = styled(
  'div',
  {}
)(({}) => ({
  lineHeight: '1.5',
}))

export const PrescriptionsContainer = styled('div', {
  name: 'Prescriptions',
  slot: 'PrescriptionsContainer',
})(({ theme }) => ({
  rowGap: theme.spacing(8),
  paddingLeft: theme.spacing(16),
  paddingRight: theme.spacing(16),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(16),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const PrescriptionViewContainer = styled('div', {
  name: 'Prescriptions',
  slot: 'PrescriptionViewContainer',
})(({ theme }) => ({
  maxWidth: theme.spacing(207.5),
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '1.5rem',
  backgroundColor: theme.palette.custom.light.grey,
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  borderRadius: theme.spacing(2.25),
}))

export const PrescriptionHeader = styled('div', {
  name: 'Prescriptions',
  slot: 'PrescriptionHeader',
})(({}) => ({
  width: '100%',
}))
export const PrescriptionHeaderRow = styled('div', {
  name: 'Prescriptions',
  slot: 'PrescriptionHeaderRow',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  padding: `0 ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
  '&.first': {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4),
    },
  },
}))
export const PrescriptionHeaderComponent = styled('div', {
  name: 'Prescriptions',
  slot: 'PrescriptionHeaderComponent',
})(({ theme }) => ({
  width: '50%',

  '&.cta': {
    textAlign: 'right',
    '& [type="button"]': {
      display: 'inline-flex',
    },

    [theme.breakpoints.down('sm')]: {
      '&.edit': {
        textAlign: 'left',
      },
      '&.delete': {
        textAlign: 'right',
      },
    },
  },
  '&.text': {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const RXIViewtemsSection = styled('div', {
  name: 'Prescriptions',
  slot: 'RXIViewtemsSection',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '1rem',
  borderRadius: `0  0 ${theme.spacing(2.25)} ${theme.spacing(2.25)}`,
  backgroundColor: theme.palette.background.light.primary,
}))

export const RXItemSection = styled('div', {
  name: 'Prescriptions',
  slot: 'RXItemSection',
})(({ theme }) => ({
  width: theme.spacing(177.5),
  [theme.breakpoints.down('md')]: {
    width: '96%',
  },
  display: 'flex',
  flexWrap: 'wrap',
  margin: `${theme.spacing(8)} 0 ${theme.spacing(8)} 0`,
  backgroundColor: theme.palette.background.light.primary,
  borderLeft: `solid 0.5px ${theme.palette.background.light.tertiary}`,
  borderTop: `solid 0.5px ${theme.palette.background.light.tertiary}`,
}))

export const RXViewSectionItem = styled('div', {
  name: 'Prescriptions',
  slot: 'RXViewSectionItem',
  shouldForwardProp: prop => prop !== 'hasError',
})<{ hasError?: boolean }>(({ theme, hasError }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  width: '18%',
  height: theme.spacing(16),
  color: hasError ? 'red' : 'black',
  borderRight: `solid 0.5px ${theme.palette.background.light.tertiary}`,
  borderBottom: `solid 0.5px ${theme.palette.background.light.tertiary}`,
  [theme.breakpoints.down('md')]: {
    width: '17%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '32%',
  },
  '&.singlePD': {
    [theme.breakpoints.up('md')]: {
      borderBottom: 0,
      alignItems: 'end',
    },
    [theme.breakpoints.down('sm')]: {
      borderRight: 0,
      justifyContent: 'end',
    },
  },
}))

export const RXViewSectionItemHeader = styled(RXViewSectionItem, {
  shouldForwardProp: prop => prop !== 'highLight',
  name: 'Prescriptions',
  slot: 'RXViewSectionItemHeader',
})<{ highLight?: boolean }>(({ theme, highLight }) => ({
  fontWeight: 700,
  height: theme.spacing(13.5),
  backgroundColor: highLight ? theme.palette.custom.light.grey : theme.palette.custom.white,
  '&.left': {
    maxWidth: '70px',
    textAlign: 'center',
    height: '64px',
    borderTop: 0,
  },
  '&.empty': {
    maxWidth: theme.spacing(17.5),
  },
}))

export const PDContainer = styled('div', {
  name: 'Prescriptions',
  slot: 'PDContainer',
})(({}) => ({
  display: 'flex',
  flexWrap: 'wrap',
}))

export const PrescriptionsWrapper = styled('div', {
  name: 'Prescriptions',
  slot: 'PrescriptionsWrapper',
})(({}) => ({}))

export const PrescriptionsTitle = styled('div', {
  name: 'Prescriptions',
  slot: 'Title',
})(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  fontSize: theme.typography.h4.fontSize,
  fontWeight: 600,
  lineHeight: 1.4,
  color: theme.palette.text.dark.primary,
}))

export const SavePrescriptionWrapper = styled('div', {
  name: 'Prescriptions',
  slot: 'SavePrescriptionWrapper',
})(({ theme }) => ({
  display: 'flex',
  rowGap: theme.spacing(6),
  alignItems: 'flex-start',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

export const SavePrescriptionCTAWrapper = styled('div', {
  name: 'Prescriptions',
  slot: 'SavePrescriptionCTAWrapper',
})(({}) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: '1rem',
}))

export const SaveCTA = styled(Button, {
  name: 'Prescriptions',
  slot: 'SaveCTA',
})(({ theme }) => ({
  color: theme.palette.background.light.primary,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
}))

export const DeleteCTA = styled(Button, {
  name: 'Prescriptions',
  slot: 'DeleteCTA',
})(({ theme }) => ({
  color: theme.palette.background.light.primary,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    width: '50%',
  },
}))

export const CancelCTA = styled(DeleteCTA, {
  name: 'Prescriptions',
  slot: 'CancelCTA',
})(({ theme }) => ({
  color: theme.palette.background.dark.primary,
}))

export const UploadPrescriptionCTA = styled(Button, {
  name: 'Prescriptions',
  slot: 'UploadPrescriptionCTA',
})(({ theme }) => ({
  color: theme.palette.background.dark.primary,
  whiteSpace: 'nowrap',
  width: theme.spacing(62.5),
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}))

export const RxCTA = styled(StyledButtonAsLink, {
  name: 'Prescriptions',
  slot: 'RxCTA',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  svg: {
    marginRight: theme.spacing(1),
  },
  ':after': {
    content: 'none',
  },
}))

export const ViewRxCTA = styled(RxCTA, {
  name: 'Prescriptions',
  slot: 'ViewRxCTA',
})(({ theme }) => ({
  display: 'flex',
  margin: ` 0 0 ${theme.spacing(2)} 0`,
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'left',
    marginBottom: theme.spacing(2.5),
  },
}))

export const DownloadRxLink = styled('a', {
  name: 'Prescriptions',
  slot: 'DownloadRxLink',
})(({}) => ({
  textDecoration: 'none',
}))

export const StyledModalDialog = styled(ModalDialog, {
  name: 'Prescriptions',
  slot: 'StyledModalDialog',
  shouldForwardProp: prop => prop !== 'isSaveRxModal' && prop != 'isDeleteRxModal',
})<{ isSaveRxModal?: boolean; isDeleteRxModal?: boolean }>(({ isSaveRxModal, isDeleteRxModal, theme }) => ({
  '> .ModalDialog--wrapper': {
    backgroundColor: isSaveRxModal ? theme.palette.custom.light.grey : theme.palette.background.light.primary,
    maxWidth: '630px',
    minHeight: isDeleteRxModal ? 'auto' : theme.spacing(175),
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      minHeight: isDeleteRxModal ? 'auto' : theme.spacing(150),
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: isDeleteRxModal ? 'auto' : theme.spacing(157.5),
      maxWidth: '95%',
      overflowY: 'scroll',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: '9px',
      minWidth: theme.spacing(157.5),
    },
    '> .ModalDialog--content': {
      paddingTop: theme.spacing(4),
    },
  },
}))

export const DeletePrescriptionSubSection = styled('div', {
  name: 'Prescriptions',
  slot: 'DeletePrescriptionSubSection',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
}))

export const ViewRXSubSection = styled('div', {
  name: 'Prescriptions',
  slot: 'ViewRXSubSection',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    width: '40%',
    flexDirection: 'column',
    justifyContent: 'left',
  },
}))

export const ViewRXFileContainer = styled('div', {
  name: 'Prescriptions',
  slot: 'ViewRXFileContainer',
})(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}))

export const DeletePrescriptionConfirmationDialog = styled('div', {
  name: 'Prescriptions',
  slot: 'DeletePrescriptionConfirmationDialog',
})({
  width: '100%',
  fontSize: '1.5rem',
  fontWeight: 700,
  lineHeight: '2rem',
  textAlign: 'center',
  marginBottom: '1rem',
})

export const UploadRXSection = styled('div', {
  name: 'Prescriptions',
  slot: 'UploadRXSection',
})(({ theme }) => ({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const SavePrescriptionContainer = styled('div', {
  name: 'Prescriptions',
  slot: 'SavePrescriptionContainer',
})(({}) => ({
  width: '98%',
  margin: '2%',
}))

export const SavePrescriptionModalDialogTitle = styled('div', {
  name: 'Prescriptions',
  slot: 'SavePrescriptionModalDialogTitle',
})(({ theme }) => ({
  fontWeight: 700,
  lineHeight: theme.spacing(6),
  whiteSpace: 'nowrap',
  paddingBottom: theme.spacing(2),
}))

export const HelpLink = styled('a', {
  name: 'Prescriptions',
  slot: 'HelpLink',
})(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 700,
  textDecoration: 'underline',
  lineHeight: theme.spacing(6),
}))

export const StyledTextFieldWrapper = styled('div')(({ theme }) => ({
  paddingTop: '2rem',
  width: '98%',
  paddingBottom: 32,
  '.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.custom.white,
  },
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-input': {
    backgroundColor: theme.palette.custom.white,
  },
}))

export const PrescriptionSection = styled('div', {
  name: 'Prescriptions',
  slot: 'PrescriptionSection',
})(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  margin: '-22px',
  marginTop: 0,
  border: `${theme.spacing(5.5)} solid ${theme.palette.custom.white}`,
  borderTop: 0,
}))

export const PrescriptionInfoPanel = styled('div')(({theme}) => ({
  width: '100%',
  minHeight: theme.spacing(37.5),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const RowsBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '&.od-os-label-block': {
    width: theme.spacing(15),
  },
  '.labels-container': {
    width: theme.spacing(15),
    height: '100%',
    paddingTop: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'flex-end',
  },
}))

export const RowsBlockMobile = styled(RowsBlock)(({theme}) => ({
  marginBottom: theme.spacing(2.5),
  '&.od-os-label-block': {
    width: theme.spacing(15),
  },
}))

export const RXBlock = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: theme.spacing(36.25),
  width: theme.spacing(35),
}))

export const RXBlockMobile = styled(RXBlock)(({theme}) => ({
  height: theme.spacing(50),
  width: '99%',
}))

export const FreeLabel = styled('div')(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '1rem',
  '&.pd-label': {
    fontSize: '0.9rem',
    width: '100%',
    lineHeight: '1.3rem',
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    '.prescription-page-what-is-pd-link': {
      marginLeft: theme.spacing(5),
    },
  },
  '&.od-os-labels': {
    marginRight: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(6.25),
      marginRight: theme.spacing(2.5),
    },
  },
  '&.rx-name': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    marginBottom: theme.spacing(2.5),
  },
}))

export const RangeSelectorContainer = styled('div', {
  name: 'Prescriptions',
  slot: 'RangeSelectorContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  marginBottom: theme.spacing(4),
  '.is-select': {
    width: theme.spacing(27.5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  '&.puppilary-distance-container': {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}))

export const RangeSelector = styled(Select, {
  name: 'Prescriptions',
  slot: 'RangeSelector',
})(({ theme }) => ({
  fontSize: '0.75rem',
  position: 'relative',
  lineHeight: '1rem',
  maxWidth: theme.spacing(26.75),
  '&.range-selector': {
    display: 'flex',
    alignItems: 'center',
    height: '2rem',
  },
  '&.pd-range-selector': {
    margin: '0 10px 10px 2px',
    width: theme.spacing(25),
  },
}))

export const PanelDivider = styled('div', {
  name: 'Prescriptions',
  slot: 'PanelDivider',
})(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(5),
  borderBottom: `1px solid ${theme.palette.custom.light2.grey}`,
  lineHeight: 1,
}))

export const CheckboxContainer = styled('div')(({}) => ({
  fontSize: '0.9rem',
  display: 'flex',
  flexWrap: 'wrap',
}))

export const StyledMenuItem = styled(MenuItem, {
  name: 'Prescriptions',
  slot: 'StyledMenuItem',
})(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '1rem',
  height: '1.3rem',
  '&:hover': {
    color: theme.palette.custom.white,
  },
}))
