import * as React from 'react'
import { CustomInputFieldProps } from '../../../types/inputFields'
import InputAdornment from '@mui/material/InputAdornment'
import { useTheme } from '@mui/material/styles'
import { BootstrapInput, StyledTextFieldWrapper } from './TextField.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import styles from './styles/TextField.module.scss'
import clsx from 'clsx'

export const TextField = ({
  showvalidationstatus,
  showclearbutton,
  error,
  isfilled,
  isvalid,
  onClearButtonClick,
  customInputProps,
  SelectProps,
  type,
  select,
  ...rest
}: CustomInputFieldProps) => {
  const theme = useTheme()

  const wrapperClassNames = ['textfield-wrapper', ...(isvalid ? ['is-valid'] : []), ...(select ? ['is-select'] : [])]

  const getEndAdornments = () => {
    let validationIcons: React.ReactNode | null = null
    if (showclearbutton && !error && isfilled) {
      validationIcons = (
        <button
          type="button"
          className={styles.clearButton}
          onClick={() => onClearButtonClick && onClearButtonClick()}
          aria-label="Clear text"
          title="Clear text"
        >
          <SVGIcon library="close" name="close-circle" color={theme.palette.custom?.boulder} />
        </button>
      )
    } else if (error && showvalidationstatus) {
      validationIcons = <SVGIcon library="validation" name="alert" />
    } else if (isvalid && showvalidationstatus) {
      validationIcons = <SVGIcon library="validation" name="validate" />
    }
    return (
      <InputAdornment
        position="end"
        className={clsx(error && showvalidationstatus && styles.alert, isvalid && showvalidationstatus && styles.valid)}
      >
        {validationIcons}
        {customInputProps?.endAdornment}
      </InputAdornment>
    )
  }

  const getStartAdornments = () => {
    return customInputProps?.startAdornment ? (
      <InputAdornment position="start">{customInputProps?.startAdornment}</InputAdornment>
    ) : null
  }

  const ArrowDown = () => (
    <SVGIcon
      library="arrow"
      name="arrow-down"
      color={theme.palette.background.dark.primary}
      className={styles.arrowDown}
    />
  )

  return (
    <StyledTextFieldWrapper
      className={wrapperClassNames.join(' ')}
      showvalidationstatus={showvalidationstatus}
      isvalid={isvalid}
    >
      <BootstrapInput
        InputProps={{
          ...customInputProps,
          startAdornment: getStartAdornments(),
          endAdornment: getEndAdornments(),
        }}
        SelectProps={{
          ...SelectProps,
          IconComponent: ArrowDown,
          MenuProps: {
            disableScrollLock: true,
            disablePortal: true,
            elevation: 0,
            marginThreshold: 0,
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          },
        }}
        type={type}
        error={error}
        variant="outlined"
        select={select}
        {...rest}
      />
    </StyledTextFieldWrapper>
  )
}
