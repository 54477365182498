import { CMS_MODULES } from '@components/Cms/constants'

export const getDataElementIdByModule = ({ tileIndex, viewType }) => {
  switch (viewType) {
    case CMS_MODULES.RECENTLY_VIEWED:
      return `X_X_RecentlyViewed_Tile${tileIndex}`
    case CMS_MODULES.YOU_MAY_ALSO_LIKE:
      return `X_X_AlsoLike_Tile${tileIndex}`
    default:
      return `X_X_Tiles_Tile${tileIndex}_Img`
  }
}
