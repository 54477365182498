import styled from '@mui/material/styles/styled'
import { Button, StyledAnchor } from '../../../../components/UI'

export const StyledApplePayCheckoutButton = styled(Button, {
  name: 'CartCheckoutButtonsContainer',
  slot: 'ApplePayCheckoutButton',
})(({ theme }) => ({
  backgroundColor: 'black',
  borderRadius: 8,
  marginLeft: 0,
  marginRight: 0,

  svg: {
    marginLeft: theme.spacing(1),
  },
}))

export const StyledCheckoutButtonsWrapper = styled('div', {
  name: 'CartCheckoutButtonsContainer',
  slot: 'CheckoutButtonsWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginTop: theme.spacing(6),
  alignItems: 'center',
}))

export const StyledTermsLink = styled(StyledAnchor, {
  name: 'CartCheckoutButtonsContainer',
  slot: 'TermsLink',
})(() => ({
  fontWeight: 'bold',
}))

export const StyledTerms = styled('div', {
  name: 'CartCheckoutButtonsContainer',
  slot: 'Terms',
})(() => ({
  width: 320,
  fontSize: 12,
  textAlign: 'center',
  textJustify: 'inter-word',
}))

export const StyledCheckoutButton = styled(Button, {
  name: 'CartCheckoutButtonsContainer',
  slot: 'CheckoutButton',
})(({ theme }) => ({
  color: theme.palette.background.light.primary,
  backgroundColor: theme.palette.custom.light1.green,
  borderColor: theme.palette.custom.green,
}))
