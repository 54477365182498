import { useEffect } from 'react'

interface IdleTimerProps {
  onIdle: () => void,
  timeout: number,
}

export const useIdleTimer = ({onIdle, timeout}: IdleTimerProps): void => {
  let timer: NodeJS.Timeout
  
  const idleTimer = () => {
    timer = setTimeout(() => {
      onIdle()
    }, timeout)
  }

  const resetCountdown = () => {
    clearTimeout(timer)
    idleTimer()
  }
  
  useEffect(() => {
    window.addEventListener('mousemove', resetCountdown)
    window.addEventListener('keypress', resetCountdown)
  }, [])
}
