import React from 'react'
import { MODAL_INFO_ARTICLE_CROP_TYPE } from '../../../../constants/ui'
import { TMedia } from '@typesApp/cmsPlacement/Media'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'
import {
  StyledTextModuleWrapper,
  StyledTitle,
  TextContainer,
  StyledTextModuleContainer,
  StyledTextModuleLearnMoreContainer,
} from '../TextModule.style'

const TextModuleArticle: React.FC<{
  item: ICMArticle
  backgroundColor: React.CSSProperties['backgroundColor']
  isLearnMoreArticle?: boolean
}> = ({ item, backgroundColor, isLearnMoreArticle }) => {
  const title = item?.title || ''
  const detailText = item?.detailText || ''
  const media: TMedia = (item?.media && item?.media[0]) || null
  const showMediaContent = isLearnMoreArticle && media
  const TextModuleContainer = isLearnMoreArticle ? StyledTextModuleLearnMoreContainer : StyledTextModuleContainer
  return (
    <StyledTextModuleWrapper istextoverlay={0}>
      <TextModuleContainer>
        {title && title !== '.' && (
          <StyledTitle data-cm-metadata={`[{"_":"properties.${title}"}]`} textAlign={'center'}>
            {title}
          </StyledTitle>
        )}
        {showMediaContent && (
          <div className="media-container-wrap">
            <CMSCommonMedia type={MODAL_INFO_ARTICLE_CROP_TYPE} media={media} isLazy={false} />
          </div>
        )}
        {detailText && (
          <TextContainer dangerouslySetInnerHTML={{ __html: detailText }} teaserBackground={backgroundColor} />
        )}
      </TextModuleContainer>
    </StyledTextModuleWrapper>
  )
}

export default TextModuleArticle
