import React from 'react'
import { useTranslation } from 'next-i18next'
import {
  PrescriptionBannerCheckoutButton,
  PrescriptionBannerCheckoutLabel,
  PrescriptionBannerCheckoutWrapper,
} from './PrescriptionBannerCheckout.style'
import config from '@configs/index'

const PrescriptionBannerCheckout: React.FC = () => {
  const { t } = useTranslation()
  const prescriptionBannerImgPath = config.publicUrl + '/images/common/prescription-banner.png'

  return (
    <PrescriptionBannerCheckoutWrapper>
      <img src={prescriptionBannerImgPath} alt={''} />
      <PrescriptionBannerCheckoutLabel>
        <span>{t('PrescriptionBannerCheckout.Title')}</span>
        <span>{t('PrescriptionBannerCheckout.SubTitle')}</span>
        <span>{t('PrescriptionBannerCheckout.Description')}</span>
        <PrescriptionBannerCheckoutButton filltype={'fill'}>
          {t('PrescriptionBannerCheckout.Button')}
        </PrescriptionBannerCheckoutButton>
      </PrescriptionBannerCheckoutLabel>
    </PrescriptionBannerCheckoutWrapper>
  )
}

export default PrescriptionBannerCheckout
