//Standard libraries
import React from 'react'
import { ProductSoldOutStatus, IProduct } from '@typesApp/product'
import ProductPriceAlgolia from '../../../ProductDetails/components/ProductPriceAlgolia'
import ProductPrice from '../../../ProductDetails/components/ProductPrice'
import { getIsRoxable, getProductType } from '../../../../utils/productAttributes'
import { determineAlgoliaPrice, shouldShowAbsoluteDiscount } from '../../../../foundation/algolia/algoliaPrice'
import { getCustomerSegmentsfromCookie } from '../../../../features/plp/algoliaUtils'
import { Adjustment } from '@typesApp/order'

export interface ProductSummaryPriceProps {
  product: IProduct
  adjustments: Adjustment[]
  isRxOrder?: boolean
  soldOutStatus: ProductSoldOutStatus
}

export const ProductSummaryPrice: React.FC<ProductSummaryPriceProps> = ({
  product,
  adjustments,
  isRxOrder = false,
  soldOutStatus,
}: ProductSummaryPriceProps) => {
  const productType = getProductType(product)?.toLowerCase()?.replaceAll(' ', '-')
  const isRoxable = getIsRoxable(product)

  return (
    <>
      {product && product.x_price && (
        <ProductPriceAlgolia
          isLoading={false}
          productType={productType}
          adjustments={adjustments}
          price={determineAlgoliaPrice(
            product?.x_price,
            getCustomerSegmentsfromCookie(),
            productType,
            false,
            isRoxable,
            isRxOrder
          )}
          isCompact={true}
          type={soldOutStatus}
          isRoxable={isRoxable}
          isRxOrder={isRxOrder}
          x_offerpriceRx={product?.x_offerpriceRx}
          isAbsoluteDiscount={shouldShowAbsoluteDiscount(product)}
        />
      )}
    </>
  )
}
