import React from 'react'
import { SubscriptionIntervalSection } from '../../Subscription.style'
import { TextField } from '@components/UI'
import { useTranslation } from 'next-i18next'
import { MenuItem } from '@mui/material'
import { SUBSCRIPTION_DISCOUNT_PERCENT_STRING } from '@views/Subscription/constants'
import { useFrequencyList } from '@views/Subscription/hooks/useFrequencyList'
import { useGetSubscriptionsTermsAndConditionsQuery } from '@features/subscription/query'
import { getDescriptionFromMarketingSpot } from '@views/Subscription/helpers/subscriptionHelpers'
import { useSite } from '@foundation/hooks/useSite'

export type RecurrencySelectProps = {
  defaultValue: string
  onChange: (value: string) => void
  showStacked?: boolean
  customLabel?: string
  customPlaceHolder?: string
}

export const RecurrencySelect: React.FC<RecurrencySelectProps> = ({
  defaultValue,
  onChange,
  showStacked,
  customLabel,
  customPlaceHolder,
}) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const frequencyList = useFrequencyList()
  const { data: eSpotData } = useGetSubscriptionsTermsAndConditionsQuery({ storeId: mySite.storeID })
  const subscriptionTermsAndCondition = getDescriptionFromMarketingSpot(eSpotData)

  return (
    <SubscriptionIntervalSection showStacked={showStacked}>
      <div>
        {customLabel ||
          subscriptionTermsAndCondition ||
          t('Subscriptions.Msgs.Active.Description', { discountAmount: SUBSCRIPTION_DISCOUNT_PERCENT_STRING })}
      </div>
      <TextField
        select
        label={customPlaceHolder}
        className="recurrency-selector"
        defaultValue={defaultValue}
        value={defaultValue}
        onChange={e => onChange(e.target.value)}
      >
        {frequencyList.map(item => {
          const val = Object.keys(item)[0]
          return (
            <MenuItem key={val} value={val}>
              {item[val]}
            </MenuItem>
          )
        })}
      </TextField>
    </SubscriptionIntervalSection>
  )
}
