import Axios, { Canceler } from 'axios'
import { Button, ModalDialog } from '../UI'
import { ButtonContainer, StyledHeadingContainer, WarningSection } from './RemoveCreditCardDialog.style'
import React, { useEffect } from 'react'

import useBreakpoints from '../../hooks/useBreakpoints'
import { useTranslation } from 'next-i18next'
import personWalletService from '../../foundation/apis/transaction/person.wallet.service'
import { useDispatch } from 'react-redux'
import { FETCH_USER_WALLET_REQUESTED_ACTION } from '../../redux/actions/user'
import PaymentMethods from '../../views/PaymentMethods'
import getDisplayName from 'react-display-name'
import Log from '../../services/Log'

const RemoveCreditCardDialogLayout: React.FC<{
  handleClose: () => void
  userCreditCard
}> = ({ handleClose, userCreditCard }) => {
  const { isDesktop } = useBreakpoints()

  const [removeCreditCardDialog, setRemoveCreditCardDialogState] = React.useState<boolean>(true)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const widgetName = getDisplayName(PaymentMethods)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const deleteCreditCard = (cardId: string) => {
    personWalletService
      .deleteCard(cardId)
      .then(res => res.data)
      .then(cardData => {
        if (cardData.success === 'ok') {
          dispatch(FETCH_USER_WALLET_REQUESTED_ACTION(payloadBase))
          handleClose()
        }
      })
      .catch(e => {
        Log.error('Could not create new card: ' + e)
      })
  }

  const handleRemoveCreditCardDialogState = () => setRemoveCreditCardDialogState(false)

  useEffect(() => {
    if (removeCreditCardDialog) handleRemoveCreditCardDialogState()
  }, [])

  useEffect(() => {
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [])

  return (
    <ModalDialog
      open={true}
      onClose={handleClose}
      onCloseButton={isDesktop ? handleClose : undefined}
      widthSize={'sm'}
      roundBorders
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {!userCreditCard.hasSubscriptions && (
          <StyledHeadingContainer>{t('RemoveCreditCardDialog.Title')}</StyledHeadingContainer>
        )}
        {userCreditCard.hasSubscriptions && (
          <WarningSection>{t('RemoveCreditCardDialog.HasSubscriptionsWarning')}</WarningSection>
        )}
        <ButtonContainer>
          {!userCreditCard.hasSubscriptions && (
            <Button onClick={() => deleteCreditCard(userCreditCard.id)}>{t('RemoveCreditCardDialog.YesRemove')}</Button>
          )}

          <Button filltype={'outline'} onClick={handleClose}>
            {t(!userCreditCard.hasSubscriptions ? 'RemoveCreditCardDialog.NoClose' : 'RemoveCreditCardDialog.OK')}
          </Button>
        </ButtonContainer>
      </>
    </ModalDialog>
  )
}

export { RemoveCreditCardDialogLayout }
