import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

export const OpeningHoursContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  flexWrap: 'wrap',
  maxHeight: 98,
}))

export const WeekDayHours = styled('div')(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(5),
  minWidth: 140,
  justifyContent: 'space-between',
  marginBottom: '4px',
}))

export const WeekDay = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  fontWeight: 600,
  lineHeight: 1.6,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
}))

export const Hours = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  lineHeight: 1.33,
  color: theme.palette.text.dark.primary,
}))
