import { useEffect, useState } from 'react'
import { Attachment, IProduct } from '@typesApp/product'
import { IMAGE_WITH_SHADOW_QUARTER_ANGLE_ID_INCLUDES } from '@components/ProductImage/ProductImage'

/**
 * @param { IProduct } product current selected product object
 */
export const useProductImages = (product?: IProduct) => {
  const [images, setImages] = useState<Attachment[]>([])

  useEffect(() => {
    const attachments = product?.attachments || []
    // filter images array to get array with PDP images
    const PDPImagesArray = attachments.filter(d => d.usage === 'PDP')
    if (!PDPImagesArray.length) {
      return
    }
    // order images by "sequence" attribute
    PDPImagesArray.sort((a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10))
    // remove first image from array with PDP images
    setImages(PDPImagesArray)
  }, [product?.attachments])

  return {
    images,
  }
}

// plain function instead to perform sync retrieving and to fix issues connected with hook's async flow
export const retrieveProductImages = (product?: IProduct): Attachment[] => {
  if (!(product && product.attachments)) return []

  // filter images array to get array with PDP images
  const PDPImagesArray = product.attachments
    .filter(d => d.usage === 'PDP')
    // filter out quarter angle image with shadow (assuming main image is also quarter angle but without shodow)
    .filter(d => !d.identifier?.includes(IMAGE_WITH_SHADOW_QUARTER_ANGLE_ID_INCLUDES))
  if (!PDPImagesArray.length) {
    return []
  }

  // order images by "sequence" attribute
  PDPImagesArray.sort((a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10))

  return PDPImagesArray
}
