import styled from '@mui/material/styles/styled'

export const DialogContainer = styled('div', {
  name: 'CartSubscriptionDialog',
  slot: 'Container',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    minWidth: theme.spacing(100),
    border: `solid 1px ${theme.palette.background.light.tertiary}`,
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    p: {
      textAlign: 'center',
    },
  },
}))
