import React from 'react'
//COMPONENTS
import NavigationLogo from '../Header/components/NavigationBar/components/NavigationLogo'
//STYLES
import {
  ReducedHeaderWrapper,
  ReducedHeaderNavigationLogoWrapper,
  ReducedHeaderCheckoutDisclaimer,
  ReducedHeaderMobileWrapper,
  ReducedHeaderMobileBottom,
  ReducedHeaderMobileTop,
  ReducedHeaderMobileBack,
} from '../ReducedHeader/reducedheader.style'
import { useTranslation } from 'next-i18next'
import { useReturnOrderSteps } from '@hooks/useReturnOrderSteps'
import { HeaderStepsBar } from '@components/HeaderStepsBar/HeaderStepsBar'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const OrderReturnHeader: React.FC = () => {
  const { t } = useTranslation()
  const title = t('Header.Reduced.Return')
  const returnSteps = useReturnOrderSteps()

  return (
    <>
      <ReducedHeaderMobileWrapper>
        <ReducedHeaderMobileTop>
          <ReducedHeaderMobileBack>
            <SVGIcon library="arrow" name="arrow-left" />
          </ReducedHeaderMobileBack>
          <NavigationLogo />
          <ReducedHeaderCheckoutDisclaimer>{<span>{title}</span>}</ReducedHeaderCheckoutDisclaimer>
        </ReducedHeaderMobileTop>
        {returnSteps && (
          <ReducedHeaderMobileBottom>
            <HeaderStepsBar stepState={returnSteps} />
          </ReducedHeaderMobileBottom>
        )}
      </ReducedHeaderMobileWrapper>

      <ReducedHeaderWrapper>
        <ReducedHeaderNavigationLogoWrapper>
          <NavigationLogo />
        </ReducedHeaderNavigationLogoWrapper>
        {returnSteps && <HeaderStepsBar stepState={returnSteps} />}
        <ReducedHeaderCheckoutDisclaimer>{<span>{title}</span>}</ReducedHeaderCheckoutDisclaimer>
      </ReducedHeaderWrapper>
    </>
  )
}

export default OrderReturnHeader
