import { styled, Theme } from '@mui/material/styles'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'

// This function is used to overwrite the styles of the mui/date-picker component,
// because at the moment there is no way to fully customize the component header.
export const getPopperSX = ({ theme, showMonthController }: { theme: Theme; showMonthController: boolean }) => ({
  boxShadow: theme.shadows[9],

  ['.MuiPaper-root']: {
    ['& > div:first-of-type']: {
      width: 'fit-content',

      ['& > div:first-of-type']: {
        width: 'fit-content',
      },
    },
  },

  ['.MuiCalendarPicker']: {
    ['&-viewTransitionContainer']: {
      maxWidth: 226,

      ['.PrivatePickersSlideTransition-root']: {
        minHeight: 160,

        ['& div[role=row]']: {
          margin: 0,
        },
      },
    },

    ['&-root']: {
      maxWidth: `calc(226px + ${theme.spacing(8)})`,
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,

      ['& > div:first-of-type']: {
        padding: 0,
        margin: `0 0 ${theme.spacing(3.5)}`,
      },
    },
  },

  ['.MuiPickersDay-root']: {
    width: 32,
    height: 32,
    margin: 0,
  },

  ['.MuiPickersArrowSwitcher-spacer']: {
    display: 'none',
  },

  ['div[role=presentation]']: {
    order: 2,

    ['& > .PrivatePickersFadeTransitionGroup-root']: {
      display: 'none',
    },

    ['& > .MuiPickersFadeTransitionGroup-root']: {
      display: 'none',
    },

    ['& + .MuiPickersArrowSwitcher-root']: {
      flex: 1,
      visibility: `${showMonthController ? 'visible' : 'hidden'} !important`,
      opacity: `${+showMonthController} !important`,
    },
  },

  ['& .Mui-selected']: {
    backgroundColor: `${theme.palette.custom.halfBaked} !important`,
    color: `${theme.palette.custom.cyprus} !important`,
    fontSize: 14,
    fontWeight: 'bold',
  },

  ['.MuiYearPicker-root']: {
    margin: 0,
  },

  ['.MuiMonthPicker-root']: {
    width: 'fit-content',
  },

  ['.PrivatePickersMonth-root, .PrivatePickersYear-yearButton']: {
    height: 32,
    width: 56,
    margin: 0,

    ['&:hover, &:focus']: {
      backgroundColor: theme.palette.custom.jaggedIce,
    },
  },

  ['.PrivatePickersSlideTransition-root, .MuiCalendarPicker-viewTransitionContainer']: {
    ['&::-webkit-scrollbar']: {
      width: '2px',
    },

    ['&::-webkit-scrollbar-track']: {
      backgroundColor: theme.palette.custom.alto,
    },

    ['&::-webkit-scrollbar-thumb']: {
      backgroundColor: theme.palette.custom.cyprus,
    },
  },
})

export const StyledCurrentDate = styled('span')<{ type: 'month' | 'year' }>(({ theme, type }) => ({
  margin: `0 ${theme.spacing(type === 'month' ? 4 : 2)}`,
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.custom.cyprus,
}))

export const StyledDatePickerDays = styled(PickersDay, {
  name: 'DatePicker',
  slot: 'Days',
  shouldForwardProp: prop => prop !== 'isDateSelected',
})<{
  isDateSelected: boolean
}>(({ theme, isDateSelected = false }) => ({
  ['&.Mui-selected']: {
    backgroundColor: `${isDateSelected ? theme.palette.custom.halfBaked : 'transparent'} !important`,
    fontWeight: isDateSelected ? 'bold' : 'normal',

    ['&:hover']: {
      backgroundColor: theme.palette.custom[isDateSelected ? 'halfBaked' : 'jaggedIce'],
    },
  },

  ['&:focus']: {
    backgroundColor: 'transparent',
  },

  ['&:hover, &:focus-visible']: {
    backgroundColor: theme.palette.custom.jaggedIce,
    fontWeight: 'bold',
  },

  ['&.MuiPickersDay-today']: {
    border: `1px solid ${theme.palette.custom.cyprus}`,
    fontWeight: 'bold',
  },

  ['&, &.Mui-selected']: {
    color: theme.palette.custom.cyprus,
    fontSize: 14,
  },
}))
