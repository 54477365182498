import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { AccordionDetails, Box, Dialog, Typography } from '@mui/material'
import { toNumber } from 'lodash-es'
import { useTranslation } from 'next-i18next'
import {
  sendCouponSubmitEvent,
  sendEventDiscountApplied,
  sendPromoCodeEvent,
} from '../../../foundation/analytics/tealium/lib'
import { StyledTypography, PreLoader } from '../../../components/UI'
import { ANALYTICS_PAGE_TYPE, getUserToken, useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { Adjustment } from '../../../types/order'
import { CART_DATA_ELEMENT_IDS } from '../../../constants/checkout'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import { orderApi } from '../../../features/order/query'
import { useSite } from '../../../foundation/hooks/useSite'
import {
  StyledPromoCodeAccordion,
  StyledPromoCodeAccordionSummary,
  StyledPromoCodeTextField,
  StyledApplyButton,
  StyledRemoveLink,
} from './CartPromoCodeSection.style'
import CurrencyService from '../../../services/CurrencyService'
import { INVENTORY, SHIPPING_ADJUSTMENT_TEXT, SURCHARGE_TEXT } from '../../../constants/common'
import {
  registerApplyPromoCodeError,
  registerRemovePromoCodeError,
  retrieveApplyPromoCodeError,
  ERR_PROMOTION_CODE_KEYS,
} from '@utils/promoCode'
import { cartSelector, isPromoCouponAppliedSelector } from '@features/order/selector'
import { useDispatch, useSelector } from 'react-redux'
import { setIsPromoCouponApplied } from '@features/order/slice'
import { CartPayload } from '@typesApp/cart'
import { currentContractIdSelector } from '@redux/selectors/contract'
import getDisplayName from 'react-display-name'
import { SUBSCRIPTION_ERROR_CODES } from '@views/Subscription/constants'
import PromoCodeDisabledAlert from './PromoCodeDisabledAlert'
import { hasSubscribedItemsInOrder } from '@views/Subscription/helpers/subscriptionHelpers'
import { CartSubscriptionDialog } from '@views/Subscription/cart/components/Dialog/CartSubscriptionDialog'
import orderService from '@foundation/apis/transaction/order.service'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const CartPromoCodeSection: FC = () => {
  const { t: translate } = useTranslation()
  const dispatch = useDispatch()
  const isPromoCouponSubmitted = useSelector(isPromoCouponAppliedSelector)
  const cart = useSelector(cartSelector)
  const promotionCodes = cart?.promotionCode || []
  const adjustment = cart?.adjustment || []
  const x_promoHasAdjustment = cart.x_promoHasAdjustment === 'true'
  const timestampRef = useRef(Date.now()).current
  const adjustmentsLessSurcharges = (adjustment ?? []).filter(adj => {
    return adj?.usage !== SURCHARGE_TEXT
  })

  const { mySite } = useSite()
  const storeId = mySite?.storeID
  const contractId = useSelector(currentContractIdSelector)
  const checkInventory = useMemo<boolean>(() => mySite.inventorySystem === INVENTORY.NON_ATP, [mySite.inventorySystem])
  const payloadBase: CartPayload = {
    storeId: mySite.storeID,
    currency: mySite.defaultCurrencyID,
    contractId,
    checkInventory,
    widget: getDisplayName(''),
  }
  const [getCart] = orderApi.endpoints.getCart.useLazyQuery()
  const [applyPromotioncode] = orderApi.endpoints.applyPromotioncode.useLazyQuery()
  const [removePromotioncode] = orderApi.endpoints.removePromotioncode.useLazyQuery()
  const analyticsDataForPromoCode = useAnalyticsData(ANALYTICS_PAGE_TYPE.PROMO)

  const appliedCouponCodes = (promotionCodes ?? []).map(promotion => promotion?.code.toLowerCase())

  const isCouponAdjustment = (adj: Adjustment) =>
    appliedCouponCodes.some(id => adj?.code?.toLowerCase().replace(/\s/g, '').includes(id) ?? false)

  const [explicitAdjustments, implicitAdjustments] = (adjustmentsLessSurcharges ?? ([] as Adjustment[])).reduce(
    ([explicit, implicit], adj) =>
      isCouponAdjustment(adj) ? [[...explicit, adj], implicit] : [explicit, [...implicit, adj]],
    [[] as Adjustment[], [] as Adjustment[]]
  )

  let orderItemDiscountAdjustments = adjustment ? adjustment.filter(adj => toNumber(adj.amount) < 0) : []
  orderItemDiscountAdjustments =
    orderItemDiscountAdjustments.filter(obj => {
      return obj?.description && obj?.usage !== SHIPPING_ADJUSTMENT_TEXT && obj?.usage !== SURCHARGE_TEXT
    }) || []

  const orderDiscountAdjustmentsMap = orderItemDiscountAdjustments.reduce(
    (map, { code, amount, description }) =>
      map.set(code, {
        amount: (map.get(code)?.amount || 0) + Number(amount),
        description,
      }),
    new Map<string, { amount: number; description: string }>()
  )
  const orderDiscountAdjustments = Array.from(orderDiscountAdjustmentsMap, ([key, valueObj]) => ({
    code: key,
    amount: valueObj.amount,
    description: valueObj.description,
  }))

  const determinePromoCodeDescription = adj => {
    let description = adj.code
    if (adj.description) {
      try {
        const jsonDesc = JSON.parse(adj.description)
        description = jsonDesc[mySite.locale.substring(0, 2).toUpperCase()]
      } catch {
        description = adj.description
      }
    }
    return description
  }

  const [promoCodeInputValue, setPromoCodeInputValue] = useState<string>(promotionCodes[0]?.code || '')
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(false)
  const [invalidPromoCode, setInvalidPromoCode] = useState<string>('')
  const [subscriptonPromoCodeError, setSubscriptonPromoCodeError] = useState<string>('')
  const [promoCodeError, setPromoCodeError] = useState<boolean>(false)
  const [isPromoCodeLoading, setPromoCodeLoading] = useState<boolean>(false)
  const [isPromoCodeApplied, setPromoCodeApplied] = useState<boolean>(!!promoCodeInputValue && x_promoHasAdjustment)
  const [shouldSendEvent, setSendEvent] = useState(false)
  const [isPromoCodeAccordionOpen, setPromoCodeAccordionOpen] = useState<boolean>(
    isPromoCodeApplied || isPromoCodeLoading || promoCodeError || orderDiscountAdjustments.length > 0
  )
  const allowedPromoCodesForSubscriptions = mySite?.xStoreCfg?.SUBSCRIPTION_STACKABLE_PROMO_CODES_LIST?.split(',') || []
  const cartHasSubscriptions = hasSubscribedItemsInOrder(cart, null)

  const isPromoCodeDisabled = orderService.hasInsuranceApplied(cart)

  const applyPromotionCode = () => {
    const promoCodeToApply = promoCodeInputValue.trim()

    if (promoCodeToApply !== '') {
      setPromoCodeLoading(true)

      applyPromotioncode({
        storeId,
        payload: { ...payloadBase, promoCode: promoCodeToApply },
      }).then(data => {
        if (data.error) {
          const error = data?.error?.response?.data?.errors?.[0] || data?.error?.response?.data
          const errorCode = error.errorCode
          const isSubscriptionError = SUBSCRIPTION_ERROR_CODES.INVALID_PROMO_CODE === errorCode
          const isPromoCodeError = ERR_PROMOTION_CODE_KEYS.includes(error.errorKey)
          const promoErrorMessageTranslation = isPromoCodeError
            ? translate(`Cart.Labels.${error.errorKey}`, { promoCode: promoCodeToApply })
            : ''

          const { errorMessage, isWrongPromoCode } = retrieveApplyPromoCodeError(
            error,
            isSubscriptionError
              ? translate('Subscriptions.Errors.PromoCodeError')
              : translate('Cart.Labels.InvalidPromoCode'),
            promoErrorMessageTranslation
          )
          setSubscriptonPromoCodeError(
            isSubscriptionError ? translate('Subscriptions.Errors.PromoCodeError') : errorMessage
          )

          analyticsDataForPromoCode.userToken = getUserToken()
          registerApplyPromoCodeError(
            analyticsDataForPromoCode,
            promoCodeToApply,
            errorMessage,
            isWrongPromoCode,
            errorCode
          )
          dispatch(setIsPromoCouponApplied(false))
          onPromoCodeApplied(promoCodeToApply, false, !!errorMessage)
        } else {
          getCart({
            ...payloadBase,
            fetchCatentries: true,
            fetchShippingInfo: true,
            refetch: false,
            sessionId: timestampRef,
          })
        }
      })
    } else {
      onPromoCodeApplied(promoCodeInputValue, false, true)
    }
  }

  useEffect(() => {
    if (!!promoCodeInputValue) {
      if (cartHasSubscriptions && !allowedPromoCodesForSubscriptions.includes(promoCodeInputValue)) {
        setShowSubscriptionAlert(true)
      } else {
        setShowSubscriptionAlert(false)
        if (!isPromoCodeApplied) {
          applyPromotionCode()
        }
      }
    }
  }, [cartHasSubscriptions])

  const onPromoCodeRemove = (code: string) => {
    if (!isPromoCodeLoading && !isPromoCodeApplied && !!promoCodeError) {
      onPromoCodeApplied('', false, false)
      return
    }

    setPromoCodeLoading(true)

    removePromotioncode({
      storeId,
      promoCode: encodeURIComponent(code),
      payload: {
        ...payloadBase,
        promoCode: encodeURIComponent(code),
        widget: payloadBase.widget,
      },
    }).then(data => {
      if (data.error) {
        registerRemovePromoCodeError(code)
      } else {
        getCart({
          ...payloadBase,
          fetchCatentries: true,
          fetchShippingInfo: true,
          refetch: false,
          sessionId: timestampRef,
        })
      }

      dispatch(setIsPromoCouponApplied(false))
      onPromoCodeApplied('', false, false)
    })
  }

  const onPromoCodeApplied = (promocodeValue: string, isApplied: boolean, hasError: boolean) => {
    setPromoCodeInputValue(promocodeValue)
    setPromoCodeApplied(isApplied)
    setPromoCodeError(hasError)
    setInvalidPromoCode(hasError ? promocodeValue : '')
    setPromoCodeLoading(false)
    if (isApplied && shouldSendEvent) {
      sendEventDiscountApplied(analyticsDataForPromoCode)
    }
  }

  useEffect(() => {
    if (promotionCodes[0] && promotionCodes[0].code && adjustment && adjustment?.length > 0) {
      setPromoCodeInputValue(promotionCodes[0].code)
      onPromoCodeApplied(promotionCodes[0].code, true, !x_promoHasAdjustment)
      if (!isPromoCouponSubmitted && appliedCouponCodes.length === 0) {
        sendCouponSubmitEvent({ result: true, ...analyticsDataForPromoCode })
        dispatch(setIsPromoCouponApplied(true))
      }
    }
  }, [promotionCodes, adjustment, analyticsDataForPromoCode, x_promoHasAdjustment, shouldSendEvent])

  const promoCodeAppledMsg = (promotionCodes ?? []).map(() => translate('Cart.Labels.PromoCodeApplied'))
  const emptyPromoCode = translate('Cart.Labels.NoCode')
  const invalidPromoCodeMsg =
    subscriptonPromoCodeError ||
    translate('Cart.Labels.InvalidPromoCode', {
      promoCode: invalidPromoCode === '' ? emptyPromoCode : invalidPromoCode,
    })

  const handleSubscriptionDialogClose = () => {
    setShowSubscriptionAlert(false)
    onPromoCodeRemove(promoCodeInputValue)
  }

  const displayableImplicitAdjustments = new Map<string, number>()
  //if the order has the same discount description for multiple items, we want to merge them into a single line
  implicitAdjustments?.forEach(adjustment => {
    const description = determinePromoCodeDescription(adjustment)
    const currentValue = displayableImplicitAdjustments.get(description) || 0
    displayableImplicitAdjustments.set(description, currentValue + toNumber(adjustment.amount))
  })

  return (
    <>
      <StyledPromoCodeAccordion
        defaultExpanded={
          isPromoCodeApplied || isPromoCodeLoading || promoCodeError || orderDiscountAdjustments.length > 0
        }
        onClick={() => {
          sendPromoCodeEvent(isPromoCodeAccordionOpen)
          setPromoCodeAccordionOpen(!isPromoCodeAccordionOpen)
        }}
      >
        <StyledPromoCodeAccordionSummary
          data-analytics_available_call="1"
          data-element-id="X_X_Prods_Remove"
          expandIcon={<SVGIcon library="arrow" name="arrow-down" />}
        >
          <StyledTypography fontWeight={'700'}>{translate('Cart.Labels.PromoCode')}</StyledTypography>
        </StyledPromoCodeAccordionSummary>
        <AccordionDetails
          style={{
            margin: 0,
            width: '100%',
          }}
        >
          {isPromoCodeDisabled && <PromoCodeDisabledAlert />}

          {(!isPromoCodeDisabled || isPromoCodeApplied) && (
            <Box flex={1}>
              <StyledPromoCodeTextField
                type="text"
                id="cart_input_promocode"
                label={translate('Cart.Labels.PromoCode_placeholder')}
                fullWidth
                helperText={!promoCodeError && !isPromoCodeApplied ? translate('Cart.Labels.PromoCodeHelper') : ''}
                autoComplete="off"
                error={promoCodeError}
                showvalidationstatus={true}
                isvalid={isPromoCodeApplied}
                disabled={isPromoCodeLoading || isPromoCodeDisabled || isPromoCodeApplied}
                value={promoCodeInputValue}
                onChange={event => setPromoCodeInputValue(event.target.value)}
                onClick={e => {
                  e.stopPropagation()
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  e.stopPropagation()
                  if (e.key === 'Enter') {
                    setSendEvent(true)
                    applyPromotionCode()
                  }
                }}
              />
              {!isPromoCodeLoading && (isPromoCodeApplied || promoCodeError) && (
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginTop={'8px'}>
                  <CmsIcon teaserIcon={promoCodeError ? 'arn-icon--alert' : 'arn-icon--check'} />
                  <Box flex={1}>
                    {promoCodeError && (
                      <Typography color={'red'} fontSize={12} marginLeft={4} variant="body1" align="left">
                        {invalidPromoCodeMsg}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginTop={'8px'}>
            <Box flex={1}>
              {explicitAdjustments.length > 0 && (
                <Typography color={'green'} fontSize={12} variant="body1" align="left" gap={4}>
                  <Typography
                    fontFamily={'Mulish'}
                    fontSize={12}
                    fontWeight={700}
                    lineHeight={'16px'}
                    letterSpacing={'0em'}
                    textAlign={'left'}
                  >
                    {promoCodeAppledMsg}
                  </Typography>
                  {explicitAdjustments.map(adj => {
                    const description = determinePromoCodeDescription(adj)

                    return (
                      <Typography
                        fontFamily={'Mulish'}
                        fontSize={12}
                        fontWeight={400}
                        lineHeight={'16px'}
                        key={adj.code}
                      >
                        {`${description} (${CurrencyService.getFormattedPrice(
                          mySite.locale,
                          mySite.defaultCurrencyID,
                          adj.amount
                        )})`}
                      </Typography>
                    )
                  })}
                </Typography>
              )}
              {!isPromoCodeDisabled && (
                <>
                  {!isPromoCodeLoading && (isPromoCodeApplied || promoCodeError) ? (
                    <StyledRemoveLink
                      onClick={e => {
                        e.stopPropagation()
                        onPromoCodeRemove(promoCodeInputValue)
                      }}
                    >
                      {isPromoCodeLoading ? <PreLoader withButton /> : translate('Cart.Actions.RemoveCode')}
                    </StyledRemoveLink>
                  ) : (
                    <StyledApplyButton
                      id="cart_link_2_promocode"
                      data-element-id={CART_DATA_ELEMENT_IDS.PROMOCODE_SUBMIT}
                      data-analytics_available_call="1"
                      disabled={!promoCodeInputValue}
                      variant="primary"
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (isPromoCodeLoading) return
                        setSendEvent(true)
                        applyPromotionCode()
                      }}
                    >
                      {isPromoCodeLoading ? (
                        <PreLoader fill={'light'} withButton />
                      ) : (
                        translate('Cart.Actions.ApplyCode')
                      )}
                    </StyledApplyButton>
                  )}
                </>
              )}
            </Box>
          </Box>
          {implicitAdjustments.length > 0 && (
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginTop={'8px'}>
              <Box flex={1}>
                <Typography color={'green'} fontSize={12} variant="body1" align="left" gap={4}>
                  <Typography
                    fontFamily={'Mulish'}
                    fontSize={12}
                    fontWeight={700}
                    lineHeight={'16px'}
                    letterSpacing={'0em'}
                    textAlign={'left'}
                  >
                    {translate('Cart.Labels.ShipmentAdjustmentsList')}
                  </Typography>

                  {Array.from(displayableImplicitAdjustments).map(([key, value]) => (
                    <Typography fontFamily={'Mulish'} fontSize={12} fontWeight={400} lineHeight={'16px'} key={key}>
                      {`${key} (${CurrencyService.getFormattedPrice(mySite.locale, mySite.defaultCurrencyID, value)})`}
                    </Typography>
                  ))}
                </Typography>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </StyledPromoCodeAccordion>

      <Dialog fullScreen={false} open={showSubscriptionAlert}>
        <CartSubscriptionDialog
          message={translate('Subscriptions.Errors.PromoCodeDialogErrorMessage')}
          handleClose={handleSubscriptionDialogClose}
        />
      </Dialog>
    </>
  )
}

export default CartPromoCodeSection
