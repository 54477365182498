import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { StyledAlert, StyledAlertHeader, StyledAlertMessage } from '../../../components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const PromoCodeDisabledAlert: FC = () => {
  const { t } = useTranslation()

  return (
    <StyledAlert
      severity={'warning'}
      sx={{ width: '100%', marginTop: -2, marginBottom: 6 }}
      icon={<SVGIcon library="validation" name="alert" />}
    >
      <StyledAlertHeader>{t('Cart.Msgs.PromoCodeDisabledTitle')}</StyledAlertHeader>
      <StyledAlertMessage fullWidth={true}>{t('Cart.Msgs.PromoCodeDisabledMessage')}</StyledAlertMessage>
    </StyledAlert>
  )
}

export default PromoCodeDisabledAlert
