import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { IProduct } from '../../../types/product'
import { PROFILE_NAME_PARAM, ITEM_DETAILS_PROFILE_NAME } from '../../../constants/common'
import {
  isWishlistLoadingSelector,
  wishlistItemsSelector,
  wishlistProductsSelector,
} from '../../../features/wishList/selector'
import {
  DashboardGridItemContainer,
  DashboardGridItemHeaderWrapper,
  DashboardGridItemHeader,
  DashboardGridItemContent,
} from '../AccountDashboard.style'
import {
  DashboardWishlistItems,
  DashboardWishlistProductDetails,
  DashboardWishlistProductName,
  DashboardWishlistBrandName,
  DashboardWishlistRowPrice,
} from './DashboardWishlist.style'
import { StyledLinkAsButton } from '../../../components/UI'
import ProductImage from '../../../components/ProductImage/ProductImage'
import productsService from '../../../foundation/apis/search/products.service'
import { getBrand, getModelName } from '../../../utils/productAttributes'
import theme from '../../../themes'
import { useProductSoldOutState } from '@views/ProductDetails/useProductSoldOutState'
import { useSite } from '@foundation/hooks/useSite'
import Log from '@services/Log'
import { ProductSummaryPrice } from '@views/Cart/components/CartRecap/ProductSummaryPrice'
import LoadingSkeleton from '@components/LoadingSkeleton/LoadingSkeleton'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const DashboardWishlist: FC = () => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const { mySite } = useSite()
  const isWishlistLoading = useSelector(isWishlistLoadingSelector)
  const wishlistItems = useSelector(wishlistItemsSelector)
  const wishlistProducts = useSelector(wishlistProductsSelector)
  const productsCount = wishlistItems?.length
  const wishlistProductsCount = wishlistProducts?.length || 0

  const [product, setProduct] = useState<IProduct | undefined>(wishlistProducts?.[0] || undefined)
  const [areProductsLoading, setProductsLoading] = useState<boolean>(true)

  const currentProductPartNumber = product?.partNumber || product?.items?.[0]?.partNumber || ''
  const { soldOutStatus } = useProductSoldOutState({
    product,
    currentProductPartNumber,
  })

  const isBusy = areProductsLoading || isWishlistLoading

  useEffect(() => {
    if (productsCount > 0) {
      productsService
        .get({
          id: wishlistItems.map(item => item.productId),
          [PROFILE_NAME_PARAM]: ITEM_DETAILS_PROFILE_NAME,
          storeId: mySite.storeID,
        })
        .then(products => {
          if (products.contents?.length) {
            const p = products.contents[0]
            setProduct(p)
          }
          setProductsLoading(false)
        })
        .catch(error => {
          Log.error(`Could not get products: ${error}`)
        })
    } else if (wishlistProductsCount === 0 && !isWishlistLoading) {
      setProductsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsCount, wishlistProductsCount, isWishlistLoading])

  if (isBusy) {
    return (
      <DashboardGridItemContainer>
        <LoadingSkeleton fitContainer />
      </DashboardGridItemContainer>
    )
  }

  return !productsCount ? (
    <DashboardGridItemContainer>
      <DashboardGridItemHeaderWrapper>
        <SVGIcon library="favorite" name="heart-slim" size={48} />
        <DashboardGridItemHeader>{t('AccountDashboard.MyFavs')}</DashboardGridItemHeader>
      </DashboardGridItemHeaderWrapper>
      <DashboardGridItemContent>{t('AccountDashboard.BrowseLatestStyles')}</DashboardGridItemContent>
      <StyledLinkAsButton dataElementId={'myFavouritesCTA'} href={`/${langCode}/${t('Common.Shop.Glasses.Url')}`}>
        {t('AccountDashboard.MyFavsButton')}
      </StyledLinkAsButton>
    </DashboardGridItemContainer>
  ) : (
    <DashboardGridItemContainer>
      <DashboardGridItemHeaderWrapper>
        <DashboardGridItemHeader>{t('AccountDashboard.MyFavs')}</DashboardGridItemHeader>
        <DashboardWishlistItems>{t('AccountDashboard.ItemsCount', { count: productsCount })}</DashboardWishlistItems>
      </DashboardGridItemHeaderWrapper>
      <DashboardGridItemContent>
        {product && (
          <ProductImage
            alt={`Product image for ${getModelName(product)}`}
            attachments={product?.attachments}
            usage="Thumbnail"
            width={280}
            backgroundColor={theme.palette.background.light.primary}
          />
        )}
      </DashboardGridItemContent>
      <DashboardWishlistProductDetails>
        {product && (
          <>
            <DashboardWishlistProductName>{getModelName(product)}</DashboardWishlistProductName>
            <DashboardWishlistBrandName>{getBrand(product)}</DashboardWishlistBrandName>
            <DashboardWishlistRowPrice>
              <ProductSummaryPrice adjustments={[]} product={product} soldOutStatus={soldOutStatus} />
            </DashboardWishlistRowPrice>
          </>
        )}
      </DashboardWishlistProductDetails>
      <StyledLinkAsButton dataElementId={'myFavouritesCTA'} href={`/${langCode}/wishlist`}>
        {t('AccountDashboard.ViewAllFavourites')}
      </StyledLinkAsButton>
    </DashboardGridItemContainer>
  )
}

export default DashboardWishlist
