import React from 'react'
import {
  AmExIcon,
  CreditCardPlaceHolderIcon,
  JCBIcon,
  MastercardIcon,
  VisaIcon,
} from '@components/UI-CSS/SVGIcon/PaymentIcon'
import { useCardValidation } from './useCardValidation'

export interface CardProviderIconProps {
  cardNumber: string
}

export const CardProviderIcon: React.FC<CardProviderIconProps> = ({ cardNumber }) => {
  const { getCardType } = useCardValidation()

  const cardType = getCardType(cardNumber)

  switch (cardType) {
    case 'visa':
      return <VisaIcon />
    case 'jcb':
      return <JCBIcon />
    case 'mc':
      return <MastercardIcon />
    case 'amex':
      return <AmExIcon />
    default:
      return <CreditCardPlaceHolderIcon />
  }
}
