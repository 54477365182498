import { useDispatch, useSelector } from 'react-redux'
import { Box, Hidden, useMediaQuery } from '@mui/material'
import {
  CartRecapData,
  CartRecapDataContent,
  CartRecapItemWrapper,
  CartRecapTop,
  CartRecapImageContainer,
  CartRecapEDD,
  CartRecapContentContainer,
  CartRecapRightColumn,
  StyledCartRecapDivider,
  CartRecapModelPriceDataRow,
  CartRecapTotalDataRow,
  CartRecapDataRowProductModel,
  CartRecapBrand,
  CartRecapRxInitialTotalPrice,
  CartRecapTotalSection,
  CartRecapActionItem,
  CartRecapActions,
  CartRecapTotalBoxes,
} from '../CartRecap.style'
import { getModelCode, getModelName } from '../../../../../utils/productAttributes'

import { OrderRecapItemProps } from '@typesApp/order'
import ProductImage from '../../../../../components/ProductImage/ProductImage'
import React, { useCallback, useMemo } from 'react'
import { catentriesSelector, orderItemsSelector } from '../../../../../features/order/selector'
import theme from '../../../../../themes'
import { useAppSelector } from '../../../../../hooks/redux'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { useContactLensCartItemData } from '../../../../../hooks/useContactLensCartItemData/useContactLensCartItemData'
import { ContactLensQuantity } from './ContactLensQuantity'
import FormattedPriceDisplay from '../../../../../components/formatted-price-display'
import { formatOrderRecapItemPrices } from '../../../../../utils/order'
import { LensDetailsSection } from './ContactLensDetails.style'

import {
  doneRemovingCartItem,
  removeCartItemIdSelector,
  removingCartItemSelector,
  startRemovingCartItem,
  startRemovingCLEye,
} from '../../../../../features/cartui/cartuiSlice'
import { getInsuranceEventModule } from '../../../../../components/DirectBilling'
import { OrderItemContactLensData } from '@typesApp/cart'
import { PreLoader } from '../../../../../components/UI'
import { InsuranceEligibleCallout } from './InsuranceEligibleCallout'
import { CART_PRODUCT_IMAGE_WIDTH } from './constants'
import { determineAlgoliaPrice, getProductPrice } from '@foundation/algolia/algoliaPrice'
import { getCustomerSegmentsfromCookie } from '@features/plp/algoliaUtils'
import { toNumber } from 'lodash-es'
import { useRouter } from 'next/router'
import CurrencyService from '@services/CurrencyService'
import { ClSubscribedMsg } from '@views/Subscription/Subscription.style'
import {
  getTotalAdjustments,
  isItemSubscribed,
  isSubscriptionAllowedForItem,
} from '@views/Subscription/helpers/subscriptionHelpers'
import { useSite } from '@foundation/hooks/useSite'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { OrderRecapClItemLensDetails } from '@components/order-recap/OrderRecapClItemLensDetails'
import { PRODUCT_URL_SEARCH_PARAMS } from '@constants/product'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { useDeleteCLOrderItemsMutation, useLazyGetCartQuery } from '@features/order/query'
import { usePathname } from 'next/navigation'
import { isReOrderSummary } from '@utils/routeUtils'
import { ItemSubscriptionHeader } from '@views/Subscription/cart/components/ItemSubscriptionHeader'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface ContactLensQuantityChangeOptions {
  quantity: string
  orderItemId: string
  eye: string
  contactLensData: OrderItemContactLensData
}

const CartRecapClItem: React.FC<OrderRecapItemProps> = ({ orderItem, onDelete, onItemUpdated }) => {
  const { productId } = orderItem
  const { t } = useTranslation()
  const router = useRouter()
  const { mySite } = useSite()
  const dispatch = useDispatch()
  const [getCart] = useLazyGetCartQuery()
  const { basePath } = useStoreIdentity()
  const pathname = usePathname()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const catentries = useAppSelector(catentriesSelector)
  const isRemovingCartItem = useSelector(removingCartItemSelector)
  const removeOrderItemId = useSelector(removeCartItemIdSelector)
  const [deleteCLOrderItems] = useDeleteCLOrderItemsMutation()
  const product = catentries && catentries[productId]
  const { interceptRemoveCartItem, interceptChangeQuantity } = getInsuranceEventModule()
  const { orderItemClData, setOrderItemClData, totalBoxes } = useContactLensCartItemData({
    orderItem: orderItem,
  })
  const productModelName = getModelName(orderItem)
  const productBaseModelCode = getModelCode(orderItem)
  const { productOrderItemPrice, productUnitPrice } = formatOrderRecapItemPrices(orderItem)
  const orderItems = useSelector(orderItemsSelector)
  const subscriptionDiscount = getTotalAdjustments(orderItem, orderItems, true)
  const subscriptionConfig = useSelector(subscriptionConfigSelector)
  const showSubscriptionHeader =
    isSubscriptionAllowedForItem(orderItem, mySite, subscriptionConfig) && !isReOrderSummary(pathname)
  const showSubscriptionDiscountSection =
    subscriptionConfig.enabled && isItemSubscribed(orderItem) && !!subscriptionDiscount

  const algoliaPrice = product?.x_price
    ? determineAlgoliaPrice(product?.x_price, getCustomerSegmentsfromCookie())
    : {
        listPrice: productUnitPrice || '',
        offerPrice: productUnitPrice || '',
        percentageDiscount: 0,
        startDate: '',
        endDate: '',
        precedence: 0,
        priceListPrecedence: 0,
      }

  const { initialPrice, offerPrice, discountPercentage } = getProductPrice(
    algoliaPrice,
    [orderItem.quantity],
    orderItem.adjustment
  )

  const estimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)
  const orderItemPriceAfterAutoDiscount = offerPrice * (totalBoxes ?? 1)
  const isTotalDiscounted = toNumber(orderItemPriceAfterAutoDiscount.toFixed(2)) < toNumber(productOrderItemPrice)

  const attachments = catentries?.[productId] ? catentries[productId].attachments ?? [] : []

  const { href } = orderItem.seo || product?.seo || { href: '' }

  const isRemovingCurrentItem = isRemovingCartItem && removeOrderItemId === orderItem?.orderItemId

  const onImageClicked = () => {
    const url = `${basePath}${href}`
    router.push(url)
  }

  const handleClChangeQuantity = (options: ContactLensQuantityChangeOptions) => {
    interceptChangeQuantity(() => handleClChangeQuantityImpl(options))
  }

  const handleClChangeQuantityImpl = (options: ContactLensQuantityChangeOptions) => {
    const { contactLensData, eye, quantity, orderItemId } = options

    setOrderItemClData({
      ...contactLensData,
      [eye]: {
        ...contactLensData[eye],
        quantity: quantity,
      },
    })

    onItemUpdated && onItemUpdated(quantity || '', orderItemId || '')
  }
  const productHref = orderItem.seo?.href || product?.seo?.href || ''
  const editLink = `${productHref}?${PRODUCT_URL_SEARCH_PARAMS.EDIT_CONTACT_LENS}=1`

  const onEditClick = () => {
    const { interceptEditCartItem } = getInsuranceEventModule()
    interceptEditCartItem(() => {
      router.push(editLink)
    })
  }

  const handleDelete = useCallback(() => {
    if (!isRemovingCurrentItem) {
      interceptRemoveCartItem(() => {
        onDelete(orderItem)
      })
    }
  }, [])

  const onRemoveEye = (orderitemId: string, leftItemId: string | undefined, rightItemId: string | undefined) => {
    const { interceptRemoveCartItem } = getInsuranceEventModule()
    interceptRemoveCartItem(() => {
      removeSingleEye(orderitemId, leftItemId, rightItemId)
    })
  }

  const removeSingleEye = useCallback(
    (orderitemId: string, leftItemId: string | undefined, rightItemId: string | undefined) => {
      dispatch(startRemovingCartItem(leftItemId || rightItemId || ''))
      dispatch(
        startRemovingCLEye({
          orderItemId: orderitemId || orderItem.orderItemId,
          left: !!leftItemId,
          right: !!rightItemId,
        })
      )

      deleteCLOrderItems({
        storeId: mySite.storeID,
        leftItemId,
        rightItemId,
      })
        .unwrap()
        .then(() => {
          getCart({ storeId: mySite.storeID }).finally(() => {
            dispatch(doneRemovingCartItem(!orderItem.groupedItem ? orderItem : undefined))
          })
        })
        .catch(() => {
          dispatch(doneRemovingCartItem())
        })
    },
    []
  )

  const actionCTAs = useMemo(() => {
    return (
      <CartRecapActions>
        <CartRecapActionItem onClick={handleDelete} data-element-id="X_X_Prods_Remove">
          <span>
            {(isRemovingCurrentItem && <PreLoader fill={'dark'} withButton />) || (
              <SVGIcon library="account" name="trash-bin" size={18} />
            )}
          </span>
          <span> {t('CartRecap.Actions.Delete')}</span>
        </CartRecapActionItem>
      </CartRecapActions>
    )
  }, [productHref, isRemovingCurrentItem, handleDelete])

  const showEditLens = !!productHref && !isReOrderSummary(pathname)

  return (
    <Box>
      <InsuranceEligibleCallout orderItem={orderItem} />
      <CartRecapItemWrapper>
        {!!estimatedDeliveryDate && (
          <CartRecapEDD>
            {t('CartRecap.Labels.EstimatedDeliveryDate')}
            <span> &nbsp;{estimatedDeliveryDate}</span>
          </CartRecapEDD>
        )}
        {showSubscriptionHeader && <ItemSubscriptionHeader orderItem={orderItem} />}
        <CartRecapContentContainer>
          <CartRecapImageContainer>
            <Box>
              {isMobile && actionCTAs}
              <ProductImage
                attachments={attachments}
                data-element-id="X_X_Prods_ProdLink"
                width={CART_PRODUCT_IMAGE_WIDTH}
                usage={'PDP'}
                onClick={onImageClicked}
              />
            </Box>
          </CartRecapImageContainer>
          <CartRecapRightColumn>
            <CartRecapData>
              <Hidden smDown>{actionCTAs}</Hidden>

              <CartRecapTop>
                <CartRecapBrand>{productModelName}</CartRecapBrand>
              </CartRecapTop>
              <CartRecapDataContent>
                {orderItemClData && (
                  <>
                    <StyledCartRecapDivider />
                    <ContactLensQuantity
                      orderItemClData={orderItemClData}
                      listPrice={Number(initialPrice)}
                      offerPrice={Number(offerPrice)}
                      percentageDiscount={discountPercentage || 0}
                      currency={orderItem.currency}
                      onRemoveEye={onRemoveEye}
                      onQuantityChange={(quantity, orderItemId, eye) => {
                        handleClChangeQuantity({ quantity, orderItemId, eye, contactLensData: orderItemClData })
                      }}
                    />
                  </>
                )}
                <CartRecapModelPriceDataRow>
                  <CartRecapDataRowProductModel>{productBaseModelCode}</CartRecapDataRowProductModel>
                </CartRecapModelPriceDataRow>
                {showSubscriptionDiscountSection && (
                  <ClSubscribedMsg>
                    {t('Subscriptions.Msgs.Active.SavingMessage', {
                      savingsAmount: CurrencyService.getFormattedPrice(
                        mySite.locale,
                        mySite.defaultCurrencyID,
                        subscriptionDiscount
                      ),
                    })}
                  </ClSubscribedMsg>
                )}
                {orderItemClData && (
                  <LensDetailsSection>
                    <OrderRecapClItemLensDetails
                      orderItemClData={orderItemClData}
                      showQuantityPerEye={false}
                      alwaysShowPrescription={false}
                      onEdit={showEditLens ? onEditClick : undefined}
                    />
                    <StyledCartRecapDivider />
                  </LensDetailsSection>
                )}
                <CartRecapTotalDataRow uppercase>
                  <div>
                    <span>
                      {t(['CartRecap.Labels.Total', 'TOTAL'])}
                      {!!totalBoxes && (
                        <CartRecapTotalBoxes>
                          {` (${t('CartRecap.Labels.BoxCount', { count: totalBoxes })})`}
                        </CartRecapTotalBoxes>
                      )}
                    </span>
                  </div>
                  <CartRecapTotalSection>
                    {!!productOrderItemPrice && isTotalDiscounted && (
                      <CartRecapRxInitialTotalPrice>
                        <FormattedPriceDisplay min={productOrderItemPrice} currency={orderItem.currency} />
                      </CartRecapRxInitialTotalPrice>
                    )}
                    {!!orderItemPriceAfterAutoDiscount && (
                      <FormattedPriceDisplay min={orderItemPriceAfterAutoDiscount} currency={orderItem.currency} />
                    )}
                  </CartRecapTotalSection>
                </CartRecapTotalDataRow>
              </CartRecapDataContent>
            </CartRecapData>
          </CartRecapRightColumn>
        </CartRecapContentContainer>
      </CartRecapItemWrapper>
    </Box>
  )
}

export default CartRecapClItem
