import styled from '@mui/material/styles/styled'

import { StyledButtonAsLink, TextField, Button } from '../UI'

export const StyledWrapper = styled('div', {
  name: 'ForgotPassword',
  slot: 'Wrapper',
})(({ theme }) => ({
  borderRadius: theme.spacing(),
  backgroundColor: 'white',
  padding: `${theme.spacing(5.5)} ${theme.spacing(5.5)}`,
  minWidth: theme.spacing(80)
}))

export const StyledHeadingContainer = styled('div', {
  name: 'ForgotPassword',
  slot: 'HeadingContainer',
})(({ theme }) => ({
  textAlign: 'center',
  color: `${theme.palette.text.dark.primary}`,
  fontWeight: 'bold',
  padding: `0 0 ${theme.spacing(4)} 0`,
  fontSize: theme.typography.h3.fontSize,

  svg: {
    margin: `-${theme.spacing(6)} ${theme.spacing(4)} 0 0`,
    float: 'right',
    cursor: 'pointer',
  },

  div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [theme.breakpoints.up('md')]: {
    padding: `0 0 ${theme.spacing(0)} 0`,
  }
}))

export const StyledBodyContainer = styled('div', {
  name: 'ForgotPassword',
  slot: 'BodyContainer',
})(() => ({}))

export const StyledMessageContainer = styled('div', {
  name: 'ForgotPassword',
  slot: 'MessageContainer',
})(({ theme }) => ({
  padding: `0 0 ${theme.spacing(4)} 0`,
 
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6)
  }
}))

export const StyledInputField = styled(TextField, {
  name: 'ForgotPassword',
  slot: 'InputField',
})(() => ({
  // width: '80%'
  width: '410px'
}))

export const StyledButton = styled(Button, {
  name: 'ForgotPassword',
  slot: 'Button',
})<{ mTop?: boolean }>(({ theme, mTop }) => ({
  marginTop: mTop ? 54 : 0,
  width: '120px',
  height: '48px',
  background: theme.palette.background.dark.primary,
  borderRadius: '4px',
  color: theme.palette.background.light.primary,

  [theme.breakpoints.up('md')]: {
    marginTop: mTop ? 32 : 0
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0',
    marginTop: mTop ? 24 : 0
  }
}))

export const StyledEmailFormContainer = styled('div', {
  name: 'ForgotPassword',
  slot: 'FormContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `0 ${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1)}`,

  div: {
    color: `${theme.palette.text.dark.primary}`,
    fontSize: theme.spacing(4),
    textAlign: 'center'
  },
  '.textfield-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      width: '80%'
    }
  }
}))

export const StyledNewPasswordContainer = styled('div', {
  name: 'ForgotPassword',
  slot: 'NewPasswordContainer',
})(({ theme }) => ({
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.spacing(3.5),
  padding: `0 ${theme.spacing(5.5)} ${theme.spacing(8)} ${theme.spacing(5.5)}`,
  margin: 0,
  minWidth: 335,

  [theme.breakpoints.up('md')]: {
    margin: '0 226px',

    '.forgot-password-message': {
      width: 400
    }
  }
}))

export const StyledResetPasswordWrapper= styled('div', {
  name: 'ForgotPassword',
  slot: 'ResetPasswordWrapper',
})(({  }) => ({
  // marginTop: theme.spacing(6),
  maxWidth: '640px',
  display: 'flex',
  flexDirection:'column',
  alignItems: 'center',
  justifyContent: 'center'
}))



export const StyledEmailNotFound = styled('div', {
  name: 'ForgotPassword',
  slot: 'EmailNotFound',
})(({ theme }) => ({
  margin: '1rem 0',
  color: `${theme.palette.color.error}`,
}))

export const StyledLeftLine = styled('div', {
  name: 'ForgotPassword',
  slot: 'LeftLine',
})(({ theme }) => ({
  height: 1,
  maxWidth: 10,
  flexGrow: 1,
  backgroundColor: `${theme.palette.text.dark.primary}`,
  marginRight: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    maxWidth: 70
  }
}))


export const StyledRightLine = styled('div', {
  name: 'ForgotPassword',
  slot: 'RightLine',
})(({ theme }) => ({
  height: 1,
  maxWidth: 10,
  flexGrow: 1,
  backgroundColor: `${theme.palette.text.dark.primary}`,
  marginLeft: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    maxWidth: 70
  }
}))

export const SubmittedEmail = styled('div', {
  name: 'ForgotPassword',
  slot: 'Email',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  color: `${theme.palette.text.dark.primary}`,
  fontWeight: 'bold',
  margin: '0px 0 16px 0',

  [theme.breakpoints.up('md')]: {
    margin: '24px 0 0 0'
  }
}))

export const ResendEmailButton = styled(StyledButtonAsLink, {
  name: 'ForgotPassword',
  slot: 'ResendEmailButton',
})(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 'bold',
  color: `${theme.palette.text.dark.primary}`,
  marginTop: 24,

  [theme.breakpoints.up('md')]: {
    marginTop: 12
  }
}))

export const StyledTemporaryPasswordSentContainer = styled('div', {
  name: 'ForgotPassword',
  slot: 'TempPasswordSent',
})(({ theme }) => ({
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,

  [theme.breakpoints.up('md')]: {
    margin: '0 226px'
  }
}))

export const StyledPasswordHelper = styled('div', {
  name: 'ForgotPassword',
  slot: 'PasswordHelper',
})(({ theme }) => ({
  textAlign: 'left',
  fontSize: 10,
  color: `${theme.palette.text.light.secondary}`,
  margin: `-${theme.spacing(2)} 0 -${theme.spacing()} ${theme.spacing(3)}`,

  div: {
    display: 'flex',
    color: `${theme.palette.text.dark.primary}`,

    div: {
      margin: `${theme.spacing()} 0 0 ${theme.spacing()}`
    },

    svg: {
      margin: `${theme.spacing()} 0 0 ${theme.spacing(2)}`
    }
  },

  [theme.breakpoints.up('md')]: {
    padding: `0 0 0 ${theme.spacing()}`
  }
}))
