import styled from '@mui/material/styles/styled'
import Link from 'next/link'
import { StyledTypography } from '@components/UI/Typography'

export const StyledReorderSummaryLabel = styled(StyledTypography, {
  name: 'StyledReorder',
  slot: 'SummaryLabel',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  fontSize: theme.typography.body2.fontSize,
}))

export const StyledEditLink = styled(Link, {
  name: 'Styled',
  slot: 'EditLink',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
}))

export const StyledEditLabel = styled('span', {
  name: 'Styled',
  slot: 'EditLabel',
})(({ theme }) => ({
  textDecoration: 'underline',
  paddingLeft: theme.spacing(1),
}))

export const StyledBillingAddressContainer = styled('div', {
  name: 'StyledBillingAddress',
  slot: 'Container',
})(({ theme }) => ({
  paddingTop: theme.spacing(5),
}))

export const StyledStrikethroughPriceContainer = styled('span', {
  name: 'StyledStrikethroughPrice',
  slot: 'Container',
})(({ theme }) => ({
  textDecoration: 'line-through',
  paddingRight: theme.spacing(1),
  fontWeight: 'lighter',
  fontSize: theme.typography.body2.fontSize,
}))
