import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { size, toNumber } from 'lodash-es'
import { OrderItemContactLensData } from '@typesApp/cart'
import { removingCLEyeSelector } from '@features/cartui/cartuiSlice'
import { ContactLensQuantitySelect } from './ContactLensQuantitySelect'
import {
  ContactLensQuantitySelectContainer,
  ContactLensQuantitySelectWrapper,
  ContactLensQuantitySelectLabel,
  ContactLensQuantitySelectSubWrapper,
} from './ContactLensDetails.style'
import FormattedPriceDisplay from '../../../../../components/formatted-price-display'
import { PreLoader } from '@components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import styles from './styles/ContactLensQuantity.module.scss'

export const ContactLensQuantity: React.FC<{
  orderItemClData: OrderItemContactLensData
  listPrice: number
  offerPrice: number
  percentageDiscount: number
  currency: string
  displayPrice?: boolean
  isDisabled?: boolean
  onQuantityChange?: (quantity: string, orderItemId: string, eye: string) => void
  onRemoveEye?: (orderiteId: string, leftItemId: string | undefined, rightItemId: string | undefined) => void
}> = ({
  orderItemClData,
  listPrice,
  currency,
  displayPrice = true,
  isDisabled = false,
  onQuantityChange,
  onRemoveEye,
}) => {
  const { t } = useTranslation()
  const removingEyeItem = useSelector(removingCLEyeSelector)
  return (
    <ContactLensQuantitySelectContainer>
      {!!orderItemClData &&
        Object.keys(orderItemClData).map(eye => {
          if (size(orderItemClData[eye].data) > 0) {
            return (
              <ContactLensQuantitySelectWrapper key={eye}>
                <ContactLensQuantitySelectLabel>
                  {t(`CartRecap.Labels.QuantitySelect_${eye}`)}
                </ContactLensQuantitySelectLabel>
                <ContactLensQuantitySelectSubWrapper>
                  <ContactLensQuantitySelect
                    eye={eye}
                    orderItemClData={orderItemClData[eye]}
                    isDisabled={isDisabled}
                    onQuantityChanged={(quantity, orderItemId) => {
                      onQuantityChange && onQuantityChange(quantity, orderItemId, eye)
                    }}
                  />
                  {!isDisabled
                    ? (removingEyeItem.orderItemId === orderItemClData[eye].orderItemId && removingEyeItem[eye] && (
                        <PreLoader fill={'dark'} paddingLeft={6} />
                      )) || (
                        <SVGIcon
                          library="account"
                          name="trash-bin"
                          className={styles.trashBinIcon}
                          onClick={() =>
                            onRemoveEye &&
                            onRemoveEye(
                              orderItemClData[eye].orderItemId || '',
                              (eye === 'left' && orderItemClData[eye].orderItemId) || undefined,
                              (eye === 'right' && orderItemClData[eye].orderItemId) || undefined
                            )
                          }
                        />
                      )
                    : null}
                </ContactLensQuantitySelectSubWrapper>
                {displayPrice && (
                  <ContactLensQuantitySelectSubWrapper>
                    <FormattedPriceDisplay
                      min={listPrice * toNumber(orderItemClData[eye].quantity || 0)}
                      currency={currency}
                    />
                  </ContactLensQuantitySelectSubWrapper>
                )}
              </ContactLensQuantitySelectWrapper>
            )
          }
          return null
        })}
    </ContactLensQuantitySelectContainer>
  )
}
