import { AxiosResponse } from 'axios'
import { Dispatch } from 'react'
import { fetchWishlistAction } from '../features/wishList/action'
import guestIdentityService from '../foundation/apis/transaction/guestIdentity.service'
import WishlistService from '../foundation/apis/transaction/wishList.service'
import { storageSessionHandler } from '../foundation/utils/storageUtil'
import { GUEST_LOGIN_SUCCESS_ACTION } from '../redux/actions/user'
import Log from '../services/Log'
import { CreateWishlistResponse, IWishlistItem } from '@typesApp/wishlist'
import { sendPLPAddToFavoritesEvent } from '../features/plp/algoliaUtils'
import { IAlgoliaHit } from '@typesApp/product'

const getGuestIdentity = async (dispatch: Dispatch<any>) => {
  const payload = { widget: 'axiosConfig' }

  const guestIdentity: AxiosResponse = await guestIdentityService.login(payload)

  dispatch(GUEST_LOGIN_SUCCESS_ACTION({ ...guestIdentity.data, ...payload }))
}

const getWishlist = async (langId: string) => {
  return await WishlistService.getWishlist(langId)
}

const createWishlist = async (): Promise<string> => {
  return await WishlistService.createWishlist()
    .then((res: CreateWishlistResponse) => res.uniqueID)
    .catch(error => {
      Log.error('Create wishlist error: ' + error)
      return ''
    })
}

const addItemToWishlist = async (item: IWishlistItem[], langId: string, dispatch: Dispatch<any>) => {
  const wishListId = await getWishListId(isLoggedInUser(), langId, dispatch)
  await WishlistService.addItemToWishlist(wishListId, item, langId)

  dispatch(fetchWishlistAction())
}

const isLoggedInUser = (): boolean => {
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  return !!currentUser
}

const getWishListId = async (isLoggedIn: boolean, langId: string, dispatch: Dispatch<any>): Promise<string> => {
  let wishListId = ''

  //if generic user, call the guest identity
  if (!isLoggedIn) {
    await getGuestIdentity(dispatch)
    wishListId = await createWishlist()
  } else {
    //if NOT generic user, call the get wishlist
    try {
      const wishList = await getWishlist(langId)
      if (!!wishList) {
        wishListId = wishList.GiftList[0].uniqueID
      } else {
        wishListId = await createWishlist()
      }
    } catch (e) {
      wishListId = await createWishlist()
    }
  }
  return wishListId
}

const addToWishList = async (langId: string, product: IAlgoliaHit, dispatch: Dispatch<any>, isLoggedIn: boolean) => {
  try {
    const partNumber = product.partnumberId
    const wishListId = await getWishListId(isLoggedIn, langId, dispatch)
    if (!!wishListId && wishListId.length > 0) {
      addItemToWishlist(
        [
          {
            partNumber: partNumber,
            quantityRequested: '1',
            location: 'online',
          },
        ],
        langId,
        dispatch
      )
      sendPLPAddToFavoritesEvent(product, isLoggedIn)
    } else {
      throw new Error()
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      Log.error('Add wishlist error: ' + error)
    }
  }
}

const removeFromWishList = async (wishListId: string, itemIdWishList: string, dispatch: Dispatch<any>) => {
  await WishlistService.deleteItemFromWishlist({
    externalId: wishListId,
    itemId: itemIdWishList,
  })

  dispatch(fetchWishlistAction())
}

export { addToWishList, addItemToWishlist, removeFromWishList }
