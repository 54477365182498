import { isValidCardExpirationDate, isValidCVV as isValidSecurityCode } from '../../../constants/paymentMethods'

const CREDIT_CARD_NUMBER_SEPARATOR = ' '
const CREDIT_CARD_MAXIMUM_NUMBER_OF_DIGITS = 19
const VISA_CARD_START_DIGITS: string[] = ['4']
const MASTER_CARD_START_DIGITS: string[] = ['51', '52', '53', '54', '55']
const AMEX_CARD_START_DIGITS: string[] = ['34', '37']
const JCB_CARD_START_DIGITS: string[] = ['35']

type CreditCardType = 'visa' | 'mc' | 'amex' | 'jcb' | 'unknown'

export interface UseCardValidationReturn {
  maxLength: number
  getCardType: (cardNumber: string) => CreditCardType
  isValidCardNumber: (cardNumber: string) => boolean
  isValidCVV: (cardNumber?: string, cvv?: string) => boolean
  isValidExpiryDate: (expiry: string, mask?: string[]) => boolean
}

export const useCardValidation = (): UseCardValidationReturn => {
  /**
   * see https://learnersbucket.com/examples/javascript/credit-card-validation-in-javascript
   */
  const isValidCardNumber = (cardNumber: string) => {
    const formattedCardNumber = cardNumber.replaceAll(CREDIT_CARD_NUMBER_SEPARATOR, '').trim()

    if (formattedCardNumber.length === 0) {
      return false
    }

    let checksum = 0
    let j = 1

    for (let i = formattedCardNumber.length - 1; i >= 0; i--) {
      let calc = Number(formattedCardNumber.charAt(i)) * j

      if (calc > 9) {
        checksum = checksum + 1
        calc = calc - 10
      }

      checksum = checksum + calc

      if (j === 1) {
        j = 2
      } else {
        j = 1
      }
    }

    return checksum % 10 === 0
  }

  const getCardType = (cardNumber: string): CreditCardType => {
    if (!cardNumber || cardNumber?.trim() === '') {
      return 'unknown'
    }
    if (VISA_CARD_START_DIGITS.indexOf(cardNumber.charAt(0)) > -1) {
      return 'visa'
    }

    const firstDigits = cardNumber.substring(0, 2)
    if (JCB_CARD_START_DIGITS.indexOf(firstDigits) > -1) {
      return 'jcb'
    }
    if (MASTER_CARD_START_DIGITS.indexOf(firstDigits) > -1) {
      return 'mc'
    }
    if (AMEX_CARD_START_DIGITS.indexOf(firstDigits) > -1) {
      return 'amex'
    }

    return 'unknown'
  }

  const isValidCVV = (cardNumber?: string, cvv?: string): boolean => {
    if (!cvv || cvv === '') {
      return false
    }
    const isAmex = getCardType(cardNumber || '') === 'amex'
    return isValidSecurityCode(cvv, { isAmex })
  }

  const isValidExpiryDate = (expiry: string, mask?: string[]): boolean => {
    const value = stripMaskChars(expiry, mask)
    const [month, year] = value.split(/\D+/).map(el => +el.substring(0, 2))
    return (month && year && isValidCardExpirationDate([month, year])) || false
  }

  const stripMaskChars = (value: string, mask?: string[]): string => {
    if (!mask || mask?.length == 0) {
      return value
    }

    const maskChars = [...new Set(mask)].join('')
    const regex = new RegExp(`[${maskChars}]`)
    return value.replace(regex, '')
  }

  return {
    maxLength: CREDIT_CARD_MAXIMUM_NUMBER_OF_DIGITS,
    getCardType,
    isValidCVV,
    isValidExpiryDate,
    isValidCardNumber,
  }
}
