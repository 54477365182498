import styled from '@mui/material/styles/styled'

export const ErrorMessage = styled('div', {
  name: 'SignIn',
  slot: 'ErrorMessage',
})(({ theme }) => ({
  color: theme.palette.color.error,
}))

export const ContentForgotPassword = styled('div', {
  name: 'SignIn',
  slot: 'ContentForgotPassword',
})(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}))
