import styled from '@mui/material/styles/styled'
//SWIPER
import { ITeaserOverlaySettings, ITeaserOverlayStyle, ITeaserOverlayTextAlign } from '@typesApp/cmsPlacement/LXTeaser'
import { teaserOverlayStyleToColor } from '@utils/placements'
import { StyledAnchor } from '@components/UI/Anchor'
import { StyledTypography } from '@components/UI/Typography'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const BoxWithMarginContainer = styled('div', {
  name: ' BoxWithMargin',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'termsAndConditionsVisible' && prop !== 'order',
})<{
  termsAndConditionsVisible?: boolean
  order?: number
}>(({ theme, order, termsAndConditionsVisible }) => ({
  position: 'relative',
  overflow: termsAndConditionsVisible ? 'hidden' : 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  padding: theme.spacing(10, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8),
  },

  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: theme.spacing(16),
    gap: 0,
    'article:first-of-type': {
      order,
    },
  },
  [theme.breakpoints.up('lg')]: {
    gap: theme.spacing(16),
    padding: theme.spacing(16),
  },
}))

export const StyledBoxWithMarginArticle = styled('article', {
  name: 'BoxWithMargin',
  slot: 'Article',
  shouldForwardProp: prop => prop !== 'overlaysettings' && prop !== 'overlaytxtalign' && prop !== 'teaseroverlaystyle',
})<{
  overlaysettings?: ITeaserOverlaySettings
  overlaytxtalign?: ITeaserOverlayTextAlign
  teaseroverlaystyle?: ITeaserOverlayStyle
}>(({ theme, overlaytxtalign, teaseroverlaystyle }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& > *': {
    ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  },
  '& > *:not(#boxWithMarginCta,#boxWithMarginTermsCondition)': {
    // ...overLayTextAlignToCSS(overlaytxtalign),
  },

  [theme.breakpoints.up('md')]: {
    '& > *': {
      // ...overLayTextAlignToCSS(overlaytxtalign),
    },
    padding: theme.spacing(16),
  },
  [theme.breakpoints.up(1280)]: {
    padding: theme.spacing(0, 16),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(16),
  },
}))

export const StyledBoxWithMarginPreTitle = styled(StyledTypography, {
  name: 'BoxWithMargin',
  slot: 'PreTitle',
  shouldForwardProp: prop => prop !== 'marginProductEnabled',
})<{ marginProductEnabled: boolean }>(({ marginProductEnabled, theme }) => ({
  fontSize: 16,
  marginBottom: theme.spacing(marginProductEnabled ? 2 : 4),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(marginProductEnabled ? 2 : 6),
  },
}))

export const StyledBoxWithMarginCTAContainer = styled('div', {
  name: 'BoxWithMargin',
  slot: 'CTAContainer',
  shouldForwardProp: prop => prop !== 'marginProductEnabled',
})<{ marginProductEnabled: boolean }>(({ marginProductEnabled, theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column wrap',
  gap: theme.spacing(4),
  marginTop: theme.spacing(6),

  //ctas always centered on mobile
  //alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(marginProductEnabled ? 0 : 8),
  },
}))

export const MediaOverlapBoxWithMarginContainer = styled('div', {
  name: ' ImageOverlap',
  slot: 'BoxWithMarginContainer',
  shouldForwardProp: prop => prop !== 'viewType',
})<{ viewType: IViewType }>(({ viewType, theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
  },
  // ...getRatioBanner(viewType, theme),
}))

export const MediaOverlapBoxWithMarginAnchor = styled(StyledAnchor, {
  name: 'BoxWithMarginImageOverlap',
  slot: 'Anchor',
})(() => ({
  display: 'block',
  fontSize: 0,
  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))

export const BoxWithMarginTermsConditionButtonContainer = styled('div', {
  name: ' BoxWithMargin',
  slot: 'TermsConditionButtonContainer ',
})(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  paddingTop: 20,
  alignItems: 'unset',
  [theme.breakpoints.up('md')]: {
    padding: 'unset',
    position: 'absolute',
    bottom: 0,
    width: `calc(100% - ${theme.spacing(24)})`,
  },
  [theme.breakpoints.up(1280)]: {
    width: `calc(100% - ${theme.spacing(32)})`,
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${theme.spacing(32)})`,
  },
}))

export const ProductTileContentBoxWithMargin = styled('div', {
  name: 'BoxWithMargin',
  slot: 'ProductTileContent',
  shouldForwardProp: prop => prop !== 'centerProducts',
})<{ centerProducts?: string }>(({ centerProducts }) => ({
  display: 'flex',
  justifyContent: centerProducts ? 'center' : 'normal',
}))

export const IconContentBoxWithMargin = styled('div', {
  name: 'BoxWithMargin',
  slot: 'IconContent',
  shouldForwardProp: prop => prop !== 'marginProductEnabled',
})<{ marginProductEnabled: boolean }>(({ marginProductEnabled, theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(marginProductEnabled ? 1 : 8),
  svg: {
    height: 48,
    width: 48,
  },
}))
