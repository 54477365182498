import { useDispatch, useSelector } from 'react-redux'
import getDisplayName from 'react-display-name'
import { isEmpty } from 'lodash-es'
import Axios, { Canceler } from 'axios'
import { Contact, PasswordFormData } from '@typesApp/user'
import { loginStatusSelector, userDetailsSelector } from '@redux/selectors/user'
import { REGISTRATION_PENDING_ACTION, registrationAction } from '@redux/actions/user'
import { useSite } from '@foundation/hooks/useSite'
import { AddressFormData } from '@typesApp/form'
import { CreatePassword } from './CreatePassword'

export function useRegisterNewUser() {
  const dispatch = useDispatch()
  const widgetName = getDisplayName(CreatePassword)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const { mySite } = useSite()
  const preferredLanguage: string = mySite ? mySite.defaultLanguageID : ''
  const storeId: string = mySite ? mySite.storeID : ''
  const catalogId: string = mySite ? mySite.catalogID : ''
  const isLoggedIn = useSelector(loginStatusSelector)
  const userDetails = useSelector(userDetailsSelector)

  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const registerUser = (addressForm: AddressFormData | null | undefined, password: PasswordFormData) => {
    const user = addressForm ? { ...addressForm } : userDetails?.contact?.[0] || ({} as Contact)
    if (!user || isEmpty(user) || isLoggedIn) return

    const body = {
      firstName: user?.firstName?.trim(),
      lastName: user?.lastName?.trim(),
      logonId: user.email1,
      logonPassword: password.password1,
      logonPasswordVerify: password.password2,
      registerType: 'G',
      profileType: 'C',
      email1: user?.email1,
      dateOfBirth: '',
      phone1: '',
      storeId,
      country: mySite.country,
      catalogId,
      preferredLanguage,
      preferredCurrency: '',
      receiveEmail: '',
      receiveEmailPreference: [
        {
          value: '',
          storeID: storeId,
        },
      ],
      challengeQuestion: '-',
      challengeAnswer: '-',
      // TODO: do we need to set this object when registering from shipping page?
      // x_optIn: {
      //   x_privacy: !check1 ? true : privacy1,
      //   x_newsLetter: !check2 ? true : privacy2,
      //   x_profiling: !check3 ? true : privacy3,
      // },
    }

    dispatch(REGISTRATION_PENDING_ACTION({ isRegistrationPending: true }))
    setTimeout(
      () =>
        dispatch(
          registrationAction({
            body: body,
            ...payloadBase,
          })
        ),
      400
    )
  }
  return registerUser
}
