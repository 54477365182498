import React, { ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MenuItem } from '@mui/material'
import { FormControlHelperText } from './FormControlHelperText'
import { SelectOption } from './types'
import { TextField } from '../TextField'
import {v4 as uuid} from 'uuid'

export interface FormControlSelectFieldProps {
  name: string
  rules: object
  label: string | ReactNode
  placeholder?: string
  description?: string | ReactNode
  showEmptyOption?: boolean
  options: SelectOption[]
  disabled?: boolean
}

export const FormControlSelectField: React.FC<FormControlSelectFieldProps> = ({
  name,
  rules,
  label,
  placeholder,
  description,
  showEmptyOption,
  options,
  disabled,
}) => {
  const { control } = useFormContext()
  const required = rules ? (rules['required']?.value === true || rules['required'] === true) : undefined

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
        <>
          <TextField
            {...rest}
            select
            inputRef={ref}
            value={value}
            fullWidth
            label={`${label}${required ? ' *' : ''}`}
            placeholder={placeholder}
            error={!!error}
            showvalidationstatus={true}
            isvalid={!error && !!value?.length}
            ispristine={!value?.length}
            disabled={disabled}
          >
            {showEmptyOption && <MenuItem key={uuid()} value=''></MenuItem>}
            {options.map(({ value, label }) => (
              <MenuItem key={uuid()} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          {description && (
            <FormControlHelperText description={description} />
          )}
          {error && (
            <FormControlHelperText error={error} />
          )}
        </>
      )}
    />
  )
}
