import React from 'react'
import { useTheme } from '@mui/material'
import { IconButton } from '../IconButton'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface ToggleShowPasswordProps {
  showPassword: boolean
  onClick: () => void
}

export const ToggleShowPassword: React.FC<ToggleShowPasswordProps> = ({
  showPassword,
  onClick,
}: ToggleShowPasswordProps) => {
  const theme = useTheme()
  return (
    <IconButton onClick={onClick}>
      {showPassword && <SVGIcon library="account" name="show" color={theme.palette.text.dark.primary} />}
      {!showPassword && <SVGIcon library="account" name="hide" color={theme.palette.text.dark.primary} />}
    </IconButton>
  )
}
