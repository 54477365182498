import styled from '@mui/material/styles/styled'
import { Button, StyledTypography } from '../UI'

export const SearchContainer = styled('div', {
  name: 'SearchStore',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.common.white,
  padding: theme.spacing(6),
  border: `solid 1px ${theme.palette.custom.alto}`,
}))

export const InputContainer = styled('div', {
  name: 'SearchStore',
  slot: 'InputContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  maxWidth: theme.spacing(131),
  height: theme.spacing(32),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(6),
}))

export const InputWithIconContainer = styled('div', {
  name: 'SearchStore',
  slot: 'InputWithIconContainer',
})(({ theme }) => ({
  width: '100%',
  position: 'relative',
  marginRight: theme.spacing(3),
}))

export const IconContainer = styled('div', {
  name: 'SearchStore',
  slot: 'IconContainer',
})(({ theme }) => ({
  position: 'absolute',
  right: '3%',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  width: theme.spacing(4),
  height: theme.spacing(4),
}))

export const StyledInput = styled('input', {
  name: 'SearchStore',
  slot: 'SearchTextField',
  shouldForwardProp: prop => prop !== 'error',
})<{
  error: boolean
}>(({ theme, error }) => ({
  height: theme.spacing(12),
  width: '100%',
  padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1),
  border: `solid 1px ${error ? theme.palette.color.error : theme.palette.custom.alto}`,
  backgroundColor: theme.palette.common.white,
}))

export const InputErrorMessage = styled('span', {
  name: 'SearchStore',
  slot: 'InputErrorText',
})(({ theme }) => ({
  color: theme.palette.color.error,
}))

export const StyledButton = styled(Button, {
  name: 'SearchStore',
  slot: 'SearchStoreButton',
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: `${theme.spacing(32)} !important`,
  height: theme.spacing(10),
}))

export const SearchTitle = styled('h2', {
  name: 'SearchStore',
  slot: 'SearchTextTitle',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  lineHeight: 1.5,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
}))

export const SearchSubTitle = styled(StyledTypography, {
  name: 'SearchStore',
  slot: 'SearchTextSubTitle',
})(({ theme }) => ({
  width: '100%',
  fontSize: theme.spacing(4.25),
}))

export const SearchTitleContainer = styled('div', {
  name: 'SearchStore',
  slot: 'SearchTitleContainer',
})(() => ({
  width: '100%',
}))
