import styled from '@mui/material/styles/styled'
import Accordion from '@mui/material/Accordion'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import { StyledCheckbox } from '../../../../../../components/UI'

export const HealthFundProviderAccordion = styled(Accordion, {
  name: 'HealthFundProvider',
  slot: 'Accordion',
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  marginBottom: `${theme.spacing(6)}`,
  boxShadow: expanded
    ? `0px 0px 0px 1px ${theme.palette.custom.blue}`
    : `0px 0px 0px 1px ${theme.palette.custom.light2.grey}`,
  
  '&:first-of-type': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingTop: 0,
  },

  '&:last-of-type': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  '&.Mui-expanded': {
    marginBottom: theme.spacing(6)
  },
}))

export const HealthFundProviderTitle = styled(AccordionSummary, {
  name: 'HealthFundProvider',
  slot: 'Title',
})(({ theme }) => ({
  minHeight: '56px',
  padding: 0,
  fontWeight: 'bold',

  '.MuiAccordionSummary-content': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  },

  '&.Mui-expanded': {
    boxShadow: `0px 1px 0px 0px ${theme.palette.custom.blue}`,
  },
}))

export const HealthFundProviderCheckbox = styled(StyledCheckbox, {
  name: 'HealthFundProvider',
  slot: 'Checkbox'
})(({theme}) => ({
  padding: 0,
  marginRight: theme.spacing(5)
}))

export const HealthFundProviderDetails = styled(AccordionDetails, {
  name: 'HealthFundProvider',
  slot: 'Details',
})(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(
    8
  )} ${theme.spacing(4)}`,
  '& .MuiTextField-root': {
    height: `${theme.spacing(12)}`,
  },
  '& .is-select.is-select .MuiInputBase-root .MuiSelect-select': {
    display: 'inline-block',
    padding: `0 ${theme.spacing(17.5)} 0 ${theme.spacing(4)}`,
    top: theme.spacing(3.25)
  }
}))
