import React from 'react'
import { DialogContainer } from './CartSubscriptionDialog.style'
import { StyledButton } from '@components/UI/Button/Button'
import { useTranslation } from 'next-i18next'

interface DialogProps {
  message: string
  handleClose: () => void
}

export function CartSubscriptionDialog(props: DialogProps) {
  const { t: translate } = useTranslation('', { keyPrefix: 'Subscriptions' })
  const { message, handleClose } = props

  return (
    <DialogContainer>
      <div className="header">
        <p className="description">{message}</p>
      </div>
      <div>
        <StyledButton variant="primary" filltype="fill" onClick={handleClose} className="cta">
          {translate('Ctas.Confirm')}
        </StyledButton>
      </div>
    </DialogContainer>
  )
}
