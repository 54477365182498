import { HYPHEN } from '@constants/common'
import DateService from '../services/DateService'
import { DeliveryCalculationProps } from '../types/order'

/**
 * Get the estimated delivery date.
 * Basic formula: Product SLA + Ship min method value + FSA lead time
 */
export const getEstimatedDeliveryDateForZipcode = (
  deliveryCalculationItems: DeliveryCalculationProps,
  locale: string,
  useFallBackValue?: boolean
): string => {
  const country = locale.slice(-2)
  const FSA_LENGTH = {
    ca: 3,
    au: 4,
    nz: 4,
  }
  const { shippingZipCode, productSlaStart, productSlaEnd } = deliveryCalculationItems

  if ((!shippingZipCode || shippingZipCode.length < FSA_LENGTH[country]) && !useFallBackValue) {
    return ''
  }
  const estimatedStartDate = DateService.addCalendarDays(Date.now(), productSlaStart)
  const estimatedEndDate = DateService.addCalendarDays(Date.now(), productSlaEnd) || Date.now()
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  if (estimatedStartDate && productSlaStart !== productSlaEnd) {
    return `${dateFormatter.format(estimatedStartDate)} ${HYPHEN} ${dateFormatter.format(estimatedEndDate)}`
  }
  return dateFormatter.format(estimatedStartDate!)
}

export const formatEstimatedDeliveryDate = (formattedDate: string, locale: string): string => {
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })
  const dateParts: string[] = formattedDate.split('/')
  const dateObject =
    dateParts.length > 2 ? new Date(+dateParts[2], (dateParts[1] as unknown as number) - 1, +dateParts[0]) : new Date()
  return dateFormatter.format(dateObject)
}
