import Axios from 'axios'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePathname } from 'next/navigation'
import config from '@configs/index'
import { CART, SIGNIN } from '@constants/routes'
import { CommerceEnvironment } from '@constants/common'
import Log from '@services/Log'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { orderApi } from '@features/order/query'
import { cartSelector, catentriesSelector, orderItemsSelector } from '@features/order/selector'
import { activityIdSelector, isPartiallyAuthenticatedSelector, loginStatusSelector, wcTokenSelector, wcTrustedTokenSelector } from '@redux/selectors/user'
import { useSite } from '@foundation/hooks/useSite/useSite'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { sendInsuranceEvent } from '@foundation/analytics/tealium/lib'
import { InsuranceEventPayload } from '@foundation/analytics/tealium/interfaces'

import { Environment, Session, ActionModule, ChatWidgetAction, CartBundle } from '../types'
import { Webchat } from '../utils/webchat'

interface UseConfigResult {
  cartBundle: CartBundle | undefined
  session: Session
  environment: Environment
  actionModule: ActionModule
}

/**
 * @returns the configuration object for direct billing integration
 */
export const useConfiguration = (): UseConfigResult => {
  const session = useSessionInit()
  const environment = useEnvironmentInit()
  const actionModule = useActionModuleInit()
  const cartBundle = useCartBundleInit()

  return {
    session,
    environment,
    actionModule,
    cartBundle,
  }
}

const useSessionInit = (): Session => {
  const cart = useSelector(cartSelector)
  const wcToken = useSelector(wcTokenSelector)
  const wcTrustedToken = useSelector(wcTrustedTokenSelector)
  const isLoggedIn = useSelector(loginStatusSelector)
  const activityId = useSelector(activityIdSelector)
  const isPartiallyAuthenticated = useSelector(isPartiallyAuthenticatedSelector)

  return {
    wcToken,
    wcTrustedToken,
    isLoggedIn,
    sessionId: `${activityId ?? ''}`,
    cartId: cart?.orderId ?? '',
    isPartiallyAuthenticated,
  }
}

const useEnvironmentInit = (): Environment => {
  const { mySite } = useSite()
  const { country, langCode, langId } = useStoreIdentity()
  const commerceLocale = mySite.locale.toLowerCase()
  const pathname = usePathname()

  return {
    host: config.directBilling.host,
    brand: 'CLE',
    country,
    locale: langCode,
    langId: mySite.langId,
    storeId: mySite.storeID,
    currency: mySite.defaultCurrencyID || '',
    dateFormat: CommerceEnvironment.dateFormat[commerceLocale]?.format || 'MM/dd/yyyy',
    basePath: pathname,
    cartPath: `/${langCode}/${CART}`,
    signInPath: `/${langCode}/${SIGNIN}`,
    signInRedirectParam: 'redirectRoute',
    ecommerceCode: 'N1',
    ecommerceTransactionHost: config.transactionHost ?? '',
    ecommerceEnvInstance: config.envInstance ?? '',
    termsConditionsUrl: `/${langCode}/c/terms-and-conditions`,
    privacyPolicyUrl: `/${langCode}/c/privacy-policy`,
    insuranceLandingPageUrl: `/${langCode}/c/${
      langCode.includes('fr')
        ? 'facturation-directe-pour-assurance-des-soins-de-la-vue'
        : 'direct-billing-vision-care-insurance'
    }`,
    assetHost: mySite?.xStoreCfg?.['damDomain'] ?? config.defaultDamDomain,
  }
}

const useActionModuleInit = (): ActionModule => {
  const dispatch = useDispatch()
  const { mySite } = useSite()

  return {
    refreshCart: (_payload?: unknown): Promise<void> => {
      return new Promise(resolve => {
        dispatch(orderApi.endpoints.getCart.initiate({ storeId: mySite.storeID }, { forceRefetch: true }))
        resolve()
      })
    },

    sendAnalyticsEvent: (payload: unknown): Promise<void> => {
      return new Promise(resolve => {
        sendInsuranceEvent(payload as InsuranceEventPayload)
        resolve()
      })
    },

    /**
     * When db ui navigates through each form, issue a small request to keep the N1 session alive
     */
    onFormAction: (): Promise<void> => {
      return new Promise(resolve => {
        const url = `${config.transactionHost}${config.transactionContext}/store/${mySite.storeID}/customersegments`
        Axios.get(url)
          .then(() => resolve())
          .catch(error => Log.error('[DIRECTBILLING] form action error: ' + error))
      })
    },

    onChatWidgetAction: (action: ChatWidgetAction): Promise<void> => {
      return new Promise(resolve => {
        if (action === 'open') {
          Webchat.openWebchat()
        } else if (action === 'hide') {
          Webchat.hideWebchat()
        } else if (action === 'toggle') {
          Webchat.toggleWebchat()
        } else if (action === 'reset') {
          Webchat.resetWebchat()
        }
        resolve()
      })
    },
  }
}

const useCartBundleInit = (): CartBundle | undefined => {
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)
  const catalog = useSelector(catentriesSelector)
  const customerSegments = useCustomerSegmentsUtil()

  const { adjustment = [], promotionCode = [], orderExtendAttribute = [], orderItem, ...order } = cart ?? {}

  const orderInfo = {
    order: order,
    adjustments: cart?.adjustment || [],
    promotionCodes: cart?.promotionCode || [],
    orderAttributes: cart?.orderExtendAttribute || [],
  }

  const cartBundle = useMemo<CartBundle | undefined>(() => {
    return {
      orderInfo,
      orderItems,
      catalog,
      options: {
        customerSegments,
      },
    }
  }, [orderItems])

  return cartBundle
}
