import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { GridContainer, GridItem, NewPassword, StyledTypography } from '../../../../components/UI'
import { FormControlPasswordField } from '../../../../components/UI/Form'
import { PasswordValidationMessages } from '../../../../components/UI/NewPassword'
import { StyledFormContainer } from './ChangePasswordForm.style'

export interface ChangePasswordFormProps {
  wrongPassword: boolean
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ wrongPassword = false }) => {
  const { t } = useTranslation()
  const [newPasswordValid, setNewPasswordValid] = useState(false)
  const { control, getValues, setError, clearErrors, trigger } = useFormContext()

  const messages = {
    empty: t('ChangePasswordSection.Msgs.Empty.NewPassword'),
    invalid: t('ChangePasswordSection.Msgs.Invalid.NewPassword'),
    requirements: t('ChangePasswordSection.Msgs.Requirements', { returnObjects: true }),
  } as PasswordValidationMessages

  if (wrongPassword) {
    setError('currentPassword', {
      type: 'validate',
      message: t('ChangePasswordSection.Msgs.Invalid.CurrentPasswordIncorrect') || '',
    })
  } else {
    clearErrors('currentPassword')
  }

  const isNotMatch = (verifyPassword: string): boolean => {
    const newPwd = getValues('newPassword')
    return (verifyPassword && verifyPassword !== newPwd) as boolean
  }

  const isSameAsOld = (verifyPassword: string): boolean => {
    const currentPwd = getValues('currentPassword')
    return (verifyPassword && verifyPassword === currentPwd) as boolean
  }

  return (
    <StyledFormContainer>
      <GridContainer>
        <GridItem xs={12}>
          <StyledTypography sx={{ fontWeight: 700 }}>{t('ChangePasswordSection.Title')}</StyledTypography>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlPasswordField
            name="currentPassword"
            label={t('ChangePasswordSection.Labels.CurrentPassword') || ''}
            rules={{
              required: {
                value: true,
                message: t('ChangePasswordSection.Msgs.Empty.CurrentPassword'),
              },
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer className="NewPassword-Container">
        <GridItem xs={12} sm={6}>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('ChangePasswordSection.Msgs.Empty.NewPassword'),
              },
              validate: (_value: string): any => {
                trigger('verifyPassword')
                return newPasswordValid || messages.invalid
              },
            }}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <NewPassword
                {...field}
                error={!!error}
                label={t('ChangePasswordSection.Labels.NewPassword') || ''}
                description={t('ChangePasswordSection.Labels.NewPasswordDescription') || ''}
                messages={messages}
                onValidationChange={(isValid: boolean) => setNewPasswordValid(isValid)}
              />
            )}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <FormControlPasswordField
            name="verifyPassword"
            label={t('ChangePasswordSection.Labels.VerifyPassword') || ''}
            rules={{
              required: {
                value: true,
                message: t('ChangePasswordSection.Msgs.Empty.VerifyPassword'),
              },
              validate: (value: string) => {
                if (isNotMatch(value)) {
                  return t('ChangePasswordSection.Msgs.Invalid.VerifyPassword')
                }
                if (isSameAsOld(value)) {
                  return t('ChangePasswordSection.Msgs.Invalid.NewPasswordSameAsOld')
                }
                return true
              },
            }}
          />
        </GridItem>
      </GridContainer>
    </StyledFormContainer>
  )
}
