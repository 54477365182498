import React from 'react'
import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled'
import styled from '@mui/material/styles/styled'

export interface PillProps extends ButtonUnstyledProps {
  labelText?: string
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quinary'
}

export const variantMap = (
  theme,
  variant: PillProps['variant'] = 'primary'
) => {
  const primary = {
    background: theme.palette.background.dark.primary,
    hoverBackground: theme.palette.background.dark.quaternary,
    color: theme.palette.text.light.primary,
    colorHover: theme.palette.text.light.primary,
    borderColor: theme.palette.background.dark.primary,
    borderColorhover: theme.palette.background.dark.tertiary,
  }
  const secondary = {
    background: theme.palette.custom.wildSand,
    hoverBackground: theme.palette.background.dark.primary,
    color: theme.palette.text.dark.primary,
    colorHover: theme.palette.text.light.primary,
    borderColor: theme.palette.background.light.tertiary,
    borderColorhover: theme.palette.background.light.tertiary,
  }
  const tertiary = {
    background: theme.palette.background.dark.primary,
    hoverBackground: theme.palette.background.dark.primary,
    color: theme.palette.text.light.primary,
    colorHover: theme.palette.text.light.primary,
    borderColor: theme.palette.background.dark.primary,
    borderColorhover: theme.palette.background.dark.tertiary,
  }
  const quinary = {
    background: theme.palette.background.dark.secondary,
    hoverBackground: theme.palette.background.dark.secondary,
    color: theme.palette.text.light.primary,
    colorHover: theme.palette.text.light.primary,
    borderColor: theme.palette.background.dark.secondary,
    borderColorhover: theme.palette.background.dark.secondary,
  }

  return {
    primary,
    secondary,
    tertiary,
    quinary,
  }[variant]
}

export const Pill = styled(
  ({ labelText, startIcon, endIcon, ...props }: PillProps) => (
    <ButtonUnstyled {...props}>
      {startIcon}
      {labelText}
      {endIcon}
    </ButtonUnstyled>
  )
)(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
  borderRadius: 40,
  appearance: 'none',
  fontFamily: 'inherit',
  fontWeight: 700,
  fontSize: theme.spacing(3),
  textTransform: 'uppercase',
  lineHeight: theme.spacing(4),
  cursor: 'pointer',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

  color: variantMap(theme, variant)?.color || 'inherit',
  background: variantMap(theme, variant)?.background || 'inherit',
  border: `1px solid${variantMap(theme, variant)?.borderColor || 'inherit'}`,

  '&:hover': {
    background: variantMap(theme, variant)?.hoverBackground || 'inherit',
    color: variantMap(theme, variant)?.colorHover || 'inherit',
    borderColor: `${variantMap(theme, variant)?.hoverBackground || 'inherit'}`,
  },

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}))
