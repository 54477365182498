import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV4 } from '../../../../constants/ui'

export const WrapperHeader = styled('header', {
  shouldForwardProp: prop => prop !== 'isSticky',
  name: 'Header',
  slot: 'Wrapper',
})<{ isSticky: boolean | null }>(({ theme, isSticky }) => ({
  position: 'sticky',
  visibility: 'visible',
  top: 0,
  left: 0,
  zIndex: Z_INDEX_LV4,
  transform: isSticky || isSticky === null ? 'translateY(0%)' : 'translateY(-100%)',
  transition: `all ${theme.transitions.duration.standard}ms ease-in-out`,
  [theme.breakpoints.up('lg')]: {
    transform: isSticky || isSticky === null ? 'translateY(0%)' : 'translateY(-40px)',
  },
}))
