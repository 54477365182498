import React, { MouseEventHandler } from 'react'
import { Stack } from '@mui/material'
import { Button, PreLoader } from '../../../../components/UI'
import { ButtonProps } from '@typesApp/button'
import { SubmitSuccessMessage } from '../SubmitSuccessMessage/SubmitSuccessMessage'

export interface SubmitActionProps {
  form?: string
  label: string
  isBusy: boolean
  isSuccess: boolean
  disabled?: boolean
  ctaVariant?: ButtonProps['variant']
  successMessage?: string
  onClick?: MouseEventHandler
}

export const SubmitAction: React.FC<SubmitActionProps> = ({ form, label, isBusy, isSuccess, disabled = false, ctaVariant, successMessage, onClick }) => {
  return (
    <Stack spacing={2} alignItems={'flex-start'}>
      <Button
        type='submit'
        variant={ctaVariant}
        disabled={isBusy || disabled}
        form={form}
        onClick={onClick}
        sx={{ marginLeft: 0, marginRight: 0}}
      >
        {isBusy && <PreLoader fill={'light'} withButton />}
        {label}
      </Button>

      {!isBusy && isSuccess && successMessage &&
        <SubmitSuccessMessage
          message={successMessage}
        />
      }
    </Stack>
  )
}
