import { useEffect, useState } from 'react'
//CONSTS
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_MAP } from '../../constants/product'
//TYPES
import { ProductSoldOutStatus, ProductType, IProduct } from '../../types/product'
import inventoryavailabilityService from '../../foundation/apis/transaction/inventoryavailability.service'
import { getSoldOut } from '../../utils/productAttributes'
import { useSite } from '../../foundation/hooks/useSite'
import ProductService from '../../services/ProductService'

export interface Inventory {
  availableQuantity?: string
  inventoryStatus?: string
  onlineStoreId?: string
  onlineStoreName?: string
  productId?: string
  unitOfMeasure?: string
}

const AVAILABLE_KEY = 'Available'
const INFINITE_INVENTORY_PRODUCT_TYPES: ProductType[] = [PRODUCT_TYPES_MAP['contact lenses']]

const getInventoryAvailability = (inventory: Inventory): boolean => {
  const { availableQuantity, inventoryStatus } = inventory

  return inventoryStatus === AVAILABLE_KEY && parseInt(availableQuantity || '') > 0
}

export const useProductSoldOutState = ({
  product,
  currentProductPartNumber,
}: {
  product?: IProduct
  currentProductPartNumber: string
}): {
  addToCartButtonFail: boolean
  onlyFewPieces: boolean
  soldOutStatus: ProductSoldOutStatus
} => {
  const { mySite } = useSite()
  const storeID = mySite.storeID || ''

  const [addToCartButtonFail, setAddToCartButtonFail] = useState<boolean>(false)
  const [onlyFewPieces, setOnlyFewPieces] = useState<boolean>(false)
  const [soldOutStatus, setSoldOutStatus] = useState<ProductSoldOutStatus>(PRODUCT_SOLDOUT_STATUS.NONE)

  /**
   * @returns indicator of product presence in inventory.
   * Value is `boolean`, if `products` prop is single `IProduct`,
   * and map with `partNumber` as key, `boolean` as value.
   */
  const fetchInventoryStatus = async (partNumber: string): Promise<boolean> => {
    try {
      const { data } = await inventoryavailabilityService.getInventoryAvailabilityByProductId({
        storeID,
        productIds: partNumber,
      })

      const InventoryAvailability = data.InventoryAvailability as Inventory[]
      const availableQuantity = InventoryAvailability?.[0]?.availableQuantity

      if (!InventoryAvailability) {
        setAddToCartButtonFail(true)

        return false
      }

      setAddToCartButtonFail(false)

      if (InventoryAvailability && parseInt(availableQuantity!) > 0 && parseInt(availableQuantity!) <= 2) {
        setOnlyFewPieces(true)
        return true
      }

      setOnlyFewPieces(false)

      const inventory = InventoryAvailability.find(i => i)!

      return inventory ? getInventoryAvailability(inventory) : false
    } catch (e) {
      return false
    }
  }

  /** @returns `ProductSoldOutStatus` based on indicator of product presence in inventory and product's attributes. */
  const getSoldOutStatus = (availabilityInInventory: boolean, products: IProduct): ProductSoldOutStatus => {
    if (availabilityInInventory) {
      return PRODUCT_SOLDOUT_STATUS.NONE
    } else {
      const attributeSoldOutValue = products && getSoldOut(products)

      return attributeSoldOutValue === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON ||
        attributeSoldOutValue === PRODUCT_SOLDOUT_STATUS.COMING_SOON
        ? attributeSoldOutValue
        : PRODUCT_SOLDOUT_STATUS.SOLDOUT
    }
  }

  /** Logic for determining value of `soldOutStatus` variable. */
  useEffect(() => {
    if (!product) {
      return
    }

    const inventoryId = currentProductPartNumber || product.partNumber
    const productType = ProductService.getProductType(product)
    const soldOutAttributeValue = getSoldOut(product) || null

    if (INFINITE_INVENTORY_PRODUCT_TYPES.includes(productType)) {
      setSoldOutStatus(PRODUCT_SOLDOUT_STATUS.INFINITE_INVENTORY)
    } else if (inventoryId && productType && !soldOutAttributeValue) {
      fetchInventoryStatus(inventoryId).then(isAvailable => {
        setSoldOutStatus(getSoldOutStatus(isAvailable, product))
      })
    } else if (soldOutAttributeValue && inventoryId) {
      if (soldOutAttributeValue === PRODUCT_SOLDOUT_STATUS.NONE && product.buyable === 'true') {
        fetchInventoryStatus(inventoryId).then(isAvailable => {
          setSoldOutStatus(getSoldOutStatus(isAvailable, product))
        })
      } else {
        setSoldOutStatus(soldOutAttributeValue as ProductSoldOutStatus)
      }
    }
  }, [product?.partNumber])

  return {
    addToCartButtonFail,
    soldOutStatus,
    onlyFewPieces,
  }
}
