import styled from '@mui/material/styles/styled'

export const StyledTotal = styled('div', {
  name: 'OrderTotalSummary',
  slot: 'Total',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: 20,
  fontWeight: 'bold',
}))

export const StyledHandlingFee = styled('div', {
  name: 'OrderTotalSummary',
  slot: 'HandlingFeeText',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  marginRight: theme.spacing(0.5),
}))

export const StyledList = styled('div', {
  name: 'OrderShippingList',
  slot: 'Wrapper',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledListItem = styled('div', {
  name: 'OrderShippingListItem',
  slot: 'Wrapper',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const HandlingFeeDiv = styled('div', {
  name: 'OrderRecap',
  slot: 'HandlingFeeDiv',
})(({ theme }) => ({
  display: 'flex',
  margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
  'span': {
    marginRight: theme.spacing(1)
  }
}))

export const StyledDiscountPriceValue = styled('p', {
  name: 'DiscountPrice',
  slot: 'Value',
})`
  margin: 0;
  color: ${({ theme }) => theme.palette.color.warning};
`
