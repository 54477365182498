import React, { FC } from 'react'
import { StyledTooltip } from '../../../components/UI'
import { useTranslation } from 'next-i18next'
import { PRODUCT_TYPES_KEYS } from '@constants/product'
import { TotalContainer, ProductPriceLabel } from './ProductPrice.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

type PriceTotalContainerProps = {
  productType: string | undefined
  isPDP: boolean
  isStickyBar: boolean
}

const TotalLabelContainer: FC<PriceTotalContainerProps> = ({ productType, isPDP, isStickyBar }) => {
  const { t: translate } = useTranslation()

  const isContactLensesPDP = isPDP && productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES

  return (
    <TotalContainer>
      <ProductPriceLabel isPDP={isPDP} isStickyBar={isStickyBar}>
        {translate('Labels.Total')}
      </ProductPriceLabel>
      {isContactLensesPDP && (
        <StyledTooltip
          contentVariant={{
            type: 'textonly',
            body: translate('ContactLenses.Tooltip.Total'),
          }}
          colorVariant={'darkgray'}
          placement={'right'}
        >
          <span>
            <SVGIcon library="validation" name="info" color="black" />
          </span>
        </StyledTooltip>
      )}
    </TotalContainer>
  )
}

export default TotalLabelContainer
