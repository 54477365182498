//Standard libraries
import React, { ChangeEvent, useState } from 'react'
//Custom libraries
import { StyledRadioGroup, StyledTypography } from '../../../../../components/UI'

import { UsableAddress } from '@typesApp/checkout'
import addressUtil from '../../../../../utils/addressUtil'
import personService from '../../../../../foundation/apis/transaction/person.service'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { userDetailsSelector } from '../../../../../redux/selectors/user'
import {
  AddressSelectionContainer,
  AddressSelectionEditLinkButton,
  AddressSelectionError,
  AddressSelectionLabel,
  AddressSelectionNameAndSurname,
  AddressSelectionNewLabel,
  AddressSelectionNewWrapper,
  AddressSelectionRadioButton,
  AddressSelectionWrapper,
} from './AddressSelection.style'
import { shippingFormFieldsSelector } from '../../../../../redux/selectors/site'
import { Contact } from '@typesApp/user'

interface AddressSelectionProps {
  addressList: UsableAddress[]
  selectedAddressId?: string
  setIsEditingAddress: (isEditing: boolean) => void
  setAddressId: (addressId: string) => void
  setValidAddressSelected: (isValidAddressSelected: boolean) => void
  handleChange?: (e: ChangeEvent<HTMLInputElement>, zipCode: string) => void
}

/**
 * Address list display component
 * displays list of addresses
 * @param props
 */
const AddressSelection: React.FC<AddressSelectionProps> = ({
  addressList = [],
  selectedAddressId,
  setIsEditingAddress,
  setAddressId,
  setValidAddressSelected,
  handleChange,
}) => {
  const { t } = useTranslation()
  const contactArray = useSelector(userDetailsSelector)?.contact || []
  const contactMap = personService.generateContactMap(contactArray)
  const shippingFormFields = useSelector(shippingFormFieldsSelector)

  const [isValidAddressFound, setValidAddressFound] = useState<boolean>(false)

  const isAddressValid = (address: UsableAddress, addressDetail: Contact, isAddressInvalid: boolean | undefined) => {
    return (
      (selectedAddressId ? selectedAddressId === address.addressId : addressDetail?.primary === 'true') &&
      !isAddressInvalid
    )
  }

  return (
    <AddressSelectionContainer className="checkout-addresses-list-selection">
      <StyledRadioGroup
        name="shippingAddress"
        value={selectedAddressId}
        onChange={event => {
          handleChange && handleChange(event, contactMap[event.target.value]?.zipCode || '')
        }}
      >
        <AddressSelectionWrapper>
          {addressList
            .filter(address => Object.prototype.hasOwnProperty.call(contactMap, address.addressId))
            .map((address: UsableAddress) => {
              const addressDetail = contactMap[address.addressId] || ({} as typeof contactMap)

              const { firstName, lastName, addressLine, city, state, zipCode, country, phone1 } = addressDetail
              if (!selectedAddressId && addressDetail?.primary === 'true') {
                selectedAddressId = addressDetail.addressId
                setAddressId(selectedAddressId)
              }
              const addressDataFields = [
                [firstName || '', lastName || ''].join(' '),
                addressLine ? addressLine[0].replace(/\s/g, ' ') || ' ' : '',
                city || '',
                state || '',
                country || '',
                zipCode || '',
              ]

              const customNickName = addressDetail
                ? addressUtil.getAddressNickName(addressDataFields.slice(1))
                : address.nickName

              const isAddressInvalid = shippingFormFields?.some(field => {
                const fieldName = field.fieldName === 'addressLine1' ? 'addressLine' : field.fieldName
                const fieldValue =
                  field.fieldName === 'addressLine1' ? addressDetail[fieldName][0] : addressDetail[fieldName]
                if (field.mandatory) {
                  if (!fieldValue) {
                    return true
                  }
                  return !new RegExp(field.validation || '^$').test(fieldValue)
                }
                return false
              })
              if (!isValidAddressFound) {
                const isValidAddress = isAddressValid(address, addressDetail, isAddressInvalid)
                isValidAddress && setValidAddressFound(isValidAddress)
                isValidAddress && setValidAddressSelected(isValidAddress)
              }
              return (
                <AddressSelectionLabel
                  checked={isAddressValid(address, addressDetail, isAddressInvalid)}
                  disabled={isAddressInvalid}
                  key={address.addressId}
                  value={address.addressId}
                  control={
                    <AddressSelectionRadioButton checked={isAddressValid(address, addressDetail, isAddressInvalid)} />
                  }
                  label={
                    <>
                      <AddressSelectionEditLinkButton
                        onClick={() => {
                          setAddressId(address.addressId)
                          setIsEditingAddress(true)
                        }}
                      >
                        {t('Shipping.Labels.EditAddress')}
                      </AddressSelectionEditLinkButton>
                      {isAddressInvalid && (
                        <AddressSelectionError>{t('Shipping.Labels.AddressError')}</AddressSelectionError>
                      )}
                      <AddressSelectionNameAndSurname>{addressDataFields[0]}</AddressSelectionNameAndSurname>
                      <StyledTypography variant="body2">{customNickName}</StyledTypography>
                      <StyledTypography variant="body2">{phone1}</StyledTypography>
                    </>
                  }
                />
              )
            })}
        </AddressSelectionWrapper>
        <AddressSelectionNewWrapper
          data-element-id="X_X_Cart_newAddress"
          key="newAddress"
          value="newAddress"
          control={<AddressSelectionRadioButton hidden />}
          label={
            <AddressSelectionNewLabel variant="body2">{t('Shipping.Labels.AddNewAddress')}</AddressSelectionNewLabel>
          }
        />
      </StyledRadioGroup>
    </AddressSelectionContainer>
  )
}

export default AddressSelection
