import { ClickAwayListener, Slide } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { StyledFormControlLabel, StyledRadio, StyledRadioGroup } from '../../../components/UI'
import { Order } from '../../../types/order'
import { FilterOption } from './orderhistoryfilter'
import {
  OrderFilterContainer,
  OrderFilterContent,
  OrderFilterPill,
  OrderFilterWrapper,
  OrderCountLabel,
} from './OrderFilter.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface OrderFilterProps {
  orders: Order[]
  selectedOption: FilterOption
  onChange: (option: FilterOption) => void
}

export const OrderFilter: React.FC<OrderFilterProps> = ({ orders, selectedOption, onChange }) => {
  const { t, i18n } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const contentContainerRef = useRef<HTMLDivElement>(null)

  const onFilterChange = (option: FilterOption) => {
    setMenuOpen(false)
    onChange(option)
  }

  const menuContent = useMemo<JSX.Element[]>(() => {
    return Object.values(FilterOption).map(option => {
      return (
        <StyledFormControlLabel
          key={option}
          value={option}
          control={<StyledRadio />}
          label={t(`Order.${option}`)}
          onChange={() => onFilterChange(option)}
        />
      )
    })
  }, [i18n.language])

  return (
    <OrderFilterWrapper>
      <OrderCountLabel>{t('Order.OrdersMadeIn', { count: orders.length })}</OrderCountLabel>
      <OrderFilterPill
        labelText={t(`Order.${selectedOption}`)}
        variant={'secondary'}
        endIcon={menuOpen ? <SVGIcon library="arrow" name="arrow-up" /> : <SVGIcon library="arrow" name="arrow-down" />}
        onClick={() => setMenuOpen(v => !v)}
      />
      <div className="order-history__filter-container" ref={contentContainerRef}>
        {menuOpen && (
          <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
            <Slide
              direction="down"
              in={menuOpen}
              timeout={500}
              container={contentContainerRef.current}
              mountOnEnter
              unmountOnExit
            >
              <OrderFilterContent isVisible={true}>
                <OrderFilterContainer>
                  <StyledRadioGroup defaultValue={selectedOption}>{menuContent}</StyledRadioGroup>
                </OrderFilterContainer>
              </OrderFilterContent>
            </Slide>
          </ClickAwayListener>
        )}
      </div>
    </OrderFilterWrapper>
  )
}
