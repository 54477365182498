import * as ROUTES from '@constants/routes'

import {
  ACTIVITY_TOKEN_ERROR_KEY,
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  EXPIRED_COOKIE_ERROR_KEY,
  INVALID_COOKIE_ERROR_KEY,
} from '@constants/errors'
import Axios, { Canceler } from 'axios'
import {
  INIT_STATE_FROM_STORAGE_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
  LOGOUT_REQUESTED_ACTION,
} from '@redux/actions/user'
import React, { Dispatch, useEffect, useState, PropsWithChildren } from 'react'
import { useSite } from '@foundation/hooks/useSite'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import BaseLayout from './BaseLayout'
import { CANCEL_SESSION_ERROR_ACTION } from '@redux/actions/error'
import { CommerceEnvironment } from '@constants/common'
import Log from '@services/Log'
import { SIGNIN } from '@constants/routes'
import config from '@configs/index'
import { countryUtil } from '@utils/countryUtil'
import getDisplayName from 'react-display-name'
import maintenanceService from '@foundation/apis/maintenance/maintenance.service'
import { genericErrorSelector, sessionErrorSelector } from '@redux/selectors/error'
import { localStorageUtil, storageSessionHandler } from '@foundation/utils/storageUtil'
import { useTranslation } from 'next-i18next'
import { customerSegmentsEnabledSelector } from '@redux/selectors/site'
import { setCustomerSegment } from '@foundation/hooks/useCustomerSegment'
import { userDetailsResponseStatusSelector, loginStatusSelector } from '@redux/selectors/user'
import { useRouter } from 'next/router'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { isLocalhost } from '../serviceWorker'
import { useIdleTimer } from '@hooks/useIdleTimer'
import { orderItemsSelector } from '@features/order/selector'
import { ProductForMonetate, monetateMapProductsInCart } from '@foundation/monetate/lib'
import { MONETATE_CART_ITEMS } from '@foundation/monetate/monetateConstants'

export const StoreAppContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const [hasError, setHasError] = useState(false)
  const widgetName = getDisplayName(StoreAppContainer)
  const dispatch = useDispatch<Dispatch<any>>()
  const router = useRouter()
  const { mySite } = useSite()
  const country = router.locale?.toLowerCase()
  const { i18n } = useTranslation()
  const CancelToken = Axios.CancelToken
  const cancels: Canceler[] = []
  const customerSegments = useCustomerSegmentsUtil()[0]

  const customerSegmentEnabled = useSelector(customerSegmentsEnabledSelector)
  const isUserDetailsResponseStatusOk = useSelector(userDetailsResponseStatusSelector)

  const countryValues = CommerceEnvironment.localeLangMap
  const controlUrlCountry = countryValues.find(t => {
    const { currentLangCode } = countryUtil('', t.langCode)
    return country === currentLangCode
  })
  const loginStatus = useSelector(loginStatusSelector)
  const sessionError = useSelector(sessionErrorSelector, shallowEqual)
  const error: any = useSelector(genericErrorSelector)
  const payloadBase: any = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const itemsInCart = useSelector(orderItemsSelector)

  const onIdle = () => {
    // To trigger the customersegment API and check if it has an expired token
    setCustomerSegment(mySite)
  }

  // Default timeout: 30 mins (30 * 60secs)
  const getSessionTimeout = mySite.xStoreCfg.SESSION_TIMEOUT_SECONDS ?? 1800
  const sessionTimeout = 1000 * getSessionTimeout

  useIdleTimer({
    onIdle,
    timeout: sessionTimeout,
  })

  useEffect(() => {
    // isUserDetailsResponseStatusOk is a flag to execute the setCustomerSegment after getting the data
    // of the user details response and its response status is ok
    if (customerSegmentEnabled && isUserDetailsResponseStatusOk) {
      setCustomerSegment(mySite)
    }
  }, [loginStatus, customerSegments, customerSegmentEnabled, isUserDetailsResponseStatusOk])

  useEffect(() => {
    if (
      !hasError &&
      (sessionError.errorKey === INVALID_COOKIE_ERROR_KEY ||
        sessionError.errorCode === EXPIRED_ACTIVITY_TOKEN_ERROR ||
        error.errorKey === EXPIRED_COOKIE_ERROR_KEY)
    ) {
      const payload = {
        ...payloadBase,
      }
      setHasError(true)
      dispatch(CANCEL_SESSION_ERROR_ACTION(payload))
      dispatch(LOGOUT_REQUESTED_ACTION(payload))
    } else if (sessionError.errorKey === ACTIVITY_TOKEN_ERROR_KEY) {
      const payload = {
        ...payloadBase,
      }
      dispatch(CANCEL_SESSION_ERROR_ACTION(payload))
      dispatch(LOGOUT_REQUESTED_ACTION(payload))
    }
  }, [sessionError, error])

  useEffect(() => {
    if (hasError && !loginStatus) {
      setHasError(false)
      router.push(`/${SIGNIN}?sessionExpired=true`)
    }
  }, [hasError, loginStatus])

  useEffect(() => {
    if (!isLocalhost) {
      const interval = setInterval(async () => {
        const maintenanceStatus = await maintenanceService.checkMaintenanceStatus()
        if (maintenanceStatus === config.maintenanceStatus) {
          router.push(ROUTES.MAINTENANCE)
        }
      }, 20e3)

      return () => {
        clearInterval(interval)
      }
    }
    return () => {}
  }, [])

  Log.info('StoreAppContainer')

  // used for tracking products in the cart on every page
  useEffect(() => {
    const productsInCart: ProductForMonetate[] = monetateMapProductsInCart(itemsInCart)
    localStorageUtil.set(MONETATE_CART_ITEMS, JSON.stringify(productsInCart))
  }, [itemsInCart])

  useEffect(() => {
    const cancels: Canceler[] = []

    const payloadBase = {
      widget: widgetName,
      storeId: mySite.storeID,
      langId: mySite?.langId || mySite?.defaultLanguageID,
      cancelToken: new CancelToken(c => {
        cancels.push(c)
      }),
    }

    if (controlUrlCountry) {
      if (mySite) {
        Log.info('INIT STATE FROM STORAGE SITE')
        dispatch(INIT_STATE_FROM_STORAGE_ACTION(payloadBase))

        storageSessionHandler.triggerUserStorageListener(() => dispatch(LISTEN_USER_FROM_STORAGE_ACTION(payloadBase)))

        // SET TRANSLATE
        // const locale =
        //localStorageUtil.get(LOCALE)?.split('_').join('-') ||
        //CommerceEnvironment.languageMap[mySite.defaultLanguageID]
        //  controlUrlCountry.langCode.split('_').join('-')
        // Log.info('MYSITE LOCALE: ', mySite.locale)
        // Log.info('LANGUAGEMAP LOCALE: ', locale)
        // if (locale !== i18n.languages[0]) {
        //   i18n.changeLanguage(locale)
        // }
        // Log.info('SET LOCALE in storeAppContainer ', locale)
      } else {
        // Log.info('SET LOCALE in storeAppContainer ', currentCountry)
        // initSite(site, controlUrlCountry.langCode, currentCountry, dispatch)
      }
    }
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [mySite, dispatch, i18n, widgetName, country, CancelToken, controlUrlCountry])

  Log.info('App routing setup in StoreAppContainer, selected country: ' + country)

  if (!controlUrlCountry) {
  }

  return <BaseLayout>{children}</BaseLayout>
}
