//Standard libraries
import { Canceler } from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux'
import { RESET_ERROR_ACTION } from '../../redux/actions/error'
import {
  AUTHENTICATION_ERROR,
  BAD_PARMS_ERROR,
  ERR_COOKIE_EXPIRED,
  ERR_INTERNAL_SERVER_ERROR,
  ERR_LOGIN_NOT_ALLOWED_NOW,
  ERR_PERSON_ACCOUNT_LOCKED_OUT,
  INVALID_NEW_PASSWORD,
  INVALID_PARAMETER_ERROR,
  LOCK_ORDER_ACTION,
  LOCK_ORDRE_ERROR,
  PARENT_IFRAME,
  SESSION_ERROR_ACTION,
  ERR_WUNDERKIND_LOGIN_SYNC,
} from '../constants/csr'
import { ERROR_CART_OUT_OF_STOCK } from '@constants/errors'

const useCSRForUser = () => {
  const dispatch = useDispatch()
  const cancels: Canceler[] = []
  const handleForUserSessionError = (errorMsgKey: string) => {
    if (typeof window == 'undefined') return
    const parentIFrame: any = window[PARENT_IFRAME]
    if (parentIFrame) {
      const sessionError = {
        msgKey: errorMsgKey,
        className: 'error',
      }
      parentIFrame.sendMessage(
        {
          type: SESSION_ERROR_ACTION,
          payload: { sessionError },
        },
        window.location.origin
      )
    }
  }
  const handleLockOrderError = (error: any) => {
    if (typeof window == 'undefined') return
    const parentIFrame: any = window[PARENT_IFRAME]
    if (parentIFrame) {
      dispatch(RESET_ERROR_ACTION())
      parentIFrame.sendMessage(
        {
          type: LOCK_ORDER_ACTION,
          payload: error,
        },
        window.location.origin
      )
    }
  }

  const isOrderLockError = (error: any) => {
    if (typeof window == 'undefined') return
    if (PARENT_IFRAME in window) {
      return LOCK_ORDRE_ERROR.includes(error.errorKey)
    } else {
      return false
    }
  }

  const isAuthenticationError = (error: any) => {
    return AUTHENTICATION_ERROR.includes(error.errorKey)
  }

  const isBadParmsError = (error: any) => {
    return BAD_PARMS_ERROR.includes(error.errorKey)
  }

  const isInvalidParameterError = (error: any) => {
    return INVALID_PARAMETER_ERROR.includes(error.errorKey)
  }

  const isInvalidResetPassword = (error: any) => {
    return INVALID_NEW_PASSWORD.includes(error.errorKey)
  }

  const isAccountLockedOut = (error: any) => {
    return ERR_PERSON_ACCOUNT_LOCKED_OUT.includes(error.errorKey)
  }

  const isLoginNotAllowedNow = (error: any) => {
    return ERR_LOGIN_NOT_ALLOWED_NOW.includes(error.errorKey)
  }

  const isInternalServerError = (error: any) => {
    return ERR_INTERNAL_SERVER_ERROR.includes(error.errorKey) || error.errorKey === null
  }

  const isCookieExpiredError = (error: any) => {
    return ERR_COOKIE_EXPIRED.includes(error.errorKey)
  }

  const isWunderkindLoginSyncError = (error: any) => {
    return ERR_WUNDERKIND_LOGIN_SYNC.includes(error.errorKey)
  }

  const isOutOfStockError = (error: any) => {
    return [ERROR_CART_OUT_OF_STOCK].includes(error.errorKey)
  }

  React.useEffect(() => {
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [])

  return {
    handleForUserSessionError,
    handleLockOrderError,
    isOrderLockError,
    isAuthenticationError,
    isBadParmsError,
    isInvalidParameterError,
    isInvalidResetPassword,
    isAccountLockedOut,
    isLoginNotAllowedNow,
    isInternalServerError,
    isCookieExpiredError,
    isWunderkindLoginSyncError,
    isOutOfStockError,
  }
}

export { useCSRForUser }
