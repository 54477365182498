import React, { useCallback, useEffect, useState } from 'react'
import {
  HealthFundProviderAccordion,
  HealthFundProviderCheckbox,
  HealthFundProviderDetails,
  HealthFundProviderTitle,
} from './HealthFundProvider.style'
import { useTranslation } from 'next-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormControlSelectField } from '../../../../../../components/UI/Form'
import { debounce } from 'lodash-es'
import { useGetFundProvidersQuery, useSetFundProviderMutation } from '@features/healthfund/query'

export interface HealthProviderForm {
  health_provider: string
}

export interface HealthFundProviderProps {
  orderId: string
}

export const HealthFundProvider = (props: HealthFundProviderProps) => {
  const { orderId } = props
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const { data: healthFunds = [] } = useGetFundProvidersQuery()
  const [setFundProvider] = useSetFundProviderMutation()
  const methods = useForm<HealthProviderForm>({
    defaultValues: {
      health_provider: '',
    },
  })

  const { watch } = methods
  const watchProvider = watch('health_provider')
  const debouncedSetProvider = useCallback(
    debounce((value: string) => {
      const body = {
        orderId,
        key: 'ProviderNumber',
        value: value,
      }
      setFundProvider(body)
    }, 1500),
    []
  )

  useEffect(() => {
    debouncedSetProvider(watchProvider)
  }, [watchProvider])

  useEffect(() => {
    if (!expanded) {
      debouncedSetProvider('')
    }
  }, [expanded])

  return (
    <HealthFundProviderAccordion expanded={expanded}>
      <HealthFundProviderTitle
        aria-controls={'content-HealthFundProvider'}
        id={'header-HealthFundProvider'}
        onClick={() => setExpanded(!expanded)}
      >
        <HealthFundProviderCheckbox color="secondary" checked={expanded} />
        {t('HealthFundProvider.Title')}
      </HealthFundProviderTitle>
      <FormProvider {...methods}>
        <HealthFundProviderDetails>
          <FormControlSelectField
            name="health_provider"
            label={t('HealthFundProvider.Provider.Label')}
            rules={{
              required: {
                value: true,
                message: t('HealthFundProvider.Provider.Required'),
              },
            }}
            options={healthFunds}
            showEmptyOption
          />
        </HealthFundProviderDetails>
      </FormProvider>
    </HealthFundProviderAccordion>
  )
}
