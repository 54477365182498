import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash-es'

import { PasswordFormData } from '@typesApp/user'
import { loginStatusSelector } from '@redux/selectors/user'
import { REGISTRATION_PASSWORD_VALID_ACTION } from '@redux/actions/user'
import { NewPassword, TextField } from '@components/UI'
import { PasswordValidationMessages } from '@components/UI/NewPassword'
import { ToggleShowPassword } from '@components/UI/ToggleShowPassword'
import { StyledGridContainer, StyledGridItem } from '@components/AddressForm/AddressForm.style'

export function CreatePassword() {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(loginStatusSelector)
  const { t } = useTranslation()
  const [inputType, setInputType] = React.useState<string>('password')
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const {
    control,
    formState: { errors },
  } = useFormContext<PasswordFormData>()

  const passwordValidationMessages = useMemo<PasswordValidationMessages>(() => {
    return {
      empty: t('RegistrationLayout.Msgs.Empty.newPassword'),
      invalid: t('RegistrationLayout.Msgs.InvalidNewPassword'),
      requirements: t('RegistrationLayout.Msgs.Requirements', { returnObjects: true }),
    }
  }, [t])

  useEffect(() => {
    setInputType(showPassword ? 'text' : 'password')
  }, [showPassword])

  if (isLoggedIn) {
    return null
  }

  return (
    <>
      <h3>{t('RegistrationLayout.CreatePassword')}</h3>
      <div>{t('RegistrationLayout.CreatePasswordComment')}</div>
      <StyledGridContainer sx={theme => ({ marginTop: theme.spacing(8) })}>
        <StyledGridItem xs={12}>
          <Controller
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <NewPassword
                {...rest}
                value={value}
                error={!!error}
                label={t('RegistrationLayout.Password')}
                description={t('RegistrationLayout.Password-description')}
                messages={passwordValidationMessages}
                onValidationChange={(isValid: boolean) => {
                  dispatch(REGISTRATION_PASSWORD_VALID_ACTION({ isValid }))
                }}
              />
            )}
            name="password1"
            control={control}
            defaultValue=""
          />
        </StyledGridItem>
        <StyledGridItem xs={12}>
          <Controller
            render={({ field: { value, ...rest }, fieldState: { error } }) => {
              return (
                <TextField
                  {...rest}
                  value={value}
                  fullWidth
                  label={t('RegistrationLayout.VerifyPassword')}
                  autoComplete="new-password"
                  inputProps={{
                    maxLength: 50,
                  }}
                  type={inputType}
                  customInputProps={{
                    endAdornment: (
                      <ToggleShowPassword showPassword={showPassword} onClick={() => setShowPassword(!showPassword)} />
                    ),
                  }}
                  error={(error && !!value?.length) || (error && isEmpty(value))}
                  helperText={
                    ((error && !!value?.length) || (error && isEmpty(value))) && t(errors.password2?.message ?? '')
                  }
                  showvalidationstatus
                  isvalid={!error && !!value?.length}
                  ispristine={!value?.length}
                />
              )
            }}
            name="password2"
            control={control}
            defaultValue=""
          />
        </StyledGridItem>
      </StyledGridContainer>
    </>
  )
}
