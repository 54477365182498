import React from 'react'
import { SaveCCDialogContainer } from './SaveCreditCardDialog.style'
import { StyledButton } from '@components/UI/Button/Button'
import { useTranslation } from 'next-i18next'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import styles from './styles/SaveCreditCardDialog.module.scss'

interface SaveCCDialogProps {
  handleClose: () => void
}

export function SaveCreditCardDialog(props: SaveCCDialogProps) {
  const { t: translate } = useTranslation('', { keyPrefix: 'Payment.Msgs.Subscription.SaveCCWarning' })
  const { handleClose } = props

  return (
    <SaveCCDialogContainer>
      <div className="body">
        <div className="icon-wrapper">
          <button type="button" aria-label="Close" title="Close" className={styles.closeButton} onClick={handleClose}>
            <SVGIcon library="close" name="close" />
          </button>
        </div>
        <h2 className="title">{translate('Title')}</h2>
        <p>{translate('Details')}</p>
        <StyledButton variant="primary" filltype="fill" onClick={handleClose} className="cta">
          {translate('Confirm')}
        </StyledButton>
      </div>
    </SaveCCDialogContainer>
  )
}
