import styled from '@mui/material/styles/styled'

export const NewPasswordErrorText = styled('div', {
  name: 'NewPassword',
  slot: 'HelperEmptyNewPassword',
})(({ theme }) => ({
  color: theme.palette.color.error,
  fontSize: theme.typography.subtitle1.fontSize,
}))

export const NewPasswordHelperTextWrapper = styled('div', {
  name: 'NewPassword',
  slot: 'HelperTextWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
}))

export const HelperTextWrapper = styled('div', {
  name: 'HelperText',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'description',
})<{ description?: string }>(({ description }) => ({
  marginTop: description ? 0 : '',
  paddingLeft: 4,
  paddingRight: 4,
}))

export const NewPasswordHelperText = styled('div', {
  name: 'NewPassword',
  slot: 'HelperText',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: 1.6,
  color: theme.palette.text.light.secondary,
}))

export const NewPasswordHelperTextRow = styled('div', {
  name: 'NewPassword',
  slot: 'HelperTextRow',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(1),
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: 1.6,
  color: theme.palette.text.dark.primary,
}))
