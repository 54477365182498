import { Button } from '../UI'
import * as ROUTES from '../../constants/routes'
import { LinkAsButton } from '../UI/Button/LinkAsButton'
import React from 'react'
import { StyledTitleWrapper } from './OrderRecap.style'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import {
  OrderRecapEditBagWrapper,
  OrderRecapTitleContent,
  OrderRecapCartContent,
  OrderRecapLinksContent,
} from './OrderRecapEditBag.style'
import { ModalDialog } from '../UI/ModalDialogV2'

const OrderRecapCartEditConfirmDialog: React.FC<{
  route: string
  open: boolean
  handleClose: () => void
}> = ({ route, open, handleClose }) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const generateEditLink = () => {
    return `/${langCode}/${route}`
  }
  const confirmDialogConfig = {
    [ROUTES.CART]: {
      text: 'OrderRecap.Labels.BackConfirm',
      button: 'OrderRecap.Labels.BackYesCTA',
    },
    [ROUTES.CHECKOUT_SHIPPING]: {
      text: 'OrderRecap.Labels.BackConfirmShipping',
      button: 'OrderRecap.Labels.BackYesCTAShipping',
    },
  }

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      hideFooter
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <OrderRecapEditBagWrapper>
        <OrderRecapTitleContent>
          <StyledTitleWrapper>{t(confirmDialogConfig?.[route]?.text)}</StyledTitleWrapper>
        </OrderRecapTitleContent>
        <OrderRecapCartContent>
          <OrderRecapLinksContent>
            <LinkAsButton replace={true} href={generateEditLink()}>
              {t(confirmDialogConfig?.[route]?.button)}
            </LinkAsButton>
            <Button filltype={'outline'} onClick={handleClose}>
              {t('OrderRecap.Labels.BackNoCTA')}
            </Button>
          </OrderRecapLinksContent>
        </OrderRecapCartContent>
      </OrderRecapEditBagWrapper>
    </ModalDialog>
  )
}

export default OrderRecapCartEditConfirmDialog
