import styled from '@mui/material/styles/styled'
import { TextField } from '../UI'

export const StyledInputField = styled(TextField, {
  name: 'ForgotPassword',
  slot: 'StyledInputField',
})(() => ({
  width: '100%',
}))

export const ResetPasswordContent = styled('div', {
  name: 'ForgotPassword',
  slot: 'ResetPasswordContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(8),
  marginTop: theme.spacing(12),
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
}))

export const ResetPasswordMessage = styled('div', {
  name: 'ForgotPassword',
  slot: 'ResetPasswordMessage',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  textAlign: 'center'
}))