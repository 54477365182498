import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { debounce, find } from 'lodash-es'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { useSite } from '../../../../foundation/hooks/useSite'
import { useGetPageStaticLandingQuery } from '../../../../features/cms/query'
import { hamburgerMenuOpenSelector, openDrawerSearchSelector } from '../../../../features/ui/selector'
import useScrollingUp from '../../../../hooks/useScrollingUp/useScrollingUp'
import {
  loginStatusSelector,
  wcTokenSelector,
  userDetailsResponseStatusSelector,
} from '../../../../redux/selectors/user'
import { wishlistEnabledSelector } from '../../../../features/wishList/selector'
import { fetchWishlistAction } from '../../../../features/wishList/action'
import { WrapperHeader } from './DefaultHeader.style'
import { currentContractIdSelector } from '../../../../redux/selectors/contract'
import { useLazyGetCartQuery } from '../../../../features/order/query'
import { seoAlternateHrefsSelector } from '../../../../features/seo/slice'
import {
  CART,
  CHECKOUT_CHILDREN,
  ORDER_CONFIRMATION,
  STORELOCATOR,
  STORELOCATOR_FR,
  ACCOUNT,
} from '../../../../constants/routes'
import { useAbandonedOrder } from '@views/Cart/useAbandonedOrder'
import { usePathname } from 'next/navigation'
import { AppState } from '@redux/store'
import dynamic from 'next/dynamic'
import { APP_MAIN_CONTAINER_ID } from '@constants/ui'
import clsx from 'clsx'
const BenefitBar = dynamic(() => import('../BenefitBar'))
const PromotionBar = dynamic(() => import('../PromotionBar'))
const NavigationBar = dynamic(() => import('../NavigationBar'))
const HamburgerMenu = dynamic(() => import('../HamburgerMenu'), {
  ssr: false,
})
const SearchHeader = dynamic(() => import('../SearchHeader'), {
  ssr: false,
})

const contentsHeader = (headerPlacements: IPlacement[]) => {
  const benefitBar = find(headerPlacements, { name: 'header_benefit_bar' })
  const trendingNow = find(headerPlacements, {
    name: 'header_search_trending_now',
  })
  const searchBanner = find(headerPlacements, { name: 'header_search_banner' })
  const promoBar = find(headerPlacements, { name: 'header_promo_bar' })
  const findStore = find(headerPlacements, { name: 'header_find_a_store' })

  return {
    benefitBar,
    trendingNow,
    searchBanner,
    promoBar,
    findStore,
  }
}

export const DefaultHeader: React.FC = () => {
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const header = useSelector((s: AppState) => s.cms.header)
  const { benefitBar, promoBar } = contentsHeader(header?.headerPlacements!)
  const isHamburgerDrawerOpen = useSelector(hamburgerMenuOpenSelector)
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)
  const hasScrolled = useScrollingUp()
  const pathname = usePathname()
  const router = useRouter()
  const wcToken = useSelector(wcTokenSelector)
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const loggedIn = useSelector(loginStatusSelector)
  const isUserDetailsResponseStatusOk = useSelector(userDetailsResponseStatusSelector)
  const contractId = useSelector(currentContractIdSelector)
  const isCartOrThankYouPage = pathname.endsWith(`/${CART}`) || pathname.endsWith(`/${ORDER_CONFIRMATION}`)
  const [getCart] = useLazyGetCartQuery()
  const timestampRef = React.useRef(Date.now()).current
  const showLanguageSelector = mySite.country === 'ca'
  const alternateHrefs = useSelector(seoAlternateHrefsSelector)
  const [currentScrollY, setCurrentScrollY] = useState<number>(0)
  const STICKY_HEADER_HEIGHT = 175

  const pageInfo = useGetPageStaticLandingQuery(
    { pageId: pathname.split('/c/')?.[1] },
    {
      skip: !pathname?.split('/c/')?.[1] ?? true,
    }
  )

  useEffect(() => {
    const appMainContainerElement = document.getElementById(APP_MAIN_CONTAINER_ID)
    const debouncedScrollListener = debounce(
      () => setCurrentScrollY(appMainContainerElement ? appMainContainerElement?.scrollTop : 0),
      200
    )
    appMainContainerElement?.addEventListener('scroll', debouncedScrollListener, {
      passive: true,
    })
  }, [])

  // TODO: ideally we should refetch only on log in, but currently the cart items are not removed from the slice
  // on log off so this will force a refresh of the cart product count icon
  useEffect(() => {
    // isUserDetailsResponseStatusOk is a flag to execute the getCart after getting the data
    // of the user details response and its response status is ok
    if (!isCartOrThankYouPage && isUserDetailsResponseStatusOk !== false) {
      getCart({
        storeId: mySite.storeID,
        currency: mySite.defaultCurrencyID || '',
        contractId,
        sessionId: timestampRef,
      })
    }
  }, [loggedIn, isUserDetailsResponseStatusOk])

  useAbandonedOrder()

  useEffect(() => {
    const isShippingOrPayment =
      location.pathname.includes(CHECKOUT_CHILDREN.SHIPPING) || location.pathname.includes(CHECKOUT_CHILDREN.PAYMENT)
    // `wcToken` (loggedIn or guest user) is required for wishlist requests
    if (mySite && isWishlistEnabled && !!wcToken && !isShippingOrPayment) {
      dispatch(fetchWishlistAction())
    }
  }, [isWishlistEnabled, mySite, wcToken])

  const changeLanguage = useCallback(
    (language: 'en' | 'fr', country: 'ca' | 'CA') => {
      const targetLocale = `${language}-${country.toLowerCase()}`
      const isCartPage = location.pathname.endsWith(`/${CART}`) /* look for alternate links on SEO endpoint */
      const isStoreLocatorPage = pathname.includes(STORELOCATOR) || pathname.includes(STORELOCATOR_FR)

      let targetUrl =
        alternateHrefs &&
        alternateHrefs.find(x => x.key === targetLocale)?.value /* look for alternate links on CMS static page */

      const { data: staticPageData } = pageInfo
      if (staticPageData?.alternateLinks) {
        targetUrl = staticPageData.alternateLinks.find(x => x.includes(targetLocale))
      }

      if (pathname.includes(ACCOUNT)) {
        targetUrl = pathname
      } else if (isStoreLocatorPage) {
        const storeLocatorPath = pathname.includes(STORELOCATOR_FR) ? STORELOCATOR : STORELOCATOR_FR
        targetUrl = storeLocatorPath
      } /* try basic locale replace if all else fails */

      if (!targetUrl || isCartPage) {
        targetUrl = pathname
      }
      router
        .push(targetUrl, undefined, {
          locale: targetLocale,
        })
        .then(() => {
          if (isCartPage || isStoreLocatorPage) {
            router.reload()
          }
        })
    },
    [alternateHrefs, pageInfo, pathname, router]
  )

  const [isHeaderVisible, setHeaderVisibility] = useState<boolean>(false)

  useEffect(() => {
    if (hasScrolled == null) {
      setHeaderVisibility(false)
    } else {
    }
  }, [hasScrolled, isSearchDrawerOpen])

  return (
    <WrapperHeader
      isSticky={hasScrolled || currentScrollY < STICKY_HEADER_HEIGHT}
      className={clsx({ 'is-scrolled': !isHeaderVisible })}
    >
      <BenefitBar data={benefitBar!} showLanguageSelector={showLanguageSelector} changeLanguage={changeLanguage} />
      <NavigationBar hasScrolled={hasScrolled!} />
      <PromotionBar data={promoBar!} />
      <HamburgerMenu
        open={isHamburgerDrawerOpen}
        showLanguageSelector={showLanguageSelector}
        changeLanguage={changeLanguage}
      />
      <SearchHeader isHeaderVisible={isHeaderVisible} />
    </WrapperHeader>
  )
}
