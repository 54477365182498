import styled from '@mui/material/styles/styled'
import { Button, GridItem } from '../../components/UI'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

export const AddressBookWrapper = styled('div', {
  name: 'AddressBook',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(16)}`,
  },
}))

export const AddressBookTitle = styled(Typography, {
  name: 'AddressBook',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('sm')]: {},
}))

export const NewAddressTitle = styled('div', {
  name: 'NewAddress',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
}))

export const AddressBookContent = styled(GridItem, {
  name: 'AddressBook',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {},
}))

export const AddressBoxWrapper = styled('div', {
  name: 'AddressBox',
  slot: 'ContactWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: theme.spacing(4),
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(4),
  border: `solid 1px ${theme.palette.background.light.tertiary}`,

  [theme.breakpoints.up('sm')]: {},
}))

export const AddressFormWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.light.primary,
  border: `1px solid ${theme.palette.background.light.tertiary}`,
  rowGap: theme.spacing(6),
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(12)} ${theme.spacing(8)}`,
  },
}))

export const AddressFormTitleWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const AddressLineBreak = styled('div', {
  name: 'AddressLine',
  slot: 'Break',
})(({ theme }) => ({
  height: 1,
  alignSelf: 'stretch',
  flexGrow: 0,
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
}))

export const AddressButtonWrapper = styled('div', {
  name: 'AddressButton',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: theme.spacing(40),
}))

export const EditButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.htmlFontSize,
  margin: '0',
  minWidth: '0',
  [theme.breakpoints.down('xl')]: {
    width: theme.spacing(20),
  },
}))

export const RemoveLinkButton = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'bold',
  color: theme.palette.custom.blue,
  lineHeight: theme.spacing(10),
}))

export const AddressCustomerName = styled('span', {
  name: 'CustomerName',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.dark.primary,
  lineHeight: 1.5,
  fontWeight: 600,
}))

export const UserAddressContainer = styled('div', {
  name: 'UserAdress',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}))

export const UserAddressBox = styled('span', {
  name: 'UserAddress',
  slot: 'Wrapper',
})({
  display: 'flex',
  flexDirection: 'column',
})

export const WrapperRow = styled('span', {
  name: 'RowElement',
  slot: 'WrapperRow',
})({
  display: 'flex',
  flexDirection: 'row',
})

export const AddressBookNameWrapper = styled('span', {
  name: 'AddressBook',
  slot: 'NameWrapper',
})(() => ({
  lineHeight: 1,
}))

export const UserAddressWrapper = styled('div', {
  name: 'UserAddress',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

export const RemoveButtonWrapper = styled('div', {
  name: 'NameButton',
  slot: 'Wrapper',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const AddAddressFormButtonContainer = styled('div', {
  name: 'AddAddressForm',
  slot: 'ButtonContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),

  '& > button': {
    flexGrow: 1,
  },

  [theme.breakpoints.up('sm')]: {
    '& > button': {
      flexGrow: 'initial',
    },
  },
}))

export const AddAddressButtonContainer = styled('div', {
  name: 'AddAddress',
  slot: 'ButtonContainer',
})(({ theme }) => ({
  '& > button': {
    width: 197,
    fontSize: 14,
    padding: `${theme.spacing(4)}`,
  },
}))

export const StyledSecondaryText = styled('span', {
  name: 'StyledText',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.8,
  color: theme.palette.text.dark.primary,
}))

export const RemoveAddressModalContainer = styled(Box, {
  name: 'StyledBoxContainer',
  slot: 'Box',
})(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: '40%',
  [theme.breakpoints.up('sm')]: {
    left: '50%',
  },
}))
