import React from 'react'
import { useTranslation } from 'next-i18next'
import * as PaymentMethods from '../../../../constants/paymentMethods'
import PaymentMethodsService from '../../../../services/PaymentMethodsService'
import {
  PaymentMethodContentCheckoutLinks,
  PaymentMethodItemContent,
  PaymentMethodImageWrapper,
  PaymentMethodSpanWrapper,
  PaymentMethodSection,
} from './PaymentMethod.style'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import CheckoutFooterLinks from './components/CheckoutFooterLinks'
import { StyledTypography } from '../../../../components/UI'
import { useSelector } from 'react-redux'
import { forterWebIdSelector } from '../../../../redux/selectors/site'
import { usePageType } from '../../../../foundation/hooks/usePageType'
import { ICMChannel } from '@typesApp/cmsPlacement/CMChannel'
import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'
import { ICMExternalLink } from '@typesApp/cmsPlacement/CMExternalLink'
import { ICMExternalProduct } from '@typesApp/cmsPlacement/CMExternalProduct'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { SVGIconProps } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface PaymentMethodsProps {
  availablePaymentMethods?: PaymentMethods.FooterName[]
  checkoutFooter?: IPlacement
  copyright?: IPlacement
}
export type ICheckoutPlacementItem =
  | ICMChannel
  | ICMExternalPage
  | ICMExternalChannel
  | ICMExternalLink
  | ICMExternalProduct

export const PaymentMethodsIcons: React.FC<PaymentMethodsProps & Partial<SVGIconProps>> = props => {
  const { availablePaymentMethods, ...rest } = props
  return (
    <>
      {availablePaymentMethods?.map(paymentMethodName => {
        const PaymentMethodIcon = PaymentMethodsService.getIconByFooterName(paymentMethodName)
        return PaymentMethodIcon ? <PaymentMethodIcon key={paymentMethodName} {...rest} /> : null
      })}
    </>
  )
}

const PaymentMethod: React.FC<PaymentMethodsProps> = ({ availablePaymentMethods, checkoutFooter, copyright }) => {
  const { t } = useTranslation()
  const { pageType } = usePageType()
  const isCheckoutPage = pageType === 'checkout'
  const isCheckoutFooterEnabled = !!checkoutFooter
  const checkoutFooterItems = (checkoutFooter?.items as ICheckoutPlacementItem[])?.map(item => (
    <CheckoutFooterLinks key={item.formattedUrl} item={item} />
  ))
  const forterWebId = useSelector(forterWebIdSelector) || localStorageUtil.get('FORTER_WEB_ID')
  const copyrightItem = copyright?.items?.find(isLXTeaser)
  const copyrightTitle = copyrightItem?.teaserTitle4 || ''
  const currentYear = new Date().getFullYear()

  return (
    <PaymentMethodSection isCheckoutPage={isCheckoutPage}>
      <PaymentMethodItemContent>
        <PaymentMethodSpanWrapper>{t('FooterPaymentMethod.Labels.SecureTransaction')}</PaymentMethodSpanWrapper>
        {availablePaymentMethods && (
          <PaymentMethodImageWrapper>
            <PaymentMethodsIcons availablePaymentMethods={availablePaymentMethods} />
          </PaymentMethodImageWrapper>
        )}
      </PaymentMethodItemContent>
      {isCheckoutPage && (
        <>
          <PaymentMethodContentCheckoutLinks>
            <StyledTypography fontWeight={'bold'} fontSize={10}>
              {`${t('OtherLinks.Labels.WebId')} ${forterWebId}`}
            </StyledTypography>
            {isCheckoutFooterEnabled && checkoutFooterItems}
          </PaymentMethodContentCheckoutLinks>
          <PaymentMethodContentCheckoutLinks>
            {`\u00A9 ${currentYear} ${copyrightTitle}`}
          </PaymentMethodContentCheckoutLinks>
        </>
      )}
    </PaymentMethodSection>
  )
}

export default PaymentMethod
