import styled from '@mui/material/styles/styled'

export const StyledAlertHeader = styled('div')(() => ({
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
}))

export const StyledAlertMessage = styled('div')(() => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
  maxWidth: '264px'
}))
