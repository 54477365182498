import { Alert } from '@mui/material'
import styled from '@mui/material/styles/styled'

const variantMap = (theme, severity) => {
  const severityMap = {
    success: {
      backgroundColor: theme.palette.custom.light.green,
      color: theme.palette.custom.green,
    },
    error: {
      backgroundColor: theme.palette.custom.light.red,
      color: theme.palette.custom.red,
    },
    warning: {
      backgroundColor: theme.palette.custom.light.yellow,
      color: theme.palette.custom.yellow,
    },
    info: {
      backgroundColor: theme.palette.custom.light.blue,
      color: theme.palette.custom.blue,
    },
  }

  return severityMap[severity]
}

export type AlertType = 'success' | 'error' | 'warning' | 'info' | undefined

export interface IAlert {
  type: AlertType
  title: string
  message: string
}

export const StyledAlert = styled(Alert)(({ theme, severity }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  backgroundColor: variantMap(theme, severity).backgroundColor,
  padding: '8px 16px',
  '.MuiAlert-icon': {
    color: variantMap(theme, severity).color,
    marginRight: '8px',
    paddingTop: '9px',
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 2,
  },
}))
