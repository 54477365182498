import React, { ReactNode } from 'react'
import { useTranslation } from 'next-i18next'
import { Stack } from '@mui/material'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { IOrderDetails } from '../../../types/order'
import DateService from '../../../services/DateService'
import OrderService from '../../../foundation/apis/transaction/order.service'
import {
  OrderDetailsHeaderContainer,
  OrderDetailsHeaderRow,
  OrderDetailsIssuesCTAContainer,
  StyledDetailsIssues,
  StyledReturnButton,
  StyledViewReceiptLink,
} from './OrderDetailsHeader.style'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { ORDER_RETURN, ORDER_RETURN_SELECT } from '../../../constants/routes'
import { Field } from '../../../components/UI/Field'
import { useAppDispatch } from '../../../hooks/redux'
import { DATE_FORMAT_PATTERN } from '../../../constants/date'
import { useSite } from '../../../foundation/hooks/useSite'
import { setReturnOrderId } from '@features/orderReturn/slice'
import ReorderButton from '../../../views/Reorder/ReorderButton'
import { downloadInvoice, isOrderInvoiceAvailable } from '@features/orderhistory/utils'

interface OrderDetailsHeaderProps {
  orderDetails: IOrderDetails
}

export const OrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({ orderDetails }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const { isMobile } = useBreakpoints()
  const isInvoiceAvailable = isOrderInvoiceAvailable(orderDetails)
  const isReturnEligible = OrderService.isReturnEligible(orderDetails, mySite.xStoreCfg.n1SiteGoliveDate)
  const isReorderEligible = OrderService.isReorderEligible(orderDetails)
  const orderDate = DateService.getLocalDate(orderDetails?.placedDate || '', DATE_FORMAT_PATTERN['MMM dd, yyyy'])

  const fields = {
    orderId: <Field label={t('Order.OrderNumber')} value={orderDetails.orderId} />,
    orderDate: <Field label={t('Order.OrderDate')} value={orderDate} />,
    orderStatus: <Field label={t('Order.Status')} value={t(`Order.Status_.${orderDetails.orderStatus}`)} />,
  }

  const actions = {
    viewReceipt: isInvoiceAvailable ? (
      <StyledViewReceiptLink onClick={() => downloadInvoice(mySite.storeId, orderDetails.orderId)}>
        {t('Order.PrintReceipt')}
      </StyledViewReceiptLink>
    ) : null,

    returnCta: isReturnEligible ? (
      <StyledReturnButton
        href={`/${langCode}/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`}
        onClick={() => dispatch(setReturnOrderId(orderDetails.orderId))}
        filltype="outline"
        className="cta"
        fullWidth={false}
      >
        {t('Order.CreateReturn')}
      </StyledReturnButton>
    ) : null,

    reorderCta: isReorderEligible ? (
      <ReorderButton
        label={t('OrderDetails.Actions.OrderAgain')}
        orderId={orderDetails.orderId}
        isRX={OrderService.isRoxable(orderDetails.orderExtendAttribute)}
      />
    ) : null,
  }

  const Layout = isMobile ? MobileLayout : DefaultLayout

  return (
    <OrderDetailsHeaderContainer>
      <Layout fields={fields} actions={actions} />
      <OrderDetailsIssuesCTAContainer>
        <StyledDetailsIssues dangerouslySetInnerHTML={{ __html: t('Order.OrderIssues') }} />
      </OrderDetailsIssuesCTAContainer>
    </OrderDetailsHeaderContainer>
  )
}

interface LayoutProps {
  fields: {
    orderId: ReactNode
    orderDate: ReactNode
    orderStatus: ReactNode
  }
  actions: {
    viewReceipt: ReactNode
    returnCta: ReactNode
    reorderCta: ReactNode
  }
}

const DefaultLayout: React.FC<LayoutProps> = props => {
  const { fields, actions } = props

  return (
    <>
      <OrderDetailsHeaderRow>
        {fields.orderId}
        {actions.viewReceipt}
      </OrderDetailsHeaderRow>

      <OrderDetailsHeaderRow>
        <Stack direction={'column'} rowGap={2}>
          {fields.orderDate}
          {fields.orderStatus}
        </Stack>

        <Stack direction={'row'} columnGap={2}>
          {actions.returnCta}
          {actions.reorderCta}
        </Stack>
      </OrderDetailsHeaderRow>
    </>
  )
}

const MobileLayout: React.FC<LayoutProps> = props => {
  const { fields, actions } = props

  return (
    <>
      <OrderDetailsHeaderRow>{fields.orderId}</OrderDetailsHeaderRow>

      <OrderDetailsHeaderRow>{fields.orderDate}</OrderDetailsHeaderRow>

      <OrderDetailsHeaderRow>{fields.orderStatus}</OrderDetailsHeaderRow>

      <OrderDetailsHeaderRow addTopMargin>{actions.viewReceipt}</OrderDetailsHeaderRow>

      <Stack direction={'column'} rowGap={2}>
        {actions.reorderCta}
        {actions.returnCta}
      </Stack>
    </>
  )
}
