import { createAsyncThunk } from '@reduxjs/toolkit'
import { ORDER_CONFIGS } from '../../../configs/order'
import shippingInfoService from '../../../foundation/apis/transaction/shippingInfo.service'
import fetchCart from './fetchCart'

export interface IUpdateShipModeArgs {
  widget?: string
  shipModeId: string
  storeId?: string
}

const updateShipMode = createAsyncThunk<any, IUpdateShipModeArgs>(
  'order/updateShipMode',
  async (args, { dispatch }) => {
    const { shipModeId, widget, storeId } = args
    const body = {
      body: {
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        x_allocate: ORDER_CONFIGS.allocate,
        x_backorder: ORDER_CONFIGS.backOrder,
        x_remerge: ORDER_CONFIGS.remerge,
        x_check: ORDER_CONFIGS.check,
        orderId: '.',
        shipModeId: shipModeId,
        //addressId: payload.shipAddressId,
        orderItem: [], //bypass defect HC-2784,
        storeId,
      },
      widget,
      storeId,
    }

    const responseData = await shippingInfoService.updateOrderShippingInfo(body)

    await dispatch(fetchCart({})).unwrap()

    return responseData
  }
)

export default updateShipMode
