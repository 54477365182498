import styled from '@mui/material/styles/styled'

export const BOGOContainer = styled('div', {
  name: 'BOGO',
  slot: 'container'
})(({theme}) => ({
  marginBottom: theme.spacing(6),
  padding: theme.spacing(4),
  backgroundColor: theme.palette.custom.light.green,
  color: theme.palette.custom.green,
  backgroundImage: 'url(/images/ui/checkout-buy-one-give-one.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  backgroundSize: 'contain',

  [theme.breakpoints.up('sm')]: {
    backgroundSize: '50%',
    backgroundPosition: 'right 30%'
  },
  [theme.breakpoints.down('sm')]: {
    backgroundImage: 'none'
  }
}))

export const BOGOTitle = styled('div', {
  name: 'BOGO',
  slot: 'title'
})(({}) => ({
  position: 'relative',
  fontWeight: '700',
  width: '95%'
}))

export const BOGOText = styled('p', {
  name: 'BOGO',
  slot: 'text'
})(({theme}) => ({
  margin: 0,
  maxWidth: '60%',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%'
  }
}))

export const BOGOInfoIcon = styled('span', {
  name: 'BOGO',
  slot: 'infoIcon'
})(({theme}) => ({
  position: 'absolute',
  paddingTop: theme.spacing(0.5),
  marginLeft: theme.spacing(1.5),
  cursor: 'pointer'
}))

export const BOGOImage = styled('div', {
  name: 'BOGO',
  slot: 'image'
})(({}) => ({
  backgroundImage: 'url(/images/ui/modal-buy-one-give-one.jpg)',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: 0,
  paddingTop: '66.67%'
}))