import React from 'react'
import styled from '@mui/material/styles/styled'
import useTheme from '@mui/material/styles/useTheme'
import Radio from '@mui/material/Radio'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface CustomRadioProps {
  error?: boolean
  disabled?: boolean
  hidden?: boolean
}

export const StyledInternalRadio = styled(Radio, {
  name: 'Radio',
  slot: 'Radio',
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>(({ hidden }) => ({
  display: hidden ? 'none' : 'inline-flex',

  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

export const StyledRadioIcon = styled('span', {
  name: 'RadioIcon',
  slot: 'RadioIcon',
})(({ theme }) => ({
  borderRadius: 18,
  width: 16,
  height: 16,
  backgroundColor: theme.palette.custom.white,
  borderWidth: 1,
  borderColor: theme.palette.custom.light1.grey,
  borderStyle: 'solid',
  'input:disabled ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.custom.light2.grey,
    background: theme.palette.custom.light.grey,
  },
  'input:hover ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.custom.blue,
    background: theme.palette.custom.light.grey,
  },
  'input:focus ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.custom.blue,
    background: theme.palette.custom.light.grey,
  },
  '.errorIcon': {
    borderRadius: 18,
    width: 16,
    height: 16,
    backgroundColor: theme.palette.custom.white,
    borderWidth: 1,
    borderColor: theme.palette.custom.red,
    borderStyle: 'solid',
  },
}))

export const StyledRadioCheckedIconWrapper = styled('span', {
  name: 'RadioCheckedIconWrapper',
  slot: 'RadioIcon',
  shouldForwardProp: prop => prop !== 'borderColor' && prop !== 'color',
})<{ color?: string; borderColor?: string }>(({ theme, color, borderColor }) => ({
  borderRadius: 18,
  color: color || theme.palette.custom.blue,
  width: 16,
  height: 16,
  display: 'inherit',
  borderWidth: 1,
  borderColor: borderColor || theme.palette.custom.blue,
  borderStyle: 'solid',
  position: 'relative',

  'input:disabled ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.custom.light2.grey,
    background: theme.palette.custom.light.grey,
  },

  '& > svg': {
    position: 'absolute',
    height: 11,
    width: 11,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
}))

export const StyledRadio = React.forwardRef<any, CustomRadioProps>((props: any, _ref: any) => {
  const { error, disabled, ...other } = props
  const theme = useTheme()
  return (
    <StyledInternalRadio
      icon={<StyledRadioIcon className={error ? 'errorIcon' : ''} />}
      disableRipple
      checkedIcon={
        <StyledRadioCheckedIconWrapper theme={theme}>
          <SVGIcon
            library="global"
            name="checked-radio"
            size={10}
            color={disabled ? theme.palette.custom.light2.grey : theme.palette.custom.blue}
          />
        </StyledRadioCheckedIconWrapper>
      }
      disabled={disabled}
      error={error}
      {...other}
    />
  )
})
