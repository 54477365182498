import React, { useEffect } from 'react'
import { useModuleLoad, useConfiguration } from '../hooks'
import { DirectBillingWidgetConfig } from '../types'
import styles from '../styles/index.module.scss'
import { Status } from './Status'

interface DirectBillingCTAProps {
  translations: {
    unavailableTitle: string
    unavailableMessage: string
  }
}

export const DirectBillingCTA: React.FC<DirectBillingCTAProps> = (props) => {
  const { translations } = props
  const { isReady, isBusy, isError } = useModuleLoad()
  const { session, environment, actionModule, cartBundle } = useConfiguration()

  const config = {
    cartBundle,
    session,
    environment,
    actionModule,
    selector: '#insuranceApp'
  } as DirectBillingWidgetConfig

  useEffect(() => {
    return () => {
      document.dispatchEvent(
        new CustomEvent('db:insuranceWidgetEvent', {
          detail: { type: 'close', widgetName: 'dbWidget' },
        })
      )
    }
  }, [])

  useEffect(() => {
    if (!isReady) return

    document.dispatchEvent(
      new CustomEvent('db:insuranceWidgetEvent', {
        detail: { type: 'render', widgetName: 'dbWidget', config },
      })
    )
  }, [isReady, session])

  useEffect(() => {
    if (!cartBundle) {
      return
    }

    document.dispatchEvent(
      new CustomEvent('db:insuranceEvent', {
        detail: { type: 'onCartBundleChange', payload: cartBundle },
      })
    )
  }, [cartBundle])

  return (
    <div id="insuranceAppContainer" className={styles.container}>
      <div id='insuranceApp' />
      <Status
        isLoading={isBusy}
        isError={isError}
        unavailableTitle={translations.unavailableTitle}
        unavailableMessage={translations.unavailableMessage}
      />
    </div>
  )
}
