import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import personService from '../../foundation/apis/transaction/person.service'
import { useSite } from '../../foundation/hooks/useSite'
import { FETCH_USER_DETAILS_SUCCESS_ACTION } from '../../redux/actions/user'
import Log from '../../services/Log'
import { useFavoriteStores } from '../useFavoriteStores'

export const useFavoriteStoresMethods = () => {
  const dispatch = useDispatch()

  const { favoriteStoreIds, defaultFavoriteStoreId } = useFavoriteStores()
  const { mySite } = useSite()
  const { storeID } = mySite

  const [isLoading, setIsLoading] = useState(false)

  const updateFavoriteStores = useCallback(
    async (body: any) => {
      try {
        setIsLoading(true)
        await personService.updatePerson(body)
        const response = await personService.findPersonBySelf({
          langId: mySite?.defaultLanguageID,
          storeId: mySite?.storeID,
        })
        dispatch(FETCH_USER_DETAILS_SUCCESS_ACTION(response.data))
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Log.error('Error while updating favorite stores')
      }
    },
    [dispatch]
  )

  const changeDefaultFavoriteStore = useCallback(
    (id: string) => {
      const parameters: any = {
        responseFormat: 'application/json',
        storeId: storeID,
        body: {
          [`StoreLocator.default_${storeID}_r_0`]: id,
        },
        queryParams: { action: 'updateUserRegistration' },
      }
      updateFavoriteStores(parameters)
    },
    [updateFavoriteStores]
  )

  const addFavoriteStore = useCallback(
    (id: string, wishlistedStoreIds?: string[]) => {
      const wishlist = wishlistedStoreIds || favoriteStoreIds

      const newDefaultStoreId =
        wishlist.length === 0 || !wishlist.includes(defaultFavoriteStoreId) ? wishlist[0] ?? id : defaultFavoriteStoreId

      const parameters: any = {
        responseFormat: 'application/json',
        storeId: storeID,
        body: {
          [`StoreLocator.favorites_${storeID}_r_0`]: [...wishlist, id].join(','),
          [`StoreLocator.default_${storeID}_r_0`]: newDefaultStoreId,
        },
        queryParams: { action: 'updateUserRegistration' },
      }

      return updateFavoriteStores(parameters)
    },
    [favoriteStoreIds, updateFavoriteStores]
  )

  const removeFavoriteStore = useCallback(
    (id: string, wishlistedStoreIds?: string[]) => {
      const wishlist = wishlistedStoreIds || favoriteStoreIds

      const newFavoriteStoreIds = wishlist.filter(storeId => storeId !== id)
      const defaultStoreId: string = newFavoriteStoreIds.includes(defaultFavoriteStoreId)
        ? defaultFavoriteStoreId
        : newFavoriteStoreIds[0]

      const parameters: any = {
        responseFormat: 'application/json',
        storeId: storeID,
        body: {
          [`StoreLocator.favorites_${storeID}_r_0`]: [newFavoriteStoreIds].join(','),
          ...(defaultStoreId && {
            [`StoreLocator.default_${storeID}_r_0`]: defaultStoreId,
          }),
        },
        queryParams: { action: 'updateUserRegistration' },
      }
      return updateFavoriteStores(parameters)
    },
    [updateFavoriteStores, favoriteStoreIds]
  )

  const toggleFavoriteStore = useCallback(
    async (id: string, isWishlisted: boolean, wishlistedStoreIds: string[]) =>
      isWishlisted ? await removeFavoriteStore(id, wishlistedStoreIds) : await addFavoriteStore(id, wishlistedStoreIds),
    [removeFavoriteStore, addFavoriteStore]
  )

  return {
    changeDefaultFavoriteStore,
    removeFavoriteStore,
    toggleFavoriteStore,
    isLoading,
  }
}
