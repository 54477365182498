import { useEffect, useState } from 'react'
import { hasInsuranceModule } from '../utils/insuranceModule'

const MAX_WAIT_TIME = 30000

interface UseModuleLoadResult {
  isReady: boolean
  isBusy: boolean
  isError: boolean
}

/**
 * Hook to wait for the direct billing module to be ready
 */
export const useModuleLoad = (): UseModuleLoadResult => {
  const [isReady, setReady] = useState(false)
  const [isBusy, setBusy] = useState(true)
  const [isError, setError] = useState(false)

  const waitForModule = (startTime: Date) => {
    const elapsedTime = new Date().getTime() - startTime.getTime()

    if (hasInsuranceModule()) {
      setReady(true)
      setError(false)
      setBusy(false)
    } else {
      if (elapsedTime > MAX_WAIT_TIME) {
        setError(true)
        setBusy(false)
        // eslint-disable-next-line no-console
        console.error(`Insurance module did not load after ${elapsedTime}ms`)
      } else {
        setBusy(true)
        setTimeout(() => waitForModule(startTime), 200)
      }
    }
  }

  useEffect(() => {
    if (isReady || isError) {
      return
    }

    waitForModule(new Date())
  }, [])

  return {
    isReady,
    isBusy,
    isError,
  }
}
