import { AddressSuggesterWrapper, StyledTextField, SuggestionsElement, SuggestionsWrapper } from './AddressForm.style'
import React, { useEffect, useRef, useState } from 'react'
import { APP_MAIN_CONTAINER_CLASSNAME } from '../../constants/ui'
import { ClickAwayListener } from '@mui/material'
import Log from '../../services/Log'
import { SuggesterInputProps } from '../../types/inputFields'
import { Suggestion } from 'use-places-autocomplete'
import useAddressSuggestion from './useAddressSuggestion'
import useBreakpoints from '../../hooks/useBreakpoints'

const AddressSuggester: React.FC<SuggesterInputProps> = ({
  cid,
  value,
  fieldName,
  fieldLabel,
  mandatoryField,
  formErrors,
  error,
  onSuggestedFieldValueChanged,
  inputRef,
  country,
  addressFormFields,
  ...props
}) => {
  const addressFormFieldsNames = addressFormFields?.map(field => {
    return field.fieldName
  })

  const [suggestionIndex, setSuggestionIndex] = useState<number>(0)
  const addressSuggestionsRef = useRef<HTMLUListElement>(null)
  const { isMobile, isDesktop } = useBreakpoints()
  const { clearSuggestions, setSuggestionValue, suggestions, getDetails, formatAddressData, init } =
    useAddressSuggestion(country?.toLowerCase(), addressFormFieldsNames)

  const onSelectedSuggestion = async (suggestion: Suggestion) => {
    const parameter = {
      placeId: suggestion.place_id,
      fields: [''],
    }

    getDetails(parameter)
      .then(details => {
        const addressComponents = (details as google.maps.places.PlaceResult).address_components
        if (addressComponents && addressComponents.length > 0) {
          const addressData = formatAddressData(addressComponents)
          addressData?.address1 && onSuggestedFieldValueChanged('address1', addressData.address1)
          addressData?.addressLine1 && onSuggestedFieldValueChanged('addressLine1', addressData.addressLine1)
          addressData?.city
            ? onSuggestedFieldValueChanged('city', addressData.city)
            : onSuggestedFieldValueChanged('city', '')
          addressData?.zipCode
            ? onSuggestedFieldValueChanged('zipCode', addressData.zipCode)
            : onSuggestedFieldValueChanged('zipCode', '')
          addressData?.country && onSuggestedFieldValueChanged('country', addressData.country)
          addressData?.state && onSuggestedFieldValueChanged('state', addressData.state)
        }
        clearSuggestions()
      })
      .catch(error => {
        Log.error('Could not retrieve maps details: ' + error)
      })
  }

  const renderSuggestions = () =>
    suggestions.data.map((suggestion, index) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <SuggestionsElement
          key={`${index}`}
          onTouchStart={() => onSelectedSuggestion(suggestion)}
          onClick={() => onSelectedSuggestion(suggestion)}
          selected={isDesktop && suggestionIndex === index}
        >
          <strong key={`main_${index}`}> {main_text} </strong>
          <small key={`secondary_${index}`}> {secondary_text} </small>
        </SuggestionsElement>
      )
    })

  const onEnterPressed = () => {
    const selectedSuggestion = suggestions.data[suggestionIndex]
    onSelectedSuggestion(selectedSuggestion)
  }

  const downHandler = e => {
    if (isDesktop) {
      let value = suggestionIndex
      switch (e.key) {
        case 'ArrowDown':
          value = value === suggestions?.data.length - 1 ? suggestions?.data.length - 1 : (value += 1)
          break
        case 'ArrowUp':
          e.preventDefault()
          value = value === 0 ? 0 : (value -= 1)
          break
        case 'Enter':
          onEnterPressed()
          break
      }
      setSuggestionIndex(value)
    }
  }

  useEffect(() => {
    init()
  }, [country])

  useEffect(() => {
    if (isMobile && !!suggestions && addressSuggestionsRef.current !== null) {
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight
      let addressSuggestionContainerBoundingBottom = addressSuggestionsRef?.current?.getBoundingClientRect().bottom
      let elementIsInViewport = addressSuggestionContainerBoundingBottom <= viewportHeight
      let elementHeightSurplus = viewportHeight - addressSuggestionContainerBoundingBottom
      if (!elementIsInViewport && elementHeightSurplus <= 0) {
        document.getElementsByClassName(APP_MAIN_CONTAINER_CLASSNAME)[0].scrollBy(0, -elementHeightSurplus)
      }
    }
  }, [suggestions, isMobile])

  useEffect(() => {
    setSuggestionIndex(0)
  }, [suggestions?.data.length])

  return (
    <AddressSuggesterWrapper>
      <StyledTextField
        InputLabelProps={{ required: mandatoryField }}
        {...{ ispristine: props.ispristine }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => downHandler(e)}
        onChange={e => {
          onSuggestedFieldValueChanged(fieldName, e.target.value)
          setSuggestionValue(e.target.value)
        }}
        key={`${cid}-${fieldName}`}
        inputRef={inputRef}
        value={value}
        required={mandatoryField}
        id={`${cid}-${fieldName}`}
        type="text"
        label={fieldLabel}
        error={error}
        helperText={formErrors[fieldName]?.message?.toString()}
        fullWidth
        showvalidationstatus
        autoComplete="off"
      />
      {suggestions.status === 'OK' && (
        <ClickAwayListener onClickAway={clearSuggestions} key={`${cid}`}>
          <SuggestionsWrapper ref={addressSuggestionsRef}>{renderSuggestions()}</SuggestionsWrapper>
        </ClickAwayListener>
      )}
    </AddressSuggesterWrapper>
  )
}

export { AddressSuggester }
