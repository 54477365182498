import { RefObject, useEffect, useState } from 'react'
//SERVICES
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'
import Log from '../../../services/Log'
//CONSTS
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import { BILLING_ADDRESS_ID } from '../../../constants/checkout'
import { PAYMENT_METHODS } from '../../../constants/paymentMethods'
//UTILS
import { executeOnce } from '../../../utils/common'
//TYPES
import { CheckoutPayload, KlarnaAuthResponse, KlarnaSession, KlarnaStatus } from '../../../types/checkout'
//REDUX
import { useSelector } from 'react-redux'
import { cartSelector, paymentInstructionIdSelector } from '../../../features/order/selector'
//HOOKS
import { useSite } from '../../../foundation/hooks/useSite'

import { orderApi } from '../../../features/order/query'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { getCheckoutPaths } from '../../../utils/routeUtils'
import { useRouter } from 'next/router'

export const useKlarna = (klarnaContainer?: RefObject<HTMLDivElement>, selected?: boolean) => {
  const router = useRouter()
  const { langCode } = useStoreIdentity()
  const checkoutPaths = getCheckoutPaths(langCode)
  const cart = useSelector(cartSelector)
  const { mySite } = useSite()
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const piId = useSelector(paymentInstructionIdSelector)

  const [updatePaymentInstruction] = orderApi.endpoints.updatePaymentInstruction.useLazyQuery()
  const [addPaymentInstruction] = orderApi.endpoints.addPaymentInstruction.useLazyQuery()
  const [getCart] = orderApi.endpoints.getCart.useLazyQuery()

  const payloadBase: CheckoutPayload = {
    currency: defaultCurrencyID,
    storeId: mySite.storeID,
    responseFormat: 'application/json',
    widget: 'useKlarna',
  }

  const [klarnaStatus, setKlarnaStatus] = useState<KlarnaStatus>({
    loading: false,
    session: null,
    authorized: false,
    loaded: false,
    piId: '',
  })

  const loadScript = () => {
    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const klarnaTag = document.createElement('script')
      let src = 'https://x.klarnacdn.net/kp/lib/v1/api.js'
      klarnaTag.src = src
      headFragment.appendChild(klarnaTag)
      document.head.appendChild(headFragment)
    }, 'dw-klarna')()
  }

  const fetchKLarnaSession = async (payload: CheckoutPayload) => {
    await addPaymentInstruction({
      ...payload,
    }).catch(e => {
      throw e
    })

    return await paymentInstructionService.createKlarnaSession().catch(e => {
      throw e
    })
  }

  const initKlarnaSession = async () => {
    setKlarnaStatus({
      ...klarnaStatus,
      loading: true,
    })
    try {
      const payload: CheckoutPayload = {
        ...payloadBase,
        body: {
          piAmount: cart?.grandTotal,
          billing_address_id: localStorageUtil.get(BILLING_ADDRESS_ID),
          payMethodId: PAYMENT_METHODS.CHECKOUT_NAMES.KLARNA,
          piId,
        },
      }

      const klarnaSessionData: KlarnaSession = await fetchKLarnaSession(payload)

      setKlarnaStatus({
        ...klarnaStatus,
        loading: false,
      })

      klarnaSessionData.processorToken &&
        window.Klarna.Credit.init({
          client_token: klarnaSessionData.processorToken,
        })

      window.Klarna.Credit.load(
        {
          container: klarnaContainer?.current,
        },
        async () => {
          setKlarnaStatus({
            ...klarnaStatus,
            loaded: true,
          })
        }
      )
    } catch (e: any) {
      Log.error('KLARNA INITIALIZATION ERROR: ' + e.message, window.location.href)
      setKlarnaStatus({
        ...klarnaStatus,
        loading: false,
        loaded: false,
        authorized: false,
      })
    }
  }

  const authorizeKlarna = async () => {
    setKlarnaStatus({
      ...klarnaStatus,
      loading: true,
    })
    try {
      window.Klarna.Payments.authorize({}, {}, async (res: KlarnaAuthResponse) => {
        setKlarnaStatus({
          ...klarnaStatus,
          loading: false,
          authorized: res.approved || false,
        })

        const cartRest = await getCart({
          ...payloadBase,
          storeId: mySite.storeID,
          fetchCatentries: false,
          fetchShippingInfo: false,
          refetch: true,
          sessionId: Date.now(),
        })
        const updatedPiid = cartRest.data?.cart?.paymentInstruction[0].piId
        res.approved &&
          (await updatePaymentInstruction({
            ...payloadBase,
            body: {
              piAmount: cart?.grandTotal,
              billing_address_id: localStorageUtil.get(BILLING_ADDRESS_ID),
              payMethodId: PAYMENT_METHODS.CHECKOUT_NAMES.KLARNA,
              piId: updatedPiid,
              protocolData: [
                {
                  name: 'preapprovalToken',
                  value: res.authorization_token || '',
                },
              ],
            },
          }))

        // ...
      })
    } catch (e: any) {
      Log.error('KLARNA AUTHORIZATION ERROR: ' + e.message, window.location.href)
      setKlarnaStatus({
        ...klarnaStatus,
        loading: false,
        authorized: false,
      })
    }
  }

  const submitOrderWithKlarnaKlarna = async () => {
    setKlarnaStatus({
      ...klarnaStatus,
      loading: true,
    })
    try {
      localStorage.setItem('isKlarnaSuccess', 'true')
      router.push(checkoutPaths['order-confirmation'])
    } catch (e: any) {
      localStorage.setItem('isKlarnaSuccess', 'false')
      Log.error('KLARNA ORDER FINALIZATION ERROR: ' + e.message, window.location.href)
      setKlarnaStatus({
        ...klarnaStatus,
        loaded: false,
        loading: false,
        authorized: false,
      })
    }
  }

  useEffect(() => {
    loadScript()
  }, [])

  useEffect(() => {
    if (!selected) {
      setKlarnaStatus({
        ...klarnaStatus,
        loaded: false,
        authorized: false,
      })
    }
  }, [selected])

  return {
    initKlarnaSession,
    klarnaStatus,
    setKlarnaStatus,
    authorizeKlarna,
    submitOrderWithKlarnaKlarna,
  }
}
