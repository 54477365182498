import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CheckoutPayload, PayPalStatus } from '../../../types/checkout'

import { paypalSecureAcceptanceUrlSelector } from '../../../redux/selectors/site'
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'
import { cartSelector } from '../../../features/order/selector'
import { PAYMENT_METHODS } from '../../../constants/paymentMethods'
import Axios, { Canceler } from 'axios'
import { useSite } from '../../../foundation/hooks/useSite'
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import { fetchPaypalExpress } from '../../../features/order/thunks'
import { getCheckoutPaths } from '../../../utils/routeUtils'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'

export const usePayPal = () => {
  const cart = useSelector(cartSelector)
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const checkoutPaths = getCheckoutPaths(langCode)
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const contractId = useSelector(currentContractIdSelector)
  const paypalSecureAcceptanceUrl = useSelector(
    paypalSecureAcceptanceUrlSelector
  )
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const payloadBase: CheckoutPayload = {
    currency: defaultCurrencyID,
    contractId: contractId,
    storeId: mySite.storeID,
    responseFormat: 'application/json',
    widget: 'usePaypal',
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const dispatch = useDispatch()
  const [paypalFormSubmitting, setPaypalFormSubmitting] =
    useState<boolean>(false)
  const [paypalStatus, setPayPalStatus] = useState<PayPalStatus>({
    loading: false,
    signatureData: {},
  })

  /**
   * PayPal can be used from Cart page as well as from Payments page.
   * PayPal used from Cart page is considered 'Express'
   * as users don't need to enter billing and shipping address.
   *
   * @param isExpress true if called from Cart, false otherwise.
   */
  const continueWithPayPal = async (isExpress: 'true' | 'false') => {
    await paymentInstructionService.deleteAllPaymentInstructions()

    const body: CheckoutPayload['body'] = {
      piAmount: cart?.grandTotal,
      payMethodId: PAYMENT_METHODS.CHECKOUT_NAMES.PAYPAL,
      isPayPalExpress: isExpress,
    }

    const addPaymentInstructionPayload: CheckoutPayload = {
      ...payloadBase,
      body,
    }

    await paymentInstructionService.addPaymentInstruction(
      addPaymentInstructionPayload
    )

    dispatch(
      fetchPaypalExpress({
        successUrl: `${window.location.origin}${checkoutPaths['order-confirmation']}`,
        cancelUrl: `${window.location.origin}${checkoutPaths.cart}`,
      })
    )
  }

  const submitWithPaypal = (
    cyberSourceRef: React.RefObject<HTMLFormElement>
  ) => {
    setPaypalFormSubmitting(true)
    cyberSourceRef.current?.submit()
  }

  const createPaypalCybersourceForm = (fields, formRef): JSX.Element => {
    return (
      <form ref={formRef} method="POST" action={paypalSecureAcceptanceUrl}>
        {fields &&
          Object.keys(fields)
            .filter((field) => field !== 'hiddens')
            .map((val, key) => {
              return (
                <input
                  type="hidden"
                  key={key}
                  name={val}
                  id={val}
                  value={fields[val]}
                />
              )
            })}
      </form>
    )
  }

  return {
    setPayPalStatus,
    continueWithPayPal,
    submitWithPaypal,
    createPaypalCybersourceForm,
    paypalFormSubmitting,
    paypalStatus,
  }
}
