import styled from '@mui/material/styles/styled'

const PriceContainer = styled('div', {
  name: 'OrderRecap',
  slot: 'PriceContainer',
})(({ theme }) => ({
  textAlign: 'right',
  color: theme.palette.text.dark.primary,
}))

const OriginalPrice = styled('span', {
  name: 'OrderRecap',
  slot: 'OriginalPrice',
})(() => ({
  color: 'inherit',
}))

const StrokeOriginalPrice = styled('span', {
  name: 'OrderRecap',
  slot: 'StrokeOriginalPrice',
})(({ theme }) => ({
  textDecoration: 'line-through',
  fontSize: theme.typography.subtitle1.fontSize,
}))

const DiscountedPrice = styled('span', {
  name: 'OrderRecap',
  slot: 'DiscountedPrice',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontWeight: 'bold',
  marginLeft: theme.spacing(2),
}))

export { OriginalPrice, StrokeOriginalPrice, DiscountedPrice, PriceContainer }
