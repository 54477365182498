import React from 'react'
import { StyledSubmitSuccessMessageWrapper, StyledSubmitSuccessMessage } from './SubmitSuccessMessage.style'
import { useTranslation } from 'next-i18next'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface SubmitSuccessMessageProps {
  message?: string
}

export const SubmitSuccessMessage: React.FC<SubmitSuccessMessageProps> = ({ message }) => {
  const { t } = useTranslation()

  return (
    <StyledSubmitSuccessMessageWrapper>
      <SVGIcon library="validation" name="checkmark-rounded" />
      <StyledSubmitSuccessMessage>{message || t('SubmitSuccessMessage.SuccessMessage')}</StyledSubmitSuccessMessage>
    </StyledSubmitSuccessMessageWrapper>
  )
}
