import styled from '@mui/material/styles/styled'

export const StyledPaypalIcon = styled('img', {
  name: 'PaypalPayment',
  slot: 'Icon',
})(() => ({
  minWidth: 56,
  height: 56,
  minHeight: 56,
}))


