import styled from '@mui/material/styles/styled'
import { CustomInputFieldProps } from '../../../types/inputFields'
import { TextField as MuiTextField } from '@mui/material'

export const StyledTextFieldWrapper = styled('div', {
  name: 'CustomThemeComponent',
  slot: 'Root',
  shouldForwardProp: prop => prop !== 'showvalidationstatus' && prop !== 'ispristine' && prop !== 'isvalid',
})<CustomInputFieldProps>(({ theme }) => ({
  position: 'relative',
  minHeight: theme.spacing(12),
  width: '100%',
  '& > div': {
    width: '100%',
  },
  '& svg': {
    top: '25%',
  },
}))

export const BootstrapInput = styled(MuiTextField, {
  name: 'TextField',
  slot: 'BootstrapInput',
  shouldForwardProp: prop => prop !== 'ispristine',
})(({ theme }) => ({
  '.MuiInputLabel-root': {
    lineHeight: 1,
    fontWeight: 'normal',
    '&.MuiInputLabel-shrink': {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.text.dark.primary,
    },
    '&.Mui-error': {
      color: theme.palette.text.light.secondary,
    },
    '&[data-shrink="true"]': {
      transform: 'translate(12px, -14px) scale(1)',
      backgroundColor: 'transparent',
      left: 6,
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.light.primary,
    letterSpacing: 'normal',
    height: '100%',
    padding: `0 ${theme.spacing(4)} 0 ${theme.spacing(4)}`,

    '.is-select &': {
      padding: 0,
      '.MuiSelect-select': {
        height: '100%',
        padding: `0 0 0 ${theme.spacing(4)}`,
        display: 'flex',
        alignItems: 'center',
      },
      '.MuiList-root': {
        border: `2px solid ${theme.palette.custom.blue}`,
      },
      '.MuiMenuItem-root': {
        color: theme.palette.custom.black,
        fontFamily: 'inherit',
        padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(4)}`,
        '&:hover': {
          backgroundColor: `${theme.palette.custom?.light.blue}}`,
        },
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: 4,
      border: `1px solid ${theme.palette.text.light.tertiary}`,
      top: 0,
      legend: {
        display: 'none',
      },
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.text.light.tertiary}`,
    },
    '&.Mui-error > fieldset': {
      borderColor: `${theme.palette.color.error}`,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.color.error}`,
    },
    '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.color.error}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.custom?.blue}`,
    },
    '.is-valid & .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.color.success}`,
    },
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.text.light.primary,
    color: theme.palette.text.dark.primary,
    fontSize: theme.typography.body1.fontSize,
    width: '100%',
    lineHeight: `${theme.spacing(6)}`,
    padding: `0 ${theme.spacing(4)} 0 0`,
    height: `${theme.spacing(12)}`,
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.text.light.tertiary,
    },
    '&::-webkit-search-decoration': { display: 'none' },
    '&::-webkit-search-cancel-button': { display: 'none' },
    '&::-webkit-search-results-button': { display: 'none' },
    '&::-webkit-search-results-decoration': { display: 'none' },
    '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active': {
      backgroundClip: 'content-box !important',
      webkitBoxShadow: `0 0 0 30px ${theme.palette.text.light.primary} inset`,
      boxShadow: `0 0 0 30px ${theme.palette.text.light.primary} inset`,
      WebkitTextFillColor: `${theme.palette.text.dark.primary}`,
    },
  },
  '.MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    display: 'block !important',
    left: 0,
    bottom: 'calc(-4px - 16px)', // indent from input: 4px, line-height: 16px
    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: '16px',
    color: `${theme.palette.color.error}`,
  },
  '.MuiInputAdornment-root': {
    margin: 0,
    '&.MuiInputAdornment-positionStart': {
      width: `${theme.spacing(15)}`,
      marginRight: `${theme.spacing()}`,
    },
    '&.MuiInputAdornment-positionEnd': {
      gap: `${theme.spacing(4)}`,
    },
    '.is-select &': {
      position: 'absolute',
      '&.MuiInputAdornment-positionEnd': {
        right: `${theme.spacing(12)}`,
      },
    },
  },
  '.MuiSelect-icon': {
    right: `${theme.spacing(3)}`,
    top: `${theme.spacing(2)}`,
    width: '2rem',
    height: '2rem',
  },
  '.MuiAutocomplete-inputRoot': {
    padding: `0 ${theme.spacing(4)} 0 ${theme.spacing(4)} !important`,
    '.MuiAutocomplete-endAdornment': {
      position: 'static',
      display: 'flex',
      gap: `${theme.spacing(4)}`,
      right: `${theme.spacing(4)}`,
    },
    '.MuiAutocomplete-input': {
      padding: '0 !important',
    },
  },
}))
