import React from 'react'
import { ModalDialog, ModalDialogTitle } from '../UI'
import { useDispatch, useSelector } from 'react-redux'
import { openModalRegistrationSelector } from '../../features/ui/selector'
import { setOpenModalRegistration } from '../../features/ui/action'
import RegistrationFormDialog from './components/RegistrationFormDialog'
import { useTranslation } from 'next-i18next'

const RegistrationDialog: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const open = useSelector(openModalRegistrationSelector)

  const handleClose = () => dispatch(setOpenModalRegistration(false))
  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      onCloseButton={handleClose}
      widthSize={'sm'}
    >
      <>
        <ModalDialogTitle>{t('RegistrationLayout.Title')}</ModalDialogTitle>
        <RegistrationFormDialog />
      </>
    </ModalDialog>
  )
}

export default RegistrationDialog
