import styled from '@mui/material/styles/styled'

export const StyledAlertHeader = styled('div', {
  name: 'Alert',
  slot: 'Header'
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: '700',
  lineHeight: '24px',
}))

export const StyledAlertMessage = styled('div', {
  name: 'Alert',
  slot: 'Message',
  shouldForwardProp: prop => prop !== 'fullWidth'
})<{fullWidth?:boolean}>(({ theme, fullWidth }) =>  ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: '400',
  lineHeight: '16px',
  maxWidth: fullWidth ? '100%' : '264px'
}))
