import styled from '@mui/material/styles/styled'
import { StyledAlert } from '../../UI'

export const ImageContainer = styled('div', {
  name: 'RXInfoModal',
  slot: 'ImageContainer'
})(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('lg')]: {
    marginBottom: theme.spacing(3)
  }
}))

export const MainContainer = styled('div', {
  name: 'RXInfoModal',
  slot: 'MainContainer'
})(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    height: '100%'
  }
}))

export const ColumnContainer = styled('div', {
  name: 'RXInfoModal',
  slot: 'ColumnContainer'
})<{ width?: string }>(({ width, theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(6),
  '.title': {
    display: 'block',
    fontWeight: 700,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2)
  },
  [theme.breakpoints.up('md')]: {
    width: width || 'calc(50% - 20px)',
    marginBottom: 0
  }
}))

export const GapColumnContainer = styled('div', {
  name: 'RXInfoModal',
  slot: 'ColumnGap'
})<{ width?: string }>(({ theme, width }) => ({
  width: width || '20px',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export const FeatureItemSection = styled('div', {
  name: 'RXInfoModal',
  slot: 'FeatureItemSection'
})(({ theme }) => ({
  display: 'flex',
  fontSize:theme.typography.body1.fontSize,
  width: '100%',

  '.icon': {
    width: theme.spacing(9)
  },
  '.body': {
    margin: `0 0 0 ${theme.spacing(2)}`
  }
}))

export const FeatureItemTitle = styled('p', {
  name: 'RXInfoModal',
  slot: 'FeatureItemTitle'
})<{ color?: string, fontSize?: string }>(({ theme, color, fontSize }) => ({
  fontSize: fontSize || theme.typography.body1.fontSize,
  fontWeight: 700,
  margin: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
  color: color || theme.palette.text.primary
}))

export const FeatureItemDescription = styled(FeatureItemTitle, {
  name: 'RXInfoModal',
  slot: 'FeatureItemDescription'
})<{ color?: string, fontSize?: string }>(({ theme, color, fontSize }) => ({
  fontSize: fontSize || theme.typography.body1.fontSize,
  lineHeight: theme.typography.h4.fontSize,
  color: color || theme.palette.text.primary,
  fontWeight: 400,
  margin: `${theme.spacing(-4)} 0 0 ${theme.spacing(2)}`
}))

export const ItemTitle = styled('div', {
  name: 'RXInfoModal',
  slot: 'ItemTitle'
})<{ color?: string, fontSize?: string }>(({ theme, color, fontSize }) => ({
  fontSize: fontSize || theme.typography.body1.fontSize,
  fontWeight: 700,
  color: color || theme.palette.text.primary,
  marginTop: theme.spacing(3)
}))

export const ItemDescription = styled(ItemTitle)(({ }) => ({
  fontWeight: 400
}))

export const HighLightSection = styled(StyledAlert, {
  name: 'RXInfoModal',
  slot: 'HighLightSection'
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  '> .MuiAlert-icon' : {
    paddingTop: '5px',
    marginTop: 0
  }
}))

export const CarouselContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))

export const CarouselItem = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.custom.light.grey
}))
