import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TextField } from '../TextField'
import { ToggleShowPassword } from '../ToggleShowPassword'
import { FormControlHelperText } from './FormControlHelperText'
import { FormControlProps } from './types'

export const FormControlPasswordField: React.FC<FormControlProps> = ({
  id,
  name,
  rules,
  label,
  autoComplete,
  placeholder,
  description,
  inputProps,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const { control } = useFormContext()
  const required = rules ? rules['required']?.value === true || rules['required'] === true : undefined

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
        <>
          <TextField
            {...rest}
            id={id}
            inputRef={ref}
            value={value}
            fullWidth
            label={`${label}${required ? ' *' : ''}`}
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            error={!!error}
            showvalidationstatus={true}
            isvalid={!error && !!value?.length}
            ispristine={!value?.length}
            inputProps={inputProps}
            customInputProps={{
              endAdornment: (
                <ToggleShowPassword showPassword={showPassword} onClick={() => setShowPassword(!showPassword)} />
              ),
            }}
            autoComplete={autoComplete}
          />
          <FormControlHelperText description={description} error={error} />
        </>
      )}
    />
  )
}
