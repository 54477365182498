import Axios, { Canceler } from 'axios'
import { pick } from 'lodash-es'
import React, { FC, useState } from 'react'
import getDisplayName from 'react-display-name'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CommerceEnvironment, EMPTY_STRING } from '../../../../constants/common'
import { ISO_DATE_FORMAT } from '../../../../constants/date'
import personService from '../../../../foundation/apis/transaction/person.service'
import { useSite } from '../../../../foundation/hooks/useSite'
import * as userActions from '../../../../redux/actions/user'
import { userDetailsSelector } from '../../../../redux/selectors/user'
import DateService from '../../../../services/DateService'
import Log from '../../../../services/Log'
import { PersonalInfoFormData, UserDetails } from '@typesApp/user'
import PersonalInformationForm from './PersonalInformationForm'
import { SubmitAction } from '../SubmitAction/SubmitAction'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

type PatchedFormData = Optional<PersonalInfoFormData, 'preferredCurrency' | 'preferredLanguage'>

const infoFormDataInit: PersonalInfoFormData = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  phone1: EMPTY_STRING,
  preferredCurrency: EMPTY_STRING,
  preferredLanguage: EMPTY_STRING,
  gender: EMPTY_STRING,
  dateOfBirth: EMPTY_STRING,
  email1: EMPTY_STRING,
  logonId: EMPTY_STRING,
}

const pickPersonalInfoDataFromUserDetails = (userDetails: UserDetails): PersonalInfoFormData => {
  return pick<UserDetails, keyof PersonalInfoFormData>(
    userDetails,
    'dateOfBirth',
    'firstName',
    'gender',
    'lastName',
    'phone1',
    'email1',
    'preferredCurrency',
    'preferredLanguage'
  )
}

const CancelToken = Axios.CancelToken
let cancels: Canceler[] = []

export const PersonalInformationSection: FC = () => {
  const { t } = useTranslation()
  const widgetName = getDisplayName(PersonalInformationSection)
  const userDetails = useSelector(userDetailsSelector)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSuccessSubmit, setIsSubmitSuccess] = useState(false)
  const { mySite } = useSite()
  const DATE_FORMAT = CommerceEnvironment.dateFormat[mySite.locale]

  const dispatch = useDispatch()

  const payloadBase = {
    widget: widgetName,
    storeId: mySite.storeID,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const initialValues = {
    ...infoFormDataInit,
    ...(userDetails ? pickPersonalInfoDataFromUserDetails(userDetails) : null),
    dateOfBirth: DateService.convertISODate(userDetails?.dateOfBirth || '', DATE_FORMAT.format),
  }

  const methods = useForm<PersonalInfoFormData>({
    defaultValues: initialValues,
  })

  const patchData = (formData: PersonalInfoFormData): PatchedFormData => {
    const data = {
      ...formData,
      logonId: formData.email1,
      dateOfBirth: formData.dateOfBirth ? DateService.format(formData.dateOfBirth, ISO_DATE_FORMAT) : '',
    } as PatchedFormData

    delete data.preferredCurrency
    delete data.preferredLanguage

    for (const key in data) {
      data[key] = data[key].trim()
    }

    return data
  }

  const updateProfile = (data: PatchedFormData) => {
    setSubmitting(true)
    setIsSubmitSuccess(false)
    personService
      .updatePerson({
        body: data,
        extraParams: payloadBase,
      })
      .then(addressData => {
        if (addressData.addressId) {
          dispatch(
            userActions.FETCH_USER_DETAILS_SUCCESS_ACTION({
              ...userDetails!,
              ...data,
            })
          )
          setIsSubmitSuccess(true)
          setSubmitting(false)
        }
      })
      .catch(e => {
        setSubmitting(false)
        Log.error(`Could not create new address: ${e}`)
      })
  }

  const onSubmit = (formData: PersonalInfoFormData) => {
    const data = patchData(formData)
    updateProfile(data)
  }

  return (
    <>
      <FormProvider {...methods}>
        <form id="personal-details-form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <PersonalInformationForm />
        </form>
      </FormProvider>
      <SubmitAction
        form="personal-details-form"
        isBusy={isSubmitting}
        isSuccess={isSuccessSubmit}
        label={t('PersonalInformationSection.SaveChanges')}
        successMessage={t('PersonalInformationSection.Msgs.Success') || ''}
      />
    </>
  )
}
