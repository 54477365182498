import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'next-i18next'
import { Field, GridContainer, GridItem } from '../../../../../../components/UI'
import { FormControlTextField } from '../../../../../../components/UI/Form'
import { isValidCardExpirationDate } from '../../../../../../constants/paymentMethods'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import { useAppSelector } from '../../../../../../hooks/redux'
import { storeCfgServiceLinksSelector } from '../../../../../../redux/selectors/site'
import DateService from '../../../../../../services/DateService'
import { Wallet } from '../../../../../../types/user'
import {
  PaymentMethodSelectionCVVHint,
  PaymentMethodSelectionCVVImage,
  PaymentMethodSelectionCVVText,
} from '../../../../payment/Payment.style'
import {
  StoredCCardConditions,
  StoredCCardConditionsLink,
  StoredCCardButton,
  StoredCCardField,
  StoredCCardForm,
  StoredCCardSecurityLink,
} from './StoredCCard.style'
import config from '@configs/index'

export interface StoredCreditCardProps {
  cardDetails: Wallet
  onSubmit: (wallet: Wallet) => void
}

export interface FormData {
  expirationDate: string
  cvv: string
}

export const StoredCreditCard: React.FC<StoredCreditCardProps> = props => {
  const { cardDetails } = props
  const { t } = useTranslation()
  const [isCVVHintOpen, setIsCVVHintOpen] = useState(false)
  const serviceLinksString = useAppSelector(storeCfgServiceLinksSelector)
  const { langCode } = useStoreIdentity()
  const methods = useForm<FormData>({})
  const serviceLinks = serviceLinksString ?? null
  const termsLink = `/${langCode}${serviceLinks?.terms}`
  const cvvImgPath = `${config.publicUrl}images/common/cvv-code.png`

  const cardDate = DateService.getValidDate(cardDetails.expireDate)
  const cardYear = cardDate?.getFullYear()
  const cardMonth = cardDate?.getMonth()

  let isCardValid = false
  if (cardMonth && cardYear) {
    isCardValid = isValidCardExpirationDate([cardMonth + 1, cardYear])
  }

  const CC_EXPIRATION_VALIDATION_PATTERN = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/
  const CC_CVV_VALIDATION_PATTERN = /^([0-9]{3}|[0-9]{4})$/

  // TODO: add form submission handling
  const onSubmit = (formData: FormData) => {
    return formData
  }

  // TODO: add form submission error handling
  const onError = error => {
    return error
  }

  return (
    <>
      <StoredCCardField>
        <Field label={t('StoredCCard.Labels.Cardholder')} value={cardDetails.owner} />
      </StoredCCardField>
      {!isCardValid && (
        <GridContainer>
          <GridItem xs={12}>
            <FormProvider {...methods}>
              <StoredCCardForm
                id={`update-creditcard-expiration-date-${cardDetails?.identifier}`}
                onSubmit={methods.handleSubmit(onSubmit, onError)}
              >
                <GridContainer>
                  <GridItem xs={12} sm={5} lg={4} xl={3}>
                    <FormControlTextField
                      name="expirationDate"
                      rules={{
                        required: {
                          value: true,
                          message: t('StoredCCard.Labels.Required'),
                        },
                        pattern: {
                          value: CC_EXPIRATION_VALIDATION_PATTERN,
                          message: t('StoredCCard.Labels.InvalidDate'),
                        },
                      }}
                      label={t('StoredCCard.Labels.MMYY')}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={7} lg={6}>
                    <FormControlTextField
                      name="cvv"
                      rules={{
                        required: {
                          value: true,
                          message: t('StoredCCard.Labels.Required'),
                        },
                        pattern: {
                          value: CC_CVV_VALIDATION_PATTERN,
                          message: t('StoredCCard.Labels.InvalidCode'),
                        },
                      }}
                      label={t('StoredCCard.Labels.Security')}
                      description={
                        <>
                          <StoredCCardSecurityLink
                            onClick={() => {
                              setIsCVVHintOpen(!isCVVHintOpen)
                            }}
                          >
                            {t('StoredCCard.Labels.CVVSecurityCode')}
                          </StoredCCardSecurityLink>
                        </>
                      }
                    />
                    {isCVVHintOpen && (
                      <PaymentMethodSelectionCVVHint xs={12} sm={7} lg={8} xl={9}>
                        <PaymentMethodSelectionCVVImage src={cvvImgPath} alt={'CVV Hint'} />
                        <PaymentMethodSelectionCVVText>{t('StoredCCard.Labels.CVVHint')}</PaymentMethodSelectionCVVText>
                      </PaymentMethodSelectionCVVHint>
                    )}
                  </GridItem>
                </GridContainer>
              </StoredCCardForm>
            </FormProvider>
          </GridItem>
        </GridContainer>
      )}

      <StoredCCardConditions>
        <Trans
          i18nKey="StoredCCard.Conditions"
          components={{ termsLink: <StoredCCardConditionsLink href={termsLink} title={t('StoredCCard.TermsTitle')} /> }}
        />
      </StoredCCardConditions>
      <StoredCCardButton
        sx={{ marginLeft: 0 }}
        type="submit"
        onClick={() => props.onSubmit(cardDetails)}
        form={`update-creditcard-expiration-date-${cardDetails?.identifier}`}
      >
        {t('StoredCCard.CTA')}
      </StoredCCardButton>
    </>
  )
}
