//Standard libraries
import React from 'react'
//Custom libraries
import { RemoveCreditCardDialogLayout } from './RemoveCreditCardDialogLayout'
import { StandardPageHero2BlocksLayout } from '../../layouts/standard-page-hero-2-blocks'
import { SectionContent } from '../../layouts/sectionContentType'

const RemoveCreditCardDialog: React.FC<{
  handleClose: () => void
  userCreditCard
}> = ({ handleClose, userCreditCard }) => {
  const removeCreditCard: SectionContent[] = [
    {
      key: 'creditCard-page-removecreditCard',
      CurrentComponent: () => {
        return <RemoveCreditCardDialogLayout handleClose={handleClose} userCreditCard={userCreditCard} />
      },
    },
  ]

  return <StandardPageHero2BlocksLayout cid="remove-creditCard-page" sectionOne={removeCreditCard} />
}

export { RemoveCreditCardDialog }
