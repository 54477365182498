import { useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { ORDER_RETURN, ORDER_RETURN_CONFIRMATION, ORDER_RETURN_PRINT, ORDER_RETURN_SELECT } from '@constants/routes'
import { useRouter } from 'next/router'

export interface Step {
  stepId: string
  stepNumber: number
  stepTitle: string
  url: string
}

export const useReturnOrderSteps = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const basePath = router.asPath.split('?')[0]

  const allSteps: Step[] = [
    {
      stepId: 'return',
      stepNumber: 0,
      stepTitle: t('OrderReturn.ProgressBar.Select'),
      url: `/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`,
    },
    {
      stepId: 'confirmation',
      stepNumber: 1,
      stepTitle: t('OrderReturn.ProgressBar.Confirmation'),
      url: `/${ORDER_RETURN}/${ORDER_RETURN_CONFIRMATION}`,
    },
    {
      stepId: 'print',
      stepNumber: 2,
      stepTitle: t('OrderReturn.ProgressBar.ReturnCreated'),
      url: `/${ORDER_RETURN}/${ORDER_RETURN_PRINT}`,
    },
  ]

  const activeStep = useMemo(() => {
    switch (basePath) {
      case `/${ORDER_RETURN}/${ORDER_RETURN_PRINT}`:
        return 'print'
      case `/${ORDER_RETURN}/${ORDER_RETURN_CONFIRMATION}`:
        return 'confirmation'
      default:
        return 'return'
    }
  }, [basePath])

  const previousSteps = useMemo(() => {
    return allSteps.filter((step, i) => {
      return (
        step.stepId !== activeStep &&
        i <
          allSteps.findIndex(step => {
            return step.stepId === activeStep
          })
      )
    })
  }, [allSteps, activeStep])

  const nextSteps = useMemo(() => {
    return allSteps.filter((step, i) => {
      return (
        step.stepId !== activeStep &&
        i >
          allSteps.findIndex(step => {
            return step.stepId === activeStep
          })
      )
    })
  }, [allSteps, activeStep])

  return {
    activeStep,
    previousSteps,
    nextSteps,
    allSteps,
  }
}
