import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Axios, { Canceler } from 'axios'
import getDisplayName from 'react-display-name'
import { FormProvider, useForm } from 'react-hook-form'
import personService from '../../../../foundation/apis/transaction/person.service'
import { useSite } from '../../../../foundation/hooks/useSite'
import { SettingsFormData } from '@typesApp/user'
import Log from '../../../../services/Log'
import { ChangePasswordForm } from './ChangePasswordForm'
import { SubmitAction } from '../SubmitAction/SubmitAction'

export const ChangePasswordSection: React.FC = () => {
  const { t } = useTranslation()
  const { mySite: site } = useSite()
  const widgetName = getDisplayName(ChangePasswordSection)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSuccessSubmit, setSubmitSuccess] = useState(false)
  const [wrongPassword, setWrongPassword] = useState(false)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const patchData = (data: SettingsFormData): any => {
    const { currentPassword, newPassword, verifyPassword } = data
    const storeId = site.storeID
    const parameters: any = {
      responseFormat: 'application/json',
      storeId,
      body: {
        resetPassword: 'true',
        xcred_logonPasswordOld: currentPassword,
        logonPassword: newPassword,
        xcred_logonPasswordVerify: verifyPassword,
      },
      ...payloadBase,
    }
    return parameters
  }

  const onSubmit = async (data: SettingsFormData) => {
    const parameters = patchData(data)
    try {
      setSubmitting(true)

      const submitResult = await personService.updatePerson(parameters)
      setSubmitSuccess(submitResult.resourceName === 'person')
      setWrongPassword(false)
      setSubmitting(false)
    } catch (e) {
      setSubmitting(false)
      setSubmitSuccess(false)
      setWrongPassword(true)
      Log.error('Error while updating password settings')
    }
  }

  const initialValues: SettingsFormData = {
    currentPassword: '',
    newPassword: '',
    verifyPassword: '',
  }

  const methods = useForm<SettingsFormData>({
    defaultValues: initialValues,
  })

  return (
    <>
      <FormProvider {...methods}>
        <form id="change-password-form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <ChangePasswordForm wrongPassword={wrongPassword} />
        </form>
      </FormProvider>
      <SubmitAction
        form="change-password-form"
        isBusy={isSubmitting}
        isSuccess={isSuccessSubmit}
        label={t('ChangePasswordSection.Labels.SaveChanges')}
        successMessage={t('ChangePasswordSection.Msgs.Success') || ''}
      />
    </>
  )
}
