/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise, AxiosRequestConfig, Method } from 'axios'
import { PRODUCTION, SHOW_API_FLOW } from '../../constants/common'

//Redux
import { API_CALL_ACTION } from '../../../redux/actions/api'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { getSite } from '../../hooks/useSite'
import { localStorageUtil } from '../../utils/storageUtil'
import { site as siteConstant } from '@foundation/constants/site'

const inventoryavailabilityService = {
  /**
   * Gets inventory details for the specified product by it's identifier (Catalog Entry Id). Multiple product IDs can be passed to the URI separated by a comma (,).
   * `@method`
   * `@name Inventoryavailability#getInventoryAvailabilityByProductId`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} productIds (required)` The child property of `Parameters`.The product identifiers. Multiple values are separated by commas. Example: /inventoryavailability/10001,10002
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {string} onlineStoreId ` The online store identifier.
   ** `@property {string} physicalStoreId ` The physical store identifiers. Multiple values are separated by commas. Example: physicalStoreId=10001,10002
   */
  getInventoryAvailabilityByProductId(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstant.transactionContextUrl
    const domain = url || siteContext
    const path = '/store/{storeId}/inventoryavailability/byPartNumber/{productIds}'
    let requestUrl = domain + path
    const method: Method = 'GET'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request "/store/{storeId}/inventoryavailability/{productIds}" missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['productIds'] === undefined) {
      throw new Error('Request "/store/{storeId}/inventoryavailability/{productIds}" missing path parameter productIds')
    }
    requestUrl = requestUrl.replace('{productIds}', parameters['productIds'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['onlineStoreId'] !== undefined) {
      const name = 'onlineStoreId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['physicalStoreId'] !== undefined) {
      const name = 'physicalStoreId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow = process.env.NODE_ENV !== PRODUCTION ? localStorageUtil.get(SHOW_API_FLOW) === 'true' : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(API_CALL_ACTION(from + ' -> Transaction: ' + method + ' ' + requestUrl + '?' + queryParameters))
      }
    }

    return executeRequest(requestOptions)
  },
}

export default inventoryavailabilityService
