import { Box, Grid } from '@mui/material'
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export interface IProps {}

const CartSkeletonLoader: React.FC<IProps> = () => {
  const CartPayButtonSkeleton = () => (
    <Box height="40px" mt={1} borderRadius={32} overflow="hidden">
      <Skeleton width="100%" height="100%" />
    </Box>
  )

  return (
    <SkeletonTheme
      baseColor="transparent"
      highlightColor={'rgba(0, 0, 0, 0.1)'}
    >
      <Box display="flex" flexDirection="column">
        <SkeletonTheme
          baseColor="transparent"
          highlightColor={'rgba(0, 0, 0, 0.1)'}
        >
          <Skeleton width="100%" height="180px" />
        </SkeletonTheme>
        <Box marginTop="42px" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            {new Array(5).fill(0).map((_, index) => (
              <Box mb={1} key={`cart_skeleton_${index}`}>
                <Skeleton width="100%" height="180px" />
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton width="100%" height="240px" />
            <CartPayButtonSkeleton />
            <CartPayButtonSkeleton />
          </Grid>
        </Grid>
      </Box>
    </SkeletonTheme>
  )
}

export default CartSkeletonLoader
