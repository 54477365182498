import styled from '@mui/material/styles/styled'
import { StyledButtonAsLink, StyledTypography } from '../../../components/UI'
import { LinkAsButton } from '@components/UI/Button'

export const OrderDetailsHeaderContainer = styled('div', {
  name: 'OrderDetailsHeader',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flewWrap: 'wrap',
  color: theme.palette.text.dark.primary,
  padding: `${theme.spacing(6)} ${theme.spacing(4)} 0px ${theme.spacing(4)}`,
  rowGap: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 0,
  },
}))

export const OrderDetailsListContainer = styled(OrderDetailsHeaderContainer, {
  name: 'OrderDetails',
  slot: 'ListContainer',
})(({}) => ({}))

export const OrderDetailsHeaderRow = styled('div', {
  name: 'OrderDetailsHeader',
  slot: 'Row',
  shouldForwardProp: prop => prop !== 'addTopMargin'
})<{ addTopMargin?: boolean }>(({ theme, addTopMargin = false }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  marginTop: addTopMargin ? theme.spacing(3) : '',
  [theme.breakpoints.up(768)]: {
    justifyContent: 'space-between',
  },
}))

export const StyledDetailsTitle = styled(StyledTypography, {
  name: 'OrderDetailsHeader',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  span: {
    fontWeight: 700,
  }
}))

export const StyledViewReceiptLink = styled(StyledButtonAsLink, {
  name: 'OrderDetailsHeader',
  slot: 'ViewReceiptLink',
})(({ theme }) => ({
  width: 'fit-conteent',
  margin: `${theme.spacing(2)} 0`,

  [theme.breakpoints.up(768)]: {
    float: 'right',
    margin: 0,
  },
}))

export const StyledReturnButton = styled(LinkAsButton, {
  name: 'OrderDetailsHeader',
  slot: 'ReturnLink',
})(({ theme }) => ({
  width: '100%',
  margin: `${theme.spacing(2)} 0`,

  [theme.breakpoints.up(768)]: {
    float: 'right',
    margin: 0,
  },

  [theme.breakpoints.up('sm')]: {
    width: 'fit-content'
  }
}))


export const OrderDetailsIssuesCTAContainer = styled('div', {
  name: 'OrderDetailsHeader',
  slot: 'IssuesCTAContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  alignItems: 'flex-end',
  marginTop: theme.spacing(3),

  [theme.breakpoints.up(768)]: {
    justifyContent: 'space-between',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  '.cta': {
    margin: 0,
    marginLeft: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minWidth: 140,

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    }
  }
}))

export const StyledDetailsIssues = styled('span', {
  name: 'OrderDetailsHeader',
  slot: 'Issues'
})(({}) => ({}))
