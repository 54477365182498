import Axios from 'axios'

import { PersonalizationIDResponse } from '@redux/reducers/reducerStateInterface'
import Log from '@services/Log'
import { localStorageUtil, storageSessionHandler } from '@foundation/utils/storageUtil'
import { PERSONALIZATION_ID } from '@foundation/constants/user'
import { site } from '@foundation/constants/site'

async function getPersonalizationID(s: any): Promise<PersonalizationIDResponse> {
  try {
    const response = await Axios.get(`${site.transactionContextUrl}/store/${s.storeID}/usercontext/personalizationId`)
    return response
  } catch {
    Log.error('unable to get personalization id', window.location.href, 'useCustomerSegment/index.ts')
    return {}
  }
}

async function callWebActivity(s: any, espotName: string, personalizationID: string) {
  const url = `${site.transactionContextUrl}/store/${s.storeID}/espot/${espotName}`
  return Axios.get(url, {
    headers: { WCPersonalization: personalizationID },
  }).catch(() => {
    Log.error('unable to get web activity', window.location.href, 'useCustomerSegment/index.ts')
  })
}

async function getCustomerSegments(s: any, personalizationID: string) {
  const url = `${site.transactionContextUrl}/store/${s.storeID}/customersegments`
  return Axios.get(url, {
    headers: { WCPersonalization: personalizationID },
  }).catch(() => {
    Log.error('unable to get customer segments', window.location.href, 'useCustomerSegment/index.ts')
  })
}

export async function setCustomerSegment(s: any) {
  const windowUrl = window.location.search
  const params = new URLSearchParams(windowUrl)
  const promoParam = params.get('promo')

  const currentUserSession = storageSessionHandler.getCurrentUserAndLoadAccount()
  let personalizationID = currentUserSession
    ? currentUserSession?.personalizationID
    : localStorageUtil.get(PERSONALIZATION_ID)

  if (!personalizationID) {
    const { data } = await getPersonalizationID(s)
    personalizationID = data?.personalizationID
    localStorageUtil.set(`-${PERSONALIZATION_ID}`, personalizationID)
  }

  if (promoParam) {
    await callWebActivity(s, promoParam, personalizationID)
  }

  await getCustomerSegments(s, personalizationID)
}
