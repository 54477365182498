import { unescape } from 'lodash-es'

/**
 * Parse embedded html inside ${html} tags
 */
export const parseHtmlText = (text: string): string => {
  let resultText = text

  const matches = Array.from(resultText?.matchAll(/\${html}(.*?)\${html}/g) ?? '')
  if (!matches || matches.length === 0) {
    return resultText
  }

  matches.forEach(item => {
    resultText = resultText.replace(item[0], unescape(item[1]))
  })

  return resultText
}
