import React from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Stack } from '@mui/material'
import { Button, PreLoader, StyledAnchor } from '../../../../../components/UI'
import { StyledPaypalDisclaimer } from '../../../payment/Payment.style'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useDispatch } from 'react-redux'
import { togglePaypalExpress } from '../../../../../features/order/slice'
import { PaypalRegularIcon } from '@components/UI-CSS/SVGIcon/PaymentIcon'
import styles from './styles/PaymentMethod.module.scss'

export interface PaypalPaymentMethodContentProps {
  isBusy: boolean
  onSubmit: () => void
}

export const PaypalPaymentMethodContent: React.FC<PaypalPaymentMethodContentProps> = ({ isBusy, onSubmit }) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const dispatch = useDispatch()

  return (
    <Stack direction={'column'} spacing={2} alignItems={'flex-start'}>
      <Button
        data-element-id={'X_Checkout_Paypal-CTA'}
        filltype={'outline'}
        disabled={isBusy}
        onClick={async () => {
          await dispatch(togglePaypalExpress(true))
          onSubmit()
        }}
        sx={{ marginLeft: 0, marginRight: 0 }}
      >
        {isBusy && <PreLoader withButton />}
        <PaypalRegularIcon className={styles.paypalIcon} />
      </Button>

      <StyledPaypalDisclaimer>
        <Trans i18nKey={'PaymentMethodSelection.Paypal.TermsConditions.Label'}>
          <StyledAnchor
            href={t('PaymentMethodSelection.Paypal.TermsConditions.Url', { langCode })}
            target="_blank"
            external
          ></StyledAnchor>
        </Trans>
      </StyledPaypalDisclaimer>
    </Stack>
  )
}
