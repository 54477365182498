import React, { useState } from 'react'
import { BOGOContainer, BOGOImage, BOGOInfoIcon, BOGOText, BOGOTitle } from './BuyOneGiveOne.style'
import { Trans, useTranslation } from 'next-i18next'
import { ModalDialog } from '../../../../../../components/UI/ModalDialogV2'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const BuyOneGiveOne = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  // TODO: make this number dynamic once the api is available
  const numberOfGlasses = '750,000+'

  return (
    <BOGOContainer>
      <ModalDialog open={isModalOpen} onClose={() => setIsModalOpen(false)} title={t('BuyOneGiveOne.Title')}>
        <BOGOImage />
        <h4>
          {numberOfGlasses} {t('BuyOneGiveOne.ExplanationTitle')}
        </h4>
        <p>{t('BuyOneGiveOne.Explanation')}</p>
      </ModalDialog>
      <BOGOTitle>
        {t('BuyOneGiveOne.Title')}
        <BOGOInfoIcon onClick={() => setIsModalOpen(true)}>
          <SVGIcon library="validation" name="info" />
        </BOGOInfoIcon>
      </BOGOTitle>
      <BOGOText>
        <Trans i18nKey={'BuyOneGiveOne.Text'} />
      </BOGOText>
    </BOGOContainer>
  )
}
