import { getParsedOrderRecapItems } from '../../../utils/order'

import React from 'react'
import { useSelector } from 'react-redux'
import { cartSelector, catentriesSelector, orderItemsSelector } from '../../../features/order/selector'
import { getOrderItemsAttachments } from '../../../constants/order'
import { OrderRecap } from '@components/order-recap'

export type CheckoutRecapItemProps = {
  isOrderComplete?: boolean
}

const CheckoutRecapItem: React.FC<CheckoutRecapItemProps> = ({ isOrderComplete = false }) => {
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)
  const catEntries = useSelector(catentriesSelector)
  const orderItemsAttachments = getOrderItemsAttachments(orderItems, catEntries)

  const parsedOrderItems = getParsedOrderRecapItems(orderItems)
  return (
    <OrderRecap
      order={cart}
      orderItems={parsedOrderItems}
      orderItemsAttachments={orderItemsAttachments}
      isOrderComplete={isOrderComplete}
    />
  )
}

export default CheckoutRecapItem
