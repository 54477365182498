import { useEffect, useState, useRef } from 'react'
import { APP_MAIN_CONTAINER_ID } from '../../constants/ui'
/**
 * useScrollingUp custom react hook
 * @returns {boolean}
 */
const useScrollingUp = () => {
  const prevScrollY = useRef(0)
  const yPosDelta = useRef(10)

  const [goingUp, setGoingUp] = useState<boolean | null>(null)

  useEffect(() => {
    const appMainContainerElement = document.getElementById(APP_MAIN_CONTAINER_ID)
    const handleScroll = () => {
      const currentScrollY = appMainContainerElement ? appMainContainerElement?.scrollTop : 0

      if (Math.abs(prevScrollY.current - currentScrollY) >= yPosDelta.current) {
        // i'm scrolling down
        if (prevScrollY.current < currentScrollY && (goingUp || goingUp === null)) {
          setGoingUp(false)
        }
        // i'm scrolling up
        if (prevScrollY.current > currentScrollY && !goingUp) {
          setGoingUp(true)
        }

        // i'm on top of the page
        if (currentScrollY <= 1) {
          setGoingUp(null)
        }
      }
      prevScrollY.current = currentScrollY
    }

    appMainContainerElement?.addEventListener('scroll', handleScroll, {
      passive: true,
    })

    return () => appMainContainerElement?.removeEventListener('scroll', handleScroll)
  }, [goingUp])
  return goingUp
}

export default useScrollingUp
