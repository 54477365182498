// Main libraries
import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'

// Components
import AddressForm from '../../../components/AddressForm'
import { Button } from '../../../components/UI'

// Constants
import {
  ADDRESSLINE1,
  ADDRESSLINE2,
  ADDRESS_BOOK,
  ADDRESS_LINE,
  ADDRESS_SHIPPING,
  APARTMENT_CHECK,
  BUZZER_CODE,
  EMPTY_STRING,
} from '../../../constants/common'

// Custom Hooks
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useCheckoutForm } from '../../../hooks/useCheckoutForm'

// Redux
import { sendSuccessMessage } from '../../../features/success/slice'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../../redux/actions/user'
import { shippingFormFieldsSelector } from '../../../redux/selectors/site'
import { useAppDispatch } from '../../../hooks/redux'

// Services
import Log from '../../../services/Log'
import personContactService from '../../../foundation/apis/transaction/personContact.service'

// Styles
import {
  AddAddressFormButtonContainer,
  AddressFormTitleWrapper,
  AddressFormWrapper,
  NewAddressTitle,
} from '../AddressBook.style'

// Types
import { AddressFormData } from '../../../types/form'
import { CheckoutAddressFormField } from '../../../types/checkout'
import { Contact } from '../../../types/user'

// Utils
import addressUtil from '../../../utils/addressUtil'
import { userEmailSelector } from '../../../redux/selectors/user'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { AddressLine } from '../../../types/common'

export interface IProps {
  formData: Contact | null
  payloadBase: any
  onEditEnd: () => void
  isEdit?: boolean
  updateAddress?: (address: Contact) => void
  handleResponse?: (response: boolean) => void
}

const addressFormDataInit: AddressFormData = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  addressLine1: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  city: EMPTY_STRING,
  country: EMPTY_STRING,
  state: EMPTY_STRING,
  zipCode: EMPTY_STRING,
  phone1: EMPTY_STRING,
  email1: EMPTY_STRING,
  addressType: ADDRESS_SHIPPING,
}

const AddressDialog: React.FC<IProps> = ({
  formData,
  payloadBase,
  onEditEnd,
  isEdit = false,
  updateAddress,
  handleResponse,
}) => {
  const dispatch = useAppDispatch()
  const userEmail = useSelector(userEmailSelector)
  const { t } = useTranslation()
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const [isFormValid, setFormValid] = useState<boolean>(false)
  const [open, setOpen] = React.useState(true)
  const handleClose = () => {
    setOpen(false)
    onEditEnd()
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const addressLine2Fields = addressUtil.getAddressWithDelimiter(formData?.addressLine?.[1])
  const [newAddressFormData, setNewAddressFormData] = useState<AddressFormData>({
    ...addressFormDataInit,
    ...formData,
    addressLine1: formData?.addressLine?.[0] || '',
    ...addressLine2Fields,
    country: storeCountry,
    email1: userEmail,
  })

  const shippingFormFieldsConf = useSelector(shippingFormFieldsSelector)

  const shippingFormFields: CheckoutAddressFormField[] =
    shippingFormFieldsConf?.filter(i => i.fieldName !== 'email1') || []

  const form = useCheckoutForm({
    defaultValues: newAddressFormData,
    fields: shippingFormFields,
  })

  const saveAddress = () => {
    // remove leading and trailing white space from all form input fields.
    let newAddressData = addressUtil.removeLeadingTrailingWhiteSpace(newAddressFormData)
    newAddressData[ADDRESS_LINE] = [newAddressData[ADDRESSLINE1]]

    // concat address line 2, buzzer code, and apartment check value with delimiter, to save
    const newAddressLine2Data: AddressLine = {
      addressLine2: newAddressData[ADDRESSLINE2],
      buzzerCode: newAddressData[BUZZER_CODE],
      apartmentCheck: newAddressData[APARTMENT_CHECK],
    }
    newAddressData[ADDRESS_LINE].push(addressUtil.concatAddressWithDelimiter(newAddressLine2Data))
    delete newAddressData[ADDRESSLINE1]
    delete newAddressData[ADDRESSLINE2]

    if (isEdit) {
      updateAddress && updateAddress(newAddressData)
    } else {
      createAddress(newAddressData, payloadBase, dispatch)
    }
    handleClose()
  }

  const createAddress = (newAddressData: any, payloadBase: any, dispatch) => {
    personContactService
      .addPersonContact({
        body: newAddressData,
        ...payloadBase,
      })
      .then(res => res.data)
      .then(addressData => {
        if (addressData.addressId) {
          dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION({ ...payloadBase }))
          dispatch(
            sendSuccessMessage({
              key: 'success-message.ADD_ADDRESS_SUCCESS',
              messageParameters: {
                '0': newAddressData.nickName,
              },
            })
          )
          handleResponse && handleResponse(true)
          handleClose()
        }
      })
      .catch(e => {
        handleResponse && handleResponse(false)
        Log.error(`Could not create new address: ${e}`)
      })
  }

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <AddressFormWrapper>
        <AddressFormTitleWrapper>
          {!isEdit && <NewAddressTitle>{t('AddressBook.NewAddress')}</NewAddressTitle>}
          {isEdit && <NewAddressTitle>{t('AddressBook.EditAddress')}</NewAddressTitle>}
          <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
        </AddressFormTitleWrapper>
        <AddressForm
          addressFormFields={shippingFormFields}
          addressType={newAddressFormData.addressType}
          cid="newAddress-shipping-and-billing"
          form={form}
          page={ADDRESS_BOOK}
          onFormDataChanged={(_, data) => setNewAddressFormData(data)}
          onFormValidationStatusChanged={(_, isValid) => setFormValid(isValid)}
        />
        <AddAddressFormButtonContainer>
          <Button
            data-element-id={'X_X_AddressBook_Add'}
            disabled={!isFormValid}
            variant="primary"
            onClick={saveAddress}
          >
            {!isEdit && t('AddressBook.CreateAddress')}
            {isEdit && t('AddressBook.SaveChanges')}
          </Button>
        </AddAddressFormButtonContainer>
      </AddressFormWrapper>
    </Dialog>
  )
}

export default AddressDialog
