import { Divider, Modal, ModalProps } from '@mui/material'

import { IconButton } from '../IconButton'
import React, { ReactNode } from 'react'
import styled from '@mui/material/styles/styled'
import theme from '../../../themes'
import { DEFAULT_DIALOG_Z_INDEX } from '../../../constants/ui'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const calWidthSize = (value: ModalDialogProps['widthSize']) => {
  switch (value) {
    case 'xs':
      return '30%'
    case 'sm':
      return '45%'
    case 'md':
      return '65%'
    case 'md-lg':
      return '864px'
    case 'lg':
      return '85%'
    default:
      return '30%'
  }
}
export interface ModalDialogProps extends ModalProps {
  onCloseButton?: () => void
  widthSize?: 'xs' | 'sm' | 'md' | 'lg' | 'md-lg'
  roundBorders?: boolean
  fullSizeWhenSmall?: boolean
  customZIndex?: number
}

const WrapperModalDialog = styled('div', {
  name: 'ModalDialog',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'widthSize' && prop !== 'fullSizeWhenSmall' && prop !== 'roundBorders',
})<{
  roundBorders: ModalDialogProps['roundBorders']
  widthSize: ModalDialogProps['widthSize']
  fullSizeWhenSmall?: ModalDialogProps['fullSizeWhenSmall']
}>(({ theme, widthSize, roundBorders, fullSizeWhenSmall = true }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 32px)',
    maxHeight: '80%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.light.primary,
    borderRadius: roundBorders ? '4px' : 0,

    [theme.breakpoints.up('md')]: {
      width: widthSize ? calWidthSize(widthSize) : 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      width: fullSizeWhenSmall ? '100%' : null,
    },

    '&:focus': {
      outline: 'none',
    },
  }
})

const ContentModalDialog = styled('div', {
  name: 'ModalDialog',
  slot: 'Content',
})(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}))

const TitleDivider = styled(Divider, {
  name: 'ModalDialog',
  slot: 'TitleDivider',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'bold',
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  justifyContent: 'center',

  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(13),
  },

  '& .MuiDivider-wrapper': {
    padding: `0 ${theme.spacing(4)}`,
  },

  '&::before, &::after': {
    borderColor: 'inherit',
    borderWidth: 1,
    [theme.breakpoints.up('sm')]: {
      width: '10%',
    },
  },
}))

export const Title = styled('div', {
  name: 'ModalDialog',
  slot: 'Title',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 16,
  fontWeight: 'bold',
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  justifyContent: 'center',

  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(13),
  },
}))

const StyledIconButton = styled(IconButton, {
  name: 'ModalDialog',
  slot: 'CloseButton',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
  top: theme.spacing(4),
  zIndex: 2,
}))

export const ModalDialogTitle: React.FC<{ children?: ReactNode; divider?: boolean }> = ({
  children,
  divider = true,
}) => {
  return divider ? <TitleDivider>{children}</TitleDivider> : <Title>{children}</Title>
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  onCloseButton,
  children,
  widthSize,
  roundBorders,
  fullSizeWhenSmall,
  customZIndex,
  ...rest
}) => {
  return (
    <Modal sx={{ zIndex: customZIndex || DEFAULT_DIALOG_Z_INDEX }} {...rest}>
      <WrapperModalDialog
        widthSize={widthSize}
        roundBorders={roundBorders}
        fullSizeWhenSmall={fullSizeWhenSmall}
        className="ModalDialog--wrapper"
      >
        <ContentModalDialog className="ModalDialog--content">
          {onCloseButton && (
            <StyledIconButton className="ModalDialog--close" aria-label="close" onClick={onCloseButton}>
              <SVGIcon library="close" name="close" color={theme.palette.text.dark.primary} />
            </StyledIconButton>
          )}
          {children}
        </ContentModalDialog>
      </WrapperModalDialog>
    </Modal>
  )
}
