import { GridContainer, GridItem, TextField } from '../UI'

import { Z_INDEX_LV2 } from '../../constants/ui'
import styled from '@mui/material/styles/styled'

export const StyledAddressForm = styled('form', {
  name: 'AddressForm',
  slot: 'Form',
})(() => ({}))

export const StyledGridContainer = styled(GridContainer, {
  name: 'AddressForm',
  slot: 'GridContainer',
})(({ theme }) => ({
  columnGap: '16px !important',
  rowGap: theme.spacing(10),
}))

export const StyledTextField = styled(TextField, {
  name: 'AddressForm',
  slot: 'TextField',
})(({ theme }) => ({
  background: theme.palette.background.light.primary,

  '&.state-field': {
    '.MuiOutlinedInput-root': {
      minHeight: '3rem'
    },
  },
  '& .MuiFormHelperText-root.Mui-error': {
    background: theme.palette.background.light.secondary,
  },
}))

export const StyledGridItem = styled(GridItem, {
  name: 'AddressForm',
  slot: 'GridItem',
})(({ theme }) => ({
  width: 'auto',
  [theme.breakpoints.up('xs')]: {
    overflow: 'visible',
  },
}))

export const StyledGridItemAdditionalText = styled('span', {
  name: 'AddressForm',
  slot: 'GridItemAdditionalText',
})(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.light.secondary,
  marginBottom: theme.spacing(-5),
}))

export const StyledGridItemErrorMsg = styled('span', {
  name: 'AddressForm',
  slot: 'GridItemErrorMsg',
})(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.color.error,
  whiteSpace: 'nowrap',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(3.5)
}))

export const StyledPhoneErrorMsg = styled(StyledGridItemErrorMsg, {
  name: 'AddressForm',
  slot: 'PhoneErrorMsg',
  shouldForwardProp: (prop) => prop !== 'isVisible'
})<{isVisible: boolean}>(({ theme, isVisible }) => ({
  marginTop: isVisible ? theme.spacing(10) : theme.spacing(4),
}))

export const WrapperCountry = styled('div', {
  name: 'AddressForm',
  slot: 'CountryWrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const CountryTitle = styled('div', {
  name: 'AddressForm',
  slot: 'TitleCountry',
})(({ theme }) => ({
  color: theme.palette.text.light.secondary,
}))

export const CountryText = styled('div', {
  name: 'AddressForm',
  slot: 'CountryText',
})(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.light.secondary,
}))

export const AddressLine2Button = styled('div', {
  name: 'AddressForm',
  slot: 'AddressLine2Button',
})<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 'bold',
  color: theme.palette.custom.blue,
  marginBottom: isOpen ? theme.spacing(6) : 0,
  cursor: 'pointer',
  textDecoration: 'underline',
}))

export const AddressSuggesterWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'SuggesterWrapper',
})(() => ({
  position: 'relative',
}))

export const SuggestionsWrapper = styled('ul', {
  name: 'AddressForm',
  slot: 'SuggestionsWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  zIndex: Z_INDEX_LV2,
  padding: 0,
  margin: 0,
  listStyleType: 'none',
  width: '100%',
  border: `2px solid ${theme.palette.background.dark.secondary}`,
  borderTop: 0,
}))

export const SuggestionsElement = styled('li', {
  name: 'AddressForm',
  slot: 'SuggestionsElement',
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ selected, theme }) => ({
  color: 'inherit',
  cursor: 'pointer',
  padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
  margin: '0',
  background: selected
    ? theme.palette.background.dark.quaternary
    : theme.palette.background.light.primary,
  '&:hover': {
    background: theme.palette.background.dark.quaternary,
  },
}))
