import { useDispatch, useSelector } from 'react-redux'
import { ImageryType } from '../../../components/PrescriptionLenses/PrescriptionLenses'
import { RX_CONF_SELECTOR } from '../../../constants/common'
import { CART } from '../../../constants/routes'
import { useRemoveOrderItemMutation } from '../../../features/order/query'
import { addItem } from '../../../features/order/thunks'
import { doneEditingCartItem, setAddToCartBusy } from '../../../features/cartui/cartuiSlice'
import addContactLenses, { ContactLensesAddItemArgs } from '../../../features/order/thunks/addContactLenses'
import addLensItem, { IAddLensArgs } from '../../../features/order/thunks/addLensItem'

import { productContactLensesDataSelector, productAddToCartEnabledSelector } from '../../../features/product/selector'
import { sendAddToCartEvent } from '../../../foundation/analytics/tealium/lib'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { OrderItem } from '../../../types/order'
import { ContactLensesData, IProduct } from '../../../types/product'
import { LensObject } from '../../../types/rxConfigurator'
import { getCurrentProductItemBean, isCLAccessoriesProduct, isContactLensesProduct } from '../../../utils/product'
import { useEditCartItemParams } from '../../../hooks/useEditCartItemParams'
import { getInsuranceEventModule } from '../../../components/DirectBilling'
import Log from '../../../services/Log'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { PartNumberAlgoliaPrice } from '../../../foundation/algolia/algoliaPrice'
import guestIdentityService from '../../../foundation/apis/transaction/guestIdentity.service'
import { GUEST_LOGIN_SUCCESS_ACTION } from '../../../redux/actions/user'
import { storageSessionHandler } from '../../../foundation/utils/storageUtil'
import { useRouter } from 'next/router'
import { userDetailsSelector } from '@redux/selectors/user'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { SubscriptionInfo } from '@typesApp/subscription'

interface AddToCartProps {
  isClItem?: boolean
  isLenses?: boolean
  product?: IProduct
  fromConfigurator?: boolean
  lensObject?: LensObject
  images?: ImageryType
  fromRxPanel?: boolean
}

export const useAddOrderItem = (
  productInCart?: OrderItem,
  currentProduct?: IProduct,
  partNumber?: string,
  productQuantity?: string[],
  subscriptionInfo?: SubscriptionInfo,
  algoliaPrices?: PartNumberAlgoliaPrice
) => {
  const { mySite } = useSite()
  const router = useRouter()
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const { langId, langCode } = useStoreIdentity()
  const dispatch = useDispatch()
  const { interceptAddCartItem } = getInsuranceEventModule()
  const contactLensData = useSelector(productContactLensesDataSelector)
  const addToCartEnabled = useSelector(productAddToCartEnabledSelector)
  const [removeOrderItem] = useRemoveOrderItemMutation()
  const isClAccessory = isCLAccessoriesProduct(currentProduct)
  const isCl = isContactLensesProduct(currentProduct)
  const { isEditLens, isEditContactLens } = useEditCartItemParams()
  const { ...analyticsDataForCart } = useAnalyticsData(ANALYTICS_PAGE_TYPE.CART)
  const productSubscriptionInfo =
    isSubscriptionEnabled && currentProduct && subscriptionInfo?.identifier === currentProduct.partNumber
      ? subscriptionInfo
      : undefined
  const buildContactLensesPayload = (contactLensData: ContactLensesData): ContactLensesAddItemArgs['items'] => {
    const clPayload: ContactLensesAddItemArgs['items'] = !!contactLensData
      ? Object.values(contactLensData).map(entry => {
          let singleLensData = { ...entry }
          let { quantity } = singleLensData
          delete singleLensData.quantity
          if (singleLensData?.x_dominance === '') {
            delete singleLensData.x_dominance
          }
          if (singleLensData.x_axis) {
            singleLensData = {
              ...singleLensData,
              //The BE does not accept values without the leading zeroes, but the FE should hide them
              x_axis: String(singleLensData.x_axis || '0').padStart(3, '0'),
            }
          }
          return {
            x_contactLens: {
              ...singleLensData,
            },
            quantity: quantity || '',
          }
        })
      : []
    return clPayload
  }

  let userInfo = useSelector(userDetailsSelector)

  const addItemToCart = async ({
    isClItem,
    isLenses,
    fromConfigurator,
    lensObject,
    product,
    fromRxPanel,
  }: AddToCartProps) => {
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
    if (!currentUser) {
      const guestResponse = await guestIdentityService.login({})

      dispatch(GUEST_LOGIN_SUCCESS_ACTION(guestResponse.data))
    }

    if (isClItem) {
      const contactLensesParams: ContactLensesAddItemArgs = {
        items: contactLensData ? buildContactLensesPayload(contactLensData) : [],
        product: currentProduct,
        subscriptionInfo: productSubscriptionInfo,
        callback: () => {
          dispatch(doneEditingCartItem())
          sendAddToCartEventConditionally(isLenses || false)
          router.push(`/${langCode}/${CART}`)
        },
        langId: langId,
      }
      dispatch(addContactLenses(contactLensesParams))
    } else if (isLenses) {
      const params: IAddLensArgs = {
        params: {
          catentryId: lensObject?.lensPackage.catEntryId || '',
          langId,
          partnumber: partNumber || '',
          product: currentProduct,
          quantity: ['1'],
          fromRxPanel,
          userInfo,
        },
        callback: () => {
          if (window.RXC) {
            window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
          }
          dispatch(doneEditingCartItem())
          sendAddToCartEventConditionally(isLenses)
          router.push(`/${langCode}/${CART}`)
        },
        siteInfo: mySite,
      }
      dispatch(addLensItem(params))
    } else {
      const accessoryPartNumber = getCurrentProductItemBean(currentProduct)?.partNumber ?? ''
      const params = {
        isClAccessory,
        storeId: mySite.storeID,
        partnumber: isClAccessory ? accessoryPartNumber : product ? product.partNumber : partNumber || '',
        quantity: isClAccessory ? productQuantity || ['1'] : ['1'],
        langId,
        product: currentProduct,
        subscriptionInfo: productSubscriptionInfo,
        callback: fromConfigurator
          ? () => {
              if (window.RXC) {
                window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
              }
              sendAddToCartEventConditionally(isLenses || false)
              router.push(`/${langCode}/${CART}`)
            }
          : () => {
              sendAddToCartEventConditionally(isLenses || false)
              router.push(`/${langCode}/${CART}`)
            },
      }
      dispatch(addItem(params))
    }
  }

  const sendAddToCartEventConditionally = (isLenses: boolean) => {
    if (!isLenses && currentProduct && productQuantity) {
      sendAddToCartEvent(
        currentProduct ? [currentProduct] : [],
        Number(productQuantity?.[0]) || 1,
        algoliaPrices,
        analyticsDataForCart
      )
    }
  }

  const addToCart = async (props: AddToCartProps = {}) => {
    if (!addToCartEnabled) {
      return
    }

    interceptAddCartItem(() => addToCartImpl(props))
  }

  const handleRemoveOrderItemError = () => {
    if (window.RXC) {
      window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
    }
    dispatch(doneEditingCartItem())
    router.push(`/${langCode}/${CART}`)
  }

  const addToCartImpl = async ({
    isClItem = isCl,
    isLenses,
    product,
    fromConfigurator,
    lensObject,
    images,
    fromRxPanel,
  }: AddToCartProps = {}) => {
    if (isEditContactLens || isEditLens) {
      if (!productInCart) throw Error('Add to cart requires product in cart to be specified in edit mode!')

      dispatch(setAddToCartBusy(true))

      // add item only if remove was successful otherwise we risk corrupting the cart
      removeOrderItem({ orderItem: productInCart })
        .unwrap()
        .then(() => {
          addItemToCart({
            isClItem,
            isLenses,
            product,
            fromConfigurator,
            lensObject,
            images,
            fromRxPanel,
          })
        })
        .catch(error => {
          Log.error('Error removing order item: ' + error)
          handleRemoveOrderItemError()
        })
    } else {
      dispatch(setAddToCartBusy(true))

      addItemToCart({
        isClItem,
        isLenses,
        product,
        fromConfigurator,
        lensObject,
        images,
        fromRxPanel,
      })
    }
  }

  const handleAddToCartLenses = (_, lensObject: LensObject, ...args) => {
    // when we call the configurator with the cartMode object
    // the position of the image arg, isn't the same as the usual
    const images: ImageryType = args.flat().find(arg => !!arg?.fallbackImage || !!arg?.productImage)

    if (lensObject.lensPackage.type === 'Frame Only') {
      addToCart({ fromConfigurator: true })
    } else {
      addToCart({ fromConfigurator: true, isLenses: true, lensObject, images, fromRxPanel: true })
    }
  }

  return {
    addToCart,
    handleAddToCartLenses,
  }
}
