import styled from '@mui/material/styles/styled'
import {
  GridContainer,
  GridItem,
  StyledAnchor,
  StyledButtonAsLink,
  StyledLinkAsButton,
} from '../../components/UI'

export const DashboardWrapper = styled('div', {
  name: 'Dashboard',
  slot: 'Wrapper'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(6),
  },

  [theme.breakpoints.up(1023)]: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingTop: theme.spacing(8),
  },
}))

export const DashboardTitleWrapper_ = styled('div', {
  name: 'DashboardTitle',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(8),
  fontSize: theme.typography.h4.fontSize,
  lineHeight:theme.typography.h3.fontSize,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(16),
  }
}))

export const DashboardTitleWrapper = styled('div', {
  name: 'Dashboard',
  slot: 'Title',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontWeight: 700,
  fontSize: theme.typography.h4.fontSize,
  lineHeight:theme.typography.h3.fontSize,
}))

export const DashboardStyledLinkCTA = styled(StyledButtonAsLink)(
  ({ theme }) => ({
    fontWeight: 'bold',
    '&::after': {
      width: '0%',
    },
    [theme.breakpoints.up('sm')]: {
      textDecoration: 'none',
      marginLeft: '10px',
      '&::after': {
        width: '100%',
      }
    }
  })
)

export const DashboardStyledAnchorCTA = styled(StyledAnchor, {
  name: 'Dashboard',
  slot: 'StyledAnchorCTA',
})(({ theme }) => ({
  fontWeight: 'bold',
  textDecoration: 'underline',
  marginLeft: theme.spacing(2),
  '&::after': {
    width: '0%',
  },

  [theme.breakpoints.up('sm')]: {
    textDecoration: 'none',
    marginLeft: '10px',
    '&::after': {
      width: '100%',
    }
  }
}))

export const DashboardGridContainer = styled(GridContainer, {
  name: 'Dashboard',
  slot: 'GridContainer',
})(({ theme }) => ({
  gridTemplateColumns: '1fr',
  rowGap: theme.spacing(6),
  columnGap: theme.spacing(6),
  maxWidth: '950px',
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, "1fr")',
    rowGap: theme.spacing(4),
    columnGap: theme.spacing(4)
  }
}))

export const DashboardGridItem = styled(GridItem, {
  name: 'Dashboard',
  slot: 'GridItem'
})(({ theme }) => ({
  minHeight: '439px',
  gridColumn: 'span 3',
  [theme.breakpoints.up('md')]: {
    minWidth: '375px',
    maxWidth: '456px'
  }
}))

export const DashboardGridItemContainer = styled('div', {
  name: 'Dashboard',
  slot: 'GridItemContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(6)}`,
  borderRadius: theme.spacing(2),
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  minHeight: '439px',
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(10)
  }
}))

export const DashboardGridItemHeaderWrapper = styled('div', {
  name: 'Dashboard',
  slot: 'GridItemHeaderWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexGrow: 0,
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: 0
}))

export const DashboardGridItemHeader = styled('span', {
  name: 'Dashboard',
  slot: 'GridItemHeader',
})(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  lineHeight:theme.typography.h3.fontSize,
  fontWeight: 600,
  color: theme.palette.text.dark.primary
}))

export const DashboardGridItemSubheader = styled('span', {
  name: 'Dashboard',
  slot: 'GridItemSubheader',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight:theme.typography.h3.fontSize,
  fontWeight: 'normal',
  color: theme.palette.text.dark.primary
}))

export const DashboardLastOrderContent = styled('div', {
  name: 'Dashboard',
  slot: 'LastOrderContent',
})(({ theme }) => ({
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  padding: 0,
}))

export const DashboardLastOrderTitle = styled('span', {
  name: 'Dashboard',
  slot: 'LastOrderTitle',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight:theme.typography.h3.fontSize,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
}))

export const DashboardLastOrderDetailsContainer = styled('div', {
  name: 'Dashboard',
  slot: 'LastOrderDetailsContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center'
}))

export const DashboardLastOrderDetails = styled('div', {
  name: 'Dashboard',
  slot: 'LastOrderDetails',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(2)
}))

export const DashboardLastOrderDetailsLabel = styled('span', {
  name: 'Dashboard',
  slot: 'LastOrderDetailsLabel',
})(({theme}) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight:theme.typography.h3.fontSize,
}))

export const DashboardLastOrderDetailsItem = styled('span', {
  name: 'Dashboard',
  slot: 'LastOrderDetailsItem',
})(({theme}) => ({
  fontSize: theme.typography.body2.fontSize,
  lineHeight:theme.typography.body1.fontSize,
  display: 'inline-flex',
  alignItems: 'center'
}))

export const DashboardGridItemContent = styled('div', {
  name: 'Dashboard',
  slot: 'GridItemContent',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.h3.fontSize,
  textAlign: 'center',
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('lg')]: {
    width: 283
  },
}))

export const DashboardMyStores = styled('div', {
  name: 'Dashboard',
  slot: 'MyStore',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: 0,
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
}))

export const CTATextContainer = styled('div', {
  name: 'CallToAction',
  slot: 'TextContainer',
})(({ theme }) => ({
  alignSelf: 'center',
  gap: theme.spacing(2),
  textAlign: 'center',
  [theme.breakpoints.up('lg')]: {
    width: 283
  },
}))

export const TextFieldContainer = styled('div', {
  name: 'TextField',
  slot: 'Container',
})(({ theme }) => ({
  width: 295,
  alignSelf: 'center',
  marginTop: theme.spacing(8),
  position: 'relative',
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('md')]: {
    width: 240,
    marginTop: theme.spacing(5)
  },
  [theme.breakpoints.up('lg')]: {
    width: 343,
    marginTop: theme.spacing(8)
  },
}))

export const TextFieldIconContainer = styled('div', {
  name: 'TextField',
  slot: 'IconContainer',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(-4),
  top: theme.spacing(4),
  flexGrow: 0,
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: 0,
  fontSize: theme.typography.h4.fontSize,
  lineHeight:theme.typography.h3.fontSize,
  fontWeight: 600,
  textAlign: 'center',
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(2)
  }
}))

export const HeartIconContainer = styled('div', {
  name: 'HeartIcon',
  slot: 'IconContainer',
})(() => ({
  display: 'flex',
  '& > svg': {
    transform: 'scale(3) translate(33%, 33%)'
  },
}))

export const ShopNowAccountButton = styled(StyledLinkAsButton, {
  name: 'Account',
  slot: 'ShopNowButton'
})(() => ({}))

export const DashboardTileHeader = styled('span', {
  name: 'Dashboard',
  slot: 'GridItemHeader',
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.4,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  marginTop: theme.spacing(1),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(2),
  },
}))

export const DashboardMyStoresStyled = styled('div', {
  name: 'Dashboard',
  slot: 'MyStore',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(8),
  padding: 0,
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('sm')]: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(8),
  },
}))

export const DashboardTileHeaderWrapper = styled('div', {
  name: 'Dashboard',
  slot: 'GridItemHeaderWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexGrow: 0,
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: 0,
  marginBottom: 'auto',
}))
