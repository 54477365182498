import Axios, { Canceler } from 'axios'
//Standard libraries
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/navigation'
import getDisplayName from 'react-display-name'
import { EMPTY_STRING } from '../../../constants/common'
//Custom libraries
import { ACCOUNT, ACCOUNT_CHILDREN, CART, HOME } from '../../../constants/routes'
//Redux
import { RESET_ERROR_ACTION } from '@redux/actions/error'
import { loginStatusSelector } from '@redux/selectors/user'
import { passwordExpiredErrorSelector } from '@redux/selectors/error'
import { useSite } from '@foundation/hooks/useSite'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { orderApi } from '@features/order/query'
import Log from '@services/Log'

//UI
import LogonAndChangePasswordForm from './LogonAndChangePasswordForm'
import SignInForm from './SignInForm'
import { LoginContainer } from '../styles/SignInLayout.style'

export interface SignInContext {
  isFromCheckout?: boolean
  redirectRoute?: string
}

function SignInLayout({ ...props }: SignInContext) {
  const dispatch = useDispatch()
  const router = useRouter()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const MY_ACCOUNT_ROUTE = `${HOME}${langCode}/${ACCOUNT}/${ACCOUNT_CHILDREN.DASHBOARD}`
  const CART_ROUTE = `${HOME}${langCode}/${CART}`
  const passwordExpiredError = useSelector(passwordExpiredErrorSelector)
  const widgetName = getDisplayName(SignInLayout)
  const loginStatus = useSelector(loginStatusSelector)
  const [currentEmail, setCurrentEmail] = useState<string>(EMPTY_STRING)
  const [redirectRoute, setRedirectRoute] = useState<string>(props.redirectRoute || MY_ACCOUNT_ROUTE)
  const [readyToRedirect, setReadyToRedirect] = useState<boolean>(false)
  const [errorMessageKey, setErrorMessageKey] = useState<string>(EMPTY_STRING)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const [getCart] = orderApi.endpoints.getCart.useLazyQuery()
  const payloadBase: object = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  useEffect(() => {
    if (passwordExpiredError.errorKey && passwordExpiredError.errorCode) {
      setErrorMessageKey('error-message.' + passwordExpiredError.errorKey + '_' + passwordExpiredError.errorCode)
    } else {
      setErrorMessageKey(EMPTY_STRING)
    }
  }, [passwordExpiredError])

  useEffect(() => {
    return () => {
      dispatch(RESET_ERROR_ACTION())
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [])

  useEffect(() => {
    if (loginStatus) {
      getCart({
        ...payloadBase,
        storeId: mySite.storeID,
        fetchCatentries: true,
        fetchShippingInfo: false,
        refetch: false,
      })
        .unwrap()
        .then(cartRes => {
          const { catentries } = cartRes
          const hascartItems = catentries && Object.keys(catentries).length > 0
          setRedirectRoute(redirectRoute || (hascartItems ? CART_ROUTE : MY_ACCOUNT_ROUTE))
          setReadyToRedirect(true)
        })
        .catch(err => {
          Log.error(`While retrieving the cart: ${err}`)
          setRedirectRoute(MY_ACCOUNT_ROUTE)
          setReadyToRedirect(true)
        })
    }
  }, [loginStatus])

  if (loginStatus && readyToRedirect) {
    router.push(redirectRoute)
    return null
  } else {
    return (
      <>
        {errorMessageKey ? (
          <LogonAndChangePasswordForm
            errorMessageKey={errorMessageKey}
            currentEmail={currentEmail}
            payloadBase={payloadBase}
          />
        ) : (
          <LoginContainer>
            <SignInForm setCurrentEmail={setCurrentEmail} payloadBase={payloadBase} />
          </LoginContainer>
        )}
      </>
    )
  }
}

export { SignInLayout }
