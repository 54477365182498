import * as yup from 'yup'
import { BaseSchema } from 'yup'

const schemaFieldNames = {
  MINIMUM_LENGTH: 'minimumLength',
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase',
  NUMBER: 'number',
  SPECIAL_CHARACTER: 'specialCharacter',
} as const

type MessageKey = 'minimumLength' | 'uppercase' | 'lowercase' | 'number' | 'specialCharacter'

export type PasswordValidationMessages = {
  empty: string | undefined
  invalid: string | undefined
  requirements: Record<MessageKey, string | undefined>
}

const fields = {
  [schemaFieldNames.MINIMUM_LENGTH]: {
    regexp: /.{8,}/,
  },
  [schemaFieldNames.UPPERCASE]: {
    regexp: /[A-Z]/,
  },
  [schemaFieldNames.LOWERCASE]: {
    regexp: /[a-z]/,
  },
  [schemaFieldNames.NUMBER]: {
    regexp: /\d/,
  },
  [schemaFieldNames.SPECIAL_CHARACTER]: {
    regexp: /[!@#$%^&*)(+=._\-£§€:;<>?\[\]`{|}~"'/]/,
  },
}

export interface UsePasswordValidationReturn {
  maxLength: number
  validationSchema: BaseSchema
}

export const usePasswordValidation = (): UsePasswordValidationReturn => {
  const validationSchema = yup
    .string()
    .required()
    .matches(
      fields[schemaFieldNames.MINIMUM_LENGTH].regexp,
      schemaFieldNames.MINIMUM_LENGTH
    )
    .matches(
      fields[schemaFieldNames.NUMBER].regexp,
      schemaFieldNames.NUMBER
    )
    .matches(
      fields[schemaFieldNames.LOWERCASE].regexp,
      schemaFieldNames.LOWERCASE
    )
    .matches(
      fields[schemaFieldNames.UPPERCASE].regexp,
      schemaFieldNames.UPPERCASE
    )
    .matches(
      fields[schemaFieldNames.SPECIAL_CHARACTER].regexp,
      schemaFieldNames.SPECIAL_CHARACTER
    )

  return {
    maxLength: 50,
    validationSchema,
  }
}
