import React from 'react'
import ReducedHeader from './components/ReducedHeader'
import { usePageType } from '../../foundation/hooks/usePageType'
import { DefaultHeader } from './components/DefaultHeader'
import { useTranslation } from 'next-i18next'
import { HeaderStepsBar } from '../../components/UI/ProgressBar/HeaderStepsBar'
import { useCheckoutSteps } from '../../hooks/useCheckoutSteps'
import paymentInstructionService from '../../foundation/apis/transaction/paymentInstruction.service'
import { useReturnOrderSteps } from '@hooks/useReturnOrderSteps'
import { usePathname } from 'next/navigation'

const Header: React.FC = () => {
  const { pageType } = usePageType()
  const { t } = useTranslation()
  const checkoutSteps = useCheckoutSteps()
  const returnSteps = useReturnOrderSteps()
  let DynamicHeader: JSX.Element | null = null
  const pathname = usePathname()

  const handlePreviousCheckoutStepClick = async () => await paymentInstructionService.deleteAllPaymentInstructions()

  // TODO: implement a function to handle previous step click on returns flow
  const handlePreviousReturnStepClick = () => {
    return
  }

  switch (pageType) {
    case 'checkout':
      if (pathname?.indexOf('order-confirmation') === -1) {
        DynamicHeader = (
          <ReducedHeader title={t('Header.Reduced.Checkout')}>
            <HeaderStepsBar stepState={checkoutSteps} handlePreviousStepClick={handlePreviousCheckoutStepClick} />
          </ReducedHeader>
        )
      }
      break
    case 'orderReturnSelect':
    case 'orderReturnConfirm':
    case 'orderReturnPrint':
      DynamicHeader = (
        <ReducedHeader title={t('Header.Reduced.Return')}>
          <HeaderStepsBar stepState={returnSteps} handlePreviousStepClick={handlePreviousReturnStepClick} />
        </ReducedHeader>
      )
      break
    default:
      DynamicHeader = null
  }

  return <>{DynamicHeader === null ? <DefaultHeader /> : DynamicHeader}</>
}

export default Header
