import { CheckoutPayload } from '@typesApp/checkout'
import Axios, { Canceler } from 'axios'
import ReorderSummary from '../reorder-summary/ReorderSummary'
import getDisplayName from 'react-display-name'
import { orderApi } from '@features/order/query'
import Log from '@services/Log'
import { cartSelector, orderItemsSelector, reorderInfoSelector } from '@features/order/selector'
import { useSelector } from 'react-redux'
import PaymentMethods from '@views/PaymentMethods'
import { useStoredPaymentDetails } from '@hooks/useStoredPaymentDetails'
import { Contact } from '@typesApp/user'
import { userDetailsSelector } from '@redux/selectors/user'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import * as ROUTES from '../../../constants/routes'
import { useTranslation } from 'next-i18next'
import { currentContractIdSelector } from '@redux/selectors/contract'
import { useRouter } from 'next/navigation'
import { useSite } from '@foundation/hooks/useSite'
import React from 'react'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { REORDER_BILLING_ADDRESS_ID } from '@foundation/constants/common'
import { isValidOrderItemsInReorder } from '@utils/order'
import { BILLING_ADDRESS_ID } from '@constants/checkout'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'

export const useReorder = (setReorderBusy?: React.Dispatch<React.SetStateAction<boolean>>) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const router = useRouter()
  const { country: storeCountry } = useStoreIdentity()
  const { completeCheckoutStep } = useCheckoutSteps()

  const cart = useSelector(cartSelector)
  const contractId = useSelector(currentContractIdSelector)
  const userDetails = useSelector(userDetailsSelector)
  const reorderInfo = useSelector(reorderInfoSelector)
  const orderItems = useSelector(orderItemsSelector)

  const [deleteAllPaymentInstructions] = orderApi.endpoints.deleteAllPaymentInstructions.useLazyQuery()
  const [addPaymentInstruction] = orderApi.endpoints.addPaymentInstruction.useLazyQuery()

  const cardList = useStoredPaymentDetails(PaymentMethods)
  const contactListFiltered: Contact[] = (userDetails?.contact || []).filter(
    contact =>
      contact.country?.toLowerCase() === storeCountry?.toLowerCase() && contact.addressType?.toLowerCase() !== 'billing'
  )

  const availablePaymentMethod = cardList.find(wallet => wallet.isDefault === true)
  const isShippingAddressAvailable = contactListFiltered?.length > 0
  const isBillingAddressAvailable =
    !!reorderInfo?.billingAddressId ||
    !!localStorageUtil.get(REORDER_BILLING_ADDRESS_ID) ||
    !!localStorageUtil.get(BILLING_ADDRESS_ID)

  const ctaTexts = [
    {
      text: t('ReorderSummary.PayAndConfirm'),
      link: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`,
    },
    { text: t('ReorderSummary.ProceedToPayment'), link: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.PAYMENT}` },
    { text: t('ReorderSummary.ProceedToShipping'), link: `/${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_CHILDREN.SHIPPING}` },
    { text: t('ReorderSummary.ShopOtherProducts'), link: `/${ROUTES.HOME}` },
  ]

  const cta = isValidOrderItemsInReorder(reorderInfo, orderItems)
    ? availablePaymentMethod && isShippingAddressAvailable && isBillingAddressAvailable
      ? ctaTexts[0]
      : !availablePaymentMethod && isShippingAddressAvailable && isBillingAddressAvailable
        ? ctaTexts[1]
        : ctaTexts[2]
    : ctaTexts[3]

  const proceedCheckout = async () => {
    /* Proceed to either place order or redirect to Shipping page or Payment page
       based on availablePaymentMethod && isShippingAddressAvailable
    */
    if (isValidOrderItemsInReorder(reorderInfo, orderItems) && availablePaymentMethod && isShippingAddressAvailable) {
      try {
        setReorderBusy && setReorderBusy(true)
        const cancels: Canceler[] = []
        const payloadBase: CheckoutPayload = {
          currency: mySite?.defaultCurrencyID || '',
          contractId: contractId,
          storeId: mySite.storeID,
          widget: getDisplayName(ReorderSummary),
          cancelToken: new Axios.CancelToken(function executor(c) {
            cancels.push(c)
          }),
        }
        await deleteAllPaymentInstructions(payloadBase)
        await addPaymentInstruction({
          ...payloadBase,
          body: {
            billing_address_id:
              reorderInfo?.billingAddressId ||
              (localStorageUtil.get(REORDER_BILLING_ADDRESS_ID) ?? localStorageUtil.get(BILLING_ADDRESS_ID)),
            cc_brand: availablePaymentMethod.issuer,
            createWallet: 'false',
            payMethodId: availablePaymentMethod.payMethodId,
            piAmount: cart?.grandTotal,
            walletId: '' + availablePaymentMethod.identifier,
          },
        })
      } catch (e: any) {
        Log.error('PAYMENT INIT ERROR: ' + e, window.location.href)
      } finally {
        setReorderBusy && setReorderBusy(false)
      }
    }
    completeCheckoutStep('reorderSummary')
    router.push(cta.link)
  }
  return {
    reorderInfo,
    availablePaymentMethod,
    cta,
    proceedCheckout,
  }
}
