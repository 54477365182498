import styled from '@mui/material/styles/styled'
import { cartDesktopBreakpoint } from '../../../constants/checkout'

export const StyledCartReassuranceContainer = styled('div', {
  name: 'CartReassurance',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '18px',
  marginLeft: '-16px',
  marginRight: '-16px',
  [cartDesktopBreakpoint(theme)]: {
    margin: '10px 0 0',
  },
}))
