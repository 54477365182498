import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Order } from '../../../types/order'
import OrderService from '../../../foundation/apis/transaction/order.service'
import {
  OrderStatusPrescriptionLabel,
  OrderStatusShippedLabel,
  OrderStatusContainer,
  StyledAnchorCTA,
  StyledText,
} from './OrderStatusInfo.style'
import { useSite } from '@foundation/hooks/useSite'
import { downloadInvoice, isOrderInvoiceAvailable } from '@features/orderhistory/utils'
import { StyledAnchor } from '@components/UI/Anchor'
import { Loader } from '@components/UI/Loader'

export interface OrderStatusInfoProps {
  order: Order
}

export const OrderStatusInfo: React.FC<OrderStatusInfoProps> = ({ order }) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { orderStatus, orderExtendAttribute } = order
  const [isDownloading, setDownloading] = useState(false)

  const trackingNumbers = OrderService.getTrackingNumbers(orderExtendAttribute)
  const isInvoiceAvailable = isOrderInvoiceAvailable(order)
  const isPendingPrescriptionStatus = OrderService.isPendingPrescription(order)
  const isShippedStatus = OrderService.isShipped(order)
  const isCompletedStatus = OrderService.isCompleted(order)
  const showTrackingNumber = trackingNumbers?.length > 0 && !OrderService.isShippedSettled(order)

  const renderStatusLabel = (): JSX.Element => {
    if (isPendingPrescriptionStatus) {
      return <OrderStatusPrescriptionLabel>{t(`Order.Status_.${orderStatus}`)}</OrderStatusPrescriptionLabel>
    }

    if (isShippedStatus) {
      return (
        <OrderStatusShippedLabel>
          <StyledText>{t(`Order.Status_.${orderStatus}`)}</StyledText>
          {showTrackingNumber && (
            <StyledText>{t('Order.TrackingNumber', { value: trackingNumbers.join(', ') })}</StyledText>
          )}
        </OrderStatusShippedLabel>
      )
    }

    if (isCompletedStatus) {
      return <OrderStatusShippedLabel>{t('Order.StateCompleted')}</OrderStatusShippedLabel>
    }

    return <StyledText isBold>{t(`Order.Status_.${orderStatus}`)}</StyledText>
  }

  if (isDownloading) {
    return <Loader />
  }

  return (
    <OrderStatusContainer>
      {renderStatusLabel()}

      {isPendingPrescriptionStatus && (
        <StyledAnchorCTA href={`mailto:${t('OrderDetails.Labels.CustomerServiceEmail')}`}>
          {t('Order.PendingPrescriptionCta')}
        </StyledAnchorCTA>
      )}

      {isInvoiceAvailable && (
        <StyledAnchor
          href={`#orderItem_${order.orderId}`}
          onClick={async () => {
            setDownloading(true)
            await downloadInvoice(mySite.storeId, order.orderId)
            setDownloading(false)
          }}
        >
          {t('OrderDetails.Actions.DownloadInvoice')}
        </StyledAnchor>
      )}
    </OrderStatusContainer>
  )
}
