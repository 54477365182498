import { isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import React, { FC } from 'react'
import {
  cartSelector,
  catentriesSelector,
  orderCompleteSelector,
  orderItemsSelector,
} from '../../../features/order/selector'

import CartReassuranceContainer from '../../Cart/components/CartReassuranceContainer'
import { OrderRecap } from '../../../components/order-recap'
import { getFormatterHclPageCartData } from '../../../features/cms/selector'
import { getOrderItemsAttachments } from '../../../constants/order'
import { getParsedOrderRecapItems } from '../../../utils/order'
import { useAppSelector } from '../../../hooks/redux'
import { useSelector } from 'react-redux'

const CheckoutSidebar: FC<{
  shippingZipCode?: string
}> = ({ shippingZipCode }) => {
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)
  const orderComplete = useSelector(orderCompleteSelector)
  const catEntries = useAppSelector(catentriesSelector)
  const orderItemsAttachments = getOrderItemsAttachments(orderItems, catEntries)
  const { cartCheckoutBenefitArea } = useSelector(getFormatterHclPageCartData('cart'))

  const item = cartCheckoutBenefitArea?.items?.find(isCMCollection)
  const teasableItems: ILXTeaser[] = item?.teasableItems?.filter(isLXTeaser) ?? []

  const parsedOrderItems = getParsedOrderRecapItems(orderItems)

  return (
    <div>
      {cart && (
        <OrderRecap
          order={cart}
          orderItems={parsedOrderItems}
          orderItemsAttachments={orderItemsAttachments}
          isOrderComplete={orderComplete}
          shippingZipCode={shippingZipCode}
        />
      )}
      {teasableItems && <CartReassuranceContainer />}
    </div>
  )
}

export default CheckoutSidebar
