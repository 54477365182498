import React from 'react'
import { TooltipWithStyle } from './Tooltip.style'
import type { ContentVariant, CustomTooltipProps } from './Tooltip.type'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const ContentVariantHandler = (props: ContentVariant) => {
  switch (props.type) {
    case 'textonly':
      return (
        <div className="popover-body-container">
          <p className="popover-body-container__body">{props.body}</p>
        </div>
      )

    case 'title+text':
      return (
        <div className="popover-body-container">
          <h3 className="popover-body-container__title">{props.title}</h3>
          <p className="popover-body-container__body">{props.body}</p>
        </div>
      )

    case 'storelocator':
      return (
        <div className="popover-body-container">
          <div className="popover-body-container__button">
            {props.vectorColor === 'white' ? (
              <SVGIcon library="close" name="thin-black" size={16} />
            ) : (
              <SVGIcon library="close" name="thin-white" size={16} />
            )}
          </div>
          <h3 className="popover-body-container__storeTitle">{props.title}</h3>
          <p className="popover-body-container__body popover-body-container__body--spacing-up">{props.body}</p>
          {/* <div className="popover-body-container--footer-container">
            <span className="popover-body-container--footer-container__phone">
              {props.phone}
            </span>
            <span className="popover-body-container--footer-container__cta">
              {props.cta}
            </span>
          </div> */}
        </div>
      )
  }
}

export const StyledTooltip = ({
  contentVariant,
  text,
  disablePortal,
  children,
  ...props
}: Omit<CustomTooltipProps, 'title'>) => {
  return (
    <TooltipWithStyle
      arrow
      disablePortal={disablePortal}
      title={<ContentVariantHandler {...contentVariant} />}
      {...props}
      text={text}
      type={contentVariant.type}
    >
      {children}
    </TooltipWithStyle>
  )
}
