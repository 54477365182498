import {
  MONETATE_ADD_CART_ROWS,
  MONETATE_ADD_PRODUCT_DETAILS,
  MONETATE_ADD_PRODUCTS,
  MONETATE_ADD_PURCHASE_ROWS,
  MONETATE_PAGE_TYPE_MAP,
  MONETATE_PURCHASE,
  MONETATE_SET_PAGE_TYPE,
  MONETATE_TRACK_DATA,
} from './monetateConstants'
import Log from '../../services/Log'
import { IProduct } from '../../types/product'
import { OrderItem } from '../../types/order'
import { unionBy } from 'lodash-es'

export interface ProductForMonetate {
  productId?: string
  sku?: string
  quantity?: string
  unitPrice?: string
  currency?: string
}

interface MonetatePropTypes {
  pageType: string | undefined
  productIds: string[]
  productsInCart: ProductForMonetate[]
}

interface MonetateAddToBagType extends Omit<MonetatePropTypes, 'productIds'> {
  currentProduct: IProduct
}

interface MonetateTrackSingleProductType {
  pageType: string | undefined
  partNumber: string
  productId: string
}

/*******************************************************************************
 * Monetate Helpers *
 */

export function monetateMapProductsInCart(productsInCart?: OrderItem[]) {
  let mappedProducts = productsInCart?.map(item => {
    return {
      productId: item.partNumber,
      quantity: item.quantity,
      unitPrice: item.orderItemPrice,
      currency: item.currency,
    }
  })
  return mappedProducts || []
}

function sanitizeSku(sku) {
  if (sku === undefined) {
    return ''
  }
  return sku.replace(/ /g, '_')
}

function mapPurchaseRows(orderId, productsInCart: ProductForMonetate[]) {
  const purchaseRows = productsInCart.map(product => {
    return {
      ...product,
      purchaseId: orderId,
    }
  })
  return purchaseRows
}

function monetateMergeProductsInCart(productsInCart: ProductForMonetate[]): ProductForMonetate[] {
  const mergedProducts = unionBy(productsInCart, 'productId').map(item => {
    const found = productsInCart.filter(temp => {
      return temp.productId === item.productId
    })?.length
    item.quantity = String(found)
    return item
  })
  return mergedProducts
}

function getMonetatePageType(pageType?: string): string {
  if (!pageType) {
    return MONETATE_PAGE_TYPE_MAP.default
  }
  return MONETATE_PAGE_TYPE_MAP[pageType]
}

export function getMonetate() {
  window.monetateQ = window?.monetateQ ?? []
  return window.monetateQ
}

export function sendMonetateEvent() {
  getMonetate()?.push([MONETATE_TRACK_DATA])
}

/*******************************************************************************
 * Monetate event functions *
 */

export function monetateSendPageType(pageType?: string) {
  const M = getMonetate()
  const pagesToSend = ['home', 'search', 'contactUs', 'wishlist', 'storeLocator', 'pdp']
  try {
    if (pageType && !pagesToSend.includes(pageType)) return
    const monetatePageType = getMonetatePageType(pageType)
    M.push([MONETATE_SET_PAGE_TYPE, monetatePageType])
    sendMonetateEvent()
  } catch (e) {
    Log.error('MONETATE TRACK PAGE ERROR: ' + e)
  }
}

export function monetateAddToBag({ currentProduct, pageType, productsInCart }: MonetateAddToBagType) {
  try {
    monetateTrackSingleProduct({
      pageType,
      partNumber: currentProduct.partNumber,
      productId: currentProduct.uniqueID || currentProduct.id || '',
    })
    monetateTrackCart({ pageType, productsInCart })
  } catch (e) {
    Log.error('MONETATE ADD TO BAG TRACK ERROR: ' + e)
  }
}

export function monetateTrackSingleProduct({ partNumber, pageType, productId }: MonetateTrackSingleProductType) {
  try {
    const M = getMonetate()
    const monetatePageType = getMonetatePageType(pageType)
    const sanitizedPartNumber = sanitizeSku(partNumber)
    const currentProduct: ProductForMonetate = {
      productId: productId,
      sku: sanitizedPartNumber,
    }
    M.push([MONETATE_ADD_PRODUCT_DETAILS, [currentProduct]])
    M.push([MONETATE_SET_PAGE_TYPE, monetatePageType])
    Log.info('MONETATE SINGLE PRODUCT: ' + JSON.stringify(currentProduct))
  } catch (e) {
    Log.error('MONETATE SINGLE PRODUCT TRACK ERROR: ' + e)
  }
}

export function monetateTrackProducts({ productIds, pageType }: Omit<MonetatePropTypes, 'productsInCart'>) {
  try {
    const M = getMonetate()
    const monetatePageType = getMonetatePageType(pageType)
    M.push([MONETATE_ADD_PRODUCTS, productIds])
    M.push([MONETATE_SET_PAGE_TYPE, monetatePageType])
    Log.info('MONETATE PRODUCTS' + JSON.stringify(productIds))
  } catch (e) {
    Log.error('MONETATE PRODUCTS TRACK ERROR: ' + e)
  }
}

export function monetateTrackPurchase({
  productsInCart,
  purchaseId,
}: {
  productsInCart: ProductForMonetate[]
  purchaseId: string
}) {
  try {
    const M = getMonetate()
    productsInCart = monetateMergeProductsInCart(productsInCart)
    const purchaseRows = mapPurchaseRows(purchaseId, productsInCart)
    M.push([MONETATE_ADD_PURCHASE_ROWS, purchaseRows])
    M.push([MONETATE_SET_PAGE_TYPE, MONETATE_PURCHASE])
    Log.info('MONETATE PURCHASE: ' + JSON.stringify(purchaseRows))
    sendMonetateEvent()
  } catch (e) {
    Log.error('MONETATE PURCHASE TRACK ERROR: ' + e)
  }
}

export function monetateTrackCart({ pageType, productsInCart }: Omit<MonetatePropTypes, 'productIds'>) {
  try {
    const M = getMonetate()
    const products = monetateMergeProductsInCart(productsInCart)
    const mappedPageType = getMonetatePageType(pageType)
    M.push([MONETATE_ADD_CART_ROWS, products])
    M.push([MONETATE_SET_PAGE_TYPE, mappedPageType])
    Log.info('MONETATE CART: ' + JSON.stringify({ products, pageType }))
    sendMonetateEvent()
  } catch (e) {
    Log.error('MONETATE TRACK CART ERROR: ' + e)
  }
}

/**
 * Monetate page-specific events
 */
interface MonetateTrackPdpType extends MonetateTrackSingleProductType {
  productsInCart: ProductForMonetate[]
}

interface MonetateTrackPlpType extends Omit<MonetatePropTypes, 'productsInCart'> {
  productsInCart?: ProductForMonetate[]
}

export function monetateTrackPdp({ pageType, partNumber, productId, productsInCart }: MonetateTrackPdpType) {
  monetateTrackSingleProduct({ partNumber, pageType, productId })
  monetateTrackProducts({ pageType, productIds: [productId] })
  monetateTrackCart({ pageType, productsInCart })
}

export function monetateTrackPlp({ productIds, productsInCart = [], pageType }: MonetateTrackPlpType) {
  monetateTrackProducts({ pageType, productIds })
  monetateTrackCart({ pageType, productsInCart })
}
