import { FormControlLabel, styled, Typography } from '@mui/material'
import { Button } from '../../../../components/UI'

export const SubscriptionFormContainer = styled('div', {
  name: 'Subscription',
  slot: 'Container'
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const SubscriptionTitle = styled(Typography, {
  name: 'Subscription',
  slot: 'Title'
})(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(4),
}))

export const SubscriptionStatusText = styled(Typography, {
  name: 'Subscription',
  slot: 'StatusText'
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

export const SubscriptionButton = styled(Button, {
  name: 'Subscription',
  slot: 'Button',
})(() => ({
  transform: 'none',
  marginLeft: 0,
  marginRight: 0,
}))

export const SubscriptionFormControlLabel = styled(FormControlLabel, {
  name: 'Subscription',
  slot: 'CheckboxFormControlLabel'
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
  marginRight: 0,
  alignItems: 'flex-start',

  '& .MuiCheckbox-root': {
    paddingTop: theme.spacing(1)
  }
}))

export const SubscriptionButtonContainer = styled('div', {
  name: 'Subscription',
  slot: 'ctaContainer'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))
