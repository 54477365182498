import styled from '@mui/material/styles/styled'

export const LoginContainer = styled('div', {
  name: 'Login',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
  // [theme.breakpoints.up('sm')]: {
  //   padding: `${theme.spacing(8)} ${theme.spacing(16)}`,
  // },
}))

export const FastLoginContainer = styled('div', {
  name: 'FastLogin',
  slot: 'Container',
})(({ theme }) => ({
  height: theme.spacing(6),
  alignSelf: 'stretch',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
  padding: 0,
}))

export const FastLoginContent = styled('span', {
  name: 'FastLogin',
  slot: 'Content',
})(({ theme }) => ({
  flexGrow: 0,
  fontSize: 16,
  lineHeight: 1.5,
  color: theme.palette.background.dark.primary,
}))

export const LineDiv = styled('div', {
  name: 'SignIn',
  slot: 'LineDiv',
})(({ theme }) => ({
  height: 1,
  flexGrow: 1,
  backgroundColor: theme.palette.background.dark.primary,
}))
