import { CheckoutPayload, PaymentInfoType } from '../../../types/checkout'

import { BILLING_ADDRESS_ID } from '../../../constants/checkout'
import { EMPTY_STRING } from '../../../constants/common'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import { useExpiryDate } from './useExpiryDate'
import { useSite } from '../../../foundation/hooks/useSite'
import { IS_REORDER_SUCCESS, REORDER_BILLING_ADDRESS_ID } from '@foundation/constants/common'

export interface UsePaymentCCardInfoReturn {
  getUserPaymentCCardInfo: (
    selectedPaymentInfoList: PaymentInfoType[],
    isZeroCheckout?: boolean,
    createWallet?: boolean
  ) => CheckoutPayload['body']
}

export const usePaymentCCardInfo = (): UsePaymentCCardInfoReturn => {
  const { parseExpiryDate } = useExpiryDate()
  const { mySite } = useSite()

  const getUserPaymentCCardInfo = (
    selectedPaymentInfoList: PaymentInfoType[],
    isZeroCheckout?: boolean,
    createWallet?: boolean
  ): CheckoutPayload['body'] => {
    const selectedPaymentMethod = selectedPaymentInfoList[0]
    const payMethod = selectedPaymentMethod?.payMethodId

    let paymentAddInfoBody: CheckoutPayload['body'] = {
      piAmount: selectedPaymentMethod?.piAmount,
      billing_address_id:
        localStorageUtil.get(IS_REORDER_SUCCESS) === true
          ? localStorageUtil.get(REORDER_BILLING_ADDRESS_ID) ?? localStorageUtil.get(BILLING_ADDRESS_ID)
          : localStorageUtil.get(BILLING_ADDRESS_ID),
      payMethodId: isZeroCheckout ? 'Zero' : payMethod,
    }

    if (selectedPaymentMethod.paymentTermConditionId === '') {
      const expiryDate = parseExpiryDate(selectedPaymentMethod.creditCardFormData.expiration_date)

      paymentAddInfoBody = {
        ...paymentAddInfoBody,
        cc_account:
          selectedPaymentMethod.creditCardFormData.cc_account &&
          selectedPaymentMethod.creditCardFormData.cc_account.trim(),
        cc_nameoncard: selectedPaymentMethod.creditCardFormData.cc_nameoncard?.trim(),
        expire_month: expiryDate.month,
        expire_year: expiryDate.year,
        cc_cvc:
          selectedPaymentMethod.creditCardFormData.cc_cvc && selectedPaymentMethod.creditCardFormData.cc_cvc.trim(),
        cc_brand: isZeroCheckout ? 'Zero' : payMethod,
        walletId: selectedPaymentMethod.creditCardFormData.walletId,
        currency: mySite.defaultCurrencyID,
        createWallet: createWallet?.toString() || 'false',
      }
    } else {
      paymentAddInfoBody = {
        ...paymentAddInfoBody,
        cc_account: EMPTY_STRING,
        cc_nameoncard: EMPTY_STRING,
        cc_cvc: EMPTY_STRING,
        expire_month: EMPTY_STRING,
        expire_year: EMPTY_STRING,
        cc_brand: payMethod,
        valueFromPaymentTC: 'true',
        paymentTermConditionId: selectedPaymentMethod.paymentTermConditionId,
        valueFromProfileOrder: '',
        orderId: '.',
      }
    }

    return paymentAddInfoBody
  }

  return {
    getUserPaymentCCardInfo,
  }
}
