import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TextField } from '../TextField'
import { FormControlHelperText } from './FormControlHelperText'
import { FormControlProps } from './types'

export const FormControlTextField: React.FC<FormControlProps> = ({
  id,
  name,
  rules,
  label,
  placeholder,
  description,
  inputProps,
  customInputProps,
  inputRegex,
  disabled,
  select,
  children
}) => {
  const { control } = useFormContext()
  const required = rules ? (rules['required']?.value === true || rules['required'] === true) : undefined

  const handleOnChange = (originalHandler: (e) => void, e) => {
    if (inputRegex && inputRegex.test(e.target.value)) {
      originalHandler(e)
    } else if (!inputRegex) {
      originalHandler(e)
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, ref, onChange, ...rest }, fieldState: { error } }) => {
        return (
        <>
          <TextField
            {...rest}
            id={id}
            inputRef={ref}
            value={value}
            select={select}
            fullWidth
            label={`${label}${required ? ' *': ''}`}
            placeholder={placeholder}
            error={!!error}
            showvalidationstatus={true}
            isvalid={!error && !!value?.length}
            ispristine={!value?.length}
            inputProps={inputProps}
            customInputProps={customInputProps}
            disabled={disabled}
            onChange={(e) => handleOnChange(onChange, e)}
          >
            {children}
          </TextField>
          <FormControlHelperText description={description} error={error} />
        </>
        )
      }
      }
    />
  )
}
