import { OrderUnavailableItem, UnavailableContactLensesBundled } from '@typesApp/order'
import { isContactLensesProduct } from '@utils/product'

/**
 * Get contact lenses order items bundled (main + sibling).
 * The sibling order item will have a property called `siblingOrderItemId` that refers to the other eye item.
 * This is applicable to Reorder API only.
 *
 * @param unavailableItems Unbundled unavailable order items
 * @returns Bundled unavailable contact lenses order items
 */
export const getUnavailableContactLensesBundled = (
  unavailableItems?: OrderUnavailableItem[]
): UnavailableContactLensesBundled[] => {
  const unavailableContactLenses = unavailableItems?.filter(unavailableItem => isContactLensesProduct(unavailableItem))
  const unavailableContactLensesBundled: UnavailableContactLensesBundled[] = []
  const siblingsFound: OrderUnavailableItem[] = []
  unavailableContactLenses?.forEach(unavailableContactLens => {
    const sibling = unavailableContactLenses.find(unavailableContactLens => unavailableContactLens.siblingOrderItemId)
    sibling && siblingsFound.push(sibling)
    !siblingsFound.includes(unavailableContactLens) &&
      unavailableContactLensesBundled.push({ main: unavailableContactLens, sibling: sibling })
  })
  return unavailableContactLensesBundled
}

/**
 * Get unavailable CL accessory items.
 *
 * @param unavailableItems All unavailable order items
 * @returns Unavailable CL accessory items
 */
export const getUnavailableContactLensAccessories = (
  unavailableItems?: OrderUnavailableItem[]
): OrderUnavailableItem[] | undefined => {
  return unavailableItems?.filter(unavailableItem => !isContactLensesProduct(unavailableItem))
}
