import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const BaseLayoutSkeleton = () => {
  return (
    <div className="_skeleton">
      <SkeletonTheme baseColor="transparent" highlightColor={'rgba(0, 0, 0, 0.1)'}>
        <Skeleton width="100%" height="100vh" />
      </SkeletonTheme>
    </div>
  )
}

export default BaseLayoutSkeleton
