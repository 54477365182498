import React, { useEffect } from 'react'
import { SubscriptionErrorBox } from '../../Subscription.style'
import { useTranslation } from 'next-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { cartProductCountSelector, catentriesSelector, orderItemsSelector } from '@features/order/selector'
import { getSubscribedItems } from '../../helpers/subscriptionHelpers'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { sendCartSubscriptionErrorEvent } from '@foundation/analytics/tealium/lib'
import { SUBSCRIPTION_ERROR_CODES } from '@views/Subscription/constants'

export const SubscriptionsErrorBox: React.FC = () => {
  const { t: translate } = useTranslation()
  const catEntries = useSelector(catentriesSelector, shallowEqual)
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const orderItems = useSelector(orderItemsSelector)
  const cartItemsCount = useSelector(cartProductCountSelector)
  const hasSubscribedItems = !!getSubscribedItems(orderItems).length
  const multipleItemsError = cartItemsCount > 1 && hasSubscribedItems
  const hasErrors = isSubscriptionEnabled && multipleItemsError
  const errorLine1 = translate('Subscriptions.Errors.MultipleSubscriptions.Line1')
  const errorLine2 = translate('Subscriptions.Errors.MultipleSubscriptions.Line2')

  const analyticsData = useAnalyticsData(ANALYTICS_PAGE_TYPE.CART)
  useEffect(() => {
    if (catEntries !== null && hasErrors && cartItemsCount) {
      sendCartSubscriptionErrorEvent({
        common: analyticsData,
        errorCode: SUBSCRIPTION_ERROR_CODES.INVALID_CART,
        errorDetails: errorLine1,
        errorSource: 'User',
        errorMessage: `${errorLine1} ${errorLine2}`,
      })
    }
  }, [catEntries, hasErrors, cartItemsCount])

  return (
    (hasErrors && (
      <SubscriptionErrorBox>
        <div>{errorLine1}</div>
        <div>{errorLine2}</div>
      </SubscriptionErrorBox>
    )) ||
    null
  )
}
