import React, { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Modal } from '@mui/material'

import { RemoveCreditCardDialog } from '../../../components/RemoveCreditCardDialog'

import {
  PaymentMethodContent,
  PaymentMethodButtonWrapper,
  PaymentMethodCustomerName,
  PaymentMethodLineBreak,
  RemoveButtonWrapper,
  StyledSecondaryText,
  PaymentMethodBoxBox,
  PaymentMethodBoxWrapper,
  RemovePaymentMethodModalContainer,
  RemoveLinkButton,
  PaymentMethodFormWrapper,
  CreditCardDisplayWrapper,
} from '../PaymentMethod.style'
import { CreditCardFormData } from '../../../types/form'
import { StyledFormControlLabel } from '../../../components/UI/Checkbox/StyledFormControlLabel'
import { StyledRadio } from '../../../components/UI/Checkbox/StyledRadio'
import { Card } from '../../../types/user'
import DateService from '../../../services/DateService'
import { CardProviderIcon } from '../../../components/UI/CreditCardField'
import { useSite } from '@foundation/hooks/useSite'

export interface IProps {
  creditCard: Card
  updateCreditCard: (creditCard: CreditCardFormData) => void
}

const PaymentMethodBox: FC<IProps> = ({ creditCard, updateCreditCard }) => {
  const [isRemoveCreditCardDialogOpen, setIsRemoveCreditCardDialogOpen] = useState<boolean>(false)
  const handleRemoveCreditCardOpen = () => setIsRemoveCreditCardDialogOpen(true)
  const handleRemoveCreditCardClose = () => setIsRemoveCreditCardDialogOpen(false)

  const { t: translate } = useTranslation('', { keyPrefix: 'PaymentMethod' })
  const { mySite } = useSite()

  return (
    <PaymentMethodContent key={creditCard.cardNumber}>
      <Modal
        open={isRemoveCreditCardDialogOpen}
        onClose={handleRemoveCreditCardClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RemovePaymentMethodModalContainer>
          <RemoveCreditCardDialog userCreditCard={creditCard} handleClose={handleRemoveCreditCardClose} />
        </RemovePaymentMethodModalContainer>
      </Modal>
      <PaymentMethodFormWrapper>
        <RemoveButtonWrapper>
          <StyledSecondaryText>{translate('CreditCard')}</StyledSecondaryText>
          <PaymentMethodButtonWrapper>
            <RemoveLinkButton
              data-element-id={creditCard && 'X_X_CreditCard_Remove'}
              onClick={handleRemoveCreditCardOpen}
            >
              {translate('RemoveButton')}
            </RemoveLinkButton>
          </PaymentMethodButtonWrapper>
        </RemoveButtonWrapper>
        <PaymentMethodLineBreak />
        <PaymentMethodBoxWrapper>
          {creditCard && (
            <PaymentMethodBoxBox>
              <PaymentMethodCustomerName>{creditCard?.cardHolderName}</PaymentMethodCustomerName>
              <CreditCardDisplayWrapper>
                <CardProviderIcon cardNumber={creditCard?.cardNumber} />
                <StyledSecondaryText>
                  {translate('EndsWith')}
                  {creditCard?.cardNumber.substring(creditCard?.cardNumber.length - 4)}
                </StyledSecondaryText>
              </CreditCardDisplayWrapper>
              <StyledSecondaryText>
                {translate('ExpiresIn')} {DateService.format(creditCard?.expiryDate, 'MM/yy', mySite.locale)}
              </StyledSecondaryText>
            </PaymentMethodBoxBox>
          )}
        </PaymentMethodBoxWrapper>
        <StyledFormControlLabel
          control={<StyledRadio />}
          label={creditCard.isDefault ? translate('DefaultCreditCardMsg') : translate('SetAsDefaultCreditCardMsg')}
          checked={creditCard.isDefault}
          onChange={() => {
            const defaultCreditCard = { ...creditCard, isDefault: true }
            updateCreditCard(defaultCreditCard)
          }}
        />
      </PaymentMethodFormWrapper>
    </PaymentMethodContent>
  )
}

export default PaymentMethodBox
