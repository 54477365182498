import React, { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { GridContainer, GridItem } from '../../../components/UI'
import { EMAIL_VALIDATION_PATTERN, FormControlMaskTextField, FormControlTextField } from '../../../components/UI/Form'
import { creditCardFieldValidationConfig } from '../config/CreditCardFieldsConfig'
import { CardProviderIcon, useCardValidation } from '../../../components/UI/CreditCardField'
import Image from 'mui-image'
import { PaymentMethodSelectionCVVLink } from '../../Checkout/payment/Payment.style'
import config from '@configs/index'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { ALLOWED_COUNTRIES_WITH_THREE_LETTER_REGION } from '@constants/common'

export const CreditCardForm: FC = ({}) => {
  const { t } = useTranslation()
  const [showCVVHint, setShowCVVHint] = useState(false)
  const { isValidCardNumber, isValidCVV, isValidExpiryDate } = useCardValidation()
  const validationConfig = creditCardFieldValidationConfig()
  const cvvImgPath = `${config.publicUrl}images/common/cvv-code.png`
  const { getValues } = useFormContext()
  const { langCode } = useStoreIdentity()
  const regionRegex = ALLOWED_COUNTRIES_WITH_THREE_LETTER_REGION.includes(langCode) ? /^[a-zA-Z]{0,3}$/ : /^[a-zA-Z]{0,2}$/

  const onShowCVVHint = () => {
    setShowCVVHint(!showCVVHint)
  }

  const expiryDateMask: string[] = t('PaymentMethod.CreditCardForm.expiryDateMask', { returnObjects: true }) || [
    'M',
    'M',
    'Y',
    'Y',
  ]

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={8}>
          <FormControlTextField
            name="cardHolderName"
            label={t('PaymentMethod.CreditCardForm.Labels.cardHolderName') || ''}
            description={t('PaymentMethod.CreditCardForm.Labels.cardHolderName-description') || ''}
            rules={{
              required: {
                value: validationConfig.cardHolderName.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.cardHolderName'),
              },
              pattern: {
                value: validationConfig.cardHolderName.regExp,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.cardHolderName'),
              },
            }}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={8}>
          <FormControlMaskTextField
            name="cardNumber"
            label={t('PaymentMethod.CreditCardForm.Labels.cardNumber') || ''}
            inputProps={{ maxLength: 19 }}
            format="#### #### #### ####"
            invalidValuesRegexp={/\D/g}
            customInputProps={{
              endAdornment: <CreditCardAdornment />,
            }}
            rules={{
              required: {
                value: validationConfig.cardNumber.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.cardNumber'),
              },
              validate: (value: string) => {
                return isValidCardNumber(value) || t('PaymentMethod.CreditCardForm.Msgs.Invalid.cardNumber')
              },
            }}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={6} sm={4}>
          <FormControlMaskTextField
            name="expiryDate"
            label={t('PaymentMethod.CreditCardForm.Labels.expiryDate') || ''}
            description={t('PaymentMethod.CreditCardForm.Labels.expiryDate-description') || ''}
            format="## / ##"
            invalidValuesRegexp={/\D/g}
            rules={{
              required: {
                value: validationConfig.expiryDate.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.expiryDate'),
              },
              validate: (value: string) => {
                return (
                  isValidExpiryDate(value, expiryDateMask) || t('PaymentMethod.CreditCardForm.Msgs.Invalid.expiryDate')
                )
              },
            }}
          />
        </GridItem>
        <GridItem xs={!showCVVHint ? 6 : 4} sm={4}>
          <FormControlTextField
            name="cvv"
            label={t('PaymentMethod.CreditCardForm.Labels.cvv') || ''}
            description={
              <PaymentMethodSelectionCVVLink onClick={onShowCVVHint}>
                {t('PaymentMethod.CreditCardForm.Labels.cvvHelpText')}
              </PaymentMethodSelectionCVVLink>
            }
            inputProps={{ maxLength: 4 }}
            inputRegex={/^(\d{0,4})+$/}
            rules={{
              required: {
                value: validationConfig.cvv.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.cvv'),
              },
              pattern: {
                value: validationConfig.cvv.regExp,
                message: t('PaymentMethod.CreditCardForm.Msgs.Invalid.cvv'),
              },
              validate: (cvv: string) => {
                return isValidCVV(getValues('cardNumber'), cvv) || t('PaymentMethod.CreditCardForm.Msgs.Invalid.cvv')
              },
            }}
          />
        </GridItem>

        {showCVVHint && (
          <GridItem xs={2}>
            <Image src={cvvImgPath} height={52} width={72} alt={'CVV Hint'} />
          </GridItem>
        )}
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={8}>
          <FormControlTextField
            name="billingEmail"
            label={t('PaymentMethod.CreditCardForm.Labels.billingEmail') || ''}
            rules={{
              required: {
                value: validationConfig.billingEmail.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingEmail'),
              },
              pattern: {
                value: EMAIL_VALIDATION_PATTERN,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingEmail'),
              },
            }}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <FormControlTextField
            name="billingAddress"
            label={t('PaymentMethod.CreditCardForm.Labels.billingAddress') || ''}
            rules={{
              required: {
                value: validationConfig.billingAddress.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingAddress'),
              },
              pattern: {
                value: validationConfig.billingAddress.regExp,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingAddress'),
              },
            }}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={6}>
          <FormControlTextField
            name="billingCity"
            label={t('PaymentMethod.CreditCardForm.Labels.billingCity') || ''}
            rules={{
              required: {
                value: validationConfig.billingCity.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingCity'),
              },
              pattern: {
                value: validationConfig.billingCity.regExp,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingCity'),
              },
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6}>
          <FormControlTextField
            name="billingProvince"
            label={t('PaymentMethod.CreditCardForm.Labels.billingProvince') || ''}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            inputRegex={regionRegex}
            rules={{
              required: {
                value: validationConfig.billingProvince.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingProvince'),
              },
              pattern: {
                value: validationConfig.billingProvince.regExp,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingProvince'),
              },
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <FormControlTextField
            name="billingPostalCode"
            label={t('PaymentMethod.CreditCardForm.Labels.billingPostalCode') || ''}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            inputRegex={/^(\w{0,3})( )?(\w{0,3})$/}
            rules={{
              required: {
                value: validationConfig.billingPostalCode.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingPostalCode'),
              },
              pattern: {
                value: validationConfig.billingPostalCode.regExp,
                message: t('PaymentMethod.CreditCardForm.Msgs.Invalid.billingPostalCode'),
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlTextField
            name="billingCountry"
            label={t('PaymentMethod.CreditCardForm.Labels.billingCountry') || ''}
            disabled
            rules={{
              required: {
                value: validationConfig.billingCountry.required,
                message: t('PaymentMethod.CreditCardForm.Msgs.Empty.billingCountry'),
              },
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  )
}

const CreditCardAdornment: React.FC = () => {
  const cardNumber = useWatch({ name: 'cardNumber' })

  return <CardProviderIcon cardNumber={cardNumber} />
}
