import { useEffect } from 'react'
import { CommerceEnvironment } from '@constants/common'
import config from '@configs/config.base'
import { useSite } from '@foundation/hooks/useSite'
import Log from '@services/Log'
import { executeOnce } from '@utils/common'

const SCRIPT_ID = 'directBillingLoader'

export const useDirectBilling = () => {
  const { mySite } = useSite()
  const commerceLocale = mySite.locale.toLowerCase()
  const isDirectBillingEnabled = !!CommerceEnvironment.directInsuranceBilling[commerceLocale]?.enabled ?? false

  const loadScript = () => {
    return executeOnce(() => {
      const host = config.directBilling.host
      const url = `${host}${host.endsWith('/') ? '' : '/'}static/js/loader.js`

      const script = document.createElement('script')
      script.id = SCRIPT_ID
      script.type = 'text/javascript'
      script.async = true
      script.src = url

      document.body.appendChild(script)
    }, 'directbilling')()
  }

  useEffect(() => {
    if (isDirectBillingEnabled) {
      try {
        loadScript()
      } catch (e: any) {
        Log.error(`[DIRECTBILLING] LOAD SCRIPT ERROR:  ${e.message}`, window.location.href)
      }
    }
  }, [isDirectBillingEnabled])
}
