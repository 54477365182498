import queryString from 'query-string'
import config from '../configs/config.base'

import { Attachment } from '../types/product'
import { SiteInfo } from '@redux/rootReducer'

const IMAGE_404_URL = config.publicUrl + '/images/common/404.svg'
const QUARTER_IMG_DEFAULT_SEQUENCE = '2.0'

export const sortImageByConf = (images: Attachment[], sortArray: number[] | undefined) => {
  if (!Array.isArray(sortArray)) return images
  return images.sort((a, b) => {
    return sortArray.indexOf(parseInt(a.sequence)) - sortArray.indexOf(parseInt(b.sequence))
  })
}

export const parseAttachmentUrl = (site: SiteInfo, attachments: Attachment[]) => {
  const damDomain: string = site.xStoreCfg
    ? site.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain

  const attachment = attachments[0]

  const imagePath = attachment ? damDomain + attachment.attachmentAssetPathRaw : IMAGE_404_URL

  const imageUrl = queryString.stringifyUrl({
    url: imagePath.toString(),
  })

  return imageUrl
}

/*
When the product images contains the model image, it changes the quarter image position.
There will be no guarantee on the new positioningm but we can still and retrieve it based on the image identifier, which is present only
whent here are model images. If we cant find the image by the identifier, we fallback to the older way, what migh also not guaratee the positioning.
For a better solution, we would need to guarantee that the images positioning do not change  based on the total amount of images.
*/
export const getQuarterImageUrl = (site: SiteInfo, attachments: Attachment[], sortArray: number[] | undefined) => {
  const damDomain: string = site.xStoreCfg
    ? site.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain
  let attachment = attachments.find(attachment =>
    attachment.identifier ? attachment?.identifier?.indexOf('__qt') > -1 : false
  )

  if (!attachment) {
    attachment = attachments.find(attachment => attachment.sequence === QUARTER_IMG_DEFAULT_SEQUENCE)
  }

  const imagePath = attachment ? damDomain + attachment.attachmentAssetPathRaw : null

  const imageUrl = imagePath
    ? queryString.stringifyUrl({
        url: imagePath.toString(),
      })
    : parseAttachmentUrl(site, sortImageByConf(attachments, sortArray))

  return imageUrl
}
