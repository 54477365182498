import React, { ReactElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import { StyledCheckbox, StyledFormControlLabel } from '..'
export interface FormCheckboxFieldProps {
  name: string
  rules: object
  label: string | ReactElement
}

export const FormControlCheckboxField: React.FC<FormCheckboxFieldProps> = ({
  name,
  rules,
  label,
}) => {
  const { control } = useFormContext()
  
  return (
    <StyledFormControlLabel
      control={
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
            <>
              <StyledCheckbox
                {...rest}
                inputRef={ref}
                value={value}
                checked={value === true}
                error={!!error}
                sx={{ paddingLeft: 0} }
              />

              {label && <Box color={error ? 'custom.thunderBird': ''}>{label}</Box>}
            </>
          )}
        />
      }
      label={''}
      sx={{
        alignItems: 'flex-start',
        marginLeft: 0,
      }}
    />
  )
}
