import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { PaymentMethodType } from '@typesApp/checkout'
import { StyledPaymentMethodAdornmentLink } from '../../../payment/Payment.style'
import { PaypalTooltipModal } from './PaypalTooltipModal'
import { useSimplePaymentType } from './useSimplePaymentType'

export interface PaymentMethodAdornmentProps {
  paymentMethod: PaymentMethodType
}

export const PaymentMethodAdornment: React.FC<PaymentMethodAdornmentProps> = ({ paymentMethod }) => {
  const type = useSimplePaymentType(paymentMethod)
  switch (type) {
    case 'paypal':
      return <PayPalAdornmentLink />
    case 'klarna':
      return <KlarnaAdornmentLink />
    default:
      return null
  }
}

const PayPalAdornmentLink: React.FC = () => {
  const { t } = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false)
  const onOpenModal = () => setModalOpen(true)
  const onCloseModal = () => setModalOpen(false)

  return (
    <>
      <StyledPaymentMethodAdornmentLink onClick={onOpenModal}>
        {t('PaymentMethodSelection.Labels.WhatIsPayapal')}
      </StyledPaymentMethodAdornmentLink>

      <PaypalTooltipModal
        open={isModalOpen}
        onClose={onCloseModal}
      />
    </>
  )
}

const KlarnaAdornmentLink: React.FC = ({}) => {
  const { t } = useTranslation()

  return (
    <StyledPaymentMethodAdornmentLink>
      {t('PaymentMethodSelection.Labels.LearnMore')}
    </StyledPaymentMethodAdornmentLink>
  )
}
