// Standard libraries
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'

//UI
import {
  SaveCTA,
  SavePrescriptionWrapper,
  PrescriptionsContainer,
  PrescriptionsTitle,
  PrescriptionsWrapper,
  StyledEmptyMessage,
} from './Prescriptions.style'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
import { StyledAlert } from '../../components/UI'
import { StyledAlertHeader, StyledAlertMessage } from '../../components/UI/Alert'
import LoadingSkeleton from '../../components/LoadingSkeleton/LoadingSkeleton'
import { sortedPrescriptions } from '../../components/PrescriptionLenses/RxUtils'
import SavePrescriptionDialog from './SavePrescriptionDialog'
import DeletePrescriptionDialog from './DeletePrescriptionDialog'
import ViewRXDialog from './ViewRXDialog'
import { useGetPrescriptionsQuery } from '@features/prescription/query'
import { setOpenModalAddPrescription } from '@features/ui/action'
import { RenderPrescriptionContainer } from './PrescriptionInfoContainer'
import { sendMyAccountEvent } from '@foundation/analytics/tealium/lib'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const Prescriptions: FC = () => {
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showMessageContainer, setShowMessageContainer] = useState<boolean>(true)
  const [infoMessage, setInfoMessage] = useState<any>(null)
  const { data, isLoading, isFetching, isError, refetch } = useGetPrescriptionsQuery({})

  useEffect(() => {
    setShowMessageContainer(infoMessage !== null)
    refetch()
  }, [infoMessage])

  const { ...analyticsDataForMyAccount } = useAnalyticsData(ANALYTICS_PAGE_TYPE.PROFILE)
  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsDataForMyAccount,
      Page_Section2: 'Profile',
      Page_Section3: 'Prescriptions',
    })
  }, [])

  return (
    <>
      {showMessageContainer && (
        <StyledAlert
          onClose={() => setInfoMessage(null)}
          severity={infoMessage?.type || 'info'}
          sx={{ width: '100%' }}
          icon={
            infoMessage?.type === 'success' ? (
              <SVGIcon library="validation" name="validate" />
            ) : (
              <SVGIcon library="close" name="close-circle" />
            )
          }
        >
          <StyledAlertHeader>{infoMessage?.title}</StyledAlertHeader>
          <StyledAlertMessage fullWidth={true}> {infoMessage?.description}</StyledAlertMessage>
        </StyledAlert>
      )}
      <PrescriptionsContainer>
        <PrescriptionsTitle>{t('PrescriptionsSection.Title')}</PrescriptionsTitle>
        <PrescriptionsWrapper>
          {data?.recordCount == 0 ||
            (isError && <StyledEmptyMessage>{t('PrescriptionsSection.EmptyListMessage')}</StyledEmptyMessage>)}

          <SavePrescriptionWrapper>
            <SaveCTA
              fullwidth={isMobile}
              data-element-id={'X_X_Prescriptions_AddNew'}
              variant="primary"
              type="submit"
              onClick={() => dispatch(setOpenModalAddPrescription(true, null, setInfoMessage))}
            >
              {t('PrescriptionsSection.AddNewLabel')}
            </SaveCTA>
            <SavePrescriptionDialog />
            <DeletePrescriptionDialog />
            <ViewRXDialog />
          </SavePrescriptionWrapper>
          {(isLoading || isFetching) && <LoadingSkeleton />}
          {data && data.recordCount > 0
            ? sortedPrescriptions(data.Prescription).map(rx => (
                <RenderPrescriptionContainer
                  key={rx.nickName}
                  prescription={rx}
                  onDeleteAction={setInfoMessage}
                  onEditAction={setInfoMessage}
                />
              ))
            : null}
        </PrescriptionsWrapper>
      </PrescriptionsContainer>
    </>
  )
}
export default Prescriptions
