import styled from '@mui/material/styles/styled'

export const ChatButtonContainer = styled('button', {
  name: 'ChatButton',
  slot: 'Container',
})(() => ({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.custom.grey,
  border: 'none',
  borderRadius: '0.5rem 0.5rem 0 0',
  cursor: 'pointer',
  display: 'flex',
  outline: 'none',
  padding: '0.625rem 1rem',
  position: 'absolute',
  zIndex: '100002', // one index above the rxcApp selection window

  [theme.breakpoints.down('md')]: {
    boxShadow: '0px -2px 20px 0px rgba(34, 34, 34, 0.50)',
    top: '50%',
    transform: 'rotate(270deg) translate(20%, 100%)',
    right: 7,
  },
  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 2px 20px 0px rgba(34, 34, 34, 0.50)',
    bottom: '8rem',
    left: 7,
    transform: 'rotate(90deg) translateY(100%)',
  },
}))

export const ChatButtonLabel = styled('span', {
  name: 'ChatButton',
  slot: 'Label',
})(() => ({ theme }) => ({
  color: theme.palette.custom.white,
  marginLeft: '0.5rem',
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 700,
  textTransform: 'uppercase',
}))
