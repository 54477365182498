import React, { RefObject, useEffect, useRef, useState } from 'react'
import { TextField } from '../UI'
import { CustomInputFieldProps } from '../../types/inputFields'

export interface InputWithMaskProps {
  format: string
  value?: string
  mask?: string[]
  invalidValuesRegexp?: RegExp
}

export const TextFieldWithMask = ({
  format,
  mask = [],
  value,
  invalidValuesRegexp,
  onChange,
  inputRef,
  ...props
}: CustomInputFieldProps & InputWithMaskProps) => {
  const textFieldInputRef =
    (inputRef as RefObject<any>) || useRef<HTMLInputElement | null>(null)

  const [formattedValue, setFormattedValue] = useState(value ?? '')
  const [caretPosition, setCaretPosition] = useState(0)

  const splitValueBySeparators = (): [string[][], string[]] => {
    const matrix = format.split(/[^#]+/g).map((el) => el.split(''))
    const separators = format.split('#').filter(Boolean)

    return [matrix, separators]
  }

  const getRawValue = (value: string, separators: string[]) => {
    let rawValue = value

    separators.forEach((separator) => {
      rawValue = value?.replace(separator, '')
    })

    return invalidValuesRegexp
      ? rawValue.replace(invalidValuesRegexp, '')
      : rawValue
  }

  const getMergedValuesMatrix = (value: string, matrix: string[][]) => {
    let i = 0

    const mergedValues = matrix.map((el) =>
      el.map((innerEl) => value[i++] ?? mask[i - 1] ?? innerEl).join('')
    )

    return mergedValues.filter((el) => !!el.replace(/#/g, ''))
  }

  const getReducedNextValue = (
    mergedValues: string[],
    separators: string[]
  ) => {
    let i = 0

    let reducedValues = mergedValues.reduce((acc: string[], el, idx) => {
      return idx > 0 ? [...acc, separators[i++], el] : [...acc, el]
    }, [])

    return reducedValues.join('').replace(/#/g, '')
  }

  useEffect(() => {
    const [matrix, separators] = splitValueBySeparators()

    const newValue = value || ''
    const rawValue = getRawValue(newValue, separators)
    const mergedValues = getMergedValuesMatrix(rawValue, matrix)
    const nextValue = getReducedNextValue(mergedValues, separators)

    setFormattedValue(nextValue)
  }, [value])

  useEffect(() => {
    const [matrix, separators] = splitValueBySeparators()

    const gapPositions = matrix.reduce<
      { position: number; gapLength: number }[]
    >((acc, el, idx) => {
      const previousGap = acc[idx - 1] || { position: 0, gapLength: 0 }

      const separatorLength = separators[idx] ? separators[idx].length : 0
      const gap = {
        position: previousGap?.position + el.length + previousGap.gapLength,
        gapLength: separatorLength,
      }

      return [...acc, gap]
    }, [])

    const matchingGap = gapPositions.filter((gap) => {
      if (mask?.length) {
        return gap.position === caretPosition
      }

      return gap.gapLength + gap.position === caretPosition
    })

    if (matchingGap.length) {
      textFieldInputRef.current?.setSelectionRange(
        caretPosition + matchingGap[0].gapLength,
        caretPosition + matchingGap[0].gapLength
      )
      return
    }

    textFieldInputRef.current?.setSelectionRange(caretPosition, caretPosition)
  }, [formattedValue])

  const onInputChange = (event) => {
    setCaretPosition(event.target.selectionStart)

    if (onChange) {
      onChange(event)
    }
  }

  return (
    <TextField
      inputRef={textFieldInputRef}
      value={value ? formattedValue : value}
      onChange={onInputChange}
      {...props}
    />
  )
}
