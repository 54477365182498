import styled from '@mui/material/styles/styled'
import { Pill } from '../../../components/UI'
import { Z_INDEX_LV3, Z_INDEX_LV4 } from '../../../constants/ui'

export const OrderFilterWrapper = styled('div', {
  name: 'OrderFilter',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',

  // filter popup
  ' .order-history__filter-container': {
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: Z_INDEX_LV3,
  },

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-start',
    columnGap: theme.spacing(4),
  },
}))

export const OrderFilterContainer = styled('div', {
  name: 'OrderFilter',
  slot: 'Container',
})(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(5)} ${theme.spacing(4)}`,
}))

export const OrderFilterPill = styled(Pill, {
  name: 'OrderFilter',
  slot: 'Pill'
})(() => ({
  textTransform: 'none'
}))

export const OrderCountLabel = styled('div', {
  name: 'OrderFilter',
  slot: 'CountLabel'
})(({ theme }) => ({
  display: 'flex',
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('sm')]: {
    textAlign: 'right',
  },

  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}))

export const OrderFilterContent = styled('div', {
  name: 'OrderFilter',
  slot: 'Content',
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{
  isVisible: boolean
}>(({ isVisible, theme }) => ({
  position: 'static',
  backgroundColor: theme.palette.background.light.primary,
  zIndex: Z_INDEX_LV4,
  display: isVisible ? 'block' : 'none',
  boxShadow: theme.shadows[9],
  '& .MuiFormGroup-root': {
    gap: theme.spacing(4),
    '& .MuiRadio-root': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)} 0 ${theme.spacing(4)}`,
    },
  },
  '& .MuiFormGroup-root span': {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
    lineHeight: 1.43,
    color: theme.palette.text.dark.primary,
  },
  '& .MuiFormGroup-root label': {
    marginBottom: 0,
  },

  [theme.breakpoints.up('sm')]: {
    right: theme.spacing(16),
  },
}))
