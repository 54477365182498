import styled from 'styled-components'
import { StyledTypography, TextField } from '../../../../../../components/UI'
import { Button, StyledAnchor } from '../../../../../../components/UI'
export const Container = styled.div`
  width: 33vw;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr 100px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100vw;
  }
`

export const Header = styled.div`
  display: flex;

  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Title = styled(StyledTypography)`
  font-size: ${({ theme }) => theme.spacing(1.75)}px;
`

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
  display: flex;
  flex-direction: column;

  & > * > img {
    width: 100%;
  }
`

export const Image = styled.img`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
  height: auto;
`

export const EmailField = styled(TextField)`
  margin: ${({ theme }) => theme.spacing(1)}px 0;
  height: 50px;
  padding: ${({ theme }) => theme.spacing(1.25)}px fieldset {
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  }
`

export const Footer = styled.div`
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
  font-size: 12px;
`

export const Link = styled(StyledAnchor)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.dark};
`

export const SubscribeButton = styled(Button)`
  border: none;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.primary.light};
`

type ResultProps = {
  error?: boolean
}
export const Result = styled(StyledTypography)<ResultProps>`
  color: ${({ theme, error }) => (error ? 'red' : theme.palette.primary.main)};
`

export const Description = styled(StyledTypography)`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.spacing(1)}px;
`
