import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { Store } from '../../../types/store'
import { OpeningHoursContainer, WeekDayHours, WeekDay, Hours } from './StoreOpeningHours.style'
import { getOppeningHoursText } from '@utils/openingHours'

export interface OpeningHoursProps {
  store: Store
  isBoldStoreHours?: boolean
}

export const StoreOpeningHours: FC<OpeningHoursProps> = ({ store }) => {
  const { t } = useTranslation()

  const openingHoursMappedInfo = [
    {
      day: t('AccountStores.WeekDays.Monday'),
      openingTime: store?.mondayOT,
      closingTime: store?.mondayCT,
    },
    {
      day: t('AccountStores.WeekDays.Tuesday'),
      openingTime: store?.tuesdayOT,
      closingTime: store?.tuesdayCT,
    },
    {
      day: t('AccountStores.WeekDays.Wednesday'),
      openingTime: store?.wednesdayOT,
      closingTime: store?.wednesdayCT,
    },
    {
      day: t('AccountStores.WeekDays.Thursday'),
      openingTime: store?.thursdayOT,
      closingTime: store?.thursdayCT,
    },
    {
      day: t('AccountStores.WeekDays.Friday'),
      openingTime: store?.fridayOT,
      closingTime: store?.fridayCT,
    },
    {
      day: t('AccountStores.WeekDays.Saturday'),
      openingTime: store?.saturdayOT,
      closingTime: store?.saturdayCT,
    },
    {
      day: t('AccountStores.WeekDays.Sunday'),
      openingTime: store?.sundayOT,
      closingTime: store?.sundayCT,
    },
  ]

  return (
    <OpeningHoursContainer>
      {openingHoursMappedInfo.map(value => (
        <WeekDayHours key={value.day}>
          <WeekDay key={value.day}>{value.day}</WeekDay>
          <Hours key={value.day}>
            {value.openingTime === 'closed'
              ? t('AccountStores.Closed')
              : getOppeningHoursText({
                  openingTime: value.openingTime,
                  closingTime: value.closingTime,
                })}
          </Hours>
        </WeekDayHours>
      ))}
    </OpeningHoursContainer>
  )
}
