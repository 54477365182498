import styled from '@mui/material/styles/styled'

export const FormControlRadioGroupContainer = styled('div', {
  name: 'FormControl',
  slot: 'RadioGroupContainer',
})(({ theme }) => ({
  '& .MuiFormControlLabel-root': {
    marginBottom: theme.spacing(2),
    marginLeft: 0,
  },

  '& .FormControlHelperText-root': {
    marginBottom: 0,
  }
}))

export const FormControlRadioGroupContent = styled('div', {
  name: 'FormControl',
  slot: 'RadioGroupContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
  maxWidth: theme.spacing(80),
}))