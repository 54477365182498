import { useEffect, useMemo } from 'react'
import { OrderItem } from '../types/order'
import { IProduct } from '../types/product'
import { useDispatch, useSelector } from 'react-redux'
import { orderItemsSelector } from '../features/order/selector'
import { getParsedOrderItems } from '../utils/order'
import { clearCurrentProductBundle, setCurrentProductBundle } from '../features/product/slice'
import { currentProductBundleSelector } from '../features/product/selector'

export interface UseCurrentCartItemProps {
  partNumber: string
  currentProduct: IProduct | undefined
}

export const useCurrentProductSelection = (props: UseCurrentCartItemProps): OrderItem | undefined => {
  const { partNumber, currentProduct } = props
  const dispatch = useDispatch()

  const { orderItem } = useSelector(currentProductBundleSelector)
  const orderItems = useSelector(orderItemsSelector)

  const parsedOrderItems = useMemo<OrderItem[]>(() => {
    return getParsedOrderItems(orderItems) || []
  }, [orderItems])

  const getProductInCart = (): OrderItem | undefined => {
    return parsedOrderItems?.find(i => i.partNumber === currentProduct?.partNumber || i.partNumber === partNumber)
  }

  useEffect(() => {
    dispatch(clearCurrentProductBundle())
  }, [])

  useEffect(() => {
    if (currentProduct) {
      dispatch(setCurrentProductBundle({ product: currentProduct }))
    }

    const orderItemInCart = getProductInCart()
    if (orderItemInCart) {
      dispatch(setCurrentProductBundle({ orderItem: orderItemInCart }))
    }
  }, [parsedOrderItems, currentProduct])

  return orderItem
}
