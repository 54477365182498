import React from 'react'
import { useSelector } from 'react-redux'
import { PreLoader, StyledTypography } from '../../../components/UI'
import { getModelName } from '../../../utils/productAttributes'
import styled from '@mui/material/styles/styled'
import { useTranslation } from 'next-i18next'
import { OrderItem } from '../../../types/order'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { undoingRemoveCartItemSelector } from '../../../features/cartui/cartuiSlice'
import { Stack, useTheme } from '@mui/material'
import { StyledRemoveButtonAsLink } from './CartRecap/CartRecap.style'

export interface RemovedItemNotificationProps {
  product: OrderItem
  onUndo: () => void
}

const UndoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.subtitle1.fontSize,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  marginTop: theme.spacing(8),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.background.light.tertiary,
  width: '100%',
}))

const RemovedItemNotification: React.FC<RemovedItemNotificationProps> = ({ product, onUndo }) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const isBusy = useSelector(undoingRemoveCartItemSelector)
  const theme = useTheme()

  return (
    <UndoContainer sx={{ backgroundColor: theme.palette.background.light.primary }}>
      <Stack direction={isMobile ? 'column' : 'row'} columnGap={1}>
        <StyledTypography variant="subtitle1" fontWeight="bold">
          {getModelName(product)}
        </StyledTypography>
        <StyledTypography variant="subtitle1">{t('Cart.Labels.RemovedFromCart')}</StyledTypography>
      </Stack>

      <StyledRemoveButtonAsLink onClick={onUndo} disabled={isBusy}>
        {isBusy && <PreLoader fill={'dark'} withButton />}
        {t('Cart.Actions.UndoRemoval')}
      </StyledRemoveButtonAsLink>
    </UndoContainer>
  )
}

export default RemovedItemNotification
