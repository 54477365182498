import styled from '@mui/material/styles/styled'

export const RxLensDetailsWrapper = styled('div', {
  name: 'OrderRecapRxItem',
  slot: 'RxLensDetailsWrapper',
})(() => ({
  boxShadow: 'unset',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'unset',
  },
}))

export const RxLensDetailsTitleWrapper = styled('div', {
  name: 'OrderRecapRxItem',
  slot: 'RxLensDetailsTitleWrapper',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '&: > *': {
    margin: '0',
  },
  color: theme.palette.text.dark.primary,
}))

export const RxLensDetailsTitle = styled('div', {
  name: 'OrderRecapRxItem',
  slot: 'RxLensDetailsTitle ',
})(({ theme }) => ({
  minHeight: 'unset',
  justifyContent: 'unset',
  padding: '0',
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '& > .MuiAccordionSummary-content': {
    flex: '0 1 auto',
    margin: '0',
    fontSize: '14px',
    color: theme.palette.text.dark.primary,
    fontWeight: '600',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  '& > .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: theme.spacing(),
    transform: 'rotate(180deg)',
    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  },
}))

export const RxLensDetailsContent = styled('div', {
  name: 'OrderRecapRxItem',
  slot: 'RxLensDetailsContent',
})(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(3)} 0 0 0`,
  color: theme.palette.text.dark.primary,
}))

export const RxLensAttributesList = styled('ul', {
  name: 'OrderRecapRxItem',
  slot: 'AttributesList',
})(({ theme }) => ({
  justifyContent: 'space-around',
  margin: '0',
  padding: '0',
  listStyleType: 'none',
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  'li:first-of-type': {
    textTransform: 'capitalize',
  },
}))

export const RxLensAttributesListItem = styled('li', {
  name: 'OrderRecapRxItem',
  slot: 'AttributesListItem',
})(({ theme }) => ({
  color: theme.palette.text.light.secondary,
  fontSize: '12px',
  flex: '1 1 auto',
  maxWidth: '100%',
  width: '100%',
}))

export const RxLensAttributesListItemTitle = styled('span', {
  name: 'OrderRecapRxItem',
  slot: 'AttributesListItemTitle',
})(() => ({
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'inherit',
}))

export const RxLensQuantitySelectContainer = styled('div', {
  name: 'OrderRecapRxItem',
  slot: 'QuantitySelectContainer',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2),
}))
