import React from 'react'
import { useTheme } from '@mui/material/styles'
import {
  RxLensAttributesList,
  RxLensAttributesListItem,
  RxLensDetailsContent,
  RxLensDetailsTitle,
  RxLensDetailsTitleWrapper,
  RxLensDetailsWrapper,
  RxLensPriceContainer,
  RxLensPrices,
} from './RxLensDetails.style'
import { useTranslation } from 'next-i18next'
import { OrderItemWithRoxProps } from '@typesApp/order'
import FormattedPriceDisplay from '../../../../../components/formatted-price-display'
import { StyledCartRecapDivider } from '../CartRecap.style'
import { RxPrescriptionDetails } from './RxPrescriptionDetails'
import { localStorageUtil } from '../../../../../foundation/utils/storageUtil'
import {
  CORRECTION_TYPE,
  FLOW_TYPE_MAP_KEY,
  getRxValuesFromRxLens,
} from '../../../../../components/PrescriptionLenses/RxUtils'
import { getRxLensPrice, getRxLensDiscountedPrice } from '../../../../../utils/rx'
import { calculatePercentageDiscount, parsePriceAsNumber } from '../../../../../foundation/algolia/algoliaPrice'
import {
  ProductCurrentPriceContainer,
  ProductPriceCurrentPrice,
  ProductPriceDiscountBox,
  ProductPriceInitialPrice,
} from '../../../../ProductDetails/components/ProductPrice.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const RxLensDetails: React.FC<{
  orderRxItem: OrderItemWithRoxProps
}> = ({ orderRxItem }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const rxLensDetails = orderRxItem.roxableAttributes || {}
  const rxFLowTypeString = localStorageUtil.get(FLOW_TYPE_MAP_KEY) || '[]'
  const rxObject = getRxValuesFromRxLens(orderRxItem.roxableServices ? orderRxItem.roxableServices[0] : {}, {
    fallbackValue: '',
  })
  let correctionType = rxLensDetails?.correctionType || ''
  if (correctionType.toUpperCase() !== CORRECTION_TYPE.PROGRESSIVE) {
    if (rxObject?.leftAdd || rxObject?.rightAdd) {
      correctionType = CORRECTION_TYPE.READING
    }
  }

  let rxFlowTypeMap = new Map(JSON.parse(rxFLowTypeString))

  rxFlowTypeMap.set(orderRxItem?.orderItemId, correctionType.toUpperCase())
  localStorageUtil.set('rxFlowTypeMap', JSON.stringify(Array.from(rxFlowTypeMap.entries())))

  const isNonPrescription = orderRxItem?.roxableAttributes?.correctionType?.toLowerCase() !== 'non_prescription'

  const lensInitialPrice = getRxLensPrice(orderRxItem.roxableServices)
  const lensDiscountedPrice = getRxLensDiscountedPrice(orderRxItem.roxableServices)

  const lensDiscountPercentage = calculatePercentageDiscount(
    parsePriceAsNumber(lensInitialPrice),
    parsePriceAsNumber(lensDiscountedPrice)
  )

  const hasInitialPriceDiscount = lensDiscountPercentage > 0

  return (
    <>
      <RxLensDetailsWrapper>
        <RxLensDetailsTitleWrapper>
          <RxLensDetailsTitle
            expandIcon={<SVGIcon library="arrow" name="arrow-up" color={theme.palette.text.dark.primary} />}
            aria-controls="rx-lenses-details-content"
            id="rx-lenses-details-header"
          >
            {t('CartRecap.Labels.YourLenses')}
          </RxLensDetailsTitle>
          <RxLensPriceContainer>
            <RxLensPrices>
              {hasInitialPriceDiscount && (
                <ProductPriceInitialPrice>
                  <FormattedPriceDisplay min={lensInitialPrice} currency={orderRxItem.currency} />
                </ProductPriceInitialPrice>
              )}
              <ProductCurrentPriceContainer isPDP={false}>
                <ProductPriceCurrentPrice isPDP={false} isCompact={true}>
                  <FormattedPriceDisplay
                    min={hasInitialPriceDiscount ? lensDiscountedPrice : lensInitialPrice}
                    currency={orderRxItem.currency}
                  />
                </ProductPriceCurrentPrice>
              </ProductCurrentPriceContainer>
            </RxLensPrices>
            {hasInitialPriceDiscount && (
              <ProductPriceDiscountBox isPDP={false}>
                {Math.round(lensDiscountPercentage)}
                {t('ProductTile.Labels.off')}
              </ProductPriceDiscountBox>
            )}
          </RxLensPriceContainer>
        </RxLensDetailsTitleWrapper>

        <RxLensDetailsContent>
          {!!rxLensDetails && (
            <RxLensAttributesList>
              {Object.keys(rxLensDetails).map((key, i) => {
                return (
                  <RxLensAttributesListItem key={`${i}_${rxLensDetails[key]}`}>
                    <span>{(key === 'correctionType' && correctionType?.toLowerCase()) || rxLensDetails[key]}</span>
                  </RxLensAttributesListItem>
                )
              })}
            </RxLensAttributesList>
          )}
        </RxLensDetailsContent>
      </RxLensDetailsWrapper>
      {isNonPrescription && (
        <>
          <StyledCartRecapDivider />
          <RxPrescriptionDetails orderItem={orderRxItem} />
        </>
      )}
    </>
  )
}
