import { Link } from '@mui/material'
import styled from '@mui/material/styles/styled'
import { Button } from '../../../../../../components/UI'

export const StoredCCardSecurityLink = styled(Link, {
  name: 'StoredCCard',
  slot: 'securityLink'
})(({ }) => ({
  whiteSpace: 'nowrap'
}))

export const StoredCCardField = styled('div', {
  name: 'StoredCCard',
  slot: 'field'
})(({ theme }) => ({
  marginBottom: theme.spacing(4)
}))

export const StoredCCardConditions = styled('div', {
  name: 'StoredCCard',
  slot: 'conditions'
})(({ theme }) => ({
  margin: `${theme.spacing(4)} 0`
}))

export const StoredCCardConditionsLink = styled('a', {
  name: 'StoredCCard',
  slot: 'conditions'
})(({ }) => ({
  textDecoration: 'underline'
}))

export const StoredCCardButton = styled(Button, {
  name: 'StoredCCard',
  slot: 'button'
})(({ theme }) => ({
  backgroundColor: theme.palette.custom.light1.green,
  borderColor: theme.palette.custom.green,
  margin: 0
}))

export const StoredCCardForm = styled('form', {
  name: 'StoredCCard',
  slot: 'form'
})(({ }) => ({
  display: 'grid'
}))