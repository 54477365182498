import Axios, { Canceler } from 'axios'
import React from 'react'
import { useSelector } from 'react-redux'

import { currentContractIdSelector } from '../../../redux/selectors/contract'
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'
import { selectedPayMethodsSelector } from '../../../features/order/selector'
import { usePaymentCCardInfo } from './usePaymentCCardInfo'
import { useSite } from '../../../foundation/hooks/useSite'

export const useSecurePayment = () => {
  const { mySite } = useSite()
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const contractId = useSelector(currentContractIdSelector)
  //const securePaymentStatus = useSelector(securePaymentSelector)
  const selectedPaymentInfoList = useSelector(selectedPayMethodsSelector)

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const payloadBase: any = {
    currency: defaultCurrencyID,
    contractId: contractId,
    storeId: mySite.storeID,
    responseFormat: 'application/json',
    widget: 'usePayment',
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const { getUserPaymentCCardInfo } = usePaymentCCardInfo()

  const PayerAuthIFrame = ({
    name,
    hidden,
    ...props
  }: {
    name?: string
    width?: string
    height?: string
    hidden?: boolean
  }): JSX.Element => {
    return (
      <iframe
        {...props}
        title={name}
        name={name}
        style={{
          display: hidden ? 'none' : 'block',
        }}
      ></iframe>
    )
  }

  const SecurePaymentForm = ({
    action,
    token,
    formRef,
    ...props
  }: {
    id?: string
    target?: string
    action?: string
    token?: string
    formRef?: React.RefObject<HTMLFormElement>
  }): JSX.Element => {
    return (
      <form {...props} ref={formRef} method="POST" action={action}>
        <input type="hidden" name="JWT" value={token} />
      </form>
    )
  }

  const payerAuthFormSubmit = (payerAuthFormRef: React.RefObject<HTMLFormElement>) => {
    payerAuthFormRef.current?.submit()
  }

  const getPayerAuthEnrollData = async () => {
    const payload = {
      ...payloadBase,
      body: getUserPaymentCCardInfo(selectedPaymentInfoList),
    }

    return paymentInstructionService.checkPayerAuthenticationEnroll(payload)
  }

  return {
    payerAuthFormSubmit,
    PayerAuthIFrame,
    SecurePaymentForm,
    getPayerAuthEnrollData,
  }
}
