import { useEffect, useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import creditCardType from 'credit-card-type'
import { CreditCardFormDataType, PaymentMethodType } from '@typesApp/checkout'

/**
 * Watch the card number change and return the corresponding payment method from the available payments list
 */
export const useCardPaymentMethodListener = (
  control: Control<CreditCardFormDataType>,
  paymentsList: PaymentMethodType[] | undefined = []): PaymentMethodType | null => {

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType | null>(null)

  const cardNumber = useWatch({
    control,
    name: 'cc_account',
    defaultValue: ''
  })

  const trim = (value: string): string => value?.replace(/\s+/g, '').toLowerCase()

  const findPaymentMethod = (): PaymentMethodType | null => {
    if (!cardNumber) return null

    const cardNumberTrimmed = cardNumber.replace(/\D/g, '')
    const card = cardNumberTrimmed.match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/)
    const cardType = card?.input ? creditCardType(card?.input) : null

    if (!cardType || cardType.length === 0) {
      return null
    }

    const niceCardType = trim(cardType[0].niceType)
    return paymentsList.find(payment => trim(payment.description) === niceCardType) || null
  }

  useEffect(() => {
    const paymentMethod = findPaymentMethod()
    setPaymentMethod(paymentMethod)
  }, [cardNumber])

  return paymentMethod
}
