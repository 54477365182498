import useRouteQuery from '../foundation/hooks/useRouteQuery'
import { PRODUCT_URL_SEARCH_PARAMS } from '../constants/product'

export interface UseEditCartItemParamsReturn {
  isEditLens: boolean
  isEditContactLens: boolean
}

export const useEditCartItemParams = (): UseEditCartItemParamsReturn => {
  const query = useRouteQuery()

  const isEditLens = !!query.get(PRODUCT_URL_SEARCH_PARAMS.EDIT_LENS)
  const isEditContactLens = !!query.get(PRODUCT_URL_SEARCH_PARAMS.EDIT_CONTACT_LENS)

  return {
    isEditLens,
    isEditContactLens
  }
}
