import React from 'react'

import {
  StyledCheckoutProgressContainer,
  StyledCheckoutProgressBar,
  StyledCheckoutProgressBarElement,
  StyledCheckoutProgressBarElementLine,
  StyledCheckoutProgressBarElementStepname,
  StyledCheckoutProgressBarElementDot,
  StyledCheckoutProgressBarElementWrapper,
} from './CheckoutProgress.style'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useCheckoutSteps } from '../../../hooks/useCheckoutSteps'
import { useRouter } from 'next/router'
import { useTheme } from '@mui/material'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const CheckoutProgress: React.FC<{
  activeStep?: string
}> = () => {
  const { activeStep, previousSteps, checkoutSteps } = useCheckoutSteps()
  const { isMobile } = useBreakpoints()
  const router = useRouter()
  const lastStep = checkoutSteps[checkoutSteps.length - 1]
  const isOnLastStep = router.pathname.includes(lastStep.url)
  const theme = useTheme()

  return (
    <StyledCheckoutProgressContainer>
      <StyledCheckoutProgressBar>
        {checkoutSteps.map((step, index) => {
          const isActive = step.stepId === activeStep
          const isCompleted = previousSteps.includes(step)
          const isLastStep = index + 1 === checkoutSteps.length

          const moveStepBack = () => {
            if (isCompleted && !isOnLastStep) {
              router.push(step.url)
            }
          }

          return (
            <StyledCheckoutProgressBarElement
              key={step.stepId}
              active={isActive}
              completed={isCompleted}
              last={isLastStep}
            >
              <StyledCheckoutProgressBarElementWrapper
                clickable={!!isCompleted && !isOnLastStep}
                onClick={moveStepBack}
              >
                <StyledCheckoutProgressBarElementDot completed={isCompleted} active={isActive}>
                  {isCompleted && (
                    <SVGIcon library="global" name="checkmark-outlined" color={theme.palette.custom.cyprus} />
                  )}
                  {isMobile && (
                    <StyledCheckoutProgressBarElementStepname>
                      {step.stepTitle.replace('verification', '').trim()}
                    </StyledCheckoutProgressBarElementStepname>
                  )}
                </StyledCheckoutProgressBarElementDot>
                {!isMobile && (
                  <StyledCheckoutProgressBarElementStepname>{step.stepTitle}</StyledCheckoutProgressBarElementStepname>
                )}
              </StyledCheckoutProgressBarElementWrapper>
              {!isLastStep && <StyledCheckoutProgressBarElementLine completed={isCompleted} />}
            </StyledCheckoutProgressBarElement>
          )
        })}
      </StyledCheckoutProgressBar>
    </StyledCheckoutProgressContainer>
  )
}
