import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import config from '@configs/index'
import { monetateKeySelector } from '@redux/selectors/site'
import Log from '@services/Log'
import { executeOnce } from '@utils/common'
import { initMonetateHistoryTracker } from './monetateUtil'

export const useMonetate = () => {
  const isMonetateEnabled = useSelector(monetateKeySelector)

  const { historyTrackerSrc, isHistoryTrackerEnabled, source: monetateSrc } = config.monetate ?? {}

  const loadScript = ({ id, src }) => {
    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const monetateTag = document.createElement('script')
      monetateTag.async = true
      monetateTag.src = src

      headFragment.appendChild(monetateTag)
      document.head.appendChild(headFragment)
    }, id)()
  }

  useEffect(() => {
    let timer

    if (isMonetateEnabled) {
      try {
        loadScript({ id: 'dw-monetate', src: monetateSrc })

        if (isHistoryTrackerEnabled) {
          loadScript({ id: 'monetate-history-tracker', src: historyTrackerSrc })

          timer = setTimeout(() => {
            initMonetateHistoryTracker()
          }, 3000)
        }
      } catch (e: any) {
        Log.error('[MONETATE] LOAD SCRIPT ERROR: ' + e.message, window.location.href)
      }
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isMonetateEnabled])
}
