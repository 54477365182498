import Axios, { Canceler } from 'axios'
import { Cart, OrderItem, OrderItemsAttachments } from '../../types/order'
import React, { useEffect, useMemo, useState } from 'react'
import { StyledAnchor, StyledLinkAsButton, StyledTypography } from '../UI'
import {
  StyledEditButtonLink,
  StyledEditButtonWrapper,
  StyledOrderRecapItemsWrapper,
  StyledOrderRecapSummary,
  StyledOrderRecapWrapper,
  StyledRecapCTAContainer,
  StyledRecapContainer,
  StyledRecapShipping,
  TotalRow,
  TotalWrapper,
  StyledEditBagButtonWrapper,
  StyledOrderRecapTaxSummary,
  TotalDiscountRow,
  StyledDiscountPriceValue,
} from './OrderRecap.style'
import CurrencyService from '../../services/CurrencyService'
import OrderService from '../../foundation/apis/transaction/order.service'
import { ESTIMATED_TAXES } from '../../constants/order'
import FormattedPriceDisplay from '../formatted-price-display'
import OrderRecapCartEditConfirmDialog from './OrderRecapEditBag'
import { ShippingBillingRecap } from '../ShippingBillingRecap'
import cartService from '../../foundation/apis/transaction/cart.service'
import getDisplayName from 'react-display-name'
import { pdpTaxesSelector } from '../../redux/selectors/site'
import { sessionStorageUtil } from '../../foundation/utils/storageUtil'
import { useAppSelector } from '../../hooks/redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { shipInfosSelector } from '../../features/order/selector'
import * as ROUTES from '../../constants/routes'
import { OrderTaxSummary } from '../OrderTaxSummary'
import config from '../../configs/config.base'
import { DirectBillingCTA } from '../DirectBilling'
import { CHECKOUT_STEPS, CommerceEnvironment } from '../../constants/common'
import Log from '../../services/Log'
import OrderRecapItems from './OrderRecapItems'
import { ShippingComponentContainer } from '@views/Cart/Cart.style'
import CartPromoCodeSection from '@views/Cart/components/CartPromoCodeSection'
import { HandlingFeeSummary } from '@components/OrderTotalSummary/HandlingFeeSummary'
import { useLazyGetShippingInfoQuery } from '@features/order/query'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { getSubscribedItems } from '@views/Subscription/helpers/subscriptionHelpers'
import { useSite } from '@foundation/hooks/useSite'

export interface OrderRecapProps {
  order: Cart | null
  orderItems: OrderItem[]
  orderItemsAttachments: OrderItemsAttachments
  isOrderComplete: boolean
  poNumber?: string
  shippingZipCode?: string
}

/**
 * Order Recap section
 * displays order item table, order total summary, shipping, billing and payment info for order or cart summary
 * @param props
 */
const OrderRecap: React.FC<OrderRecapProps> = props => {
  const { mySite } = useSite()
  const widgetName = getDisplayName(OrderRecap)
  const { order, orderItems, orderItemsAttachments, isOrderComplete } = props
  const [getShippingInfo] = useLazyGetShippingInfoQuery()

  /**
   * Initialize the summary data
   */

  function initOrderTotalSummary() {
    let subtotal: number | null = null
    let tax: number | null = null
    let shippingName: string | null = null
    let shipping: number | null = null
    let shippingTax: number | null = null
    let totalDiscounts: number | null = null
    let grandTotal: number | null = null
    if (order) {
      try {
        subtotal = order.totalProductPrice ? parseFloat(order.totalProductPrice) : null
        tax = order.totalSalesTax ? parseFloat(order.totalSalesTax) : null
        shippingName = OrderService.getDisplayShippingName(orderItems)
        shipping = order.totalShippingCharge ? parseFloat(order.totalShippingCharge) : null
        shippingTax = order.totalShippingTax ? parseFloat(order.totalShippingTax) : null
        totalDiscounts = order.totalAdjustment ? parseFloat(order.totalAdjustment) : null
        grandTotal = order.grandTotal ? parseFloat(order.grandTotal) : null
      } catch (e) {
        Log.error('Could not parse order total summary')
      }
    }
    return {
      subtotal,
      tax,
      shippingName,
      shipping,
      shippingTax,
      totalDiscounts,
      grandTotal,
    }
  }
  const { tax, shippingName, grandTotal } = initOrderTotalSummary()
  const isTaxAvailable = useAppSelector(pdpTaxesSelector)
  const { activeStep } = useCheckoutSteps()
  const isConfirmationPage = activeStep === CHECKOUT_STEPS.ORDER_CONFIRMATION
  const estimatedTaxes = parseFloat(sessionStorageUtil.get(ESTIMATED_TAXES) || '0')
  const [_poNumber, setPONumber] = useState<string>(props.poNumber || '')
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const handleOpen = (): void => setIsEditOpen(true)
  const handleClose = (): void => setIsEditOpen(false)
  const totals = useMemo(() => OrderService.getOrderTotals(order), [order])
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const resolvePONumber = () => {
    if (props.poNumber === undefined && order && order.buyerPONumber) {
      cartService
        .getBuyerPurchaseOrderDataBean({
          buyerPurchaseOrderId: order.buyerPONumber,
          ...payloadBase,
        })
        .then(r => r.data)
        .then(d2 => {
          if (d2.resultList[0] && d2.resultList[0].purchaseOrderNumber) {
            setPONumber(d2.resultList[0].purchaseOrderNumber)
          }
        })
    }
  }

  const total =
    isTaxAvailable &&
    (isNaN(tax as number) || (!isNaN(tax as number) && tax === 0)) &&
    !isNaN(estimatedTaxes as number) &&
    !!grandTotal &&
    !!estimatedTaxes
      ? grandTotal + estimatedTaxes
      : grandTotal

  const totalKlarna = !!total && (total / 3).toFixed(2)
  const currency = CurrencyService.getSymbolByName(order?.grandTotalCurrency || '')
  const shipInfos = useSelector(shipInfosSelector)
  const estimatedDeliveryDate = useEstimatedDeliveryDate(orderItems?.[0])
  const orderId = order?.orderId
  const hasSubscribedItemsInCart = !!getSubscribedItems(orderItems).length
  const isDirectBillingEnabled =
    !!CommerceEnvironment.directInsuranceBilling[mySite.locale?.toLowerCase()]?.enabled ?? false
  const showDirectBillingCta =
    !hasSubscribedItemsInCart && isDirectBillingEnabled && (activeStep === 'shipping' || activeStep === 'payment')

  useEffect(() => {
    resolvePONumber()
    return () => {
      cancels.forEach(cancel => cancel())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.buyerPONumber])
  useEffect(() => {
    !shipInfos && orderId && getShippingInfo({ orderId })
  }, [order, orderId, shipInfos, getShippingInfo])

  return (
    <StyledOrderRecapWrapper>
      {!isConfirmationPage && (
        <StyledEditBagButtonWrapper>
          <StyledEditButtonLink onClick={handleOpen}>{t('OrderRecap.Labels.EditYourBag')}</StyledEditButtonLink>
          <OrderRecapCartEditConfirmDialog route={ROUTES.CART} open={isEditOpen} handleClose={handleClose} />
        </StyledEditBagButtonWrapper>
      )}
      <StyledOrderRecapItemsWrapper>
        {activeStep !== CHECKOUT_STEPS.UPLOAD_PRESCRIPTION && (
          <OrderRecapItems
            orderItems={orderItems}
            orderItemsAttachments={orderItemsAttachments}
            estimatedDeliveryDate={estimatedDeliveryDate}
            orderId={orderId || ''}
          />
        )}
      </StyledOrderRecapItemsWrapper>
      {!isConfirmationPage && (
        <>
          <StyledRecapShipping>
            <StyledTypography fontWeight={'bold'}>{t('OrderRecap.Labels.ShippingMethod')}:</StyledTypography>
            <StyledEditButtonWrapper>
              <StyledEditButtonLink onClick={handleOpen}>{t('OrderRecap.Labels.Edit')}</StyledEditButtonLink>
              <OrderRecapCartEditConfirmDialog route={ROUTES.CART} open={isEditOpen} handleClose={handleClose} />
            </StyledEditButtonWrapper>

            <div>{shippingName}</div>
            <div>{<FormattedPriceDisplay min={totals.shipping} currency={totals.grandTotalCurrency} />}</div>
            {totals.handlingFeeAmount > 0 && (
              <HandlingFeeSummary
                handlingFeeAmount={totals.handlingFeeAmount}
                handlingFeeTotal={totals.handlingFeeTotal}
                currency={totals.grandTotalCurrency}
              />
            )}
          </StyledRecapShipping>
          <ShippingComponentContainer style={{ marginTop: 11 }}>
            <CartPromoCodeSection />
          </ShippingComponentContainer>
          {showDirectBillingCta && (
            <DirectBillingCTA
              translations={{
                unavailableTitle: t('DirectBilling.UnavailableTitle'),
                unavailableMessage: t('DirectBilling.UnavailableMessage'),
              }}
            />
          )}
          {activeStep === CHECKOUT_STEPS.PAYMENT && (
            <StyledRecapContainer>
              <ShippingBillingRecap orderComplete={isOrderComplete} />
            </StyledRecapContainer>
          )}
          {isTaxAvailable && orderId && (
            <StyledOrderRecapTaxSummary>
              <OrderTaxSummary
                order={order}
                orderItems={orderItems}
                boldLabel={false}
                showLabelSeparator={true}
                showHeader={true}
                orderId={orderId}
                orderCurrency={totals.shippingTaxCurrency}
                salesTax={totals.tax}
                shippingTax={totals.shippingTax}
              />
            </StyledOrderRecapTaxSummary>
          )}

          <StyledOrderRecapSummary klarnaPayEnabled={config.klarnaPayEnabled}>
            {totals.hasInsurance && (
              <TotalDiscountRow>
                <StyledTypography>{t('OrderTotalSummary.Labels.InsuredCoverage')}</StyledTypography>
                <StyledDiscountPriceValue>
                  -
                  <FormattedPriceDisplay min={totals.insuranceAmount} currency={totals.insuranceCurrency} />
                </StyledDiscountPriceValue>
              </TotalDiscountRow>
            )}

            {totals.hasPromotion && (
              <TotalDiscountRow>
                <StyledTypography>{t('OrderTotalSummary.Labels.PromotionApplied')}</StyledTypography>
                <StyledDiscountPriceValue>
                  -
                  <FormattedPriceDisplay min={totals.promotionAmount} currency={totals.promotionCurrency} />
                </StyledDiscountPriceValue>
              </TotalDiscountRow>
            )}

            <TotalRow>
              <StyledTypography fontWeight={'700'}>{t('OrderTotalSummary.Labels.Total')}</StyledTypography>
              <TotalWrapper>
                <FormattedPriceDisplay min={total || 0} currency={totals.grandTotalCurrency} />
              </TotalWrapper>
            </TotalRow>

            {config.klarnaPayEnabled && (
              <>
                <StyledTypography fontSize={12}>
                  {t('OrderRecap.Klarna.MakeXPaymentsOf')}
                  <StyledTypography fontSize={12} component={'span'} fontWeight={'600'}>
                    {currency}
                    {totalKlarna}
                  </StyledTypography>
                  <StyledTypography fontSize={12} component={'span'} fontWeight={'700'}>
                    {t('OrderRecap.Klarna.Name')}
                  </StyledTypography>
                  {t('OrderRecap.Klarna.NoFees')}
                </StyledTypography>
                <StyledTypography fontSize={12} fontWeight={'700'}>
                  <StyledAnchor href={'#'}>{t('OrderRecap.Klarna.LearnMore')}</StyledAnchor>
                </StyledTypography>
              </>
            )}
          </StyledOrderRecapSummary>
          {isOrderComplete && (
            <StyledRecapCTAContainer>
              <StyledLinkAsButton href={`/${langCode}`}>{t('OrderRecap.CTA.ContinueShopping')}</StyledLinkAsButton>
            </StyledRecapCTAContainer>
          )}
        </>
      )}
    </StyledOrderRecapWrapper>
  )
}

export { OrderRecap }
