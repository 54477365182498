import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  NewPasswordHelperTextWrapper,
  NewPasswordHelperText,
  NewPasswordHelperTextRow,
  NewPasswordErrorText,
  HelperTextWrapper,
} from './NewPassword.style'
import theme from './../../../themes'
import { isEmpty } from 'lodash-es'
import { ToggleShowPassword } from '../ToggleShowPassword'
import { PasswordValidationMessages, usePasswordValidation } from './usePasswordValidation'
import { TextField } from '../TextField'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface NewPasswordProps {
  value: string
  error: boolean
  required?: boolean
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void
  onValidationChange?: (isValid: boolean, errorNames: string[]) => void
  name?: string
  label?: string
  description?: string
  messages: PasswordValidationMessages
  customWidth?: string
}

export const NewPassword: React.FC<NewPasswordProps> = ({
  error,
  value,
  required = true,
  onChange,
  onValidationChange,
  label,
  name,
  description,
  messages,
  customWidth,
}: NewPasswordProps) => {
  const [inputType, setInputType] = React.useState('password')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordErrors, setPasswordErrors] = useState<string[]>([])
  const { validationSchema, maxLength } = usePasswordValidation()
  const isInvalid = passwordErrors.length > 0

  useEffect(() => {
    validationSchema
      .validate(value, { abortEarly: false })
      .then(() => setPasswordErrors([]))
      .catch(e => setPasswordErrors(e.errors))
  }, [value])

  useEffect(() => {
    if (typeof onValidationChange === 'function') {
      onValidationChange(!passwordErrors.length, passwordErrors)
    }
  }, [passwordErrors.length])

  useEffect(() => {
    setInputType(showPassword ? 'text' : 'password')
  }, [showPassword])

  return (
    <div style={{ width: customWidth || 'auto' }}>
      <TextField
        name={name}
        type={inputType}
        value={value}
        error={(error && !!passwordErrors.length) || (error && isEmpty(value)) || (!isEmpty(value) && isInvalid)}
        ispristine={!value?.length}
        isvalid={!isInvalid}
        label={`${label}${required ? ' *' : ''}`}
        onChange={onChange}
        showvalidationstatus={true}
        inputProps={{ maxLength }}
        customInputProps={{
          endAdornment: (
            <ToggleShowPassword showPassword={showPassword} onClick={() => setShowPassword(!showPassword)} />
          ),
        }}
      />
      <HelperText
        value={value}
        error={error}
        description={description}
        messages={messages}
        passwordErrors={passwordErrors}
      />
    </div>
  )
}

export interface HelperTextProps {
  value: string
  error: boolean
  description?: string
  messages: PasswordValidationMessages
  passwordErrors: string[]
}

/**
 * Helper text component with password requirements. Using separate component instead of TextField.helperText prop
 * because using the helperText prop was messing up the field's focus and hover styling when there is an error.
 * Using a separate component was the easiest workaround.
 */
const HelperText: React.FC<HelperTextProps> = ({ value, error, description, messages, passwordErrors }) => {
  const errorKeys = Object.keys(messages.requirements)
  const isInvalid = passwordErrors.length > 0

  return (
    <HelperTextWrapper description={description}>
      <NewPasswordHelperText>{description}</NewPasswordHelperText>
      <NewPasswordHelperTextWrapper>
        {errorKeys.map(key => {
          const isRequirementExecuted = passwordErrors.includes(key)
          const RequirementIcon = isRequirementExecuted ? (
            <SVGIcon library="validation" name="checkmark-rounded-error" color={theme.palette.color.error} />
          ) : (
            <SVGIcon library="validation" name="checkmark-rounded" color={theme.palette.color.success} />
          )
          return (
            <NewPasswordHelperTextRow key={key}>
              {value ? (
                RequirementIcon
              ) : (
                <SVGIcon library="global" name="minus-circle" color={theme.palette.text.light.tertiary} />
              )}
              {messages?.requirements[key] || ''}
            </NewPasswordHelperTextRow>
          )
        })}
      </NewPasswordHelperTextWrapper>
      {error && isEmpty(value) && messages?.empty && <NewPasswordErrorText>{messages?.empty}</NewPasswordErrorText>}
      {!isEmpty(value) && isInvalid && messages?.invalid && (
        <NewPasswordErrorText>{messages?.invalid}</NewPasswordErrorText>
      )}
    </HelperTextWrapper>
  )
}
