/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from 'react'
import { useTranslation } from 'next-i18next'
//Foundation libraries
import { useSite } from '../../foundation/hooks/useSite'
import { LANGUAGE_CODE_MAP } from '@constants/common'

export interface FormattedPriceDisplayProps {
  min?: number
  max?: number
  currency?: string
  locale?: string
  showSign?: boolean
}

const normalizeSymbol = (currency: string, value: string) => {
  if (currency === 'EUR') {
    return `€ ${value.replace('€', '')}`
  }
  return value
}

/**
 * Formatted Price or Price Range Display component
 * displays a price or price range between min price to max price that is formatted to the currency and locale or show price pending if price is not available
 * @param props
 */
const FormattedPriceDisplay: React.FC<FormattedPriceDisplayProps> = ({
  max = 0,
  min = 0,
  currency: currencyProp,
  showSign = false,
}) => {
  const { mySite } = useSite()
  const { i18n } = useTranslation()

  const currency = currencyProp || mySite.defaultCurrencyID

  const { t } = useTranslation()

  if (!mySite) {
    return <></>
  }

  const language = i18n.languages?.[0] || LANGUAGE_CODE_MAP[mySite.locale]
  const minCurrency = Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    signDisplay: showSign ? 'always' : 'never',
  }).format(min)

  const maxCurrency = Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    signDisplay: showSign ? 'always' : 'never',
  }).format(max)

  if (max === 0) {
    if (min === null) {
      return <>{t('PriceDisplay.Labels.Pending')}</>
    }

    return <>{normalizeSymbol(currency, minCurrency)}</>
  }

  return <>{`${normalizeSymbol(currency, minCurrency)} - ${normalizeSymbol(currency, maxCurrency)}`}</>
}

export default FormattedPriceDisplay
