//redux
import * as userAction from '../../redux/actions/user'
import Axios, { Canceler } from 'axios'
//Standard libraries
import React, { ChangeEvent, useEffect, useState } from 'react'
import { EMPTY_STRING } from '../../constants/common'
import Log from '../../services/Log'
//UI
import { Button, NewPassword } from '../UI'
// Custom libraries
import getDisplayName from 'react-display-name'
import personService from '../../foundation/apis/transaction/person.service'
import { useDispatch } from 'react-redux'

//Foundation libraries
import { useSite } from '../../foundation/hooks/useSite'
import { useTranslation } from 'next-i18next'

import theme from '../../themes'
import { ToggleShowPassword } from '../UI/ToggleShowPassword'
import { PasswordValidationMessages } from '../UI/NewPassword'
import { ResetPasswordContent, ResetPasswordMessage, StyledInputField } from './ResetPassword.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface ResetPasswordProps {
  urlValidationCode: string
  email: string
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ email, urlValidationCode }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const widgetName = getDisplayName(ResetPassword)

  const { mySite: site } = useSite()

  const [validationCode, setValidationCode] = useState<string>(urlValidationCode)
  const [newPassword, setNewPassword] = useState<string>(EMPTY_STRING)

  const [passwordVerify, setPasswordVerify] = useState<string>(EMPTY_STRING)
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isVerifyVisible, setIsVerifyVisible] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)
  const [errorVerifyPassword, setErrorVerifyPassword] = React.useState<string>(EMPTY_STRING)
  const [errorValidationCode, setErrorValidationCode] = useState(false)
  const [errorNewPassword, setErrorNewPassword] = useState(false)

  const newPasswordLabel = t('ResetPassword.NewPasswordLabel')
  const verifyPasswordLabel = t('ResetPassword.VerifyPasswordLabel')
  const submitButton = t('ResetPassword.SubmitButton')
  const emailLabel = t('ForgotPassword.EmailLabel')
  const temporaryPasswordLabel = t('ForgotPassword.TemporaryPassword')

  const invalidVerifyPassword = t('ResetPassword.Msgs.InvalidVerifyPassword')
  const emptyVerifyPassword = t('ResetPassword.Msgs.Empty.VerifyPassword')
  const emptyValidationCode = t('ResetPassword.Msgs.Empty.ValidationCode')

  const passwordValidationMessages = {
    empty: t('RegistrationLayout.Msgs.Empty.newPassword'),
    invalid: t('RegistrationLayout.Msgs.InvalidNewPassword'),
    requirements: t('RegistrationLayout.Msgs.Requirements', { returnObjects: true }),
  } as PasswordValidationMessages

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const handleValidationCodeChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value !== EMPTY_STRING) {
      setValidationCode(evt.target.value)
      setErrorValidationCode(false)
    } else {
      setValidationCode(evt.target.value)
      setErrorValidationCode(true)
    }
  }

  const handleNewPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value === EMPTY_STRING) {
      setNewPassword(evt.target.value)
    } else {
      setNewPassword(evt.target.value)
      setErrorNewPassword(true)
    }
  }

  const handleVerifyPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value !== EMPTY_STRING) {
      if (newPassword !== evt.target.value) {
        setPasswordVerify(evt.target.value)
        setErrorVerifyPassword(invalidVerifyPassword)
      } else {
        setPasswordVerify(evt.target.value)
        setErrorVerifyPassword(EMPTY_STRING)
      }
    } else {
      setPasswordVerify(evt.target.value)
      setErrorVerifyPassword(emptyVerifyPassword)
    }
  }

  const handleSubmit = async () => {
    if (!passwordValid) return

    if (validationCode !== EMPTY_STRING && newPassword !== EMPTY_STRING && passwordVerify !== EMPTY_STRING) {
      setIsLoading(true)
      const storeID = site.storeID
      const parameters: any = {
        responseFormat: 'application/json',
        storeId: storeID,
        body: {
          logonId: email,
          resetPassword: 'true',
          xcred_validationCode: validationCode,
          logonPassword: newPassword,
          xcred_logonPasswordVerify: passwordVerify,
          challengeAnswer: '-',
        },
        pathParams: {
          storeId: storeID,
        },
        ...payloadBase,
      }
      const rest = await personService
        .updatePerson(parameters)
        .catch(err => {
          Log.error('ERROR IN UPDATING PASSWORD: ' + err)
        })
        .finally(() => {
          setIsLoading(false)
        })
      if (rest?.userId) {
        dispatch(
          userAction.LOGIN_REQUESTED_ACTION({
            body: {
              logonId: email,
              logonPassword: newPassword,
            },
            ...payloadBase,
          })
        )
      }
    } else {
      setErrorVerifyPassword(emptyVerifyPassword)
      setErrorValidationCode(true)
      setErrorNewPassword(true)
    }
  }

  useEffect(() => {
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [])

  useEffect(() => {
    setErrorNewPassword(false)
  }, [])

  return (
    <>
      <ResetPasswordMessage>{t('ResetPassword.ResetPasswordMsg')}</ResetPasswordMessage>
      <ResetPasswordContent>
        <StyledInputField
          variant="outlined"
          name="email"
          value={email}
          label={emailLabel}
          onChange={handleValidationCodeChange}
          className={'forgot-password-input'}
        />
        <StyledInputField
          required={true}
          error={errorValidationCode}
          className={'forgot-password-input'}
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          name="validationCode"
          value={validationCode}
          label={temporaryPasswordLabel}
          onChange={handleValidationCodeChange}
          helperText={errorValidationCode && emptyValidationCode}
          customInputProps={{
            endAdornment: (
              <>
                {errorValidationCode && <SVGIcon library="validation" name="alert" color={theme.palette.color.error} />}
                <ToggleShowPassword showPassword={showPassword} onClick={() => setShowPassword(!showPassword)} />
              </>
            ),
          }}
        />
        <NewPassword
          name="newPassword"
          value={newPassword}
          label={newPasswordLabel}
          description={t('RegistrationLayout.Password-description')}
          onChange={handleNewPasswordChange}
          onValidationChange={(isValid: boolean) => setPasswordValid(isValid)}
          error={errorNewPassword}
          messages={passwordValidationMessages}
          customWidth={'100%'}
        />
        <StyledInputField
          required={true}
          className={'forgot-password-input'}
          variant="outlined"
          name="verifyPassword"
          type={isVerifyVisible ? 'text' : 'password'}
          value={passwordVerify}
          label={verifyPasswordLabel}
          onChange={handleVerifyPasswordChange}
          helperText={errorVerifyPassword && errorVerifyPassword}
          error={errorVerifyPassword !== EMPTY_STRING}
          customInputProps={{
            endAdornment: (
              <>
                {errorVerifyPassword && <SVGIcon library="validation" name="alert" color={theme.palette.color.error} />}
                <ToggleShowPassword
                  showPassword={isVerifyVisible}
                  onClick={() => setIsVerifyVisible(!isVerifyVisible)}
                />
              </>
            ),
          }}
        />
        <Button variant="primary" onClick={handleSubmit} loading={isLoading}>
          {submitButton}
        </Button>
      </ResetPasswordContent>
    </>
  )
}

export { ResetPassword }
