import React, { ReactNode } from 'react'
import { useTranslation } from 'next-i18next'
import { Stack, Typography } from '@mui/material'
import { Order } from '@typesApp/order'
import { Button, ButtonAsLink } from '@components/UI/Button'
import { generateAccountPagePath } from '@views/Account/Account'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { ACCOUNT_CHILDREN } from '@constants/routes'
import useBreakpoints from '@hooks/useBreakpoints'
import { useRouter } from 'next/navigation'
import { useSite } from '@foundation/hooks/useSite'
import { downloadInvoice, isOrderInvoiceAvailable } from '@features/orderhistory/utils'

interface DashboardLastOrderHeaderProps {
  order: Order
  onReorder: () => void
  isReorderBusy: boolean
}

export const DashboardLastOrderHeader: React.FC<DashboardLastOrderHeaderProps> = props => {
  const { order } = props
  const { t } = useTranslation()
  const router = useRouter()
  const { mySite } = useSite()
  const { isMobile, isDesktop } = useBreakpoints()
  const { langCode } = useStoreIdentity()
  const isInvoiceAvailable = isOrderInvoiceAvailable(order)

  const onViewDetails = () => {
    const path = generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY, { orderId: order.orderId })

    router.push(path)
  }

  const title = (
    <Typography variant="h4" fontWeight={600}>
      {t('Order.OrderId', { orderId: order.orderId })}
    </Typography>
  )

  const actions = {
    viewInvoice: isInvoiceAvailable ? (
      <ButtonAsLink
        onClick={() => downloadInvoice(mySite.storeId, order.orderId)}
        sx={{ width: 'fit-content', alignSelf: isMobile ? 'flex-start' : 'flex-end' }}
      >
        {t('OrderDetails.Actions.DownloadInvoice')}
      </ButtonAsLink>
    ) : null,

    viewDetails: (
      <Button onClick={onViewDetails} variant="secondary" sx={{ margin: 0 }}>
        {t('Order.HistoryViewDetailTooltip')}
      </Button>
    ),

    reorder: null,
  }

  const Layout = isMobile ? MobileLayout : isDesktop ? DesktopLayout : DefaultLayout

  return <Layout title={title} actions={actions} />
}

interface LayoutProps {
  title: ReactNode
  actions: {
    viewInvoice: ReactNode
    viewDetails: ReactNode
    reorder: ReactNode
  }
}

const DefaultLayout: React.FC<LayoutProps> = props => {
  const { title, actions } = props

  return (
    <Stack direction={'row'} columnGap={2} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
      {title}
      <Stack direction={'column'} rowGap={2}>
        {actions.viewInvoice}
        <Stack direction={'row'} columnGap={2}>
          {actions.viewDetails}
          {actions.reorder}
        </Stack>
      </Stack>
    </Stack>
  )
}

const DesktopLayout: React.FC<LayoutProps> = props => {
  const { title, actions } = props

  return (
    <Stack direction={'column'} rowGap={2} width={'100%'} alignItems={'center'}>
      {title}
      <Stack direction={'row'} columnGap={2} justifyContent={'space-between'}>
        {actions.viewInvoice}
      </Stack>
      <Stack direction={'row'} columnGap={2} justifyContent={'center'}>
        {actions.viewDetails}
        {actions.reorder}
      </Stack>
    </Stack>
  )
}

const MobileLayout: React.FC<LayoutProps> = props => {
  const { title, actions } = props

  return (
    <Stack direction={'column'} rowGap={2} width={'100%'} marginTop={4} alignItems={'center'}>
      {title}
      {actions.viewInvoice}
      <Stack direction={'column-reverse'} rowGap={2} marginTop={4}>
        {actions.viewDetails}
        {actions.reorder}
      </Stack>
    </Stack>
  )
}
