import * as React from 'react'
import { Button } from '../Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import styled from '@mui/material/styles/styled'
import CloseIcon from '@mui/icons-material/Close'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { Divider, Drawer, IconButton } from '@mui/material'
import { useRef } from 'react'
import { useTheme, Breakpoint } from '@mui/system'
import { DEFAULT_DIALOG_Z_INDEX, Z_INDEX_LV8 } from '../../../constants/ui'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export type IDialogProps = {
  title?: any
  content?: any
  cancelButton?: IDialogButtonProps
  confirmButton?: IDialogButtonProps
  buttonText?: any
  textAlign?: any
  scrollVariant?: any
  readMoreButton?: any
  buttonAlign?: any
  parentbg?: any
  maxWidth?: Breakpoint | false
  isBackButton?: boolean
  autoOpen?: boolean
  customZIndex?: number
  onCloseAction?: () => void
}

type IDialogButtonProps = {
  label: string
  onclick?: () => void
}

const getVariantMap = (theme, parentbg) => {
  const darkTheme = {
    color: theme.palette.custom.white,
    backgroundColor: theme.palette.custom.blue,
  }
  const lightTheme = {
    color: theme.palette.custom.black,
    backgroundColor: theme.palette.custom.white,
  }
  return parentbg === 'dark' ? darkTheme : lightTheme
}

const StyledDialog = styled(Dialog, {
  name: 'Dialog',
  slot: 'CustomDialog',
  shouldForwardProp: props => props !== 'parentbg' && props !== 'maxWidth',
})<{ parentbg }>(({ theme, parentbg, maxWidth }) => ({
  zIndex: Z_INDEX_LV8, //bigger zIndex than the ROX modal
  '&.MuiPaper-root': {
    backgroundColor: getVariantMap(theme, parentbg).backgroundColor,
    color: getVariantMap(theme, parentbg).color,
    width: '60%',
    [theme.breakpoints.down(1281)]: {
      width: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    maxWidth: maxWidth || '100%',
  },
  '.MuiDialogContentText-root': {
    overflowY: 'visible',
  },
}))

const StyledDialogContentContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down(769)]: {
    marginBottom: 0,
  },
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}))

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '32px 32px 0 32px',
  height: theme.spacing(12),
  [theme.breakpoints.down(769)]: {
    padding: '16px 16px 0 16px',
    height: theme.spacing(8),
  },
}))

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '0 32px 0 32px !important',
  textAlign: 'center',
  fontSize: theme.spacing(6),
  lineHeight: '30px',
  fontWeight: '700',
  paddingLeft: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    padding: '0 16px 0 16px  !important',
    paddingLeft: theme.spacing(4),
    fontSize: theme.spacing(5),
  },
}))

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: props => props !== 'parentbg',
})<{ parentbg }>(({ theme, parentbg }) => ({
  '.MuiPaper-root': {
    backgroundColor: getVariantMap(theme, parentbg).backgroundColor,
    color: getVariantMap(theme, parentbg).color,
    width: '100%',
  },
}))

const StyledDialogContentText = styled(DialogContentText, {
  shouldForwardProp: props => props !== 'scrollVariant' && props !== 'parentbg',
})<{ scrollVariant; parentbg }>(({ theme, scrollVariant, parentbg }) => ({
  overflowY: 'auto',
  marginTop: theme.spacing(6),
  marginRight: theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: `0 ${theme.spacing(2)} 0 ${theme.spacing(8)}`,
  maxHeight: theme.spacing(200),
  color: getVariantMap(theme, parentbg).color,
  backgroundColor: getVariantMap(theme, parentbg).backgroundColor,
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    maxHeight: theme.spacing(130),
  },
  '::-webkit-scrollbar': {
    width: scrollVariant === 'default' ? theme.spacing(1) : 0,
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.custom.alto,
    borderRadius: theme.spacing(1),
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.custom.light1.grey,
    borderRadius: theme.spacing(1),
  },
  [theme.breakpoints.down(769)]: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginRight: 0,
    padding: `0 ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
  },
}))

const StyledCtaContainer = styled('div', { shouldForwardProp: prop => prop !== 'buttonAlign' })<{ buttonAlign }>(
  ({ theme, buttonAlign }) => ({
    display: 'flex',
    justifyContent: buttonAlign,
    gap: theme.spacing(4),
    margin: theme.spacing(4),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down(769)]: {
      flexDirection: 'column',
      margin: 0,
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down(321)]: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
    },
    button: {
      width: theme.spacing(30),
      margin: `${theme.spacing(2)} 0`,
      [theme.breakpoints.down(769)]: {
        width: '100%',
        margin: 0,
      },
    },
  })
)

const StyledScrollButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
  backgroundColor: 'transparent',
  top: 0,
  bottom: 0,
  width: '34px',
  height: '90px',
  margin: 'auto 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}))

const StyledReadMeButtonContainer = styled('div')(({ theme }) => ({
  right: 0,
  left: 0,
  bottom: 0,
  marginBottom: theme.spacing(8),
  backgroundColor: 'transparent',
  width: '100%',
  height: theme.spacing(10),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  [theme.breakpoints.down(321)]: {
    marginBottom: theme.spacing(4),
  },
}))

const StyledScrollButton = styled('div')(({ theme }) => ({
  boxShadow: theme.shadows[19],
  width: theme.spacing(8),
  height: theme.spacing(8),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(4),
  backgroundColor: theme.palette.custom.white,
  ':hover': {
    backgroundColor: theme.palette.custom.light.grey,
  },
}))
const StyledReadMoreButton = styled('div')(({ theme }) => ({
  boxShadow: theme.shadows[19],
  width: 'auto',
  height: theme.spacing(10),
  padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
  borderRadius: theme.spacing(10),
  ':hover': {
    backgroundColor: theme.palette.custom.light1.grey,
  },
  backgroundColor: theme.palette.custom.light.grey,
  display: 'flex',
  alignItems: 'center',
}))
const StyledIconContainer = styled('span')(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}))
const StyledReadMeIconContainer = styled('span')(({ theme }) => ({
  width: theme.spacing(4),
  height: '18px',
  marginRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  fontSize: theme.spacing(6),
  lineHeight: theme.spacing(7.5),
  fontWeight: '700',
}))
const DrawerLabel = styled('span')(() => ({
  textAlign: 'center',
  flexGrow: 1,
  maxWidth: 300,
}))

const StyledDivider = styled(Divider, {
  shouldForwardProp: props => props !== 'parentbg',
})<{ parentbg }>(({ parentbg }) => ({
  backgroundColor: parentbg === 'dark' ? '#666666' : '#E2E2E5',
}))

export function CustomDialog({
  title,
  content,
  buttonText,
  cancelButton,
  confirmButton,
  buttonAlign,
  textAlign,
  scrollVariant,
  readMoreButton,
  parentbg,
  isBackButton,
  autoOpen,
  customZIndex,
  onCloseAction,
  maxWidth,
}: IDialogProps) {
  const scrollbarRef: any = useRef<any>(null)
  const [open, setOpen] = React.useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const { isMobile } = useBreakpoints()
  const theme = useTheme()
  React.useEffect(() => {
    if (autoOpen === true) {
      if (isMobile) {
        setOpenDrawer(true)
      } else {
        setOpen(true)
      }
    }
  }, [autoOpen])

  const handleClickOpen = () => {
    if (isMobile) {
      setOpenDrawer(true)
    } else {
      setOpen(true)
    }
  }

  const handleClose = () => {
    if (isMobile) {
      setOpenDrawer(false)
    } else {
      setOpen(false)
    }
    onCloseAction && onCloseAction()
  }

  const onHandleClick = (type: 'down' | 'up') => {
    let currentScrollTop = scrollbarRef.current?.scrollTop
    let increment = type === 'up' ? -200 : 200

    scrollbarRef.current?.scrollTo({ top: currentScrollTop + increment, behavior: 'smooth' })
  }

  function Content({ title, content }: IDialogProps) {
    return (
      <>
        {(!isMobile || !isBackButton) && (
          <StyledDialogActions>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
          </StyledDialogActions>
        )}
        <StyledDialogContentContainer>
          {!isMobile || !isBackButton ? (
            <StyledDialogTitle
              id="alert-dialog-title"
              sx={{
                paddingLeft: 0,
              }}
            >
              {title}
            </StyledDialogTitle>
          ) : (
            <>
              <DrawerHeader>
                <IconButton onClick={handleClose}>
                  <SVGIcon
                    library="arrow"
                    name="arrow-left"
                    color={parentbg === 'dark' ? theme.palette.custom.white : ''}
                  />
                </IconButton>
                <DrawerLabel>{title}</DrawerLabel>
              </DrawerHeader>
              <StyledDivider parentbg={parentbg} />
            </>
          )}
          <StyledDialogContentText
            parentbg={parentbg}
            ref={scrollbarRef}
            textAlign={textAlign || 'left'}
            scrollVariant={scrollVariant || 'default'}
          >
            {content}
            {scrollVariant === 'buttons' && (
              <StyledScrollButtonContainer>
                <StyledScrollButton onClick={() => onHandleClick('up')}>
                  <StyledIconContainer>
                    <SVGIcon library="arrow" name="arrow-up" />
                  </StyledIconContainer>
                </StyledScrollButton>
                <StyledScrollButton onClick={() => onHandleClick('down')}>
                  <StyledIconContainer>
                    <SVGIcon library="arrow" name="arrow-down" size={10} />
                  </StyledIconContainer>
                </StyledScrollButton>
              </StyledScrollButtonContainer>
            )}
          </StyledDialogContentText>
          {(cancelButton || confirmButton) && (
            <StyledCtaContainer buttonAlign={buttonAlign || 'center'}>
              {cancelButton && (
                <Button
                  parentbg={parentbg}
                  onClick={() => cancelButton?.onclick && cancelButton.onclick()}
                  fullwidth={true}
                  variant="secondary"
                >
                  {cancelButton.label}
                </Button>
              )}
              {confirmButton && (
                <Button
                  parentbg={parentbg}
                  onClick={() => confirmButton?.onclick && confirmButton.onclick()}
                  fullwidth={true}
                  variant="primary"
                >
                  {confirmButton.label}
                </Button>
              )}
            </StyledCtaContainer>
          )}
        </StyledDialogContentContainer>
        {scrollVariant === 'readmore' && (
          <StyledReadMeButtonContainer>
            <StyledReadMoreButton onClick={() => onHandleClick('down')}>
              <StyledReadMeIconContainer>
                <SVGIcon library="arrow" name="arrow-down" size={16} />
              </StyledReadMeIconContainer>
              {readMoreButton}
            </StyledReadMoreButton>
          </StyledReadMeButtonContainer>
        )}
      </>
    )
  }

  return (
    <div>
      {buttonText && <Button onClick={handleClickOpen}>{buttonText}</Button>}
      <StyledDialog
        parentbg={parentbg || 'light'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          zIndex: customZIndex || DEFAULT_DIALOG_Z_INDEX,
        }}
        fullWidth={true}
        maxWidth={maxWidth || 'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogContent>
          <Content title={title} content={content} />
        </StyledDialogContent>
      </StyledDialog>

      <StyledDrawer sx={{ zIndex: customZIndex || DEFAULT_DIALOG_Z_INDEX }} parentbg={parentbg} open={openDrawer}>
        <Content title={title} content={content} />
      </StyledDrawer>
    </div>
  )
}
