import React, { FC, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import ErrorMessageSnackbar from '../../components/message-snackbar/ErrorMessageSnackbar'
import { sendMyAccountEvent } from '../../foundation/analytics/tealium/lib'
import {
  AccountSummaryWrapper,
  AccountSummaryHeader,
  AccountSummaryTitle,
  AccountSummaryContainerWrapper,
} from './AccountSummary.style'
import { PersonalInformationSection } from './components/PersonalInformation/PersonalInformationSection'
import { SubscriptionSection } from './components/Subscription/SubscriptionSection'
import { ChangePasswordSection } from './components/ChangePassword/ChangePasswordSection'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import { useSite } from '@foundation/hooks/useSite'
import { ALLOWED_NEWSLETTER_SUBSCRIPTION_BY_COUNTRY } from '@views/Subscription/constants'

const AccountSummary: FC = () => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { ...analyticsDataForMyAccount } = useAnalyticsData(ANALYTICS_PAGE_TYPE.PROFILE)

  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsDataForMyAccount,
      Page_Section2: 'Profile',
      Page_Section3: 'UserSummary',
    })
  }, [])

  return (
    <AccountSummaryWrapper>
      <AccountSummaryHeader>
        <AccountSummaryTitle>{t('AccountSummary.Title')}</AccountSummaryTitle>
      </AccountSummaryHeader>
      <AccountSummaryContainerWrapper>
        <PersonalInformationSection />
      </AccountSummaryContainerWrapper>
      { ALLOWED_NEWSLETTER_SUBSCRIPTION_BY_COUNTRY.includes(mySite.country) &&
        <AccountSummaryContainerWrapper>
          <SubscriptionSection />
        </AccountSummaryContainerWrapper>
      }
      <AccountSummaryContainerWrapper>
        <ChangePasswordSection />
      </AccountSummaryContainerWrapper>
      <ErrorMessageSnackbar />
    </AccountSummaryWrapper>
  )
}

export default AccountSummary
