import styled from '@mui/material/styles/styled'
import { StoreInfo } from '../../AccountStores/AccountStores'
import Typography from '@mui/material/Typography'
import { FindStoreInput } from '../../../components/FindStore/FindStoreInput'
import { DashboardTileHeader } from '../AccountDashboard.style'

export const FindStoreInputStyled = styled(FindStoreInput, {
  name: 'StoreInfoSearch',
  slot: 'TextField',
})(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    width: '90%',
  },
}))

export const StoreName = styled(Typography, {
  name: 'StoreInfoWrapper',
  slot: 'Typography',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
  fontWeight: 'bold',
}))

export const StoreInfoWrapper = styled('div', {
  name: 'StoreInfoWrapper',
  slot: 'StoreInfoWrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(4.75),
}))

export const StoreInfoStyled = styled(StoreInfo, {
  name: 'StoreInfoWrapper',
  slot: 'DashboardStoresInfo',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    margin: 0,
  },
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    margin: 0,
  },
  '& .store-info__links-container': {
    justifyContent: 'flex-start',
  },
  '& .store-info__store-hours-title': {
    fontSize: 10,
  },
}))

export const StoreInfoHeader = styled('span', {
  name: 'StoreInfoWrapper',
  slot: 'StoreInfoHeader',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight: 1.4,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
}))

export const DashBoardStoresInfoHeader = styled(DashboardTileHeader, {
  name: 'StoreInfo',
  slot: 'Header',
})(() => ({
  marginTop: 0,
}))
