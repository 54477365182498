import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openModalViewRXSelector } from '../../features/ui/selector'
import { setOpenModalViewRX } from '../../features/ui/action'
import { useTranslation } from 'next-i18next'
import {
  FreeLabel,
  ViewRxCTA,
  StyledModalDialog,
  ViewRXSubSection,
  DownloadRxLink,
  ViewRXFileContainer,
} from './Prescriptions.style'
import { useUploadRxFileMutation, useDeleteRxFileMutation } from '../../features/prescription/query'
import { StyledAlert } from '../../components/UI'
import { StyledAlertHeader, StyledAlertMessage } from '../../components/UI/Alert'
import { IAlert } from '../../components/UI/Alert/Alert'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const ViewRXDialog: React.FC = () => {
  const [uploadFile] = useUploadRxFileMutation()
  const [deleteRxFile] = useDeleteRxFileMutation()
  const dialogProps = useSelector(openModalViewRXSelector)
  const prescription = dialogProps?.prescription
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [alert, setAlert] = useState<IAlert | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const SUPPORTED_FORMAT = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/gif']
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleClose = () => {
    dispatch(setOpenModalViewRX(false, null))
    setAlert(null)
  }
  const url = prescription?.prescriptionUrl || ''

  const onDeleteHandle = () => {
    deleteRxFile(dialogProps.prescription?.idXprescription)
      .unwrap()
      .then(result => {
        if (result.errorCode) {
          setAlert({
            type: 'error',
            title: t('PrescriptionsSection.DeleteSection.Error.GenericError.Title'),
            message: result.errorMessage,
          })
        } else {
          handleClose
        }
      })
      .catch(error => {
        setAlert({
          type: 'error',
          title: t('PrescriptionsSection.DeleteSection.Error.GenericError.Title'),
          message: `${t('PrescriptionsSection.DeleteSection.Error.GenericError.Description')}: ${error}`,
        })
      })
  }

  const handleUploadedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files![0]) {
      setAlert(null)
      setUploadedFile(e?.target?.files![0])
    }
  }

  useEffect(() => {
    if (uploadedFile) {
      if (uploadedFile.size > 10485760) {
        setAlert({
          type: 'error',
          title: t('PrescriptionsSection.UploadSection.Error.FileTooBig.Title'),
          message: t('PrescriptionsSection.UploadSection.Error.FileTooBig.Description'),
        })
      } else if (!SUPPORTED_FORMAT.includes(uploadedFile.type)) {
        setAlert({
          type: 'error',
          title: t('PrescriptionsSection.UploadSection.Error.WrongFormat.Title'),
          message: t('PrescriptionsSection.ViewSection.AcceptedFiles'),
        })
      } else {
        setAlert(null)
      }
      if (!alert) {
        const formData = new FormData()
        formData.append('file', uploadedFile)
        formData.append('type', uploadedFile.type)
        formData.append('title', uploadedFile.name)
        uploadFile(formData)
          .unwrap()
          .then(() => {
            handleClose
          })
          .catch(error => {
            setAlert({
              type: 'error',
              title: t('PrescriptionsSection.UploadSection.Error.GenericError.Title'),
              message: `${t('PrescriptionsSection.UploadSection.Error.GenericError.Description')}: ${error}`,
            })
          })
      }
    }
  }, [uploadedFile])

  return (
    <StyledModalDialog open={dialogProps.isOpen} onClose={handleClose} onCloseButton={handleClose} widthSize={'sm'}>
      <>
        <FreeLabel className="rx-name">{prescription?.nickName}</FreeLabel>
        <input
          type="file"
          style={{ display: 'none' }}
          name="rxUploader"
          ref={fileInputRef}
          onChange={handleUploadedFile}
          accept={SUPPORTED_FORMAT.join(', ')}
        />
        <ViewRXSubSection>
          <ViewRxCTA onClick={() => fileInputRef?.current?.click()}>
            <SVGIcon library="account" name="upload" size={18} />{' '}
            {t(
              url !== '' ? 'PrescriptionsSection.UploadSection.ReUpload' : 'PrescriptionsSection.UploadSection.Upload'
            )}
          </ViewRxCTA>

          {url !== '' && (
            <>
              <ViewRxCTA>
                <DownloadRxLink href={url} download>
                  <SVGIcon library="account" name="download" size={18} />
                  {t('PrescriptionsSection.UploadSection.Download')}
                </DownloadRxLink>
              </ViewRxCTA>
              <ViewRxCTA onClick={onDeleteHandle}>
                <SVGIcon library="account" name="trash-bin" size={18} />{' '}
                {t('PrescriptionsSection.UploadSection.Delete')}
              </ViewRxCTA>
            </>
          )}
        </ViewRXSubSection>

        {alert && (
          <StyledAlert
            onClose={() => setAlert(null)}
            severity={alert?.type || 'error'}
            sx={{ width: '100%' }}
            icon={<SVGIcon library="close" name="close-circle" />}
          >
            <StyledAlertHeader>{alert?.title}</StyledAlertHeader>
            <StyledAlertMessage fullWidth> {alert?.message}</StyledAlertMessage>
          </StyledAlert>
        )}
        {url !== '' && (
          <ViewRXFileContainer>
            <embed src={url} width="100%" height="540px" />
          </ViewRXFileContainer>
        )}
      </>
    </StyledModalDialog>
  )
}

export default ViewRXDialog
