import styled from '@mui/material/styles/styled'

export const AccountSummaryWrapper = styled('div', {
  name: 'AccountSummary',
  slot: 'Wrapper'
})(({ theme }) => ({
  backgroundColor: theme.palette.custom.wildSand,
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    rowGap: theme.spacing(8),
    padding: `${theme.spacing(8)} ${theme.spacing(16)}`,
  },
}))

export const AccountSummaryHeader = styled('div', {
  name: 'AccountSummary',
  slot: 'Header'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const AccountSummaryTitle = styled('div', {
  name: 'AccountSummary',
  slot: 'Title'
})(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  fontSize: theme.spacing(5),
  lineHeight: 1.4,
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.5,
  },
}))

export const AccountSummaryContainerWrapper = styled('div', {
  name: 'AccountSummary',
  slot: 'ContainerWrapper'
})(({ theme }) => ({
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
  },
}))

