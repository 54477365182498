import React, { Fragment } from 'react'
import {
  ContactLensAttributesList,
  ContactLensAttributesListItem,
  ContactLensDetailsContent,
  ContactLensDetailsWrapper,
  ContactLensAttributesListItemTitle,
} from './ContactLensDetails.style'
import { size, uniq, forEach, map } from 'lodash-es'
import { useTranslation } from 'next-i18next'
import { OrderItemContactLensData } from '@typesApp/cart'

export const ContactLensDetails: React.FC<{
  orderItemClData?: OrderItemContactLensData
}> = ({ orderItemClData }) => {
  const { t } = useTranslation()
  const labelsArray = ['eye']
  const getLabels = (): string[] => {
    !!orderItemClData &&
      Object.keys(orderItemClData).map(eye => {
        !!orderItemClData[eye].data &&
          forEach(orderItemClData[eye].data, function (_, key) {
            labelsArray.push(key)
          })
      })
    return uniq(labelsArray)
  }

  return (
    <ContactLensDetailsWrapper>
      <ContactLensDetailsContent>
        <>
          <ContactLensAttributesList>
            {getLabels().map((label, i) => {
              return (
                <ContactLensAttributesListItem key={`${label}_${i}`}>
                  <ContactLensAttributesListItemTitle>
                    {t(`CartRecap.Labels.${label}`)}
                  </ContactLensAttributesListItemTitle>
                </ContactLensAttributesListItem>
              )
            })}
          </ContactLensAttributesList>
          {!!orderItemClData &&
            Object.keys(orderItemClData).map((eye, i) => {
              return (
                <Fragment key={`${eye}_${i}`}>
                  <ContactLensAttributesList>
                    {!!orderItemClData[eye].data && size(orderItemClData[eye].data) > 0 ? (
                      <ContactLensAttributesListItem>
                        <span>{eye}</span>
                      </ContactLensAttributesListItem>
                    ) : null}

                    {!!orderItemClData[eye].data &&
                      map(orderItemClData[eye].data, (value, key) => {
                        return (
                          <ContactLensAttributesListItem key={`${key}_${value}`}>
                            <span>
                              <>{value}</>
                            </span>
                          </ContactLensAttributesListItem>
                        )
                      })}
                  </ContactLensAttributesList>
                </Fragment>
              )
            })}
        </>
      </ContactLensDetailsContent>
    </ContactLensDetailsWrapper>
  )
}
