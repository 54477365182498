import React from 'react'
import {
  StyledFieldLabel,
  StyledFieldText
} from './Field.style'

export interface FieldProps {
  label: string
  value: string
}

export const Field: React.FC<FieldProps> = ({ label, value }) => {
  return (
    <div>
      <StyledFieldLabel>{label}</StyledFieldLabel>
      <StyledFieldText>{value}</StyledFieldText>
    </div>
  )
}
