import { CreditCardPlaceHolderIcon } from '@components/UI-CSS/SVGIcon/PaymentIcon'
import { Icon, PAYMENT_METHODS } from '../constants/paymentMethods'
import * as PaymentMethod from '../constants/paymentMethods'

const { CHECKOUT_NAMES, ICONS, FOOTER_NAMES } = PAYMENT_METHODS

type PaymentMethodName = PaymentMethod.CheckoutName | PaymentMethod.FooterName

class PaymentMethodsService {
  private getIcon(
    NAMES: typeof CHECKOUT_NAMES | typeof FOOTER_NAMES,
    methodName: PaymentMethodName
  ): PaymentMethod.Icon | null {
    let paymentMethodKey: PaymentMethodName | null = null

    for (const key in NAMES) {
      if (methodName === NAMES[key]) {
        paymentMethodKey = key as PaymentMethodName
        break
      }
    }

    return paymentMethodKey ? ICONS[paymentMethodKey] : null
  }

  getIconByChekoutName(checkoutName: PaymentMethod.CheckoutName): Icon | null {
    return this.getIcon(CHECKOUT_NAMES, checkoutName)
  }

  getIconByFooterName(footerName: PaymentMethod.FooterName): PaymentMethod.Icon | null {
    return this.getIcon(FOOTER_NAMES, footerName)
  }

  getIconsForCreditCardForm(): Icon[] {
    return [
      ICONS.VISA ?? CreditCardPlaceHolderIcon,
      ICONS.AMEX ?? CreditCardPlaceHolderIcon,
      ICONS.MASTERCARD ?? CreditCardPlaceHolderIcon,
    ]
  }
}

const paymentMethodsService = new PaymentMethodsService()

export default paymentMethodsService
