import React from 'react'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { ICheckoutPlacementItem } from '../PaymentMethod'
import { StyledAnchor } from '@components/UI/Anchor'
import styled from '@mui/material/styles/styled'
import Divider from '@mui/material/Divider'

const AnchorCheckoutFooter = styled(StyledAnchor, {
  name: 'CheckoutFooterLinks',
  slot: 'Anchor',
})(({ theme }) => ({
  color: 'inherit',
  fontSize: 'inherit',
  '&:hover': {
    color: 'inherit',
    cursor: 'pointer',
  },
  '&::after': {
    background: theme.palette.background.light.primary,
  },
}))

const DividerCheckoutFooter = styled(Divider, {
  name: 'CheckoutFooterLinks',
  slot: 'Divider',
})(({ theme }) => ({
  borderColor: theme.palette.background.light.primary,
  margin: `${theme.spacing(2)} 0`,
}))

const CheckoutFooterLinks: React.FC<{ item: ICheckoutPlacementItem }> = ({ item }) => {
  const { basePath } = useStoreIdentity()

  const isExternal = ['CMExternalLink'].includes(item.type)
  const title = item.title || ''
  const formattedUrl = item.formattedUrl || ''
  const link = isExternal ? formattedUrl : `${basePath}${formattedUrl}`

  return (
    <>
      <DividerCheckoutFooter orientation="vertical" flexItem />
      <AnchorCheckoutFooter href={link} external={isExternal}>
        {title}
      </AnchorCheckoutFooter>
    </>
  )
}

export default CheckoutFooterLinks
