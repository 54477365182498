//Standard libraries
import React, { useEffect, useRef } from 'react'
import { Link } from '@components/common/Link/Link'
import { usePathname } from 'next/navigation'
import HTMLReactParser from 'html-react-parser'
//UI
import { SnackbarOrigin, Button } from '@mui/material'
import { StyledAlert, StyledSnackbar } from '../UI'
import { InfoRounded } from '@mui/icons-material'
import { AlertType } from '../UI/Alert/Alert'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface MessageSnackbarProperty {
  anchorOrigin: SnackbarOrigin
  handleClose: Function
  message: string | null
  severity: AlertType
  linkAction?: { url: string; text: string }
  autoHideDuration?: number
  ClickAwayListenerProps?: any
}
const MessageSnackbar = ({
  anchorOrigin,
  handleClose,
  message,
  severity,
  linkAction,
  autoHideDuration,
  ClickAwayListenerProps,
}: MessageSnackbarProperty) => {
  const pathname = usePathname()
  const isInitialMount = useRef(true)
  const open = !!message
  let clickAway = {}
  if (ClickAwayListenerProps) {
    clickAway = { ClickAwayListenerProps }
  }
  const MessageToDisplay = () => {
    return (
      <>
        {linkAction === null || linkAction === undefined ? (
          message && HTMLReactParser(message)
        ) : (
          <span>
            {message && HTMLReactParser(message)}
            <Link href={linkAction?.url ?? '/'}>{linkAction.text}</Link>
          </span>
        )}
      </>
    )
  }
  const onClose = () => {
    if (open) {
      handleClose()
    }
  }

  useEffect(() => {
    // close on location change only after initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      onClose()
    }
  }, [pathname])

  const icon = (severity: AlertType): JSX.Element => {
    switch (severity) {
      case 'success':
        return <SVGIcon library="validation" name="validate" />
      case 'info':
        return <InfoRounded fontSize="inherit" />
      case 'error':
        return <SVGIcon library="close" name="close-circle" />
      case 'warning':
        return <SVGIcon library="validation" name="alert" />
      default:
        return <InfoRounded fontSize="inherit" />
    }
  }

  return (
    <StyledSnackbar
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      {...clickAway}
    >
      <StyledAlert
        className="arn-message-snackbar_alert"
        icon={icon(severity)}
        action={<Button color="inherit" size="small" style={{ display: 'none' }} />}
        variant="filled"
        severity={severity}
        onClose={onClose}
      >
        <MessageToDisplay />
      </StyledAlert>
    </StyledSnackbar>
  )
}

export default MessageSnackbar
