import React from 'react'
import { useTranslation } from 'next-i18next'
import { Order } from '../../../types/order'
import {
  OrderItemImage,
  OrderItemContainer,
  OrderItemInfoContainer,
  StyledSeparator,
  OrderItemTitle,
  OrderItemTitleWrapper,
  OrderItemStatusText,
  ProductImageWrapper,
  StyledBadge,
  StyledText,
  StyledCTAContainer,
  StyledOrderNumber,
} from './OrderItem.style'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { OrderStatusInfo } from './OrderStatusInfo'
import FormattedPriceDisplay from '../../../components/formatted-price-display'
import DateService from '../../../services/DateService'
import OrderService from '../../../foundation/apis/transaction/order.service'
import { StyledLinkAsButton } from '../../../components/UI'
import { generateAccountPagePath } from '../../Account/Account'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { ACCOUNT_CHILDREN, HOME, ORDER_RETURN, ORDER_RETURN_SELECT } from '../../../constants/routes'
import { setReturnOrderId } from '@features/orderReturn/slice'
import { useAppDispatch } from '../../../hooks/redux'
import { DATE_FORMAT_PATTERN } from '../../../constants/date'
import { useSite } from '../../../foundation/hooks/useSite'
import { reorderIdSelector } from '@features/order/selector'
import { useSelector } from 'react-redux'
import config from '@configs/index'
import { StyledReturnButton } from './OrderDetailsHeader.style'
import ReorderButton from '../../../views/Reorder/ReorderButton'

interface OrderItemProps {
  order: Order
}

export const OrderItem: React.FC<OrderItemProps> = ({ order }) => {
  const { t } = useTranslation()
  const { isMobile, isDesktop, isTabletWidthBelowOrEqualTo768, isTabletWidthAbove768 } = useBreakpoints()
  const showCTAs = isDesktop || isTabletWidthBelowOrEqualTo768 || isTabletWidthAbove768
  const isDelivered = OrderService.isDelivered(order)

  return (
    <OrderItemContainer id={`orderItem_${order.orderId}`}>
      <OrderTitle order={order} showCTAs={showCTAs} />
      <StyledSeparator />
      <OrderInfo order={order} />
      {isMobile && <OrderCTAs order={order} />}
      {isDelivered && <OrderItemStatusText dangerouslySetInnerHTML={{ __html: t('Order.OrderStatusDeliveredInfo') }} />}
    </OrderItemContainer>
  )
}

export interface OrderTitleProps {
  order: Order
  showCTAs: boolean
}

const OrderTitle: React.FC<OrderTitleProps> = ({ order, showCTAs }) => {
  const { t } = useTranslation()
  const itemsCount = OrderService.getItemsCount(order)
  const url404 = config.publicUrl + '/images/common/404.svg'
  const imageUrl = OrderService.getFirstOrderItemThumbnail(order) || url404

  return (
    <OrderItemTitleWrapper>
      <OrderItemTitle>
        <ProductImageWrapper>
          <StyledBadge badgeContent={`+${itemsCount}`} invisible={itemsCount <= 1}>
            <OrderItemImage src={imageUrl} />
          </StyledBadge>
        </ProductImageWrapper>
        <StyledOrderNumber>{t('Order.OrderId', { orderId: order.orderId })}</StyledOrderNumber>
      </OrderItemTitle>

      {showCTAs && <OrderCTAs order={order} />}
    </OrderItemTitleWrapper>
  )
}

interface OrderCTAProps {
  order: Order
}

const OrderCTAs: React.FC<OrderCTAProps> = ({ order }) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const dispatch = useAppDispatch()
  const { orderId } = order

  const reorderId = useSelector(reorderIdSelector)
  const disableReorderCTA = reorderId === orderId
  const isReturnEligible = OrderService.isReturnEligible(order, mySite.xStoreCfg.n1SiteGoliveDate)
  const showReorder = OrderService.isReorderEligible(order) && OrderService.hasReorderableItem(order)

  return (
    <StyledCTAContainer>
      <StyledLinkAsButton
        href={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY, { orderId })}
        filltype="outline"
        className="cta"
        sx={{ margin: 0 }}
      >
        {t('Order.HistoryViewDetailTooltip')}
      </StyledLinkAsButton>

      {isReturnEligible && (
        <StyledReturnButton
          href={`${HOME}/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`}
          onClick={() => dispatch(setReturnOrderId(orderId))}
          filltype="outline"
          fullwidth
          className="cta"
        >
          {t('Order.CreateReturn')}
        </StyledReturnButton>
      )}

      {showReorder && (
        <ReorderButton
          label={t('OrderDetails.Actions.OrderAgain')}
          orderId={order.orderId}
          isRX={OrderService.isRoxable(order.orderExtendAttribute)}
          shouldDisable={disableReorderCTA}
        />
      )}
    </StyledCTAContainer>
  )
}

export interface OrderInfoProps {
  order: Order
}

const OrderInfo: React.FC<OrderInfoProps> = ({ order }) => {
  const { t } = useTranslation()
  const { placedDate, grandTotal, grandTotalCurrency } = order

  return (
    <OrderItemInfoContainer>
      <StyledText>{t('Order.Date')}</StyledText>
      <StyledText isBold>{DateService.getLocalDate(placedDate || '', DATE_FORMAT_PATTERN['MMM dd, yyyy'])}</StyledText>

      <StyledText>{t('Order.Status')}</StyledText>
      <OrderStatusInfo order={order} />

      <StyledText>{t('Order.TotalPrice')}</StyledText>
      <StyledText isBold>
        <FormattedPriceDisplay min={+grandTotal} currency={grandTotalCurrency} />
      </StyledText>
    </OrderItemInfoContainer>
  )
}
