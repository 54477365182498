import styled from '@mui/material/styles/styled'

export const OrderTaxSummaryContainer = styled('div', {
  name: 'OrderTaxSummary',
  slot: 'Container',
})(() => ({
}))

export const OrderTaxSummaryHeader = styled('div', {
  name: 'OrderTaxSummary',
  slot: 'Header',
})(() => ({
  fontWeight: 'bold',
}))

export const OrderTaxSummaryLineContainer = styled('div', {
  name: 'OrderTaxSummaryLine',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const OrderTaxSummaryLineLabel = styled('div', {
  name: 'OrderTaxSummaryLine',
  slot: 'Label',
  shouldForwardProp: (prop) => prop !== 'fontSize' && (prop) !== 'bolded'
})<{
  fontSize?: number;
  bolded?: boolean;
}>(({ theme, fontSize, bolded }) => ({
  fontSize: fontSize ? fontSize : theme.typography.body2.fontSize,
  fontWeight: bolded ? 'bold' : '',
}))

export const OrderTaxSummaryLineValue = styled('div', {
  name: 'OrderTaxSummaryLine',
  slot: 'Value',
})<{
  fontSize?: number;
}>(({ theme, fontSize }) => ({
  fontSize: fontSize ? fontSize : theme.typography.body2.fontSize,
}))
