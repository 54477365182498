// Monetate Page types
export const MONETATE_PAGE_TYPE_MAP = {
  default: 'otherPage',
  home: 'main',
  pdp: 'product',
  plp: 'category',
  eyewear: 'category',
  'daily-contact-lenses': 'category',
  cart: 'cart',
  checkout: 'checkout',
  shipping: 'shipping',
  payment: 'billing',
  'order-confirmation': 'purchase',
  RemixProductDisplay: 'customProduct',
  SearchDisplay: 'search',
  SearchResults: 'search',
  search: 'search',
}

export const MONETATE_PURCHASE = 'purchase'

// methods
export const MONETATE_SET_PAGE_TYPE = 'setPageType'
export const MONETATE_ADD_CART_ROWS = 'addCartRows'
export const MONETATE_ADD_PRODUCT_DETAILS = 'addProductDetails'
export const MONETATE_ADD_PRODUCTS = 'addProducts'
export const MONETATE_ADD_PURCHASE_ROWS = 'addPurchaseRows'
export const MONETATE_TRACK_DATA = 'trackData'

// Local storage key
export const MONETATE_CART_ITEMS = 'monetateCartItems'
