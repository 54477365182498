import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { MODAL_INFO_ARTICLE_CROP_TYPE, Z_INDEX_DIALOG_LEVEL_FOR_ROX_CONFIGURATOR } from '../../constants/ui'
import { useGetPageStaticLandingQuery } from '../../features/cms/query'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import { TMedia } from '@typesApp/cmsPlacement/Media'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import TextModule from '../CmsModules/TextModule'
import styled from 'styled-components'
import { CustomDialog } from '../UI'
import { MultiColumnInfoDialog } from '../CmsPlacement/RXFlowDialogs/MultiColumnInfoDialog'
import { ShapeVariant } from '../UI/Carousel/Carousel.type'
import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'
import { CShieldOverlay as MediaOverlay, MainContainer } from './DelegateLearnMore.styles'
import { SVGIcon, SVGIconProps } from '@components/UI-CSS/SVGIcon/SVGIcon'
export interface IProps {
  contentIdentifier: string
  onModalClose: () => void
}

export const CarouselItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.custom.light.grey,
  height: 200,
}))

const DelegateLearnMore: React.FC<IProps> = ({ contentIdentifier, onModalClose }) => {
  const [mainPlacement, setMainPlacement] = useState<IPlacement<IPlacementItem>>()
  const { data: dataLv1 } = useGetPageStaticLandingQuery({ pageId: contentIdentifier }, { skip: false })
  const placements: IPlacement[] = dataLv1?.contentPlacements ?? []
  const { t } = useTranslation()
  const filteredPlacements = placements?.filter(
    placement =>
      (placement?.viewtype != null && infoArticleViewTypes.includes(placement.viewtype)) ||
      placement?.viewtype === MODAL_WITH_CAROUSEL_TYPE
  )

  useEffect(() => {
    if (filteredPlacements && filteredPlacements?.length > 0) {
      setMainPlacement(filteredPlacements[0])
    }
  }, [filteredPlacements])

  return mainPlacement
    ? (mainPlacement?.viewtype != null && infoArticleViewTypes.includes(mainPlacement?.viewtype) && (
        <CustomDialog
          customZIndex={Z_INDEX_DIALOG_LEVEL_FOR_ROX_CONFIGURATOR}
          autoOpen={true}
          onCloseAction={onModalClose}
          confirmButton={{
            label: t('PrescriptionConfigurator.Labels.Dialog.OK'),
            onclick: () => {
              onModalClose()
            },
          }}
          content={<TextModule placement={mainPlacement} />}
        />
      )) ||
        buildModalWithCarousel(placements, t, onModalClose)
    : null
}

const buildModalWithCarousel = (placements, t, onModalClose) => {
  const mainPlacement = placements.find(pl => pl.viewtype === MODAL_WITH_CAROUSEL_TYPE)
  const featureList = placements.find(pl => pl.viewtype === FEATURE_LIST_MODAL)

  return (
    <MultiColumnInfoDialog
      autoOpen={true}
      title={''}
      onCloseAction={onModalClose}
      confirmButton={{
        label: t('PrescriptionConfigurator.Labels.Dialog.OK'),
        onclick: () => {
          onModalClose()
        },
      }}
      content={[
        {
          header: {
            carousel: {
              items: buildMediaCarouselItems(mainPlacement?.items || []),
              props: {
                colorVariant: 'light',
                shapeVariant: 'transparent' as ShapeVariant,
              },
            },
          },
          body: {
            featuresList: featureList?.items.map(feat => {
              return {
                title: { data: feat.teaserTitle1 },
                description: { data: feat.teaserText1 },
                icon: {
                  asset: () => <SVGIcon size={32} {...getIconProps(feat.teaserIcon)} />,
                },
              }
            }),
          },
        },
      ]}
    />
  )
}

const infoArticleViewTypes: string[] = ['single-column-info-article', 'double-column-info-article']
const MODAL_WITH_CAROUSEL_TYPE = 'banner-carousel-modal'
const FEATURE_LIST_MODAL = 'feature-list-modal'

const getIconProps = (iconName: string): SVGIconProps => {
  switch (iconName) {
    case 'cle-icon--anti-static':
      return { library: 'feature', name: 'antistatic' }
    case 'cle-icon--water-repellent':
      return { library: 'feature', name: 'water-repellent' }
    case 'cle-icon--smudge-repellent':
      return { library: 'feature', name: 'smudge-repellent' }
    case 'cle-icon--scratch-resistant':
      return { library: 'feature', name: 'scratch-resistant' }
    case 'cle-icon--anti-reflection':
      return { library: 'feature', name: 'anti-reflection' }
    case 'cle-icon--extended-durability':
      return { library: 'feature', name: 'extended-durability' }
    default:
      return { library: 'validation', name: 'checkmark' }
  }
}

const buildMediaCarouselItems = (items: ILXTeaser[]) => {
  return items.map((item, index) => {
    const media: TMedia = (item.media && item.media[0]) || null
    return (
      <MainContainer key={index}>
        <CMSCommonMedia type={MODAL_INFO_ARTICLE_CROP_TYPE} media={media} isLazy={false} />
        {(item.teaserTitle1 && (
          <>
            <MediaOverlay className="title">{item.teaserTitle1}</MediaOverlay>
            <MediaOverlay className="items">
              <div>{item.teaserTitle2}</div>
              <div>{item.teaserTitle3}</div>
            </MediaOverlay>
          </>
        )) ||
          null}
      </MainContainer>
    )
  })
}

export default DelegateLearnMore
