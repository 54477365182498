import styled from '@mui/material/styles/styled'

export const StyledFieldLabel = styled('span', {
  name: 'Field',
  slot: 'Label',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 700,
  marginRight: theme.spacing(2),
}))

export const StyledFieldText = styled('span', {
  name: 'Field',
  slot: 'Text',
})(({ }) => ({
}))