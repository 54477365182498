import { Box } from '@mui/material'
import { StyledButtonAsLink } from '../UI'
import styled from '@mui/material/styles/styled'

export const StyledOrderRecapItemsWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'ItemsWrapper',
})`
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`
export const StyledOrderRecapWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'Wrapper',
})`
  position: relative;
  padding: ${({ theme }) => theme.spacing(1)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
  }
`

export const OrderRecapTitle = styled('div', {
  name: 'OrderRecap',
  slot: 'Title',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.text.dark.primary,
  fontSize: '1rem',
  fontWeight: 'bold',
}))

export const StyledRecapContainer = styled('div', {
  name: 'OrderRecap',
  slot: 'Container',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.custom.white,
  border: `1px solid ${theme.palette.custom.alto}`,
  padding: theme.spacing(4),
}))

export const StyledRecapCTAContainer = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'ContinueCTA',
})(() => ({
  backgroundColor: 'transparent',
  border: 0,
  textAlign: 'center',
  '& a': {
    whiteSpace: 'nowrap',
  },
}))

export const StyledRecapShipping = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'Shipping',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '1fr auto',
  '>div': {
    marginBottom: theme.spacing(2),
  },
  '>div:nth-of-type(2n)': {
    textAlign: 'left',
  },
}))

export const StyledEditButtonWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'EditButtonWrapper',
})(() => ({
  textAlign: 'right',
  justifyContent: 'right',
  fontWeight: 'bold',
}))

export const HandlingFeeDiv = styled('div', {
  name: 'OrderRecap',
  slot: 'HandlingFeeDiv',
})(({ theme }) => ({
  display: 'flex',
  span: {
    marginRight: theme.spacing(1),
  },
}))

export const StyledEditBagButtonWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'EditBagButtonWrapper',
})(({ theme }) => ({
  textAlign: 'right',
  justifyContent: 'right',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}))

export const StyledEditButtonLink = styled(StyledButtonAsLink, {
  name: 'OrderRecap',
  slot: 'EditButtonLink',
})(() => ({
  fontSize: 12,
  fontWeight: 'bold',
}))

export const StyledOrderRecapTaxSummary = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'TaxSummary',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '1fr auto',
  color: theme.palette.text.dark.primary,
}))

export const StyledOrderRecapSummary = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'Summary',
  shouldForwardProp: prop => prop !== 'klarnaPayEnabled',
})<{
  klarnaPayEnabled: boolean
}>(({ theme, klarnaPayEnabled }) => ({
  color: theme.palette.text.dark.primary,
  ...(klarnaPayEnabled && {
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
    rowGap: theme.spacing(2),
  }),
}))

export const TotalDiscountRow = styled('div', {
  name: 'OrderRecap',
  slot: 'TotalDiscount',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: theme.typography.body1.fontSize,
}))

export const StyledDiscountPriceValue = styled('span', {
  name: 'DiscountPrice',
  slot: 'Value',
})(({ theme }) => ({
  color: theme.palette.color.warning,
  fontSize: theme.typography.body1.fontSize,
}))

export const TotalRow = styled('div', {
  name: 'OrderRecap',
  slot: 'Total',
})(() => ({
  display: 'grid',
  fontWeight: 'bold',
  gridTemplateColumns: '1fr 1fr',
  fontSize: '20px',
  textTransform: 'uppercase',
  lineHeight: '28px',
}))

export const Total = styled('div', {
  name: 'OrderRecap',
  slot: 'TotalValue',
})(() => ({
  alignItems: 'start',
}))

export const TotalWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'TotalWrapper',
})(() => ({
  textAlign: 'right',
}))

export const StyledTitleWrapper = styled('div', {
  name: 'TitleWrapperEditPopup',
  slot: 'StyledTitleWrapper',
})(() => ({}))

export const EditBagWrapper = styled(Box, {
  name: 'OrderRecap',
  slot: 'EditBagWrapper',
})(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

export const ShippingBillingRecapContent = styled('div', {
  name: 'OrderRecap',
  slot: 'ShippingBillingRecapContent',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.dark.primary,
  marginBottom: theme.spacing(2),
  '& *': {
    display: 'block',
    lineHeight: '14px',
  },
}))

export const ShippingBillingRecaptitle = styled(OrderRecapTitle, {
  name: 'OrderRecap',
  slot: 'ShippingBillingRecapTitle',
})(() => ({
  textTransform: 'uppercase',
  fontSize: 14,
}))
