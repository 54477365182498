import styled from '@mui/material/styles/styled'

export const FormControlHelperTextContainer = styled('span', {
  name: 'FormHelperText',
  slot: 'Container',
})(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(6),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}))

export const FormControlHelperTextDescription = styled('span', {
  name: 'FormHelperText',
  slot: 'Description',
})(({ theme }) => ({
  marginBottom: 0,
  display: 'block',
  color: theme.palette.custom.boulder,
  fontSize: theme.typography.subtitle1.fontSize,
}))

export const FormControlHelperTextError = styled('span', {
  name: 'FormHelperText',
  slot: 'Error',
})(({ theme }) => ({
  marginBottom: 0,
  display: 'block',
  color: theme.palette.custom.thunderBird,
  fontSize: theme.typography.subtitle1.fontSize,
}))
