import React from 'react'
import { useTranslation } from 'next-i18next'
import { Order } from '../../../types/order'
import {
  OrderItemContainer,
  StyledSeparator,
  OrderItemTitle,
  OrderItemTitleWrapper,
  StyledOrderNumber,
} from './OrderItem.style'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { NewOrderStatusInfo } from './NewOrderStatusInfo'
import FormattedPriceDisplay from '../../../components/formatted-price-display'
import DateService from '../../../services/DateService'
import OrderService from '../../../foundation/apis/transaction/order.service'
import { StyledLinkAsButton } from '../../../components/UI'
import { generateAccountPagePath } from '../../Account/Account'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { ACCOUNT_CHILDREN, HOME, ORDER_RETURN, ORDER_RETURN_SELECT } from '../../../constants/routes'
import { setReturnOrderId } from '@features/orderReturn/slice'
import { useAppDispatch } from '../../../hooks/redux'
import { useSite } from '../../../foundation/hooks/useSite'
import { orderItemsSelector, reorderIdSelector } from '@features/order/selector'
import { useSelector } from 'react-redux'
import { StyledReturnButton } from './OrderDetailsHeader.style'
import styles from '../styles/NewOrderItem.module.scss'
import { sortByDateString } from '@utils/common'
import Link from 'next/link'
import * as ROUTES from '@constants/routes'
import ReorderButton from '../../../views/Reorder/ReorderButton'
import { DEFAULT_DATE_FORMAT } from '@views/Subscription/constants'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

interface OrderItemProps {
  order: Order
}

export const NewOrderItem: React.FC<OrderItemProps> = ({ order }) => {
  const { isMobile, isDesktop, isTabletWidthBelowOrEqualTo768, isTabletWidthAbove768, isDesktopL, isTabletLandscape } =
    useBreakpoints()
  const showCTAsBigScreen = isDesktop || isTabletWidthBelowOrEqualTo768 || isTabletWidthAbove768
  const orderItemsThumbnails = OrderService.getOrderItemsThumbnails(order)
  // Display latest estimated delivery date from array
  const estimatedDeliveryDate = sortByDateString(OrderService.getOrderEstimatedDeliveryDates(order), true)?.[0] || ''

  return (
    <OrderItemContainer id={`orderItem_${order.orderId}`}>
      <OrderTitle order={order} showCTAs={showCTAsBigScreen} isMobile={isMobile} />
      {!showCTAsBigScreen && <OrderCTAs order={order} isMobile={isMobile} />}

      <StyledSeparator />

      <OrderInfo order={order} estimatedDeliveryDate={estimatedDeliveryDate} />

      <StyledSeparator />

      <OrderItemsImageDisplay
        imgUrls={orderItemsThumbnails}
        maxImages={isDesktopL ? 4 : isTabletLandscape || isMobile ? 2 : isDesktop ? 3 : 2}
      />
    </OrderItemContainer>
  )
}

export interface OrderTitleProps {
  order: Order
  showCTAs: boolean
  isMobile: boolean
}

const OrderTitle: React.FC<OrderTitleProps> = ({ order, showCTAs, isMobile }) => {
  const { t: translate } = useTranslation()
  const orderHasIssue = OrderService.hasIssue(order)

  return (
    <>
      {/*
        TODO: Introduce Online tag when omnichannel comes on board
        <p className={styles.badgeWrapper']}><OrderBadge label='Online' /></p>
      */}
      <OrderItemTitleWrapper>
        <OrderItemTitle>
          <StyledOrderNumber>
            {translate('Order.OrderId', { orderId: order.orderId })}
            {orderHasIssue && <ContactUsLink href={`/${ROUTES.CUSTOMER_SERVICES_CONTACT_US}`} />}
          </StyledOrderNumber>
        </OrderItemTitle>
        {showCTAs && <OrderCTAs order={order} isMobile={isMobile} />}
      </OrderItemTitleWrapper>
    </>
  )
}

interface OrderCTAProps {
  order: Order
  isMobile: boolean
}

const OrderCTAs: React.FC<OrderCTAProps> = ({ order }) => {
  const { t: translate } = useTranslation()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const dispatch = useAppDispatch()
  const { orderId } = order
  const reorderId = useSelector(reorderIdSelector)
  const disableReorderCTA = reorderId === orderId
  const isReturnEligible = OrderService.isReturnEligible(order, mySite.xStoreCfg.n1SiteGoliveDate)
  const showReorder = OrderService.isReorderEligible(order) && OrderService.hasReorderableItem(order)
  const hasThreeBtns = isReturnEligible && showReorder

  return (
    <div className={[styles.orderCTAs, hasThreeBtns ? styles.orderCTAsCrowded : ''].join(' ')}>
      <StyledLinkAsButton
        href={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY, { orderId })}
        filltype="outline"
        className="cta"
        sx={{ margin: 0 }}
      >
        {translate('Order.HistoryViewDetailTooltipNew')}
      </StyledLinkAsButton>
      {isReturnEligible && (
        <StyledReturnButton
          href={`${HOME}/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`}
          onClick={() => dispatch(setReturnOrderId(orderId))}
          filltype="outline"
          fullwidth
          className="cta"
        >
          {translate('Order.CreateReturn')}
        </StyledReturnButton>
      )}

      {showReorder && (
        <ReorderButton
          label={translate('OrderDetails.Actions.OrderAgain')}
          orderId={order.orderId}
          isRX={OrderService.isRoxable(order.orderExtendAttribute)}
          shouldDisable={disableReorderCTA}
        />
      )}
    </div>
  )
}

export interface OrderInfoProps {
  order: Order
  estimatedDeliveryDate: string
}

const OrderInfo: React.FC<OrderInfoProps> = ({ order, estimatedDeliveryDate }) => {
  const { t: translate } = useTranslation()
  const { placedDate, grandTotal, grandTotalCurrency } = order

  return (
    <div className={styles.orderInfoContainer}>
      <p className={styles.orderInfoLine}>
        <span className={styles.orderInfoLineHeading}>{translate('Order.Date')}</span>
        <span className={styles.orderInfoLineContent}>
          {DateService.getLocalDate(placedDate || '', DEFAULT_DATE_FORMAT)}
        </span>
      </p>

      <div className={styles.orderInfoLine}>
        <span className={styles.orderInfoLineHeading}>{translate('Order.Status')}</span>
        <NewOrderStatusInfo order={order} isColoredText />
        {estimatedDeliveryDate && (
          <span className={[styles.orderInfoLineContent, styles.orderInfoLineContentBold].join(' ')}>
            {translate('Order.EstimatedDeliveryDate', { date: estimatedDeliveryDate })}
          </span>
        )}
      </div>

      <p className={styles.orderInfoLine}>
        <span className={styles.orderInfoLineHeading}>{translate('Order.TotalPrice')}</span>
        <span className={styles.orderInfoLineContent}>
          <FormattedPriceDisplay min={+grandTotal} currency={grandTotalCurrency} />
        </span>
      </p>
    </div>
  )
}

export interface OrderItemsImageDisplayProps {
  imgUrls: string[]
  maxImages: number
}

const OrderItemsImageDisplay: React.FC<OrderItemsImageDisplayProps> = ({ imgUrls, maxImages }) => {
  const { t: translate } = useTranslation()
  const remainderImageCount = imgUrls.length - maxImages
  const shouldDisplayOverlay = (pos: number) => pos === maxImages - 1 && remainderImageCount > 0
  return (
    <div className={styles.imageGallery}>
      {imgUrls.slice(0, maxImages).map((imgUrl, idx) => {
        return (
          <div
            key={imgUrl}
            className={[
              styles.imageGalleryContainer,
              shouldDisplayOverlay(idx) ? styles.imageGalleryContainerOverlay : '',
            ].join(' ')}
          >
            <img src={imgUrl} className={styles.imageGalleryImg} alt={translate('Order.ItemThumbnail')} />
            {shouldDisplayOverlay(idx) && <span className={styles.imageGalleryText}>+{remainderImageCount}</span>}
          </div>
        )
      })}
    </div>
  )
}

export interface ContactUsLinkProps {
  href: string
}

const ContactUsLink: React.FC<ContactUsLinkProps> = ({ href }) => {
  const { t: translate } = useTranslation()
  return (
    <Link href={href} rel="noopener noreferrer" target="_blank" className={styles.contactUs}>
      <div className={styles.contactUsIcon}>
        <SVGIcon library="misc2" name="customer-care" size={16} />
      </div>
      <span className={styles.contactUsText}>{translate('Order.ContactUs')}</span>
    </Link>
  )
}
