import styled from '@mui/material/styles/styled'
import { StyledTypography } from '../../../components/UI'

export const StyledErrorMessage = styled(StyledTypography, {
  name: 'CreditCardForm',
  slot: 'ErrorMessage',
})(( { theme }) => ({
  color: theme.palette.custom.red,
  fontSize: theme.typography.subtitle1.fontSize,
}))
