import { ADDRESS_BILLING, ADDRESS_SHIPPING } from '../../../constants/common'
import { APPLEPAY_ADDRESS_PLACEHOLDER, APPLEPAY_ORDER_ID, BILLING_ADDRESS_ID } from '../../../constants/checkout'
import {
  ApplePayLineItem,
  ApplePayPaymentEvent,
  ApplePayShippingContact,
  ApplePayShippingEvent,
  ApplePayStatus,
  CheckoutPayload,
} from '../../../types/checkout'
import Axios, { Canceler } from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AddressFormData } from '../../../types/form'
import { AxiosResponse } from 'axios'
import { Contact } from '../../../types/user'
import Log from '../../../services/Log'
import { ORDER_CONFIGS } from '../../../configs/order'
import { PAYMENT_METHODS } from '../../../constants/paymentMethods'
import { RESET_ERROR_ACTION } from '../../../redux/actions/error'
import { cartSelector } from '../../../features/order/selector'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import fetchCart from '../../../features/order/thunks/fetchCart'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import { omit } from 'lodash-es'
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'
import personContactService from '../../../foundation/apis/transaction/personContact.service'
import shippingInfoService from '../../../foundation/apis/transaction/shippingInfo.service'
import { setApplePayFlow, updateCart } from '../../../features/order/slice'
import { getCheckoutPaths } from '../../../utils/routeUtils'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { ApplePayFlow } from '@typesApp/order'
import { REORDER_BILLING_ADDRESS_ID } from '@foundation/constants/common'

const siteName = process?.env?.NEXT_PUBLIC_STORENAME

export const useApplePay = (flow: ApplePayFlow) => {
  const { country } = useStoreIdentity()
  const { mySite } = useSite()
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const contractId = useSelector(currentContractIdSelector)
  const cart = useSelector(cartSelector)
  const CancelToken = Axios.CancelToken
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()

  const { langCode } = useStoreIdentity()
  const shippingAddressIdRef = React.useRef()

  let cancels: Canceler[] = []
  const payloadBase: CheckoutPayload = {
    currency: defaultCurrencyID,
    contractId: contractId,
    storeId: mySite.storeID,
    responseFormat: 'application/json',
    widget: 'useApplePay',
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }
  const shipInfoBody = {
    x_calculateOrder: ORDER_CONFIGS.calculateOrder,
    x_calculationUsage: ORDER_CONFIGS.calculationUsage,
    x_allocate: ORDER_CONFIGS.allocate,
    x_backorder: ORDER_CONFIGS.backOrder,
    x_remerge: ORDER_CONFIGS.remerge,
    x_check: ORDER_CONFIGS.check,
    orderId: '.',
  }
  const checkoutPaths = getCheckoutPaths(langCode)
  const [applePayStatus, setapplePayStatus] = useState<ApplePayStatus>({
    initialized: false,
    shouldUpdateCart: false,
  })

  /**
   * function to invoke Apple Pay Init Data
   */
  async function fetchApplePayInitData(payload) {
    await paymentInstructionService.deleteAllPaymentInstructions().catch(e => {
      throw e
    })
    const addedPaymentInstructionData = await paymentInstructionService.addPaymentInstruction({
      ...payload,
    })

    const paymentInstructionData = await paymentInstructionService.getAllPaymentInstructions(payload).catch(e => {
      throw e
    })

    return {
      addedPaymentInstructionData: addedPaymentInstructionData?.data,
      paymentInstructionData: paymentInstructionData?.data,
    }
  }

  /**
   * function to invoke to update apple pay payment method info
   */
  async function updateApplePayData(payload) {
    const updatedPaymentInstruction = await paymentInstructionService.updatePaymentInstructions(payload).catch(e => {
      throw e
    })
    const paymentInstructionData = await paymentInstructionService.getAllPaymentInstructions(payload).catch(e => {
      throw e
    })

    return {
      updatedPaymentInstruction: updatedPaymentInstruction?.data,
      paymentInstructionData: paymentInstructionData?.data,
    }
  }

  const buildAddressData = (paymentInstruction): AddressFormData => {
    const { firstName, lastName, phone1, email1, postalCode, city, addressLine } = paymentInstruction || {}
    const addressData: AddressFormData = {
      firstName,
      lastName,
      phone1,
      email1,
      postalCode,
      city,
      country,
      addressLine1: addressLine ? addressLine[0] : '',
      addressLine2: addressLine ? addressLine[1] : '',
      state: paymentInstruction?.state,
    }

    return addressData
  }

  /**
   * function to invoke to build the address payload from apple pay contact infos
   * @param addressContact the apple pay contact infos object
   * @param addressType the address type
   */
  const buildAddressDataFromApplePayData = (
    addressContact: ApplePayShippingContact,
    addressType: 'Shipping' | 'Billing'
  ): AddressFormData => {
    const { countryCode, familyName, givenName, locality, postalCode, administrativeArea, addressLines, emailAddress } =
      addressContact || {}
    const addressData: AddressFormData = {
      addressType,
      email1: emailAddress || '',
      firstName: givenName || '',
      lastName: familyName || '',
      zipCode: postalCode || '',
      city: locality || '',
      country: countryCode?.toUpperCase() || '',
      stateProvince: administrativeArea || '',
      state: administrativeArea || '',
      addressLine: addressLines || [],
    }

    return addressData
  }

  /**
   * function to invoke to build the apple pay finalization payload
   * @param payInstructionData the payment instruction obj
   * @param applePayEvent the apple pay event object
   * @param cartAmount The cart total amount
   * @param addressId the billing address id
   */
  const buildApplePayFinalizationData = (
    payInstructionData,
    applePayEvent: ApplePayPaymentEvent,
    cartAmount: string,
    addressId?: string
  ) => {
    Log.info('[APPLE PAY] buildApplePayFinalizationData start')
    const { payment } = applePayEvent
    const paymentInstructionObject = payInstructionData?.paymentInstruction[0]
    const piId = paymentInstructionObject?.piId
    const protocolData = paymentInstructionObject?.protocolData
    const paymentDataString = JSON.stringify(payment?.token?.paymentData)
    const paymentDataBase64 = btoa(paymentDataString)
    const billingAddressId: string = addressId || localStorageUtil.get(BILLING_ADDRESS_ID)
    Log.info('[APPLE PAY] PIID in buildApplePayFinalizationData: ' + piId)
    let applePayFinalizationData = {
      orderId: cart?.orderId,
      piId,
      billing_address_id: billingAddressId,
      payMethodId: protocolData?.find(el => el.name === 'payment_method')?.value,
      piAmount: cartAmount,
      //piAmount: cart?.grandTotal,
      token: payment?.token,
      cc_nameoncard: payment?.billingContact?.givenName + ' ' + payment?.billingContact?.familyName,
      pluginAction1: 'SAVE_AUTH_DATA',
      pluginData1: paymentDataBase64,
      pluginData2: payment?.token.paymentMethod.displayName,
      pluginData3: payment?.token.paymentMethod.network,
      pluginData4: payment?.token.paymentMethod.type,
    }
    Log.info(`[APPLE PAY] FINALIZATION DATA OBJECT:  ${JSON.stringify(applePayFinalizationData)}`)
    return applePayFinalizationData
  }

  /**
   * function to invoke to get the current session payment instruction id
   */
  const getCurrentPaymentInstructionId = async (): Promise<string> => {
    const updatedPayInstructionRes = await paymentInstructionService.getAllPaymentInstructions(payloadBase).catch(e => {
      throw e
    })
    const piId: string = updatedPayInstructionRes?.data?.paymentInstruction[0].piId
    return piId
  }

  const getApplePayLayerOrderInfo = async (): Promise<{
    newTotal: ApplePayLineItem
    newLineItems: ApplePayLineItem[]
  }> => {
    const cartApiData = await cartService.getCart(payloadBase).catch(e => {
      throw e
    })

    dispatch(updateCart(cartApiData))

    const newTotal: ApplePayLineItem = {
      type: 'final',
      label: siteName || '',
      amount: cartApiData?.grandTotal,
    }
    const newLineItems: ApplePayLineItem[] = [
      {
        type: 'final',
        label: t('OrderTotalSummary.Labels.Subtotal'),
        amount: cartApiData?.totalProductPrice,
      },
      {
        type: 'final',
        label: t('OrderTotalSummary.Labels.EstimatedTax'),
        amount: cartApiData?.totalSalesTax,
      },
      {
        type: 'final',
        label: t(['OrderTotalSummary.Labels.Total', 'TOTAL']),
        amount: cartApiData?.grandTotal,
      },
    ]
    return {
      newTotal,
      newLineItems,
    }
  }

  /**
   * function to invoke to get the initial ApplePay configuration
   */
  const initializeApplePay = async () => {
    try {
      setapplePayStatus({
        ...applePayStatus,
        loading: true,
      })
      dispatch(setApplePayFlow(flow))

      let paymentAddInfoBody: any = {
        piAmount: cart?.grandTotal,
        payMethodId: PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY,
        protocolData: [
          {
            name: 'pluginAction1',
            value: 'GET_CONFIGURATION',
          },
        ],
      }
      const payload = {
        body:
          flow === 'express'
            ? paymentAddInfoBody
            : {
                ...paymentAddInfoBody,
                billing_address_id: localStorageUtil.get(BILLING_ADDRESS_ID),
              },
      }
      const initData = await fetchApplePayInitData(payload)
      dispatch(fetchCart(payloadBase))
      setapplePayStatus({
        ...applePayStatus,
        ...initData,
        initialized: true,
        loading: false,
      })
    } catch (e: any) {
      Log.error('ApplePay initializing error: ' + e.message, window.location.href)
      setapplePayStatus({
        ...applePayStatus,
        loading: false,
      })
    }
  }

  /**
   * function to invoke to validate merchant data on BE
   */
  const onValidateMerchant = async event => {
    try {
      Log.info('APPLE PAY VALIDATE MERCHANT START ')
      const piId = await getCurrentPaymentInstructionId()
      Log.info('[APPLE PAY] PIID in onValidateMerchant: ' + piId)
      const merchantBody = {
        payMethodId: PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY,
        piAmount: cart?.grandTotal,
        piId,
        protocolData: [
          {
            name: 'pluginAction1',
            value: 'MERCHANT_VALIDATION',
          },
          {
            name: 'pluginData1',
            value: event.validationURL,
          },
          /*{
            name: 'pluginData5',
            value: process.env.NEXT_PUBLIC_APPLE_PAY_DOMAIN,
          },*/
        ],
      }
      const payload = {
        ...payloadBase,
        body:
          flow === 'express'
            ? merchantBody
            : {
                ...merchantBody,
                billing_address_id: localStorageUtil.get(BILLING_ADDRESS_ID),
              },
      }

      const merchantValidatedRes = await updateApplePayData(payload)
      const sessionValidationData = merchantValidatedRes?.paymentInstructionData?.paymentInstruction[0]?.protocolData
      const merchantSessionData = sessionValidationData?.find(
        el => el.name === 'pluginOutput_MerchantSessionObject_size'
      )
      !!merchantSessionData && validateSession()
    } catch (e: any) {
      Log.error('[APPLE PAY] VALIDATE MERCHANT ERROR: ' + e.message, window.location.href)
      setapplePayStatus({
        ...applePayStatus,
        loading: false,
        shouldUpdateCart: false,
      })
    }
  }

  const validateSession = async () => {
    try {
      Log.info('[APPLE PAY] VALIDATE SESSION START ')
      let opaqueMerchantSession = ''
      const paymentInstructionData = await paymentInstructionService.getAllPaymentInstructions(payloadBase).catch(e => {
        throw e
      })
      const protocolData = paymentInstructionData?.data?.paymentInstruction[0].protocolData
      // check for the session object info in the payment instruction data
      const merchantSessionDataSize = parseInt(
        protocolData?.find(el => el.name === 'pluginOutput_MerchantSessionObject_size')?.value
      )

      for (let i = 0; i < merchantSessionDataSize; ++i) {
        const value = protocolData?.find(el => el.name === `pluginOutput_MerchantSessionObject_${i}`)?.value
        opaqueMerchantSession += value
      }
      const opaqueMerchantSessionObject = JSON.parse(opaqueMerchantSession)
      window.applePayInstance.completeMerchantValidation(opaqueMerchantSessionObject)
      setapplePayStatus({
        ...applePayStatus,
        sessionValid: true,
      })
    } catch (e: any) {
      await initializeApplePay()
      Log.error('[APPLE PAY] session validation error: ' + e.message, window.location.href)
    }
  }
  /**
   * function invoked by Apple apis once user selects or changes shipping address on the
   * apple pay layer
   */
  const onShippingcontactSelected = async (event: ApplePayShippingEvent) => {
    try {
      const shippingMethodsObj = { methods: [] }
      if (flow === 'express') {
        const { shippingContact } = event
        const piId = await getCurrentPaymentInstructionId()
        // build the shipping address with temporary plceholders
        const shippingForm: AddressFormData = buildAddressDataFromApplePayData(shippingContact, ADDRESS_SHIPPING)
        const updatedNewAddressData: AddressFormData = {
          ...shippingForm,
          ...APPLEPAY_ADDRESS_PLACEHOLDER,
        }
        Log.info('[APPLE PAY] PIID in onShippingcontactSelected: ' + piId)
        // add the shipping address id to calculate tax
        const personServiceResponse = await personContactService
          .addPersonContact({
            body: {
              ...updatedNewAddressData,
              isAppleExpress: 'true',
            },
            ...payloadBase,
          })
          .catch(e => {
            window.applePayInstance.completeShippingContactSelection(
              window.applePayInstance.STATUS_FAILURE,
              shippingMethodsObj.methods,
              {
                type: 'final',
                label: siteName,
                amount: cart?.grandTotal,
              },
              []
            )
            throw e
          })
          .finally(() => {
            // avoid showing the snackbar incase of error while adding invalid address
            dispatch(RESET_ERROR_ACTION())
          })

        const addedAddressId = personServiceResponse.data?.addressId
        shippingAddressIdRef.current = addedAddressId

        const addressUpdatePayload = {
          ...payloadBase,
          body: {
            ...shipInfoBody,
            addressId: addedAddressId,
          },
        }
        const merchantBody = {
          payMethodId: PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY,
          piAmount: cart?.grandTotal,
          piId,
          orderId: cart?.orderId,
          billing_address_id: addedAddressId,
        }
        const paymentInstructionUpdatePayload = {
          ...payloadBase,
          body: merchantBody,
        }

        await paymentInstructionService.updatePaymentInstructions(paymentInstructionUpdatePayload).catch(e => {
          throw e
        })
        // update order shipping info with created shipping form
        await shippingInfoService.updateOrderShippingInfo(addressUpdatePayload).catch(e => {
          throw e
        })
        await paymentInstructionService.calculateTax(payloadBase).catch(e => {
          throw e
        })
      }

      const { newTotal, newLineItems } = await getApplePayLayerOrderInfo()

      window.applePayInstance.completeShippingContactSelection(
        window.applePayInstance.STATUS_SUCCESS,
        shippingMethodsObj.methods,
        newTotal,
        newLineItems
      )
    } catch (e: any) {
      Log.error('Apple pay shipping contact selection error: ' + e.message, window.location.href)
    }
  }

  const onPaymentMethodSelected = async () => {
    // needed in classic flow
    try {
      const { newTotal, newLineItems } = await getApplePayLayerOrderInfo()
      window.applePayInstance.completePaymentMethodSelection(newTotal, newLineItems)
    } catch (e: any) {
      Log.error('Apple pay payment method selection error: ' + e.message, window.location.href)
    }
  }

  const onPaymentAuthorized = async event => {
    try {
      let applePayFinalizationData = {}

      const updatedPayInstructionRes = await paymentInstructionService
        .getAllPaymentInstructions(payloadBase)
        .catch(e => {
          throw e
        })
      const updatedCartRest = await cartService.getCart(payloadBase)
      const piId = updatedPayInstructionRes?.data?.paymentInstruction[0].piId
      if (flow === 'express') {
        const { billingContact, shippingContact } = event.payment
        // build shipping and billing addresses with contact information from Apple
        const shippingUpdatedData: AddressFormData = buildAddressDataFromApplePayData(shippingContact, ADDRESS_SHIPPING)
        const billingUpdatedData: AddressFormData = buildAddressDataFromApplePayData(billingContact, ADDRESS_BILLING)
        // get all user addresses to get the one to update with correct contact infos
        const updatedAccountRes: AxiosResponse<{ contact: Contact[] }> =
          await personContactService.getAllPersonContact(payloadBase)
        const addressToUpdateNickName = updatedAccountRes?.data?.contact
          ?.filter(address => address.addressId === shippingAddressIdRef.current)
          .pop()?.nickName
        // update the shipping address and remove all previusly added placeholders
        const shippingAddressAddRes = await personContactService.updatePersonContact({
          body: {
            nickName: addressToUpdateNickName,
            ...shippingUpdatedData,
          },
          ...payloadBase,
        })
        // in the express flow we need to add billing address as well
        const billingAddressAddRes = await personContactService
          .addPersonContact({
            body: {
              ...billingUpdatedData,
              ...payloadBase,
            },
          })
          .catch(e => {
            throw e
          })

        const addedShippingAddressId = shippingAddressAddRes.data?.addressId
        const addedBillingAddressId = billingAddressAddRes.data?.addressId
        localStorageUtil.set(BILLING_ADDRESS_ID, addedBillingAddressId)
        localStorageUtil.remove(REORDER_BILLING_ADDRESS_ID)

        await shippingInfoService
          .updateOrderShippingInfo({
            ...payloadBase,
            body: {
              ...shipInfoBody,
              addressId: addedShippingAddressId,
            },
          })
          .catch(e => {
            throw e
          })

        const updatePayInstructionRest = await updateApplePayData({
          ...payloadBase,
          body: {
            payMethodId: PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY,
            piId,
            piAmount: updatedCartRest?.grandTotal,
            orderId: cart?.orderId,
            billing_address_id: addedBillingAddressId,
          },
        })

        applePayFinalizationData = buildApplePayFinalizationData(
          updatePayInstructionRest?.paymentInstructionData,
          event,
          updatedCartRest?.grandTotal,
          addedBillingAddressId
        )
      } else {
        applePayFinalizationData = buildApplePayFinalizationData(
          updatedPayInstructionRes.data,
          event,
          updatedCartRest?.grandTotal
        )
      }

      const payload = {
        ...payloadBase,
        body: applePayFinalizationData,
      }
      await paymentInstructionService.updatePaymentInstructions(payload).catch(e => {
        throw e
      })

      await cartService.preCheckout({ ...payloadBase }).catch(e => {
        throw e
      })

      await cartService
        .checkOut({
          ...payloadBase,
          orderId: cart?.orderId,
          body: {
            notifyOrderSubmitted: '1',
          },
        })
        .catch(e => {
          throw e
        })

      window.applePayInstance.completePayment(window.applePayInstance.STATUS_SUCCESS)
      localStorage.setItem('isApplePaySuccess', 'true')

      setTimeout(() => {
        router.push(checkoutPaths['order-confirmation'])
      }, 500)
    } catch (e: any) {
      localStorageUtil.remove(APPLEPAY_ORDER_ID)
      localStorageUtil.remove(BILLING_ADDRESS_ID)
      setapplePayStatus({
        ...applePayStatus,
        loading: false,
        shouldUpdateCart: false,
      })
      window.applePayInstance.completePayment(window.applePayInstance.STATUS_FAILURE)
      localStorage.setItem('isApplePaySuccess', 'false')
      Log.error('Apple pay order completion error: ' + e.message, window.location.href)
      await initializeApplePay()
    }
  }

  const onCancel = async () => {
    Log.info('[APPLE PAY] onCancel function')
    localStorageUtil.remove(APPLEPAY_ORDER_ID)
    if (flow === 'express') {
      localStorageUtil.remove(BILLING_ADDRESS_ID)
      // remove comments once BE deploy is done
      /*await paymentInstructionService.invalidateExpressAdresses()
      .catch(e => {
        Log.error(window.location.href, 'apple pay address invalidation error', e.message)
      })*/
    }
    window.applePayInstance = null
    await initializeApplePay()
  }

  const initializeSession = async () => {
    try {
      let configOpts
      localStorageUtil.set(APPLEPAY_ORDER_ID, cart?.orderId)
      setapplePayStatus({
        ...applePayStatus,
        loading: true,
      })
      const payInstructions = applePayStatus?.paymentInstructionData?.paymentInstruction?.[0]
      const protocolData = payInstructions?.protocolData
      const addressData: AddressFormData =
        payInstructions && flow !== 'express' ? buildAddressData(payInstructions) : {}
      const applePayConfigData = protocolData?.find(el => el.name === 'pluginOutput_ApplePayPaymentRequest')?.value
      //omit shipping address selection dropdown in classic flow
      const parsedApplePayConfigData = applePayConfigData && JSON.parse(applePayConfigData)
      configOpts =
        flow !== 'express'
          ? omit(parsedApplePayConfigData, ['requiredBillingContactFields', 'requiredShippingContactFields'])
          : parsedApplePayConfigData

      const appleConfidObject = {
        ...configOpts,
        shippingContact:
          flow !== 'express'
            ? {
                phoneNumber: addressData.phone1,
                emailAddress: addressData.email1,
                givenName: addressData.firstName,
                familyName: addressData.lastName,
                addressLines: [addressData.addressLine1 || '', addressData.addressLine2 || ''],
                locality: addressData.city,
                postalCode: addressData.postalCode,
                administrativeArea: addressData.state,
                countryCode: country.toUpperCase(),
              }
            : {},
      }
      const applePaySession = !!appleConfidObject && new window.ApplePaySession(1, appleConfidObject)
      applePaySession.onvalidatemerchant = onValidateMerchant
      applePaySession.onshippingcontactselected = onShippingcontactSelected
      applePaySession.onpaymentauthorized = onPaymentAuthorized
      applePaySession.onpaymentmethodselected = onPaymentMethodSelected
      applePaySession.oncancel = onCancel
      applePaySession.begin()
      window.applePayInstance = applePaySession
    } catch (e: any) {
      setapplePayStatus({
        ...applePayStatus,
        loading: false,
      })
      window.applePayInstance = null
      Log.error('Apple pay instance error: ' + e.message, window.location.href)
    }
  }

  return {
    fetchApplePayInitData,
    initializeApplePay,
    initializeSession,
    applePayStatus,
    setapplePayStatus,
  }
}
