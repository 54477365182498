import styled from '@mui/material/styles/styled'
import { AccordionSummary } from '@mui/material'
import { TextField } from '../../../../../components/UI'

export const ContactLensDetailsWrapper = styled('div', {
  name: 'CartRecapItem',
  slot: 'ContactLensDetailsWrapper',
})(() => ({}))

export const ContactLensDetailsTitle = styled(AccordionSummary, {
  name: 'CartRecapItem',
  slot: 'ContactLensDetailsTitle ',
})(({ theme }) => ({
  minHeight: 'unset',
  justifyContent: 'unset',
  padding: '0',
  '&: > *': {
    margin: '0',
  },
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '& > .MuiAccordionSummary-content': {
    flex: '0 1 auto',
    margin: '0',
    fontSize: '14px',
    color: theme.palette.text.dark.primary,
    fontWeight: '600',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  '& > .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: theme.spacing(),
    transform: 'rotate(180deg)',
    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  },
}))

export const ContactLensDetailsContent = styled('div', {
  name: 'CartRecapItem',
  slot: 'ContactLensDetailsContent',
})(({ theme }) => ({
  display: 'table',
  tableLayout: 'fixed',
  padding: theme.spacing(3),
  color: theme.palette.text.dark.primary,
  textAlign: 'center',
}))

export const ContactLensAttributesList = styled('ul', {
  name: 'CartRecapItem',
  slot: 'AttributesList',
})(({ theme }) => ({
  justifyContent: 'space-around',
  fontSize: theme.typography.body1.fontSize,
  margin: '0',
  listStyleType: 'none',
  display: 'table-row',
  ':first-of-type': {
    backgroundColor: theme.palette.custom.light.grey,
  },
  li: {
    textTransform: 'capitalize',
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: theme.spacing(2),
    border: `solid 0.5px ${theme.palette.background.light.tertiary}`,
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
  },
}))

export const ContactLensAttributesListItem = styled('li', {
  name: 'CartRecapItem',
  slot: 'AttributesList',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  flex: '1 1 auto',
  maxWidth: '100%',
  width: '100%',
}))

export const ContactLensAttributesListItemTitle = styled('span', {
  name: 'CartRecapItem',
  slot: 'AttributesListItemTitle',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'inherit',
}))

export const ContactLensQuantitySelectContainer = styled('div', {
  name: 'CartRecapItem',
  slot: 'QuantitySelectContainer',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2),
  '>div:first-of-type': {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(15),
    },
  },
}))

export const ContactLensPricePerBoxWrapper = styled('div', {
  name: 'ContactLens',
  slot: 'PricePerBoxWrapper',
})(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    marginLeft: 'auto',
  },
}))

export const ContactLensPricePerBoxContainer = styled('div', {
  name: 'CartRecapItem',
  slot: 'PricePerBoxContainer',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  fontSize: '12px',
  marginTop: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: 0,
    marginLeft: 'auto',
  },
}))

export const ContactLensQuantitySelectWrapper = styled('div', {
  name: 'CartRecapItem',
  slot: 'ContactLensQuantitySelectWrapper',
})(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(4),
  flexDirection: 'column',
  flex: '0 1 100px',

  [theme.breakpoints.down('sm')]: {
    flex: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    '> *': {
      flexBasis: '100%',
    },
  },
}))

export const ContactLensQuantitySelectSubWrapper = styled('div', {
  name: 'CartRecapItem',
  slot: 'ContactLensQuantitySelectSubWrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}))

export const ContactLensQuantitySelectLabel = styled('span', {
  name: 'CartRecapItem',
  slot: 'ContactLensQuantitySelectWrapper',
})(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 600,
  color: 'inherit',
  marginBottom: theme.spacing(2),
}))

export const ContactLensQuantityRecap = styled('span', {
  name: 'CartRecapItem',
  slot: 'ContactLensQuantityRecap',
})(({ theme }) => ({
  fontSize: 12,
  color: 'inherit',
  fontWeight: 'normal',
  textTransform: 'lowercase',
  '&: before': {
    content: '"("',
  },
  '&: after': {
    content: '")"',
  },
}))

export const LensDetailsSection = styled('div', {
  name: 'CartRecapItem',
  slot: 'ClItemLensDetails',
})(({ theme }) => ({
  marginTop: 0,
  '> div': {
    padding: 0,
  },
}))

export const StyledTextField = styled(TextField, {
  name: 'CartRecapItem',
  slot: 'TextField',
})({
  '&.text-field-overwrite': {
    height: '33px',
    flex: '0 1 100px',

    '& > div': {
      display: 'flex',
      alignItems: 'center',

      '& > svg': {
        top: '0',
      },
    },
  },
})
