import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import {
  useFrequentlyBoughtTogether,
  useRelatedProducts,
  useTrendingFacets,
  useTrendingItems,
} from '@algolia/recommend-react'
import { SwiperProps } from 'swiper/react'

import { orderItemsSelector } from '@features/order/selector'
import { initIndexName, recommendClient } from '@foundation/algolia/algoliaConfig'
import { determineAlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'

// TYPES
import { IAlgoliaBaseHit, IAlgoliaHit, IProduct } from '@typesApp/product'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'

// UTILS
import { localeLangCountryUtil } from '@utils/countryUtil'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { getProductsPartNumbers } from '@utils/product'
import { getIsRoxable, getProductType } from '@utils/productAttributesAlgolia'

import { ALGOLIA_RECOMMENDATION_MODELS } from './constants'
import { CmsCarousel } from '@components/Cms/CmsComponents-CSS/CmsCarousel/CmsCarousel'
import styles from './styles/AlgoliaRecommendations.module.scss'

export interface AlgoliaRecommendationsProps {
  item: ICMCollection
  currentProduct?: IProduct
  isGrouped: boolean
  sliderProps?: SwiperProps
}

const { FREQUENTLY_BOUGHT_TOGETHER, RELATED_PRODUCTS, TRENDING_FACETS, TRENDING_ITEMS } = ALGOLIA_RECOMMENDATION_MODELS
const MAX_RECOMMENDED_ITEMS = 6

export const useGetRecommendedProducts = (
  action: string | undefined,
  indexName: string,
  productsPartNumber: string[],
  facetName?: string
): IAlgoliaBaseHit[] => {
  const options = {
    indexName,
    maxRecommendations: MAX_RECOMMENDED_ITEMS,
    recommendClient,
  }

  const actions = {
    [FREQUENTLY_BOUGHT_TOGETHER]: () =>
      useFrequentlyBoughtTogether({
        ...options,
        objectIDs: productsPartNumber,
      }).recommendations,

    [RELATED_PRODUCTS]: () =>
      useRelatedProducts({
        ...options,
        objectIDs: productsPartNumber,
      }).recommendations,

    [TRENDING_FACETS]: () =>
      useTrendingFacets({
        ...options,
        facetName: facetName ? facetName : '',
      }).recommendations,

    [TRENDING_ITEMS]: () =>
      useTrendingItems({
        ...options,
      }).recommendations,

    default: () => {
      return []
    },
  }

  return actions[action || RELATED_PRODUCTS]()
}

const AlgoliaRecommendations: React.FC<AlgoliaRecommendationsProps> = ({ item, currentProduct, sliderProps }) => {
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const customerSegments = useCustomerSegmentsUtil()

  const { t: translate } = useTranslation()
  const carouselTitle = item.title ?? translate('ProductGrid.Recommendations.youMayAlsoLike')

  const orderItems = useSelector(orderItemsSelector)
  const productsPartNumber: string[] = getProductsPartNumbers(currentProduct ? [currentProduct] : orderItems)

  const indexName = initIndexName({ locale: langCountry, isRecommendations: true })

  const recommendationProducts = useGetRecommendedProducts(
    item.idAction?.toLocaleLowerCase(),
    indexName,
    productsPartNumber,
    item.facetName
  )

  if (!Array.isArray(recommendationProducts) || recommendationProducts.length <= 0) return null

  const recommendationsWithPrices = recommendationProducts.map((hit: Partial<IAlgoliaHit>) => ({
    ...hit,
    price: determineAlgoliaPrice(hit.prices, customerSegments, getProductType(hit), false, getIsRoxable(hit), false),
  }))

  if (item.idAction?.toLocaleLowerCase() === 'trendingfacets') {
    return <>{/* N.B.: PUT HERE THE TRENDING FACETS COMPONENT */}</>
  }

  return (
    <div className={styles.wrapper}>
      <CmsCarousel
        products={recommendationsWithPrices}
        description={item.description}
        title={carouselTitle}
        sliderProps={sliderProps}
        isAlgolia
      />
    </div>
  )
}

export default AlgoliaRecommendations
