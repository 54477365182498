import styled from '@mui/material/styles/styled'
import { StyledTypography } from '../../../../components/UI'

export const StyledFormContainer = styled('div', {
  name: 'PersonalInformation',
  slot: 'Form'
})(({ }) => ({
}))

export const StyledHeading = styled(StyledTypography, {
  name: 'PersonalInformation',
  slot: 'Heading'
})(({ }) => ({
  fontWeight: 700,
}))

