import React from 'react'
import { css, styled } from '@mui/material/styles'
import { PopperUnstyled } from '@mui/base'
import { PopperProps, Tooltip, tooltipClasses } from '@mui/material'

import type { CustomTooltipProps, ColorVariant } from './Tooltip.type'
import type { CustomTheme } from '../../../types/theme'
import { useState } from 'react'

type StyledPopperProps = PopperProps & { colorVariant: ColorVariant }

type ColorVariantMapped = {
  background: string
  text: string
  borderColor:string
  link: string
}

const mapColorVariantPopper = (
  theme: CustomTheme,
  variant: ColorVariant
): ColorVariantMapped => {
  const themeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    darkgray: {
      background: theme.palette.custom.grey,
      text: theme.palette.custom.white,
      borderColor:'',
      link:theme.palette.custom.white
    },
    lightgray: {
      background: theme.palette.custom.light.grey,
      text: theme.palette.text.dark.primary,
      borderColor:theme.palette.custom.light2.grey,
      link:theme.palette.custom.blue
    },
    primaryBlue: {
      background: theme.palette.custom.blue,
      text: theme.palette.custom.white,
      borderColor:'',
      link:theme.palette.custom.white
    },
    primaryLightBlue: {
      background: theme.palette.custom.light.blue,
      text: theme.palette.custom.cyprus,
      borderColor:'',
      link:theme.palette.custom.blue
    },
    white: {
      background: theme.palette.custom.white,
      text: theme.palette.custom.black,
      borderColor:theme.palette.custom.light2.grey,
      link:theme.palette.custom.blue
    },
    dark: {
      background: theme.palette.custom.cyprus,
      text: theme.palette.custom.white,
      borderColor:'',
      link:theme.palette.custom.white,
    },
    primary: {
      background: theme.palette.custom.halfBaked,
      text: theme.palette.custom.cyprus,
      borderColor:'',
      link:theme.palette.custom.cyprus,
    },
  }
  return themeStyles[variant]
}

export const PopperStyle = ({ theme, colorVariant}) => css({
  [`.${tooltipClasses.tooltip}`]: {
    maxWidth: '16.5rem',
    backgroundColor: mapColorVariantPopper(theme, colorVariant).background,
    color: mapColorVariantPopper(theme, colorVariant).text,
    border: `${mapColorVariantPopper(theme, colorVariant).borderColor ? `1px solid ${mapColorVariantPopper(theme, colorVariant).borderColor}`: 'none' }`,
    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: 1.33,
    fontWeight: 'normal',
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    margin:'0px !important',
    boxShadow: '15px 15px 32px 0px rgba(0, 0, 0, 0.15)',
    '.popover-body-container': {
      position:'relative',
      '&__title': {
        fontSize: theme.typography.body.fontSize,
        lineHeight: theme.spacing(6),
        fontWeight: 700,
        margin: 0,
      },
      '&__storeTitle': {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.spacing(6),
        fontWeight: 700,
        margin: 0,
        width:'max-content',
        maxWidth:'13.5rem'
      },
      '&__button':{
        position:'absolute',
        width:'1rem',
        height:'1rem',
        right:0,
        top:0,
        cursor:'pointer',
      },
      '&__body': {
        fontSize:theme.typography.body2.fontSize,
        lineHeight:theme.spacing(5),
        margin: 0,
        '&--spacing-up': {
          margin: `${theme.spacing(1)} 0 0 0`,
        },
      },
      '&--footer-container': {
        marginTop: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 600,
       ' &__cta > a': {
          fontSize:theme.typography.subtitle1.fontSize,
          color: mapColorVariantPopper(theme, colorVariant).link,
          textDecoration: 'underline',
        },
       ' &__phone > a': {
          fontSize:theme.typography.subtitle1.fontSize,
          color: mapColorVariantPopper(theme, colorVariant).link,
          textDecoration: 'underline',
        }
      }
    }
  },
  [`.${tooltipClasses.arrow}`]: {
    color: mapColorVariantPopper(theme, colorVariant).background,
    '&:before': {
      border: `${mapColorVariantPopper(theme, colorVariant).borderColor ? `1px solid ${mapColorVariantPopper(theme, colorVariant).borderColor}`: 'none' }`,
    }
  }
  })

  const TooltipContainer = styled('div', {
    name: 'Tooltip',
    slot: 'Container'
  })(() => ({
    display:'flex',
    cursor:'pointer',
    WebkitTapHighlightColor:'transparent'
  }))
  const TextContainer = styled('div')(() => ({
    cursor:'pointer',
    WebkitTapHighlightColor:'transparent'
  }))
  
export const PopperWithStyle = styled(PopperUnstyled, {
  slot: 'Tooltip',
  label: 'Popper',
  shouldForwardProp: (prop) => prop !== 'colorVariant'
})<StyledPopperProps>(PopperStyle)

export const TooltipWithStyle = ({
  placement,
  colorVariant,
  text,
  type,
  disablePortal,
  clickable = false,
  ...props
}: Omit<CustomTooltipProps, 'contentVariant'>) => {
  const [show, setShow] = useState<boolean>(false)
  const onMouseLeave = () => {
    clickable ? () => setShow(show) : type && type === 'storelocator' ? setTimeout(() => setShow(false), 900) : setShow(false)
  }
  return(
      <TooltipContainer
      className='Tooltip-root'
      onMouseLeave={onMouseLeave}
      onMouseOver={() => setShow(clickable ? show : true)}
      onTouchStart={() => setShow(clickable ? show : !show)}
      onClick={() => setShow(clickable ? !show : show)}
      >
          <Tooltip
          PopperComponent={(props) => <PopperWithStyle colorVariant={colorVariant} disablePortal={disablePortal} {...props} placement={placement}/>}
          arrow
          {...props}
          open={show}
          disableHoverListener
          onMouseLeave={onMouseLeave}
          onMouseOver={()=>setShow(true)}
          onTouchStart={()=>{setShow(!show)}}
          PopperProps={{popperOptions:{
            modifiers:[{
              name:'flip',
              options:{
                fallbackPlacements:['top','bottom'],
                allowedAutoPlacements:['top', 'bottom', 'right', 'left']
              }
            },
          {
            name:'offset',
            options:{
              offset:[0,12]
            }
          }
          ]
          }}}
          />
          <TextContainer>{text}</TextContainer>
      </TooltipContainer>
    )
  }
