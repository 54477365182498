import React, { FC, useEffect, useState } from 'react'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { Divider } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { generateAccountPagePath } from '../Account/Account'
import { userIdSelector } from '../../redux/selectors/user'
import { Loader, StyledLinkAsButton } from '../../components/UI'
import { Order } from '../../types/order'
import orderService from '../../foundation/apis/transaction/order.service'
import DateService from '../../services/DateService'
import { ACCOUNT_CHILDREN, CART } from '../../constants/routes'
import { useGetOrderHistoryQuery } from '../../features/orderhistory/query'

import {
  DashboardTitleWrapper,
  DashboardGridItemContainer,
  DashboardGridContainer,
  DashboardGridItemHeader,
  DashboardGridItemContent,
  DashboardGridItemHeaderWrapper,
  DashboardGridItemSubheader,
  DashboardLastOrderContent,
  DashboardLastOrderDetails,
  DashboardStyledAnchorCTA,
  DashboardLastOrderDetailsLabel,
  DashboardLastOrderDetailsItem,
  ShopNowAccountButton,
  DashboardGridItem,
  DashboardWrapper,
  DashboardLastOrderDetailsContainer,
} from './AccountDashboard.style'

import { useTranslation } from 'next-i18next'
import DashboardWishlist from './components/DashboardWishlist'
import { useSite } from '../../foundation/hooks/useSite'
import FormattedPriceDisplay from '../../components/formatted-price-display'
import { FilterOption, filterOptionToDate } from '../OrderHistory/components/orderhistoryfilter'
import { DashboardMyStores } from './components/DashboardMyStores'
import { useCopyOrderMutation } from '@features/order/query'
import { DashboardLastOrderHeader } from './components/DashboardLastOrderHeader'
import { resetReorderId, setReorderId } from '@features/order/slice'
import { useFrameGenius } from '@hooks/useFrameGenius'
import { StyledButton } from '@components/UI/Button/Button'
import { useRouter } from 'next/router'
import { sessionStorageUtil } from '@foundation/utils/storageUtil'
import { ANALYTICS_IS_REORDER, sendMyAccountEvent } from '@foundation/analytics/tealium/lib'
import { DEFAULT_DATE_FORMAT } from '@views/Subscription/constants'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const AccountDashboard: FC = () => {
  const { langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const userId = useSelector(userIdSelector)
  const {
    data: orders = [],
    isLoading,
    isFetching,
  } = useGetOrderHistoryQuery({
    userId,
    dateBefore: filterOptionToDate(FilterOption.LAST_6_MONTHS, langCode),
    sort: 'descending',
  })
  const [lastOrder, setLastOrder] = useState<Order | null>(null)
  const router = useRouter()
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const [performReorder, { isLoading: isReorderBusy }] = useCopyOrderMutation()
  const isBusy = isLoading || isFetching
  const fgData = useFrameGenius()
  const isMyStoreEnabled =
    mySite?.xStoreCfg?.locatorTenancy?.filter(tenancy => tenancy.langCode === langCode)[0]?.isEnabled === 'false'
      ? false
      : true

  useEffect(() => {
    if (orders?.length) {
      setLastOrder(orders[0])
    }
  }, [orders])

  const { ...analyticsDataForMyAccount } = useAnalyticsData(ANALYTICS_PAGE_TYPE.PROFILE)
  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsDataForMyAccount,
      Page_Section2: 'DashBoard',
    })
  }, [])

  const isOrderRoxable = orderService.isRoxable(lastOrder?.orderExtendAttribute)

  const onOrderAgain = (orderId: string, isRX: string) => {
    if (!orderId) return

    dispatch(setReorderId(orderId))
    sessionStorageUtil.set(ANALYTICS_IS_REORDER, true)
    performReorder({
      fromOrderId: orderId,
      rxCopyEnabled: isRX,
      newUserId: userId,
    })
      .unwrap()
      .then(() => {
        router.push(CART)
      })
      .finally(() => dispatch(resetReorderId()))
  }

  if (isBusy) {
    return <Loader />
  }

  return (
    <DashboardWrapper>
      <DashboardTitleWrapper>{t('AccountDashboard.Title')} </DashboardTitleWrapper>
      <DashboardGridContainer gap={1}>
        <DashboardGridItem xs={12} md={6} lg={6}>
          {!isBusy && !orders.length ? (
            <DashboardGridItemContainer>
              <DashboardGridItemHeaderWrapper>
                <SVGIcon library="global" name="bag-checkout" size={48} />
                <DashboardGridItemHeader>{t('AccountDashboard.MyOrders')}</DashboardGridItemHeader>
              </DashboardGridItemHeaderWrapper>

              <DashboardGridItemContent>{t('AccountDashboard.EmptyOrderHistory')}</DashboardGridItemContent>

              <ShopNowAccountButton dataElementId={'myOrdersCTA'} href={`/${langCode}/${t('Common.Shop.Glasses.Url')}`}>
                {t('AccountDashboard.ShopNow')}{' '}
              </ShopNowAccountButton>
            </DashboardGridItemContainer>
          ) : (
            <DashboardGridItemContainer>
              <DashboardGridItemHeaderWrapper>
                <DashboardGridItemHeader>{t('AccountDashboard.MyOrders')}</DashboardGridItemHeader>
                <DashboardGridItemSubheader>
                  {t('Order.NumberOfOrders', { count: orders.length })}
                </DashboardGridItemSubheader>
              </DashboardGridItemHeaderWrapper>

              {lastOrder && (
                <DashboardLastOrderContent>
                  <DashboardLastOrderHeader
                    order={lastOrder}
                    onReorder={() => onOrderAgain(lastOrder.orderId, isOrderRoxable)}
                    isReorderBusy={isReorderBusy}
                  />
                  <Divider flexItem />
                  <DashboardLastOrderDetailsContainer>
                    <DashboardLastOrderDetails>
                      <DashboardLastOrderDetailsLabel>{t('Order.Date')}</DashboardLastOrderDetailsLabel>
                      <DashboardLastOrderDetailsItem>
                        {DateService.format(lastOrder.placedDate || '', DEFAULT_DATE_FORMAT, mySite.locale)}
                      </DashboardLastOrderDetailsItem>
                      <DashboardLastOrderDetailsLabel>{t('Order.Status')}</DashboardLastOrderDetailsLabel>
                      <DashboardLastOrderDetailsItem>
                        {t(`Order.Status_.${lastOrder.orderStatus}`)}
                        {['V', 'E'].includes(lastOrder.orderStatus) && (
                          <DashboardStyledAnchorCTA href={`mailto:${t('OrderDetails.Labels.CustomerServiceEmail')}`}>
                            {t('Order.PendingPrescriptionCta')}
                          </DashboardStyledAnchorCTA>
                        )}
                      </DashboardLastOrderDetailsItem>
                      <DashboardLastOrderDetailsLabel>{t('OrderDetails.Total')}</DashboardLastOrderDetailsLabel>
                      <DashboardLastOrderDetailsItem>
                        <FormattedPriceDisplay min={+lastOrder.grandTotal} currency={lastOrder.grandTotalCurrency} />
                      </DashboardLastOrderDetailsItem>
                    </DashboardLastOrderDetails>
                  </DashboardLastOrderDetailsContainer>
                </DashboardLastOrderContent>
              )}

              <StyledLinkAsButton
                dataElementId={'myOrdersCTA'}
                href={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY)}
              >
                {t('AccountDashboard.ViewAllOrders')}
              </StyledLinkAsButton>
            </DashboardGridItemContainer>
          )}
        </DashboardGridItem>
        {isMyStoreEnabled && (
          <DashboardGridItem xs={12} md={6} lg={6}>
            <DashboardGridItemContainer>
              <DashboardMyStores />
            </DashboardGridItemContainer>
          </DashboardGridItem>
        )}
        <DashboardGridItem xs={12} md={6} lg={6}>
          <DashboardWishlist />
        </DashboardGridItem>
        <DashboardGridItem xs={12} md={6} lg={6}>
          <DashboardGridItemContainer>
            <DashboardGridItemHeaderWrapper>
              <SVGIcon library="global" name="look" size={48} />
              <DashboardGridItemHeader>{t('AccountDashboard.FindYourLook')}</DashboardGridItemHeader>
            </DashboardGridItemHeaderWrapper>

            <DashboardGridItemContent>{t('AccountDashboard.FindTheBest')}</DashboardGridItemContent>
            <StyledButton onClick={fgData?.openFrameAdvisor}>{t('AccountDashboard.FindYourLookButton')}</StyledButton>
          </DashboardGridItemContainer>
        </DashboardGridItem>
      </DashboardGridContainer>
    </DashboardWrapper>
  )
}

export default AccountDashboard
