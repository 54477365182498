import styled from '@mui/material/styles/styled'
import { Typography } from '@mui/material'

export const StyledSubmitSuccessMessageWrapper = styled('div', {
  name: 'SubmitSuccess',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.color.success,
  columnGap: theme.spacing(1),
}))

export const StyledSubmitSuccessMessage = styled(Typography, {
  name: 'SubmitSuccess',
  slot: 'Message',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  lineHeight: 1.5,
}))
