import { ADDRESS_BILLING, APARTMENT_CHECK, BUZZER_CODE, CHECKOUT } from '../../constants/common'
//UI
import {
  CountryText,
  CountryTitle,
  StyledAddressForm,
  StyledGridContainer,
  StyledGridItem,
  StyledGridItemAdditionalText,
  StyledGridItemErrorMsg,
  StyledPhoneErrorMsg,
  StyledTextField,
  WrapperCountry,
} from './AddressForm.style'
import { FormControlLabel, MenuItem } from '@mui/material'
//Custom libs
import { Controller, UseFormReturn, useFormState } from 'react-hook-form'
//Standard libraries
import React, { FC, useEffect, useMemo } from 'react'

import { AddressFormData } from '../../types/form'
import { AddressSuggester } from './AddressSuggester'
import { CheckoutAddressFormField } from '../../types/checkout'
import { ZipCodeSuggester } from './ZipCodeSuggester'
import { countriesListSelector } from '../../redux/selectors/account'
import { useGoogleSuggestions } from '../../hooks/useGoogleSuggestions'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { Trans, useTranslation } from 'next-i18next'
import { StyledCheckbox } from '../UI'
import { loginStatusSelector } from '../../redux/selectors/user'
import addressUtil from '@utils/addressUtil'

export interface AddressFormProps {
  addressFormFields: CheckoutAddressFormField[]
  addressType?: AddressFormData['addressType']
  cid: string
  edit?: boolean
  form: UseFormReturn
  formName?: string
  page?: string
  resetForm?: boolean
  onFormValidationStatusChanged?: (type: AddressFormData['addressType'], isValid: boolean) => void
  onFormDataChanged?: (type: AddressFormData['addressType'], data: AddressFormData) => void
}

/**
 * Address Form component
 * to be resued to display form inputs on a shipping or billing address form
 * @param props
 */

const AddressForm: FC<AddressFormProps> = ({
  addressFormFields,
  addressType,
  cid,
  form,
  formName,
  page = CHECKOUT, // Default page is checkout
  onFormValidationStatusChanged,
  onFormDataChanged,
}) => {
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const { t } = useTranslation()
  const countriesList = useSelector(countriesListSelector)
  const isLoggedIn = useSelector(loginStatusSelector)

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form
  const addressFormData = getValues()
  const { isValid } = useFormState({ control })

  const googleSuggestions = useGoogleSuggestions({
    addressType: addressType || '',
    country: storeCountry || '',
    page,
    state: addressFormData?.state || '',
  })

  const { googleAPISuggestionEnabled, gmapCountry, gmapLoaded, provinces } = googleSuggestions

  useEffect(() => {
    const subscription = watch(value => {
      setTimeout(() => {
        onFormDataChanged && onFormDataChanged(addressType, { ...value })
      }, 300)
    })

    return () => subscription.unsubscribe()
  }, [watch])

  useMemo(() => {
    setTimeout(() => {
      onFormValidationStatusChanged && onFormValidationStatusChanged(addressType, isValid)
    }, 300)
  }, [isValid])

  return (
    <StyledAddressForm id={`address_form_5_${cid}`} name={formName || `address_form_5_${cid}`} noValidate>
      <StyledGridContainer>
        {addressFormFields?.map(({ fieldName, ...fieldData }, i) => {
          const key = `${cid}-${fieldName}-${i}`
          let fieldType: string
          const isName = fieldName === 'firstName' || fieldName === 'lastName'
          const isAddressLine2 = fieldName === 'addressLine2'
          const isCity = fieldName === 'city'
          const isCountry = fieldName === 'country'
          const isState = fieldName === 'state'
          const isZipCodeField = fieldName === 'zipCode'

          switch (fieldName) {
            case 'email1':
              fieldType = 'email'
              break
            case 'phone1':
              fieldType = 'tel'
              break
            default:
              fieldType = 'text'
          }

          if (fieldName === 'addressLine1' && googleAPISuggestionEnabled && !!window.google) {
            const actualFieldName = addressType === ADDRESS_BILLING ? 'billingAddressLine1' : fieldName
            return (
              <StyledGridItem xs={12} md={12} lg={12} key={key}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({ field: { value, ref, ...rest }, fieldState: { error, isTouched } }) => (
                    <AddressSuggester
                      addressFormFields={addressFormFields}
                      country={gmapCountry}
                      inputRef={ref}
                      mandatoryField={fieldData.mandatory}
                      gMapLoaded={gmapLoaded}
                      value={value}
                      formControl={control}
                      cid={`${fieldName}-${addressType}`}
                      fieldName={fieldName}
                      fieldLabel={t(`AddressForm.Labels.${actualFieldName}`)}
                      error={!!error}
                      formErrors={{}}
                      onSuggestedFieldValueChanged={(fieldName, value) => {
                        setValue(fieldName, value, {
                          shouldValidate: true,
                        })
                      }}
                      ispristine={!isTouched}
                      {...rest}
                    />
                  )}
                />
                {errors[fieldName]?.message && (
                  <StyledGridItemErrorMsg>{errors[fieldName]?.message?.toString()}</StyledGridItemErrorMsg>
                )}
                <Controller
                  name={APARTMENT_CHECK}
                  control={control}
                  render={({ field: { value, ref, ...rest } }) => (
                    <FormControlLabel
                      control={<StyledCheckbox value={value} checked={value} inputRef={ref} {...rest} />}
                      label={t('AddressForm.Labels.apartmentCheck')}
                      style={{ margin: 0 }}
                    />
                  )}
                />
              </StyledGridItem>
            )
          } else if (fieldName === 'zipCode' && googleAPISuggestionEnabled && !!window.google) {
            return (
              <StyledGridItem xs={6} md={6} lg={6} key={key}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({ field: { value, ref, ...rest }, fieldState: { error, isTouched } }) => (
                    <ZipCodeSuggester
                      addressFormFields={addressFormFields}
                      country={gmapCountry}
                      inputRef={ref}
                      mandatoryField={fieldData.mandatory}
                      gMapLoaded={gmapLoaded}
                      value={value}
                      formControl={control}
                      cid={`${fieldName}-${addressType}`}
                      fieldName={fieldName}
                      fieldLabel={t(`AddressForm.Labels.${fieldName}`)}
                      error={!!error}
                      formErrors={{}}
                      ispristine={!isTouched}
                      onSuggestedFieldValueChanged={(fieldName, value) => {
                        setValue(
                          fieldName,
                          fieldName === 'zipCode' ? addressUtil.formatZipCode(value, storeCountry) : value,
                          {
                            shouldValidate: true,
                          }
                        )
                      }}
                      {...rest}
                    />
                  )}
                />
                {errors[fieldName]?.message && (
                  <Trans>
                    <StyledGridItemErrorMsg>{errors[fieldName]?.message?.toString()}</StyledGridItemErrorMsg>
                  </Trans>
                )}
              </StyledGridItem>
            )
          } else if (isState && provinces && provinces.length > 0) {
            return (
              <StyledGridItem xs={12} md={6} lg={6} key={key}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({ field: { value, ref, ...rest }, fieldState: { error } }) => {
                    return (
                      <StyledTextField
                        select
                        InputLabelProps={{ required: fieldData.mandatory }}
                        {...rest}
                        key={`${cid}-${fieldName}`}
                        inputRef={ref}
                        value={value}
                        required={fieldData.mandatory}
                        id={`${cid}-${fieldName}`}
                        type={fieldType}
                        label={t(`AddressForm.Labels.${fieldName}`)}
                        error={!!error}
                        fullWidth
                        className={`${isState ? 'state-field' : ''}`}
                        onChange={event => {
                          setValue(fieldName, event.target.value, {
                            shouldValidate: true,
                          })
                        }}
                        ispristine={value ? (value as string).length <= 0 : true}
                      >
                        {(!provinces || provinces.length <= 0) && <option>&nbsp;</option>}
                        {provinces?.map(provinceState => (
                          <MenuItem key={provinceState.code} value={provinceState.code || ''}>
                            {provinceState.displayName}
                          </MenuItem>
                        ))}
                      </StyledTextField>
                    )
                  }}
                />
                {errors[fieldName]?.message && (
                  <StyledGridItemErrorMsg>{errors[fieldName]?.message?.toString()}</StyledGridItemErrorMsg>
                )}
              </StyledGridItem>
            )
          } else if (isCountry) {
            const currentCountryInfo = countriesList && countriesList.find(val => val.code === getValues('country'))
            const currentCountry = currentCountryInfo?.displayName || ''
            return (
              <StyledGridItem xs={12} md={6} lg={6} key={key}>
                <WrapperCountry>
                  <CountryTitle>{t(`AddressForm.Labels.${fieldName}`)}</CountryTitle>
                  <CountryText>{currentCountry}</CountryText>
                </WrapperCountry>
              </StyledGridItem>
            )
          } else if (isAddressLine2) {
            return (
              <React.Fragment key={key}>
                <StyledGridItem xs={12} md={9}>
                  <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { value, ref, ...rest }, fieldState: { error, isTouched } }) => (
                      <StyledTextField
                        InputLabelProps={{ required: fieldData.mandatory }}
                        id={`${cid}-${fieldName}`}
                        inputRef={ref}
                        value={value}
                        required={fieldData.mandatory}
                        type={fieldType}
                        inputProps={fieldType === 'tel' ? { maxLength: 16 } : undefined}
                        label={t('AddressForm.Labels.address2')}
                        error={!!error}
                        helperText={errors[fieldName]?.message?.toString()}
                        fullWidth
                        showvalidationstatus
                        ispristine={!isTouched}
                        {...rest}
                        onChange={event => {
                          // Allow alphanumeric characters, spaces, commas, periods, dashes and hash symbols
                          setValue(fieldName, event.target.value.replace(/[^A-Za-z0-9, .\-#]/g, ''), {
                            shouldValidate: true,
                          })
                        }}
                      />
                    )}
                  />
                </StyledGridItem>
                <StyledGridItem xs={12} md={3}>
                  <Controller
                    name={BUZZER_CODE}
                    control={control}
                    render={({ field: { value, ref, ...rest }, fieldState: { error, isTouched } }) => (
                      <StyledTextField
                        InputLabelProps={{
                          required: fieldData.mandatory,
                        }}
                        id={`${cid}-${BUZZER_CODE}`}
                        inputRef={ref}
                        value={value}
                        required={fieldData.mandatory}
                        type={fieldType}
                        inputProps={fieldType === 'tel' ? { maxLength: 16 } : undefined}
                        label={t('AddressForm.Labels.buzzerCode')}
                        error={!!error}
                        helperText={errors[BUZZER_CODE]?.message?.toString()}
                        fullWidth
                        showvalidationstatus
                        ispristine={!isTouched}
                        {...rest}
                        onChange={event => {
                          // Allow only numbers
                          setValue(BUZZER_CODE, event.target.value.replace(/\D/g, ''), {
                            shouldValidate: true,
                          })
                        }}
                      />
                    )}
                  />
                </StyledGridItem>
              </React.Fragment>
            )
          } else {
            return (
              <StyledGridItem xs={12} md={isName || isCity || isState || isZipCodeField ? 6 : 12} key={key}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({ field: { value, ref, ...rest }, fieldState: { error, isTouched } }) => (
                    <StyledTextField
                      autoComplete="no"
                      InputLabelProps={{ required: fieldData.mandatory }}
                      {...rest}
                      id={`${cid}-${fieldName}`}
                      inputRef={ref}
                      value={value}
                      disabled={page === CHECKOUT && isLoggedIn && fieldType === 'email' && value !== ''}
                      required={fieldData.mandatory}
                      type={fieldType}
                      inputProps={fieldType === 'tel' ? { maxLength: 16 } : undefined}
                      label={t(`AddressForm.Labels.${fieldName}`)}
                      error={!!error}
                      onChange={event => {
                        if (
                          isName &&
                          fieldData.validation &&
                          new RegExp(fieldData.validation.toString()).test(event.target.value)
                        ) {
                          setValue(fieldName, event.target.value, {
                            shouldValidate: true,
                          })
                        } else if (!isName) {
                          setValue(fieldName, event.target.value, {
                            shouldValidate: true,
                          })
                        }
                      }}
                      helperText={
                        fieldType === 'tel' && (
                          <Trans>
                            <StyledGridItemAdditionalText>
                              {t('AddressForm.Msgs.AdditionalMsgs.phone1')}
                            </StyledGridItemAdditionalText>
                          </Trans>
                        )
                      }
                      fullWidth
                      showvalidationstatus
                      ispristine={!isTouched}
                    />
                  )}
                />
                {fieldType === 'tel' && (
                  <StyledPhoneErrorMsg isVisible={!!errors[fieldName]?.message}>
                    {errors[fieldName]?.message?.toString()}
                  </StyledPhoneErrorMsg>
                )}
                {errors[fieldName]?.message && fieldType !== 'tel' && (
                  <StyledGridItemErrorMsg>{errors[fieldName]?.message?.toString()}</StyledGridItemErrorMsg>
                )}
              </StyledGridItem>
            )
          }
        })}
      </StyledGridContainer>
    </StyledAddressForm>
  )
}

export default AddressForm
