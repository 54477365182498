import {
  CART,
  CHECKOUT,
  CHECKOUT_PAYMENT,
  CHECKOUT_REORDER_SUMMARY,
  CHECKOUT_SHIPPING,
  CHECKOUT_UPLOAD_PRESCRIPTION,
  ORDER_CONFIRMATION,
  ORDER_RETURN,
  ORDER_RETURN_CONFIRMATION,
  ORDER_RETURN_PRINT,
  ORDER_RETURN_SELECT,
} from '../constants/routes'

export const getCheckoutPaths = (country: string) => {
  return {
    checkout: `/${country}/${CHECKOUT}`,
    cart: `/${country}/${CART}`,
    shipping: `/${country}/${CHECKOUT_SHIPPING}`,
    payment: `/${country}/${CHECKOUT_PAYMENT}`,
    'reorder-summary': `/${country}/${CHECKOUT_REORDER_SUMMARY}`,
    'order-confirmation': `/${country}/${ORDER_CONFIRMATION}`,
    uploadPrescription: `/${country}/${CHECKOUT_UPLOAD_PRESCRIPTION}`,
  }
}

export const getOrderReturnPaths = (country: string) => {
  return {
    create: `/${country}/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`,
    confirmation: `/${country}/${ORDER_RETURN}/${ORDER_RETURN_CONFIRMATION}`,
    print: `/${country}/${ORDER_RETURN}/${ORDER_RETURN_PRINT}`,
  }
}

export const isReOrderSummary = (path: string) => path.includes(CHECKOUT_REORDER_SUMMARY)

export const getUrlParams = () => {
  return new URLSearchParams(location.search)?.toString()
}
