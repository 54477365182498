import styled from '@mui/material/styles/styled'

export const OrderRecapEditBagWrapper = styled('div', {
  name: 'OrderRecapEditBag',
  slot: 'OrderRecapEditBagWrapper',
})(({ theme }) => ({
  paddingTop: theme.spacing(4)
}))

export const OrderRecapCartContent = styled('div', {
  name: 'OrderRecapEditBag',
  slot: 'OrderRecapCartContent',
})(({ }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const OrderRecapTitleContent = styled('div', {
  name: 'OrderRecapEditBag',
  slot: 'TitleContent',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 16,
  fontWeight: 'bold',
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  justifyContent: 'center',

  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(13),
  },
}))

export const OrderRecapLinksContent = styled('div', {
  name: 'OrderRecapEditBag',
  slot: 'LinksContent',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  [theme.breakpoints.up('sm')]: {
    width: '60%',
  },
  '& a, button': {
    marginLeft: 0,
    marginRight: 0,
  }
}))
