'use client'
//Standard libraries
import React, { useEffect } from 'react'
import { get, isEmpty } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'

import Log from '../../services/Log'

//Redux
import { RESET_ERROR_ACTION } from '../../redux/actions/error'
//UI
import { SnackbarOrigin } from '@mui/material'
import { genericErrorSelector } from '../../redux/selectors/error'
import { useCSRForUser } from '../../foundation/hooks/useCSRForUser'
//Custom libraries

import { useTranslation } from 'next-i18next'
import MessageSnackbar from './MessageSnackbar'

const useErrorMessageSnackbar = () => {
  const anchorOrigin: SnackbarOrigin = {
    horizontal: 'center',
    vertical: 'bottom',
  }

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const error: any = useSelector(genericErrorSelector)
  const {
    handleLockOrderError,
    isOrderLockError,
    isAuthenticationError,
    isInternalServerError,
    isCookieExpiredError,
    isWunderkindLoginSyncError,
    isOutOfStockError,
  } = useCSRForUser()

  let errorKeys: string[] = []
  let errorKey = error.errorKey || ''
  let errorCode = error.errorCode
  let errorParameters = error.errorParameters
  let errorParameterKey: string | undefined = undefined

  const isI18nReady = Object.keys(i18n).length > 0

  if (typeof errorParameters === 'string') {
    errorParameters = errorParameters.split(',')
  }
  if (!errorParameters) {
    errorParameters = []
  }

  if (errorCode && errorCode !== errorKey) {
    errorKey = errorKey + '_' + errorCode
  }

  if (errorParameters.length > 0) {
    //user first item in parameters as part of key
    errorParameterKey = errorKey + '_' + errorParameters[0]
  }

  if (errorParameterKey) {
    const _errorParameterKey = `error-message.${errorParameterKey}`
    if (isI18nReady && i18n.exists(_errorParameterKey)) {
      errorKeys.push(_errorParameterKey)
    }
  }
  if (isI18nReady && i18n.exists(`error-message.${errorKey}`)) {
    errorKeys.push(`error-message.${errorKey}`)
  }

  let errorMessage = error.errorMessage || error.message
  if (errorKeys.length > 0) {
    errorMessage = t(errorKeys, { ...errorParameters })
  }

  const handleClose = () => {
    dispatch(RESET_ERROR_ACTION())
  }

  useEffect(() => {
    let logErrorUrl = get(errorParameters, 'url', '')
    let logErrorMessage = errorMessage ? errorMessage : ''
    if (!isEmpty(logErrorUrl) || !isEmpty(logErrorMessage)) {
      Log.error(logErrorMessage, logErrorUrl)
    }
  }, [error])

  return {
    anchorOrigin,
    handleClose,
    errorMessage,
    error,
    isOrderLockError,
    handleLockOrderError,
    isAuthenticationError,
    isInternalServerError,
    isCookieExpiredError,
    isWunderkindLoginSyncError,
    isOutOfStockError,
  }
}

const ErrorMessageSnackbar = () => {
  const {
    anchorOrigin,
    handleClose,
    errorMessage,
    error,
    isOrderLockError,
    handleLockOrderError,
    isAuthenticationError,
    isInternalServerError,
    isCookieExpiredError,
    isWunderkindLoginSyncError,
    isOutOfStockError,
  } = useErrorMessageSnackbar()
  if (isOrderLockError(error)) {
    handleLockOrderError(error)
  }

  return (
    <>
      {!isOrderLockError(error) &&
        !isAuthenticationError(error) &&
        !isCookieExpiredError(error) &&
        !isWunderkindLoginSyncError(error) &&
        !isOutOfStockError(error) &&
        !isInternalServerError(error) && ( // Do not show snackbar notification to end user for internal server error
          <MessageSnackbar
            handleClose={handleClose}
            anchorOrigin={anchorOrigin}
            severity="error"
            message={errorMessage}
            ClickAwayListenerProps={{
              mouseEvent: 'onMouseDown',
            }}
          />
        )}
    </>
  )
}

export default ErrorMessageSnackbar
