import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { PhoneNumberField } from '../PhoneNumberField'
import { FormControlHelperText } from './FormControlHelperText'
import { FormControlProps } from './types'

type FormControlPhoneNumberFieldProps = Omit<FormControlProps, 'placeholder' | 'inputProps' | 'customInputProps'>

export const FormControlPhoneNumberField: React.FC<FormControlPhoneNumberFieldProps> = ({
  id,
  name,
  rules,
  label,
  description,
}) => {
  const { control, setValue, formState: { isSubmitted } } = useFormContext()
  const required = rules ? (rules['required']?.value === true || rules['required'] === true) : false

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
        <>
          <PhoneNumberField
            {...rest}
            id={id}
            label={`${label}${required ? ' *': ''}`}
            showFlag={true}
            inputRef={ref}
            value={value && value.indexOf('(') > -1 ? '(' + value.split('(')[1] : ''}
            fixedCountryCode={value && value.indexOf('(') > -1 ? value.split('(')[0] : ''}
            useUndecoratedValue={false}
            onValueChange={(newValue) => {
              setValue(name, newValue, { shouldValidate: isSubmitted })
            }}
            error={!!error}
          />
          <FormControlHelperText description={description} error={error} />
        </>
      )}
    />
  )
}
