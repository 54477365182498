import React from 'react'
import { IOrderDetails, OrderItem, OrderItemsAttachments } from '../../../types/order'
import OrderRecapItems from '../../../components/order-recap/OrderRecapItems'
import { getParsedOrderRecapItems } from '../../../utils/order'
import { OrderDetailsListContainer } from './OrderDetailsHeader.style'

export interface OrderDetailsItemListProps {
  orderDetails: IOrderDetails
  orderItems: OrderItem[]
}

export const OrderDetailsItemList: React.FC<OrderDetailsItemListProps> = ({ orderDetails, orderItems }) => {
  const parsedOrderRecapItems = getParsedOrderRecapItems(orderDetails.detailedOrderItems) || []
  const orderItemsImages = {} as OrderItemsAttachments
  orderItems?.forEach(orderItem => {
    const { productId } = orderItem
    const attachments = orderDetails.x_data?.productDetails?.find(product => product.id === productId)?.attachments

    orderItemsImages[productId] = attachments || []
  })

  return (
    <>
      {orderItems && (
        <OrderDetailsListContainer>
          <OrderRecapItems
            orderItems={parsedOrderRecapItems}
            orderItemsAttachments={orderItemsImages}
            orderId={orderDetails.orderId}
          />
        </OrderDetailsListContainer>
      )}
    </>
  )
}
