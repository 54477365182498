import { APP_MAIN_CONTAINER_CLASSNAME, APP_MAIN_CONTAINER_ID } from '@constants/ui'
import dynamic from 'next/dynamic'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PAGE_TYPES } from '../../foundation/analytics/tealium/pageTypes'
import LoginGuard from '../../foundation/guard/LoginGuard'
import { AppMain, AppWrapper } from './components/BaseLayout.style'
//UI
import { openDrawerMegaMenuSelector, openDrawerSearchSelector } from '@features/ui/selector'
import config from '../../configs'
import { getPreviewSelector } from '../../features/preview/selector'
//Custom libraries
import { setOpenDrawerNewsLetter } from '@features/ui/action'
import styled from '@mui/material/styles/styled'
// Tealium
import { TealiumService } from '../../foundation/analytics/tealium'
import { getUserToken, useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
//hooks
import { usePrescriptionLenses } from '@components/PrescriptionLenses/PrescriptionLensesContext'
import { useForter } from '@foundation/forter/useForter'
import { useRouterChange } from '@hooks/useRouterChange/useRouterChange'
import HeaderSelector from '@layouts/Header/HeaderSelector'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { usePageType } from '../../foundation/hooks/usePageType'
import { useSite } from '../../foundation/hooks/useSite'
import { useMonetate } from '../../foundation/monetate/useMonetate'
import SocialLoginProvider from '@views/SignUp/provider'
import BaseLayoutSkeleton from './BaseLayoutSkeleton'
import { BaseLayoutHead } from './components/BaseLayoutHead'
import { ChatButton } from '@foundation/genesys'
import { useFrameGenius } from '@hooks/useFrameGenius'
import { useWordLift } from '@foundation/wordLift/WordLift'
import { useSubscription } from '@views/Subscription/hooks/useSubscription'
import { useDirectBilling } from '@components/DirectBilling/hooks/useDirectBilling'
import useScrollRestoration from '../../hooks/useScrollRestoration'
import { ON_BEHALF_RELOAD_STATUS } from '@foundation/constants/common'

const FrameAdvisor = dynamic(() => import('@components/FrameGenius/FrameAdvisor'), {
  ssr: false,
})
const SizeAdvisor = dynamic(() => import('@components/FrameGenius/SizeAdvisor'), {
  ssr: false,
})

const Footer = dynamic(() => import('../Footer'), {
  ssr: false,
})

const Prescription = dynamic(() => import('@components/PrescriptionLenses/Prescription').then(m => m.Prescription), {
  ssr: false,
})

const ErrorMessageSnackbar = dynamic(() => import('@components/message-snackbar/ErrorMessageSnackbar'), {
  ssr: false,
})

const TopBar = dynamic(() => import('../../components/TopBar'), {
  ssr: false,
})

const StyledContent = styled('div', {
  name: 'App',
  slot: 'Content',
  shouldForwardProp: prop => prop !== 'pageType' && prop !== 'orderConfirmationPage',
})<{ pageType?: string; orderConfirmationPage?: boolean }>(({ theme, pageType, orderConfirmationPage }) => ({
  width: '100%',
  minHeight: pageType === 'cart' ? 'calc(100vh - 15rem)' : '100vh',
  backgroundColor:
    pageType === 'checkout' && !orderConfirmationPage ? theme.palette.background.light.secondary : 'unset',

  [theme.breakpoints.up('sm')]: {
    minHeight: pageType === 'cart' ? 'calc(100vh - 40rem)' : 'calc(100vh - 56px)',
  },

  [theme.breakpoints.up('md')]: {
    minHeight: pageType === 'cart' ? 'calc(100vh - 35rem)' : 'calc(100vh - 56px)',
  },
}))

const AppMainWrapper: React.FC<PropsWithChildren & { routerPath: string }> = ({ children, routerPath }) => {
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)
  const megaMenuDrawer = useSelector(openDrawerMegaMenuSelector)
  const openElements = isSearchDrawerOpen || megaMenuDrawer.isOpen /*TODO RESTORE|| vMisOpen */
  const scrollContainerRef = useRef<HTMLElement>(null)

  useScrollRestoration({ routerPath, scrollContainerRef, scrollPageType: 'plp', backPageType: 'pdp' })

  return (
    <AppMain
      id={APP_MAIN_CONTAINER_ID}
      className={APP_MAIN_CONTAINER_CLASSNAME}
      openElements={openElements}
      data-cs-scroll-container
      ref={scrollContainerRef}
    >
      {children}
    </AppMain>
  )
}

const BaseLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const getPreview = useSelector(getPreviewSelector)
  const { isRouterChange } = useRouterChange()
  const topRef = useRef<HTMLDivElement>(null)
  const { mySite } = useSite()
  const { pageType, ignoredBaseLayoutSkeletonPages, isScrollToTopPage } = usePageType()
  const { ...analyticsCommonData } = useAnalyticsData(PAGE_TYPES.home)
  const query = useSearchParams()
  const [isOnBehalfSession, setIsOnBehalfSession] = useState(query.get('onBehalfSession') === 'true')
  const disableThirdParties = query.get('disableThirdParties') === 'true' ?? false
  const { seoAltUrl } = children?.['props']
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const orderConfirmationPage = router.asPath.includes('order-confirmation')
  const fgData = useFrameGenius()

  // init analytics
  const Analytics = useCallback(() => {
    analyticsCommonData.userToken = getUserToken()
    return new TealiumService(mySite, Date.now().toString(), analyticsCommonData)
  }, [analyticsCommonData, mySite])

  useForter(mySite)
  useMonetate()
  useWordLift()
  useSubscription()
  useDirectBilling()

  const { isOpenPrescriptionLensesForm } = usePrescriptionLenses()

  useEffect(() => {
    if (isScrollToTopPage) {
      document.querySelector(`#${config.name}-app__main`)?.scrollTo({ top: 0 })
    }
  }, [isScrollToTopPage, isRouterChange, query, pageType])

  useEffect(() => {
    if (!isOnBehalfSession)
      setIsOnBehalfSession(
        typeof window !== 'undefined' ? localStorage.getItem(ON_BEHALF_RELOAD_STATUS) === 'done' : false
      )
  }, [isOnBehalfSession])

  useEffect(() => {
    if (window && window.tealium_data2track) {
      setScriptLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (!disableThirdParties && !scriptLoaded && config.enableAnalytics && mySite) {
      Analytics()
      window.dispatchEvent(new Event('tealiumInit'))
    }
  }, [Analytics, disableThirdParties, mySite, scriptLoaded])

  useEffect(() => {
    dispatch(setOpenDrawerNewsLetter(false))
  }, [mySite])

  return (
    <AppWrapper data-testid="app-wrapper" isPreview={getPreview.isEnabled} isOnBehalfSession={isOnBehalfSession}>
      <BaseLayoutHead />
      {isOnBehalfSession && <TopBar />}
      <AppMainWrapper routerPath={router.asPath}>
        <HeaderSelector pageType={pageType} orderConfirmationPage={orderConfirmationPage} />
        <section ref={topRef} className="full-viewport-height" id="main-viewport">
          <LoginGuard />
          <StyledContent pageType={pageType} orderConfirmationPage={orderConfirmationPage} className={'main'}>
            <SocialLoginProvider>
              {isRouterChange && !ignoredBaseLayoutSkeletonPages ? <BaseLayoutSkeleton /> : children}
            </SocialLoginProvider>
          </StyledContent>
        </section>
        <Footer seoAltUrl={seoAltUrl} />
        <ChatButton />
        <ErrorMessageSnackbar />
        {fgData.isFrameAdvisorOpen && <FrameAdvisor />}
        {fgData.isSizeAdvisorOpen && <SizeAdvisor />}
        {isOpenPrescriptionLensesForm && <Prescription />}
      </AppMainWrapper>
      <div id="vmPortal"></div>
    </AppWrapper>
  )
}

export default BaseLayout
