import { ITEM_DETAILS_PROFILE_NAME, PROFILE_NAME_PARAM } from '../../constants/common'
import React, { createRef, FC, useEffect, useRef, useState } from 'react'
import {
  StyledWishlistLinkCta,
  WishlistAsideSection,
  WishlistContainer,
  WishlistDescription,
  WishlistEmptyButtonsContainer,
  WishlistHeader,
  WishlistItemsContainer,
  WishlistMainSection,
  WishlistSignInAlert,
  WishlistCta,
  WishlistTitle,
} from './styles/Wishlist.style'
import { Trans, useTranslation } from 'next-i18next'
import {
  isWishlistLoadingSelector,
  wishlistEnabledSelector,
  wishlistExternalIdSelector,
  wishlistItemsSelector,
  wishlistProductsSelector,
} from '../../features/wishList/selector'
import { useDispatch, useSelector } from 'react-redux'

import { StyledAlert } from '../../components/UI'
import { HOME, CART } from '@constants/routes'
import { useRouter } from 'next/navigation'
import RegistrationDialog from '../../components/RegistrationDialog'
import { IProduct } from '../../types/product'
import { SignInLayout } from '../SignIn/components/SignInLayout'
import { fetchWishlistAction } from '../../features/wishList/action'
import { loginStatusSelector } from '../../redux/selectors/user'
import productsService from '../../foundation/apis/search/products.service'
import { VIRTUAL_PAGE_VIEW_EVENT_ID, sendAnalyticEventRaw } from '../../foundation/analytics/tealium/lib'
import { setOpenModalRegistration } from '../../features/ui/action'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
import { StyledAlertHeader, StyledAlertMessage } from '../../components/UI/Alert'
import { IAlert } from '../../components/UI/Alert/Alert'
import WishlistItem from './WishlistItem'
import { toggleAddToCart } from '../../features/product/slice'
import { cartSelector, isAddingItemSelector } from '../../features/order/selector'
import CartSkeletonLoader from '../Cart/components/CartSkeletonLoader'
import { Prescription } from '../../components/PrescriptionLenses/Prescription'
import { prescriptionLensFormOpenSelector } from '../../features/rox/roxSlice'
import { useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import { WishListData } from '../../foundation/analytics/tealium/interfaces'
import { getProductsForAnalytics } from '../../foundation/analytics/tealium/formatters/productFormatter'
import { addItemToWishlist, removeFromWishList } from '../../hooks/useWishlistMethods'
import Log from '@services/Log'
import { getAllProductAttributes } from '@utils/productAttributes'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { useTheme } from '@mui/material'
export interface IWishListItem {
  location: string
  partNumber: string
  quantityRequested: string
}

const Wishlist: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const router = useRouter()
  const { langCode, langId, storeId } = useStoreIdentity()
  const wishlistExternalId = useSelector(wishlistExternalIdSelector)
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const isWishlistLoading = useSelector(isWishlistLoadingSelector)
  const wishlistItems = useSelector(wishlistItemsSelector)
  const wishlistProducts = useSelector(wishlistProductsSelector)
  const loggedIn = useSelector(loginStatusSelector) || false
  const [products, setProducts] = useState<IProduct[]>(wishlistProducts || [])
  const [areProductsLoading, setProductsLoading] = useState<boolean>(false)
  const ANONYMOUS_WISH_LIST_STORAGE_NAME = 'anonymousWishList'
  const [alert, setAlert] = useState<IAlert | null>()
  const theme = useTheme()
  const ctaRefs = useRef(wishlistItems.map(() => createRef<HTMLDivElement>()))
  const isAddingToCart = useSelector(isAddingItemSelector)
  const isPrescriptionLensFormOpen = useSelector(prescriptionLensFormOpenSelector)
  const analyticsData = useAnalyticsData('accountWishlist')
  const cart = useSelector(cartSelector)

  const removeFromWishlist = async (productId: string) => {
    removeFromWishList(wishlistExternalId, productId, dispatch)
  }

  const saveUserAnonymousWishList = async () => {
    if (loggedIn) {
      const anonymousList = localStorageUtil.get(ANONYMOUS_WISH_LIST_STORAGE_NAME)
      const items: IWishListItem[] = []

      anonymousList?.forEach(listItem => {
        items.push({ location: listItem.location, partNumber: listItem.partNumber, quantityRequested: '1' })
      })

      if (items.length > 0) {
        addItemToWishlist(items, langId, dispatch)
        localStorageUtil.remove(ANONYMOUS_WISH_LIST_STORAGE_NAME)
      }
    } else {
      localStorageUtil.set(ANONYMOUS_WISH_LIST_STORAGE_NAME, wishlistItems)
    }
  }

  useEffect(() => {
    if (!wishlistItems.length) {
      setProducts([])
      return
    } else if (wishlistProducts?.length) {
      setProducts(wishlistProducts || products || [])
      return
    }

    setProductsLoading(true)

    productsService
      .get({
        id: wishlistItems.map(item => item.productId),
        [PROFILE_NAME_PARAM]: ITEM_DETAILS_PROFILE_NAME,
        storeId,
      })
      .then(products => {
        setProducts(products.contents)
        setProductsLoading(false)
      })
      .catch(error => {
        Log.error(`Could not get products ${error}`)
      })
  }, [wishlistProducts, wishlistItems])

  useEffect(() => {
    if (wishlistItems.length) {
      const Products = getProductsForAnalytics({ products })

      const dataToSend: WishListData = {
        ...analyticsData,
        id: VIRTUAL_PAGE_VIEW_EVENT_ID,
        Page_Type: 'Wishlist',
        Page_Section1: 'Account',
        Page_Section2: 'Wishlist',
        Order_CartId: cart?.orderId || '',
        Order_CartUrl: `/${langCode}/${CART}`,
        Products,
      }
      sendAnalyticEventRaw(dataToSend)
    }
  }, [wishlistItems.length])

  useEffect(() => {
    saveUserAnonymousWishList()
    dispatch(toggleAddToCart(true))
    return () => {
      dispatch(toggleAddToCart(false))
    }
  }, [])

  useEffect(() => {
    if (!loggedIn) {
      setProducts([])
      dispatch(fetchWishlistAction())
    }
    saveUserAnonymousWishList()
  }, [loggedIn])

  if (!isWishlistEnabled) {
    router.push(HOME)
  }

  const hasAvailableProductsToLoad = products.length || areProductsLoading || isWishlistLoading
  const showLoginAlert = hasAvailableProductsToLoad && !loggedIn

  return isAddingToCart ? (
    <CartSkeletonLoader />
  ) : (
    <>
      <WishlistContainer>
        <RegistrationDialog />

        <WishlistMainSection>
          <WishlistHeader>
            <WishlistTitle>{t('Wishlist.Header.Title')}</WishlistTitle>
            {products.length === 0 && <WishlistDescription>{t('Wishlist.Header.Description')}</WishlistDescription>}
          </WishlistHeader>
          <WishlistItemsContainer>
            {showLoginAlert && (
              <WishlistSignInAlert>
                <Trans i18nKey="Wishlist.Messages.AnonymousDisclaimer">
                  <WishlistCta
                    onClick={() => {
                      document.querySelector<HTMLInputElement>('input[name="email"]')?.focus()
                    }}
                  />
                  <WishlistCta
                    onClick={() => {
                      dispatch(setOpenModalRegistration(true))
                    }}
                  />
                </Trans>
              </WishlistSignInAlert>
            )}
            {alert && (
              <StyledAlert
                onClose={() => setAlert(null)}
                severity={alert?.type || 'warning'}
                sx={{ width: '100%' }}
                icon={<SVGIcon library="validation" name="alert" color={theme.palette.custom.brown} />}
              >
                <StyledAlertHeader>{alert?.title}</StyledAlertHeader>
                <StyledAlertMessage fullWidth>{alert?.message}</StyledAlertMessage>
              </StyledAlert>
            )}
            {hasAvailableProductsToLoad ? (
              products.map((product, index) => {
                return (
                  <WishlistItem
                    key={index}
                    product={{
                      ...product,
                      productAttributes: getAllProductAttributes(product.attributes),
                    }}
                    removeFromWishlist={removeFromWishlist}
                    ctaRef={ctaRefs[index]}
                  />
                )
              })
            ) : (
              <WishlistEmptyButtonsContainer>
                {['Glasses', 'Sunglasses', 'Contacts'].map(productType => (
                  <StyledWishlistLinkCta
                    variant="primary"
                    href={`/${langCode}/${t(`Common.Shop.${productType}.Url`)}`}
                    key={productType}
                  >
                    {t(`Common.Shop.${productType}.Cta`)}
                  </StyledWishlistLinkCta>
                ))}
              </WishlistEmptyButtonsContainer>
            )}
          </WishlistItemsContainer>
        </WishlistMainSection>

        {!loggedIn && (
          <WishlistAsideSection>
            <SignInLayout />
          </WishlistAsideSection>
        )}
      </WishlistContainer>
      {isPrescriptionLensFormOpen && <Prescription />}
    </>
  )
}

export default Wishlist
