export const PAGE_TYPES: { [key: string]: string } = {
  home: 'Home',
  products: 'Plp',
  cart: 'CartPage',
  wishlist: 'Wishlist',
  pdp: 'Pdp',
  'thank-you': 'Thankyou',
  checkout: 'Delivery',
  rb: 'Custompage',
  oo: 'Custompage',
  storeLocator: 'StoreLocator',
}

export const PAGE_SECTION_1: { [key: string]: string } = {
  home: 'Home',
  plp: 'Catalog',
  pdp: 'Catalog',
  wishlist: 'Account',
  cart: 'CartPage',
  'thank-you': 'Checkout',
  checkout: 'Checkout',
}

export const PAGE_SECTION_2: { [key: string]: string } = {
  [PAGE_TYPES['thank-you']]: 'Standard',
  [PAGE_TYPES.pdp]: '', // TODO: fix empty before was window.location.search
}

export const PAGE_DESIGN: { [key: string]: string } = {
  [PAGE_TYPES.home]: '2020',
  [PAGE_TYPES.pdp]: 'VM',
}

// NOTE: SearchRun refers to analytics event triggered by user's attempt to 'search' on site
export type SearchRunType = 'text' | 'img' | 'barcode'
export type SearchRunView = 'NO-SERP' | 'SERB'
