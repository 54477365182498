import * as React from 'react'
import styled from '@mui/material/styles/styled'
import { MenuItem, Select } from '@mui/material'
import { toNumber } from 'lodash-es'
import { MenuProps } from 'react-instantsearch-hooks-web/dist/es/ui/Menu'

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 0,
  fontSize: '12px',
  textAlign: 'center',
  pointerEvents: 'none',
  minHeight: '15px',
  ':hover': {
    backgroundColor: theme.palette.custom.white,
  },
  '&.main': {
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.08)',
    },
  },
}))
export const DoubleSideMenuItem = styled('div')(({}) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
}))

export const DoubleSidedMenuItemDiv = styled('div')(({ theme }) => ({
  width: '50%',
  pointerEvents: 'auto',
  height: '100%',
  '&.main': {
    width: '100%',
    textAlign: 'center',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    cursor: 'pointer',
  },
  '&:hover': {
    backgroundColor: theme.palette.custom.blue,
    color: theme.palette.custom.white,
  },
  '&.left': {
    borderRight: '1px solid #B8B8B8',
  },
  '&.disabled': {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  '&.selected': {
    backgroundColor: 'rgba(25, 118, 210, 0.08)',
    '&:hover': {
      backgroundColor: theme.palette.custom.blue,
      color: theme.palette.custom.white,
    },
  },
  '&.signal': {
    backgroundColor: theme.palette.custom.blue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.custom.white,
    fontSize: '1rem',
    fontWeight: 700,
    height: '28px',
    lineHeight: '24px',
    div: {
      height: '80%',
      width: '100%',
      '&.left': {
        borderRight: `1px solid ${theme.palette.custom.white}`,
      },
    },
  },
}))

const RangeSelector = styled(Select, {
  name: 'Prescriptions',
  slot: 'RangeSelector',
})(({}) => ({
  fontSize: '12px',
  position: 'relative',
  lineHeight: '16px',
  maxWidth: '107px',
  '&.range-selector': {
    display: 'flex',
    alignItems: 'center',
    minHeight: '32px',
    maxHeight: '32px',
  },
}))

export interface SplitSelectorProps {
  id: string
  label: string
  required: boolean
  leftSideItems: number[]
  rightSideItems: number[]
  showSignals: boolean
  leftSignal?: string
  rightSignal?: string
  decimaFractionalParts?: 2 | 3 | 4
  mainItem?: number
  value?: number
  errorMessage: string
  renderNative: boolean
  MenuProps: Partial<MenuProps>
  onValueChange: (string) => void
}

const defaultProps: SplitSelectorProps = {
  id: 'splitRangeSelector',
  label: '',
  required: false,
  showSignals: true,
  leftSignal: '-',
  rightSignal: '+',
  errorMessage: '',
  leftSideItems: [],
  rightSideItems: [],
  value: 0,
  renderNative: false,
  MenuProps: {},
  onValueChange: () => {},
}

export interface CombinedItem {
  left: any
  right: any
}

export const SplitRangeSelect = (props: SplitSelectorProps) => {
  const {
    showSignals,
    leftSideItems,
    leftSignal,
    rightSideItems,
    rightSignal,
    mainItem,
    required,
    decimaFractionalParts,
    onValueChange,
    renderNative,
  } = props
  const [selectedValue, setSelectedValue] = React.useState<number>(props?.value || 0)
  const [maxValue] = React.useState<number>(
    leftSideItems.length >= rightSideItems.length ? leftSideItems.length : rightSideItems.length
  )
  const values: CombinedItem[] = []

  for (let i = 0; i < maxValue; i++) {
    values.push({
      left:
        i < leftSideItems.length
          ? (decimaFractionalParts && leftSideItems[i].toFixed(decimaFractionalParts)) || leftSideItems[i]
          : null,
      right:
        i < rightSideItems.length
          ? (decimaFractionalParts && rightSideItems[i].toFixed(decimaFractionalParts)) || rightSideItems[i]
          : null,
    })
  }

  const [combinedValues] = React.useState(values)

  const handleItemClick = value => {
    setSelectedValue(toNumber(value))
    onValueChange(value)
  }

  return (
    <RangeSelector
      required={required}
      className="range-selector"
      value={selectedValue}
      native={renderNative}
      MenuProps={{ PaperProps: { sx: { maxHeight: 250, marginTop: '-10px' } } }}
      renderValue={() => (decimaFractionalParts && selectedValue.toFixed(decimaFractionalParts)) || selectedValue}
    >
      {showSignals && (
        <StyledMenuItem>
          <DoubleSideMenuItem>
            <DoubleSidedMenuItemDiv className={'signal disabled'}>
              <div className="left">{leftSignal}</div>
            </DoubleSidedMenuItemDiv>
            <DoubleSidedMenuItemDiv className={'signal disabled'}>
              <div>{rightSignal}</div>
            </DoubleSidedMenuItemDiv>
          </DoubleSideMenuItem>
        </StyledMenuItem>
      )}

      <StyledMenuItem>
        <DoubleSideMenuItem>
          <DoubleSidedMenuItemDiv
            className={`${mainItem === selectedValue ? 'selected' : ''} main`}
            onClick={() => handleItemClick(mainItem)}
          >
            {(decimaFractionalParts && mainItem?.toFixed(decimaFractionalParts)) || mainItem}
          </DoubleSidedMenuItemDiv>
        </DoubleSideMenuItem>
      </StyledMenuItem>

      {combinedValues.map((combinedItem, i) => (
        <StyledMenuItem key={i}>
          <DoubleSideMenuItem>
            <DoubleSidedMenuItemDiv
              className={`${toNumber(combinedItem.left) == selectedValue ? 'selected' : ''} left ${
                combinedItem.left == null ? 'disabled' : ''
              }`}
              onClick={() => handleItemClick(combinedItem?.left)}
            >
              {combinedItem.left}
            </DoubleSidedMenuItemDiv>
            <DoubleSidedMenuItemDiv
              className={`${combinedItem.right === selectedValue ? 'selected' : ''}  ${
                combinedItem.right == null ? 'disabled' : ''
              }`}
              onClick={() => handleItemClick(combinedItem?.right)}
            >
              {combinedItem.right}
            </DoubleSidedMenuItemDiv>
          </DoubleSideMenuItem>
        </StyledMenuItem>
      ))}
    </RangeSelector>
  )
}

SplitRangeSelect.defaultProps = defaultProps
