import { ESTIMATED_TAXES } from '../../../../../constants/order'
import { sessionStorageUtil } from '../../../../../foundation/utils/storageUtil'
import { useAppSelector } from '../../../../../hooks/redux'
import { pdpTaxesSelector } from '../../../../../redux/selectors/site'
import { Cart } from '@typesApp/order'
import { getCartTotalsSummary } from '../../../../../utils/cart'

export interface UsePaymentTotalReturn {
  totalWithTax: number | null
  totalKlarna: string | null
  currency: string
}

export const usePaymentTotal = (cart: Cart): UsePaymentTotalReturn => {
  const isTaxAvailable = useAppSelector(pdpTaxesSelector)
  const { tax, grandTotal } = getCartTotalsSummary(cart)
  const estimatedTaxes = sessionStorageUtil.get(ESTIMATED_TAXES) as number

  const totalWithTax =
    isTaxAvailable &&
    (isNaN(tax as number) || (!isNaN(tax as number) && tax === 0)) &&
    !isNaN(estimatedTaxes as number) &&
    !!grandTotal &&
    !!estimatedTaxes
      ? grandTotal + estimatedTaxes
      : grandTotal
  
  const totalKlarna = !!totalWithTax ? (totalWithTax / 3).toFixed(2) : null

  return {
    totalWithTax,
    totalKlarna,
    currency: cart.grandTotalCurrency
  }
}
