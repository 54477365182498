import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import config from '@configs/index'
import { getPreviewSelector } from '@features/preview/selector'
import { IFRAME_RESIZER } from '@foundation/constants/csr'
import { hostnameSelector } from '@redux/selectors/site'
import { addWunderkindSmartTag, isWunderkindEnabledForLocale } from '@components/Wunderkind/Wunderkind'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { cartSelector } from '@features/order/selector'
import { userEmailSelector } from '@redux/selectors/user'
import { WunderkindLocales } from '@components/Wunderkind/types'

export const BaseLayoutHead: React.FC = () => {
  const getPreview = useSelector(getPreviewSelector)
  const currentHostname = useSelector(hostnameSelector)
  const router = useRouter()

  const { langCode } = useStoreIdentity()
  const cart = useSelector(cartSelector)
  const userEmail = useSelector(userEmailSelector)
  const [isIOSDevice, setIsIOSDevice] = useState(false)
  const [isIframeResizer, setIsIframeResizer] = useState(false)
  const [originUrl, setOriginUrl] = useState(`https://${currentHostname}/${router.locale}${router.asPath}`)

  useEffect(() => {
    const isIOS = navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)
    setIsIOSDevice(!!isIOS)
  }, [])

  useEffect(() => {
    if (window[IFRAME_RESIZER]) {
      setIsIframeResizer(true)
    }
  }, [])

  useEffect(() => {
    if (window) {
      setOriginUrl(`${location.origin}${location.pathname}`)
    }
  }, [])

  useEffect(() => {
    if (isWunderkindEnabledForLocale(langCode)) {
      addWunderkindSmartTag({ document, langCode: langCode as WunderkindLocales })
    }
  }, [langCode])
  useEffect(() => {
    if (isWunderkindEnabledForLocale(langCode) && window?.wunderkind) {
      window.wunderkind.cart = {
        value: !!cart ? +cart.grandTotal : 0,
        quantity: !!cart ? +cart.productCount : 0,
      }
    }
  }, [cart])
  useEffect(() => {
    if (isWunderkindEnabledForLocale(langCode) && window?.wunderkind) {
      window.wunderkind.user = {
        email: !!userEmail ? userEmail : '',
      }
    }
  }, [userEmail])

  return (
    <Head>
      <meta charSet="utf-8" />
      {isIOSDevice && <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />}

      {!!originUrl && <link rel="canonical" href={originUrl} />}

      {isIframeResizer && <script src="/iframeResizer.contentWindow.min.js" type="text/javascript" async />}

      {getPreview?.isEnabled && (
        <>
          <html lang={`${getPreview.locale}`} dir="ltr" />
          <title data-cm-metadata='[{"_":"properties.htmlTitle"}]'>{`${getPreview.name || 'CMS Preview'}`}</title>
          <meta name="generator" content="CoreMedia Content Cloud" />
          <script id="js-enabled">
            {(document.documentElement.className = document.documentElement.className.replace(/no-js/g, 'js'))}
          </script>
          <link rel="stylesheet" href="/cms-preview/coremedia.preview.blueprint.css" />
          <script src="/cms-preview/jquery-3.6.0.min.js" />
          <script src="/cms-preview/coremedia.preview.js" />
          <script src="/cms-preview/coremedia.preview.$.js" />
          <script src="/cms-preview/coremedia.preview.blueprint.js" />
          <body
            id="top"
            className="cm-page"
            data-cm-metadata={
              '[{"cm_responsiveDevices":{"mobile_portrait":{"width":375,"height":667,"order":1},"tablet_landscape":{"width":1024,"height":768,"order":4},"mobile_landscape":{"width":667,"height":375,"order":2},"tablet_portrait":{"width":768,"height":1024,"order":3}},"cm_preferredWidth":1500}]'
            }
          />
        </>
      )}
      {config?.trustPilotEnabled && (
        <Script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></Script>
      )}
    </Head>
  )
}
