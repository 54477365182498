import Axios, { Canceler } from 'axios'
//Standard libraries
import React, { ChangeEvent, useEffect } from 'react'
//UI
import { EMPTY_STRING } from '../../constants/common'
//Custom libraries
import { ResetPassword } from '../ResetPassword'
import addressUtil from '../../utils/addressUtil'
import getDisplayName from 'react-display-name'
import personService from '../../foundation/apis/transaction/person.service'
import { useDispatch } from 'react-redux'
//Foundation libraries
import { useSite } from '../../foundation/hooks/useSite'
import { Trans, useTranslation } from 'next-i18next'
import {
  StyledWrapper,
  StyledHeadingContainer,
  StyledBodyContainer,
  StyledEmailFormContainer,
  StyledInputField,
  StyledEmailNotFound,
  SubmittedEmail,
  ResendEmailButton,
  StyledButton,
  StyledMessageContainer,
  StyledResetPasswordWrapper,
} from './ForgotPassword.style'
import { sendSuccessMessage } from '../../features/success/slice'
import { StyledAnchor } from '../UI'
import { useSearchParams } from 'next/navigation'

const ForgotPasswordLayout: React.FC<{ handleClose: () => void }> = ({}) => {
  const queryParams = useSearchParams()
  const urlValidationCode = queryParams.get('validation')
  const urlEmail = queryParams.get('email')

  const widgetName = getDisplayName(ForgotPasswordLayout)
  const [email, setEmail] = React.useState<string>(urlEmail ?? EMPTY_STRING)
  const [isEmailError, setIsEmailError] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [forgotPasswordState, setForgotPasswordState] = React.useState<boolean>(true)
  const [resetPasswordState, setResetPasswordState] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const { mySite: site } = useSite()
  const { t } = useTranslation()
  const forgotPasswordTitle = t('ForgotPassword.Title')
  const resetPasswordTitle = t('ForgotPassword.ResetPasswordButton')
  const helperTextEmailVerificationForm = t('ForgotPassword.HelperTextEmailVerificationForm')

  const emailLabel = t('ForgotPassword.EmailLabel')
  const submitButon = t('ForgotPassword.SubmitButton')
  const resetPasswordButon = t('ForgotPassword.ResetPasswordButton')

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const handleEmail = (evt: ChangeEvent<HTMLInputElement>) => {
    if (isError) setIsError(false)
    if (isEmailError) setIsEmailError(false)
    setEmail(evt.target.value)
  }

  const handleForgotPasswordState = () => setForgotPasswordState(false)

  const canContinue = () => email !== EMPTY_STRING

  const handleSubmit = () => {
    if (!addressUtil.validateEmail(email)) {
      setIsEmailError(true)
      return
    }
    setIsLoading(true)
    const storeID = site.storeID
    const parameters = {
      responseFormat: 'application/json',
      storeId: storeID,
      body: {
        logonId: email,
        resetPassword: 'true',
        challengeAnswer: '-',
      },
      pathParams: {
        storeId: storeID,
      },
      ...payloadBase,
      skipErrorSnackbar: true,
    }
    personService
      .updatePerson(parameters)
      .then(() => {
        handleForgotPasswordState()

        dispatch(
          sendSuccessMessage({
            key: 'success-message.RESEND_VERIFICATION_CODE',
          })
        )
      })
      .catch(() => {
        handleForgotPasswordState()

        dispatch(
          sendSuccessMessage({
            key: 'success-message.RESEND_VERIFICATION_CODE',
          })
        )
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (urlValidationCode && urlEmail) handleForgotPasswordState()
  }, [])

  useEffect(() => {
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [])

  return (
    <StyledWrapper>
      <StyledHeadingContainer>
        <div>
          {!resetPasswordState ? (
            forgotPasswordState ? (
              <>{forgotPasswordTitle}</>
            ) : (
              t('ForgotPassword.TemporaryPasswordTitle')
            )
          ) : (
            <>{resetPasswordTitle}</>
          )}
        </div>
      </StyledHeadingContainer>
      <StyledBodyContainer>
        {!resetPasswordState ? (
          forgotPasswordState ? (
            <StyledEmailFormContainer>
              <>
                <StyledMessageContainer dangerouslySetInnerHTML={{ __html: helperTextEmailVerificationForm }} />
                <StyledInputField
                  variant="outlined"
                  margin="normal"
                  name="email"
                  label={`${emailLabel}`}
                  type={'email'}
                  value={email}
                  onChange={handleEmail}
                  error={isEmailError}
                  inputProps={{ maxLength: 100 }}
                  helperText={isEmailError ? t('ForgotPassword.Msgs.InvalidFormat') : EMPTY_STRING}
                  showvalidationstatus={true}
                  required
                />
                <StyledButton
                  mTop
                  variant="secondary"
                  disabled={!canContinue()}
                  onClick={handleSubmit}
                  loading={isLoading}
                >
                  {submitButon}
                </StyledButton>
                {isError && <StyledEmailNotFound>{t('ForgotPassword.Msgs.EmailNotFound')}</StyledEmailNotFound>}
              </>
            </StyledEmailFormContainer>
          ) : (
            <StyledEmailFormContainer>
              <SubmittedEmail>{email}</SubmittedEmail>
              <StyledMessageContainer>
                <Trans i18nKey="ForgotPassword.Msgs.TemporaryPassword">
                  {{
                    contactUs: t('ForgotPassword.Msgs.ContactUs'),
                  }}
                  <StyledAnchor external={true} href={t('ForgotPassword.Msgs.SupportEmail')} />
                </Trans>
              </StyledMessageContainer>

              <StyledButton variant="secondary" onClick={() => setResetPasswordState(true)} loading={isLoading}>
                {resetPasswordButon}
              </StyledButton>

              <ResendEmailButton onClick={handleSubmit}>{t('ForgotPassword.ResendEmail')}</ResendEmailButton>
            </StyledEmailFormContainer>
          )
        ) : (
          <StyledResetPasswordWrapper>
            <ResetPassword email={email} urlValidationCode={urlValidationCode ?? ''} />
          </StyledResetPasswordWrapper>
        )}
      </StyledBodyContainer>
    </StyledWrapper>
  )
}

export { ForgotPasswordLayout }
