import styled from '@mui/material/styles/styled'
import { Button, StyledButtonAsLink, TextField } from '../../../components/UI'

export const SignInContentDiv = styled('div')(({ theme }) => ({
  'form > div.textfield-wrapper': {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8)
    },
  }
}))


export const StyledHeader = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const StyledSignInTitle = styled('span')(() => ({
  fontSize: 40,
}))

export const StyledSignInSubtitle = styled('span')(() => ({
  fontSize: '1rem',
}))

export const StyledSignInContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '600px',
  padding: '1rem',
  [theme.breakpoints.up('lg')]: {
    padding: 0,
  },
}))

export const StyledFYPLinkWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(-5)
}))

export const StyledForgotYourPasswordLink = styled(StyledButtonAsLink)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.primary,
  fontWeight: 700,
  ':after': {
    backgroundColor: theme.palette.text.primary
  }
}))


export const StyledSignInButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(4),
  minWidth: theme.spacing(30),
  marginLeft: 0,
  marginRight: 0,
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-input': {
    backgroundColor: theme.palette.custom.white
  }
}))

export const StyledErrorLoginMessage = styled('div')(({ theme }) => ({
  color: theme.palette.custom.red,
  paddingBottom: '2rem'
}))
