interface WebchatUtil {
  openWebchat: () => void
  hideWebchat: () => void
  toggleWebchat: () => void
  resetWebchat: () => void
}

const GenesysWebchat = (): WebchatUtil => {

  const getContainer = (): HTMLElement | null => document.getElementById('genesys-mxg-frame')

  const openWebchat = () => {
    const widget = getContainer()
    if (widget) {
      widget.style.removeProperty('display')
    }
  }

  const hideWebchat = () => {
    const widget = getContainer()
    if (widget) {
      widget.style.display = 'none'
    }
  }

  const toggleWebchat = () => {
    const widget = getContainer()
    if (!widget) return

    if (widget.style.display === 'none') {
      openWebchat()
    } else {
      hideWebchat()
    }
  }

  return {
    openWebchat,
    hideWebchat,
    toggleWebchat,
    resetWebchat: openWebchat
  }
}

export const Webchat = GenesysWebchat()
