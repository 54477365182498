import React from 'react'
import { CHECKOUT_NAMES, CheckoutName, Icon, isValidCardExpirationDate } from '../../../../../constants/paymentMethods'
import {
  PaymentMethodAccordion,
  PaymentMethodSummaryWrapper,
  PaymentMethodSelectionFakeRadio,
  PaymentMethodDescription,
  PaymentIconWrapperRow,
  PaymentMethodSelectionDetailsWrapper,
  PaymentMethodCardDetails,
  PaymentMethodCardExpired,
  PaymentMethodDetailsContainer,
  PaymentIconWrapperColumn,
} from '../../../payment/Payment.style'
import { Wallet } from '@typesApp/user'
import DateService from '../../../../../services/DateService'
import { useTranslation } from 'next-i18next'
import PaymentMethodsService from '@services/PaymentMethodsService'
import { getIconByChekoutName } from '@utils/payMethods'
import { SVGIcon, SVGIconProps } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface PaymentMethodProps {
  paymentMethodName: CheckoutName
  paymentDescription: string
  expanded: boolean
  onPaymentMethodSelected: () => void
  content: React.ReactNode
  paymentIcon?: Icon | null
  paymentAdorment?: React.ReactNode | null
  storedCardDetails?: Wallet
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  paymentMethodName,
  expanded,
  onPaymentMethodSelected: onPaymenMethodSelected,
  paymentDescription,
  content,
  paymentIcon,
  paymentAdorment,
  storedCardDetails,
}: PaymentMethodProps) => {
  const { t } = useTranslation()
  const isKlarnaPaymentMethod = paymentMethodName === CHECKOUT_NAMES.KLARNA
  let isCardValid = false
  let expiredDate = ''
  if (storedCardDetails) {
    const cardDate = DateService.getValidDate(storedCardDetails.expireDate)
    const cardYear = cardDate?.getFullYear()
    const cardMonth = cardDate?.getMonth()
    if (cardMonth && cardYear) {
      isCardValid = isValidCardExpirationDate([cardMonth + 1, cardYear])
      expiredDate = `${cardMonth + 1}/${cardYear % 100}`
    }
  }

  const onHeaderClick = () => {
    if (expanded) {
      return
    }
    onPaymenMethodSelected()
  }

  let paymentIcons: React.FC<Partial<SVGIconProps>>[]
  if (
    CHECKOUT_NAMES.MASTERCARD === paymentMethodName ||
    CHECKOUT_NAMES.VISA === paymentMethodName ||
    CHECKOUT_NAMES.AMEX === paymentMethodName
  ) {
    paymentIcons = PaymentMethodsService.getIconsForCreditCardForm()
  } else {
    paymentIcons = [getIconByChekoutName(paymentMethodName as CheckoutName)] as React.FC<Partial<SVGIconProps>>[]
  }

  const Icon = paymentIcon as React.FC<Partial<SVGIconProps>>
  const displayPaymentIcon = !storedCardDetails ? paymentIcons.map((Icon, index) => <Icon key={index} />) : <Icon />
  return (
    <>
      <PaymentMethodAccordion expanded={expanded}>
        <PaymentMethodSummaryWrapper
          aria-controls={`content-${paymentMethodName}`}
          id={`header-${paymentMethodName}`}
          onClick={onHeaderClick}
          isKlarna={isKlarnaPaymentMethod}
        >
          <PaymentMethodSelectionFakeRadio checked={expanded} isKlarna={isKlarnaPaymentMethod}>
            <SVGIcon library="global" name="checked-radio" size={10} />
          </PaymentMethodSelectionFakeRadio>
          <PaymentMethodDetailsContainer>
            <PaymentMethodDescription fontWeight="800" isKlarna={isKlarnaPaymentMethod}>
              {paymentDescription}
            </PaymentMethodDescription>
            {storedCardDetails && (
              <PaymentMethodCardDetails>
                {t('PaymentMethodSelection.Labels.Bullets')} {storedCardDetails.creditCardPan}
              </PaymentMethodCardDetails>
            )}
            <PaymentIconWrapperRow>{displayPaymentIcon}</PaymentIconWrapperRow>
            {storedCardDetails && !isCardValid && expiredDate && (
              <PaymentMethodCardExpired>
                {t('PaymentMethodSelection.Labels.Expired')} {expiredDate}
              </PaymentMethodCardExpired>
            )}
          </PaymentMethodDetailsContainer>
          <PaymentIconWrapperColumn>{displayPaymentIcon}</PaymentIconWrapperColumn>
          {paymentAdorment}
        </PaymentMethodSummaryWrapper>
        <PaymentMethodSelectionDetailsWrapper>{content}</PaymentMethodSelectionDetailsWrapper>
      </PaymentMethodAccordion>
    </>
  )
}

export default PaymentMethod
