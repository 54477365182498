import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  subscriptionFrequencyAllowedAmountSelector,
  subscriptionFrequencyAllowedUOMSelector,
} from '@redux/selectors/site'
import { RESTRICTED_INTERVALS } from '../constants'
import { toNumber } from 'lodash-es'

export function useFrequencyList() {
  const { t: translate } = useTranslation('', { keyPrefix: 'SubscriptionMyAccount.Frequency' })
  const subscriptionUOM = useSelector(subscriptionFrequencyAllowedUOMSelector)
  const subscriptionFrequency = useSelector(subscriptionFrequencyAllowedAmountSelector)

  const frequencyList = useMemo(() => {
    const uom = subscriptionUOM?.split(',')?.sort().reverse() || []
    const frequencyNumbers = subscriptionFrequency?.split(',') || []
    return uom
      .map(uomItem => {
        return frequencyNumbers
          .filter(val => !RESTRICTED_INTERVALS?.[uomItem]?.includes(val))
          .map(val => ({ [`${val}|${uomItem}`]: translate(`${uomItem}WithCount`, { count: toNumber(val) }) }))
      })
      .flat(1)
  }, [subscriptionFrequency, subscriptionUOM, translate])

  return frequencyList
}
