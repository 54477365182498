import React, { FC, useCallback } from 'react'
import { CSSProperties } from 'styled-components'

import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../constants/product'
import { AlgoliaPrice, getProductPrice, parsePriceAsNumber } from '../../../foundation/algolia/algoliaPrice'
import CurrencyService from '../../../services/CurrencyService'
import { ProductSoldOutStatus } from '../../../types/product'
import {
  ProductPriceWrapper,
  ProductPriceContainer,
  ProductPriceDetails,
  ProductPriceInitialPrice,
  ProductCurrentPriceContainer,
  ProductPriceCurrentPrice,
  ProductPriceDiscountBox,
  ProductPriceContainerPDP,
  ProductPriceLabel,
  ProductPriceInitialPricePDP,
  PriceSection,
  InitialPriceSection,
  DiscountedPriceSection,
  ProductPriceTotal,
  ProductPriceSoldOutBox,
} from './ProductPrice.style'
import theme from '../../../themes'
import WithSpinningLoader from '../../../components/with-loading/WithLoading'
import { useSite } from '../../../foundation/hooks/useSite'
import { Adjustment } from '../../../types/order'
import useBreakpoints from '../../../hooks/useBreakpoints'
import TotalLabelContainer from './TotalLabelContainer'
import { isSoldOutFeatureEnabled } from '@utils/common'

export const PRODUCT_PRICE_SIZES = {
  sm: '0.75rem',
  md: '1rem',
  lg: '1.75rem',
} as const

type SizeKeys = keyof typeof PRODUCT_PRICE_SIZES
type ProductPriceSize = (typeof PRODUCT_PRICE_SIZES)[SizeKeys]
type ProductPriceOrders = {
  current?: 1 | 2 | 3
  initial?: 1 | 2 | 3
  discountAmount?: 1 | 2 | 3
}

type ProductPriceSizes = {
  current?: ProductPriceSize
  currentDesktop?: ProductPriceSize
  initial?: ProductPriceSize
  initialDesktop?: ProductPriceSize
  discountAmount?: ProductPriceSize
  discountAmountDesktop?: ProductPriceSize
}

export interface ProductPriceProps {
  isLoading?: boolean
  isCompact?: boolean
  type?: ProductSoldOutStatus
  orders?: ProductPriceOrders
  productType?: string
  columnGap?: CSSProperties['columnGap']
  justifyContent?: CSSProperties['justifyContent']
  sizes?: ProductPriceSizes
  isRoxable?: boolean
  price?: AlgoliaPrice
  isPDP?: boolean
  isTotal?: boolean
  totalBoxes?: number
  productQuantity?: string[]
  isCL?: boolean
  isCLAccessory?: boolean
  isRxOrder?: boolean
  x_offerpriceRx?: string
  adjustments?: Adjustment[]
  isAbsoluteDiscount?: boolean
  isVerticalLayout?: boolean
  isStickyBar?: boolean
  renderFramePriceLabel?: boolean
  soldOutStatus?: ProductSoldOutStatus
}

const ProductPriceAlgolia: FC<ProductPriceProps> = ({
  isLoading = false,
  type,
  productType,
  isCompact,
  isRoxable,
  price,
  isTotal = false,
  isPDP = false,
  totalBoxes = 0,
  productQuantity,
  isCL,
  isCLAccessory,
  adjustments = [],
  isAbsoluteDiscount = false,
  isVerticalLayout = false,
  isStickyBar = false,
  renderFramePriceLabel = true,
  soldOutStatus,
}) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'))
  const { isViewportWidthUnder426, isViewportWidthUnder769 } = useBreakpoints()
  const showSoldOutBadge =
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON
  const isFramesPdp = (isPDP && productType === PRODUCT_TYPES_KEYS.FRAMES) || productType === PRODUCT_TYPES_KEYS.OPTICAL
  const isContactLensesPDP = isPDP && productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES
  const soldOutFeatureEnabled = isSoldOutFeatureEnabled()
  const formatPriceValue = useCallback((value: number): string => {
    const shouldMaskTotal = value === 0 && isContactLensesPDP
    return CurrencyService.getFormattedPrice(mySite.locale, currency, value.toFixed(2), 1, 1, shouldMaskTotal) as string
  }, [])

  const parseQuantity = totalBoxes ? totalBoxes : productQuantity && parseInt(productQuantity[0])

  const getClItemAccessoryPrice = (
    priceValue: number,
    parseQuantity?: number,
    totalBoxes?: number,
    isCL?: boolean,
    isCLAccessory?: boolean
  ): string => {
    isCLAccessory && parseQuantity ? formatPriceValue(+priceValue * parseQuantity) : formatPriceValue(+priceValue)
    switch (true) {
      case isCLAccessory && !!parseQuantity:
        return formatPriceValue(+priceValue * (parseQuantity || 0))
      case isCL:
        return formatPriceValue(+priceValue * (totalBoxes || 0))
      default:
        return formatPriceValue(+priceValue)
    }
  }

  const { initialPrice, offerPrice, currency, discountPercentage, discountAbsolute } = getProductPrice(
    price,
    productQuantity,
    adjustments
  )

  const showFramePriceLabel = !isViewportWidthUnder426 && isViewportWidthUnder769 && renderFramePriceLabel
  const hasDiscount = discountAbsolute > 0
  const discountBadgeLabel = isAbsoluteDiscount
    ? t('ProductTile.Labels.AmountOff', { amount: discountAbsolute.toFixed(2) })
    : t('ProductTile.Labels.PercentageOff', { amount: discountPercentage })

  const showPriceSection =
    soldOutFeatureEnabled ||
    (type !== PRODUCT_SOLDOUT_STATUS.SOLDOUT && type !== PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK) ||
    (type === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK && isStickyBar)

  return showPriceSection ? (
    <ProductPriceWrapper className={`${initialPrice !== undefined ? 'product-price' : 'product-price no-discount'}`}>
      <WithSpinningLoader isLoading={isLoading} skeletonCount={isLgBreakpoint ? 5 : 4}>
        <>
          {!isTotal &&
            (isPDP ? (
              <>
                <ProductPriceContainerPDP>
                  <div>
                    {(isFramesPdp && (
                      <ProductPriceLabel
                        isPDP={isPDP}
                        isStickyBar={isStickyBar}
                        showFramePriceLabel={showFramePriceLabel}
                      >
                        {t('Labels.Frame')} {showFramePriceLabel && t('Labels.Price')}
                      </ProductPriceLabel>
                    )) || <TotalLabelContainer productType={productType} isPDP isStickyBar />}
                  </div>
                  {hasDiscount ? (
                    <DiscountedPriceSection>
                      <InitialPriceSection>
                        <ProductPriceInitialPricePDP isStickyBar={isStickyBar}>
                          {!!initialPrice &&
                            !!totalBoxes &&
                            getClItemAccessoryPrice(
                              parsePriceAsNumber(initialPrice),
                              parseQuantity,
                              totalBoxes,
                              isCL,
                              isCLAccessory
                            )}
                        </ProductPriceInitialPricePDP>
                        <PriceSection>
                          {offerPrice != null && (
                            <ProductPriceCurrentPrice isPDP={isPDP} isCompact={isCompact} isStickyBar={isStickyBar}>
                              {getClItemAccessoryPrice(+offerPrice, parseQuantity, totalBoxes, isCL, isCLAccessory)}
                            </ProductPriceCurrentPrice>
                          )}
                        </PriceSection>
                        {!!totalBoxes && (
                          <ProductPriceDiscountBox isPDP={isPDP}>{discountBadgeLabel}</ProductPriceDiscountBox>
                        )}
                      </InitialPriceSection>
                    </DiscountedPriceSection>
                  ) : (
                    <PriceSection>
                      {offerPrice != null && (
                        <ProductPriceCurrentPrice isPDP={isPDP} isCompact={isCompact}>
                          {getClItemAccessoryPrice(+offerPrice, parseQuantity, totalBoxes, isCL, isCLAccessory)}
                        </ProductPriceCurrentPrice>
                      )}
                    </PriceSection>
                  )}
                </ProductPriceContainerPDP>
              </>
            ) : (
              <ProductPriceContainer isPDP={false} isCompact={!!isCompact} isVerticalLayout={isVerticalLayout}>
                <ProductPriceDetails
                  justifyContent={isVerticalLayout ? 'center' : 'flex-start'}
                  isVerticalLayout={isVerticalLayout}
                >
                  {(isRoxable || productType === 'contact-lenses') &&
                    initialPrice !== offerPrice &&
                    t('ProductTile.Labels.from')}
                  {hasDiscount && <ProductPriceInitialPrice>{formatPriceValue(initialPrice)}</ProductPriceInitialPrice>}
                  <ProductCurrentPriceContainer isPDP={false}>
                    <ProductPriceCurrentPrice isPDP={false} isCompact={isCompact}>
                      {formatPriceValue(offerPrice)}
                    </ProductPriceCurrentPrice>
                  </ProductCurrentPriceContainer>
                  {(productType === 'contact-lenses' || productType === 'contact-lenses-accessories') &&
                    t('ContactLenses.Labels.perBox')}
                </ProductPriceDetails>
                {hasDiscount && <ProductPriceDiscountBox isPDP={false}>{discountBadgeLabel}</ProductPriceDiscountBox>}
                {showSoldOutBadge && (
                  <ProductPriceSoldOutBox isPDP={isPDP}>{t('ProductTile.Labels.soldout')}</ProductPriceSoldOutBox>
                )}
              </ProductPriceContainer>
            ))}

          {isTotal && (
            <>
              <ProductPriceContainerPDP>
                <div>
                  <TotalLabelContainer productType={productType} isPDP isStickyBar />
                </div>
                <ProductPriceTotal>
                  {hasDiscount && (
                    <ProductPriceInitialPricePDP>
                      {!!initialPrice &&
                        getClItemAccessoryPrice(
                          parsePriceAsNumber(initialPrice),
                          parseQuantity,
                          totalBoxes,
                          isCL,
                          isCLAccessory
                        )}
                    </ProductPriceInitialPricePDP>
                  )}
                  {offerPrice != null && (
                    <ProductPriceCurrentPrice isPDP={isPDP} isCompact={isCompact}>
                      {getClItemAccessoryPrice(+offerPrice, parseQuantity, totalBoxes, isCL, isCLAccessory)}
                    </ProductPriceCurrentPrice>
                  )}
                </ProductPriceTotal>
              </ProductPriceContainerPDP>
            </>
          )}
        </>
      </WithSpinningLoader>
    </ProductPriceWrapper>
  ) : null
}

export default ProductPriceAlgolia
