import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import useThemeMode from '../../hooks/useThemeMode'

const StyledReassurance = styled('div')`
  display: flex;
  flex-direction: column;
`

const StyledWrapper = styled('div')<{ themeMode: string }>`
  border-top: 1px solid ${({ theme }) => theme.palette.secondary.main};

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.main};
  }
`

const Reassurance: React.FC<PropsWithChildren> = ({ children }) => {
  const { themeMode } = useThemeMode()

  return (
    <StyledReassurance>
      {React.Children.map(children, (item) => (
        <StyledWrapper themeMode={themeMode}>{item}</StyledWrapper>
      ))}
    </StyledReassurance>
  )
}

export default Reassurance
