import styled from '@mui/material/styles/styled'

export const AppWrapper = styled('div', {
  name: 'BaseLayout',
  slot: 'AppWrapper',
  shouldForwardProp: prop => prop !== 'isPreview' && prop !== 'isOnBehalfSession',
})<{ isPreview?: boolean; isOnBehalfSession?: boolean }>(({ isPreview, isOnBehalfSession, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: isPreview ? '' : '100vh',
  minHeight: '100vh',
  maxHeight: isPreview ? '' : '100vh',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  marginTop: isOnBehalfSession ? '75px' : '0',
}))

export const AppMain = styled('main', {
  name: 'BaseLayout',
  slot: 'Main',
  shouldForwardProp: prop => prop !== 'openElements',
})<{ openElements?: boolean }>(({ openElements, theme }) => ({
  maxWidth: '100vw',
  overflow: openElements ? 'hidden' : 'hidden auto',
  flex: 1,
  '&.is-pdp [data-element-id="Footer_Copyright"]': {
    marginBottom: '88px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '48px',
    },
  },
}))
