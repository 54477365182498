import React from 'react'

import { useGenesys } from './useGenesys'
import { ChatButtonContainer, ChatButtonLabel } from './ChatButton.style'
import { useTranslation } from 'next-i18next'
import { matchPath } from 'react-router-dom'
import { CART, CHECKOUT_PAYMENT, CHECKOUT_REVIEW, CHECKOUT_SHIPPING, ORDER_CONFIRMATION } from '../../constants/routes'
import { useStoreIdentity } from '../hooks/useStoreIdentity'
import useScrollingUp from '../../hooks/useScrollingUp/useScrollingUp'
import { usePathname } from 'next/navigation'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const ChatButton = () => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const hasScrolled = useScrollingUp()
  const { isLoading, toggleGenesys } = useGenesys()
  const pathname = usePathname()

  const DISALLOW_LIST = [CART, CHECKOUT_SHIPPING, CHECKOUT_PAYMENT, CHECKOUT_REVIEW, ORDER_CONFIRMATION]

  const isVisible =
    !isLoading && !DISALLOW_LIST.some(path => matchPath(`/${langCode}/${path}`, pathname)) && hasScrolled !== null

  if (isVisible) {
    return (
      <ChatButtonContainer onClick={toggleGenesys}>
        <SVGIcon library="global" name="chat-alt" color={'#ffffff'} />
        <ChatButtonLabel>{t('Chat.Cta')}</ChatButtonLabel>
      </ChatButtonContainer>
    )
  }

  return null
}
