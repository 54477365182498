//Standard libraries
import React, { Dispatch, useEffect, PropsWithChildren } from 'react'

import Log from './services/Log'
import { fetchCountryListDetails } from './features/account/thunks'
import { initAxios } from './foundation/axios/axiosConfig'
import { useDispatch } from 'react-redux'
import { useSite } from './foundation/hooks/useSite'
import { StoreAppContainer } from './layouts/StoreAppContainer'
// import { useInitFrameGenius } from '@hooks/useFrameGenius'
import dynamic from 'next/dynamic'

// This is a workaround to init site data on client side
// Needed for apis services, has to to be removed after avoiding get data from site service in apis
const SiteData = dynamic(() => import('./SiteData'), { ssr: false })

const App: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch<Dispatch<any>>()
  const { mySite } = useSite()

  initAxios(dispatch)
  // useInitFrameGenius()
  useEffect(() => {
    const storeId = mySite?.storeID
    if (!storeId) {
      return
    }
    dispatch(
      fetchCountryListDetails({
        storeId,
      })
    )
  }, [dispatch, mySite?.storeID])

  Log.info('Init Axios in App.tsx')
  Log.info('App start in App.tsx')

  return (
    <>
      <SiteData mySite={mySite} />
      <StoreAppContainer>{children}</StoreAppContainer>
    </>
  )
}

export default App
