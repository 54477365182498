import styled from '@mui/material/styles/styled'
import { StyledTypography, GridContainer, GridItem } from '../../components/UI'

const isDesktop = theme => theme.breakpoints.up('md')

export const CheckoutWrapper = styled(GridContainer, {
  name: 'Checkout',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'isGreyBackground',
})<{ isGreyBackground?: boolean }>(({ theme, isGreyBackground }) => ({
  rowGap: theme.spacing(2),
  minHeight: 'calc(100vh - 116px)',
  padding: theme.spacing(8, 4),
  backgroundColor: isGreyBackground ? theme.palette.custom.wildSand : 'initial',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8, 16, 16, 16),
    rowGap: theme.spacing(2),
  },
}))

export const CheckoutLeftColumn = styled(GridItem, {
  name: 'Checkout',
  slot: 'LeftColumn',
  shouldForwardProp: prop => prop !== 'isFullWidth',
})<{ isFullWidth?: boolean }>(({ theme, isFullWidth }) => ({
  '>div, >section': {
    maxWidth: isFullWidth ? '100%' : theme.spacing(300),
  },
}))

export const CheckoutRightColumn = styled(GridItem, {
  name: 'Checkout',
  slot: 'RightColumn',
})(() => ({}))

export const SectionWrapper = styled('section', {
  name: 'Section',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  color: theme.palette.text.dark.primary,
}))

export const OrderDate = styled('div', {
  name: 'OrderDate',
  slot: 'Wrapper',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
  flexDirection: 'row',
  gap: theme.spacing(2),
  color: theme.palette.text.dark.primary,
  backgroundColor: theme.palette.custom.light.blue,

  div: {
    display: 'flex',
    flex: 1,
    '&:nth-of-type(2)': {
      justifyContent: 'flex-end',
    },
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100vw',
    marginLeft: `-${theme.spacing(4)}`,
    padding: theme.spacing(3, 6, 3, 4),
    div: {
      display: 'flex',
      flex: 1,
      '&:nth-of-type(2)': {
        justifyContent: 'flex-start !important',
      },
    },
  },
}))

export const SectionTitle = styled('div', {
  name: 'Section',
  slot: 'Title',
})(() => ({
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 20,
}))

export const SectionSubtitle = styled('div', {
  name: 'Section',
  slot: 'SubTitle',
})(() => ({
  textTransform: 'initial',
  fontSize: 14,

  button: {
    fontWeight: 'bold',
  },
}))

export const SectionTitleNumber = styled('span', {
  name: 'Section',
  slot: 'TitleNumber',
})(({ theme }) => ({
  marginRight: theme.spacing(1),

  [isDesktop(theme)]: {
    marginRight: theme.spacing(2),
  },
}))

//TODO: fix in the Payment refactoring
//-----------------------------------------------------------------------
export const StyledCheckoutActions = styled('div', {
  name: 'CheckoutActions',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  '.MuiButtonBase-root': {
    margin: 0,
  },
}))

export const RecapWrapper = styled(CheckoutWrapper, {
  name: 'Recap',
  slot: 'Wrapper',
})(({ theme }) => ({
  lineHeight: 1.5,
  fontSize: '0.875rem',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const RecapTitle = styled('span', {
  name: 'Recap',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: 1.5,
  display: 'flex',

  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2),
  },
}))

export const OrderRecapTopInfo = styled('div', {
  name: 'RecapTopInfo',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },

  '& > div': {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },

  '& > *': {
    padding: `${theme.spacing(2)} ${theme.spacing()} 
      ${theme.spacing(1)} 
      ${theme.spacing(2)}`,
  },

  '&:first-of-type': {
    borderBottom: `1px solid ${theme.palette.text.dark.primary}`,
  },

  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(4.5)}`,

    flex: '0 0 50%',

    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.text.dark.tertiary}`,
      borderBottom: 0,
      marginBottom: 0,
    },
  },
}))

export const StyledCheckoutHeading = styled('section', {
  name: 'CheckoutHeading',
  slot: 'Section',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '100vw',
  width: '100%',
  height: 120,
  backgroundColor: theme.palette.background.light.primary,

  [theme.breakpoints.up('md')]: {
    height: 180,
  },
}))

export const OrderConfirmationHeader = styled('div', {
  name: 'OrderConfirmationHeader',
  slot: 'Wrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  padding: `0 ${theme.spacing(4)}`,

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(16),
  },
}))
export const OrderConfirmationThankYou = styled(StyledTypography, {
  name: 'OrderConfirmationThankYou',
  slot: 'Title',
})(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  fontSize: theme.typography.h1.fontSize,
  lineHeight: '56px',
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(6),
  },
}))

export const OrderConfirmationLabelWrapper = styled('div', {
  name: 'OrderConfirmationLabel',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
}))

export const OrderConfirmationLabel = styled(StyledTypography, {
  name: 'OrderConfirmation',
  slot: 'Label',
})(({ theme }) => ({
  paddingTop: theme.spacing(4),
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.dark.primary,

  button: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
}))

export const OrderConfirmationEmail = styled('span', {
  name: 'OrderConfirmationEmail',
  slot: 'Label',
})(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 'bold',
  paddingLeft: theme.spacing(1),
  color: theme.palette.text.dark.primary,
}))

export const StyledCRXProductsLimitErrorContainer = styled('div', {
  name: 'CRXProductsLimitError',
  slot: 'Container',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))
