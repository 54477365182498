import { useSelector } from 'react-redux'
import { Box, Hidden, useMediaQuery } from '@mui/material'
import {
  CartRecapBottom,
  CartRecapData,
  CartRecapDataContent,
  CartRecapItemWrapper,
  CartRecapTop,
  CartRecapImageContainer,
  CartRecapEDD,
  CartRecapContentContainer,
  CartRecapRightColumn,
  StyledCartRecapDivider,
  CartRecapTotalDataRow,
  StyledRemoveButtonContainer,
  StyledRemoveButtonAsLink,
  CartRecapBrand,
  ContactLensQuantityPrescriptionMessage,
  CartRecapModelPriceDataRow,
  CartRecapDataRowPrice,
  CartRecapDataRowProductModel,
  CartRecapDataRowLabel,
  CartRecapDataRow,
  CartRecapRxInitialTotalPrice,
  CartRecapTotalSection,
  CartRecapTopLeft,
  CartRecapTopRight,
  CartRecapPromoCodeLabelWrapper,
} from '../CartRecap.style'
import { getBrand, getFrontColor, getModelName, getSize } from '../../../../../utils/productAttributes'
import { OrderItemWithRoxProps, OrderRecapItemProps } from '@typesApp/order'
import ProductImage from '../../../../../components/ProductImage/ProductImage'
import React from 'react'
import { catentriesSelector } from '../../../../../features/order/selector'
import theme from '../../../../../themes'
import { useAppSelector } from '../../../../../hooks/redux'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import FormattedPriceDisplay from '../../../../../components/formatted-price-display'
import { PreLoader, StyledTooltip } from '../../../../../components/UI'
import { RxLensDetails } from './RxLensDetails'
import { getRxDiscountedPrice, getRxPrice } from '../../../../../utils/rx'
import { removeCartItemIdSelector, removingCartItemSelector } from '../../../../../features/cartui/cartuiSlice'
import { getInsuranceEventModule } from '../../../../../components/DirectBilling'
import { InsuranceEligibleCallout } from './InsuranceEligibleCallout'
import { CART_PRODUCT_IMAGE_WIDTH } from './constants'
import { useRouter } from 'next/router'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { getPromoCodeDiscount, isDiscountOnItemLevel } from '@utils/order'
import currencyService from '@services/CurrencyService'
import { determineAlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { getCustomerSegmentsfromCookie } from '@features/plp/algoliaUtils'
import { ProductSummaryPrice } from '../ProductSummaryPrice'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import styles from '../styles/CartRecap.module.scss'

const CartRecapRxItem: React.FC<OrderRecapItemProps> = ({ orderItem, onDelete, soldOutStatus }) => {
  const { productId } = orderItem
  const { t } = useTranslation()
  const router = useRouter()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const catentries = useAppSelector(catentriesSelector)
  const isRemovingCartItem = useSelector(removingCartItemSelector)
  const removeOrderItemId = useSelector(removeCartItemIdSelector)
  const { basePath } = useStoreIdentity()
  const { interceptRemoveCartItem } = getInsuranceEventModule()
  const productBrandName = getBrand(orderItem) || 'BRAND NAME'
  const productModelName = getModelName(orderItem)
  const productBaseModelSize = getSize(orderItem, t)
  const productFrontColor = getFrontColor(orderItem)
  const product = (catentries && catentries[productId]) || undefined
  // const { x_offerpriceRx, x_offerDiscountpriceRx } = formatOrderRecapItemPrices(orderItem)

  const algoliaPrice = product?.x_price
    ? determineAlgoliaPrice(product?.x_price, getCustomerSegmentsfromCookie())
    : {
        listPrice: orderItem?.x_offerpriceRx,
        offerPrice: orderItem.unitPrice,
      }

  const estimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)

  const promoCodeDiscountItem = getPromoCodeDiscount(orderItem)
  const promoCodeDiscountServices = orderItem.roxableServices?.reduce((acc, item) => {
    return acc + getPromoCodeDiscount(item)
  }, 0)
  const promoCodeDiscount =
    (promoCodeDiscountItem === Number(algoliaPrice?.offerPrice) ? algoliaPrice?.listPrice : promoCodeDiscountItem) +
    promoCodeDiscountServices
  const hasPromoDiscount = promoCodeDiscount !== 0

  const isItemLevelDiscount = isDiscountOnItemLevel(orderItem)

  const fullRxPriceTotal = getRxPrice(orderItem.roxableServices, (algoliaPrice?.listPrice ?? 0).toString())

  let discountRxPriceTotal = getRxDiscountedPrice(
    orderItem.roxableServices,
    (algoliaPrice?.offerPrice ?? 0).toString(),
    orderItem.adjustment
  )

  const isTotalDiscounted = !!(
    discountRxPriceTotal != null &&
    fullRxPriceTotal &&
    +discountRxPriceTotal < +fullRxPriceTotal
  )

  const attachments = product?.attachments || []

  const { href } = orderItem.seo || product?.seo || { href: '' }

  const isRemovingCurrentItem = isRemovingCartItem && removeOrderItemId === orderItem?.orderItemId

  const onImageClicked = () => {
    const url = `${basePath}${href}`
    router.push(url)
  }

  const handleDelete = () => {
    interceptRemoveCartItem(handleDeleteImpl)
  }

  const handleDeleteImpl = () => {
    onDelete(orderItem)
  }

  return (
    <Box>
      <InsuranceEligibleCallout orderItem={orderItem} />
      <CartRecapItemWrapper>
        {!!estimatedDeliveryDate && (
          <CartRecapEDD>
            {t('CartRecap.Labels.EstimatedDeliveryDate')}
            <span> &nbsp;{estimatedDeliveryDate}</span>
          </CartRecapEDD>
        )}

        <CartRecapContentContainer>
          <CartRecapImageContainer>
            <Box>
              {isMobile && (
                <StyledRemoveButtonContainer>
                  <StyledRemoveButtonAsLink
                    data-element-id="X_X_Prods_"
                    onClick={handleDelete}
                    disabled={isRemovingCurrentItem}
                  >
                    {isRemovingCurrentItem && <PreLoader fill={'dark'} withButton />}
                    {t('CartRecap.Actions.Delete')}
                  </StyledRemoveButtonAsLink>
                </StyledRemoveButtonContainer>
              )}

              <ProductImage
                attachments={attachments}
                data-element-id="X_X_Prods_ProdLink"
                width={CART_PRODUCT_IMAGE_WIDTH}
                usage={'PDP'}
                onClick={onImageClicked}
              />
            </Box>
          </CartRecapImageContainer>
          <CartRecapRightColumn>
            <CartRecapData>
              <Hidden smDown>
                <CartRecapBottom>
                  <StyledRemoveButtonAsLink
                    data-element-id="X_X_Prods_Remove"
                    onClick={handleDelete}
                    disabled={isRemovingCurrentItem}
                  >
                    {isRemovingCurrentItem && <PreLoader fill={'dark'} withButton />}
                    {t('CartRecap.Actions.Delete')}
                  </StyledRemoveButtonAsLink>
                </CartRecapBottom>
              </Hidden>

              <CartRecapTop>
                <CartRecapTopLeft>
                  {!isMobile && <CartRecapBrand>{productBrandName}</CartRecapBrand>}
                  <CartRecapModelPriceDataRow>
                    <CartRecapDataRowProductModel>{productModelName}</CartRecapDataRowProductModel>
                  </CartRecapModelPriceDataRow>
                </CartRecapTopLeft>
                <CartRecapTopRight>
                  <CartRecapDataRowPrice>
                    {product && (
                      <ProductSummaryPrice
                        product={product}
                        adjustments={orderItem?.adjustment ?? []}
                        isRxOrder={true}
                        soldOutStatus={soldOutStatus}
                      />
                    )}
                  </CartRecapDataRowPrice>
                </CartRecapTopRight>
              </CartRecapTop>
              <CartRecapDataContent>
                <>
                  <CartRecapDataRow>
                    <CartRecapDataRowLabel>
                      <span>{t('CartRecap.Labels.Frame')}:</span>
                      <span>{productFrontColor}</span>
                    </CartRecapDataRowLabel>
                  </CartRecapDataRow>
                  <CartRecapDataRow>
                    <CartRecapDataRowLabel>
                      <span>{t('CartRecap.Labels.Size')}:</span>
                      <span>{productBaseModelSize}</span>
                    </CartRecapDataRowLabel>
                  </CartRecapDataRow>

                  <StyledCartRecapDivider />
                  <RxLensDetails orderRxItem={orderItem as OrderItemWithRoxProps} />
                  <StyledCartRecapDivider />
                  {t('CartRecap.ShowSendPrescriptionLaterWarning') && (
                    <ContactLensQuantityPrescriptionMessage>
                      <StyledTooltip
                        placement="top"
                        contentVariant={{
                          type: 'textonly',
                          body: t('CartRecap.Labels.Tooltip.MessagePrescription'),
                        }}
                        colorVariant={'dark'}
                        enterTouchDelay={0}
                      >
                        <div>
                          <SVGIcon library="validation" name="info" className={styles.infoIcon} />
                        </div>
                      </StyledTooltip>
                      {t('CartRecap.Labels.MessagePrescription')}
                    </ContactLensQuantityPrescriptionMessage>
                  )}
                </>

                {hasPromoDiscount && isItemLevelDiscount && (
                  <CartRecapPromoCodeLabelWrapper>
                    <div>{t('CartRecap.Labels.PromoCode')}</div>
                    <div>
                      -{currencyService.getSymbolByName(orderItem?.currency) ?? ''}
                      {promoCodeDiscount}
                    </div>
                  </CartRecapPromoCodeLabelWrapper>
                )}

                <CartRecapTotalDataRow uppercase={true}>
                  <div>
                    <span> {t(['CartRecap.Labels.Total', 'TOTAL'])} </span>
                  </div>
                  <CartRecapTotalSection>
                    {isTotalDiscounted && (
                      <CartRecapRxInitialTotalPrice>
                        <FormattedPriceDisplay min={+fullRxPriceTotal} currency={orderItem.currency} />
                      </CartRecapRxInitialTotalPrice>
                    )}
                    {!!fullRxPriceTotal && (
                      <FormattedPriceDisplay
                        min={isTotalDiscounted ? +discountRxPriceTotal : +fullRxPriceTotal}
                        currency={orderItem.currency}
                      />
                    )}
                  </CartRecapTotalSection>
                </CartRecapTotalDataRow>
              </CartRecapDataContent>
            </CartRecapData>
          </CartRecapRightColumn>
        </CartRecapContentContainer>
      </CartRecapItemWrapper>
    </Box>
  )
}

export default CartRecapRxItem
