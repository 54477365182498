import React from 'react'

import { OrderItemEyeContactLensData } from '@typesApp/cart'
import { ContactLensesSelect } from '../../../../ProductDetails/components/ContactLensesSelect'
import { formatOrderRecapItemClquantity, generateQuantityOptions } from '../../../../../utils/order'

export const ContactLensQuantitySelect: React.FC<{
  eye: string
  orderItemClData: OrderItemEyeContactLensData
  isDisabled?: boolean
  onQuantityChanged?: (quantity: string, orderItemId: string) => void
}> = ({ eye, orderItemClData, isDisabled = false, onQuantityChanged }) => {
  const isValueChanged = (value: string | null | undefined): boolean => {
    return parseFloat(orderItemClData.quantity || '0') !== parseFloat(value || '0')
  }

  return (
    <ContactLensesSelect
      id={'quantity'}
      eye={eye}
      onSelectValueChange={(_eye, _id, val) =>
        onQuantityChanged && isValueChanged(val) && onQuantityChanged(val || '', orderItemClData.orderItemId || '')
      }
      value={!!orderItemClData.quantity ? formatOrderRecapItemClquantity(orderItemClData.quantity) : '0'}
      options={generateQuantityOptions(30, 1)}
      disabled={isDisabled}
    />
  )
}
