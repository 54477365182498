import React from 'react'
//COMPONENTS

import NavigationLogo from '../NavigationBar/components/NavigationLogo'
//STYLES
import {
  ReducedHeaderWrapper,
  ReducedHeaderNavigationLogoWrapper,
  ReducedHeaderCheckoutDisclaimer,
  ReducedHeaderMobileWrapper,
  ReducedHeaderMobileBottom,
  ReducedHeaderMobileTop,
  ReducedHeaderMobileBack,
} from './reducedheader.style'
//HOOKS
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { useTheme } from '@mui/material/styles'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface ReducedHeaderProps {
  title?: string
  children?: any
}

const ReducedHeader: React.FC<ReducedHeaderProps> = ({ title, children }) => {
  const { isMobile } = useBreakpoints()
  const theme = useTheme()

  return (
    <>
      {isMobile ? (
        <ReducedHeaderMobileWrapper>
          <ReducedHeaderMobileTop>
            <ReducedHeaderMobileBack>
              <SVGIcon library="arrow" name="arrow-left" color={theme.palette.custom.cyprus} />
            </ReducedHeaderMobileBack>
            <NavigationLogo width={95} height={24} />
            <ReducedHeaderCheckoutDisclaimer>{title && <span>{title}</span>}</ReducedHeaderCheckoutDisclaimer>
          </ReducedHeaderMobileTop>
          <ReducedHeaderMobileBottom>{children}</ReducedHeaderMobileBottom>
        </ReducedHeaderMobileWrapper>
      ) : (
        <ReducedHeaderWrapper>
          <ReducedHeaderNavigationLogoWrapper>
            <NavigationLogo />
          </ReducedHeaderNavigationLogoWrapper>
          {children}
          <ReducedHeaderCheckoutDisclaimer>{title && <span>{title}</span>}</ReducedHeaderCheckoutDisclaimer>
        </ReducedHeaderWrapper>
      )}
    </>
  )
}

export default ReducedHeader
