import styled from '@mui/material/styles/styled'

export const MainContainer = styled('div', {
  name: 'MediaContainer',
  slot: 'CarouselItems'
})(({ }) => ({
  position: 'relative'
}))

export const CShieldOverlay = styled('div', {
  name: 'MediaContainer',
  slot: 'TextOverlay'
})(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.custom.white,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 700,

  '&.title': {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    bottom: '10%',
    [theme.breakpoints.down('sm')]: {
      bottom: '1%'
    },
  },
  '&.items': {
    width: '100%',
    bottom: '20%',
    display: 'flex',
    div: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '15%'
    },
  }

}))
