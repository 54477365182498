import { useEffect, useState } from 'react'
import { ACCOUNT_CHILDREN } from '../../constants/routes'
import { useRouter } from 'next/router'

export const useIsAccountPage = () => {
  const router = useRouter()
  const [isAccountPage, setIsAccountPage] = useState<boolean>(false)
  const AccountChildrenValues: string[] = Object.values(ACCOUNT_CHILDREN)

  useEffect(() => {
    const item = AccountChildrenValues.find((path) => {
      if (typeof path !== 'string') return false
      return router.asPath.includes(path)
    })

    setIsAccountPage(!!item)
  }, [router])

  return isAccountPage
}
