import React from 'react'
import { useTranslation } from 'next-i18next'
import { useFavoriteStores } from '../../../hooks/useFavoriteStores'
import { Store } from '../../../types/store'
import {
  StoreInfoStyled,
  StoreName,
  StoreInfoWrapper,
  StoreInfoHeader,
  FindStoreInputStyled,
  DashBoardStoresInfoHeader,
} from './DashboardMyStores.styles'
import {
  CTATextContainer,
  DashboardMyStoresStyled,
  DashboardTileHeaderWrapper,
  ShopNowAccountButton,
} from '../AccountDashboard.style'
import theme from '../../../themes'
import * as ROUTES from '../../../constants/routes'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { generateAccountPagePath } from '../../Account/Account'
import { useGoogleSuggestions } from '../../../hooks/useGoogleSuggestions'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const DashboardMyStoresEmpty: React.FC = () => {
  const { t } = useTranslation()

  const storeCountry = useStoreIdentity().country.toUpperCase()

  const { gmapLoaded, googleAPISuggestionEnabled } = useGoogleSuggestions({
    addressType: '',
    country: storeCountry,
    page: ROUTES.ACCOUNT_CHILDREN.STORES,
    state: '',
  })

  return (
    <>
      <DashboardMyStoresStyled>
        <CTATextContainer>{t('AccountDashboard.FindAStore')}</CTATextContainer>
        {gmapLoaded && googleAPISuggestionEnabled && <FindStoreInputStyled dataElementId={'myStoresCTA'} />}
      </DashboardMyStoresStyled>
    </>
  )
}

export interface DashboardMyStoresWithStoresInfo {
  store?: Store
  qnt: number
}

const DashboardMyStoresWithStoresInfo: React.FC<DashboardMyStoresWithStoresInfo> = ({ store, qnt }) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()

  if (!store) return <DashboardMyStoresEmpty />

  return (
    <>
      <StoreInfoHeader>{t('AccountDashboard.FavouriteStores', { count: qnt }).toUpperCase()}</StoreInfoHeader>
      <StoreInfoWrapper>
        <StoreName>{store.storeName}</StoreName>
        <StoreInfoStyled store={store} hideBookEyeBtn={true} isBoldStoreHours={false} />
      </StoreInfoWrapper>
      <ShopNowAccountButton href={generateAccountPagePath(langCode, ROUTES.ACCOUNT_CHILDREN.STORES)}>
        {t('AccountDashboard.ViewAllStores').toUpperCase()}
      </ShopNowAccountButton>
    </>
  )
}

export const DashboardMyStores: React.FC = () => {
  const { t } = useTranslation()
  const { favoriteStores, defaultFavoriteStoreId } = useFavoriteStores()
  const store = favoriteStores.find(s => `${s.storeId}` === defaultFavoriteStoreId) || favoriteStores[0]
  return (
    <>
      <DashboardTileHeaderWrapper>
        {!store && <SVGIcon library="global" name="stores" size={48} color={theme.palette.text.dark.primary} />}
        <DashBoardStoresInfoHeader>{t('AccountDashboard.MyStores').toUpperCase()}</DashBoardStoresInfoHeader>
      </DashboardTileHeaderWrapper>

      <DashboardMyStoresWithStoresInfo store={store} qnt={favoriteStores.length} />
    </>
  )
}
