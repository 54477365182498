import React from 'react'

import {
  RxLensAttributesList,
  RxLensAttributesListItem,
} from './OrderRecapRxItemLensDetails.style'
import { OrderItemWithRoxProps } from '../../types/order'

export const OrderRecapRxItemLensDetails: React.FC<{
  orderRxItem: OrderItemWithRoxProps
}> = ({ orderRxItem }) => {
  return (
    !!orderRxItem.roxableAttributes && (
      <RxLensAttributesList>
        {Object.values(orderRxItem.roxableAttributes).map((value, i) => {
          return (
            <RxLensAttributesListItem key={`${i}_${value}`}>
              <span>{value}</span>
            </RxLensAttributesListItem>
          )
        })}
      </RxLensAttributesList>
    )
  )
}
