import { useMemo } from 'react'
import * as ROUTES from '../../constants/routes'

export const useCheckoutPaths = (country: string) => {
  return useMemo(() => {
    return {
      checkout: `/${country}/${ROUTES.CHECKOUT}`,
      cart: `/${country}/${ROUTES.CART}`,
      shipping: `/${country}/${ROUTES.CHECKOUT_SHIPPING}`,
      payment: `/${country}/${ROUTES.CHECKOUT_PAYMENT}`,
      'reorder-summary': `/${country}/${ROUTES.CHECKOUT_REORDER_SUMMARY}`,
      'order-confirmation': `/${country}/${ROUTES.ORDER_CONFIRMATION}`,
      uploadPrescription: `/${country}/${ROUTES.CHECKOUT_UPLOAD_PRESCRIPTION}`,
    }
  }, [country])
}
