import React, { useEffect, useState } from 'react'
import { areUserDetailsLoadingSelector, userDetailsSelector } from '../../redux/selectors/user'
import {
  orderItemsSelector,
  //paypalExpressSelector,
  orderDetailsSelector,
} from '../../features/order/selector'

import { AddressFormData } from '../../types/form'
import { BILLING_ADDRESS_ID } from '../../constants/checkout'
import { PreLoader } from '../UI'
import addressUtil from '../../utils/addressUtil'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
//Redux
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { OrderItem } from '../../types/order'
import {
  ShippingBillingRecapContent,
  ShippingBillingRecaptitle,
  StyledEditBagButtonWrapper,
  StyledEditButtonLink,
} from '../order-recap/OrderRecap.style'
import OrderRecapCartEditConfirmDialog from '../order-recap/OrderRecapEditBag'
import * as ROUTES from '../../constants/routes'
import { IS_REORDER_SUCCESS, REORDER_BILLING_ADDRESS_ID } from '@foundation/constants/common'

const buildAddressFormData = (dataCollection): AddressFormData => {
  const formData: AddressFormData = {
    addressId: dataCollection?.addressId || '',
    addressType: dataCollection?.addressType,
    fullName: `${dataCollection?.firstName || ''} ${dataCollection?.lastName || ''}`,
    addressLine: `${addressUtil.getAddressWithDelimiter(dataCollection?.addressLine[0]).addressLine2 || ''}, ${
      dataCollection?.city || ''
    }, ${dataCollection?.state || ''} ${dataCollection?.zipCode || ''}`,
    email1: dataCollection?.email1 || '',
    phone1: dataCollection?.phone1 || '',
  }
  return formData
}
export const ShippingBillingRecap: React.FC<{
  orderComplete: boolean
}> = () => {
  const { t } = useTranslation()
  const orderItems = useSelector(orderItemsSelector)
  //const paypalExpress = useSelector(paypalExpressSelector)
  const userDetails = useSelector(userDetailsSelector)
  const areUserDetailsLoading = useSelector(areUserDetailsLoadingSelector)
  const orderDetails = useSelector(orderDetailsSelector)
  const usedBillingAddressId =
    localStorageUtil.get(IS_REORDER_SUCCESS) === true
      ? localStorageUtil.get(REORDER_BILLING_ADDRESS_ID) ?? localStorageUtil.get(BILLING_ADDRESS_ID)
      : localStorageUtil.get(BILLING_ADDRESS_ID)
  const hiddenAddressFormRows = ['addressId', 'addressType']
  const hiddenBillingAddressFormRows = ['addressId', 'addressType', 'email1', 'phone1']
  const [shippingFormData, setShippingFormData] = useState<AddressFormData>({})
  const [billingFormData, setBillingFormData] = useState<AddressFormData>({})
  const [isEditOpen, setIsEditOpen] = useState(false)

  const handleEditClick = () => {
    setIsEditOpen(!isEditOpen)
  }

  useEffect(() => {
    const hasPaymentInstruction = (orderDetails?.paymentInstruction?.length ?? 0) > 0
    const isPaypalExpressOrder =
      !!orderDetails?.paymentInstruction?.find(pi => !!pi)?.protocolData.find(el => el.name === 'isPayPalExpress')
        ?.value || false

    const shippingAddressData: OrderItem = orderItems[0]
    const billingAddressId =
      isPaypalExpressOrder || hasPaymentInstruction
        ? orderDetails?.paymentInstruction[0]?.billing_address_id
        : usedBillingAddressId

    const billingAddressData = userDetails?.contact?.find(address => address.addressId === billingAddressId)

    setShippingFormData(buildAddressFormData(shippingAddressData))
    setBillingFormData(buildAddressFormData(billingAddressData))
  }, [userDetails, orderItems, areUserDetailsLoading, orderDetails])

  return (
    <div>
      <div aria-label="BillingAndShippingWrapper" style={{ position: 'relative' }}>
        <StyledEditBagButtonWrapper sx={{ position: 'absolute', top: '-4px', right: 0, zIndex: 2 }}>
          <StyledEditButtonLink onClick={handleEditClick}>{t('OrderRecap.Labels.Edit')}</StyledEditButtonLink>
          <OrderRecapCartEditConfirmDialog
            route={ROUTES.CHECKOUT_SHIPPING}
            open={isEditOpen}
            handleClose={handleEditClick}
          />
        </StyledEditBagButtonWrapper>
        {shippingFormData && Object.keys(shippingFormData).length > 0 && !areUserDetailsLoading ? (
          <>
            <ShippingBillingRecaptitle>{t('ShippingAndBillingRecap.shippingRecapTitle')}</ShippingBillingRecaptitle>
            <ShippingBillingRecapContent>
              {Object.keys(shippingFormData)
                .filter(row => !hiddenAddressFormRows.includes(row))
                .map(key => (
                  <span key={key}>{shippingFormData[key]}</span>
                ))}
            </ShippingBillingRecapContent>
          </>
        ) : (
          <>
            <PreLoader size={20} />
          </>
        )}

        {billingFormData && Object.keys(billingFormData).length > 0 && !areUserDetailsLoading ? (
          <>
            <ShippingBillingRecaptitle>{t('ShippingAndBillingRecap.billingRecapTitle')}</ShippingBillingRecaptitle>
            <ShippingBillingRecapContent>
              {Object.keys(billingFormData)
                .filter(row => !hiddenBillingAddressFormRows.includes(row))
                .map(key => (
                  <span key={key}>{billingFormData[key]}</span>
                ))}
            </ShippingBillingRecapContent>
          </>
        ) : (
          <>
            <PreLoader size={20}></PreLoader>
          </>
        )}
      </div>
    </div>
  )
}
