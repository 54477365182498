import styled from '@mui/material/styles/styled'
import { Button } from '../Button'

export const PrescriptionBannerCheckoutWrapper = styled('div', {
  name: 'PrescriptionBannerCheckout',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  marginTop: theme.spacing(12),

  img: {
    width: '100%',
    height: 375,
    objectFit: 'cover',
  },

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gridTemplateColumns: '1fr 1fr',

    img: {
      height: 356,
      order: 2,
    },
  },
}))

export const PrescriptionBannerCheckoutLabel = styled('div', {
  name: 'PrescriptionBannerCheckout',
  slot: 'Label',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  color: theme.palette.text.dark.primary,
  background: theme.palette.background.light.secondary,
  padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(
    8
  )} ${theme.spacing(4)}`,

  'span:nth-of-type(1)': {
    fontWeight: 'bold',
    fontSize: 20,
    textTransform: 'uppercase',
  },

  'span:nth-of-type(2)': {
    fontWeight: 'bold',
    fontSize: 20,
    textTransform: 'uppercase',
  },

  'span:nth-of-type(3)': {
    fontSize: 16,
    marginTop: theme.spacing(2),
  },

  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
    alignItems: 'flex-start',
    padding: `${theme.spacing(22)} ${theme.spacing(1)} ${theme.spacing(
      22
    )} ${theme.spacing(16)}`,

    'span:nth-of-type(1)': {
      width: 400,
    },

    'span:nth-of-type(3)': {
      width: 430,
    },
  },
}))

export const PrescriptionBannerCheckoutButton = styled(Button, {
  name: 'PrescriptionBannerCheckout',
  slot: 'Button',
})(({ theme }) => ({
  color: theme.palette.background.light.secondary,
  marginTop: theme.spacing(4),
  width: 285,
  height: 40,

  [theme.breakpoints.up('sm')]: {
    width: 189,
    height: 40,
    padding: `${theme.spacing(2.5)}`,
  },
}))
