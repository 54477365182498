import styled from '@mui/material/styles/styled'
import { StyledAnchor, StyledButtonAsLink } from '../../../components/UI'

export const OrderStatusContainer = styled('div', {
  name: 'OrderStatus',
  slot: 'Container'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(4),
  },
}))

export const OrderStatusPrescriptionLabel = styled('span', {
  name: 'OrderStatus',
  slot: 'PrescriptionLabel'
})(({ theme }) => ({
  color: theme.palette.color.error,
  fontWeight: 700,
}))

export const OrderStatusShippedLabel = styled('span', {
  name: 'OrderStatus',
  slot: 'ShippedLabel'
})(({ theme }) => ({
  color: theme.palette.color.success,
  fontWeight: 700,
}))

export const StyledText = styled('div', {
  name: 'OrderStatus',
  slot: 'Text',
  shouldForwardProp: (prop) => prop !== 'isBold'
})<{ isBold?: boolean }>(({ isBold = false}) => ({
  fontWeight: isBold ? 700 : undefined,
}))

export const StyledAnchorCTA = styled(StyledAnchor, {
  name: 'OrderStatus',
  slot: 'AnchorCTA'
})(
  ({ theme }) => ({
    fontWeight: 700,
    textDecoration: 'underline',
    '&::after': {
      width: '0%',
    },

    [theme.breakpoints.up('sm')]: {
      textDecoration: 'none',
      '&::after': {
        width: '100%',
      },
    },
  })
)

export const StyledLinkCTA = styled(StyledButtonAsLink, {
  name: 'OrderStatus',
  slot: 'LinkCTA'
})(() => ({
  fontWeight: 700,
}))

