//Standard libraries
import React, { useEffect } from 'react'
//Redux
import { loginStatusSelector, userInitStatusSelector } from '../../redux/selectors/user'

//Custom libraries
import { useSelector } from 'react-redux'
//Foundation libraries
import { useSite } from '../hooks/useSite'
import { useRouter } from 'next/router'
import { forceLoggedCheckout } from '@redux/selectors/site'
import { CHECKOUT } from '@constants/common'
import { HOME, REGISTER_PROTECTED_ROUTES, SIGNIN } from '@constants/routes'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'

const LoginGuard: React.FC = () => {
  const { mySite } = useSite()
  const router = useRouter()
  const userLoggedIn = useSelector(loginStatusSelector)
  const userInited = useSelector(userInitStatusSelector)
  const isForceLoggedCheckout = useSelector(forceLoggedCheckout)
  const { langCode } = useStoreIdentity()

  useEffect(() => {
    if (mySite && userInited) {
      let registerProtectedRoute

      if (isForceLoggedCheckout && router.pathname.includes(CHECKOUT) && !userLoggedIn) {
        router.push({
          pathname: `/${langCode}/${SIGNIN}`,
          query: {
            redirect: `/${langCode}/${CHECKOUT}`,
          },
        })
      }

      if (!userLoggedIn) {
        registerProtectedRoute = REGISTER_PROTECTED_ROUTES.some(b => {
          return router.pathname.includes(b)
        })

        if (!!registerProtectedRoute) {
          router.push(`/${langCode}/${HOME}`)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, userLoggedIn, mySite, userInited, langCode])

  return <></>
}

export default LoginGuard
