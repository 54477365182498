import React, { FC } from 'react'

import styled from '@mui/material/styles/styled'
import { useSelector } from 'react-redux'
import { creditCardNumberSelector, paypalExpressSelector } from '../../../features/order/selector'
import creditCardType from 'credit-card-type'
import {
  AmExIcon,
  ApplePayIcon,
  DiscoverIcon,
  JCBIcon,
  MastercardIcon,
  PayPalIcon,
  VisaIcon,
} from '@components/UI-CSS/SVGIcon/PaymentIcon'
import { IOrderDetails } from '@typesApp/order'
import { CHECKOUT_NAMES } from '@constants/paymentMethods'
import { getOrderPayMethodId } from '@utils/payMethods'

export const Container = styled('div', {
  name: 'CheckoutPaymentMethod',
  slot: 'Container',
})(({}) => ({
  width: '100%',
  height: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  svg: {
    width: 30,
    height: 20,
  },
}))
export const DigitsWrapper = styled('div', {
  name: 'Digits',
  slot: 'Wrapper',
})(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.text.light.secondary,
}))

interface ICheckoutPaymentMethodProps {
  cardNumber?: string
  orderDetails?: IOrderDetails | null
}
const CheckoutPaymentMethod: FC<ICheckoutPaymentMethodProps> = ({ cardNumber, orderDetails }) => {
  const creditCardNumber = useSelector(creditCardNumberSelector) || cardNumber
  const { isSelected: isPayPal } = useSelector(paypalExpressSelector)
  // Get last 4 digits of credit card number
  const lastDigits = creditCardNumber?.slice(-4)
  const currentCardType = creditCardNumber ? creditCardType(creditCardNumber) : undefined
  const cardType = currentCardType && currentCardType[0]?.niceType

  const payMethodId = orderDetails && getOrderPayMethodId(orderDetails)
  const isApplePay = payMethodId === CHECKOUT_NAMES.APPLE_PAY

  const getIcon = () => {
    switch (true) {
      case cardType === 'Mastercard':
        return MastercardIcon
      case cardType === 'American Express':
        return AmExIcon
      case cardType === 'Discover':
        return DiscoverIcon
      case cardType === 'JCB':
        return JCBIcon
      case isPayPal:
        return PayPalIcon
      case isApplePay:
        return ApplePayIcon
      default:
        return VisaIcon
    }
  }
  const Icon = getIcon()
  return (
    <Container>
      <Icon />
      {lastDigits && <DigitsWrapper>•••• •••• •••• {lastDigits}</DigitsWrapper>}
      {isPayPal && <DigitsWrapper>PayPal</DigitsWrapper>}
    </Container>
  )
}

export default CheckoutPaymentMethod
