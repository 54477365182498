import styled from '@mui/material/styles/styled'
import { Link } from '@components/common/Link/Link'
import { StyledLinkAsButton } from '../../components/UI'

export const OrderHistoryTitle = styled('div', {
  name: 'OrderHistory',
  slot: 'Title',
})(({ theme }) => ({
  display: 'flex',
  fontWeight: 700,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: 1.4,
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.5,
  },
}))

export const OrderHistoryHeader = styled('div', {
  name: 'OrderHistory',
  slot: 'Header'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(8),
  minHeight: 34,

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const OrderHistoryEmptyText = styled('span', {
  name: 'OrderHistory',
  slot: 'EmptyText'
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(4),
    lineHeight: 1.5,
    color: theme.palette.text.dark.primary,
  },
}))

export const OrderHistoryWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'Wrapper'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(6),
  },


  [theme.breakpoints.up(1023)]: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingTop: theme.spacing(8),
  },
}))

export const StyledBackToOrderHistoryWrapper = styled(Link, {
  name: 'OrderHistory',
  slot: 'BackToOrderHistoryWrapper',
})(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.dark.primary,

  svg: {
    marginRight: theme.spacing(3),
  }
}))

export const OrderDetailsHeader = styled('div', {
  name: 'OrderHistory',
  slot: 'OrderDetailsHeader',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontWeight: 700,
  fontSize: theme.spacing(5),
  lineHeight: 1.4,
}))

export const OrderHistoryEmptyWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'EmptyWrapper'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(6),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}))

export const OrderHistoryEmptyCTAContainer = styled('div', {
  name: 'OrderHistory',
  slot: 'EmptyCTAContainer'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    columnGap: theme.spacing(6),
    whiteSpace: 'nowrap',
    '& a': {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
}))

export const OrderHistoryContainerWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'ContainerWrapper'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  rowGap: theme.spacing(6),
  marginBottom: theme.spacing(16),
}))

export const OrderHistoryStatusContainer = styled('div', {
  name: 'OrderHistory',
  slot: 'StatusContainer'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(4),
  },
}))

export const OrderHistoryStyledButtonCTA = styled(StyledLinkAsButton, {
  name: 'OrderHistory',
  slot: 'ButtonCTA'
})(() => ({
  fontWeight: 700,
}))

export const OrderHistoryList = styled('div', {
  name: 'OrderHistory',
  slot: 'List'
})(() => ({}))

// For mobile screens only
export const OrderHistoryListItem = styled('div', {
  name: 'OrderHistory',
  slot: 'ListItem',
  shouldForwardProp: (prop) => prop !== 'themeMode'
}) <{ themeMode: string }>`
  ${({ theme }) => `
  padding: ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;

  &:not(:first-of-type) {
    margin-top: 10px
  }


  &:not(:last-child) {
    border-bottom: 1px solid ${theme.palette.text.primary};
  }
`}
`
