import React from 'react'
import { useTheme } from '@mui/material/styles'
import {
  StyledHeaderStepsBar,
  StyledHeaderStepsBarContainer,
  StyledHeaderStepsBarElement,
  StyledHeaderStepsBarElementDot,
  StyledHeaderStepsBarElementLine,
  StyledHeaderStepsBarElementStepname,
  StyledHeaderStepsBarElementWrapper,
} from './HeaderStepsBar.style'
import useBreakpoints from '../../../hooks/useBreakpoints'
import Log from '../../../services/Log'
import { AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface Step {
  stepId: string
  stepNumber: number
  stepTitle: string
  url: string
}

export interface StepsProgress {
  activeStep?: string
  previousSteps: Step[]
  nextSteps: Step[]
  allSteps: Step[]
}

export interface HeaderStepsBarProps {
  stepState: StepsProgress | null
  handlePreviousStepClick: () => Promise<void> | void | Promise<AxiosResponse<any>>
}

export const HeaderStepsBar: React.FC<HeaderStepsBarProps> = ({ stepState, handlePreviousStepClick }) => {
  if (stepState === null) return null
  const { activeStep, previousSteps, allSteps } = stepState
  const { isMobile } = useBreakpoints()
  const theme = useTheme()
  const router = useRouter()

  const goToStep = async (index: number) => {
    try {
      await handlePreviousStepClick()
      router.push(`${allSteps[index].url}`)
    } catch (e) {
      if (e instanceof Error) {
        Log.error(e.message, window.location.href)
      } else {
        Log.error(e as string, window.location.href)
      }
    }
  }

  return (
    <StyledHeaderStepsBarContainer>
      <StyledHeaderStepsBar>
        {allSteps.map((step, index) => {
          const isActive = step.stepId === activeStep ? 1 : 0
          const isCompleted = previousSteps.includes(step) ? 1 : 0
          const isLastStep = index + 1 === allSteps.length

          return (
            <StyledHeaderStepsBarElement
              key={step.stepId}
              active={isActive}
              completed={isCompleted}
              last={isLastStep ? 1 : 0}
            >
              <StyledHeaderStepsBarElementWrapper
                completed={isCompleted}
                onClick={() => isCompleted && goToStep(index)}
              >
                <StyledHeaderStepsBarElementDot completed={isCompleted} active={isActive}>
                  {isCompleted === 1 && (
                    <SVGIcon library="validation" name="validate" color={theme.palette.custom.cyprus} />
                  )}
                  {isMobile && (
                    <StyledHeaderStepsBarElementStepname>{step.stepTitle}</StyledHeaderStepsBarElementStepname>
                  )}
                </StyledHeaderStepsBarElementDot>
                {!isMobile && (
                  <StyledHeaderStepsBarElementStepname>{step.stepTitle}</StyledHeaderStepsBarElementStepname>
                )}
              </StyledHeaderStepsBarElementWrapper>
              {!isLastStep && <StyledHeaderStepsBarElementLine completed={isCompleted} />}
            </StyledHeaderStepsBarElement>
          )
        })}
      </StyledHeaderStepsBar>
    </StyledHeaderStepsBarContainer>
  )
}
