export enum SessionPrefix {
  SCROLL_PAGE = 'scrollPage',
}

interface SetSessionItem<T> {
  key: string
  value: T
  prefix: SessionPrefix
}

const buildKey = (prefix: SessionPrefix, key: string) => `${prefix}_${key}`

export const setSessionItem = <T>({ key, value, prefix }: SetSessionItem<T>): void => {
  sessionStorage.setItem(buildKey(prefix, key), JSON.stringify(value))
}

interface GetSessionItem<T> {
  key: string
  prefix: SessionPrefix
}

export const getSessionItem = <T extends { x: number; y: number }>({ key, prefix }: GetSessionItem<T>): T | null => {
  const storedValue = sessionStorage.getItem(buildKey(prefix, key))
  if (storedValue) {
    try {
      const parsedValue = JSON.parse(storedValue) as T
      return parsedValue
    } catch (error) {
      console.log(`Error parsing session storage: ${buildKey(prefix, key)}`, error)
    }
  }
  return null
}
