import React, { FC, useMemo } from 'react'
import { LinkProps } from 'next/link'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { formatPathWithBase } from '../../utils/url'
import { StyledAnchor, AnchorProps } from '../UI/Anchor/Anchor'

const CountryBaseAnchor: FC<AnchorProps> = (props) => {
  const { href, ...restProps } = props
  const { basePath } = useStoreIdentity()

  const formattedTo = useMemo<LinkProps['href']>(() => {
    if (typeof href === 'string') {
      return formatPathWithBase(href, basePath)
    }

    if (typeof href === 'object' && href.pathname) {
      return {
        ...href,
        pathname: formatPathWithBase(href.pathname, basePath),
      }
    }

    return href
  }, [basePath, href])

  return <StyledAnchor external={false} {...restProps} href={formattedTo} />
}

export default CountryBaseAnchor
