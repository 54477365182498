import { CART, CHECKOUT_REORDER_SUMMARY } from '../../constants/routes'
import React, { useState, useEffect } from 'react'
import { IReorderInfo } from '../../types/order'
import { ANALYTICS_IS_REORDER } from '../../foundation/analytics/tealium/lib'
import { useDispatch, useSelector } from 'react-redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { userIdSelector } from '../../redux/selectors/user'
import { useCopyOrderMutation, useOneClickReorderMutation } from '@features/order/query'
import { resetReorderId, setReorderId, setReorderInfo } from '@features/order/slice'
import { useRouter } from 'next/router'
import { localStorageUtil, sessionStorageUtil } from '@foundation/utils/storageUtil'
import config from '@configs/config.base'
import {
  IS_REORDER_SUCCESS,
  REORDER_BILLING_ADDRESS_ID,
  REORDER_ORDER_ID,
  REORDER_OLD_ORDER_ID,
} from '@foundation/constants/common'
import { Button } from '@components/UI/Button'
import { PreLoaderWithButton } from '@components/UI/Loader'
import { clearReorderLocalStorage } from '@utils/order'
import paymentInstructionService from '@foundation/apis/transaction/paymentInstruction.service'
import Log from '@services/Log'
import Snackbar from '@mui/material/Snackbar/Snackbar'
import { StyledAlert, StyledAlertHeader, StyledAlertMessage } from '@components/UI/Alert'
import { useTranslation } from 'next-i18next'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'

const ReorderButton: React.FC<{
  label: string
  orderId: string
  isRX: string
  shouldDisable?: boolean
}> = ({ label, orderId, isRX, shouldDisable = null }) => {
  const { langCode } = useStoreIdentity()
  const { t: translate } = useTranslation()
  const userId = useSelector(userIdSelector)
  const router = useRouter()
  const dispatch = useDispatch()
  const [performReorder, { isLoading: isThreeClickReorderBusy }] = useCopyOrderMutation()
  const [oneClickReorder, { isLoading: isOneClickReorderBusy }] = useOneClickReorderMutation()
  const [isReorderFailure, setReorderFailure] = useState<boolean>(false)
  const isReorderBusy =
    shouldDisable === null
      ? isThreeClickReorderBusy || isOneClickReorderBusy
      : (isThreeClickReorderBusy || isOneClickReorderBusy) && shouldDisable
  const isOneClickReorderEnabled = config.isOneClickReorderEnabled
  const { completeCheckoutStep } = useCheckoutSteps()

  useEffect(() => {
    completeCheckoutStep('')
  }, [completeCheckoutStep])

  const onOrderAgain = (orderId: string, isRX: string) => {
    if (!orderId) return

    dispatch(setReorderId(orderId))
    sessionStorageUtil.set(ANALYTICS_IS_REORDER, true)
    clearReorderLocalStorage()
    isOneClickReorderEnabled
      ? oneClickReorder({ orderId })
          .unwrap()
          .then(data => {
            const reorderInfo = data as unknown as IReorderInfo
            localStorageUtil.set(REORDER_OLD_ORDER_ID, orderId)
            reorderInfo.orderId && localStorageUtil.set(REORDER_ORDER_ID, reorderInfo.orderId)
            reorderInfo.billingAddressId &&
              localStorageUtil.set(REORDER_BILLING_ADDRESS_ID, reorderInfo.billingAddressId)
            localStorageUtil.set(IS_REORDER_SUCCESS, reorderInfo.reorderSuccessful)
            dispatch(setReorderInfo(reorderInfo))
          })
          .then(() => {
            paymentInstructionService
              .calculateTax({})
              .then(() => {
                completeCheckoutStep('start-reorder')
                router.push(`/${langCode}/${CHECKOUT_REORDER_SUMMARY}`)
              })
              .catch(e => {
                Log.error('Could not calculate tax: ' + e)
              })
          })
          .catch(() => {
            setReorderFailure(true)
          })
      : performReorder({
          fromOrderId: orderId,
          rxCopyEnabled: isRX,
          newUserId: userId,
        })
          .unwrap()
          .then(() => {
            router.push(`/${langCode}/${CART}`)
          })
          .finally(() => dispatch(resetReorderId()))
  }

  const ReorderErrorSnackBar = () => {
    return (
      <Snackbar open={true} autoHideDuration={2000} onClose={() => setReorderFailure(false)}>
        <StyledAlert severity="error">
          <StyledAlertHeader>{translate('ReorderSummary.ErrorMessageHeader')}</StyledAlertHeader>
          <StyledAlertMessage>{translate('ReorderSummary.ErrorMessageBody')}</StyledAlertMessage>
        </StyledAlert>
      </Snackbar>
    )
  }

  return (
    <>
      {isReorderFailure && <ReorderErrorSnackBar />}
      <Button
        data-element-id="MyAccount_Reorder"
        onClick={() => onOrderAgain(orderId, isRX)}
        variant="primary"
        disabled={isReorderBusy}
        sx={{ margin: 0 }}
      >
        <PreLoaderWithButton fill="light" busy={isReorderBusy}>
          {label}
        </PreLoaderWithButton>
      </Button>
    </>
  )
}

export default ReorderButton
