import styled from '@mui/material/styles/styled'

export const StyledCountryCodeLabel = styled('label')(({ theme }) => ({
  color: theme.palette.background.dark.tertiary,
}))

export const StyledCountryCodeInput = styled('input')(({ theme }) => ({
  fontSize: theme.typography.htmlFontSize,
  height: '50px',
  width: '30px',
  border: 'none',
  outlineWidth: '0',
}))
