import React, { useCallback, useEffect, useRef } from 'react'
import {
  InputContainer,
  StyledButton,
  IconContainer,
  InputWithIconContainer,
  InputErrorMessage,
  StyledInput,
} from './FindStore.style'
import { useRouter } from 'next/navigation'
import { useFindLocation } from './useFindLocation'
import { useTranslation } from 'next-i18next'
import { STORELOCATOR, STORELOCATOR_FR } from '../../constants/routes'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface FindStoreInputProps {
  onSearch?: () => void
  dataElementId?: string
  className?: string
}

export const FindStoreInput: React.FC<FindStoreInputProps> = ({ onSearch, className, dataElementId }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const router = useRouter()
  const { t } = useTranslation()

  const { value, selectedPlace, error, onChange, onHandleSearch, getGeoposition } = useFindLocation(inputRef.current)

  const { langCode } = useStoreIdentity()

  const navigateTo = useCallback(
    (to: string) => {
      router.push(`/${langCode}/${langCode === 'fr-ca' ? STORELOCATOR_FR : STORELOCATOR}#/result?address=${to}`)
    },
    [langCode, router]
  )

  useEffect(() => {
    if (selectedPlace?.formatted_address && !error) {
      navigateTo(selectedPlace.formatted_address)
      onSearch && onSearch()
    }
  }, [selectedPlace, error, navigateTo, onSearch])

  const onClickSearch = useCallback(() => {
    onHandleSearch()
    if (value && !selectedPlace) {
      navigateTo(value)
      onSearch && onSearch()
    }
  }, [onHandleSearch, onSearch, navigateTo, selectedPlace, value])

  return (
    <InputContainer className={className}>
      <InputWithIconContainer>
        <IconContainer onClick={getGeoposition}>
          <SVGIcon library="global" name="locator" />
        </IconContainer>
        <StyledInput
          value={value}
          onChange={onChange}
          ref={inputRef}
          error={!!error}
          placeholder={t('findStore.searchTownPlaceholder')}
        />
      </InputWithIconContainer>
      {error && <InputErrorMessage>{error}</InputErrorMessage>}
      <StyledButton onClick={onClickSearch} data-element-id={dataElementId}>
        {t('findStore.searchButton')}
      </StyledButton>
    </InputContainer>
  )
}
