import styled from '@mui/material/styles/styled'

export const WrapperCheckoutAddressSelection = styled('div', {
  name: 'CheckoutAddressSelection',
  slot: 'Wrapper',
})<{ hidden?: boolean }>(({ theme, hidden }) => ({
  display: `${hidden ? 'none' : 'block'}`,
  paddingBottom: `${theme.spacing(6)}`,
}))

export const ContentFormCheckoutAddress = styled('div', {
  name: 'CheckoutAddressSelection',
  slot: 'ContentForm',
})(({}) => ({}))

export const CheckoutAddressFormTitle = styled('div', {
  name: 'CheckoutAddressSelection',
  slot: 'FormTitle',
})(({ theme }) => ({
  paddingBottom: `${theme.spacing(11)}`,
  fontWeight: 'bold',
}))

export const CheckoutAddressFormButtonContainer = styled('div', {
  name: 'CheckoutAddressForm',
  slot: 'ButtonContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  paddingTop: `${theme.spacing(9)}`,

  '& > button': {
    flexGrow: 1,
  },

  [theme.breakpoints.up('sm')]: {
    '& > button': {
      flexGrow: 'initial',
    },
  },
}))

export const CheckoutAddressFormCloseLinkButton = styled('div', {
  name: 'CheckoutAddressForm',
  slot: 'CloseLinkButton',
})(({ theme }) => ({
  cursor: 'pointer',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'bold',
  color: theme.palette.custom.blue,
  lineHeight: theme.spacing(10),
  padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center'
  },
}))