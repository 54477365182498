import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import styled from '@mui/material/styles/styled'

// @ts-nocheck
// TODO fix later
import React from 'react'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface CustomCheckboxProps extends CheckboxProps {
  error?: boolean
}

const StyledInternalCheckbox = styled(Checkbox, {
  name: 'CheckBox',
  slot: 'CheckBox',
})(() => ({
  // marginRight: 10,
  padding: 4,
  '&:hover': {
    // backgroundColor: 'transparent',
  },
}))

const StyledCheckBoxIcon = styled('span', {
  name: 'CheckBoxIcon',
  slot: 'CheckBoxIcon',
})(({ theme }) => ({
  borderRadius: 1,
  width: 16,
  height: 16,
  backgroundColor: theme.palette.custom.white,
  borderWidth: 1,
  borderColor: theme.palette.custom.light1.grey,
  borderStyle: 'solid',
  boxShadow: theme.shadows[6],
  'input:disabled ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.custom.light2.grey,
    background: theme.palette.custom.light.grey,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.custom.light.grey,
    borderColor: theme.palette.custom.blue,
  },
  'input:focus ~ &': {
    backgroundColor: theme.palette.custom.light.grey,
    borderColor: theme.palette.custom.blue,
  },
  '.errorIcon': {
    borderRadius: 1,
    width: 16,
    height: 16,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: theme.palette.custom.red,
    borderStyle: 'solid',
    'input:disabled ~ &': {
      boxShadow: 'none',
      borderColor: theme.palette.custom.red,
      background: 'transparent',
    },
  },
}))

const StyledCheckboxCheckedIcon = styled('span', {
  name: 'CheckBoxCheckedIcon',
  slot: 'CheckBoxIcon',
})(({ theme }) => ({
  borderRadius: 1,
  width: 16,
  height: 16,
  backgroundColor: theme.palette.custom.blue,
  color: theme.palette.custom.white,
  display: 'inherit',
  borderWidth: 1,
  borderColor: theme.palette.custom.blue,
  borderStyle: 'solid',
  position: 'relative',
  'input:disabled ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.custom.light2.blue,
    color: theme.palette.custom.alto,
    background: theme.palette.custom.light1.blue,
  },
}))

export const StyledCheckbox = ({ error, ...other }: CustomCheckboxProps) => {
  return (
    <StyledInternalCheckbox
      icon={<StyledCheckBoxIcon className={error ? 'errorIcon' : ''} />}
      disableRipple
      color="default"
      checkedIcon={
        <StyledCheckboxCheckedIcon>
          <SVGIcon library="validation" name="checkmark" />
        </StyledCheckboxCheckedIcon>
      }
      indeterminateIcon={
        <StyledCheckboxCheckedIcon>
          <SVGIcon library="global" name="indeterminate-checkbox" size={8} />
        </StyledCheckboxCheckedIcon>
      }
      {...other}
    />
  )
}
