import styled from '@mui/material/styles/styled'
import { Button } from '../../../../components/UI'

export const AddToCartButton = styled(Button, {
  name: 'AddToCart',
  slot: 'Button',
  shouldForwardProp: prop => prop !== 'isStickyBar' && prop !== 'isOutOfStock',
})<{ isStickyBar?: boolean; isOutOfStock?: boolean }>(({ theme, isStickyBar, isOutOfStock }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: 48,
  width: '100%',
  whiteSpace: 'nowrap',
  fontSize: isStickyBar ? theme.typography.body2.fontSize : theme.typography.body.fontSize,
  padding: isStickyBar ? theme.spacing(2, 0) : theme.spacing(0),
  overflow: 'visible',
  color: isOutOfStock ? theme.palette.custom.white : theme.palette.custom.green,
  borderColor: isOutOfStock ? theme.palette.custom.blue : theme.palette.custom.green,
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}))

export const PdpCtasWrapper = styled('div', {
  name: 'PdpCtas',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'isStickyBar',
})<{ isStickyBar?: boolean }>(({ theme, isStickyBar }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: !isStickyBar ? '100%' : 'fit-content',
  overflow: !isStickyBar ? 'auto' : 'unset',

  button: !isStickyBar
    ? {
        margin: 0,
      }
    : {
        padding: 0,
      },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const SoldOutContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isStickyBar',
  name: 'SoldOut',
  slot: 'Container',
})<{ isStickyBar: boolean }>(({ theme, isStickyBar }) => ({
  backgroundColor: isStickyBar ? theme.palette.background.light.primary : theme.palette.custom.wildSand,
  padding: isStickyBar ? theme.spacing(1) : theme.spacing(4),
  width: '100%',

  button: {
    width: '100%',
    margin: 0,
    padding: isStickyBar ? `0 ${theme.spacing(6)}` : 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: isStickyBar ? 0 : theme.spacing(4),
    },
  },

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: isStickyBar ? `${theme.spacing(2)} ${theme.spacing(4)}` : 0,
    gap: theme.spacing(2),
    justifyContent: 'center',
    marginLeft: 0,
    marginRight: 0,
  },
}))

export const AvailabilityTitle = styled('div', {
  name: 'AvailabilityTitle',
  slot: 'Divider',
})(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  justifyContent: 'center',
  lineHeight: 1.4,
  marginBottom: theme.spacing(4),
  color: theme.palette.color.grey,
}))

export const AvailabilityWarning = styled('div', {
  name: 'AvailabilityWarning',
  slot: 'Divider',
})(({ theme }) => ({
  fontSize: 14,
  textAlign: 'center',
  lineHeight: 1.42,
  color: theme.palette.color.grey,
  gap: theme.spacing(4),
  marginBottom: theme.spacing(1),
}))

export const ComingSoonWarning = styled('div', {
  shouldForwardProp: prop => prop !== 'isStickyBar',
  name: 'CtaPdp',
  slot: 'TextContainer',
})<{ isStickyBar: boolean }>(({ theme, isStickyBar }) => ({
  fontSize: isStickyBar ? 14 : 20,
  textAlign: 'center',
  fontWeight: 600,
  lineHeight: isStickyBar ? 1 : 1.4,
  marginTop: isStickyBar ? theme.spacing(1) : theme.spacing(0),
  marginBottom: isStickyBar ? theme.spacing(1) : theme.spacing(3),
  color: theme.palette.color.grey,

  [theme.breakpoints.up('sm')]: {
    fontSize: isStickyBar ? 16 : 20,
  },
}))

export const StyledButtonsContainer = styled('div', {
  name: 'StyledButtons',
  slot: 'Container',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'center',
  button: {
    textWrap: 'wrap',
  },
}))

export const StyledRelativeBtn = styled(Button, {
  name: 'StyledButtons',
  slot: 'RelativeBtn',
  shouldForwardProp: prop => prop !== 'variant' && prop !== 'customBg' && prop !== 'customBorderColor',
})<{ customBg?: boolean; customBorderColor?: boolean }>(({ theme, variant, customBg, customBorderColor }) => {
  const backgroundColor = customBg
    ? theme?.palette.custom.light1.green
    : variant === 'secondary'
      ? theme.palette.custom.white
      : theme.palette.custom.blue

  return {
    position: 'relative',
    height: 48,
    whiteSpace: 'nowrap',
    overflow: 'visible',
    color: variant === 'primary' ? theme.palette.custom.black : theme.palette.custom.white,
    backgroundColor,
    borderColor: customBorderColor ? theme?.palette.custom.green : theme.palette.custom.black,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',

    '&:disabled': {
      backgroundColor,
      opacity: 0.5,
    },

    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  }
})

export const NeedPrescriptionText = styled('div', {
  name: 'NeedPrescription',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.43,
  textAlign: 'center',
  color: theme.palette.color.grey,
  paddingTop: theme.spacing(3),
}))

export const CTAContainer = styled('div')({
  overflow: 'auto',
})

export const LineSeparator = styled('div', {
  name: 'LineSeparator',
})(({ theme }) => ({
  height: theme.spacing(0.25),
  backgroundColor: theme.palette.background.light.tertiary,
  marginBottom: theme.spacing(2),
}))

export const BrowseSimilarItemsContainer = styled('div', {
  name: 'BrowseSimilarItemsContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  fontSize: theme.typography.body2.fontSize,
  a: {
    color: theme.palette.custom.black,
    fontWeight: 700,
    textDecoration: 'underline',
  },
  p: {
    margin: 0,
  },
}))
