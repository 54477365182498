import React from 'react'
import { useTranslation } from 'next-i18next'
import { ModalDialog } from '../../../../components/UI/ModalDialogV2'
import { Button } from '../../../../components/UI'

export interface UnsubscribeModalProps {
  open: boolean,
  handleConfirm: () => void,
  handleClose: () => void
}

export const UnsubscribeModal: React.FC<UnsubscribeModalProps> = ({open, handleClose, handleConfirm}) => {
  const {t} = useTranslation()

  return (
      <ModalDialog
        open={open}
        title={t('SubscriptionSection.UnsubscribeModal.Message')}
        onClose={handleClose}
        footer={
          <>
            <Button onClick={handleConfirm}>{t('SubscriptionSection.UnsubscribeModal.YesLabel')}</Button>
            <Button onClick={handleClose} variant='secondary'>{t('SubscriptionSection.UnsubscribeModal.NoLabel')}</Button>
          </>
        }
      >
      </ModalDialog>
    )
}
