import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'next-i18next'
import { ModalDialog } from '../../../../../components/UI/ModalDialogV2'
import { GridContainer, GridItem, StyledTypography } from '../../../../../components/UI'
import {
  StyledPaypalIcon,
} from './PaypalTooltipModal.style'
import Icon1 from '../assets/paypal-popup-icon-1.png'
import Icon2 from '../assets/paypal-popup-icon-2.png'
import Icon3 from '../assets/paypal-popup-icon-3.png'

export interface SectionContent {
  iconUrl: string
  title: string
  content: string
}

export interface PaypalTooltipModalProps {
  open: boolean,
  onClose: MouseEventHandler
}

export const PaypalTooltipModal: React.FC<PaypalTooltipModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  
  const iconsUrl = [Icon1, Icon2, Icon3]
  const contentItems = [1, 2, 3].map(value => {
    return {
      iconUrl: iconsUrl[value-1].toString(),
      title: t(`PaymentMethodSelection.Paypal.Section${value}.Title`),
      content: t(`PaymentMethodSelection.Paypal.Section${value}.Content`),
    } as SectionContent
  })
  
  return (
    <ModalDialog
      title={t('PaymentMethodSelection.Paypal.Title')}
      open={open}
      onClose={onClose}
    >
      <GridContainer sx={{ rowGap: 8 }}>
        <GridItem xs={12} sx={{ textAlign: 'center' }}>
          <StyledTypography>{t('PaymentMethodSelection.Paypal.Subtitle')}</StyledTypography>
        </GridItem>

        {contentItems.map(item => {
          return (
            <GridItem xs={12} key={item.title}>
              <GridContainer sx={{ rowGap: 1 }}>
                <GridItem xs={12} sm={1}>
                  <StyledPaypalIcon src={item.iconUrl} />
                </GridItem>
                <GridItem xs={12} sm={11}>
                  <StyledTypography sx={{ fontWeight: 700 }}>{item.title}</StyledTypography>
                  <StyledTypography>{item.content}</StyledTypography>
                </GridItem>
              </GridContainer>
            </GridItem>
          )
        })
        }
      </GridContainer>
    </ModalDialog>
  )
}
