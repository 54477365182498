import React from 'react'
import { useTranslation } from 'next-i18next'
import { Box, Stack } from '@mui/material'
import { useSite } from '../../../../../foundation/hooks/useSite'
import { CommerceEnvironment } from '../../../../../constants/common'
import { OrderItem } from '@typesApp/order'
import { isInsuranceEligible } from '../../../../../utils/rx'
import { StyledTooltip, StyledTypography } from '../../../../../components/UI'
import useBreakpoints from '../../../../../hooks/useBreakpoints'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface InsuranceEligibleCalloutProps {
  orderItem: OrderItem
}

export const InsuranceEligibleCallout: React.FC<InsuranceEligibleCalloutProps> = props => {
  const { orderItem } = props
  const { mySite } = useSite()
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const commerceLocale = mySite.locale
  const isDirectBillingEnabled = !!CommerceEnvironment.directInsuranceBilling[commerceLocale]?.enabled || false
  if (!isDirectBillingEnabled) {
    return null
  }

  if (!isInsuranceEligible(orderItem)) {
    return null
  }

  return (
    <Stack direction={'row'} justifyContent={'flex-end'}>
      <Stack
        direction={'row'}
        columnGap={2}
        alignItems={'center'}
        sx={{
          borderRadius: '4px 4px 0 0',
          padding: '12px 16px',
          background: theme => theme.palette.custom.light.purple,
        }}
      >
        <StyledTypography
          fontWeight="450"
          sx={{ fontSize: theme => (isMobile ? theme.typography.body2.fontSize : undefined) }}
          dangerouslySetInnerHTML={{ __html: t('CartRecap.Labels.InsuranceEligibleCallout.Title') }}
        />

        <StyledTooltip
          contentVariant={{
            type: 'textonly',
            body: t('CartRecap.Labels.InsuranceEligibleCallout.Tooltip'),
          }}
          disablePortal
          colorVariant={'darkgray'}
          placement={'top'}
        >
          <Box sx={{ height: 20 }}>
            <SVGIcon library="global" name="question-mark" />
          </Box>
        </StyledTooltip>
      </Stack>
    </Stack>
  )
}
