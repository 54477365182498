import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import {
  getFrameMaterialFacet,
  getFrontColor,
  getIsRoxable,
  getLensesColor,
  getLensesTreatment,
  getModelCode,
  getModelName,
  getSize,
} from '../../../../../utils/productAttributes'
import ProductImage from '../../../../../components/ProductImage/ProductImage'
import { StyledAnchor, IconButton } from '../../../../../components/UI'
import { Typography } from '@mui/material'
import { Button } from '../../../../../components/UI'
import { Container, Content, Description, Footer, Header } from './styled-components'
import theme from '../../../../../themes'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface IProps {
  onClose: () => void
  product?: any
}

const StyledModelName = styled(Typography)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.light};
`

const StyledPrescriptionLenses = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  font-size: ${({ theme }) => theme.spacing(0.75)}px;
  color: ${({ theme }) => theme.palette.text.light};
`

const StyledItemTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.spacing(0.75)}px;
  color: ${({ theme }) => theme.palette.text.light};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`

const StyledEditSize = styled(StyledAnchor)`
  color: ${({ theme }) => theme.palette.text.light};
  font-size: ${({ theme }) => theme.spacing(0.75)}px;
  text-decoration: underline;
  text-transform: uppercase;
  text-align: end;
`

const StyledBackToCartButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(1.25)}px;
`

const StyledInfoContainer = styled.div`
  display: grid;
  color: ${({ theme }) => theme.palette.text.light};
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(4, auto);
  grid-column-gap: 10px;
  grid-row-gap: 0.5rem;

  & > :nth-of-type(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  & > :nth-of-type(2) {
    grid-area: 1 / 2 / 2 / 4;
  }
  & > :nth-of-type(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  & > :nth-of-type(4) {
    grid-area: 2 / 2 / 3 / 4;
  }
  & > :nth-of-type(5) {
    grid-area: 3 / 1 / 4 / 2;
  }
  & > :nth-of-type(6) {
    grid-area: 3 / 2 / 4 / 3;
  }
  & > :nth-of-type(7) {
    grid-area: 3 / 3 / 4 / 4;
  }
  & > :nth-of-type(8) {
    grid-area: 4 / 1 / 5 / 2;
  }
  & > :nth-of-type(9) {
    grid-area: 4 / 2 / 5 / 3;
  }
  & > :nth-of-type(10) {
    grid-area: 4 / 3 / 5 / 4;
  }
`

const ProductDetailsDrawerContent: React.FC<IProps> = ({ onClose, product }) => {
  const { t } = useTranslation()
  const { isRXEnabled } = useStoreIdentity()

  if (!product) {
    return null
  }

  const { attachments } = product
  const productName = getModelName(product)

  const productBaseModelLensRoxable = product?.roxableAttributes

  const { href } = product.seo || { href: '' }
  const ADD_LENS_HREF = `${href}?addLens=1`
  const EDIT_LENS_HREF = `${href}?editLens=1`
  const EDIT_SIZE_HREF = `${href}?editSize=1`

  const IS_RX_PRODUCT = isRXEnabled && productBaseModelLensRoxable

  return (
    <Container>
      <Header>
        <IconButton onClick={onClose}>
          <SVGIcon library="close" name="close" color="black" style={{ marginTop: '16px' }} />
        </IconButton>
      </Header>
      <Content>
        <ProductImage
          attachments={attachments}
          usage="PDP"
          width={400}
          backgroundColor={theme.palette.background.light.primary}
        />
        <Description>
          <Box display="flex" alignItems="space-between" justifyContent="space-between">
            <StyledModelName>{productName}</StyledModelName>
          </Box>
          <StyledPrescriptionLenses>
            {IS_RX_PRODUCT && t('CartRecap.Labels.PrescriptionLenses')}
          </StyledPrescriptionLenses>
          <Box height="1px" bgcolor="black" my={0.5}></Box>
          <StyledInfoContainer>
            <StyledItemTitle>{t('CartRecap.Labels.ModelCode')}: </StyledItemTitle>
            <StyledItemTitle>{getModelCode(product)}</StyledItemTitle>
            <StyledItemTitle>{t('CartRecap.Labels.Frame')}: </StyledItemTitle>
            <StyledItemTitle>
              {getFrontColor(product)} / {getFrameMaterialFacet(product)}
            </StyledItemTitle>
            <StyledItemTitle>{t('CartRecap.Labels.Lenses')}: </StyledItemTitle>
            <StyledItemTitle>
              {IS_RX_PRODUCT ? (
                <>
                  <div>{productBaseModelLensRoxable.lensColor}</div>
                  <div>{productBaseModelLensRoxable.correctionType}</div>
                </>
              ) : (
                `${getLensesColor(product)} / ${getLensesTreatment(product)}`
              )}
            </StyledItemTitle>
            <StyledAnchor style={{ textAlign: 'end' }} href={product.parentCatalogGroupID}>
              {IS_RX_PRODUCT && (
                <StyledEditSize
                  href={EDIT_LENS_HREF}
                  data-element-id="X_X_Prods_EditLens"
                  data-description={`${productName}_${product?.partNumber}`}
                >
                  {t('CartRecap.Actions.EditLens')}
                </StyledEditSize>
              )}
              {!IS_RX_PRODUCT && getIsRoxable(product) ? (
                <StyledEditSize
                  href={ADD_LENS_HREF}
                  data-element-id="X_X_Prods_AddLens"
                  data-description={`${productName}_${product?.partNumber}`}
                >
                  {t('CartRecap.Actions.AddLens')}
                </StyledEditSize>
              ) : null}
            </StyledAnchor>
            <StyledItemTitle>{t('CartRecap.Labels.Size')}: </StyledItemTitle>
            <StyledItemTitle>{getSize(product)}</StyledItemTitle>
            <StyledEditSize href={EDIT_SIZE_HREF}>{t('CartRecap.Actions.EditSize')}</StyledEditSize>
          </StyledInfoContainer>
          <StyledBackToCartButton onClick={onClose} variant="secondary">
            {t('CartRecap.ProductDetailsDrawer.BackToCart')}
          </StyledBackToCartButton>
        </Description>
      </Content>
      <Footer></Footer>
    </Container>
  )
}

export default ProductDetailsDrawerContent
