import styled from '@mui/material/styles/styled'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'

export const OrderDetailsWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.custom.light2.grey}`,
  backgroundColor: theme.palette.background.light.primary,
  rowGap: theme.spacing(4),
  marginBottom: theme.spacing(6),
  maxWidth: '830px',

  [getDesktopThemeBreakpoint(theme)]: {
    padding: theme.spacing(6),
    rowGap: theme.spacing(6),
  },
}))
