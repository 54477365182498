import React from 'react'
import { useTranslation } from 'next-i18next'
import {
  ShippingAddressNotEligible,
  ShippingNotEligibleWarningBody,
  ShippingNotEligibleWarningHeader,
} from './LookOutNotice.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const LookOutNotice: React.FC<{ page: string }> = ({ page }) => {
  const { t } = useTranslation()
  return (
    <ShippingAddressNotEligible>
      <span>
        <SVGIcon library="validation" name="alert" />
      </span>
      <ShippingNotEligibleWarningHeader>{t('Shipping.Labels.LookOut')}</ShippingNotEligibleWarningHeader>
      <ShippingNotEligibleWarningBody>
        {page === 'cart' ? t('Shipping.Labels.PostalCodeNotEligible') : t('Shipping.Labels.AddressNotEligible')}
      </ShippingNotEligibleWarningBody>
    </ShippingAddressNotEligible>
  )
}
