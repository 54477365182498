import { StyledButton } from '@components/UI/Button/Button'
import styled from '@mui/material/styles/styled'

export const StyledPaymentCTA = styled(StyledButton, {
  name: 'Payment',
  slot: 'CTA',
})(({ theme }) => ({
  backgroundColor: theme.palette.custom.light1.green,
  borderColor: theme.palette.custom.green,
}))
