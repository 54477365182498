import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import { COUNTRY_MAP } from '../../constants/common'
import { SuggesterInputFormData } from '../../types/inputFields'
import { NEW_ZEALAND_PROVINCES_MAP } from '../../utils/countryUtil'

// more info at https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
const GOOGLE_ADDRESS_TYPE = {
  ADMINISTRATIVE_AREA_LEVEL_1: 'administrative_area_level_1',
  ADMINISTRATIVE_AREA_LEVEL_3: 'administrative_area_level_3',
  STREET_NUMBER: 'street_number',
  ROUTE: 'route',
  PREMISE: 'premise',
  SUBPREMISE: 'subpremise',
  COUNTRY: 'country',
  POLITICAL: 'political',
  STREEET_ADDRESS: 'street_address',
  POSTAL_CODE: 'postal_code',
  POSTAL_TOWN: 'postal_town',
  LOCALITY: 'locality',
  SUBLOCALITY: 'sublocality',
  SUBLOCALITY_LEVEL_1: 'sublocality_level_1',
}

const useAddressSuggestion = (country: string, addressFields?: string[]) => {
  let geocoder: google.maps.Geocoder = window.google && window.google.maps && new window.google.maps.Geocoder()
  const {
    init,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    cache: false,
    requestOptions: {
      componentRestrictions: {
        country: country,
      },
      types: ['address'],
    },
    debounce: 300,
  })

  const getAutoCompleteAddressData = (address: string) => {
    return (
      geocoder &&
      geocoder
        .geocode({ address }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            return results
          } else {
            return new Error(status)
          }
        })
        .then(res => {
          return res.results && res.results.length > 0
            ? () => {
                const addressComponents = ([] as google.maps.GeocoderAddressComponent[]).concat(
                  ...res.results.map<google.maps.GeocoderAddressComponent[]>(item => item.address_components)
                )
                return formatAddressData(addressComponents)
              }
            : undefined
        })
    )
  }
  /**
   *
   * @param data
   * the address form data
   * @returns address form data fields compared to those in store conf
   */
  const filterAddressDataFields = (data: SuggesterInputFormData): SuggesterInputFormData => {
    const filteredData: SuggesterInputFormData = {}
    Object.keys(data).map(fieldName => {
      if (addressFields?.includes(fieldName)) {
        filteredData[fieldName] = data[fieldName]
      }
    })
    return filteredData
  }

  const formatAddressData = (details: google.maps.GeocoderAddressComponent[]) => {
    const addressData: SuggesterInputFormData = {
      address1: setAddressByGeocode(details) || '',
      addressLine1: setAddressByGeocode(details) || '',
      zipCode: setZipByGeocode(details) || '',
      city: setCityByGeocode(details) || '',
      country: setCountryByGeocode(details) || '',
      state: setStateByGeocode(details) || '',
    }

    return filterAddressDataFields(addressData)
  }

  const getCityAndStateByZipcode = async (zip: string, zipCountry: string) => {
    return zip && zip.length > 3
      ? geocoder &&
          (await geocoder
            .geocode(
              {
                componentRestrictions: {
                  country: zipCountry,
                  postalCode: zip,
                },
              },
              (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                  return results
                } else {
                  return new Error(status)
                }
              }
            )
            .then(res => {
              return !!res.results && res.results.length > 0
                ? filterAddressDataFields({
                    state: setStateByGeocode(res.results[0].address_components),
                    city: setCityByGeocode(res.results[0].address_components),
                  })
                : undefined
            }))
      : undefined
  }

  const setAddressByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const subPremise = addressComponents.find(
      comp => comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.SUBPREMISE) !== -1
    )
    const streetNumber = addressComponents.find(
      comp => comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.STREET_NUMBER) !== -1
    )
    const route = addressComponents.find(
      comp => comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.ROUTE) !== -1
    )
    const premise = addressComponents.find(
      comp => comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.PREMISE) !== -1
    )
    let address
    if (subPremise && streetNumber && route) {
      address = `${subPremise.long_name} ${streetNumber.long_name} ${route.long_name}`
    } else if (streetNumber && route) {
      address = `${streetNumber.long_name} ${route.long_name}`
    } else if (route) {
      address = route.long_name
    } else if (premise) {
      address = premise.long_name
    }

    if (address) {
      return address
    }
  }

  const setZipByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const zip = addressComponents.find(
      comp => comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.POSTAL_CODE) !== -1
    )

    return !!zip ? zip.short_name : undefined
  }

  const setCityByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const cityFallback1 = addressComponents.find(
      comp => comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.POSTAL_TOWN) !== -1 // UK or GB
    )
    const cityFallback2 = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.LOCALITY) !== -1 &&
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.POLITICAL) !== -1
    ) // DE

    const cityFallback3 = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.SUBLOCALITY) !== -1 &&
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.POLITICAL) !== -1 &&
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.SUBLOCALITY_LEVEL_1) !== -1
    )

    const cityFallback4 = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.SUBLOCALITY) !== -1 &&
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.POLITICAL) !== -1
    )

    const cityFallback5 = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.ADMINISTRATIVE_AREA_LEVEL_3) !== -1 &&
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.POLITICAL) !== -1
    )

    const city = cityFallback1 || cityFallback2 || cityFallback3 || cityFallback4 || cityFallback5

    return !!city ? city.short_name : undefined
  }

  const setStateByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    let state: google.maps.GeocoderAddressComponent | undefined = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.ADMINISTRATIVE_AREA_LEVEL_1) !== -1 &&
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.POLITICAL) !== -1
    )

    if (!!state) {
      if (country === COUNTRY_MAP.NEW_ZEALAND) {
        return NEW_ZEALAND_PROVINCES_MAP.has(state.short_name)
          ? NEW_ZEALAND_PROVINCES_MAP.get(state.short_name)
          : undefined
      }
      return state.short_name
    }
    return undefined
  }

  const setCountryByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const country = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.COUNTRY) !== -1 &&
        comp.types.findIndex(type => type === GOOGLE_ADDRESS_TYPE.POLITICAL) !== -1
    )

    return !!country ? country.short_name : undefined
  }

  return {
    setSuggestionValue: setValue,
    getAutoCompleteAddressData,
    suggestions: { status, data },
    clearSuggestions,
    getDetails,
    formatAddressData,
    getCityAndStateByZipcode,
    init,
  }
}
export default useAddressSuggestion
