import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { generateAccountPagePath } from '../Account/Account'
import { sendMyAccountEvent } from '../../foundation/analytics/tealium/lib'
import { ClaimsWrapper } from './Claims.style'
import { isCanadaStore } from '../../utils/storeUtil'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import { MyClaims } from '../../components/DirectBilling'
import { CTAItem } from '@components/DirectBilling/types'
import { ACCOUNT_CHILDREN } from '../../constants/routes'
import { useRouter } from 'next/router'

/**
 * `@name Claims`
 *
 * Component that is rendered at `/account/my-claims` route. Routing is managed by `Account` component.
 */
export const Claims: React.FC = () => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const { mySite } = useSite()
  const router = useRouter()

  const analyticsData = useAnalyticsData('accountClaims')

  const shoppingActions = useMemo<CTAItem[]>(() => {
    return ['Glasses', 'Sunglasses', 'Contacts'].map(productType => ({
      ctaLabel: t(`Common.Shop.${productType}.Cta`),
      onClick: () => router.push(`/${langCode}/${t(`Common.Shop.${productType}.Url`)}`),
    }))
  }, [])

  const onOrderClick = (orderNumber: string) => {
    router.push(generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY, { orderId: orderNumber }))
  }

  useEffect(() => {
    if (!isCanadaStore(mySite.locale)) {
      router.push('/account/dashboard')
      return
    }

    sendMyAccountEvent({
      common: analyticsData,
      Page_Section2: 'MyClaims',
    })
  }, [])

  return (
    <ClaimsWrapper>
      <MyClaims
        shoppingActions={shoppingActions}
        onOrderClick={onOrderClick}
        translations={{
          unavailableTitle: t('DirectBilling.UnavailableTitle'),
          unavailableMessage: t('DirectBilling.UnavailableMessage'),
        }}
      />
    </ClaimsWrapper>
  )
}

export default Claims
