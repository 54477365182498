import React, { useEffect } from 'react'
import { SearchContainer, SearchTitle, SearchTitleContainer, SearchSubTitle } from './FindStore.style'
import { useTranslation } from 'next-i18next'
import { FindStoreInput } from './FindStoreInput'
import { ACCOUNT_CHILDREN } from '../../constants/routes'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useGoogleSuggestions } from '../../hooks/useGoogleSuggestions'
import { StyledLoader } from '../UI'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { sendMyAccountEvent } from '@foundation/analytics/tealium/lib'

export const FindStore: React.FC = () => {
  const { t } = useTranslation()

  const storeCountry = useStoreIdentity().country.toUpperCase()

  const { googleAPISuggestionEnabled } = useGoogleSuggestions({
    addressType: '',
    country: storeCountry,
    page: ACCOUNT_CHILDREN.STORES,
    state: '',
  })

  const { ...analyticsDataForMyAccount } = useAnalyticsData(ANALYTICS_PAGE_TYPE.PROFILE)

  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsDataForMyAccount,
      Page_Section2: 'Profile',
      Page_Section3: 'FindStores',
    })
  }, [])

  return (
    <SearchContainer>
      <SearchTitleContainer>
        <SearchTitle>{t('findStore.title')}</SearchTitle>
        <SearchSubTitle>{t('findStore.subTitle')}</SearchSubTitle>
        {googleAPISuggestionEnabled && <FindStoreInput />}
        {!googleAPISuggestionEnabled && <StyledLoader size={40} />}
      </SearchTitleContainer>
    </SearchContainer>
  )
}
