import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isClAccessoriesProduct, isContactLensesProduct, isSunProduct } from '../../../../utils/product'
import { OrderItem, OrderRecapItemProps, OrderUnavailableItem } from '@typesApp/order'
import CartRecapCLAccessoriesItem from './components/CartRecapCLAccessoriesItem'
import CartRecapClItem from './components/CartRecapClItem'
import { CartRecapContainer } from './CartRecap.style'
import CartRecapItem from './components/CartRecapItem'
import CartRecapRxItem from './components/CartRecapRxItem'
import CartRecapClItemUnavailable from './components/CartRecapClItemUnavailable'
import CartRecapCLAccessoriesItemUnavailable from './components/CartRecapCLAccessoriesItemUnavailable'
import ProductDetailsDrawerContent from './ProductDetailsDrawer/ProductDetailsDrawerContent'
import { SwipeableDrawer } from '@mui/material'
import { catentriesSelector } from '../../../../features/order/selector'
import { isRxFrame } from '../../../../utils/isRxOrder'
import { useProductSoldOutState } from '@views/ProductDetails/useProductSoldOutState'
import { SubscriptionsErrorBox } from '@views/Subscription/cart/components/ErrorBox'
import { isReOrderSummary } from '@utils/routeUtils'
import { usePathname } from 'next/navigation'
import { getUnavailableContactLensAccessories, getUnavailableContactLensesBundled } from '@features/reorder/utils'
import { IProductLiveStockResultItem } from '@typesApp/product'

export interface CartRecapProps {
  cartItems: OrderItem[] | null
  unavailableItems?: OrderUnavailableItem[]
  onRemove: (item: OrderItem) => void
  onUpdate?: (quantity: string, orderItemId: string) => void
  cartUpdating?: boolean
  outOfStockCLAccessories?: string[]
  liveStockCLAccessories?: IProductLiveStockResultItem[] | undefined
}

/**
 * Order item table component
 * displays order item table with item info, inventory status, quantity and actions
 * allows for ready-only mode with no edits/actions
 * @param props
 */
const CartRecap: React.FC<CartRecapProps> = ({
  cartItems,
  liveStockCLAccessories,
  outOfStockCLAccessories,
  unavailableItems,
  onRemove,
  onUpdate,
}: CartRecapProps) => {
  const unavailableContactLensesBundled = getUnavailableContactLensesBundled(unavailableItems)
  const unavailableContactLensAccessories = getUnavailableContactLensAccessories(unavailableItems)
  const [productBase, setProductBase] = useState<OrderItem>()
  const catentries = useSelector(catentriesSelector)
  const pathname = usePathname()

  const attachments =
    productBase && catentries?.[productBase.productId] ? catentries[productBase.productId].attachments : []

  const PDPImagesArray = attachments
    .filter(d => d.usage === 'PDP')
    .sort((a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10))
    .shift()

  const onClose = () => {
    setProductBase(undefined)
  }

  const product = catentries?.[productBase?.productId!]
  const currentProductPartNumber = product?.partNumber || product?.items?.[0]?.partNumber || ''
  const { soldOutStatus } = useProductSoldOutState({
    product,
    currentProductPartNumber,
  })

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={productBase !== undefined}
        className="product-details-menu"
        onClose={onClose}
        onOpen={() => {}}
      >
        <ProductDetailsDrawerContent
          product={
            productBase
              ? {
                  ...productBase,
                  attachments: PDPImagesArray ? [PDPImagesArray] : [],
                }
              : undefined
          }
          onClose={onClose}
        />
      </SwipeableDrawer>
      {!isReOrderSummary(pathname) && <SubscriptionsErrorBox />}
      <CartRecapContainer>
        {cartItems?.map(cartItem => {
          let DynamicCartItem: React.FC<OrderRecapItemProps> | null = null
          switch (true) {
            case isContactLensesProduct(cartItem):
              DynamicCartItem = CartRecapClItem
              break
            case isClAccessoriesProduct(cartItem):
              DynamicCartItem = CartRecapCLAccessoriesItem
              break
            case isSunProduct(cartItem) && !isRxFrame(cartItem['orderItemExtendAttribute']):
              DynamicCartItem = CartRecapItem
              break
            case isRxFrame(cartItem['orderItemExtendAttribute']):
              DynamicCartItem = CartRecapRxItem
              break
            default:
              DynamicCartItem = CartRecapItem
              break
          }

          return (
            <DynamicCartItem
              key={cartItem.orderItemId}
              orderItem={cartItem}
              soldOutStatus={soldOutStatus}
              onDelete={() => onRemove(cartItem)}
              onItemUpdated={(quantity, orderItemId) => onUpdate && onUpdate(quantity, orderItemId)}
              outOfStockCLAccessories={outOfStockCLAccessories}
              liveStockCLAccessories={liveStockCLAccessories}
            />
          )
        })}
        {unavailableContactLensesBundled?.map(unavailableItem => {
          return (
            <CartRecapClItemUnavailable
              key={unavailableItem.main.id}
              unavailableItem={unavailableItem.main}
              unavailableSiblingItem={unavailableItem.sibling}
            />
          )
        })}
        {unavailableContactLensAccessories?.map(unavailableItem => {
          return <CartRecapCLAccessoriesItemUnavailable key={unavailableItem.id} unavailableItem={unavailableItem} />
        })}
      </CartRecapContainer>
    </>
  )
}

export { CartRecap }
