import styled from '@mui/material/styles/styled'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

export const ContactLensDetailsWrapper = styled(Accordion, {
  name: 'OrderRecapClItem',
  slot: 'ContactLensDetailsWrapper',
})(({ theme }) => ({
  boxShadow: 'unset',
  overflow: 'scroll',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'unset',
  },
  '>div': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    '> div:last-of-type': {
      span: {
        marginTop: theme.spacing(2),
      },
    },
  },
}))

export const ContactLensDetailsTitle = styled(AccordionSummary, {
  name: 'OrderRecapClItem',
  slot: 'ContactLensDetailsTitle ',
  shouldForwardProp: props => props !== 'isCheckoutPage',
})<{ isCheckoutPage?: boolean }>(({ theme, isCheckoutPage }) => ({
  minHeight: 'unset',
  padding: '0',
  '&: > *': {
    margin: '0',
  },
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '& > .MuiAccordionSummary-content': {
    flex: '0 1 auto',
    margin: '0',
    fontSize: isCheckoutPage ? theme.typography.body2.fontSize : theme.typography.body1.fontSize,
    color: theme.palette.text.dark.secondary,
    fontWeight: '600',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  '& > .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: theme.spacing(),
  },
}))

export const ContactLensDetailsContent = styled(AccordionDetails, {
  name: 'OrderRecapClItem',
  slot: 'ContactLensDetailsContent',
})(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(3)} 0 0 0`,
  display: 'table',
  tableLayout: 'fixed',
  overflow: 'hidden',
  ' > div': {
    overflow: 'hidden',
  },
  'li, span': {
    display: 'flex',
    justifyContent: 'center',
    ':first-of-type': {
      justifyContent: 'left',
    },
  },
}))

export const ContactLensAttributesList = styled('ul', {
  name: 'OrderRecapClItem',
  slot: 'AttributesList',
})(({ theme }) => ({
  justifyContent: 'space-around',
  margin: '0',
  padding: '0',
  listStyleType: 'none',
  display: 'flex',
  minWidth: '100%',
  marginBottom: theme.spacing(2),
}))

export const ContactLensAttributesListItem = styled('li', {
  name: 'OrderRecapClItem',
  slot: 'AttributesList',
  shouldForwardProp: props => props !== 'isCompact',
})<{ isCompact?: boolean }>(({ theme, isCompact = false }) => ({
  fontSize: isCompact ? theme.typography.subtitle1.fontSize : theme.typography.body1.fontSize,
  flex: '1 1 auto',
  maxWidth: '100%',
  width: '100%',
  display: 'table-cell',
  textAlign: 'left',
  paddingRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}))

export const ContactLensAttributesListItemTitle = styled('span', {
  name: 'OrderRecapClItem',
  slot: 'AttributesListItemTitle',
  shouldForwardProp: props => props !== 'isCompact',
})<{ isCompact?: boolean }>(({ theme, isCompact = false }) => ({
  fontSize: isCompact ? theme.typography.subtitle1.fontSize : theme.typography.body1.fontSize,
  fontWeight: 700,
  textTransform: 'uppercase',
  color: 'inherit',
  display: 'table-cell',
  textAlign: 'left',
  paddingRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    textTransform: 'capitalize',
    minWidth: '33%',
  },
}))

export const ContactLensQuantitySelectContainer = styled('div', {
  name: 'OrderRecapClItem',
  slot: 'QuantitySelectContainer',
})(() => ({
  display: 'flex',
}))

export const ContactLensQuantitySelectWrapper = styled('div', {
  name: 'OrderRecapClItem',
  slot: 'ContactLensQuantitySelectWrapper',
})(({ theme }) => ({
  flex: '0 1 100px',
  marginRight: theme.spacing(6),
}))

export const ContactLensQuantitySelectLabel = styled('span', {
  name: 'OrderRecapClItem',
  slot: 'ContactLensQuantitySelectWrapper',
})(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.body1.fontSize,
  color: 'inherit',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  textTransform: 'uppercase',
}))

export const ContactLensQuantityRecap = styled('span', {
  name: 'OrderRecapClItem',
  slot: 'ContactLensQuantityRecap',
})(() => ({
  fontSize: '1rem',
  color: 'inherit',
  fontWeight: 'normal',
  '&: before': {
    content: '"( "',
  },
  '&: after': {
    content: '" )"',
  },
}))
