import { shipFsaLeadTimeSelector, shipInfosSelector } from '@features/order/selector'
import { useSite } from '@foundation/hooks/useSite'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { ShippingMethods } from '@typesApp/cart'
import { DedicatedShippingMode, OrderItem } from '@typesApp/order'
import { getEstimatedDeliveryDateForZipcode } from '@utils/getEstimatedDeliveryDate'
import { getShippingCode } from '@utils/order'
import { useSelector } from 'react-redux'
import { CheckoutContext } from '../Checkout'
import { useContext } from 'react'

export const useEstimatedDeliveryDate = (orderItem: OrderItem, useFallBackValue?: boolean) => {
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const shipInfos = useSelector(shipInfosSelector)
  const { shippingZipCode } = useContext(CheckoutContext)

  const shippingCode: ShippingMethods = getShippingCode(orderItem)
  const productSlaList = shipInfos?.find(s => s.shipModeCode === shippingCode) as DedicatedShippingMode
  const productSlaStart = parseInt(productSlaList?.minLeadTime || '0')
  const productSlaEnd = parseInt(productSlaList?.maxLeadTime || '0')
  const fsaLocaleLeadTime = useSelector(shipFsaLeadTimeSelector)
  const shipFsaLeadTimeMap = (fsaLocaleLeadTime && fsaLocaleLeadTime[mySite.country]) || {}
  const zipCode = shippingZipCode || orderItem?.zipCode

  let estimatedDeliveryDate = getEstimatedDeliveryDateForZipcode(
    { productSlaStart, productSlaEnd, shipFsaLeadTimeMap, shippingZipCode: zipCode },
    langCode,
    useFallBackValue
  )

  return estimatedDeliveryDate
}
