import styled from '@mui/material/styles/styled'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'

export const OrderItemContainer = styled('div', {
  name: 'OrderItem',
  slot: 'Container'
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(5),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
  },
}))

export const OrderItemTitleWrapper = styled('div', {
  name: 'OrderItem',
  slot: 'TitleWrapper'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    justifyContent: 'space-between',
    columnGap: theme.spacing(5),
  },

  [theme.breakpoints.up('lg')]: {
    columnGap: theme.spacing(9),
  }
}))

export const OrderItemTitle = styled('div', {
  name: 'OrderItem',
  slot: 'Title'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(5),
    rowGap: theme.spacing(4),
  },

  [theme.breakpoints.up('lg')]: {
    columnGap: theme.spacing(9),
  },
}))

export const StyledOrderNumber = styled('span', {
  name: 'OrderItem',
  slot: 'OrderNumber'
})(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: 700,
  lineHeight: 1.6,
  color: theme.palette.text.dark.primary,
}))

export const StyledCTAContainer = styled('div', {
  name: 'OrderItem',
  slot: 'CTAContainer'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: theme.spacing(4),
  rowGap: theme.spacing(2),

  [theme.breakpoints.between(1024, 'lg')]: {
    '.cta': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    }
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    rowGap: theme.spacing(4),
    width: '100%',
  },

  '.cta': {
    margin: 0,
    whiteSpace: 'nowrap',
  }
}))

export const StyledBadge = styled(Badge, {
  name: 'OrderItem',
  slot: 'Badge'
})<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: theme.spacing(4),
    fontWeight: 600,
    lineHeight: 1.5,
    borderRadius: theme.spacing(4),
    color: theme.palette.custom.blue,
    backgroundColor: theme.palette.custom.light.blue,
    top: '15%',
    right: '6%',
  },
}))

export const OrderItemImage = styled('img', {
  name: 'OrderItem',
  slot: 'Image'
})(() => ({
  width: 'auto',
}))

export const StyledSeparator = styled('hr', {
  name: 'OrderItem',
  slot: 'Separator'
})(({ theme }) => ({
  width: '100%',
  height: 1,
  backgroundColor: theme.palette.background.light.tertiary,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderStyle: 'none',
}))

export const ProductImageWrapper = styled('div', {
  name: 'OrderItem',
  slot: 'ProductImageWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  marginBottom: 0,
  '& img': {
    maxWidth: 100,
    minWidth: 100,
    minHeight: 50,
  },
  [getDesktopThemeBreakpoint(theme)]: {
    backgroundColor: theme.palette.background.light.secondary,
  },
}))

export const OrderItemStatusText = styled('div', {
  name: 'OrderItem',
  slot: 'StatusText'
})(({ theme }) => ({
  marginTop: theme.spacing(6),
}))

export const OrderItemInfoContainer = styled('div', {
  name: 'OrderItem',
  slot: 'InfoContainer'
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '70px 1fr',
  columnGap: theme.spacing(8),
  rowGap: theme.spacing(4),
  fontSize: theme.typography.body1.fontSize,
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    columnGap: theme.spacing(10),
    marginBottom: 0,
  },
}))

export const StyledText = styled('div', {
  name: 'OrderItem',
  slot: 'Text',
  shouldForwardProp: (prop) => prop !== 'isBold'
})<{ isBold?: boolean }>(({ isBold = false}) => ({
  fontWeight: isBold ? 700 : '',
}))
