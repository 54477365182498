import styled from '@mui/material/styles/styled'
import { StyledCheckbox } from '../../../components/UI'

export const ShippingWrapper = styled('div', {
  name: 'Shipping',
  slot: 'wrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  marginTop: theme.spacing(6),
}))

export const ShippingBillingTitle = styled('div', {
  name: 'Shipping',
  slot: 'billingTitle',
})(({ theme }) => ({
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 20,
  padding: `${theme.spacing(6)} 0`,
}))

export const ShippingBillingCheckbox = styled(StyledCheckbox, {
  name: 'Shipping',
  slot: 'billingCheckbox'
})(({}) => ({
  paddingLeft: 0
}))