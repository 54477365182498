import Log from '../../../services/Log'
import config from '@configs/config.base'

const maintenanceService = {
  checkMaintenanceStatus: async () => {
    try {
      const res = await fetch(`${config.transactionHost}${config.maintenanceServiceUrl}`, {
        credentials: 'include',
      })
      return res.status
    } catch (e) {
      Log.error('Error on retrieving maintenance status: ' + e, config.maintenanceServiceUrl)
      return 500
    }
  },
}

export default maintenanceService
