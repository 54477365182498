import { ADDRESS_BILLING, ADDRESS_SHIPPING, ADDRESS_SHIPPING_BILLING } from '../../../../../constants/common'
//Standard libraries
import React, { FC, useState } from 'react'
import { setBillingFormValidStatus, setShippingFormValidStatus } from '../../../../../features/order/slice'

//Custom library
import AddressForm from '../../../../../components/AddressForm'
import { AddressFormData } from '@typesApp/form'
import { CheckoutAddressFormField } from '@typesApp/checkout'
//UI
import { UseFormReturn } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  ContentFormCheckoutAddress,
  WrapperCheckoutAddressSelection,
  CheckoutAddressFormTitle,
  CheckoutAddressFormButtonContainer,
  CheckoutAddressFormCloseLinkButton,
} from './CheckoutAdress.style'
import { Button } from '../../../../../components/UI'
import { useTranslation } from 'next-i18next'

export enum CheckoutPageType {
  SHIPPING = 'shipping',
  PAYMENT = 'payment',
}

export interface CheckoutAddressProps {
  addressFormFields: CheckoutAddressFormField[]
  addressType: AddressFormData['addressType']
  form: UseFormReturn<AddressFormData>
  updateAddressData?: AddressFormData
  formName: string
  hidden?: boolean
  isEditingAddress?: boolean
  setIsEditingAddress: (isEditing: boolean) => void
  updateAddress: (updateAddressData: AddressFormData, addressType: AddressFormData['addressType']) => void
  onFormDataChanged?: (type: AddressFormData['addressType'], data: AddressFormData) => void
}

const CheckoutAddress: FC<CheckoutAddressProps> = ({
  addressFormFields,
  addressType,
  form,
  formName,
  hidden,
  isEditingAddress,
  updateAddressData,
  updateAddress,
  setIsEditingAddress,
  onFormDataChanged,
}: CheckoutAddressProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isFormValid, setFormValid] = useState<boolean>(false)

  return (
    <WrapperCheckoutAddressSelection hidden={hidden} className="checkout-address-selection">
      <ContentFormCheckoutAddress className="checkout-address-selection__form" data-testid="checkout-address-form">
        {isEditingAddress && (
          <CheckoutAddressFormTitle>{t('Shipping.Labels.EditAddressTitle')}</CheckoutAddressFormTitle>
        )}
        <AddressForm
          addressFormFields={addressFormFields}
          addressType={addressType}
          cid={`newAddress-${addressType}`}
          form={form}
          formName={formName}
          onFormDataChanged={(type, data) => {
            onFormDataChanged && onFormDataChanged(type, data)
          }}
          onFormValidationStatusChanged={(type, isValid) => {
            setFormValid(isValid)
            if (type === ADDRESS_BILLING) {
              dispatch(setBillingFormValidStatus(isValid))
            }

            if (type === ADDRESS_SHIPPING || addressType === ADDRESS_SHIPPING_BILLING) {
              dispatch(setShippingFormValidStatus(isValid))
            }
          }}
        />
        {isEditingAddress && (
          <CheckoutAddressFormButtonContainer>
            <Button
              disabled={!isFormValid}
              variant="primary"
              onClick={() => {
                updateAddressData?.addressLine1 && updateAddress(updateAddressData, addressType)
                setIsEditingAddress(false)
              }}
            >
              {t('Shipping.Labels.Save')}
            </Button>
            <CheckoutAddressFormCloseLinkButton
              onClick={() => {
                setIsEditingAddress(false)
              }}
            >
              {t('Shipping.Labels.Cancel')}
            </CheckoutAddressFormCloseLinkButton>
          </CheckoutAddressFormButtonContainer>
        )}
      </ContentFormCheckoutAddress>
    </WrapperCheckoutAddressSelection>
  )
}

export default CheckoutAddress
