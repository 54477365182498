import { getCLBrand, getModelName } from '../../utils/productAttributes'
import { OrderItem } from '../../types/order'
import ProductImage from '../ProductImage/ProductImage'
import React from 'react'
import {
  OrderAdjustmentAmount,
  OrderRecapImageWrapper,
  OrderRecapInfoSectionWrapper,
  OrderRecapPriceSection,
  OrderRecapSectionLabel,
  OrderRecapSectionValue,
  PriceWrapper,
} from './OrderRecapItem.style'
import { useTranslation } from 'next-i18next'
import OrderRecapItemPrice from './OrderRecapItemPrice'
import FormattedPriceDisplay from '../formatted-price-display'
import { getFormattedDiscountedPrice } from '../../features/order/utils'
import { toNumber } from 'lodash-es'
import styles from './styles/OrderRecapItem.module.scss'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { useSelector } from 'react-redux'
import { SubscriptionSummaryMessage } from '@views/Subscription/cart/components/SummaryMessage'
import { Box } from '@mui/material'
import { CHECKOUT_STEPS } from '@constants/common'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { renderOrderItemStatusColor } from '@constants/order'
import OrderService from '../../foundation/apis/transaction/order.service'
import { OrderRecapItemPropsBase } from './OrderRecapItem'
import { useOrderDetails } from '../../hooks/useOrderDetails'
import clsx from 'clsx'

export type OrderRecapAccessoryItemProps = OrderRecapItemPropsBase<OrderItem>

const OrderRecapAccessoryItem: React.FC<OrderRecapAccessoryItemProps> = ({
  orderItem,
  showPromoCodeAppliedDiscount,
  isMyAccount = false,
  orderId,
  isReorderEligibleOrder,
}) => {
  const { t: translate } = useTranslation()
  const formattedDiscountedPrice = getFormattedDiscountedPrice(orderItem)
  const isPromoCodeApplied = !!formattedDiscountedPrice
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const hookEstimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)
  const estimatedItemDeliveryDate =
    OrderService.getOrderItemEstimatedDeliveryDate(orderItem) || hookEstimatedDeliveryDate
  const { activeStep } = useCheckoutSteps()
  const isConfirmationPage = CHECKOUT_STEPS.ORDER_CONFIRMATION === activeStep

  return (
    <Box style={{ width: '100%' }}>
      {isConfirmationPage && estimatedItemDeliveryDate && (
        <div className={styles.containerDeliveryDateDecorated}>
          <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
          <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.containerWrapper}>
          {isMyAccount && (
            <h3 className={clsx(styles.containerTitle, styles.containerTitleWithButton)}>
              <div className={styles.containerTitleText}>
                <span>{translate('OrderRecap.Item.Status.Label')}</span>
                <span style={{ color: renderOrderItemStatusColor(orderItem.orderItemStatus?.toUpperCase()) }}>
                  {translate(`OrderRecap.Item.Status.${orderItem.orderItemStatus?.toUpperCase()}`)}
                </span>
              </div>
            </h3>
          )}
          {isMyAccount && estimatedItemDeliveryDate && (
            <div
              className={clsx(
                styles.containerDeliveryDateDecorated,
                styles.containerDeliveryDateStretched,
                !isReorderEligibleOrder && styles.containerDeliveryDateNonReorder
              )}
            >
              <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
              <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
            </div>
          )}

          <div className={clsx(styles.content, !isMyAccount && !isConfirmationPage && styles.contentVertical)}>
            <OrderRecapImageWrapper>
              <ProductImage attachments={orderItem.attachments} usage="PLP" />
            </OrderRecapImageWrapper>
            <OrderRecapInfoSectionWrapper>
              <div className={styles.contentSection}>
                <h2 className={styles.contentTitle}>{`${getCLBrand(orderItem)} ${getModelName(orderItem)}`}</h2>
              </div>

              {Number(orderItem.quantity) > 0 && (
                <div className={styles.contentSection}>
                  <OrderRecapSectionLabel>{translate('OrderRecap.Labels.Quantity')}:</OrderRecapSectionLabel>
                  <OrderRecapSectionValue>{Number(orderItem.quantity)}</OrderRecapSectionValue>
                </div>
              )}
              {showPromoCodeAppliedDiscount && isPromoCodeApplied && (
                <div className={styles.contentSection}>
                  <OrderRecapSectionLabel>{translate('OrderRecap.Labels.PromoCodeApplied')}:</OrderRecapSectionLabel>
                  <OrderRecapSectionValue>
                    <OrderAdjustmentAmount>
                      <FormattedPriceDisplay min={formattedDiscountedPrice} currency={orderItem.currency} />
                    </OrderAdjustmentAmount>
                  </OrderRecapSectionValue>
                </div>
              )}
              {isSubscriptionEnabled && <SubscriptionSummaryMessage orderItem={orderItem} orderId={orderId} />}
              <OrderRecapPriceSection>
                <OrderRecapSectionLabel>{translate('OrderRecap.Labels.Price')}</OrderRecapSectionLabel>
                <PriceWrapper>
                  <OrderRecapItemPrice
                    orderItem={orderItem}
                    showDiscountPrice
                    quantity={toNumber(orderItem.quantity)}
                  />
                </PriceWrapper>
              </OrderRecapPriceSection>
              {!isMyAccount && !isConfirmationPage && estimatedItemDeliveryDate && (
                <div className={styles.containerDeliveryDate}>
                  <span>{translate('CartRecap.Labels.EstimatedDeliveryDate')}</span>
                  <span className={styles.containerDeliveryDateBold}>{estimatedItemDeliveryDate}</span>
                </div>
              )}
            </OrderRecapInfoSectionWrapper>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default OrderRecapAccessoryItem
