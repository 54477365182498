import React, { FC, useState } from 'react'
import { SearchClient } from 'algoliasearch'
import { useMediaQuery, useTheme } from '@mui/material'
// SWIPER
import SwiperCore, { A11y, Pagination } from 'swiper'
import { SwiperProps, SwiperSlide } from 'swiper/react'
// HOOKS
import useBreakpoints from '@hooks/useBreakpoints'
// TYPES
import { IAlgoliaBaseHit } from '@typesApp/product'
// COMPONENTS
import { SuggestedProductTileProps } from '@components/SuggestedProductTile/SuggestedProductTile'
import { SuggestedProductTileAlgolia } from '@components/SuggestedProductTileAlgolia'
import {
  SliderContainer,
  SliderNavigationButton,
  SliderNavigationContainer,
  StyledSingleItem,
  StyledSuggestedProductsCarousel,
  StyledSuggestedProductsDescription,
  StyledSuggestedProductsTextContainer,
  StyledSuggestedProductsTitle,
  StyledSuggestedProductsWrapper,
} from './SuggestedProductsAlgolia.style'
import { useTranslation } from 'next-i18next'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface SuggestedProductsProps {
  products?: IAlgoliaBaseHit[]
  sliderProps?: SwiperProps
  mainCarouselWidth?: number
  onPrevClick?: () => void
  onNextClick?: () => void
  onSlideChange?: (slideNumber: number | undefined) => void
  currentSlide?: number
  pdpdataloading?: boolean
  centeredSlides?: boolean
  productTileProps?: Partial<SuggestedProductTileProps>
  searchClient?: SearchClient
  categoryFilter?: string
  searchTerm?: string
  title?: string
  description?: string
}

export const ALGOLIA_SUGGESTED_PRODUCTS_ID = 'AlgoliaSuggestedProducts'
export const ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_CLASS_NAME = 'AlgoliaRecommendations_wrapper'

const SuggestedProductsAlgolia: FC<SuggestedProductsProps> = ({
  products,
  sliderProps,
  onSlideChange,
  productTileProps,
  title,
  description,
  ...rest
}: SuggestedProductsProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isMobile } = useBreakpoints()
  const isBelow1221 = useMediaQuery(theme.breakpoints.down(1221))
  const isBelow821 = useMediaQuery(theme.breakpoints.down(821))

  const numberOfProducts = products?.length || 0
  const singleItem = numberOfProducts === 1
  const defaultSlidesPerView = isMobile ? 1 : isBelow1221 ? (isBelow821 ? 2 : 3) : 4
  const slidesPerView = sliderProps?.slidesPerView ? Number(sliderProps.slidesPerView) : defaultSlidesPerView

  const [swiper, setSwiper] = useState<SwiperCore>()

  return products && numberOfProducts > 0 ? (
    <StyledSuggestedProductsWrapper id={ALGOLIA_SUGGESTED_PRODUCTS_ID}>
      <StyledSuggestedProductsTextContainer>
        <StyledSuggestedProductsTitle>
          {title ?? t('SuggestedProductsAlgolia.Labels.youMayAlsoLike')}
        </StyledSuggestedProductsTitle>

        {description && <StyledSuggestedProductsDescription>{description}</StyledSuggestedProductsDescription>}
      </StyledSuggestedProductsTextContainer>

      <SliderContainer {...rest} singleItem={singleItem}>
        {numberOfProducts > slidesPerView ? (
          <>
            <SliderNavigationContainer>
              <SliderNavigationButton
                onClick={() => {
                  swiper?.slidePrev()
                }}
              >
                <SVGIcon library="arrow" name="arrow-left" color={theme.palette.text.dark.primary} />
              </SliderNavigationButton>
            </SliderNavigationContainer>

            <StyledSuggestedProductsCarousel
              centeredSlides={false}
              loop={false}
              modules={[Pagination, A11y]}
              onSlideChange={() => onSlideChange && onSlideChange(swiper?.activeIndex)}
              onSwiper={setSwiper}
              pagination={{
                clickable: true,
              }}
              simulateTouch={true}
              slidesPerView={slidesPerView}
              {...sliderProps}
            >
              {products?.map((product, index) => (
                <SwiperSlide key={`swiper-${index}`}>
                  <SuggestedProductTileAlgolia
                    isPDP={true}
                    isClustered={true}
                    product={product}
                    tileIndex={index}
                    {...productTileProps}
                  />
                </SwiperSlide>
              ))}
            </StyledSuggestedProductsCarousel>

            <SliderNavigationContainer>
              <SliderNavigationButton
                onClick={() => {
                  swiper?.slideNext()
                }}
              >
                <SVGIcon library="arrow" name="arrow-right" color={theme.palette.text.dark.primary} />
              </SliderNavigationButton>
            </SliderNavigationContainer>
          </>
        ) : (
          products?.map((product, index) => (
            <StyledSingleItem key={index}>
              <SuggestedProductTileAlgolia
                isPDP={true}
                isClustered={true}
                product={product}
                tileIndex={index}
                {...productTileProps}
              />
            </StyledSingleItem>
          ))
        )}
      </SliderContainer>
    </StyledSuggestedProductsWrapper>
  ) : null
}

export default SuggestedProductsAlgolia
