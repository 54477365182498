import React, { FC } from 'react'
import ReassuranceItem from '../../../components/resurrance/ReassuranceItem'
import { useSelector } from 'react-redux'
import { isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import { getFormatterHclPageCartData } from '../../../features/cms/selector'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import Reassurance from '@components/resurrance'
import { StyledCartReassuranceContainer } from './CartReassuranceContainer.style'

const CartReassuranceContainer: FC = () => {
  const { cartCheckoutBenefitArea } = useSelector(getFormatterHclPageCartData('cart'))

  const item = cartCheckoutBenefitArea?.items?.find(isCMCollection)
  const teasableItems: ILXTeaser[] = item?.teasableItems.filter(isLXTeaser) ?? []

  return (
    <StyledCartReassuranceContainer>
      <Reassurance>
        {teasableItems &&
          teasableItems.map((placement, idx) => {
            const target = placement?.teaserLXCallToActionSettings[0]?.target as ICMArticle
            return (
              <ReassuranceItem
                key={idx}
                leftComponent={<CmsIcon teaserIcon={placement.teaserIcon} />}
                title={placement.teaserTitle1}
                subtitle={placement.teaserText1}
                content={target?.detailText}
                modalIcon={<CmsIcon teaserIcon={placement.teaserIcon} />}
              />
            )
          })}
      </Reassurance>
    </StyledCartReassuranceContainer>
  )
}

export default CartReassuranceContainer
