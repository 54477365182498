import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openModalDeletePrescriptionSelector } from '../../features/ui/selector'
import { setOpenModalDeletePrescription } from '../../features/ui/action'
import { useTranslation } from 'next-i18next'
import {
  CancelCTA,
  DeleteCTA,
  DeletePrescriptionConfirmationDialog,
  DeletePrescriptionSubSection,
  StyledModalDialog,
} from './Prescriptions.style'
import { useDeletePrescriptionMutation } from '../../features/prescription/query'
import { StyledAlert } from '../../components/UI'
import { StyledAlertHeader, StyledAlertMessage } from '../../components/UI/Alert'
import { IAlert } from '../../components/UI/Alert/Alert'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const DeletePrescriptionDialog: React.FC = () => {
  const dialogProps = useSelector(openModalDeletePrescriptionSelector)
  const prescription = dialogProps.prescription
  const [deletePrescription] = useDeletePrescriptionMutation()
  const [alert, setAlert] = useState<IAlert | null>(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClose = () => {
    setAlert(null)
    dispatch(setOpenModalDeletePrescription(false, null, null))
  }

  const onClickhandle = () => {
    deletePrescription(prescription?.idXprescription)
      .unwrap()
      .then(result => {
        if (result.errorCode) {
          setAlert({
            type: 'error',
            title: t('PrescriptionsSection.DeleteSection.Error.GenericError.Title'),
            message: result.errorMessage,
          })
        } else {
          dialogProps?.onActionSuccess({
            type: 'success',
            title: t('PrescriptionsSection.PrescriptionActionResultTitle', {
              action: t('PrescriptionsSection.Deleted'),
            }),
            description: t('PrescriptionsSection.PrescriptionActionResultMessage', {
              action: t('PrescriptionsSection.Deleted'),
              nickName: prescription?.nickName || prescription?.idXprescription,
            }),
          })
          handleClose()
        }
      })
      .catch(error => {
        setAlert({
          type: 'error',
          title: t('PrescriptionsSection.DeleteSection.Error.GenericError.Title'),
          message: `${t('PrescriptionsSection.DeleteSection.Error.GenericError.Description')} : ${error}`,
        })
      })
  }

  return (
    <StyledModalDialog
      open={dialogProps.isOpen}
      onClose={handleClose}
      onCloseButton={handleClose}
      widthSize={'xs'}
      isDeleteRxModal={true}
    >
      <>
        <DeletePrescriptionSubSection>
          <DeletePrescriptionConfirmationDialog>
            {t('PrescriptionsSection.DeleteSection.ConfirmationDialog')}
          </DeletePrescriptionConfirmationDialog>
        </DeletePrescriptionSubSection>

        {alert && (
          <StyledAlert
            onClose={() => setAlert(null)}
            severity={alert?.type || 'error'}
            sx={{ width: '100%' }}
            icon={<SVGIcon library="close" name="close-circle" />}
          >
            <StyledAlertHeader>{alert?.title}</StyledAlertHeader>
            <StyledAlertMessage fullWidth> {alert?.message}</StyledAlertMessage>
          </StyledAlert>
        )}

        <DeletePrescriptionSubSection>
          <DeleteCTA onClick={onClickhandle}>{t('PrescriptionsSection.DeleteSection.ConfirmationCTA')}</DeleteCTA>
          <CancelCTA variant="secondary" onClick={handleClose}>
            {t('PrescriptionsSection.DeleteSection.CancelCTA')}
          </CancelCTA>
        </DeletePrescriptionSubSection>
      </>
    </StyledModalDialog>
  )
}

export default DeletePrescriptionDialog
