import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Swiper from 'swiper'
import { cloneDeep, first } from 'lodash-es'
import { shallowEqual, useSelector } from 'react-redux'

import { PRODUCT_TYPES_MAP } from '@constants/product'
import { transformAttachmentsToImage } from '@features/plp/algoliaUtils'
import { productsMobileListingLayoutSelector } from '@features/ui/selector'
import { setLastSelectedProduct } from '@features/ui/action'
import { shouldShowAbsoluteDiscount } from '@foundation/algolia/algoliaPrice'
import { usePageType } from '@foundation/hooks/usePageType'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useAppDispatch } from '@hooks/redux'
import useBreakpoints from '@hooks/useBreakpoints'
import { useProductImages } from '@hooks/useProductImages/useProductImages'
import { pdpFrameImageOrderSelector, plpBadgesSelector } from '@redux/selectors/site'
import { ProductImageUsage, IProduct } from '../../types/product'
// utils
import { sortImageByConf } from '@utils/attachmentsUtils'
import { isContactLenses } from '@utils/product'
import { getBadges, getBrand, getModelCode, getModelName, getProductType } from '../../utils/productAttributesAlgolia'
import ProductPriceAlgolia from '@views/ProductDetails/components/ProductPriceAlgolia'
// components
import SuggestedProductTileGalleryAlgolia from './components/SuggestedProductTileGalleryAlgolia'
import ProductImage, {
  PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE,
  PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
  ProductImageProps,
} from '../ProductImage/ProductImage'
import {
  StyledImageGalleryWrapper,
  StyledSuggestedProductTileImageContainer,
} from './components/SuggestedProductTileAlgolia.style'
import {
  StyledImageWrapper,
  StyledSuggestedProductAnchor,
  StyledSuggestedProductBrandName,
  StyledSuggestedProductName,
  StyledSuggestedProductTileDescription,
  StyledSuggestedProductTileFooterWrapper,
  StyledSuggestedProductTileHeader,
} from '../SuggestedProductTile/components/SuggestedProductTile.style'
import {
  StyledSuggestedProductSubWrapperAlgolia,
  StyledSuggestedProductTileAlgoliaBadgeLabel,
  StyledSuggestedProductTileAlgoliaBadgeLabel2,
  StyledSuggestedProductTileFooterAlgolia,
  StyledSuggestedProductTileWrapperAlgolia,
} from './SuggestedProductTileAlgolia.style'
import { getProductImageAltLabel } from '@utils/productImage'

export interface ICurrentBadges {
  isNew: boolean
  isBadge: boolean
  isLimitedEdition: boolean
  isExclusive: boolean
  isAvantPremiere: boolean
  isOnlineExclusive: boolean
  isSustainable: boolean
  isCustomizable: boolean
  isPolarized: boolean
  isRoxable: boolean
}

const getItems = (product: any) => {
  return product?.sKUs != null ? product?.sKUs : product?.items
}

export interface SuggestedProductTileProps {
  footerElementsDisplay?: {
    colors: boolean
    inWishlistAdded: boolean
  }
  product: any
  onClick?: () => void
  tileIndex: number
  variant?: string
  onWishlistButtonClick?: (productId: string) => void
  isClustered?: boolean
  isPDP?: boolean
  isLazyLoadEnabled?: boolean
}

const siteName = process.env.NEXT_PUBLIC_STORENAME

const SuggestedProductTileAlgolia = ({
  product,
  onClick,
  tileIndex,
  variant,
  isClustered,
  isPDP,
}: SuggestedProductTileProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { pageType } = usePageType()
  const { isRXEnabled } = useStoreIdentity()
  const { isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440 } = useBreakpoints()
  const productType = (product !== null && PRODUCT_TYPES_MAP[getProductType(product).toLowerCase()]) || 'frames'

  const pdpFrameImageOrder = useSelector(pdpFrameImageOrderSelector, shallowEqual)
  const { images: currentImages } = useProductImages(transformAttachmentsToImage(product?.attachments))

  const sortedImageByConf = sortImageByConf(currentImages, pdpFrameImageOrder)
  const [_first, _second] = sortedImageByConf
  const imgProps = {
    sequence: PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
    usage: 'PLP' as ProductImageUsage,
  }

  const imgHoverProps = {
    sequence: PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE,
    usage: 'PLP' as ProductImageUsage,
  }

  const badgeSelector = useSelector(plpBadgesSelector)
  const [primaryBadge, setPrimaryBadge] = useState<string | null>()
  const [secondaryBadge, setSecondaryBadge] = useState<string | null>()
  const [clusters, setClusters] = useState<any[]>() // items when products are not clustered
  const [carouselMode, setCarouselMode] = useState<boolean>(false)
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)
  const [hoverImage, setHoverImage] = useState<ProductImageProps>(imgProps)

  const onProductTileMouseEnter = () => {
    if (isViewportWidthUnder426 || isMobile || isTablet) return
    if (clusterLength > 0) {
      setCarouselMode(true)
    } else if (selectedViewCluster) {
      setHoverImage(imgHoverProps)
    }
  }

  const onProductTileMouseLeave = () => {
    if (isViewportWidthUnder426 || isMobile || isTablet) return
    if (carouselMode) {
      setCarouselMode(false)
    } else if (selectedViewCluster) {
      setHoverImage(imgProps)
    }
  }

  const productsMobileListingLayout = useSelector(productsMobileListingLayoutSelector)
  const clusterViewLength = clusters?.length ?? 1

  const selectedViewCluster = useMemo<any>(
    () => (clusters?.length ? clusters[selectedClusterIndex] : product),
    [clusters, selectedClusterIndex, product]
  )

  const clusterLength = clusters?.length ?? 0

  const name = getModelName(product)
  const brand = getBrand(product)
  const modelCode = getModelCode(product)

  const tileDataElementId = useMemo(() => {
    switch (pageType) {
      case 'search':
        return `X_X_SearchPanel_Content_Tile${tileIndex}`
      case 'pdp':
        return `X_X_AlsoLike_Tile${tileIndex}`
      default:
        return `X_X_Tiles_Tile${tileIndex}_Img`
    }
  }, [pageType])

  const productImageWidth = useMemo<number>(() => {
    const isCMS = variant === 'cms-products-module'
    switch (true) {
      case isCMS:
        return 400
      case isViewportWidthUnder426:
        return 213
      case isMobile:
        return 296
      case isTablet:
        return 600
      case isViewportWidthAbove1440:
        return 600
      case isDesktop:
        return 600
      default:
        return 260
    }
  }, [isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440, variant])

  const onImageClick = () => {
    dispatch(setLastSelectedProduct(product?.id || ''))
  }

  const commonProductImageProps = {
    alt: getProductImageAltLabel(product, true),
    draggable: false,
    sequence: PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
    usage: 'PLP' as ProductImageUsage,
    width: productImageWidth,
    onClick: onImageClick,
  }

  const commonGalleryProductImageProps: ProductImageProps = {
    sequence: PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE,
    usage: 'PLP' as ProductImageUsage,
  }

  useEffect(() => {
    const newClusters = cloneDeep<IProduct[]>(
      (product?.cluster ?? (isClustered ? product?.clusters : product?.clusters?.slice(0, 1))) || []
    )
    setClusters(newClusters)
  }, [product?.cluster, product?.items, isClustered])

  useEffect(() => {
    let currentSku: any | undefined
    if (clusterLength) {
      const items = getItems(selectedViewCluster)

      if (items) {
        currentSku = first(items)
      } else {
        currentSku = product
      }
    } else {
      currentSku = product
    }

    if (!currentSku) {
      return
    }

    const badges = getBadges(currentSku, t, badgeSelector, isRXEnabled)
    setPrimaryBadge(badges.primaryBadge)
    setSecondaryBadge(badges.secondaryBadges)
  }, [product, selectedViewCluster])

  const getMocoLinkTo = (product: any | undefined) => {
    let href = product?.url || ''
    return href
  }

  const onProductChange = useCallback((swiper: Swiper) => {
    if (Number.isInteger(swiper.realIndex)) {
      setSelectedClusterIndex(swiper.realIndex)
    }
  }, [])

  return (
    product && (
      <StyledSuggestedProductTileWrapperAlgolia
        onMouseEnter={onProductTileMouseEnter}
        onMouseLeave={onProductTileMouseLeave}
        aria-label={primaryBadge || `${name}_${modelCode}`}
        data-element-id={tileDataElementId}
        data-description={`${siteName}_${name}_${modelCode}`}
      >
        <StyledSuggestedProductSubWrapperAlgolia onClick={onClick}>
          <>
            {(primaryBadge || secondaryBadge) && (
              <StyledSuggestedProductTileHeader>
                <StyledSuggestedProductTileAlgoliaBadgeLabel>
                  {primaryBadge}
                </StyledSuggestedProductTileAlgoliaBadgeLabel>
                <StyledSuggestedProductTileAlgoliaBadgeLabel2>
                  {secondaryBadge}
                </StyledSuggestedProductTileAlgoliaBadgeLabel2>
              </StyledSuggestedProductTileHeader>
            )}

            {(carouselMode || productsMobileListingLayout === 'full') && clusters && !isContactLenses(productType) ? (
              <StyledImageGalleryWrapper>
                <SuggestedProductTileGalleryAlgolia
                  mocoLink={getMocoLinkTo(selectedViewCluster)}
                  tileDataElementId={tileDataElementId}
                  priorityBadgeText={primaryBadge || ''}
                  commonImageProps={commonGalleryProductImageProps}
                  currentProductIndex={selectedClusterIndex}
                  onProductChange={onProductChange}
                  cluster={clusters}
                  clusterViewLength={clusterViewLength!}
                />
              </StyledImageGalleryWrapper>
            ) : (
              <StyledSuggestedProductTileImageContainer>
                <StyledSuggestedProductAnchor
                  aria-label={primaryBadge || `${name}_${modelCode}`}
                  href={getMocoLinkTo(selectedViewCluster)}
                  data-element-id={tileDataElementId}
                  data-description={`${siteName}_${name}_${modelCode}`}
                >
                  <StyledImageWrapper shouldScaleOnHover={!hoverImage}>
                    <div>
                      <ProductImage
                        attachments={transformAttachmentsToImage(selectedViewCluster.attachments)}
                        {...commonProductImageProps}
                        {...hoverImage}
                      />
                    </div>
                  </StyledImageWrapper>
                </StyledSuggestedProductAnchor>
              </StyledSuggestedProductTileImageContainer>
            )}
          </>
        </StyledSuggestedProductSubWrapperAlgolia>

        <StyledSuggestedProductTileFooterWrapper>
          <StyledSuggestedProductAnchor
            aria-label={primaryBadge || `${name}_${modelCode}`}
            href={getMocoLinkTo(selectedViewCluster)}
            data-element-id={tileDataElementId}
            data-description={`${siteName}_${name}_${modelCode}`}
          >
            <StyledSuggestedProductTileFooterAlgolia>
              {!isContactLenses(productType) ? (
                <StyledSuggestedProductTileDescription isPDP={isPDP!}>
                  <StyledSuggestedProductName productType={productType}>
                    {name ? name : '\u00A0'}
                  </StyledSuggestedProductName>

                  <StyledSuggestedProductBrandName>{brand}</StyledSuggestedProductBrandName>
                  <ProductPriceAlgolia
                    isCompact={false}
                    price={selectedViewCluster.price}
                    productType={productType}
                    orders={{ current: 2, initial: 1, discountAmount: 3 }}
                    isAbsoluteDiscount={shouldShowAbsoluteDiscount(selectedViewCluster)}
                    isVerticalLayout={!!isMobile}
                  />
                </StyledSuggestedProductTileDescription>
              ) : (
                <StyledSuggestedProductTileDescription isPDP={isPDP!}>
                  <StyledSuggestedProductName productType={productType}>
                    {name ? name : '\u00A0'}
                  </StyledSuggestedProductName>

                  {selectedViewCluster && selectedViewCluster.price && (
                    <ProductPriceAlgolia
                      isCompact={true}
                      price={selectedViewCluster.price}
                      productType={productType}
                      orders={{ current: 2, initial: 1, discountAmount: 3 }}
                      isAbsoluteDiscount={shouldShowAbsoluteDiscount(selectedViewCluster)}
                    />
                  )}
                </StyledSuggestedProductTileDescription>
              )}
            </StyledSuggestedProductTileFooterAlgolia>
          </StyledSuggestedProductAnchor>
        </StyledSuggestedProductTileFooterWrapper>
      </StyledSuggestedProductTileWrapperAlgolia>
    )
  )
}

SuggestedProductTileAlgolia.defaultProps = {
  isClustered: true,
}

export { SuggestedProductTileAlgolia }
