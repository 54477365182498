/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from 'react'
//Custom libraries
import { ForgotPasswordLayout } from './ForgotPasswordLayout'
// import { SectionContent } from '../../layouts/sectionContentType'

const ForgotPassword: React.FC<{
  handleClose: () => void
  openStepResetPassword: boolean
}> = ({ handleClose, openStepResetPassword }) => {
  return (
    <ForgotPasswordLayout
      handleClose={handleClose}
      {...{ openStepResetPassword }}
    />
  )
}

export { ForgotPassword }
