import React from 'react'
import { StyledTypography } from '../UI'
import styled from 'styled-components'
import Modal from '@mui/material/Modal'
import { Backdrop, Box, Fade } from '@mui/material'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface IProps {
  title: string
  subtitle: string

  content: string

  visible: boolean
  onClose: () => void

  icon: React.ReactNode
}

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  .reassurance-modal-no-outline {
    outline: 0;
    overflow: auto;
  }
`

const StyledModalContainer = styled('div')`
  width: 70%;
  background-color: ${({ theme }) => theme.palette.custom.warmGrey};
  border: 1px solid #000;
  color: black;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 800px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    height: 100%;
    background-color: #ebe9e4;
  }
`

const StyledBox = styled('div')`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 4px;
  }
`

const StyledModalHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 17px 16px 17px 16px;
  border-bottom: 1px solid black;
  font-weight: bold;

  .reassurance-modal-title {
    font-size: 2rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-right: 0px;
    margin: 0px 16px;
    ${({ theme }) => theme.spacing(1, 0, 1, 2)};

    .reassurance-modal-title {
      font-size: 1rem;
    }
  }
`
const StyledSubtitle = styled(StyledTypography)`
  font-size: 1rem;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 0.75rem;
  }
  p {
    margin: 0px;
    line-height: 1;
  }
`

const StyledModalHeaderLeft = styled('div')`
  display: flex;
  padding-left: 14px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 12px;
    padding-left: 0;
  }

  .arn-icon {
    background-size: contain;
    background-position-y: 50%;
    background-position-x: 50%;
    background-repeat: no-repeat;
  }
`

const StyledModalContent = styled('div')`
  padding: 30px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 25px 16px;
  }
`

const RessuranceItemModal: React.FC<IProps> = ({ title, subtitle, content, visible, onClose, icon }) => {
  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={visible}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <StyledModalContainer className="reassurance-modal-no-outline">
          <StyledModalHeader>
            <StyledModalHeaderLeft>
              <Box style={{ display: 'flex', alignItems: 'center' }}>{icon}</Box>
              <StyledBox>
                {/* <Box style={{ display: 'flex', flexDirection: 'column' }}> */}
                <Box marginLeft={1}>
                  <StyledTypography isUppercased className="reassurance-modal-title">
                    {title}
                  </StyledTypography>
                </Box>
                <Box marginLeft={1}>
                  <StyledSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
                </Box>
              </StyledBox>
            </StyledModalHeaderLeft>
            <SVGIcon library="close" name="close" onClick={onClose} />
          </StyledModalHeader>
          <StyledModalContent dangerouslySetInnerHTML={{ __html: content }}></StyledModalContent>
        </StyledModalContainer>
      </Fade>
    </StyledModal>
  )
}

export default RessuranceItemModal
