import { PAYMENT_METHODS } from '../../../../../constants/paymentMethods'
import { PaymentMethodType } from '@typesApp/checkout'

export type SimplePaymentType = 'card' | 'klarna' | 'paypal' | 'other' | 'applepay'

export const useSimplePaymentType = (
  paymentMethod: PaymentMethodType,
  paymentTermConditionId = ''
): SimplePaymentType => {
  if (paymentTermConditionId !== '') {
    return 'other'
  }

  switch (true) {
    case PAYMENT_METHODS.CHECKOUT_CC_NAMES.includes(paymentMethod.paymentMethodName):
      return 'card'
    case paymentMethod.paymentMethodName === 'CyberSourceKlarna':
      return 'klarna'
    case paymentMethod.paymentMethodName === 'PayPal':
      return 'paypal'
    case paymentMethod.paymentMethodName === 'ApplePay':
      return 'applepay'
    default:
      return 'other'
  }
}
