import styled from '@mui/material/styles/styled'
import { IconButton } from '../../../../components/UI'

export const ReducedHeaderWrapper = styled('header', {
  name: 'ReducedHeaderWrapper',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  height: theme.spacing(16),
  paddingRight: theme.spacing(16),
  paddingLeft: theme.spacing(16),
  alignItems: 'center',
  boxShadow: `inset 0 -1px 0 0 ${theme.palette.background.light.tertiary}`,
}))

export const ReducedHeaderNavigationLogoWrapper = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'NavigationLogoWrapper',
})(() => ({
  flex: 1,
}))

export const ReducedHeaderCheckoutDisclaimer = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'CheckoutDisclaimer',
})(({ theme }) => ({
  flex: 1,
  fontSize: '12px',
  color: theme.palette.custom.cyprus,
  fontWeight: 'bold',
  textAlign: 'right',
  span: {
    display: 'inline-block',
    textAlign: 'center',
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      textAlign: 'right',
      width: 'auto',
    },
  },
}))

export const ReducedHeaderMobileWrapper = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'MobileWrapper',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '10px',
  boxShadow: `inset 0 -1px 0 0 ${theme.palette.background.light.tertiary}`,
}))

export const ReducedHeaderMobileBack = styled(IconButton, {
  name: 'ReducedHeaderWrapper',
  slot: 'MobileBack',
})(() => ({
  flex: '1',
  justifyContent: 'flex-start',
}))

export const ReducedHeaderMobileTop = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'MobileTop',
})(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.custom.alto}`,
}))
export const ReducedHeaderMobileBottom = styled('div', {
  name: 'ReducedHeaderWrapper',
  slot: 'Mobilebottom',
})(({ theme }) => ({
  padding: `0 ${theme.spacing(12)} ${theme.spacing(4)} ${theme.spacing(12)}`,
}))
