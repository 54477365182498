import { EffectFade, Lazy, Navigation } from 'swiper'
import ProductImage, { ProductImageProps } from '../../ProductImage/ProductImage'
import React, { memo, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { IProduct } from '../../../types/product'
import { Swiper as SwiperClass } from 'swiper/types'
import { Swiper as SwiperDef } from 'swiper/swiper'
import { getModelCode, getModelName } from '../../../utils/productAttributesAlgolia'
import { StyledSuggestedProductAnchor } from './SuggestedProductTileAlgolia.style'
import { transformAttachmentsToImage } from '@features/plp/algoliaUtils'

const siteName = process.env.NEXT_PUBLIC_STORENAME

type SuggestedProductTileGalleryProps = {
  mocoLink: string
  tileDataElementId: string
  priorityBadgeText: string
  currentProductIndex: number
  cluster: IProduct[]
  onProductChange: (s: SwiperDef) => void
  commonImageProps?: ProductImageProps
  clusterViewLength?: number
}

const SuggestedProductTileGallery = ({
  mocoLink,
  tileDataElementId,
  priorityBadgeText,
  cluster,
  currentProductIndex,
  onProductChange,
  commonImageProps,
}: SuggestedProductTileGalleryProps) => {
  const swiperRef = useRef<SwiperClass | null>(null)
  const currentProduct = cluster[currentProductIndex]
  const name = getModelName(currentProduct)
  const modelCode = getModelCode(currentProduct)

  return (
    <Swiper
      onSwiper={swiper => (swiperRef.current = swiper)}
      onActiveIndexChange={onProductChange}
      initialSlide={currentProductIndex}
      effect={'fade'}
      className={'product-tile-swiper'}
      touchMoveStopPropagation={true}
      loop={true}
      navigation={false}
      modules={[Navigation, EffectFade, Lazy]}
    >
      {cluster.map((c: IProduct) => (
        <SwiperSlide key={`${name}_${modelCode}`}>
          <StyledSuggestedProductAnchor
            aria-label={priorityBadgeText || `${name}_${modelCode}`}
            href={mocoLink}
            className="product-tile__inner"
            data-element-id={tileDataElementId}
            data-description={`${siteName}_${name}_${modelCode}`}
          >
            <ProductImage attachments={transformAttachmentsToImage(c.attachments)} {...commonImageProps} />
          </StyledSuggestedProductAnchor>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default memo(SuggestedProductTileGallery)
