import styled from '@mui/material/styles/styled'

export const OrderConfirmationContent = styled('div', {
  name: 'OrderConfirmation',
  slot: 'Content',
})(({ theme }) => ({
  flex: '1 0 auto',
  marginBottom: theme.spacing(),
  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },
}))

export const OrderConfirmationPriceWrapper = styled('div', {
  name: 'OrderConfirmation',
  slot: 'PriceWrapper',
})(() => ({
  fontWeight: 'normal',
}))

export const StyledOrderConfirmationLoaderWrapper = styled('div', {
  name: 'OrderConfirmation',
  slot: 'LoaderWrapper',
})(({ theme }) => ({
  padding: `${theme.spacing()} 0`,
  display: 'flex',
  justifyContent: 'center',
}))
