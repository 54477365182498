import { useEffect } from 'react'
import Log from '../../services/Log'
import { executeOnce } from '@utils/common'
import { useSite } from '@foundation/hooks/useSite'

export const useWordLift = () => {
  const { mySite } = useSite()
  const loadScript = () => {
    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const wordLiftTag = document.createElement('script')

      wordLiftTag.src = mySite.xStoreCfg.WORD_LIFT_SCRIPT ?? ''
      headFragment.appendChild(wordLiftTag)
      document.head.appendChild(headFragment)
    }, 'cl-wordlift')()
  }

  useEffect(() => {
    try {
      loadScript()
    } catch (e: any) {
      Log.error('[WORDLIFT] LOAD SCRIPT ERROR: ' + e.message, window.location.href)
    }
  }, [])
}
