import { ModalDialog, ModalDialogTitle } from '../UI'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@mui/material/styles/styled'

import { ForgotPassword } from '../ForgotPassword'
import SignInFormDialog from './components/SignInFormDialog'
import { openModalSignInSelector } from '../../features/ui/selector'
import { setOpenModalSignIn } from '../../features/ui/action'
import { useSearchParams } from 'next/navigation'
import { useTranslation } from 'next-i18next'
import { Z_INDEX_DIALOG_LEVEL_FOR_ROX_CONFIGURATOR } from '../../constants/ui'

const SignInDialog: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const { isOpen, onLoginCallBack, onCancelCallBack } = useSelector(openModalSignInSelector)
  const validationUrlParams = searchParams?.['validation'] || ''
  const [openForgotPassword, setOpenForgotPassword] = useState<boolean>(false)
  const [isStepResetPassWordOpen, setIsStepResetPassWordOpen] = useState<boolean>(false)
  const handleClose = () => {
    dispatch(setOpenModalSignIn(false))
    setOpenForgotPassword(false)
    onCancelCallBack && onCancelCallBack(false)
  }
  const handleOpenStepResetPassWord = () => setIsStepResetPassWordOpen(true)
  const handleOpenForgotPassword = () => {
    setOpenForgotPassword(true)
    setOpenModalSignIn(false)
  }

  useEffect(() => {
    validationUrlParams !== null && handleOpenStepResetPassWord()
  }, [validationUrlParams])

  const StyledWrapper = styled('div')(({ theme }) => ({
    borderRadius: theme.spacing(),
    backgroundColor: 'white',
    padding: `${theme.spacing(10)} ${theme.spacing(5.5)} ${theme.spacing(5.5)}`,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  }))
  return (
    <ModalDialog
      sx={{ zIndex: Z_INDEX_DIALOG_LEVEL_FOR_ROX_CONFIGURATOR }}
      open={isOpen}
      onCloseButton={handleClose}
      fullSizeWhenSmall
    >
      {!openForgotPassword ? (
        <StyledWrapper>
          <ModalDialogTitle divider={false}>{t('SignInPage.TitleInYouAccount')}</ModalDialogTitle>
          <SignInFormDialog handleOpenForgotPassword={handleOpenForgotPassword} onLoginCallBack={onLoginCallBack} />
        </StyledWrapper>
      ) : (
        <ForgotPassword handleClose={handleClose} openStepResetPassword={isStepResetPassWordOpen} />
      )}
    </ModalDialog>
  )
}

export default SignInDialog
