import React from 'react'

import { Navigation, Pagination } from 'swiper'
import { SwiperSlide, SwiperProps } from 'swiper/react'
import { StyledSwiper } from './Carousel.style'
import { styled, useTheme } from '@mui/material'
import { ColorVariant, ShapeVariant } from './Carousel.type'

export interface CustomSwiperProps extends SwiperProps {
  items: any[],
  itemsPerView?: number | 'auto',
  itemsPerGroup?: number,
  paginationWidth?: number,
  paginationVariant?: ColorVariant,
  colorVariant?: ColorVariant,
  shapeVariant?: ShapeVariant,
  darkMode?: boolean,
  maxWidthForImages?: string,
  loop?: boolean
}

const StyledWrapper = styled('div')(()=>({

}))

export const CarouselGroup = (props: CustomSwiperProps) => {
  const theme = useTheme()
  const spaceBetween = parseInt(theme.spacing(8))
  const { items, itemsPerGroup, itemsPerView, paginationWidth, paginationVariant, colorVariant, shapeVariant, darkMode, maxWidthForImages } = props

  return (
    <StyledWrapper>
      <StyledSwiper
        slidesPerView={itemsPerView}
        slidesPerGroup={itemsPerGroup}
        spaceBetween={spaceBetween}
        loop={true}
        pagination={{
          clickable: true
        }}
        navigation={true}
        modules={[Navigation, Pagination]}
        paginationwidth={paginationWidth}
        paginationvariant={paginationVariant}
        colorvariant={colorVariant}
        shapevariant={shapeVariant}
        darkmode={darkMode ? 1 : 0}
        maxWidthForImages={maxWidthForImages}
      >
        {
          items.map((element, index) => <SwiperSlide key={index}>{element}</SwiperSlide>)
        }
      </StyledSwiper>
    </StyledWrapper>
  )
}
