import { CHECKOUT_STEPS } from '@constants/common'

export const DEFAULT_CHECKOUT_COL_GRIDS = 12

export const getCheckoutGridWidths = (
  activeStep: string | undefined,
  isViewportWidthAbove1240: boolean,
  isViewportWidthAbove1440: boolean,
  isViewportWidthAbove1950: boolean
): {
  leftColGridWidth: number
  rightColGridWidth: number
  dividerGridWidth: number
} => {
  let lCol, rCol

  if (activeStep === CHECKOUT_STEPS.ORDER_CONFIRMATION) {
    if (!isViewportWidthAbove1440) {
      lCol = 9
      rCol = 3
    } else {
      lCol = 8
      rCol = 4
    }
  } else if (activeStep === CHECKOUT_STEPS.REORDER_SUMMARY) {
    if (!isViewportWidthAbove1240) {
      lCol = 8
      rCol = 4
    } else if (isViewportWidthAbove1950) {
      lCol = 10
      rCol = 2
    } else {
      lCol = 9
      rCol = 3
    }
  } else {
    if (!isViewportWidthAbove1440) {
      lCol = 8
      rCol = 4
    } else {
      lCol = 6
      rCol = 3
    }
  }

  return {
    leftColGridWidth: lCol,
    rightColGridWidth: rCol,
    dividerGridWidth: DEFAULT_CHECKOUT_COL_GRIDS - rCol - lCol,
  }
}
