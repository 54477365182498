import styled from '@mui/material/styles/styled'
import { StyledRecapContainer } from '../../../components/order-recap/OrderRecap.style'
import FormattedPriceDisplay from '../../../components/formatted-price-display'
import { StyledTypography } from '../../../components/UI'

export const CheckoutSummaryContainer = styled('div', {
  name: 'CheckoutSummary',
  slot: 'Container',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  marginTop: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 0,
  },
}))

export const ShippingSummaryContainer = styled(StyledRecapContainer, {
  name: 'ShippingSummaryContainer',
  slot: 'Container',
})(() => ({
  marginTop: 0,
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  'div:first-of-type': {
    gridColumn: 'auto',
  },
  'div:nth-of-type(2)': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  '& .Tooltip-root': {
    display: 'inline-block',
    marginLeft: '0.25rem',
    transform: 'translateY(3px)',
  },
}))

export const HandlingFeeSummaryContainer = styled('div', {
  name: 'HandlingFee',
  slot: 'Container',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  'div:nth-of-type(2)': {
    display: 'flex',
  },
}))

export const CheckoutSummaryLabel = styled(StyledTypography, {
  name: 'CheckoutSummary',
  slot: 'Label',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}))

export const PaymentSummaryContainer = styled(StyledRecapContainer, {
  name: 'PaymentSummary',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.dark.primary,
  fontWeight: 'bold',
  '>div': {
    paddingTop: theme.spacing(1),
    flex: 1,
  },
}))

export const TotalDiscountRow = styled('div', {
  name: 'OrderRecap',
  slot: 'TotalDiscount',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: theme.typography.body1.fontSize,
}))

export const StyledDiscountPriceValue = styled('span', {
  name: 'DiscountPrice',
  slot: 'Value',
  shouldForwardProp: prop => prop !== 'fontColor',
})<{ fontColor?: string }>(({ theme, fontColor }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 700,
  color: fontColor ? fontColor : theme.palette.text.dark.primary,
}))

export const DeliverySummaryContainer = styled(StyledRecapContainer, {
  name: 'DeliverySummary',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  '>div': {
    flex: 1,
  },
}))

export const TotalSummaryContainer = styled(StyledRecapContainer, {
  name: 'TotalSummary',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const TotalSummaryRow = styled('div', {
  name: 'TotalSummary',
  slot: 'Row',
})(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  flexDirection: 'row',
  p: {
    flex: 1,
  },
}))

export const Total = styled(StyledTypography, {
  name: 'CheckoutSummary',
  slot: 'Total',
})(() => ({
  textAlign: 'right',
}))

export const StyledFormattedPriceDisplay = styled(FormattedPriceDisplay)(() => ({
  textAlign: 'right',
}))

export const TitleDeliverySummaryContent = styled('div', {
  name: 'CheckoutSummary',
  slot: 'TitleDeliverySummaryContent',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const ContinueShoppingButton = styled('div', {
  name: 'ContinueShoppingButton',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
}))
