import styled from '@mui/material/styles/styled'

export const SaveCCDialogContainer = styled('div', {
  name: 'SaveCCContainer',
  slot: 'Container',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    minWidth: theme.spacing(100),
    border: `solid 1px ${theme.palette.background.light.tertiary}`,
  },

  '.title': {
    margin: 0,
  },

  '.body': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    p: {
      marginTop: theme.spacing(2),
    },
    '.icon-wrapper': {
      width: '100%',
      textAlign: 'end',
    },
  },
  '.cta': {
    marginTop: theme.spacing(2),
  },
}))
