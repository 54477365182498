import { OrderDetailsWrapper } from './OrderDetails.style'
import React, { FC, useEffect } from 'react'
import { isRxCart, parseOrderItems } from '../../../utils/isRxOrder'
import { useOrderDetails, isOrderDetailsLoading } from '../../../hooks/useOrderDetails'

import { FETCH_ORDER_DETAILS_ACTION } from '../../../redux/actions/orderDetails'
import { useDispatch } from 'react-redux'
import { Loader } from '../../../components/UI'
import { OrderDetailsHeader } from './OrderDetailsHeader'
import { OrderDetailsItemList } from './OrderDetailsItemList'
import { Order } from '../../../types/order'
import { OrderDetailsFooter } from './OrderDetailsFooter'
import { useSite } from '@foundation/hooks/useSite'

export interface OrderDetailsProps {
  orderId: Order['orderId']
}

const OrderDetails: FC<OrderDetailsProps> = ({ orderId }) => {
  const dispatch = useDispatch()
  const orderDetails = useOrderDetails(orderId) || {}
  const isLoading = isOrderDetailsLoading(orderId)
  const { mySite } = useSite()

  useEffect(() => {
    if (orderId) {
      dispatch(FETCH_ORDER_DETAILS_ACTION({ orderId, storeId: mySite.storeID, skipErrorSnackbar: true }))
    }
  }, [orderId])

  if (isLoading) {
    return <Loader />
  }

  const orderItems = isRxCart(orderDetails?.orderExtendAttribute)
    ? parseOrderItems(orderDetails.orderItem)
    : orderDetails.orderItem

  return (
    <OrderDetailsWrapper>
      <OrderDetailsHeader orderDetails={orderDetails} />
      <OrderDetailsItemList orderDetails={orderDetails} orderItems={orderItems} />
      <OrderDetailsFooter orderDetails={orderDetails} orderItems={orderItems} />
    </OrderDetailsWrapper>
  )
}

export default OrderDetails
