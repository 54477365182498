import { useSelector, shallowEqual } from 'react-redux'
import { themeModeSelector } from '../../redux/selectors/theme'
import useTheme from '@mui/material/styles/useTheme'

/**
 * @returns current theme mode selected.
 *
 */
const useThemeMode = () => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const theme = useTheme()
  // Returns an object, so in the future we can add members without breaking current code
  // eg: switchTheme method
  return {
    themeMode: themeMode ?? 'light',
    theme,
  }
}

export default useThemeMode
