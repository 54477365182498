import { DefaultValues, useForm as useReactHookForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { buildYupValidationSchema } from '../constants/form'
import { CheckoutAddressFormField } from '../types/checkout'
import { AddressFormData } from '../types/form'
import { useYupValidationResolver } from '../utils/validationResolver'

const I18N_INVALID_MSG_BASE = 'AddressForm.Msgs.'
const I18N_FORM_FIELDS_INVALID_MSGS: Partial<Record<CheckoutAddressFormField['fieldName'], string>> = {
  email1: 'InvalidEmail',
  phone1: 'InvalidPhone',
  addressLine1: 'InvalidAddress',
  zipCode: 'InvalidZipCode',
  city: 'InvalidCity',
  firstName: 'InvalidFirstName',
  lastName: 'InvalidLastName',
}

export const useCheckoutForm = ({
  defaultValues,
  fields,
}: {
  defaultValues: DefaultValues<AddressFormData>
  fields: CheckoutAddressFormField[]
}): UseFormReturn<AddressFormData> => {
  const { t } = useTranslation()

  const form = useReactHookForm<AddressFormData>({
    defaultValues,
    mode: 'onTouched',
    resolver: useYupValidationResolver(
      buildYupValidationSchema<CheckoutAddressFormField>({
        formFields: fields,
        i18nInvalidMsgBase: I18N_INVALID_MSG_BASE,
        i18nFormFieldsInvalidMsgs: I18N_FORM_FIELDS_INVALID_MSGS,
        t,
      })
    ),
  })

  return form
}
