import styled from '@mui/material/styles/styled'

export const StyledHeadingContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.dark.primary,
  padding: theme.spacing(4),
  fontSize: theme.typography.h3.fontSize,
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(4),
  },
}))

export const WarningSection = styled('div', {
  name: 'WarningSection',
})(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(4),
  padding: theme.spacing(8, 6, 0, 6),
}))

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(5),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))
