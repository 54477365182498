import React, { useEffect } from 'react'
import getDisplayName from 'react-display-name'
import { userWalletSelector } from '../redux/selectors/user'
import { useAppDispatch, useAppSelector } from './redux'
import Axios, { Canceler } from 'axios'
import { FETCH_USER_WALLET_REQUESTED_ACTION } from '../redux/actions/user'
import { Wallet } from '../types/user'

export const useStoredPaymentDetails = <T>(component: React.FC<T>): Wallet[] => {
  const walletDetails = useAppSelector(userWalletSelector)
  const widgetName = getDisplayName(component)
  const CancelToken = Axios.CancelToken
  const dispatch = useAppDispatch()
  let cancels: Canceler[] = []

  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  useEffect(() => {
    if (!walletDetails) {
      dispatch(FETCH_USER_WALLET_REQUESTED_ACTION(payloadBase))
    }

    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [])

  return walletDetails?.wallet || []
}
