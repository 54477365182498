import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import DateService from '../../../services/DateService'
import { DatePicker } from '../Datepicker'
import { TextField } from '../TextField'
import { FormControlHelperText } from './FormControlHelperText'
import { FormControlProps } from './types'

const MIN_DATE = new Date(1900, 1, 1)

type FormControlDateFieldProps = Omit<FormControlProps, 'inputProps'>

export const FormControlDateField: React.FC<FormControlDateFieldProps> = ({
  id,
  name,
  rules,
  label,
  placeholder,
  description,
}) => {
  const { control} = useFormContext()
  const required = rules ? (rules['required']?.value === true || rules['required'] === true) : false
  const dateFormat = rules ? (rules['validation']?.format) : ''
  const invalidFormatMessage = rules ? (rules['validation']?.message) : ''

  if (!dateFormat || !invalidFormatMessage) {
    throw Error('Forgot date format and message? Make sure rules object contains {validation: {dateFormat: FORMAT, message: MESSAGE}}')
  }

  if (!rules) {
    rules = {}
  }

  // add validate rule to validate date input
  if (!rules['validate']) {
    rules['validate'] = (value: Date | string) => {
      let valid = true
      if (value instanceof Date) {
        valid = isValidDate(value)
      } else if (typeof value === 'string') {
        valid = isValidDate(DateService.parse(value, dateFormat))
      }
      return valid || invalidFormatMessage
    }
  }

  const isValidDate = (date: Date): boolean => {
    return !isNaN(date.getTime()) && date.getTime() > MIN_DATE.getTime()
  }

  const toDateValue = (value: Date | string | null): Date | null => {
    if (value instanceof Date) {
      return value
    }
    if (value && typeof value === 'string') {
      return DateService.parse(value, dateFormat)
    }
    return null
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
        <DatePicker
          {...rest}
          inputRef={ref}
          disableFuture
          views={['year', 'day']}
          inputFormat={dateFormat}
          minDate={MIN_DATE}
          value={toDateValue(value)}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                id={id}
                label={`${label}${required ? ' *': ''}`}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholder || params.inputProps?.placeholder,
                }}
                error={!!error}
              />

              <FormControlHelperText
                description={description}
                error={error}
              />
            </>
          )}
        />
      )}
    />
  )
}
